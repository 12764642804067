<div (change)="onChange()" [ngClass]="{'disabled' : disable(), 'enabled' : alwaysEnable()}" class="fieldset">
  <div class="row narrow-gutters">
    <div class="col-md-12">
      <ul #nav="ngbNav" class="nav-tabs" ngbNav>
        <li [ngbNavItem]="1">
          <a ngbNavLink>Handling Details</a>
          <ng-template ngbNavContent>
            <div class="p-3 border border-top-0 rounded-bottom">
              <notifications [forHandling]="handling"
                             [notificationContext]="'detail'">
              </notifications>

              <div *ngIf="appContext.isPhone() && !handling.new" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Handling Status</h2>
                </div>
                <div class="col-md-12">
                  <app-handling-status [displayReason]="true"
                                       [hideTooltip]="true"
                                       [setHandling]="handling"
                                       [status]="utils.getHandlingStatus(handling)"
                                       [terminal]="handling.terminal"></app-handling-status>
                </div>
                <div class="col-md-12">
                  <app-handling-status-summary [acceptStatuses]="mapAcceptStatus()"
                                               [handling]="handling"
                                               [status]="handling.status"></app-handling-status-summary>
                </div>
              </div>

              <div *ngIf="!handling.visitId" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Visit details</h2>
                </div>

                <div class="col-md-6">
                  <app-form-group *ngIf="handling.new">
                    <label>Link to existing visit</label>
                    <app-yes-no (ngModelChange)="onLinkTo($event)"
                                [(ngModel)]="linkTo" required></app-yes-no>
                  </app-form-group>

                  <app-form-group *ngIf="linkTo">
                    <label>Call id</label>
                    <app-search (ngModelChange)="onItemForAttachingFound($event)"
                                [(ngModel)]="itemForAttaching"
                                [disabled]="!handling.new"
                                [modality]="handling.modality"
                                [searchFunction]="utils.findVisits" dataKey="visitId"
                                placeholder="The call id of the terminal visit"
                                required>
                    </app-search>
                  </app-form-group>
                </div>

                <div *ngIf="!handling.new || linkTo === false || !!itemForAttaching"
                     [ngClass]="{'disabled' : linkTo && linkToItem == 'visit'}"
                     class="col-md-6 fieldset">
                  <app-form-group>
                    <label>Modality</label>
                    <app-radio (ngModelChange)="handling.modality = $event; handling.handlingData.modality = $event"
                               [(ngModel)]="handling.modality"
                               [formatter]="utils.toTitleCase"
                               [inline]="true" [options]="getModalityOptions()"
                               required></app-radio>
                  </app-form-group>

                  <app-form-group>
                    <label>Terminal</label>
                    <app-select (ngModelChange)="onTerminalChanged()"
                                [(ngModel)]="handling.terminal"
                                [dataKey]="handling.modality === 'rail' ? 'shortName' : 'quayName'"
                                [nullOption]="true"
                                [optionsProvider]="utils.getTerminals(handling.modality, true, true, true)"
                                [refreshWatch]="handling.modality"
                                optionLabel="displayName"
                                optionSelectable="shortName" required>
                    </app-select>
                  </app-form-group>

                  <app-form-group>
                    <label [ngClass]="{'required' : isEtaRequired()}">ETA</label>
                    <app-date-time [(ngModel)]="handling.eta" [required]="isEtaRequired()"></app-date-time>
                  </app-form-group>
                </div>
              </div>

              <div class="row">
                <div class="declaration-block col-md-12">
                  <h2>Handling details</h2>
                </div>

                <div class="col-md-6">
                  <app-form-group *ngIf="!!handling.visitId || !!itemForAttaching
                  || ('barge' === handling.handlingData.modality && (handling.new && !!handling.visitId) && !handling.terminal.bargeStatusRequestForbidden)
                  || ('rail' === handling.handlingData.modality && (handling.new && !!handling.visitId))
                  || ('road' === handling.handlingData.modality && handling.terminal.roadStatusRequestAllowed && (handling.attached || handling.new))">
                    <label>Pre-notification</label>
                    <app-yes-no [(ngModel)]="handling.preNotification" required></app-yes-no>
                  </app-form-group>

                  <app-form-group>
                    <label>Handling</label>
                    <app-compare [formatter]="utils.toTitleCase" [otherValue]="handling.handlingStatus?.type"
                                 [value]="handlingData.type" mode="TERMINAL">
                      <app-radio (ngModelChange)="onHandlingTypeChanged($event)" [(ngModel)]="handlingData.type"
                                 [formatter]="utils.toTitleCase"
                                 [inline]="true" [options]="['loading', 'discharge']" required></app-radio>
                    </app-compare>
                  </app-form-group>

                  <app-form-group>
                    <label>Container status</label>
                    <app-compare [formatter]="utils.containerStatusFormatter"
                                 [otherValue]="handling.handlingStatus?.full"
                                 [value]="handlingData.full"
                                 mode="TERMINAL">
                      <app-yes-no [(ngModel)]="handlingData.full" [formatter]="utils.containerStatusFormatter"
                                  required></app-yes-no>
                    </app-compare>
                  </app-form-group>

                  <app-form-group>
                    <label [ngClass]="{'required' : isContainerNumberRequired()}">Container number</label>
                    <app-compare [otherValue]="handling.handlingStatus?.equipmentNumber"
                                 [value]="handlingData.equipmentNumber" mode="TERMINAL">
                      <input (blur)="getContainer()" (change)="tryGetContainer()"
                             [(ngModel)]="handlingData.equipmentNumber" [maxLength]="11"
                             [required]="isContainerNumberRequired()" class="form-control input-uppercase"
                             oninput="this.value = this.value.toUpperCase()" placeholder="Format ABCD1234567 or custom">
                    </app-compare>
                  </app-form-group>

                  <app-form-group *ngIf="!!handlingData.equipmentNumber">
                    <label>Shipper's owned</label>
                    <app-yes-no (change)="checkValidContainerNumber()" [(ngModel)]="handlingData.shippersOwned"
                                [inline]="true"></app-yes-no>
                  </app-form-group>

                  <ng-container *ngIf="hasIntegralPlanning()">
                    <app-form-group>
                      <label>Door open</label>
                      <app-compare [formatter]="utils.yesNoFormatter" [otherValue]="handling.handlingStatus?.doorOpen"
                                   [value]="handlingData.doorOpen"
                                   mode="TERMINAL">
                        <app-yes-no [(ngModel)]="handlingData.doorOpen" [inline]="true"></app-yes-no>
                      </app-compare>
                    </app-form-group>

                    <app-form-group>
                      <label>Chains needed</label>
                      <app-compare [formatter]="utils.yesNoFormatter" [otherValue]="handling.handlingStatus?.chainWork"
                                   [value]="handlingData.chainWork"
                                   mode="TERMINAL">
                        <app-yes-no [(ngModel)]="handlingData.chainWork" [inline]="true"></app-yes-no>
                      </app-compare>
                    </app-form-group>

                    <app-form-group>
                      <label>Has generator</label>
                      <app-compare [formatter]="utils.yesNoFormatter" [otherValue]="handling.handlingStatus?.generator"
                                   [value]="handlingData.generator"
                                   mode="TERMINAL">
                        <app-yes-no [(ngModel)]="handlingData.generator" [inline]="true"></app-yes-no>
                      </app-compare>
                    </app-form-group>
                  </ng-container>

                  <app-form-group *ngIf="terminalSettings.reeferAllowed || !!handlingData.reefer" label="Reefer">
                    <app-yes-no (ngModelChange)="handlingData.reefer = $event ? {} : null"
                                [ngModel]="!!handlingData.reefer"></app-yes-no>
                  </app-form-group>
                </div>

                <div class="col-md-6">
                  <app-form-group *ngIf="!isCargoDeclarantOfHandling()">
                    <label>Cargo declarant</label>
                    <input [value]="getCargoDeclarant()" class="form-control-plaintext" readonly>
                  </app-form-group>

                  <app-form-group>
                    <label>Shipping company</label>
                    <app-compare [otherValue]="handling.handlingStatus?.shippingCompany?.name"
                                 [value]="handlingData?.shippingCompany?.name" mode="TERMINAL">
                      <app-search [(ngModel)]="handlingData.shippingCompany"
                                  [resultFormatter]="utils.shippingCompanyFormatter"
                                  [searchFunction]="utils.findShippingCompanies"
                                  dataKey="name" placeholder="Shipping company" required>
                      </app-search>
                    </app-compare>
                  </app-form-group>

                  <app-form-group *ngIf="terminalSettings.sealNumberAllowed || !!handlingData.sealNumber">
                    <label>Seal number</label>
                    <app-compare [otherValue]="handling.handlingStatus?.sealNumber" [value]="handlingData.sealNumber"
                                 mode="TERMINAL">
                      <input [(ngModel)]="handlingData.sealNumber"
                             [maxLength]="!!handling.terminal.maxSealNumberLength ? handling.terminal.maxSealNumberLength : 35"
                             [required]="terminalSettings.sealNumberAllowed" class="form-control">
                    </app-compare>
                  </app-form-group>

                  <app-form-group
                    *ngIf="handlingData.type==='discharge' || utils.isCargoDeclarant(handling) || utils.isCharter(handling)">
                    <label>Acceptance reference</label>
                    <app-compare [otherValue]="handling.handlingStatus?.bookingNumber"
                                 [value]="handlingData.bookingNumber" mode="TERMINAL">
                      <input
                        (change)="handlingData.bookingNumber = utils.bookingNumberFormatter($any($event.target).value)"
                        [(ngModel)]="handlingData.bookingNumber" class="form-control input-uppercase"
                        placeholder="Pickup/Drop-off number"
                        required>
                    </app-compare>
                  </app-form-group>

                  <app-form-group>
                    <label>Size / Type</label>
                    <app-compare [otherValue]="handling.handlingStatus?.sizeType?.code"
                                 [value]="handlingData.sizeType?.code" mode="TERMINAL">
                      <app-search [(ngModel)]="handlingData.sizeType" [resultFormatter]="utils.sizeTypeFormatter"
                                  [searchFunction]="utils.findSizeTypes"
                                  dataKey="name" placeholder="Size or type, e.g. 22G1" required>
                      </app-search>
                    </app-compare>
                  </app-form-group>

                  <app-form-group>
                    <label>Cargo weight (kg)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.weight" [value]="handlingData.weight"
                                 mode="TERMINAL">
                      <input [(ngModel)]="handlingData.weight" [required]="terminalSettings.grossWeightAllowed"
                             appDecimalNumber class="form-control" max="99999"
                             (ngModelChange)="this.calculateTotalWeight()" maxDecimals="0"
                             placeholder="Excl. weight of container in kg. (max 99999)">
                    </app-compare>
                  </app-form-group>

                  <app-form-group>
                    <label>Tare weight (kg)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.tareWeight" [value]="handlingData.tareWeight"
                                 mode="TERMINAL">
                      <input [(ngModel)]="handlingData.tareWeight" appDecimalNumber class="form-control" max="99999"
                             (ngModelChange)="this.calculateTotalWeight()" maxDecimals="0"
                             placeholder="The weight of the empty container in kg. (max 99999)">
                    </app-compare>
                  </app-form-group>

                  <app-form-group *ngIf="!!handlingData.tareWeight && !!handlingData.weight">
                    <label>Total weight (kg)</label>
                    <input [ngModel]="handlingData.totalContainerWeight" appDecimalNumber class="form-control"
                           max="99999"
                           maxDecimals="0"
                           placeholder="The total weight of the container in kg, calculated from Cargo weight and Tare weight."
                           disabled>
                  </app-form-group>

                  <app-form-group *ngIf="terminalSettings.outOfGaugeAllowed || !!handlingData.outOfGauge"
                                  label="Out of gauge">
                    <app-yes-no (ngModelChange)="handlingData.outOfGauge = $event ? {} : null"
                                [ngModel]="!!handlingData.outOfGauge"></app-yes-no>
                  </app-form-group>

                  <ng-container *ngIf="hasIntegralPlanning()">
                    <app-form-group>
                      <label>Previous port</label>
                      <app-compare [otherValue]="handling.handlingStatus?.previousPort"
                                   [value]="handlingData.previousPort" mode="TERMINAL">
                        <input [(ngModel)]="handlingData.previousPort" class="form-control">
                      </app-compare>
                    </app-form-group>

                    <app-form-group>
                      <label>Humidity (%)</label>
                      <app-compare [otherValue]="handling.handlingStatus?.humidity" [value]="handlingData.humidity"
                                   mode="TERMINAL">
                        <input [(ngModel)]="handlingData.humidity" appDecimalNumber class="form-control"
                               maxDecimals="0">
                      </app-compare>
                    </app-form-group>

                    <app-form-group>
                      <label>Detected damage</label>
                      <app-compare [otherValue]="handling.handlingStatus?.damageRemark"
                                   [value]="handlingData.damageRemark" mode="TERMINAL">
                          <textarea [(ngModel)]="handlingData.damageRemark" class="form-control"
                                    placeholder="Describe any detected damage to the container"
                                    style="min-height: 3rem"></textarea>
                      </app-compare>
                    </app-form-group>
                  </ng-container>

                  <app-form-group *ngIf="hasIntegralPlanning() || handling.modality === 'rail'">
                    <label>Remarks</label>
                    <app-compare [otherValue]="handling.handlingStatus?.remarks" [value]="handlingData.remarks"
                                 mode="TERMINAL">
                        <textarea [(ngModel)]="handlingData.remarks" class="form-control"
                                  placeholder="Other remarks about the container"
                                  style="min-height: 3rem"></textarea>
                    </app-compare>
                  </app-form-group>

                  <app-form-group *ngIf="!!handling.handlingStatus">
                    <label>Block group</label>
                    <input [(ngModel)]="handling.handlingStatus.blockGroup" class="form-control" disabled>
                  </app-form-group>
                </div>
              </div>

              <!-- barge / road -->
              <div *ngIf="handling.handlingData.modality != 'rail'" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Transport info</h2>
                </div>

                <div class="col-md-6">
                  <app-form-group>
                    <label>Transport reference</label>
                    <input [(ngModel)]="handling.handlingData['transportNumber']"
                           class="form-control"
                           placeholder="Transport order reference">
                  </app-form-group>

                </div>
                <div *ngIf="handlingData.type === 'loading' && handling.handlingData.modality === 'barge'"
                     class="col-md-6">
                  <app-form-group>
                    <label>Inland terminal location</label>
                    <app-search [(ngModel)]="handling.handlingData.inlandTerminal.location"
                                [resultFormatter]="utils.locationFormatter"
                                [searchFunction]="utils.findLocations"
                                dataKey="name"
                                minCharacters="2"
                                placeholder="Location" required>
                    </app-search>
                  </app-form-group>

                  <app-form-group>
                    <label>Inland terminal name</label>
                    <input [(ngModel)]="handling.handlingData.inlandTerminal.name" class="form-control"
                           placeholder="Name">
                  </app-form-group>
                </div>
              </div>

              <div *ngIf="handling.transportOrder" class="fieldset disabled">
                <div class="declaration-block">
                  <h2>Transport order</h2>
                </div>
                <app-transport-order [order]="handling.transportOrder"></app-transport-order>
              </div>

              <!--Reefer details-->
              <div *ngIf="!!handlingData.reefer" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Reefer details</h2>
                </div>
                <div class="col-md-6">
                  <app-form-group>
                    <label>Temperature</label>
                    <app-compare [otherValue]="handling.handlingStatus?.reefer?.temperatureFixed"
                                 [value]="handlingData.reefer?.temperatureFixed"
                                 mode="TERMINAL">
                      <app-yes-no (ngModelChange)="clearTemperature()"
                                  [(ngModel)]="handlingData.reefer.temperatureFixed"
                                  [formatter]="utils.temperatureChoiceFormatter"></app-yes-no>
                    </app-compare>
                  </app-form-group>

                  <app-form-group *ngIf="handlingData.reefer.temperatureFixed">
                    <label>Temperature (&deg;C)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.reefer?.temperature"
                                 [value]="handlingData.reefer?.temperature" mode="TERMINAL">
                      <input [(ngModel)]="handlingData.reefer.temperature" appDecimalNumber
                             class="form-control" placeholder="Connecting temperature (-999 to 999 &deg;C)">
                    </app-compare>
                  </app-form-group>
                  <app-form-group *ngIf="handlingData.reefer.temperatureFixed === false">
                    <label>Minimum temperature (&deg;C)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.reefer?.minimumTemperature"
                                 [value]="handlingData.reefer?.minimumTemperature"
                                 mode="TERMINAL">
                      <input [(ngModel)]="handlingData.reefer.minimumTemperature" appDecimalNumber
                             class="form-control" placeholder="Connecting temperature (-999 to 999 &deg;C)">
                    </app-compare>
                  </app-form-group>
                </div>
                <div class="col-md-6">
                  <app-form-group *ngIf="handlingData.reefer.temperatureFixed === false">
                    <label>Maximum temperature (&deg;C)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.reefer?.maximumTemperature"
                                 [value]="handlingData.reefer?.maximumTemperature"
                                 mode="TERMINAL">
                      <input [(ngModel)]="handlingData.reefer.maximumTemperature" appDecimalNumber
                             class="form-control" placeholder="Connecting temperature (-999 to 999 &deg;C)">
                    </app-compare>
                  </app-form-group>
                </div>
              </div>

              <!--Out of gauge-->
              <div *ngIf="!!handlingData.outOfGauge" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Out of gauge</h2>
                </div>

                <div class="col-md-6">
                  <app-form-group>
                    <label>Left (cm)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.outOfGauge?.left"
                                 [value]="handlingData.outOfGauge?.left" mode="TERMINAL">
                      <input [(ngModel)]="handlingData.outOfGauge.left" [maxDecimals]="0" appDecimalNumber
                             class="form-control" placeholder="out-of-gauge for left side">
                    </app-compare>
                  </app-form-group>
                  <app-form-group>
                    <label>Front (cm)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.outOfGauge?.front"
                                 [value]="handlingData.outOfGauge?.front" mode="TERMINAL">
                      <input [(ngModel)]="handlingData.outOfGauge.front" [maxDecimals]="0" appDecimalNumber
                             class="form-control" placeholder="out-of-gauge for front side">
                    </app-compare>
                  </app-form-group>
                  <app-form-group>
                    <label>Height (cm)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.outOfGauge?.height"
                                 [value]="handlingData.outOfGauge?.height" mode="TERMINAL">
                      <input [(ngModel)]="handlingData.outOfGauge.height" [maxDecimals]="0" appDecimalNumber
                             class="form-control" placeholder="out-of-gauge for height">
                    </app-compare>
                  </app-form-group>
                </div>

                <div class="col-md-6">
                  <app-form-group>
                    <label>Right (cm)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.outOfGauge?.right"
                                 [value]="handlingData.outOfGauge?.right" mode="TERMINAL">
                      <input [(ngModel)]="handlingData.outOfGauge.right" [maxDecimals]="0" appDecimalNumber
                             class="form-control" placeholder="out-of-gauge for right side">
                    </app-compare>
                  </app-form-group>
                  <app-form-group>
                    <label>Rear (cm)</label>
                    <app-compare [otherValue]="handling.handlingStatus?.outOfGauge?.rear"
                                 [value]="handlingData.outOfGauge?.rear" mode="TERMINAL">
                      <input [(ngModel)]="handlingData.outOfGauge.rear" [maxDecimals]="0" appDecimalNumber
                             class="form-control" placeholder="out-of-gauge for rear side">
                    </app-compare>
                  </app-form-group>
                </div>
              </div>

              <!--Dangerous goods-->
              <div *ngIf="handling.modality === 'rail'" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Dangerous goods</h2>
                </div>

                <div class="col-md-12">
                  <div *ngFor="let good of handling.handlingData?.dangerousGoods; let index = index; let last=last">
                    <div class="row mb-2">
                      <div class="col">
                        <input [(ngModel)]="handling.handlingData.dangerousGoods[index].unCode" class="form-control"
                               placeholder="UN number">
                      </div>
                      <div class="col">
                        <input [(ngModel)]="handling.handlingData.dangerousGoods[index].hazardClass"
                               class="form-control" placeholder="Hazard class">
                      </div>
                      <div class="col">
                        <input [(ngModel)]="handling.handlingData.dangerousGoods[index].gevi"
                               class="form-control" placeholder="GEVI">
                      </div>
                      <div class="col">
                        <div class="text-end">
                          <button (click)="removeDangerousGood(index)" class="btn" tabindex="-1" title="Delete good"
                                  type="button">
                            <span class="fa fa-trash text-secondary"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr *ngIf="!last" class="d-lg-none">
                  </div>
                </div>

                <div class="col text-end mt-3 d-flex justify-content-end">
                  <button (click)="addDangerousGood()" class="btn btn-info" type="button">
                    <span class="pe-2 fa fa-plus-circle"></span> <span>Add Good</span>
                  </button>
                </div>
              </div>

              <!-- Transport info -->
              <div *ngIf="handling.modality === 'rail'" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Transport info</h2>
                </div>

                <div class="col-md-6">
                  <app-form-group label="Transport reference">
                    <input [(ngModel)]="handling.handlingData['transportNumber']"
                           class="form-control"
                           placeholder="Transport order reference">
                  </app-form-group>

                  <app-form-group label="ITT location">
                    <app-select [(ngModel)]="handling.handlingData.interTerminalTransportLocation"
                                [formatter]="utils.ittFormatter" [nullOption]="true"
                                [optionsProvider]="utils.getRailInterTerminals()" class="input-group"
                                dataKey="shortName"></app-select>
                  </app-form-group>
                </div>

                <div class="col-md-6">
                  <app-form-group *ngIf="handlingData.type === 'loading'" label="Inland terminal">
                    <app-search [(ngModel)]="handling.handlingData.inlandTerminal"
                                [resultFormatter]="utils.locationFormatter"
                                [searchFunction]="utils.findLocations"
                                dataKey="name"
                                minCharacters="2"
                                placeholder="Find by name or un code">
                    </app-search>
                  </app-form-group>

                  <app-form-group label="Rail service center">
                    <input [(ngModel)]="handling.handlingData.railServiceCenterCode" class="form-control"
                           placeholder="Enter code">
                  </app-form-group>

                  <app-form-group label="Final destination">
                    <app-search [(ngModel)]="handling.handlingData.finalDestination"
                                [resultFormatter]="utils.locationFormatter"
                                [searchFunction]="utils.findLocations"
                                dataKey="name"
                                minCharacters="2"
                                placeholder="Find by name or un code">
                    </app-search>
                  </app-form-group>
                </div>
              </div>

              <!--Window -->
              <div
                *ngIf=" !handling['new'] && handling.handlingData.modality === 'barge' && context.isHinterlandTerminal()"
                class="row">
                @if (!!handling.handlingStatus?.window && !!handling.handlingStatus?.window.start === !!handling.handlingStatus?.window?.end) {
                  <div class="declaration-block col-md-12">
                    <h2>Ultimate release date</h2>
                  </div>

                  <div class="col-md-6">
                    <app-form-group>
                      <label>Ultimate release date</label>
                      <app-date-time [readonly]="true"
                                     [ngModel]="handling.handlingStatus?.window?.end"></app-date-time>
                    </app-form-group>
                  </div>
                } @else {
                  <div class="declaration-block col-md-12">
                    <h2>{{ handling.type === 'loading' ? "Commercial window" : "Cargo opening/closing" }} </h2>
                  </div>

                  <div class="col-md-6">
                    <app-form-group>
                      <label>Window start</label>
                      <app-date-time [readonly]="true"
                                     [ngModel]="handling.handlingStatus?.window?.start"></app-date-time>
                    </app-form-group>
                  </div>

                  <div class="col-md-6">
                    <app-form-group>
                      <label>Window end</label>
                      <app-date-time [readonly]="true"
                                     [ngModel]="handling.handlingStatus?.window?.end"></app-date-time>
                    </app-form-group>
                  </div>
                }

              </div>

              <!-- Deep sea details -->
              <div *ngIf="deepSea" [ngClass]="{'disabled' :isUpdateForbidden()}" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Deep sea details</h2>
                </div>

                <div class="col-md-12">
                  <div *ngIf="!handling.new" class="row narrow-gutters">
                    <div class="mb-2 ms-auto col-md-auto">
                      <button (click)="updateDeepSea()" [disabled]="handling.handlingStatus?.deepSea"
                              class="btn btn-secondary btn-block"
                              style="min-width: 12rem;"
                              tabindex="-1"
                              type="button">
                        <span>Update Deep Sea</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <app-form-group>
                    <label>Ship name</label>
                    <app-search
                      (change)=seaVesselSelected();
                      *ngIf="!handling.handlingStatus?.deepSea; else readonlySeaVessel"
                      [(ngModel)]="deepSea"
                      [resultFormatter]="utils.terminalVisitFormatter"
                      [searchFunction]="utils.findTerminalVisits(handling.terminal)"
                      dataKey="shipName" placeholder="Sea Vessel">
                    </app-search>
                    <ng-template #readonlySeaVessel>
                      <input [ngModel]="deepSea.shipName" class="form-control" disabled>
                    </ng-template>
                  </app-form-group>

                  <app-form-group>
                    <label>Imo code</label>
                    <input [ngModel]="deepSea.imoCode" class="form-control" disabled>
                  </app-form-group>

                  <app-form-group>
                    <label>CRN number</label>
                    <input [ngModel]="deepSea.crnNumber" class="form-control" disabled>
                  </app-form-group>

                  <app-form-group>
                    <label>Voyage number</label>
                    <input [ngModel]="deepSea.voyageNumber" class="form-control" disabled>
                  </app-form-group>
                </div>

                <div class="col-md-6">
                  <app-form-group>
                    <label>ETA</label>
                    <app-date-time [ngModel]="deepSea.eta"
                                   [readonly]="true"></app-date-time>
                  </app-form-group>

                  <app-form-group>
                    <label>ETD</label>
                    <app-date-time [ngModel]="deepSea.etd" [readonly]="true"></app-date-time>
                  </app-form-group>

                  <app-form-group>
                    <label>Expected discharge time</label>
                    <app-date-time [ngModel]="deepSea.expectedDischargeTime"
                                   [readonly]="true"></app-date-time>
                  </app-form-group>

                  <app-form-group>
                    <label>Source</label>
                    <input [ngModel]="deepSea.source" class="form-control" disabled>
                  </app-form-group>

                  <app-form-group>
                    <label>Last updated</label>
                    <app-date-time [ngModel]="deepSea.lastUpdated" [readonly]="true"></app-date-time>
                  </app-form-group>
                </div>
              </div>

              <!--Integral planning handling details -->
              <div *ngIf="hasIntegralPlanning() && hasIntegralPlanningDetails()" class="row">
                <div class="declaration-block col-md-12">
                  <h2>Integral planning handling details</h2>
                </div>

                <div *ngIf="handling.handlingStatus.releasedForModality" class="col-md-6">
                  <app-form-group>
                    <label>Released for modality</label>
                    <input [ngModel]="handling.handlingStatus.releasedForModality" class="form-control" disabled>
                  </app-form-group>
                </div>

                <div *ngIf="handling.handlingStatus.closestBollard" class="col-md-6">
                  <app-form-group>
                    <label>Closest bollard</label>
                    <input [ngModel]="handling.handlingStatus.closestBollard.number" class="form-control" disabled>
                  </app-form-group>
                </div>

                <div *ngIf="handling.handlingStatus.masterBundleContainerNumber" class="col-md-6">
                  <app-form-group>
                    <label>Master bundle cont.nr.</label>
                    <input [ngModel]="handling.handlingStatus.masterBundleContainerNumber" class="form-control"
                           disabled>
                  </app-form-group>
                </div>
              </div>

              <div *ngIf="!handling.cancelled && !handling.completed && !readonly" class="row">
                <div class="col-md-12">
                  <div class="row narrow-gutters my-2">
                    <div [ngClass]="{'enabled': enableCancel()}" class="col-md-auto me-2 my-2 my-md-0">
                      <button (click)="handling['new'] ? deleteHandling.emit() : cancelHandling()"
                              [ngClass]="{'btn-secondary' : !!this.handling['new'], 'btn-danger' : !this.handling['new']}"
                              class="btn btn-block" style="min-width: 10rem;"
                              tabindex="-1"
                              type="button"><span>Cancel handling</span></button>
                    </div>
                    <div class="col-md-auto me-auto my-2 my-md-0">
                      <ng-container *ngIf="!!copyHandling && !this.handling['new']">
                        <ng-container *ngIf="allowedToAddToVisit(); else copyNoVisit">
                          <span class="dropdown mx-1">
                            <button class="btn btn-secondary dropdown-toggle py-2" data-bs-toggle="dropdown"
                                    style="min-width: 10rem" tabindex="-1" type="button">Copy handling
                            </button>
                            <ul class="dropdown-menu dropdown-menu-start" style="cursor: pointer">
                              <li (click)="copyHandling.emit({handling: handling, detached: false})"
                                  class="dropdown-item">
                                Add to visit
                              </li>
                              <li (click)="copyHandling.emit({handling: handling, detached: true})"
                                  class="dropdown-item">
                                As detached handling
                              </li>
                            </ul>
                          </span>
                        </ng-container>
                        <ng-template #copyNoVisit>
                          <button (click)="copyHandling.emit({handling: handling, detached: true})"
                                  class="btn btn-block btn-secondary" style="min-width: 10rem;" tabindex="-1"
                                  type="button"><span>Copy handling</span></button>
                        </ng-template>
                      </ng-container>
                    </div>
                    <div
                      *ngIf="'road' === handling.handlingData.modality && handling.preNotification != true && !handling.visitId && !this.handling['new']"
                      class="col-md-auto my-w my-md-0">
                      <button (click)="declareHandlingAsPrenotification()" [disabled]="isUpdateForbidden()"
                              class="btn btn-info btn-block" style="min-width: 10rem;"
                              tabindex="-1" type="button">
                        <span>Submit as pre-notification</span>
                      </button>
                    </div>
                    <div class="col-md-auto my-2 my-md-0">
                      <ng-container
                        *ngIf="handling.visitLoadDischargeListStatus == 'closed' && addEquipmentNumberAllowed(); else submitPreNotification">
                        <button (click)="addEquipmentNumber()" class="btn btn-info btn-block" style="min-width: 10rem;"
                                tabindex="-1" type="button">
                          <span>Add container number</span>
                        </button>
                      </ng-container>
                      <ng-template #submitPreNotification>
                        <button (click)="declareHandling()" [disabled]="isUpdateForbidden()"
                                class="btn btn-info btn-block" style="min-width: 10rem;"
                                tabindex="-1" type="button">
                          <span>Submit {{ handling.preNotification ? 'pre-notification' : 'status request' }}</span>
                        </button>
                      </ng-template>
                    </div>

                  </div>
                  <div *ngIf="terminalSelected()">
                    <ng-container *ngIf="!handling.preNotification; else preNotification">
                      <div
                        *ngIf="'barge' === handling.handlingData.modality && handling.terminal.bargeStatusRequestForbidden"
                        class="col-auto hint-box text-end px-0">Terminal does not support barge status requests.
                      </div>
                    </ng-container>
                    <ng-template #preNotification>
                      <div *ngIf="isUpdateForbidden()"
                           class="col-auto hint-box text-end px-0">
                        <span
                          *ngIf="handling.visitWaitForCloseVisitEnabled && (handling.visitLoadDischargeListStatus == 'reopened' || handling.visitLoadDischargeListStatus == 'sent') else updateForbidden">
                          Terminal does not support updates on this pre-notification when load-discharge list is sent or reopened.
                        </span>
                        <ng-template #updateForbidden>
                          Terminal does not support updates
                        </ng-template>
                      </div>
                      <div *ngIf="isUpdateByPassAllowed()"
                           class="col-auto hint-box text-end px-0">
                      </div>
                      <div
                        *ngIf="this.handling.visitWaitForCloseVisitEnabled && this.handling.visitLoadDischargeListStatus != 'closed' && !isUpdateForbidden()"
                        class="col-auto hint-box text-end px-0">
                        Note that this terminal will receive the load/discharge list upon finalization of the list.
                        Not before.
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div *ngIf="handling.cancelled && !handling.visitArrived && !readonly" class="row">
                <div class="col-md-12">
                  <div class="row narrow-gutters my-2">
                    <div *ngIf="!!copyHandling" [ngClass]="{'enabled': enableCancel()}"
                         class="col-md-auto me-2 my-2 my-md-0">
                      <button (click)="copyHandling.emit({handling: handling, detached: true})"
                              class="btn btn-block btn-secondary" style="min-width: 10rem;" tabindex="-1"
                              type="button"><span>Copy handling</span></button>
                    </div>
                    <div class="col-md-auto ms-auto my-2 my-md-0">
                      <button (click)="declareHandling()" class="btn btn-info btn-block" style="min-width: 10rem;"
                              tabindex="-1"
                              type="button">
                        <span>Resubmit {{ handling.preNotification ? 'pre-notification' : 'status request' }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li *ngIf="!handling.new && !appContext.isHistoricalView()" [ngbNavItem]="2">
          <a ngbNavLink>History</a>
          <ng-template ngbNavContent>
            <div class="p-3 border border-top-0 rounded-bottom">
              <div class="row">
                <div class="col-2">Handling ID</div>
                <div class="col-6 select-all">
                  {{ handling.handlingId }}
                  <span (click)=copyToClipboard(handling.handlingId) class="fal fa-copy ps-2"></span>
                </div>
              </div>
              <div class="row">
                <div class="declaration-block col-md-12">
                  <h2>Handling history</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <app-history-component [handling]="handling"></app-history-component>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li *ngIf="!handling.new && appContext.isAdmin() && !appContext.isHistoricalView() && !appContext.isPhone()"
            [ngbNavItem]="3">
          <a ngbNavLink>Messages</a>
          <ng-template ngbNavContent>
            <div class="p-3 border border-top-0 rounded-bottom always-enabled">
              <div class="row">
                <div class="col-2">Voyage</div>
                <div class="col-6"><a [href]="kibanaVoyageUrl" target="_blank">{{ handling.voyageId }}</a></div>
              </div>
              <div class="row">
                <div class="col-2">Visit</div>
                <div class="col-6"><a [href]="kibanaVisitUrl" target="_blank">{{ handling.visitId }}</a></div>
              </div>
              <div class="row">
                <div class="col-2">Handling</div>
                <div class="col-6"><a [href]="kibanaHandlingUrl" target="_blank">{{ handling.handlingId }}</a></div>
              </div>
              <div class="row">
                <div class="declaration-block col-md-12">
                  <h2>Messages</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <span *ngIf="!handling.terminal.apiOnly && !handling.terminal.nonPcs">
                    <app-message-log-component [handling]="handling"></app-message-log-component>
                  </span>
                  <span *ngIf="handling.terminal.apiOnly && !handling.terminal.nonPcs">No messages to show - This is a handling for a terminal that is only connected using the MCA API</span>
                  <span *ngIf="handling.terminal.nonPcs && !handling.terminal.apiOnly">No messages to show - This is a handling for a terminal that is not connected to Portbase</span>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
