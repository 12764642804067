import {Component} from '@angular/core';
import {AbstractOverviewComponent} from '../../common/abstract-overview.component';
import {CargoMovement, DateTimeRange, FindShipments, Shipment} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from '../../../app-context';
import {Observable} from 'rxjs';
import {lodash, replaceItem, sendQuery} from '../../../common/utils';
import {map} from 'rxjs/operators';
import {HinterlandUtils} from "../../hinterland-utils";
import {Modality, Terminal} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Component({
  selector: 'app-arrivals-overview',
  templateUrl: './arrivals-overview.component.html',
  styleUrls: ['./arrivals-overview.component.scss']
})
export class ArrivalsOverviewComponent extends AbstractOverviewComponent<Arrival> {

  appContext = AppContext;
  pagingSupported: boolean = AppContext.isAdmin();
  originalItems: Shipment[];
  modalityFilter: Modality | "all" = "all";
  terminalFilter: Terminal;

  searchOrFilter = (): (Arrival[] | Observable<Arrival[]>) => {
    return this.reload();
  };

  doLoad(dateTimeRange: DateTimeRange): Observable<(Arrival)[]> {
    return sendQuery('com.portbase.hinterland.api.shipment.query.FindShipments',
      <FindShipments>{dateTimeRange: dateTimeRange, excludeVisits: true, term: this.filterTerm},
      {caching: false, showSpinner: !this.unsubscribeHandle})
      .pipe(map((results: Shipment[]) => {
        this.originalItems = results;
        return convertCargoShipments(results);
      }));
  }

  doRender = (arrivals: Arrival[]) => {
    this.items = arrivals;
  }

  trackByArrivalId(index: number, arrival: Arrival) {
    return getArrivalId(arrival);
  }

  asArrivals = (items): Arrival[] => <Arrival[]>items;

  getArrivalTime(arrival: Arrival): string {
    const stop = arrival.movement && lodash.last(arrival.movement.itinerary);
    return stop && (stop.ata || stop.eta);
  }

  public filterItems() {
    let filteredItems = convertCargoShipments(this.originalItems);
    if (this.modalityFilter != "all") {
      filteredItems = filteredItems.filter(arrival => arrival.cargoShipments.map(shipment => this.determineModality(shipment)).includes(<Modality>this.modalityFilter));
    }
    if(!!this.terminalFilter) {
      filteredItems = filteredItems.filter(arrival => arrival.cargoShipments.map(shipment => shipment.data.deepSeaTerminal?.shortName || '').includes(this.terminalFilter.shortName));
    }
    return filteredItems;
  }

  determineModality = (shipment: Shipment) : Modality => shipment.movements[1]?.vessel?.modality || shipment.data.inlandModality;

  modalityFilterOrRoad = () => this.modalityFilter == "all" ?  "road" : this.modalityFilter;

  'onUpdateShipment' = (update) => {
    const shipment = <Shipment>update.value;
    if (update.newAggregate) {
      this.originalItems.splice(0, 0, shipment);
      this.rerenderOriginalItems();
    } else {
      const old = this.originalItems.find(v => v.id === update.id);
      if (old) {
        replaceItem(this.originalItems, old, shipment);
        this.rerenderOriginalItems();
      }
    }
  };

  rerenderOriginalItems() {
    this.items = convertCargoShipments(this.originalItems);
    this.renderFilteredItems();
  }

    protected readonly utils = HinterlandUtils;
}

function convertCargoShipments(cargoShipments: Shipment[]): Arrival[] {
  const grouped = lodash.groupBy(cargoShipments, c => groupId(c));
  return lodash.values(grouped).map(section =>
    <Arrival>{
      movement: section[0].movements[0],
      cargoShipments: section
    });

  function groupId(c: Shipment): string {
    const a = c.data;
    const seaVoyage = c.movements[0].portbaseVoyageId || a.deepSeaVoyageNumber + a.deepSeaVesselImo + a.deepSeaVesselName;
    const terminal = (c.movements[0].portbaseVoyageId && lodash.last(c.movements[0].itinerary).portbaseId)
      || a.deepSeaEta + (a.deepSeaTerminal?.bicsCode || a.deepSeaTerminal?.smdgCode || "unknown");
    return seaVoyage + '___' + terminal;
  }
}

function getArrivalId(arrival : Arrival){
  const a = arrival.cargoShipments.length == 0 ? null : arrival.cargoShipments[0].data;
  return arrival.movement.portbaseVoyageId || (a == null ? null :
    a.deepSeaVoyageNumber + a.deepSeaVesselImo + a.deepSeaVesselName);
}

export interface Arrival {
  movement: CargoMovement,
  cargoShipments: Shipment[]
}
