<app-tooltip *ngIf="movementsWithStatus.length > 0" [disabled]="errorMovements.length === 0" class="formGroupLabel" [wide]="movementsWithStatus.length === 1">

  <div class="tooltipContent text-start position-relative">

    <ng-container *ngIf="movementsWithStatus.length > 1 else single">
      <div *ngFor="let movement of errorMovements.slice(0, 5)" class="row align-items-center">
        <div class="col">
          <ng-container>{{movement.item.containerNumber}}</ng-container>
        </div>
        <div *ngIf="movement.pickupStatus.status == 'REJECTED'; else accepted" class="col">
          <span class="position-absolute">
          <span class="fa fa-stack-1x fa-circle text-white"></span>
          <span class="fa fa-stack-1x fa-times-circle text-danger"></span>
          </span> <span class="ms-4"> Rejected</span>
        </div>
        <ng-template #accepted>
          <div class="col">
            <span *ngFor="let status of mapStatuses(movement); let last = last;" [ngClass]="[status.icon, status.class, last?'':'me-3']" class="fa"></span>
          </div>
        </ng-template>
      </div>
      <div *ngIf="errorMovements.length > 5" class="mt-1" style="margin-left: 20px">{{errorMovements.length - 5}} more</div>
    </ng-container>

    <ng-template #single>
      <div class="text-start font-italic" *ngIf="errorMovements[0]?.pickupStatus?.status === 'REJECTED'">
        <div class="mt-2">Reject remarks:</div>
        <ul class="py-0 my-0 ">
          <li *ngFor="let reason of errorMovements[0]?.pickupStatus?.rejectReasons || []">{{reason.parsedReason}}</li>
          <li *ngIf="!errorMovements[0]?.pickupStatus?.rejectReasons?.length>0">No terminal remarks</li>
        </ul>
      </div>
      <div class="row py-2"
           *ngIf="errorMovements[0]?.pickupStatus?.acceptStatuses?.length > 0">
        <div *ngFor="let status of mapStatuses(errorMovements[0])" [ngClass]="appContext.isPhone()? 'col-12' : 'col-6'">
          <div class="row g-0 justify-content-between">
            <span class="col-auto">{{status.label}}</span>
            <span class="col-auto">
        <span class="fa" [ngClass]="{'fa-times-circle text-danger' : status.ok === false, 'fa-check-circle text-success' : status.ok, 'fa-question-circle text-secondary' : status.ok === null}"></span>
      </span>
          </div>
          <ul class="py-0 my-0 ps-3 text-start font-italic">
            <ng-container *ngFor="let alert of status.alerts">
              <li *ngIf="alert.code != null || alert.remark != null">
                <span>{{alert.code}}</span>
                <span *ngIf="alert.remark">: {{alert.remark}}</span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-template>
  </div>

  <span *ngIf="errorMovements.length === 0; else hasErrors" class="ms-1 fa fa-check-circle text-success" title="Accepted by terminal"></span>

  <ng-template #hasErrors>
    <span class="text-danger"><span class="ms-1 fa fa-fw fa-exclamation-triangle"></span> {{errorMovements.length}}</span>
  </ng-template>

</app-tooltip>

<span *ngIf="preNotified" class="ms-2 fa-fw fa fa-envelope text-dark" title="Containers have been pre-notified to terminal"></span>
