import { Component, Input } from '@angular/core';
import { sendCommand } from "../../../../common/utils";
import { CancelDetachedHandling } from "@portbase/hinterland-service-typescriptmodels";
import { AppContext } from "../../../../app-context";
import { EditModalService } from '../../../../components/modals/edit-modal/edit-modal.service';
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";

@Component({
  selector: 'app-detach-handling-confirmation',
  templateUrl: './detach-handling-confirmation.component.html',
  styleUrls: ['./detach-handling-confirmation.component.scss']
})
export class DetachHandlingConfirmationComponent {

  @Input() data: ClosableModal;

    constructor(
        private readonly editModalService: EditModalService,
    ) {}

  detachHandling() {
    sendCommand("com.portbase.hinterland.api.common.command.DetachHandling",
      <CancelDetachedHandling>{
        voyageId: this.data['voyageId'],
        visitId: this.data['visitId'],
        handlingId: this.data['handlingId']
      },
      () => {
        AppContext.publishSuccessMessage('Handling detached.');
        this.editModalService.closeModal();
      },
      error => AppContext.publishErrorMessage(error)
    );
  }
}
