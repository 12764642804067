import {Component, OnInit} from "@angular/core";
import {AppContext} from "../../app-context";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    appContext = AppContext;

    ngOnInit(): void {

    }
}
