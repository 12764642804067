<div class="declaration-block">
  <h2>Shipments</h2>
</div>

<div class="row narrow-gutters my-2">
  <div *ngIf="shipments?.length > 1" class="col-auto">
    <label class="mt-2">
      <input (click)="toggleSelectAll()" [disabled]="!!actionInProgress"
             [ngModel]="shipments?.length === selectedItems.length" class="always-enabled" type="checkbox">
      <span class="visually-hidden">Select all</span>
    </label>
  </div>
  <div class="col-md-4 mb-1 mb-md-0" style="min-width: 19rem">
    <app-date-range (ngModelChange)="onDateSelection($event)" [ngModel]="dateRange"></app-date-range>
  </div>
  <div class="col-md-8 col-lg mb-1 mb-md-0">
    <app-filter (resultsFound)="renderFilteredItems()" (filterPasted)="checkForSeparator($event)" [(ngModel)]="filterTerm"
                [searchFunction]="searchOrFilter"
                [maxCharacters]="maxFilterLength"
                placeholder="Filter by consignment nr, container nr, etc..."></app-filter>
  </div>
  <div class="col-md-3 col-lg-1 mb-1 mb-md-0">
    <select [(ngModel)]="modalityFilter" (ngModelChange)="this.renderFilteredItems()" class="clickable font-awesome rounded form-select">
      <option value="all" class="font-awesome">&#xf0d1;&#xf21a;&#xf238;</option>
      <option value="road" class="font-awesome">&#xf0d1;</option>
      <option value="barge" class="font-awesome">&#xf21a;</option>
      <option value="rail" class="font-awesome">&#xf238;</option>
    </select>
  </div>
  <div class="col-md-9 col-lg-3 mb-1 mb-md-0">
    <app-select [(ngModel)]="terminalFilter"
                (ngModelChange)="this.renderFilteredItems()"
                [dataKey]="modalityFilter === 'rail' ? 'shortName' : 'quayName'"
                placeholder="Filter by terminal"
                [optionsProvider]="utils.getTerminals(modalityFilterOrRoad(), true, true, true)"
                [refreshWatch]="modalityFilter"
                optionLabel="displayName"
                optionSelectable="shortName" required>
    </app-select>
  </div>
</div>

<div class="mt-4">
  <app-shipments *ngIf="initialized" [items]="shipments" (actionInProgress)="updateActionState($event)"></app-shipments>
</div>
