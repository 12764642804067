<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="railCarType['id']" disabled required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Custom id</label><input class="form-control" [(ngModel)]="railCarType.customId" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="railCarType.name" required></app-form-group>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Maximum number of 20s per platform</label><input class="form-control" [(ngModel)]="railCarType.max20sPerPlatform" appDecimalNumber [maxDecimals]="0" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Maximum platforms</label><input class="form-control" [(ngModel)]="railCarType.maxPlatforms" appDecimalNumber [maxDecimals]="0" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Slots per wagon</label><input class="form-control" [(ngModel)]="railCarType.slotsPerWagon" appDecimalNumber [maxDecimals]="0" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Safe weight (KG)</label><input class="form-control" [(ngModel)]="railCarType.safeWeightKg" appDecimalNumber [maxDecimals]="0"></app-form-group>
  </div>
</div>
