import moment from 'moment';
import {Observable, timer} from 'rxjs';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {EventGateway} from '../../common/event-gateway';
import {filterByTerm, getId, makeSearchable} from '../../common/utils';
import {map, take} from 'rxjs/operators';
import {AcceptStatusType, DateTimeRange} from '@portbase/hinterland-service-typescriptmodels';
import {InjectorProvider} from '../../common/injector-provider';
import {ActivatedRoute} from '@angular/router';
import {HandlingModel} from "../hinterland-utils";
import {AppContext} from "../../app-context";
import {FilterValues} from "../../common/search/dropdown-toggle-filter/dropdown-toggle-filter.component";
import {DateRange} from '@portbase/hinterland-service-typescriptmodels/hinterland';

@Directive()
export abstract class AbstractOverviewComponent<T> implements OnInit, OnDestroy {

  // Configuration
  maxItems: number = 100;
  excludedFilterFields: string[] = [];
  localStorageKey: string = 'overview';
  pagingSupported: boolean = false;
  static loadingStatusTypes: AcceptStatusType[] = ["BLOCKED", "CUSTOMS_DOCUMENT", "AVAILABLE", "COMMERCIAL_RELEASE"];
  static dischargeStatusTypes: AcceptStatusType[] = ["BLOCKED", "CUSTOMS_DOCUMENT", "CARGO_OPENING", "BOOKING_APPROVED"];

  // State
  items: T[] = [];
  searchableItems = {};
  from: number = 0;
  endReached: boolean;
  initialized: boolean;
  loading: boolean;
  dateRange = this.getDateRange();
  showDateRangeWarning: boolean = false;
  dateRangeWarningTimer;
  filterTerm = '';
  unsubscribeHandle;

  ngOnInit(): void {
    AppContext.persistedFilterValuesChanged.subscribe(() => this.renderFilteredItems());
    InjectorProvider.injector.get(ActivatedRoute).queryParams.subscribe(queryParams => {
      this.filterTerm = queryParams['filterTerm'] || '';
      this.loadAndRender();
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribeHandle) {
      this.unsubscribeHandle();
    }
  }

  /*
    Loading and rendering
   */

  abstract doLoad(dateTimeRange: DateTimeRange): Observable<T[]>;

  abstract doRender(entries: T[]);

  renderFilteredItems = () => this.doRender(this.getFilteredItems());

  loadAndRender = (reset = false, showSpinner = false): void => {
    if (showSpinner) {
      AppContext.pendingProcesses.push('abstractOverviewLoadAndRender');
    }

    (reset ? this.reload() : this.load()).subscribe((entries: T[]) => {
      this.renderFilteredItems();
      if (!this.unsubscribeHandle) {
        const eventGateway = InjectorProvider.injector.get(EventGateway);
        this.unsubscribeHandle = eventGateway.registerLocalHandler(this);
        this.initialized = true;
      }

      if (showSpinner) {
        AppContext.pendingProcesses.splice(AppContext.pendingProcesses.indexOf('abstractOverviewLoadAndRender'), 1);
      }
    });
  };

  loadAndRenderNextPage = () => {
    if (this.pagingSupported && !this.endReached && !this.loading) {
      this.from += this.maxItems;
      this.loadAndRender();
    }
  };

  reload = (): Observable<T[]> => {
    this.from = 0;
    this.endReached = false;
    return this.load();
  };

  private load = (): Observable<T[]> => {
    this.loading = true;
    return this.doLoad(<DateTimeRange>{
      start: moment(this.dateRange.start).toISOString(),
      end: moment(this.dateRange.end).add(1, 'days').toISOString()
    }).pipe(map(entries => {
      if (entries.length < this.maxItems) {
        this.endReached = true;
      }
      if (this.from > 0) {
        entries = this.items.concat(entries);
      }
      this.loading = false;

      this.items.filter(handling => (handling as unknown as HandlingModel).new).forEach(handling => {
        if (entries.find(entry => (entry as unknown as HandlingModel).handlingId == (handling as unknown as HandlingModel).handlingId) == null) {
          entries.splice(0, 0, handling);
        }
      })

      this.items = entries;
      if (!this.pagingSupported) {
        this.generateSearchableItems();
      }
      return entries;
    }));
  };

  /*
    Filtering
   */

  getFilteredItems = (): T[] => this.filterItems();

  isHandlingFiltered(handling: HandlingModel, handlingFilters: FilterValues): boolean {
    if (handling.new || !handlingFilters) {
      return true;
    }

    let filtered = true;
    for (const selectedValue of handlingFilters.selected) {
      if (selectedValue === 'Loading' && handling.handlingData.type !== 'loading') {filtered = false; break;}
      if (selectedValue === 'Discharge' && handling.handlingData.type !== 'discharge') {filtered = false; break;}
      if (selectedValue === 'Rejected' && handling.status !== 'rejected') {filtered = false; break;}
      if (selectedValue === 'Cancelled' && handling.status !== 'cancelled') {filtered = false; break;}
      if (selectedValue === 'Blocked' && !this.containsAcceptStatus(handling, 'BLOCKED')) {filtered = false; break;}
      if (selectedValue === 'Documents Missing' && !this.containsAcceptStatus(handling, 'CUSTOMS_DOCUMENT')) {filtered = false; break;}
      if (selectedValue === 'Cargo Opening' && !this.containsAcceptStatus(handling, 'CARGO_OPENING')) {filtered = false; break;}
      if (selectedValue === 'Acceptance Reference' && !this.containsAcceptStatus(handling, 'BOOKING_APPROVED')) {filtered = false; break;}
      if (selectedValue === 'Commercial Release' && !this.containsAcceptStatus(handling, 'COMMERCIAL_RELEASE')) {filtered = false; break;}
      if (selectedValue === 'Container Present' && !this.containsAcceptStatus(handling, 'AVAILABLE')) {filtered = false; break;}
      if (selectedValue === 'Other Issue' && !this.containsOtherAcceptStatuses(handling)) {filtered = false;}
    }

    if (filtered) {
      for (const deselectedValue of handlingFilters.deselected) {
        if (deselectedValue === 'Loading' && handling.handlingData.type === 'loading') {filtered = false; break;}
        if (deselectedValue === 'Discharge' && handling.handlingData.type === 'discharge') {filtered = false; break;}
        if (deselectedValue === 'Rejected' && handling.status === 'rejected') {filtered = false; break;}
        if (deselectedValue === 'Cancelled' && handling.status === 'cancelled') {filtered = false; break;}
        if (deselectedValue === 'Blocked' && this.containsAcceptStatus(handling, 'BLOCKED')) {filtered = false; break;}
        if (deselectedValue === 'Documents Missing' && this.containsAcceptStatus(handling, 'CUSTOMS_DOCUMENT')) {filtered = false; break;}
        if (deselectedValue === 'Cargo Opening' && this.containsAcceptStatus(handling, 'CARGO_OPENING')) {filtered = false; break;}
        if (deselectedValue === 'Acceptance Reference' && this.containsAcceptStatus(handling, 'BOOKING_APPROVED')) {filtered = false; break;}
        if (deselectedValue === 'Commercial Release' && this.containsAcceptStatus(handling, 'COMMERCIAL_RELEASE')) {filtered = false; break;}
        if (deselectedValue === 'Container Present' && this.containsAcceptStatus(handling, 'AVAILABLE')) {filtered = false; break;}
        if (deselectedValue === 'Other Issue' && this.containsOtherAcceptStatuses(handling)) {filtered = false;}
      }
    }

    return filtered;
  }

  containsAcceptStatus(handling: HandlingModel, type: AcceptStatusType): boolean {
    return handling.handlingDeclaration && handling.handlingDeclaration.acceptStatuses
      && handling.handlingDeclaration.acceptStatuses.some(status => status.type === type && status.ok === false);
  }

  containsOtherAcceptStatuses(handling: HandlingModel): boolean {
    const types = handling.handlingData.type === 'loading' ? AbstractOverviewComponent.loadingStatusTypes : AbstractOverviewComponent.dischargeStatusTypes;
    return handling.handlingDeclaration && handling.handlingDeclaration.acceptStatuses
      && handling.handlingDeclaration.acceptStatuses.some(status => types.indexOf(status.type) < 0 && status.ok !== true && status.code !== null);
  }

  public generateSearchableItems() {
    this.items.forEach((item) => this.searchableItems[getId(item)] = this.searchableItems[getId(item)] || makeSearchable(item, this.excludedFilterFields))
  }

  public filterItems() {
    let filteredItems = this.items;
    if (this.filterTerm) {
      this.generateSearchableItems();
      filteredItems = filteredItems.filter(filterByTerm(this.filterTerm, this.excludedFilterFields, this.searchableItems));
    }
    return filteredItems;
  }

  onDateSelection = (dateRange: DateRange): void => {
    if (dateRange) {
      const now = moment().startOf('day');
      let daysBefore = now.diff(moment(dateRange.start), 'days')
      let daysAfter = moment(dateRange.end).diff(now, 'days');
      if (daysBefore + daysAfter > 14) {
        this.setTimedWarning();
      }
      this.dateRange = dateRange;
      this.from = 0;
      this.endReached = false;
      this.loadAndRender();

      if (this.localStorageKey) {
        localStorage.setItem(this.localStorageKey, JSON.stringify({
          daysBefore: daysBefore,
          daysAfter: daysAfter
        }));
      }
    }
  };

  private setTimedWarning() {
    this.showDateRangeWarning = true;
    this.dateRangeWarningTimer = timer(5000);
    this.dateRangeWarningTimer.pipe(take(1)).subscribe(() => {
      this.showDateRangeWarning = false;
    });
  }

  private getDateRange(): DateRange {
    const preference: OverviewPreference = this.localStorageKey && JSON.parse(localStorage.getItem(this.localStorageKey));
    return preference ? {
      start: moment().startOf('day').subtract(preference.daysBefore, 'd').format('YYYY-MM-DD'),
      end: moment().startOf('day').add(preference.daysAfter, 'd').format('YYYY-MM-DD')
    } : {
      start: moment().startOf('day').subtract(1, 'd').format('YYYY-MM-DD'),
      end: moment().startOf('day').add(10, 'd').format('YYYY-MM-DD')
    };
  }
}

export interface OverviewPreference {
  daysBefore: number;
  daysAfter: number;
}
