<nav class="secondary-menu h-100 prevent-select" [ngClass]="showNavbarAsFooter ? 'footer-mode' : ''">
  <div class="sidebar-sticky h-100">
    <div class="nav-item-header d-flex justify-content-between align-items-center ps-4">
      QUICK FILTERS
    </div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a (click)="onQuickFilterChanged('all')" [ngClass]="quickFilterType === 'all' ? 'active' : ''" class="nav-link cursor-pointer">
          <span class="nav-icon"><i class="fal pb-cargo-crane p-2"></i></span>
          <span class="nav-text sidebar-mode">All handlings</span>
          <div class="nav-text footer-mode">All</div>
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onQuickFilterChanged('todo')" [ngClass]="quickFilterType === 'todo' ? 'active' : ''" class="nav-link cursor-pointer">
          <span class="nav-icon"><i class="fal fa-list-check p-2"></i></span>
          <span class="nav-text sidebar-mode">To do</span>
          <div class="nav-text footer-mode text-nowrap">To do</div>
        </a>
      </li>
      <li class="nav-item">
        <a (click)="onQuickFilterChanged('statusRequests')" [ngClass]="quickFilterType === 'statusRequests' ? 'active' : ''" class="nav-link cursor-pointer">
          <span class="nav-icon"><i class="fal fa-window-restore p-2"></i></span>
          <span class="nav-text sidebar-mode">Status requests</span>
          <div class="nav-text footer-mode">Requests</div>
        </a>
      </li>
      <li class="nav-item sidebar-mode">
        <a (click)="onQuickFilterChanged('notReady')" [ngClass]="quickFilterType === 'notReady' ? 'active' : ''" class="nav-link cursor-pointer">
          <span class="nav-icon"><i class="fal fa-times-circle p-2"></i></span>
          <span class="nav-text">Not ready</span>
        </a>
      </li>
      <li class="nav-item sidebar-mode">
        <a (click)="onQuickFilterChanged('completed')" [ngClass]="quickFilterType === 'completed' ? 'active' : ''" class="nav-link cursor-pointer">
          <span class="nav-icon"><i class="fal fa-check-to-slot p-2"></i></span>
          <span class="nav-text">Completed</span>
        </a>
      </li>
      <li class="nav-item footer-mode">
        <a (click)="this.toggleQuickfilters.emit();" class="nav-link cursor-pointer" [ngClass]="quickFilterType === 'notReady' || quickFilterType === 'completed' ? 'active' : ''">
          <span class="nav-icon"><i class="fal fa-chevron-up p-2"></i></span>
          <div class="nav-text">Filters<span class="filter-counter" *ngIf="activeFilters"> ({{this.activeFilters}})</span></div>
        </a>
      </li>
    </ul>

    <div class="nav-item-header d-flex justify-content-between align-items-center ps-4 pt-4 filters sidebar-mode">
      FILTERS
    </div>
    <ul class="nav flex-column filters sidebar-mode">
      <li class="nav-item">
        @if (!dateRange) {
          <a class="grid-container nav-link cursor-pointer">
            <span (click)="openTimeslotEtaFilter()" class="grid-text">Date</span>
            <span (click)="openTimeslotEtaFilter()" class="grid-icon fal fa-plus"></span>
          </a>
        } @else {
          <a class="grid-container nav-link cursor-pointer active">
            <span (click)="openTimeslotEtaFilter()" class="grid-text">Date</span>
            <span (click)="clearTimeslotEtaFilter()" class="grid-icon fal fa-xmark"></span>
            <span (click)="openTimeslotEtaFilter()" class="grid-subtext">
              {{ format(dateRange.start) }} - {{ format(dateRange.end) }}
            </span>
          </a>
        }
      </li>
    </ul>

    <div aria-label="Close" class="close-button sidebar-mode d-md-none clickable" (click)="this.toggleQuickfilters.emit()">
      <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
    </div>
  </div>
</nav>
