<h3 class="py-2">Export reference data</h3>

<div class="row">
  <div class="col">
    <app-select
      [(ngModel)]="type"
      [options]="['refdata_barge', 'refdata_bollard', 'refdata_carrier', 'refdata_container', 'refdata_fixedwindow',
                  'refdata_charter', 'refdata_location', 'refdata_port', 'refdata_sizetype', 'refdata_timetableentry',
                  'organisations']">
    </app-select>
  </div>
  <div class="col">
    <a *ngIf="!!type" [href]="'/api/refdata/flux-export?type=' + type" class="btn btn-outline-info">Download</a>
  </div>
</div>
