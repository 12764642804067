import {Component, OnDestroy, OnInit} from "@angular/core";
import {AppContext} from "../../app-context";
import {Application, GetApplication, ToggleIntegralPlanning} from "@portbase/hinterland-service-typescriptmodels";
import {sendCommand, sendQuery} from "../../common/utils";
import {Subscription} from "rxjs";

@Component({
    selector: 'application-settings',
    templateUrl: './application-settings.component.html',
    styleUrls: ['./application-settings.component.css']
})
export class ApplicationSettingsComponent implements OnInit, OnDestroy {
    appContext = AppContext;
    applicationSettings: Application;
    sub: Subscription;
    ngOnInit() {
        this.sub = sendQuery("com.portbase.common.api.application.query.GetApplication", <GetApplication>{})
            .subscribe(result => {
                this.applicationSettings = result;
            });
    }

    toggleIntegralPlanning() {
        sendCommand('com.portbase.common.api.application.command.ToggleIntegralPlanning', <ToggleIntegralPlanning>{
            integralPlanningEnabled: !this.applicationSettings.integralPlanningEnabled
        }, () => {
            AppContext.registerSuccess('Integral planning settings updated successfully');
            this.applicationSettings.integralPlanningEnabled = !this.applicationSettings.integralPlanningEnabled;
            this.appContext.application = this.applicationSettings;
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
