<div *ngIf="!(integralPlanningRequested && terminal.nextlogicParticipant) && !terminalPlanningHidden"
     [class.extra-date-time]="terminalPlanningMuted">
  <span class="fa-stack fa-1x mt-n1 me-2" style="font-size:1em;width:1em" title="ETA and ETD as planned by terminal"><i class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white" style="font-size:0.8em">T</strong></span>
  <app-formatted-date-range [end]="terminalEtd"
                            [muted]="terminalPlanningMuted"
                            [start]="terminalEta">
  </app-formatted-date-range>
</div>

<div *ngIf="(integralPlanningRequested && terminal.nextlogicParticipant) && !!integralPlanningStatus && !!integralPlanningStatus['pta']"
     [class.extra-date-time]="integralPlanningMuted">
  <span class="fa fa-stack fa-1x fa-ellipsis-v mt-n1 me-2" style="font-size:1em;width:1em" title="ETA and ETD as planned by integral planning"></span>
  <app-formatted-date-range [end]="integralPlanningStatus['ptd']"
                            [muted]="integralPlanningMuted"
                            [start]="integralPlanningStatus['pta']">
  </app-formatted-date-range>
</div>

<div *ngIf="!!requestedVisitData && !requestedPlanningHidden" [class.extra-date-time]="requestedPlanningMuted">
  <span *ngIf="isModalityBarge" class="fa fa-1x fa-ship modality me-2" style="font-size:1em;width:1em" title="ETA and ETD as requested by barge operator"></span>
  <span *ngIf="isModalityRail" class="fa-stack fa-1x mt-n1 me-2" style="font-size:1em;width:1em" title="ETA and ETD as requested by rail operator"><i class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white" style="font-size:0.8em">O</strong></span>
  <span *ngIf="isModalityRoad" class="fa fa-1x fa-truck modality me-2" style="font-size:1em;width:1em" title="ETA and ETD as requested by road operator"></span>
  <app-formatted-date-range *ngIf="!!requestedVisitData"
                            [end]="requestedVisitData['etd']"
                            [muted]="requestedPlanningMuted"
                            [start]="requestedVisitData['eta']">
  </app-formatted-date-range>
  <span class="ms-1 fa fa-exclamation-circle text-warning" *ngIf="showDeviatingTimeSlotWarning" title="Deviates from slot time"></span>
</div>

<div *ngIf="!requestedVisitData">
  <span *ngIf="isModalityBarge" class="fa fa-1x fa-ship modality me-2" style="font-size:1em;width:1em" title="ETA and ETD as requested by barge operator"></span>
  <span *ngIf="isModalityRail" class="fa-stack fa-1x mt-n1 me-2" style="font-size:1em;width:1em" title="ETA and ETD as requested by rail operator"><i class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white" style="font-size:0.8em">O</strong></span>
  <span *ngIf="isModalityRoad" class="fa fa-1x fa-truck modality me-2" style="font-size:1em;width:1em" title="ETA and ETD as requested by road operator"></span>
  <app-formatted-date-range *ngIf="!requestedVisitData && !!visitData"
                            [end]="visitData['etd']"
                            [start]="visitData['eta']">
  </app-formatted-date-range>
</div>


