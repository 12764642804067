<app-tooltip class="formGroupLabel" [wide]="true" placement="bottom left" [disabled]="shouldHideTooltip()" [keepOpen]="keepTooltipOpen">
  <ng-container [ngSwitch]="status">
    <span class="me-2">
      <span *ngIf="shouldShowWarningIcon()" class="fa me-3 fa-exclamation-triangle text-warning"></span>
      <ng-container *ngSwitchCase="'declared'">
        <span
          [attr.title]="(this.handling.visitNeverAcknowledged && !this.terminal.apiOnly) ? 'Will be sent when the terminal processes the visit' : null"
          class="fa fa-paper-plane text-info"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'stuck'">
        <span class="fa fa-paper-plane text-danger"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'accepted'">
        <span class="fa fa-check-circle text-success"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'acceptedWithWarnings'">
        <span class="d-none d-lg-inline">
            <span *ngFor="let status of acceptStatuses" style="cursor: pointer" class="fa me-3" [ngClass]="[status.icon, status.class]" (click)="selectAcceptStatus(status);$event.stopPropagation()"></span>
        </span>
        <span *ngIf="!handling['waitingForCloseVisit']" class="fa fa-exclamation-triangle text-warning d-lg-none"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'rejected'">
        <span class="fa fa-times-circle text-danger"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'inOperation'">
        <span class="fas fa-cog fa-spin text-secondary"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'handled'">
        <span class="fa fa-check text-secondary"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'cancelled'">
        <span class="fa fa-times text-secondary"></span>
      </ng-container>
      <ng-container *ngSwitchCase="'unknown'">
        <span title="Note that this terminal is not connected to Portbase and will not be notified of your {{handling.preNotification ? 'pre-notification' : 'status request'}}" class="fa fa-check-circle text-secondary"></span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span class="fa fa-times-circle invisible"></span>
      </ng-container>
    </span>

    <span [ngClass]="displayReason ? '' : 'd-none'" class="d-lg-inline">
      <ng-container *ngSwitchCase="'declared'">
        <span
          [attr.title]="(this.handling.visitNeverAcknowledged && !this.terminal.apiOnly) ? 'Will be sent when the terminal processes the visit' : null"
        >{{handling.preNotification ? 'Pre-notification' : 'Status request'}} sent</span>
      </ng-container>
      <ng-container *ngSwitchCase="'stuck'">
        <span title="No response received from the terminal for more than 1 hour">{{handling.preNotification ? 'Pre-notification' : 'Status request'}} not confirmed</span>
      </ng-container>
      <ng-container *ngSwitchCase="'accepted'">
        Accepted
      </ng-container>
      <ng-container *ngIf="displayReason">
        <ng-container *ngSwitchCase="'acceptedWithWarnings'">
          Accepted with warnings
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'rejected'">
        Rejected
      </ng-container>
      <ng-container *ngSwitchCase="'inOperation'">
        In Operation
      </ng-container>
      <ng-container *ngSwitchCase="'handled'">
        {{handling.extra ? 'Extra ' : ''}}{{(handling.handlingData.type === 'loading' ? 'loaded' : 'discharged') | titlecase}}
      </ng-container>
      <ng-container *ngSwitchCase="'cancelled'">
        Cancelled
      </ng-container>
      <ng-container *ngSwitchCase="'unknown'">
        <span title="Note that this terminal is not connected to Portbase and will not be notified of your {{handling.preNotification ? 'Pre-notification' : 'Status request'}}"></span>
      </ng-container>
    </span>

    <span style="white-space: nowrap" class="text-secondary small d-none d-lg-inline" *ngIf="!!handling.waitingRoomActive && !terminal.nonPcs && !terminal.apiOnly && appContext.isAdmin()">
      <ng-container>
         (updates in waiting room)
      </ng-container>
    </span>
  </ng-container>

  <ng-container *ngIf="!hideTooltip" class="tooltipContent">
    <app-handling-status-summary [handling]="handling" [status]="status" [acceptStatuses]="acceptStatuses"></app-handling-status-summary>
  </ng-container>
</app-tooltip>
