<div class="handling-panel remain-on-truck-panel">
  <ng-container>
    <div [class]="actionMode == 'attachToVisitMode' ? 'grid-attach-to-visit-mode'
    :  actionMode == 'addHandlingToVisitMode' ? 'grid-add-handling-to-visit-mode'
    : 'panel-grid'" class="g-0 handling-panel-grid">
      <div class="hide-md-screen" *ngIf="!actionMode">&nbsp;</div>
      <div>
        <div class="panel-icon">
          <span class="fa-light fa-fw fa-1x fa-lock-keyhole remain-on-truck-icon"></span>
        </div>
      </div>
      <div class="col p-0 truncate">
        <div class="text-truncate select-all">{{containerNumber}}</div>
        <div class="sub-text text-truncate">
          <span>Remain on truck</span>
        </div>
      </div>

    </div>
  </ng-container>
</div>
