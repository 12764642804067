<div class="offcanvas-panel">

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i class="overlay-icon fa-regular fa-xmark"
                                                     data-bs-dismiss="modal"></i></div>
      <div
        class="d-flex centered select-all">{{
          (handling.handlingData.equipmentNumber) ? handling.handlingData.equipmentNumber
            : handling.handlingStatus?.equipmentNumber ? handling.handlingStatus?.equipmentNumber : 'Details'
        }}
      </div>
      <div class="d-flex flex-fill"></div>
      <div *ngIf="showAdminFunctions" class="d-flex me-2 hide-md-screen">
        <button aria-expanded="false" class="btn btn-secondary" data-bs-toggle="dropdown" type="button">Admin actions<i
          class="fal fa-angle-down ms-1"></i></button>
        <div class="dropdown-menu dropdown-menu-end">
          <a (click)="acceptHandling()" class="dropdown-item">Accept container</a>
          <a (click)="rejectHandling()" class="dropdown-item">Reject container</a>
          <a (click)="resendPendingMessages()" *ngIf="!isDetached" class="dropdown-item">Resend pending messages</a>
          <a (click)="resendHandlingDeclaration()" *ngIf="!isDetached" class="dropdown-item">Resend handling
            declaration</a>
        </div>
      </div>
      <button (click)="openChangeVesselPanel()" *ngIf="isVesselEditable"
              class="d-flex btn btn-secondary me-2 hide-md-screen" type="button">
        <i class="button-icon fa-light fa-ship pe-2"></i>Change vessel
      </button>

      <span [ngbTooltip]="!isHandlingEditable ? editTooltip : ''" placement="bottom-right" container="body">
        <button (click)="openEditHandlingPanel()" [disabled]="!isHandlingEditable"
                class="d-flex btn btn-secondary hide-md-screen" type="button">
          <i class="button-icon fa-light fa-pencil pe-2"></i>Edit
        </button>
      </span>

      <div *ngIf="appContext.isHinterlandDeclarant()" class="d-flex me-2 show-sm-screen">
        <button aria-expanded="false" class="btn btn-primary" data-bs-toggle="dropdown" type="button">Actions<i
          class="fal fa-angle-down ms-1"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <a (click)="openEditHandlingPanel()" *ngIf="isHandlingEditable" class="dropdown-item">Edit handling</a>
          <a (click)="emailHandling()" class="dropdown-item">Email handling</a>
          <a (click)="duplicateHandling()" class="dropdown-item">Duplicate handling</a>
          <a (click)="cancelHandling()" *ngIf="!handling.cancelled || !handling['completed']" class="dropdown-item">Cancel
            handling</a>
          <a (click)="attachToVisit()" class="dropdown-item">Attach to<span *ngIf="!isDetached"> other</span> visit</a>
          <a (click)="detachFromVisit()" *ngIf="!isDetached" class="dropdown-item">Detach from visit</a>
        </div>
      </div>
    </div>

    <div class="tab-bar">
      <div class="tab-pane">
        <nav #nav="ngbNav" class="nav-tabs hide-md-screen" ngbNav>
          <ng-container ngbNavItem="details">
            <a class="tabs" ngbNavLink>Details</a>
            <ng-template ngbNavContent>
              <div class="ngbNavContentTemplate h-100">

                <div class="section-group show-sm-screen">
                  <div class="nav-content single-section">
                    <div class="select-all quick-info-text">
                      {{ (visit?.plannedVisitData?.tar) ? visit?.plannedVisitData?.tar : '-' }}
                    </div>
                    <div class="pb-3 quick-info-title">TAR</div>
                    <div
                      *ngIf="handling.handlingData?.type ==='discharge' || utils.isCargoDeclarant(handling) || utils.isCharterNew(voyage?.voyageData?.charter)"
                      class="select-all quick-info-text">
                      {{ (handling.handlingData?.bookingNumber) ? handling.handlingData?.bookingNumber : '-' }}
                    </div>
                    <div class="pb-1 quick-info-title">Acceptance ref.</div>
                  </div>
                </div>

                <div class="section-header hide-md-screen">Handling Status</div>
                <app-handling-status-panel [handling]="handling" [visit]="visit"
                                           displayMode="verbose"></app-handling-status-panel>

                <div class="section-header hide-md-screen">Handling Details</div>
                <div class="d-flex section-group">
                  <div class="nav-content left-section col-md">
                    <div class="details-row">
                      <div>Container number</div>
                      <div
                        class="select-all">{{
                          (handling.handlingData.equipmentNumber) ? handling.handlingData.equipmentNumber
                            : handling.handlingStatus?.equipmentNumber ? handling.handlingStatus?.equipmentNumber : '-'
                        }}
                        <span
                          *ngIf="!handling.handlingData?.equipmentNumber && handling.handlingStatus?.equipmentNumber"
                          [ngbTooltip]="equipmentNumberTooltip" placement="bottom" container="body" class="info-icon">
                          <i class="fa-light fa-circle-info"></i>
                        </span>
                      </div>
                    </div>
                    <div class="details-row"
                         *ngIf="handling.handlingData?.type ==='discharge' || utils.isCargoDeclarant(handling) || utils.isCharterNew(voyage?.voyageData?.charter)">
                      <div>Acceptance ref.</div>
                      <div class="select-all">
                        {{ (handling.handlingData?.bookingNumber) ? handling.handlingData?.bookingNumber : '-' }}
                      </div>
                    </div>
                    <div [ngbTooltip]="(isDifferentShippingCompany) ? shippingCompanyTooltip : ''" class="details-row"
                         placement="bottom" container="body">
                      <div>Shipping company</div>
                      <div>
                        {{ (handling.handlingData?.shippingCompany) ? handling.handlingData?.shippingCompany.name : '-' }}
                        <i *ngIf="(handling.handlingData?.shippingCompany && isDifferentShippingCompany)"
                           class="fa-solid fa-circle-exclamation warning-icon"></i>
                      </div>
                    </div>
                    <div class="details-row">
                      <div>Handling</div>
                      <div>
                        {{ !!handling.handlingType ? (handling.handlingType === 'loading' ? 'Pick-up' : 'Drop-off') : '-' }}
                      </div>
                    </div>
                    <div class="details-row">
                      <div>Full/empty</div>
                      <div>
                        {{ handling.handlingData?.full ? 'Full' : 'Empty' }}
                      </div>
                    </div>
                    <div [ngbTooltip]="(isDifferentSizeType) ? sizeTypeTooltip : ''" class="details-row"
                         placement="bottom" container="body">
                      <div>Size/type</div>
                      <div>
                        {{ (handling.handlingData?.sizeType) ? handling.handlingData?.sizeType.code : '-' }}
                        <i *ngIf="(handling.handlingData?.sizeType && isDifferentSizeType)"
                           class="fa-solid fa-circle-exclamation warning-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="nav-content right-section col-sm">
                    <div class="details-row">
                      <div>Seal number</div>
                      <div>
                        {{ (handling.handlingData?.sealNumber) ? handling.handlingData?.sealNumber : '-' }}
                      </div>
                    </div>
                    <div [ngbTooltip]="(isDifferentGrossWeight) ? grossWeightTooltip : ''" class="details-row"
                         placement="bottom" container="body">
                      <div>Gross weight</div>
                      <div>
                        {{ (handling.handlingData?.weight) ? handling.handlingData?.weight + " kg" : '-' }}
                        <i *ngIf="(handling.handlingData?.weight && isDifferentGrossWeight)"
                           class="fa-solid fa-circle-exclamation warning-icon"></i>
                      </div>
                    </div>
                    <div [ngbTooltip]="(isDifferentTareWeight) ? tareWeightTooltip : ''" class="details-row"
                         placement="bottom" container="body">
                      <div>Tare weight</div>
                      <div>
                        {{ (handling.handlingData?.tareWeight) ? handling.handlingData?.tareWeight + " kg" : '-' }}
                        <i *ngIf="(handling.handlingData?.tareWeight && isDifferentTareWeight)"
                           class="fa-solid fa-circle-exclamation warning-icon"></i>
                      </div>
                    </div>
                    <div class="details-row" *ngIf="!!handling.handlingData?.totalContainerWeight">
                      <div>Total weight</div>
                      <div>
                        {{ handling.handlingData.totalContainerWeight + " kg" }}
                      </div>
                    </div>
                    <div class="details-row">
                      <div>Out of gauge</div>
                      <div>
                        {{ (handling.handlingData?.outOfGauge) ? 'Yes' : '-' }}
                        <i *ngIf="(handling.handlingData?.outOfGauge)"
                           [ngbTooltip]="outOfGaugeTooltip" class="fa-light fa-circle-info info-icon"
                           placement="bottom" container="body"></i>
                      </div>
                    </div>
                    <div [ngbTooltip]="(handling.handlingData?.reefer) ? reeferTooltip : ''" class="details-row"
                         container="body">
                      <div>Reefer</div>
                      <div>
                        {{ (handling.handlingData?.reefer) ? 'Yes' : '-' }}
                        <i *ngIf="(handling.handlingData?.reefer)"
                           [ngbTooltip]="reeferTooltip" class="fa-light fa-circle-info info-icon"
                           placement="bottom" container="body"></i>
                      </div>
                    </div>
                    <div class="details-row">
                      <div>Own reference</div>
                      <div>
                        {{ (handling.handlingData?.transportNumber) ? handling.handlingData?.transportNumber : "-" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section-header hide-md-screen">Terminal Visit</div>
                <div class="d-flex section-group">
                  <div class="nav-content left-section col-sm">
                    <div class="details-row">
                      <div>TAR</div>
                      <div class="select-all">
                        {{ (visit?.plannedVisitData?.tar) ? visit?.plannedVisitData?.tar : '-' }}
                      </div>
                    </div>
                    <div *ngIf="(visit?.plannedVisitData?.eta) else emptyTimeSlot" class="details-row">
                      <div>Timeslot</div>
                      <div>
                        <app-formatted-date-range [end]="visit?.plannedVisitData?.etd"
                                                  [showClock]="false"
                                                  [start]="visit?.plannedVisitData?.eta"></app-formatted-date-range>
                        <i
                          *ngIf="(isRequestedEtaOutsideTimeslot && visit?.plannedVisitData?.eta) else requestedEtaTooltip"
                          [ngbTooltip]="timeSlotTooltip" container="body"
                          class="fa-solid fa-circle-exclamation warning-icon"></i>

                        <ng-template #requestedEtaTooltip>
                          <i *ngIf="visit?.plannedVisitData?.eta && visit?.requestedVisitData?.eta"
                             [ngbTooltip]="timeSlotTooltip" container="body"
                             class="fa-light fa-circle-info info-icon"></i>
                        </ng-template>
                      </div>
                    </div>
                    <ng-template #emptyTimeSlot>
                      <div class="details-row">
                        <div>ETA</div>
                        <div>
                          {{ (eta) ? formatDateTime(eta) : '-' }}
                        </div>
                      </div>
                    </ng-template>
                    <div class="details-row">
                      <div>Terminal</div>
                      <div>
                        {{ (terminalName) ? terminalName : '-' }}
                      </div>
                    </div>
                    <div class="details-row">
                      <div>License plate</div>
                      <div>
                        {{ (voyage?.voyageData?.truckLicenseId) ? voyage?.voyageData?.truckLicenseId : '-' }}
                      </div>
                    </div>
                    <div *ngIf="isChartered() else charter" class="details-row">
                      <div>Executing for</div>
                      <div>
                        {{ voyage?.declarant?.fullName }} ({{ voyage?.declarant.ean }})
                      </div>
                    </div>
                    <ng-template #charter>
                      <div class="details-row">
                        <div>Executing charter</div>
                        <div>
                          @if (voyage?.voyageData?.charter) {
                            {{ voyage?.voyageData?.charter?.name }} ({{ voyage?.voyageData?.charter?.ean }})
                          } @else {
                            -
                          }
                        </div>
                      </div>
                    </ng-template>
                    <div *ngIf="appContext.isHinterlandTerminal()" class="details-row">
                      <div>Declarant</div>
                      <div>
                        {{ isDetached ? handling['declarant'].fullName : voyage?.declarant.fullName }}
                        ({{ isDetached ? handling['declarant'].ean : voyage?.declarant.ean }})
                      </div>
                    </div>
                  </div>
                  <div class="nav-content right-section col-sm">
                    <div class="details-row">
                      <div>Block group</div>
                      <div>
                        {{ (handling.handlingStatus?.blockGroup) ? handling.handlingStatus?.blockGroup : '-' }}
                      </div>
                    </div>
                    @if (isPickup && cargoOpeningTime === cargoClosingTime) {
                      <div class="details-row">
                        <div>Ultimate release date</div>
                        <div>
                          {{ (cargoOpeningTime) ? formatDateTime(cargoOpeningTime) : '-' }}
                        </div>
                      </div>
                    } @else {
                      <div class="details-row">
                        <div>{{ isPickup ? 'Com. window open' : 'Cargo opening' }}</div>
                        <div>
                          {{ (cargoOpeningTime) ? formatDateTime(cargoOpeningTime) : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>{{ isPickup ? 'Com. window closed' : 'Cargo closing' }}</div>
                        <div>
                          {{ (cargoClosingTime) ? formatDateTime(cargoClosingTime) : '-' }}
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <ng-container *ngIf="deepSea">
                  <div class="section-header hide-md-screen">Vessel Visit</div>

                  <div class="d-flex section-group">
                    <div class="nav-content left-section col-sm">
                      <div class="details-row">
                        <div>Vesselname</div>
                        <div>
                          {{ (deepSea?.shipName) ? deepSea?.shipName : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>IMO</div>
                        <div>
                          {{ (deepSea?.imoCode) ? deepSea?.imoCode : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>CRN</div>
                        <div>
                          {{ (deepSea?.crnNumber) ? deepSea?.crnNumber : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Voyage number</div>
                        <div>
                          {{ (deepSea?.voyageNumber) ? deepSea?.voyageNumber : '-' }}
                        </div>
                      </div>
                    </div>
                    <div class="nav-content right-section col-sm">
                      <div class="details-row">
                        <div>ETA</div>
                        <div>
                          {{ (deepSea?.eta) ? formatDateTime(deepSea?.eta) : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>ETD</div>
                        <div>
                          {{ (deepSea?.etd) ? formatDateTime(deepSea?.etd) : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Expected discharge time</div>
                        <div>
                          {{ (deepSea?.expectedDischargeTime) ? formatDateTime(deepSea?.expectedDischargeTime) : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Source</div>
                        <div>
                          {{ (deepSea?.source) || '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Last updated</div>
                        <div>
                          {{ (deepSea?.lastUpdated) ? formatDateTime(deepSea?.lastUpdated) : '-' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="transportOrder">
                  <div *ngIf="transportOrder" class="section-header hide-md-screen">Transport Order</div>

                  <div *ngIf="transportOrder" class="d-flex section-group">
                    <div class="nav-content left-section col-sm">
                      <div class="details-row">
                        <div>Invoice reference</div>
                        <div>
                          {{ (transportOrder?.invoiceReference) ? transportOrder?.invoiceReference : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Debtor</div>
                        <div>
                          {{ (transportOrder?.debtorName) ? transportOrder?.debtorName : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Consignee</div>
                        <div>
                          {{ (transportOrder?.consigneeName) ? transportOrder?.consigneeName : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Release expiration</div>
                        <div>
                          {{ (transportOrderReleaseExpiration) ? transportOrderReleaseExpiration : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Demurrage</div>
                        <div>
                          {{ (transportOrderDemurrage) ? transportOrderDemurrage + ' days' : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Detention (days)</div>
                        <div>
                          {{ (transportOrderDetention) ? transportOrderDetention + ' days' : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Return terminal</div>
                        <div>
                          {{ (transportOrder?.returnTerminal) ? transportOrder?.returnTerminal : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Return reference</div>
                        <div>
                          {{ (transportOrder?.returnReference) ? transportOrder?.returnReference : '-' }}
                        </div>
                      </div>
                    </div>
                    <div class="nav-content right-section col-sm">
                      <div class="details-row">
                        <div>Destination target date</div>
                        <div>
                          {{ (transportOrder?.destinationTargetDate) ? transportOrder?.destinationTargetDate : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Destination reference</div>
                        <div>
                          {{ (transportOrder?.destinationReference) ? transportOrder?.destinationReference : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Destination instructions</div>
                        <div>
                          {{ (transportOrder?.destinationInstructions) ? transportOrder?.destinationInstructions : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Customs category</div>
                        <div>
                          {{ (transportOrder?.customsCategory) ? transportOrder?.customsCategory : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Document number</div>
                        <div>
                          {{ (transportOrder?.documentNumber) ? transportOrder?.documentNumber : '-' }}
                        </div>
                      </div>
                      <div class="details-row">
                        <div>Document expiration</div>
                        <div>
                          {{ (transportOrder?.documentExpiration) ? transportOrder?.documentExpiration : '-' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ng-container>

          <ng-container ngbNavItem="history">
            <a class="tabs hide-md-screen" ngbNavLink>History</a>
            <ng-template ngbNavContent>
              <div class="ngbNavContentTemplate h-100">
                <app-view-handling-history-offcanvas
                  [handling]="handling"
                  [visit]="visit"
                  [voyage]="voyage">
                </app-view-handling-history-offcanvas>
              </div>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="appContext.isAdmin()" ngbNavItem="messages">
            <a class="tabs hide-md-screen" ngbNavLink>Messages</a>
            <ng-template ngbNavContent>
              <div class="ngbNavContentTemplate h-100">
                <app-view-message-history-offcanvas
                  [handling]="handling"
                  [visit]="visit"
                  [voyage]="voyage">
                </app-view-message-history-offcanvas>
              </div>
            </ng-template>
          </ng-container>
        </nav>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div [ngbNavOutlet]="nav" class="nav-tabs-content pb-5"></div>
  </div>

</div>

<!-- tooltips -->

<ng-template #grossWeightTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">Reported by terminal: {{ handling.handlingStatus?.weight }} kg
    </div>
  </div>
</ng-template>

<ng-template #tareWeightTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">Reported by terminal: {{ handling.handlingStatus?.tareWeight }} kg
    </div>
  </div>
</ng-template>

<ng-template #shippingCompanyTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">Reported by terminal: {{ handling.handlingStatus?.shippingCompany.name }}
    </div>
  </div>
</ng-template>

<ng-template #sizeTypeTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">Reported by terminal: {{ handling.handlingStatus?.sizeType.code }}
    </div>
  </div>
</ng-template>

<ng-template #editTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">Not allowed to update handling.</div>

    <div class="tooltip-card-body mt-2">
      @if (!isDetached && isHandlingUpdateForbidden && !isHandled) {
        {{ terminalName }} does not support updates of pre-notifications.
      } @else if (isHandled) {
        Handling already completed
      }
    </div>
  </div>
</ng-template>

<ng-template #outOfGaugeTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">Front: {{ handling.handlingData?.outOfGauge?.front }} cm</div>
    <div class="tooltip-card-body">Back: {{ handling.handlingData?.outOfGauge?.rear }} cm</div>
    <div class="tooltip-card-body">Top: {{ handling.handlingData?.outOfGauge?.height }} cm</div>
    <div class="tooltip-card-body">Left: {{ handling.handlingData?.outOfGauge?.left }} cm</div>
    <div class="tooltip-card-body">Right: {{ handling.handlingData?.outOfGauge?.right }} cm</div>
  </div>
</ng-template>

<ng-template #timeSlotTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">
      Requested ETA: {{ formatDateTime(visit?.requestedVisitData?.eta) }}
    </div>
  </div>
</ng-template>

<ng-template #reeferTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">
      Min: {{ handling.handlingData?.reefer?.minimumTemperature }}
    </div>
    <div class="tooltip-card-body">
      Max: {{ handling.handlingData?.reefer?.maximumTemperature }}
    </div>
  </div>
</ng-template>

<ng-template #equipmentNumberTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-title">Reported by terminal</div>
  </div>
</ng-template>
