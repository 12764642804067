import {HttpClient, HttpEventType} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AppContext} from '../../app-context';
import {lodash} from "../../common/utils";

@Component({
  selector: 'app-refdata-import',
  templateUrl: './refdata-import.component.html',
  styleUrls: ['./refdata-import.component.css']
})
export class RefdataImportComponent implements OnInit {
  form: FormGroup;
  uploadTypes = [
    {value: 'refdata_barge', text: 'Barges'},
    {value: 'refdata_bollard', text: 'Bollards'},
    {value: 'refdata_carrier', text: 'Carriers'},
    {value: 'refdata_container', text: 'Containers'},
    {value: 'refdata_fixedwindow', text: 'Fixed Windows', queryString: "beginPath=periodOfvalidity.start&endPath=periodOfvalidity.end"},
    {value: 'refdata_charter', text: 'Charters'},
    {value: 'refdata_location', text: 'Locations'},
    {value: 'refdata_port', text: 'Ports'},
    {value: 'refdata_sizetype', text: 'Container size types'},
    {value: 'refdata_timetableentry', text: 'Time table entries', queryString: "beginPath=startDate"},
    {value: 'user-preferences', text: 'User Preferences'},
    {value: 'organisations', text: 'Organisations'},
  ];

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      collection: this.uploadTypes[0].value,
      file: [''],
      fileName: ''
    })
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('file').setValue(file);
      this.form.get('fileName').setValue(file.name);
    }
  }

  onSubmit() {
    const collection = this.form.get('collection').value;
    const file = this.form.get('file').value;
    const fileName = this.form.get('fileName').value;

    const formData = new FormData();
    formData.append('collection', collection);
    formData.append('data', file, fileName);

    let url = '/api/refdata/import';
    let matchingType = lodash.find(this.uploadTypes, ut => ut.value === collection)
    if(matchingType && matchingType.queryString) {
      url += `?${matchingType.queryString}`;
    }

    this.http.post(url, formData, {reportProgress: true, observe: 'events'})
      .subscribe(events => {
        if (events.type == HttpEventType.UploadProgress) {
          console.log('Upload progress: ', Math.round(events.loaded / events.total * 100) + '%');
        } else if (events.type === HttpEventType.Response) {
          if (events.ok) {
            AppContext.registerSuccess('The file was imported successfully');
          }
        } else if (events.type === HttpEventType.ResponseHeader) {
          switch (events.status) {
            case 403:
              AppContext.registerError('Not authorised to upload this type of file');
              break;
            case 401 | 500:
              AppContext.registerError('Currently it is not possible to upload a file');
              break;
            default:
              AppContext.registerError('Something went wrong');
          }
        }
      });

    this.form.reset();
  }
}
