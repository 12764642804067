<ng-content select=".input-group-prepend"></ng-content>
<input autocomplete="chrome-off"
       [(ngModel)]="inputModel" (selectItem)="onSelect($event.item)" (click)="onClick()" (blur)="onBlur()"
       [ngbTypeahead]="onInput" [resultFormatter]="resultFormatter" [inputFormatter]="inputFormatter"
       [disabled]="disabled" [placeholder]="placeholder" [id]="id ? id + '-input' : ''"
       [required]="required" [placement]="placement"
       [attr.type]="searching ? 'text' : 'search'" [ngClass]="'form-control rounded-end ' + customClass"/>
<span class="input-group-text border-0 search-icon bg-transparent" *ngIf="!searching && !inputModel">
  <span class="fa fa-search bg-white ps-1"></span>
</span>
<span class="input-group-text border-0 search-icon bg-transparent" *ngIf="searching && inputModel">
  <span class="spinner-border spinner-border-sm text-secondary" role="status"></span>
</span>
<ng-content select=".invalid-feedback"></ng-content>
<ng-content select=".valid-feedback"></ng-content>
