import {Component, Input} from '@angular/core';
import {CargoVessel} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-vessel',
  templateUrl: './vessel.component.html',
  styleUrls: ['./vessel.component.css']
})
export class VesselComponent {
  @Input() vessel: CargoVessel;
  @Input() hideName: boolean;
}
