import {Component, OnInit} from '@angular/core';
import {AccountingResult, DateRange} from "@portbase/hinterland-service-typescriptmodels";
import {sendQuery} from "../common/utils";
import {downloadCsv} from "../common/download/download.utils";
import {AppContext} from "../app-context";
import moment from "moment/moment";

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  appContext = AppContext;
  dateRange: DateRange;
  validDate: boolean;

  ngOnInit(): void {
  }

  onDateSelection(dateRange: DateRange) {
    if (dateRange != null) {
      let start = moment(dateRange.start);
      let end = moment(dateRange.end);

      this.validDate = this.appContext.isAdmin() || end.diff(start, 'days') <= 31 && start.isAfter(moment().subtract(13, 'months'));
      this.dateRange = dateRange;
    }
  }

  downloadBilling() {
    AppContext.clearAlerts();
    if (this.validDate) {
      sendQuery("com.portbase.hinterland.api.accounting.GetAccountingForRange", {
        "range": this.dateRange,
        "includeEmpty": false
      }, {
        caching: false,
        showSpinner: true
      }).subscribe((r: AccountingResult) => {
        const data: string[][] = [];
        data.push(['Datum', 'Handling', 'Service', 'Type', 'Container', 'Operator', 'Terminal', 'Carrier']);
        r.transactions.forEach(t => data.push([t.date, t.handlingId, t.type, t.handlingType, t.equipmentNumber, t.operator, t.terminal, t.shippingCompany]));
        downloadCsv(data, "hcn-transactions-" + this.dateRange.start + "/" + this.dateRange.end + ".csv");
      });
    } else {
      AppContext.registerError("Please select a period of a maximum of 31 days in the last 13 months");
    }
  }

}
