<ng-content select=".input-group-prepend"></ng-content>
<input [ngModel]="filterTerm"
       (input)="onInput($any($event.target).value)"
       [disabled]="disabled"
       [placeholder]="placeholder" [id]="id"
       [attr.type]="searching ? 'text' : 'search'"
       autocomplete="off"
       maxlength="100"
       class="form-control rounded-end">
<span class="input-group-text border-0 search-icon bg-transparent" *ngIf="!filterTerm">
  <span class="fa fa-search bg-white ps-1"></span>
</span>
<span class="input-group-text border-0 search-icon bg-transparent" *ngIf="searching">
  <span class="spinner-border spinner-border-sm text-secondary" role="status"></span>
</span>
<ng-content select=".invalid-feedback"></ng-content>
<ng-content select=".valid-feedback"></ng-content>
