import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {sendQuery} from "../../../../common/utils";
import {HandlingModel} from "../../../hinterland-utils";

@Component({
  selector: 'app-handling-admin',
  templateUrl: './handling-admin.component.html',
  styleUrls: ['./handling-admin.component.scss']
})
export class HandlingAdminComponent {
  appContext = AppContext;
  @Input() handling: HandlingModel;

  openIncomingCopinoMessages() {
    AppContext.redirectToMessageManagement(this.handling.handlingDeclaration.processId);
  }

  openCodecoInMessages() {
    AppContext.redirectToMessageManagementWithMessageId('', this.handling.handlingResult.messageId);
  }

  openCodecoOutMessages() {
    AppContext.redirectToMessageManagement(this.handling.handlingId.split('-').join(''));
  }

  openOutgoingPrenotification() {
    sendQuery('com.portbase.common.api.admin.GetFriendlyHandlingId',
      <any>{voyageId: !this.handling.handlingDeclaration ?  this.handling.voyageId: this.handling.handlingDeclaration.originalVoyageId ? this.handling.handlingDeclaration.originalVoyageId: this.handling.voyageId,
        handlingId: this.handling.handlingId,
        isStatusRequest: false}, {caching: false})
      .subscribe(id => AppContext.redirectToMessageManagement(id));
  }

  openOutgoingRequests() {
    if (this.handling.voyageId) {
      sendQuery('com.portbase.common.api.admin.GetFriendlyHandlingId',
        <any>{voyageId: !this.handling.handlingDeclaration ?  this.handling.voyageId: this.handling.handlingDeclaration.originalVoyageId ? this.handling.handlingDeclaration.originalVoyageId: this.handling.voyageId,
          handlingId: this.handling.handlingId,
          isStatusRequest: true}, {caching: false})
        .subscribe((id:string) => AppContext.redirectToMessageManagement(id));
    } else {
      sendQuery('com.portbase.common.api.admin.GetFriendlyDetachedHandlingId',
        <any>{handlingId: this.handling.handlingId}, {caching: false})
        .subscribe(id => AppContext.redirectToMessageManagement(id));
    }
  }
}
