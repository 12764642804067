<div>
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'inOperation'">
      The handling has started but no additional information is available at this time.
    </ng-container>

    <div *ngSwitchCase="'handled'">
      <div class="row">
        <div [ngClass]="appContext.isPhone()? 'col-12' : 'col-6'">
          {{handling.handlingData.type === 'loading' ? 'Loaded' : 'Discharged'}} on:
        </div>
        <div [ngClass]="appContext.isPhone()? 'col-12' : 'col-6'">
          {{handling.handlingResult?.actualTimeOfHandling | timestamp}}
        </div>
        <ng-container *ngIf="handling.handlingData.modality === 'road'">
          <div [ngClass]="appContext.isPhone()? 'col-12' : 'col-6'">Vehicle license nr:</div>
          <div [ngClass]="appContext.isPhone()? 'col-12' : 'col-6'">{{handling.handlingResult?.truckLicenseId}}</div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div class="text-start font-italic" *ngIf="handling.declarationStatus === 'REJECTED'">
    <div class="mt-2">Reject remarks:</div>
    <ul class="py-0 my-0 ">
      <li *ngFor="let reason of handling.handlingDeclaration?.rejectReasons || []">{{reason.parsedReason}}</li>
      <li *ngIf="!handling.handlingDeclaration?.rejectReasons?.length>0">No terminal remarks</li>
    </ul>
  </div>
  <div class="row py-2"
       *ngIf="handling.declarationStatus === 'ACCEPTED' || handling.handlingDeclaration?.acceptStatuses?.length > 0">
    <div *ngFor="let status of acceptStatuses" [ngClass]="appContext.isPhone()? 'col-12' : 'col-6'">
      <div class="row g-0 justify-content-between">
        <span class="col-auto">{{status.label}}</span>
        <span class="col-auto">
          <span class="fa" [ngClass]="{'fa-times-circle text-danger' : status.ok === false, 'fa-check-circle text-success' : status.ok, 'fa-question-circle text-secondary' : status.ok === null}"></span>
        </span>
      </div>
      <ul class="py-0 my-0 ps-3 text-start font-italic">
        <ng-container *ngFor="let alert of status.alerts">
          <li *ngIf="alert.code != null || alert.remark != null">
            <span>{{alert.code}}</span>
            <span *ngIf="alert.remark">: {{alert.remark}}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="handling['waitingForCloseVisit']" class="text-start px-2">
  <span *ngIf="handling['waitingForCloseVisit']" class="fa fa-exclamation-triangle text-warning"></span>
  Note that this terminal will receive the load/discharge list upon finalization of the list.<br>
  <span *ngIf="handling['waitingForCloseVisit']" class="fa fa-exclamation-triangle text-warning"></span>
  This handling is waiting for the visit to close.
</div>
