<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Remove ‘Remain on Truck’ container(s)</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body">
    <p>Select which 'Remain on truck' container(s) you would like to remove.</p>
    <li *ngFor="let container of data?.remainOnTruckContainers" class="remove-remain-on-truck">
      <div class="d-flex align-content-center align-items-center">
        <input [id]="container.handlingId+'-checkbox'" (ngModelChange)="toggleForRemoval(container)"
               [ngModel]="container['forRemoval']" class="form-check-input"
               type="checkbox">
        {{ container.handlingData.equipmentNumber }}
      </div>
    </li>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
    <button (click)="remove()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Remove</button>
  </div>
</div>
