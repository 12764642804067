import {Component, EventEmitter, Input, Output} from '@angular/core';
import {QuickFilterType} from "../../../../hinterland/types";
import {Observable} from "rxjs";
import {DateRange} from "@portbase/hinterland-service-typescriptmodels";
import {DateRangeDialogComponent} from "../../dialog/date-range-dialog/date-range-dialog";
import moment from "moment";
import {ConfirmationModalService} from "../../../../components/modals/confirmation-modal/confirmation-modal.service";
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-road-navbar-left',
  templateUrl: './road-navbar-left.component.html',
  styleUrls: ['./road-navbar-left.component.scss']
})
export class RoadNavbarLeftComponent {

  constructor(private readonly confirmationModalService: ConfirmationModalService) {
  }

  @Input() onQuickFilterChanged: (arg: string) => Observable<any[]> | any[];
  @Input() onDateRangeChanged: (arg: DateRange) => Observable<any[]> | any[];

  @Input() quickFilterType: QuickFilterType = AppContext.isAdmin() ? 'all' : 'todo';
  @Input() dateRange: DateRange;
  @Input() activeFilters: number = 0;
  @Input() showNavbarAsFooter: boolean = false;

  @Output() toggleQuickfilters = new EventEmitter<{}>();

  openTimeslotEtaFilter() {
    this.confirmationModalService.openModalWithCallback((dateRange) => {
      if (dateRange) {
        this.onDateRangeChanged(dateRange);
      }
    }, DateRangeDialogComponent, {
      dateRange: this.dateRange,
      displayMonths: AppContext.isPhone() ? 1 : 2,
      title: 'Date',
      subtext: 'Select a start and end date to filter on ETA or timeslot.'
    });
  }

  clearTimeslotEtaFilter() {
    this.onDateRangeChanged(null);
  }

  format(date: string) {
    if (moment(date).year() === moment().year()) {
      return moment(date).format("DD MMM");
    }
    return moment(date).format("DD MMM YYYY");
  }

}
