import {Component} from '@angular/core';
import {AbstractOverviewComponent} from '../../hinterland/common/abstract-overview.component';
import {BinnenhavengeldSignalling, DateTimeRange} from '@portbase/hinterland-service-typescriptmodels';
import {Observable} from 'rxjs';
import {sendQuery} from '../../common/utils';
import {FindBinnenhavengeldSignallings} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {BhgAddSignallingComponent} from './bhg-add-signalling/bhg-add-signalling.component';
import {EditModalService} from "../../components/modals/edit-modal/edit-modal.service";

@Component({
  selector: 'app-bhg-signallings',
  templateUrl: './bhg-signallings.component.html',
  styleUrls: ['../bhg.scss', './bhg-signallings.component.css']
})
export class BhgSignallingsComponent extends AbstractOverviewComponent<BinnenhavengeldSignalling> {

  constructor(
    private readonly editModalService: EditModalService,
  ) {
    super();
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<BinnenhavengeldSignalling[]> {
    return sendQuery('com.portbase.hinterland.api.binnenhavengeld.FindBinnenhavengeldSignallings',
      <FindBinnenhavengeldSignallings>{term: this.filterTerm}, {caching: false});
  }

  doRender(entries: BinnenhavengeldSignalling[]) {
  }

  searchOrFilter = (): (BinnenhavengeldSignalling[] | Observable<BinnenhavengeldSignalling[]>) => {
    return this.reload();
  };

  'onBinnenhavengeldSignalling' = (notification: BinnenhavengeldSignalling) => {
    this.items.splice(0, 0, notification);
  }

  addSignalling() {
    this.editModalService.openModal(BhgAddSignallingComponent);
  }

}

