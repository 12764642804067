import {
  ArrayTemplate,
  exportExcel,
  LowercaseField,
  QuantityField,
  RequiredField,
  UppercaseField
} from '../../common/upload/excel.utils';
import {PlannedCargo, RailVisit, RailVoyage, Slot} from '@portbase/hinterland-service-typescriptmodels';
import moment from "moment/moment";

export class RailExcelUtils {

  static downloadTrainComposition(railVoyage: RailVoyage, railVisit: RailVisit) {
    const data = {
      inboundVisit: {
        train: railVoyage.voyageData.shuttle,
        voyage: railVoyage.voyageData.dischargeVoyageNumber,
        type: "Inbound",
        sender: railVoyage.declarant.fullName,
        operator: railVoyage.declarant.fullName,
        eta: moment(railVisit.visitData.eta).format("DD/MM/YYYY HH:mm"),
        etd: moment(railVisit.visitData.etd).format("DD/MM/YYYY HH:mm"),
        facility: railVisit.terminal.quayName,
        remarks: railVisit.visitData.operatorRemarks
      },
      outboundVisit: {
        train: railVoyage.voyageData.shuttle,
        voyage: railVoyage.voyageData.loadingVoyageNumber,
        type: "Outbound",
        sender: railVoyage.declarant.fullName,
        operator: railVoyage.declarant.fullName,
        eta: moment(railVisit.visitData.eta).format("DD/MM/YYYY HH:mm"),
        etd: moment(railVisit.visitData.etd).format("DD/MM/YYYY HH:mm"),
        facility: railVisit.terminal.quayName,
        remarks: railVisit.visitData.operatorRemarks
      },
      wagons: railVisit?.trainComposition.wagons
        .sort((a, b) => a.position - b.position)
        .flatMap(w => {
          const getSlot = (i: number) => w.slots.find(s => s.number == i)

          const out = [];
          for (let current = 1; current <= w.numberOfSlots; current++) {
            const row = {
              wagonPosition: '',
              wagonNumber: ''
            }

            if (current === 1) {
              (<any>row).wagonPosition = w.position;
              (<any>row).wagonNumber = w.wagonNumber;
            }
            (<any>row).slotNumber = current;

            const slot: Slot = getSlot(current);
            if (slot && slot.incomingCargo && slot.incomingCargo.type !== "loading") {
              (<any>row).discharge = mapPlannedCargoToRow(slot.incomingCargo, railVisit);
            }
            if (slot && slot.outgoingCargo && slot.outgoingCargo.type !== "discharge") {
              (<any>row).load = mapPlannedCargoToRow(slot.outgoingCargo, railVisit);
            }

            out.push(row);
          }
          return out;
        })
    };
    return exportExcel("/assets/templates/train-composition-1.0-download.xlsx",
      train_composition_excelTemplate_v1_0(data.wagons.length), data, "train-composition.xlsx");
  };

  static downloadTrainCompositionAfterOperations(railVoyage: RailVoyage, railVisit: RailVisit) {
    const data = {
      outboundVisit: {
        train: railVoyage.voyageData.shuttle,
        inboundVoyage: railVoyage.voyageData.dischargeVoyageNumber,
        outboundVoyage: railVoyage.voyageData.loadingVoyageNumber,
        type: "Outbound",
        sender: railVoyage.declarant.fullName,
        operator: railVoyage.declarant.fullName,
        eta: moment(railVisit.visitData.eta).format("DD/MM/YYYY HH:mm"),
        etd: moment(railVisit.visitData.etd).format("DD/MM/YYYY HH:mm"),
        facility: railVisit.terminal.quayName,
        remarks: railVisit.visitData.operatorRemarks
      },
      wagons: railVisit.trainComposition?.wagons
        .sort((a, b) => a.position - b.position)
        .flatMap(w => {
          const getSlot = (i: number) => w.slots.find(s => s.number == i)

          const out = [];
          for (let current = 1; current <= w.numberOfSlots; current++) {
            const row = {
              wagonPosition: '',
              wagonNumber: ''
            }

            if (current === 1) {
              (<any>row).wagonPosition = w.position;
              (<any>row).wagonNumber = w.wagonNumber;
              (<any>row).wagonRemarks = w.remarks;
            }
            (<any>row).slotNumber = current;

            const slot: Slot = getSlot(current);

            if (slot) {
              if (slot.outgoingCargo) {
                (<any>row).load = mapPlannedCargoToRow(slot.outgoingCargo, railVisit);
                (<any>row).load.remarks = slot.remarks;
              } else {
                (<any>row).load = {
                  remarks: slot.remarks
                };
              }
            }
            out.push(row);
          }
          return out;
        }) ?? []
    };
    return exportExcel("/assets/templates/train-composition-after-operations-template-1.1.xlsx",
      train_composition_after_operation_excelTemplate_v1_1(data.wagons.length), data, "train-composition-after-operations.xlsx");
  };
}

function mapPlannedCargoToRow(cargo: PlannedCargo, railVisit: RailVisit) {
  return {
    loadingTerminal: cargo.loadingTerminal,
    dischargeTerminal: cargo.dischargeTerminal,
    equipmentNumber: cargo.cargoData.equipmentNumber,
    line: cargo.cargoData.shippingCompany?.scacCode ||
      railVisit.handlings.find(h => h.handlingId === cargo.cargoData.handlingId)?.handlingData?.shippingCompany?.scacCode,
    sizeType: cargo.cargoData.sizeType.code,
    grossWeight: cargo.cargoData.grossWeight,
    fullEmpty: cargo.cargoData.full ? 'F' : 'E',
    itt: railVisit.handlings.find(h => h.handlingId === cargo.cargoData.handlingId)?.handlingData?.interTerminalTransportLocation?.interTerminalCode,
    unCode: cargo.cargoData.dangerousGood?.unCode,
    hazardClass: cargo.cargoData.dangerousGood?.hazardClass,
    gevi: cargo.cargoData.dangerousGood?.gevi,
    declarantRemarks: cargo.cargoData.remarks
  }
}

const train_composition_excelTemplate_v1_0 = (rows: number) => {
  return {
    sheets: [
      {
        name: "Inbound",
        template: {
          inboundVisit: {
            train: "B3",
            voyage: "B4",
            type: "B5",
            sender: "B6",
            operator: "B7",
            eta: "B8",
            etd: "B9",
            facility: "B10",
            remarks: "B11"
          },
          wagons: new ArrayTemplate({
            wagonPosition: "A$",
            wagonNumber: "B$",
            slotNumber: new RequiredField("C$"),
            discharge: {
              equipmentNumber: new UppercaseField("D$"),
              line: "E$",
              sizeType: new UppercaseField("F$"),
              grossWeight: new QuantityField("G$"),
              fullEmpty: new UppercaseField("H$"),
              dischargeTerminal: "I$",
              itt: "J$",
              unCode: new LowercaseField("K$"),
              hazardClass: "L$",
              gevi: "M$",
              declarantRemarks: "N$",
            }
          }, [14, (14 + rows) - 1])
        }
      },
      {
        name: "Outbound",
        template: {
          outboundVisit: {
            train: "B3",
            voyage: "B4",
            type: "B5",
            sender: "B6",
            operator: "B7",
            eta: "B8",
            etd: "B9",
            facility: "B10",
            remarks: "B11"
          },
          wagons: new ArrayTemplate({
            wagonPosition: "A$",
            wagonNumber: "B$",
            slotNumber: new RequiredField("C$"),
            load: {
              equipmentNumber: new UppercaseField("D$"),
              line: "E$",
              sizeType: new UppercaseField("F$"),
              grossWeight: new QuantityField("G$"),
              fullEmpty: new UppercaseField("H$"),
              loadingTerminal: "I$",
              itt: "J$",
              unCode: new LowercaseField("K$"),
              hazardClass: "L$",
              gevi: "M$",
              declarantRemarks: "N$"
            }
          }, [14, (14 + rows) - 1])
        }
      }
    ]
  }
}

const train_composition_after_operation_excelTemplate_v1_1 = (rows: number) => {
  return {
    sheets: [
      {
        name: "Train composition",
        template: {
          outboundVisit: {
            train: "B3",
            inboundVoyage: "B4",
            outboundVoyage: "B5",
            type: "B6",
            sender: "B7",
            operator: "B8",
            eta: "B9",
            etd: "B10",
            facility: "B11",
            remarks: "B12"
          },
          wagons: new ArrayTemplate({
            wagonPosition: "A$",
            wagonNumber: "B$",
            slotNumber: new RequiredField("C$"),
            load: {
              loadingTerminal: "D$",
              dischargeTerminal: "E$",
              equipmentNumber: new UppercaseField("F$"),
              line: "G$",
              sizeType: new UppercaseField("H$"),
              grossWeight: new QuantityField("I$"),
              fullEmpty: new UppercaseField("J$"),
              itt: "K$",
              unCode: new LowercaseField("L$"),
              hazardClass: "M$",
              gevi: "N$",
              declarantRemarks: "O$",
              remarks: "P$"
            },
            wagonRemarks: "Q$"
          }, [15, (15 + rows) - 1])
        }
      }
    ]
  }
}
