import moment, {Duration, Moment} from 'moment';
import {removeItem} from './utils';

let scheduler: TaskScheduler;

export function scheduleTask(task: (() => any), duration : Duration | number) : Timer {
  if (!scheduler) {
    scheduler = new TaskScheduler();
  }
  const timer = new Timer(task, duration);
  scheduler.timers.push(timer);
  return timer;
}

class TaskScheduler {
  timers: Timer[] = [];

  constructor() {
    setInterval(this.checkTimers, 10_000);
  }

  checkTimers = () => {
    const now = moment();
    this.timers.filter(t => t.deadline.isBefore(now)).forEach(t => t.run());
  };
}

export class Timer {
  task: (() => any);
  deadline: Moment;
  timeout : any;

  constructor(task: () => any, duration : Duration | number) {
    this.task = task;
    const millis = typeof duration === 'number' ? duration : duration.asMilliseconds();
    this.deadline = moment().add(millis, 'milliseconds');
    this.timeout = setTimeout(this.run, millis);
  }

  run = (): void => {
    if (this.timeout) {
      this.cancel();
      this.task();
    }
  };

  cancel = (): void => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      removeItem(scheduler.timers, this);
      delete this.timeout;
    }
  };
}
