<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i data-bs-dismiss="modal"
                                                     class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">Delete user</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <p>Are you sure you want to delete this user: <span class="fw-medium notranslate">{{ data.userId }}</span>? This
      cannot be undone.</p>
  </div>

  <div class="modal-footer">
    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-outline-danger" (click)="delete()">Delete user</button>
      </div>
    </div>
  </div>
</div>
