import {Component, ElementRef, OnInit} from '@angular/core';
import {
  AddBinnenhavengeldUser,
  BinnenhavengeldUser,
  Role,
  UpdateBinnenhavengeldUser
} from '@portbase/hinterland-service-typescriptmodels';
import {checkValidity, cloneObject, publishEvent, removeIf, replaceItem, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {BhgDeleteUserComponent} from '../bhg-delete-user/bhg-delete-user.component';
import {EditModalService} from "../../../components/modals/edit-modal/edit-modal.service";
import {ConfirmationModalService} from "../../../components/modals/confirmation-modal/confirmation-modal.service";

@Component({
  selector: 'app-bhg-edit-user',
  templateUrl: './bhg-edit-user.component.html',
  styleUrls: ['../../bhg.scss', './bhg-edit-user.component.css']
})
export class BhgEditUserComponent implements OnInit {

  data: BinnenhavengeldUser;
  updateMode: boolean;

  errors: String[];

  constructor(
    private element: ElementRef,
    private readonly editModalService: EditModalService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
  }

  ngOnInit(): void {
    this.errors = [];
    this.updateMode = !!this.data;
    this.data = this.data || {userRoles: [], preferredLanguage: 'Dutch'};
  }

  addRole(role: Role) {
    replaceItem(this.data.userRoles, this.data.userRoles.find(r => r === role), role);
  }

  removeRole(role: Role) {
    removeIf(this.data.userRoles, r => r === role);
  }

  add() {
    if (checkValidity(this.element)) {
      this.errors = [];
      AppContext.clearAlerts();
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.AddBinnenhavengeldUser', <AddBinnenhavengeldUser>{
        user: this.data
      }, () => {
        AppContext.publishSuccessMessage("User added successfully");
        publishEvent('onBinnenhavengeldUserAdded', cloneObject(this.data));
        this.editModalService.closeModal();
      }, (error) => {
        this.errors.push(error?.error?.error);
      });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  }

  update() {
    if (checkValidity(this.element)) {
      this.errors = [];
      AppContext.clearAlerts();
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.UpdateBinnenhavengeldUser', <UpdateBinnenhavengeldUser>{
        user: this.data
      }, () => {
        AppContext.publishSuccessMessage("User updated successfully");
        publishEvent('onBinnenhavengeldUserUpdated', cloneObject(this.data));
        this.editModalService.closeModal();
      }, (error) => {
        this.errors.push(error?.error?.error);
      });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  }

  confirmDelete() {
    this.editModalService.closeModal();
    this.confirmationModalService.openModal(BhgDeleteUserComponent, this.data);
  }
}
