import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'time',
    standalone: true
})
export class TimePipe implements PipeTransform {

  transform(value: any, outputFormat: string = 'HH:mm', inputFormat:string = 'HH:mm:ss'): string {
    if (!value) {
      return value;
    }

    return moment(value, [moment.ISO_8601, inputFormat]).format(outputFormat);
  }

}
