import {Component, ElementRef} from '@angular/core';
import {checkValidity, cloneObject, sendCommand, sendQuery} from '../../common/utils';
import {v4 as uuid} from 'uuid';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-refdata-update',
  templateUrl: './refdata-update.component.html',
  styleUrls: ['./refdata-update.component.css']
})
export class RefdataUpdateComponent {
  entry: any;
  searchResult: any;
  type: 'barge' | 'railCarType';

  constructor(private element: ElementRef) {
  }

  search = term => {
    switch (this.type) {
      case 'barge':
        return sendQuery('com.portbase.hinterland.api.barge.query.FindBarges', {
          term: term,
          untyped: true
        }, {caching: false});
      case 'railCarType':
        return sendQuery('com.portbase.hinterland.api.barge.query.FindRailCarTypes', {
          term: term,
          untyped: true
        }, {caching: false});
    }
  };

  searchResultFormatter = (result: any) => {
    switch (this.type) {
      case 'barge':
        return result.name + ' - ' + result.europeId;
      case 'railCarType':
        return result.name;
    }
  };

  newEntry = () => {
    if (this.searchResult) {
      this.entry = cloneObject(this.searchResult);
      this.entry.id = uuid();
    } else {
      this.entry = {};
    }
    this.entry.id = uuid();
    this.searchResult = undefined;
  };

  cancel = () => {
    this.clear();
  };

  addEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.Add' + this.camelCasedType(), {entity: this.entry}, () => {
        AppContext.registerSuccess(this.camelCasedType() + ' was added successfully');
        this.clear();
      });
    }
  };

  updateEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.Update' + this.camelCasedType(), {entity: this.entry}, () => {
        AppContext.registerSuccess(this.camelCasedType() + ' was updated successfully');
        this.clear();
      });
    }
  };

  deleteEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.Delete' + this.camelCasedType(), {entity: this.entry}, () => {
        AppContext.registerSuccess(this.camelCasedType() + ' was deleted successfully');
        this.clear();
      });
    }
  };

  private camelCasedType = (): string => {
    switch (this.type) {
      case 'barge':
        return 'Barge';
      case 'railCarType':
        return 'RailCarType';
    }
  };

  private clear = () => {
    this.entry = undefined;
    this.searchResult = undefined;
  };

  getRefDataTypes() {
    if (AppContext.isAdmin()) {
      return ['barge', 'railCarType'];
    }
  }

}
