import {Component, ElementRef, OnInit} from '@angular/core';
import {checkValidity, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {Location} from '@angular/common';
import {SendBinnenhavengeldLoginLink} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-bhg-forgot-password',
  templateUrl: './bhg-forgot-password.component.html',
  styleUrls: ['./bhg-forgot-password.component.css']
})
export class BhgForgotPasswordComponent implements OnInit {

  command: SendBinnenhavengeldLoginLink = {};

  errors: String[];

  constructor(public location: Location, private element: ElementRef) {
  }

  ngOnInit() {
    this.errors = [];
  }

  sendLoginLink = () => {
    if (checkValidity(this.element)) {
      this.errors = [];
      AppContext.clearAlerts();
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.SendBinnenhavengeldLoginLink', this.command,
        () => AppContext.publishSuccessMessage("Your request was successful. Please check your email for a login link."),
        (error) => {
          this.errors.push(error?.error?.error);
        });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  };

}
