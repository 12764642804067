import {
  ArrayTemplate, Cell, DateTimeField, HardCodedField, MappedField, NonNegativeQuantityField, parseExcel, QuantityField, RequiredConditionallyField,
  RequiredField, RequiredIfField, toBase64,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {HandlingsUpload, UploadData} from "../../common/upload/handlings.upload";
import {sendCommand, uuid} from "../../common/utils";
import {DeclareHandling, DeclareHandlings, GetTerminal, OutOfGauge, Reefer} from "@portbase/hinterland-service-typescriptmodels";
import {AppContext} from "../../app-context";
import {HttpErrorResponse} from "@angular/common/http";
import {DeclareDetachedHandling} from "@portbase/hinterland-service-typescriptmodels/hinterland";

export interface BargeUploadData extends UploadData {
  voyageId: string;
  visitId: string;
  preNotification: boolean
}

export class BargeHandlingsUpload extends HandlingsUpload {
  getVersion(): string {
    return "Barge v2.4";
  }

  getRowCount(): number {
    return 300;
  }

  getTemplate(forDetached:boolean = false): WorkBookTemplate {
    return {
      sheets: [
        {
          name: "Guide",
          template: {
            version: new ValidatedField(new RequiredField('C1'), value => {
              if (value !== this.getVersion()) {
                throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
              }
            })
          }
        },
        {
          name: "Pickup",
          template: {
            handlings: new ArrayTemplate({
              handlingId: new HardCodedField(() => uuid()),
              handlingData: {
                modality: new HardCodedField("barge"),
                type: new HardCodedField("loading"),
                dangerousGoods: new HardCodedField([]),
                shippingCompany: new MappedField(new RequiredField("B$"), this.shippingCompanyMapper),
                full: new MappedField(new RequiredField("C$"), this.fullEmptyMapper),
                bookingNumber: new MappedField("D$", this.toUpperCaseMapper),
                equipmentNumber: new RequiredIfField(new MappedField("E$", this.toUpperCaseMapper), [new MappedField("C$", this.fullEmptyMapper)], value => !!value),
                shippersOwned: new MappedField("E$", this.shippersOwnedMapper),
                sizeType: new MappedField(new RequiredField("F$"), this.sizeTypeMapper),
                transportNumber: "G$",
                inlandTerminal: {
                  name: "I$",
                  location: new MappedField(new RequiredField("H$"), this.locationMapper),
                }
              },
              terminal: new MappedField(new RequiredConditionallyField("J$", forDetached), this.terminalByBicsOrSmdgMapper),
              eta: new MappedField(new DateTimeField("K$", HandlingsUpload.dateTimeFormat), this.dateTimeToIsoStringMapper)
            }, [4, this.getRowCount()])
          }
        },
        {
          name: "Delivery",
          template: {
            handlings: new ArrayTemplate({
              handlingId: new HardCodedField(() => uuid()),
              handlingData: {
                modality: new HardCodedField("barge"),
                type: new HardCodedField("discharge"),
                dangerousGoods: new HardCodedField([]),
                shippingCompany: new MappedField(new RequiredField("B$"), this.shippingCompanyMapper),
                full: new MappedField(new RequiredField("C$"), this.fullEmptyMapper),
                bookingNumber: new MappedField("D$", this.toUpperCaseMapper),
                equipmentNumber: new RequiredIfField(new MappedField("E$", this.toUpperCaseMapper), [new MappedField("C$", this.fullEmptyMapper)], value => !!value),
                shippersOwned: new MappedField("E$", this.shippersOwnedMapper),
                sizeType: new MappedField(new RequiredField("F$"), this.sizeTypeMapper),
                transportNumber: "G$",
                weight: new NonNegativeQuantityField("H$"),
                tareWeight: new NonNegativeQuantityField("I$"),
                sealNumber: new MappedField("J$", this.emptyStringToNullMapper),
                outOfGauge:<OutOfGauge> {
                  height: <any>new NonNegativeQuantityField("K$"),
                  left: <any>new NonNegativeQuantityField("L$"),
                  right: <any>new NonNegativeQuantityField("M$"),
                  front: <any>new NonNegativeQuantityField("N$"),
                  rear: <any>new NonNegativeQuantityField("O$"),
                },
                reefer:<Reefer> {
                  temperature: <any>new QuantityField("P$"),
                  temperatureFixed: <any>new MappedField("P$", value => !!value),
                  minimumTemperature: <any>new QuantityField(new RequiredIfField("Q$", ["R$"])),
                  maximumTemperature: <any>new QuantityField(new RequiredIfField("R$", ["Q$"]))
                }
              },
              terminal: new MappedField(new RequiredConditionallyField("S$", forDetached), this.terminalByBicsOrSmdgMapper),
              eta: new MappedField(new DateTimeField("T$", HandlingsUpload.dateTimeFormat), this.dateTimeToIsoStringMapper)
            }, [4, this.getRowCount()])
          }
        }
      ]
    };
  }

  upload(excelFile:File, data:BargeUploadData) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
      parseExcel(excelFile, this.getTemplate()).subscribe(model => {
        if (model.handlings.length === 0) {
          AppContext.registerError('No handlings found in uploaded excel');
          return;
        }

        model.handlings.forEach((handling:DeclareHandling) => {
          handling.voyageId = data.voyageId;
          handling.visitId = data.visitId;
          handling.preNotification = data.preNotification;
        });

        model.handlings.forEach((handling) => {
          handling.cargoDeclarantShortName = AppContext.userProfile.organisationShortName;
          delete handling.terminal;
          delete handling.eta;
        });

        const command = {
          '@class': 'io.fluxcapacitor.javaclient.common.Message',
          payload:<DeclareHandlings> {
            voyageId: data.voyageId,
            visitId: data.visitId,
            handlings: model.handlings
          },
          metadata: {
            'upload': excelBase64
          }
        }

        sendCommand('com.portbase.hinterland.api.common.command.DeclareHandlings', command, () => {
          AppContext.registerSuccess('Handlings were declared successfully');
        }, (error) => {
          let errorMessage = 'Error(s) occurred during declaring of handlings: ';
          if (error instanceof HttpErrorResponse) {
            const httpErrors:Array<string> = AppContext.getHttpErrors(error);
            httpErrors.forEach(e => errorMessage += `${e}. `)
            AppContext.registerError(errorMessage);
          } else {
            AppContext.registerError(errorMessage + error);
          }
        });
      });
    });
  }

  uploadDetached(excelFile: File, data:UploadData) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
      parseExcel(excelFile, this.getTemplate(true)).subscribe(model => {
        if (model.handlings.length === 0) {
          AppContext.registerError('No handlings found in uploaded excel');
          return;
        }

        model.handlings.forEach((handling:DeclareDetachedHandling) => {
          handling.declarant = AppContext.userProfile.organisation
        });

        const command = {
          '@class': 'io.fluxcapacitor.javaclient.common.Message',
          payload:<DeclareHandlings> {
            handlings: model.handlings
          },
          metadata: {
            'upload': excelBase64
          }
        }

        sendCommand('com.portbase.hinterland.api.detached.command.DeclareDetachedHandlings', command, () => {
          AppContext.registerSuccess('Detached handlings were declared successfully');
        }, (error) => {
          let errorMessage = 'Error(s) occurred during declaring of detached handlings: ';
          if (error instanceof HttpErrorResponse) {
            const httpErrors:Array<string> = AppContext.getHttpErrors(error);
            httpErrors.forEach(e => errorMessage += `${e}. `)
            AppContext.registerError(errorMessage);
          } else {
            AppContext.registerError(errorMessage + error);
          }
        });
      });
    });
  }
}
