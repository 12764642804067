import {Component, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../component/value-accessor.component';
import {Observable, Subject} from 'rxjs';
import {dispatchChangeEvent, extractValue} from '../utils';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {Modality} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SearchComponent), multi: true}
  ],
  host: {'class': 'input-group'}
})
export class SearchComponent extends AbstractValueAccessorComponent<any> implements OnInit {

  inputModel;
  selectedItem;
  searching: boolean = false;
  typingDelayTimer;

  private outputSubject = new Subject<any[]>();
  private outputObservable = this.outputSubject.asObservable();
  private filterTerm: string;

  @Input() searchFunction: (arg: string, modality: string) => Observable<any[]>;
  @Input() dataKey: string | string [];
  @Input() inputFormatter;
  @Input() resultFormatter;
  @Input() placeholder = "";
  @Input() disabled: boolean;
  @Input() minCharacters: number = 1;
  @Input() required;
  @Input() id;
  @Input() placement: PlacementArray = "bottom-left";
  @Input() searchOnClick: boolean = false;
  @Input() autoSelectOnSingleResult: boolean = false;
  @Input() modality: Modality = null;
  @Input() customClass : string;

  constructor(private elementRef: ElementRef) {
    super();
  }

  onInput = (text$: Observable<string>) => {
    text$.subscribe(term => {
      this.filterTerm = term;
      clearTimeout(this.typingDelayTimer);
      this.typingDelayTimer = setTimeout(this.search, 100);
    });
    return this.outputObservable;
  };

  search = () => {
    if (!this.searching) {
      if (this.filterTerm.length < this.minCharacters) {
        this.outputSubject.next([]);
        return;
      }
      this.searching = true;
      const lastTerm = this.filterTerm;
      this.searchFunction(lastTerm, this.modality).subscribe(r => {
        this.outputSubject.next(r);
        this.searching = false;
        if (lastTerm !== this.filterTerm) {
          this.search();
        } else {
          if (this.autoSelectOnSingleResult && r.length == 1 && this.resultFormatter(r[0]).split(/(\s+)/).includes(this.filterTerm)) {
            this.outputSubject.next([]);
            this.onSelect(r[0]);
          }
        }
      })
    }
  };

  ngOnInit() {
    if (!this.searchFunction) {
      throw new Error('Attribute searchFunction is required for app-search component');
    }
    if (this.required === "") {
      this.required = true;
    }
    if (!this.inputFormatter) {
      this.inputFormatter = value => this.dataKey ? extractValue(value, this.dataKey) : value;
    }
    if (!this.resultFormatter) {
      this.resultFormatter = this.inputFormatter;
    }
  }

  onClick = () => {
    if (this.searchOnClick) {
      this.filterTerm = this.filterTerm || '*';
      this.search();
    }
  }

  onBlur = () => {
    if (this.inputModel !== this.selectedItem) {
      this.onSelect(null);
    }
  };

  onSelect = (value) => {
    this.inputModel = value;
    this.selectedItem = value;
    this.searching = false;
    this.onModelChange();
    dispatchChangeEvent(this.elementRef.nativeElement);
  };

  get value(): any {
    return this.selectedItem;
  }

  writeValue(value: any): void {
    this.selectedItem = value === undefined ? null : value;
    this.inputModel = this.selectedItem;
  }

}
