import {Component, OnInit} from "@angular/core";
import {sendQuery} from "../../common/utils";
import {SearchFlux} from "@portbase/hinterland-service-typescriptmodels";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {FancyCollapseComponent} from "../../common/fancy-collapse/fancy-collapse.component";
import {CollapseComponent} from "../../common/collapse/collapse.component";

@Component({
  selector: 'app-copino-comparator',
  templateUrl: './copino-comparator.html',
  styleUrls: ['./copino-comparator.component.scss'],
  imports: [
    FormsModule,
    NgForOf,
    FancyCollapseComponent,
    NgIf,
    CollapseComponent
  ],
  standalone: true
})
export class CopinoComparatorComponent implements OnInit {

  handlingId: string;
  differencesOnly: Boolean = undefined;
  result: [CopinoComparatorState];

  ngOnInit(): void {
    this.search();
  }

  search() {
    let constraints = [];
    if (!!this.handlingId) {
      constraints.push({
        "lookAhead": this.handlingId
      });
    }

    if (!!this.differencesOnly) {
      constraints.push({
        "lookAhead": "false",
        "paths" : [
          "sameCount",
          "samePayloads"
        ]
      });
    }

    sendQuery("com.portbase.common.api.admin.SearchFlux", <SearchFlux>{
      query: {
        collection: ["CopinoComparator"],
        requireTimestamp: false,
        constraints: constraints
      },
      maxSize: 30,
      excludeHits: false,
      includeHistogram: false,
      includeHitInfo: false
    }).subscribe(response => {
      this.result = response.hits;
    });
  }

}

export interface SendCopinoData {
  goal: string;
  modality: string;
  visitId: string;
  terminalShortname: string;
  preNotification: boolean;
  messageId: string;
  voyageId: string;
  handlingId: string;
  message: string;
  declarantShortName: string;
}

export interface SendCopinoEmhHeader {
  msgType: string;
  senderOrgShortName: string
  msgFunction: string
  recipientOrgShortName: string
}

export interface SendCopino {
  cancellation: boolean;
  modality: string;
  data: SendCopinoData;
  payload: string;
  processId: string;
  terminal: string;
  emhHeader: SendCopinoEmhHeader;
  subjectId: string;
}

export interface CopinoComparatorState {
  handlingId?: number;
  legacyCopinos?: [SendCopino];
  newCopinos?: [SendCopino];

  sameSize: boolean;
  samePayloads: boolean;
}
