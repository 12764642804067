import {Component, EventEmitter, Input, Output} from '@angular/core';
import {removeItem} from '../../utils';
import {AppContext} from "../../../app-context";

@Component({
  selector: 'dropdown-toggle-filter',
  templateUrl: './dropdown-toggle-filter.component.html',
  styleUrls: ['./dropdown-toggle-filter.component.css']
})
export class DropdownToggleFilterComponent {
  @Input() options:FilterOption[] = [
    {icon: 'fa-arrow-down text-dark', value: 'Loading'},
    {icon: 'fa-arrow-up text-dark', value: 'Discharge'},
    {icon: 'fa-times-circle text-danger', value: 'Rejected'},
    {icon: 'fa-times text-secondary', value: 'Cancelled'},
    {icon: 'fa-lock-open text-danger', value: 'Blocked'},
    {icon: 'fa-file-alt text-danger', value: 'Documents Missing'},
    {icon: 'fa-clock text-danger', value: 'Cargo Opening'},
    {icon: 'fa-key text-danger', value: 'Acceptance Reference'},
    {icon: 'fa-euro-sign text-danger', value: 'Commercial Release'},
    {icon: 'fa-check-square text-danger', value: 'Container Present'},
    {icon: 'fa-question text-secondary', value: 'Other Issue'}
  ];
  @Output() changed = new EventEmitter<FilterValues>();

  values:FilterValues = {selected: [], deselected: []};
  appContext = AppContext;

  hasSelection(): boolean {
    return this.values.selected.length > 0 || this.values.deselected.length > 0
  }

  isOn(option:FilterOption): boolean {
    return this.values.selected.some(o => option.value === o);
  }

  isOff(option:FilterOption): boolean {
    return this.values.deselected.some(o => option.value === o);
  }

  isToggled(option:FilterOption): boolean {
    return this.isOn(option) || this.isOff(option);
  }

  toggle(option:FilterOption) {
    if (this.isOn(option)) {
      this.toggleOff(option);
    } else if (this.isOff(option)) {
      this.toggleOff(option)
    } else {
     this.toggleOn(option);
    }
  }

  toggleOn(option:FilterOption) {
    removeItem(this.values.deselected, option.value);
    const existingOption = this.values.selected.find(o => option.value === o);
    if (existingOption) {
      removeItem(this.values.selected, option.value);
    } else {
      this.values.selected.push(option.value);
    }
    this.changed.emit(this.values);
  }

  toggleOff(option:FilterOption) {
    removeItem(this.values.selected, option.value);
    const existingOption = this.values.deselected.find(o => option.value === o);
    if (existingOption) {
      removeItem(this.values.deselected, option.value);
    } else {
      this.values.deselected.push(option.value);
    }
    this.changed.emit(this.values);
  }

  resetAll() {
    this.values = {selected: [], deselected: []};
    this.changed.emit(this.values);
  }
}

export interface FilterOption {
  icon: string;
  value: string;
}

export interface FilterValues {
  selected: string[];
  deselected: string[];
}
