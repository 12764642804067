<div *ngIf="'VISIT' === getMode()" class="row bg-light">
  <div class="col-auto" style="width: 4rem">#</div>
  <div class="col col-md-3 col-xl-2" style="max-width: 12rem">Timestamp
    <span [ngClass]="sortDirection == 'ascending' ? 'fa fa-sort-down': 'fa fa-sort-up'" (click)="toggleSort()"></span>
  </div>
  <div class="col-3">Action</div>
  <div class="col-1" style="min-width: 9rem; max-width: 10rem">Call Id</div>
  <div class="col" style="width: 2rem">Status</div>
  <div class="col col-md-3 col-xl-2" style="max-width: 16rem">
    <app-tooltip>
      <div class="tooltipContent">
        <div><span class="fa fa-stack fa-1x fa-truck modality me-1" style="font-size:1em;width:1em"></span>&nbsp;ETA
          requested by truck operator / road haulier.
        </div>
        <div><span class="fa fa-stack fa-1x fa-ship modality me-1" style="font-size:1em;width:1em"></span>&nbsp;ETA
          requested by barge operator.
        </div>
        <div><span class="fa-stack fa-1x mt-n1 me-2" style="font-size:1em;width:1em"><i
          class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white"
                                                        style="font-size:0.8em">O</strong></span>&nbsp;ETA requested by
          rail operator.
        </div>
        <div><span class="fa-stack fa-1x" style="font-size:1em;width:1em"><i
          class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x fa-inverse text-dark"
                                                        style="font-size:0.8em">T</strong></span>&nbsp;ETA according to
          terminal planning.
        </div>
        <div><span class="fa fa-ellipsis-v"></span>&nbsp;ETA according to Integral planning</div>
      </div>
      <span>ETA</span></app-tooltip>
  </div>
  <div class="col-lg" style="max-width: 8rem">Changed by</div>
  <div class="col-auto" style="min-width: 4rem"></div>
</div>

<div *ngIf="'HANDLING' === getMode()" class="row bg-light">
  <div class="col-auto" style="width: 4rem">#</div>
  <div class="col col-md-3 col-xl-2" style="max-width: 12rem">Timestamp</div>
  <div class="col-3">Action</div>
  <div class="col-1" style="min-width: 9rem; max-width: 12rem">Call Id</div>
  <div class="col col-lg" style="min-width: 3rem;max-width:5rem;">Status</div>
  <div class="col" style="min-width: 2rem;max-width: 3rem">OK?</div>
  <div class="col col-lg" style=" max-width: 8rem">
    <app-tooltip>
      <div class="tooltipContent">Are there declarations waiting to be sent, then this declaration is queued. A
        declaration is only sent when the previous declaration has been answered by the terminal.
      </div>
      <span>Queued?</span></app-tooltip>
  </div>
  <div class="col col-md-3 col-xl-2" style="max-width: 16rem">
    <app-tooltip>
      <div class="tooltipContent">
        <div><span class="fa fa-stack fa-1x fa-truck modality me-1" style="font-size:1em;width:1em"></span>&nbsp;ETA
          requested by truck operator / road haulier.
        </div>
        <div><span class="fa fa-stack fa-1x fa-ship modality me-1" style="font-size:1em;width:1em"></span>&nbsp;ETA
          requested by barge operator.
        </div>
        <div><span class="fa-stack fa-1x mt-n1 me-2" style="font-size:1em;width:1em"><i
          class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white"
                                                        style="font-size:0.8em">O</strong></span>&nbsp;ETA requested by
          rail operator.
        </div>
        <div><span class="fa-stack fa-1x" style="font-size:1em;width:1em"><i
          class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-dark" style="font-size:0.8em">T</strong></span>&nbsp;ETA
          according to terminal planning.
        </div>
        <div><span class="fa fa-ellipsis-v"></span>&nbsp;ETA according to Integral planning</div>
      </div>
      <span>ETA</span></app-tooltip>
  </div>
  <div class="col-auto" style="min-width: 4rem"></div>
</div>

<ng-container *ngFor="let item of items; let index = index">
  <ng-container *ngIf="'VISIT' === getMode()" [ngTemplateOutletContext]="{ item : item, index: index }"
                [ngTemplateOutlet]="visitTemplate"></ng-container>
  <ng-container *ngIf="'HANDLING' === getMode()" [ngTemplateOutletContext]="{ item : item, index: index }"
                [ngTemplateOutlet]="handlingTemplate"></ng-container>
</ng-container>

<ng-template #visitTemplate let-index="index" let-logItem="item">
  <div *ngIf="cast(logItem) as item" class="narrow-gutters item">
    <div class="row pb-2 pt-2">
      <div class="col-auto" style="width: 4rem">
        {{ index + 1 }}.
      </div>
      <div class="col col-md-3 col-xl-2" style="max-width: 12rem">
        <app-date-time [ngModel]="item.timestamp" [readonly]="true"></app-date-time>
      </div>
      <div class="col-3 text-truncate">
        {{ item.action }}
      </div>
      <div class="col-1" style="min-width: 9rem; ">
        <span *ngIf="item?.visitPlanningLogItem?.tar"><b
          class="badge bg-secondary text-white text me-1">TAR</b>{{ item.visitPlanningLogItem.tar }}</span><span
        *ngIf="!item?.visitPlanningLogItem?.tar">{{ item.visitId }}</span>
      </div>
      <div class="col" style="width: 2rem">
        <span
          *ngIf="item?.visitPlanningLogItem?.visitDeclarationStatus==='ACCEPTED' && (item?.visitPlanningLogItem?.statusType !== 'IN_OPERATION' || item?.visitPlanningLogItem?.statusType !== 'COMPLETED')"
          [title]="item?.visitPlanningLogItem?.visitDeclarationStatus" class="fa fa-check-circle text-success"></span>
        <span *ngIf="item?.visitPlanningLogItem?.visitDeclarationStatus==='DECLARED'"
              [title]="item?.visitPlanningLogItem?.visitDeclarationStatus" class="fa fa-paper-plane text-info"></span>
        <span *ngIf="item?.visitPlanningLogItem?.visitDeclarationStatus==='REJECTED'"
              [title]="item?.visitPlanningLogItem?.visitDeclarationStatus"
              class="fa fa-times-circle text-danger"></span>
        <span *ngIf="item?.visitPlanningLogItem?.statusType==='CANCELLED'"
              [title]="item?.visitPlanningLogItem?.statusType"
              class="fa fa-trash-alt text-secondary"></span>
        <span *ngIf="item?.visitPlanningLogItem?.statusType==='IN_OPERATION'"
              [title]="item?.visitPlanningLogItem?.statusType"
              class="fas fa-stack-1x fa-cog fa-spin text-secondary"></span>
        <span *ngIf="item?.visitPlanningLogItem?.statusType==='COMPLETED'"
              [title]="item?.visitPlanningLogItem?.statusType"
              class="fas fa-stack-1x fa-check text-secondary"></span>
      </div>

      <div class="col col-lg-3 col-xl-2" style="max-width: 16rem">
        <div *ngIf="item?.visitPlanningLogItem?.requestedEta" title="Requested ETA">
          <span *ngIf="item.modality==='road'" class="fa fa-stack fa-1x fa-truck modality mt-n1 me-1"
                style="font-size:1em;width:1em"></span>
          <span *ngIf="item.modality==='barge'" class="fa-stack fa-1x fa fa-ship modality mt-n1 me-1"
                style="font-size:1em;width:1em"></span>
          <span *ngIf="item.modality==='rail'" class="fa-stack fa-1x mt-n1 me-2" style="font-size:1em;width:1em">
            <i class="fas fa-square fa-stack-1x"></i>
            <strong class="fa-stack-1x text-white" style="font-size:0.8em">O</strong>
          </span>&nbsp;<app-date-time [ngModel]="item.visitPlanningLogItem.requestedEta"
                                      [readonly]="true"></app-date-time>
        </div>
        <div *ngIf="item?.visitPlanningLogItem?.terminalEta" title="Terminal planned ETA"><span
          class="fa-stack fa-1x mt-n1 me-1" style="font-size:1em;width:1em"><i
          class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white"
                                                        style="font-size:0.8em">T</strong></span>&nbsp;<app-date-time
          [ngModel]="item.visitPlanningLogItem.terminalEta" [readonly]="true"></app-date-time>
        </div>
        <div *ngIf="item?.visitPlanningLogItem?.integralPlanningEta" title="Integral planned ETA"><span
          class="fa fa-stack fa-1x fa-ellipsis-v mt-n1 me-1" style="font-size:1em;width:1em"></span>&nbsp;<app-date-time
          [ngModel]="item.visitPlanningLogItem.integralPlanningEta" [readonly]="true"></app-date-time>
        </div>
      </div>
      <div class="col-lg user-column"><span [title]="item.user">{{ organisationName(item.user) }}</span></div>
      <div class="col-auto text-center">
        <span class="me-2"><a *ngIf="'road'!==item.modality && item.voyageId"
                              [href]="['/rotation/' + item.voyageId + '?eventId=' + item.eventId +(item.visitId?'&visitId=' + item.visitId:'') + (item.handlingId?'&handlingId=' + item.handlingId:'')]"
                              class="text-dark"
                              target="_blank" title="View status at this point in time">
          <h6 class="m-0 btn btn-outline-secondary btn-sm fa fa-external-link-alt"></h6>
        </a></span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #handlingTemplate let-index="index" let-logItem="item">
  <div *ngIf="cast(logItem) as item" class="narrow-gutters item">
    <app-collapse [collapse]="collapse" [showOnLoad]="false">
      <div class="row pb-2 pt-2 collapsible">
        <div class="col-auto" style="width: 4rem">
          {{ index + 1 }}.
        </div>
        <div class="col col-md-3 col-xl-2" style="max-width: 12rem">
          <app-date-time [ngModel]="item.timestamp" [readonly]="true"></app-date-time>
        </div>
        <div class="col-3 text-truncate">
          {{ item.action }}
        </div>
        <div class="col" style="min-width: 9rem; max-width: 12rem">
          <span *ngIf="item?.visitPlanningLogItem?.tar"><b
            class="badge bg-secondary text-white text me-1">TAR</b>{{ item.visitPlanningLogItem.tar }}</span><span
          *ngIf="!item?.visitPlanningLogItem?.tar">{{ item.visitId }}</span>
        </div>
        <div class="col-lg" style="width: 4rem"><span
          *ngIf="item?.handlingStatusLogItem?.declarationStatus==='ACCEPTED'"
          class="fa fa-check-circle text-success"></span><span
          *ngIf="item?.handlingStatusLogItem?.declarationStatus==='DECLARED'"
          class="fa fa-paper-plane text-info"></span><span
          *ngIf="item?.handlingStatusLogItem?.declarationStatus==='REJECTED'"
          class="fa fa-times-circle text-danger"></span><span *ngIf="!item?.handlingStatusLogItem?.declarationStatus"
                                                              class="fa fa-trash-alt text-secondary"></span></div>
        <div class="col" style="width: 2rem"><span
          *ngIf="item?.handlingStatusLogItem?.statusOk && item?.handlingStatusLogItem?.declarationStatus!=='DECLARED'"
          class="fa fa-thumbs-up text-portbase-green"></span><span
          *ngIf="!item?.handlingStatusLogItem?.statusOk && item?.handlingStatusLogItem?.declarationStatus!=='DECLARED'"
          class="fa fa-thumbs-down text-portbase-red"></span>
        </div>
        <div class="col-lg" style="width: 2rem"><span
          *ngIf="item?.handlingStatusLogItem?.messagesInWaitingRoom">Yes</span><span
          *ngIf="!item?.handlingStatusLogItem?.messagesInWaitingRoom">No</span></div>
        <div class="col col-lg-3 col-xl-2" style="max-width: 16rem">
          <div *ngIf="item?.visitPlanningLogItem?.requestedEta" title="Requested ETA">
            <span *ngIf="item.modality==='road'" class="fa fa-stack fa-1x fa-truck modality mt-n1 me-1"
                  style="font-size:1em;width:1em"></span>
            <span *ngIf="item.modality==='barge'" class="fa-stack fa-1x fa fa-ship modality mt-n1 me-1"
                  style="font-size:1em;width:1em"></span>
            <span *ngIf="item.modality==='rail'" class="fa-stack fa-1x mt-n1 me-2" style="font-size:1em;width:1em">
              <i class="fas fa-square fa-stack-1x"></i>
              <strong class="fa-stack-1x text-white" style="font-size:0.8em">O</strong>
            </span>&nbsp;<app-date-time [ngModel]="item.visitPlanningLogItem.requestedEta"
                                        [readonly]="true"></app-date-time>
          </div>
          <div *ngIf="item?.visitPlanningLogItem?.terminalEta" title="Terminal planned ETA"><span
            class="fa fa-stack fa-1x mt-n1 me-1" style="font-size:1em;width:1em"><i
            class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white"
                                                          style="font-size:0.8em">T</strong></span>&nbsp;<app-date-time
            [ngModel]="item.visitPlanningLogItem.terminalEta" [readonly]="true"></app-date-time>
          </div>
          <div *ngIf="item?.visitPlanningLogItem?.integralPlanningEta" title="Integral planned ETA"><span
            class="fa fa-stack fa-1x fa-ellipsis-v mt-n1 me-1" style="font-size:1em;width:1em"></span>&nbsp;<app-date-time
            [ngModel]="item.visitPlanningLogItem.integralPlanningEta" [readonly]="true"></app-date-time>
          </div>
        </div>
        <div class="col-auto text-center">
        <span class="me-2"><a *ngIf="item.voyageId"
                              [href]="['/rotation/' + item.voyageId + '?eventId=' + item.eventId +(item.visitId?'&visitId=' + item.visitId:'') + (item.handlingId?'&handlingId=' + item.handlingId:'')]"
                              class="text-dark"
                              target="_blank" title="View status at this point in time">
          <h6 class="m-0 btn btn-outline-secondary btn-sm fa fa-external-link-alt"></h6>
        </a></span>
          <h6 class="m-0 btn btn-outline-secondary btn-sm fa fa-chevron-right text-dark"></h6>
        </div>
        <ng-template #collapse>
          <div class="collapse">
            <div class="row">
              <div class="col-auto" style="width: 4rem"></div>
              <div class="col-11">
                <div class="row">
                  <div class="col-4">
                    <app-handling-summary *ngIf="item.handlingData"
                                          [handling]="{ handlingData : item.handlingData}"></app-handling-summary>
                  </div>
                  <div class="col-4"></div>
                  <div class="col-4"><span class="label">User: </span><span>{{ item.user }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </app-collapse>
  </div>
</ng-template>
