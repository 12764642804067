<nav class="h-100">
  <div class="p-3 pt-4">
    <div class="nav-item-header sidebar-heading d-flex justify-content-between align-items-center ps-4">
      Services
    </div>
    <ul class="nav flex-column ps-1">
      <li class="nav-item" *ngIf="visible.includes('subscriptions')">
        <a class="nav-link" [ngClass]="{'active' : active === 'subscriptions'}" [routerLink]="['subscriptions']">
          <span class="nav-icon"><i class="fa-light fa-ship p-2"></i></span>
          <span class="nav-text">Subscriptions</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="visible.includes('signallings')">
        <a class="nav-link" [ngClass]="{'active' : active === 'signallings'}" [routerLink]="['signallings']">
          <span class="nav-icon"><i class="fa-light fa-clock p-2"></i></span>
          <span class="nav-text">Signallings</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="visible.includes('users')">
        <a class="nav-link" [ngClass]="{'active' : active === 'users'}" [routerLink]="['users']">
          <span class="nav-icon"><i class="fa-light fa-user-alt p-2"></i></span>
          <span class="nav-text">Users</span>
        </a>
      </li>

      <!-- on mobile -->
      <li class="d-lg-none d-block">
        <a href="#" class="nav-link beamer-button">
          <span class="nav-icon"><i class="fa-light fa-bullhorn p-2"></i></span>
          <span class="nav-text">News and notifications</span>
        </a>
      </li>
      <li class="d-lg-none d-block">
        <a href="#" class="nav-link" routerLink="/binnenhavengeld/update-account">
          <span class="nav-icon"><i class="fa-light fa-user-cog p-2"></i></span>
          <span class="nav-text">Manage account</span>
        </a>
      </li>
      <li class="d-lg-none d-block">
        <a href="#" class="nav-link" (click)="openSupport(); $event.preventDefault()">
          <span class="nav-icon"><i class="fa-light fa-headset p-2"></i></span>
          <span class="nav-text">Support</span>
        </a>
      </li>
      <li class="d-lg-none d-block">
        <a href="#" class="nav-link" (click)="signOut(); $event.preventDefault()">
          <span class="nav-icon"><i class="fa-light fa-sign-out p-2"></i></span>
          <span class="nav-text">Sign out</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
