import {Component, Input, OnInit} from '@angular/core';
import {HinterlandUtils} from '../../hinterland-utils';
import {AppContext} from '../../../app-context';
import {RoadVisit} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-view-road-visit',
  templateUrl: 'view-road-visit.component.html',
  styleUrls: ['view-road-visit.component.css']
})
export class ViewRoadVisitComponent implements OnInit {

  utils = HinterlandUtils;
  context = AppContext;

  @Input() visit: RoadVisit;

  terminal: string;

  ngOnInit(): void {
    this.terminal = HinterlandUtils.getTerminalDisplayName(this.visit.terminal, 'road');
  }

}
