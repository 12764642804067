import {Component, OnInit} from '@angular/core';
import {sendCommand, sendQuery} from '../../common/utils';
import {AppContext} from '../../app-context';
import {
  GetCurrentOrganisationPreferences,
  GetCurrentUserPreferences,
  OrganisationPreferences,
  UpdateCurrentOrganisationPreferences,
  UpdateCurrentUserPreferences,
  UserPreferences
} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-hinterland-app-preferences',
  templateUrl: './app-preferences.component.html',
  styleUrls: ['./app-preferences.component.css']
})
export class AppPreferencesComponent implements OnInit {
  appContext = AppContext;
  userPreferences: UserPreferences;
  organisationPreferences : OrganisationPreferences;

  ngOnInit() {
    sendQuery("com.portbase.common.api.authentication.query.GetCurrentOrganisationPreferences", <GetCurrentOrganisationPreferences>{})
      .subscribe(result => {
        this.organisationPreferences = result;
      });
    sendQuery("com.portbase.common.api.authentication.query.GetCurrentUserPreferences", <GetCurrentUserPreferences>{})
      .subscribe(result => {
        this.userPreferences = result;
      });
  }
  updateOrganisationPreferences() {
    sendCommand('com.portbase.common.api.authentication.command.UpdateCurrentOrganisationPreferences', <UpdateCurrentOrganisationPreferences> {
      preferences: this.organisationPreferences
    }, () => AppContext.registerSuccess('Preferences updated successfully'));
  }

  updateUserPreferences() {
    sendCommand('com.portbase.common.api.authentication.command.UpdateCurrentUserPreferences', <UpdateCurrentUserPreferences> {
      preferences: this.userPreferences
    }, () => {
      AppContext.registerSuccess('Preferences updated successfully');
      AppContext.cachedUserPrefs = this.userPreferences;
    });
  }
}
