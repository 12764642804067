<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Email handling</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body">
    Send the status of this by email.
    <app-form-field>
      <input type="text" class="form-control xl"
             id="emailAddresses" [(ngModel)]="emailAddress"
             required placeholder="Enter email address">
    </app-form-field>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
    <button (click)="emailHandling()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Send</button>
  </div>
</div>
