import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AppContext} from "../../../app-context";
import {
  HandlingStats,
  NextlogicAlert,
  Notification,
  NotificationLevel,
  TerminalNotification,
  Visit,
  VisitStats
} from "@portbase/hinterland-service-typescriptmodels";
import {HandlingModel, HinterlandUtils} from "../../hinterland-utils";
import {SummaryContext} from "../../common/voyage-summary/voyage-summary.component";

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnChanges {

  appContext = AppContext;
  @Input() topMargin: number = 2;
  @Input() compactView: boolean = false;

  @Input() alerts: NextlogicAlert[];
  @Input() visitSummary: VisitStats;
  @Input() handlingSummary: HandlingStats;
  @Input() notificationContext: SummaryContext;
  terminalDisplayName: String;

  @Input() set forVisit(visit: Visit) {
    this.viewType = "visit";
    this.notifications = [];

    if (!!visit.terminal) {
      this.terminalDisplayName = HinterlandUtils.getTerminalDisplayName(visit.terminal, visit.modality);
    }

    if (!!visit['integralPlanningStatus'] && !!visit['integralPlanningStatus'].alerts) {
      this.notifications.push(...visit['integralPlanningStatus'].alerts)
    }

    if (!!visit.plannedVisitData && !!visit['plannedVisitData']['notifications']) {
      this.notifications.push(...visit['plannedVisitData']['notifications'])
    }

    this.getNotificationCounters();
    this.totalAmountOfHighPriorityHandlingNotifications = this.countNotificationsForLevel(visit.handlingSummary, "HIGH");
    this.totalAmountOfMediumPriorityHandlingNotifications = this.countNotificationsForLevel(visit.handlingSummary, "MEDIUM");
    this.highPriorityHandlingNotifications = this.setNotificationSummary(visit.handlingSummary, "HIGH");
    this.mediumPriorityHandlingNotifications = this.setNotificationSummary(visit.handlingSummary, "MEDIUM");
    this.totalAmountOfHighPriorityNotifications = this.highLevelApplicableNotifications.length + this.totalAmountOfHighPriorityVisitNotifications + this.totalAmountOfHighPriorityHandlingNotifications;
    this.totalAmountOfMediumPriorityNotifications = this.mediumLevelApplicableNotifications.length + this.totalAmountOfMediumPriorityVisitNotifications + this.totalAmountOfMediumPriorityHandlingNotifications;

    this.showHighPriorityNotifications = true;
    this.showMediumPriorityNotifications = true;
  }

  @Input() set forHandling(handling: HandlingModel) {
    this.viewType = "handling";
    this.notifications = [];

    if (handling.terminal) {
      this.terminalDisplayName = HinterlandUtils.getTerminalDisplayName(handling.terminal, handling.modality);
    }

    if (handling['integralPlanningStatus'] && handling['integralPlanningStatus'].alerts) {
      this.notifications.push(...handling['integralPlanningStatus'].alerts)
    }

    if (!!handling.handlingStatus && !!handling.handlingStatus.notifications) {
      this.notifications.push(...handling.handlingStatus.notifications)
    }

    this.setNotificationsAndCountersForHandling();
  }

  @Input() set roadHandlingNotifications(notifications: TerminalNotification[]) {
    this.viewType = "handling";
    if (!!notifications) {
      this.notifications = notifications;
    }
    this.setNotificationsAndCountersForHandling();
  }

  @Input() set roadVisitNotifications(notifications: TerminalNotification[]) {
    this.viewType = "visit";
    if (!!notifications) {
      this.notifications = notifications;
    }
    this.setNotificationsAndCountersForHandling();
  }

  notifications: Notification[] = [];

  applicableNotifications: Notification[] = [];
  highLevelApplicableNotifications: Notification[] = [];
  mediumLevelApplicableNotifications: Notification[] = [];
  lowLevelApplicableNotifications: Notification[] = [];

  totalAmountOfHighPriorityNotifications: number = 0;
  totalAmountOfMediumPriorityNotifications: number = 0;

  totalAmountOfHighPriorityVoyageNotifications: number = 0;
  totalAmountOfMediumPriorityVoyageNotifications: number = 0;

  totalAmountOfHighPriorityVisitNotifications: number = 0;
  totalAmountOfMediumPriorityVisitNotifications: number = 0;
  highPriorityVisitNotifications: String[] = [];
  mediumPriorityVisitNotifications: String[] = [];

  totalAmountOfHighPriorityHandlingNotifications: number = 0;
  totalAmountOfMediumPriorityHandlingNotifications: number = 0;
  highPriorityHandlingNotifications: String[] = [];
  mediumPriorityHandlingNotifications: String[] = [];

  viewType: ViewType = "voyage";

  showHighPriorityNotifications: boolean;
  showMediumPriorityNotifications: boolean;
  showVoyageHighPriorityNotifications: boolean;
  showVoyageMediumPriorityNotifications: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alerts) {
      this.notifications = this.alerts ? <Notification[]>this.alerts : [];
      this.getNotificationCounters();
    }

    if (changes.visitSummary) {
      this.totalAmountOfHighPriorityVisitNotifications = this.countNotificationsForLevel(this.visitSummary, "HIGH");
      this.totalAmountOfMediumPriorityVisitNotifications = this.countNotificationsForLevel(this.visitSummary, "MEDIUM");
      this.highPriorityVisitNotifications = this.setNotificationSummary(this.visitSummary, "HIGH");
      this.mediumPriorityVisitNotifications = this.setNotificationSummary(this.visitSummary, "MEDIUM");
    }

    if (changes.handlingSummary) {
      this.totalAmountOfHighPriorityHandlingNotifications = this.countNotificationsForLevel(this.handlingSummary, "HIGH");
      this.totalAmountOfMediumPriorityHandlingNotifications = this.countNotificationsForLevel(this.handlingSummary, "MEDIUM");
      this.highPriorityHandlingNotifications = this.setNotificationSummary(this.handlingSummary, "HIGH");
      this.mediumPriorityHandlingNotifications = this.setNotificationSummary(this.handlingSummary, "MEDIUM");
    }

    this.totalAmountOfHighPriorityVoyageNotifications = this.highLevelApplicableNotifications.length;
    this.totalAmountOfMediumPriorityVoyageNotifications = this.mediumLevelApplicableNotifications.length;

    this.totalAmountOfHighPriorityNotifications = this.highLevelApplicableNotifications.length + this.totalAmountOfHighPriorityVisitNotifications + this.totalAmountOfHighPriorityHandlingNotifications;
    this.totalAmountOfMediumPriorityNotifications = this.mediumLevelApplicableNotifications.length + this.totalAmountOfMediumPriorityVisitNotifications + this.totalAmountOfMediumPriorityHandlingNotifications;

    this.shouldShowNotificationsOnSummary();
  }

  private setNotificationsAndCountersForHandling() {
    this.getNotificationCounters();
    this.totalAmountOfHighPriorityNotifications = this.highLevelApplicableNotifications.length + this.totalAmountOfHighPriorityVisitNotifications + this.totalAmountOfHighPriorityHandlingNotifications;
    this.totalAmountOfMediumPriorityNotifications = this.mediumLevelApplicableNotifications.length + this.totalAmountOfMediumPriorityVisitNotifications + this.totalAmountOfMediumPriorityHandlingNotifications;

    this.showHighPriorityNotifications = true;
    this.showMediumPriorityNotifications = true;
  }

  getNotificationCounters() {
    if (this.notifications) {
      this.applicableNotifications = this.notifications.filter(a => (this.appContext.isAdmin() || this.appContext.isHinterlandTerminalAndDeclarant()) ? true :
        this.appContext.isHinterlandDeclarant() ? a.recipients.includes("INLAND_TRANSPORT_OPERATOR") :
          this.appContext.isHinterlandTerminal() ? a.recipients.includes("TERMINAL") : false);
    } else {
      this.applicableNotifications = this.notifications;
    }

    this.lowLevelApplicableNotifications = this.getNotificationsForLevel('LOW');
    this.mediumLevelApplicableNotifications = this.getNotificationsForLevel('MEDIUM');
    this.highLevelApplicableNotifications = this.getNotificationsForLevel('HIGH');
  }

  shouldShowNotificationsOnSummary() {
    let voyageDetail = this.notificationContext == 'detail' && this.viewType == 'voyage';
    let voyageOverview = this.notificationContext == 'overview' && this.viewType == 'voyage';

    this.showVoyageHighPriorityNotifications = voyageDetail && this.totalAmountOfHighPriorityVoyageNotifications > 0;
    this.showVoyageMediumPriorityNotifications = voyageDetail && this.totalAmountOfMediumPriorityVoyageNotifications > 0;
    this.showHighPriorityNotifications = !voyageDetail && this.totalAmountOfHighPriorityNotifications > 0;
    this.showMediumPriorityNotifications = !voyageDetail && this.totalAmountOfMediumPriorityNotifications > 0 && !(voyageOverview && this.totalAmountOfHighPriorityNotifications > 0);
  }

  countNotificationsForLevel(stats: VisitStats | HandlingStats, level: NotificationLevel): number {
    if (!stats) return 0;

    let actionableNotificationsCount = 0;
    if (this.appContext.isAdmin() || this.appContext.isHinterlandTerminalAndDeclarant()) {
      actionableNotificationsCount = level == 'HIGH' ? stats.allHighPriorityNotificationsCount : stats.allMediumPriorityNotificationsCount || 0;
    } else if (this.appContext.isHinterlandDeclarant()) {
      actionableNotificationsCount = level == 'HIGH' ? stats.highPriorityBargeOperatorNotificationsCount : stats.mediumPriorityBargeOperatorNotificationsCount || 0;
    } else if (this.appContext.isHinterlandTerminal()) {
      actionableNotificationsCount = level == 'HIGH' ? stats.highPriorityTerminalNotificationsCount : stats.mediumPriorityTerminalNotificationsCount || 0;
    }
    return actionableNotificationsCount;
  }

  private setNotificationSummary(stats: VisitStats | HandlingStats, level: NotificationLevel): String[] {
    if (!stats) return [];

    let notificationSummary = [];
    if (this.appContext.isAdmin() || this.appContext.isHinterlandTerminalAndDeclarant()) {
      notificationSummary = notificationSummary.concat(level == 'HIGH' ? stats.highPriorityNotifications : stats.mediumPriorityNotifications);
    } else if (this.appContext.isHinterlandDeclarant()) {
      notificationSummary = notificationSummary.concat(level == 'HIGH' ? stats.highPriorityBargeOperatorNotifications : stats.mediumPriorityBargeOperatorNotifications);
    } else if (this.appContext.isHinterlandTerminal()) {
      notificationSummary = notificationSummary.concat(level == 'HIGH' ? stats.highPriorityTerminalNotifications : stats.mediumPriorityTerminalNotifications);
    }

    return notificationSummary;
  }

  getNotificationsForLevel(level: NotificationLevel): Notification[] {
    return this.applicableNotifications.filter(a => a.level == level || (level == "LOW" && !a.level));
  }

  getLabel(notification: Notification) {
    let label = "";
    switch (notification.senderType) {
      case "INTEGRAL_PLANNING":
        label += "Nextlogic ";
        break;
      case "TERMINAL":
        label += (!!this.terminalDisplayName ? this.terminalDisplayName : "Terminal");
        break;
      default:
        label += "Sender ";
    }
    return label;
  }

  isAre(amount: number) {
    return amount == 1 ? 'is' : 'are';
  }

  sIfMultiple(amount: number) {
    return amount == 1 ? '' : 's';
  }

  sIfSingle(amount: number) {
    return amount == 1 ? 's' : '';
  }

  anIfSingle(amount: number) {
    return amount == 1 ? 'an ' : '';
  }

}

type ViewType = "voyage" | "visit" | "handling"
