import {Upload, UploadData} from "../../../common/upload/upload";
import {
  ArrayTemplate, Field,
  MappedField,
  parseExcel, Parser,
  RequiredField,
  RequiredIfField,
  toBase64,
  ValidatedField,
  WorkBookTemplate
} from "../../../common/upload/excel.utils";
import {AppContext} from "../../../app-context";
import {PlannedCargo, Slot, TrainComposition, WagonComposition} from "@portbase/hinterland-service-typescriptmodels";
import {sendCommand} from "../../../common/utils";
import {HttpErrorResponse} from "@angular/common/http";
import {UpdateTrainComposition} from "@portbase/hinterland-service-typescriptmodels/hinterland";


interface ExcelRow {
  wagonPosition: number
  wagonNumber: string
  wagonSlot: number,
  incomingCargo: PlannedCargo,
  outgoingCargo: PlannedCargo
}

interface TrainCompositionUploadData extends UploadData {
  voyageId: string,
  visitId: string
}

export class HandlingIdField extends Field<string> {
  constructor(private field: string, private emptyFields: string[], private nonEmptyFields: string[]) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction) {
    let value = parser.mapAny(this.field, cellNameFunction);
    const cellValue = Field.getCellValue(value);
    if (this.emptyFields.every(cell => parser.isEmpty(cell, cellNameFunction))) {
      return cellValue
    }
    if (cellValue === undefined && this.nonEmptyFields.some(cell => parser.isEmpty(cell, cellNameFunction))) {
      const cell = Field.cellOrNull(value);
      parser.registerError(`Please fill handling id in cell ${cell.cell} in sheet "${cell.sheetName}" or fill cells ${this.nonEmptyFields.map(cellNameFunction).join(", ")}.`);
    }
    return cellValue;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class TrainCompositionUpload extends Upload {
  getRowCount(): number {
    return 200;
  }

  getTemplate(forDetached: boolean = false): WorkBookTemplate {
    return {
      sheets: [
        {
          name: "Guide",
          template: {
            version: new ValidatedField(new RequiredField('C1'), sheetVersion => {
              let expectedVersion = this.getVersion();
              if (sheetVersion !== expectedVersion) {
                throw `The version of your Excel file '${sheetVersion}' is not supported. Please download version '${expectedVersion}' of the template and try again.`;
              }
            })
          }
        },
        {
          name: "Template",
          template: {
            data: new ArrayTemplate({
              wagonPosition: "A$",
              wagonNumber: "B$",
              wagonSlot: new RequiredField("C$"),
              incomingCargo: {
                loadingTerminal: new RequiredIfField("E$", ["D$", "G$", "H$", "I$", "J$", "K$"]),
                dischargeTerminal: new RequiredIfField("F$", ["D$", "G$", "H$", "I$", "J$", "K$"]),
                cargoData: {
                  handlingId: new HandlingIdField("D$", ["E$", "F$", "G$", "H$", "I$", "J$", "K$", "L$", "M$"], ["G$", "H$", "I$", "J$", "K$"]),
                  equipmentNumber: "G$",
                  shippingCompany: new MappedField("H$", this.shippingCompanyMapper),
                  sizeType: new MappedField("I$", this.sizeTypeMapper),
                  full: new MappedField("J$", status => !status ? null : status.toUpperCase() === "F"),
                  grossWeight: "K$",
                  dangerousGood: {
                    unCode: new RequiredIfField("L$", ["M$", "N$"]),
                    hazardClass: new RequiredIfField("M$", ["L$", "N$"]),
                    gevi: new RequiredIfField("N$", ["L$", "M$"])
                  },
                  remarks: "O$"
                }
              },
              outgoingCargo: {
                loadingTerminal: new RequiredIfField("Q$", ["P$", "S$", "T$", "U$", "V$", "W$"]),
                dischargeTerminal: new RequiredIfField("R$", ["P$", "S$", "T$", "U$", "V$", "W$"]),
                cargoData: {
                  handlingId: new HandlingIdField("P$", ["Q$", "R$", "S$", "T$", "Y$", "V$", "W$"], ["S$", "T$", "U$", "V$", "W$"]),
                  equipmentNumber: "S$",
                  shippingCompany: new MappedField("T$", this.shippingCompanyMapper),
                  sizeType: new MappedField("U$", this.sizeTypeMapper),
                  full: new MappedField("V$", status => !status ? null : status.toUpperCase() === "F"),
                  grossWeight: "W$",
                  dangerousGood: {
                    unCode: new RequiredIfField("X$", ["Y$", "Z$"]),
                    hazardClass: new RequiredIfField("Y$", ["X$", "Z$"]),
                    gevi: new RequiredIfField("Z$", ["X$", "Y$"])
                  },
                  remarks: "AA$"
                }
              }
            }, [12, this.getRowCount()])
          }
        }
      ]
    };
  }

  getVersion(): string {
    return "v2.4";
  }

  upload(excelFile: File, data: TrainCompositionUploadData = null) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
      parseExcel(excelFile, this.getTemplate(true))
        .subscribe(model => {
          if (model.data.length === 0) {
            AppContext.registerError('No train composition data found in uploaded excel');
            return;
          }

          const trainComposition = this.mapTrainComposition(model.data);

          const command = {
            '@class': 'io.fluxcapacitor.javaclient.common.Message',
            payload: <UpdateTrainComposition>{
              voyageId: data.voyageId,
              visitId: data.visitId,
              trainComposition: trainComposition
            },
            metadata: {
              'upload': excelBase64
            }
          }

          sendCommand('com.portbase.hinterland.api.traincomposition.UpdateTrainComposition', command, () => {
            AppContext.registerSuccess('Train composition was uploaded successfully');
          }, (error) => {
            if (error instanceof HttpErrorResponse) {
              let errorMessage: string = 'Error(s) occurred during train composition upload: ';

              const httpValidationErrors: Array<string> = AppContext.getHttpValidationErrors(error);
              if (httpValidationErrors.length > 0) {
                httpValidationErrors.forEach(e => AppContext.registerError(`Error in upload: ${e}`));
              } else {
                const httpErrors: Array<string> = AppContext.getHttpErrors(error);
                httpErrors.forEach(e => errorMessage += `${e}. `)
                AppContext.registerError(errorMessage);
              }
            } else {
              AppContext.registerError('Error(s) occurred during train composition upload: ' + error);
            }
          });
        });
    });
  }

  private mapTrainComposition(model: ExcelRow[]): TrainComposition {
    let wagons: WagonComposition[] = [];
    let currentWagon = this.createWagon(model, 0);

    for (let line = 1; line < model.length; line++) {
      const wagonPosition = model[line].wagonPosition;
      if (wagonPosition && wagonPosition !== currentWagon.position) {
        wagons.push(currentWagon)
        currentWagon = this.createWagon(model, line);
      } else {
        const wagonSlot = model[line].wagonSlot
        if (wagonSlot > currentWagon.numberOfSlots) {
          currentWagon.numberOfSlots = wagonSlot;
        }
        const slot = this.createSlot(model, line)
        if (!this.isItemEmpty([slot.incomingCargo, slot.outgoingCargo])) {
          currentWagon.slots.push(slot);
        }
      }
    }
    wagons.push(currentWagon);
    return {
      wagons: wagons
    }
  }

  private createWagon(model: ExcelRow[], line: number) {
    return {
      position: model[line].wagonPosition,
      wagonNumber: model[line].wagonNumber,
      slots: this.addFirstSlot(model, line),
      numberOfSlots: model[line].wagonSlot
    };
  }

  private addFirstSlot(model: ExcelRow[], line: number) {
    if (!this.isItemEmpty([model[line].incomingCargo, model[line].outgoingCargo])) {
      return [this.createSlot(model, line)];
    }
    return [];
  }

  private createSlot(model: ExcelRow[], line: number): Slot {
    return <Slot>{
      number: model[line].wagonSlot,
      incomingCargo: model[line].incomingCargo,
      outgoingCargo: model[line].outgoingCargo
    };
  }

  private isObjEmpty = (obj: any) => obj === null || Object.values(obj).every(this.isItemEmpty);

  private isItemEmpty = (item: any) => item === undefined || item === "" ||
    (Array.isArray(item) && item.every(this.isItemEmpty)) ||
    (typeof item === "object" && this.isObjEmpty(item));
}
