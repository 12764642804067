import {Component, ElementRef, OnInit, Type} from '@angular/core';
import {
  DetachedHandling,
  RoadHandling,
  RoadVisit,
  RoadVisitData,
  RoadVoyage,
  RoadVoyageData
} from "@portbase/hinterland-service-typescriptmodels";
import {checkValidity, scrollToTopInPanel} from "../../../../common/utils";
import {HinterlandUtils, TerminalModel} from "../../../../hinterland/hinterland-utils";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";

import {AppContext} from "../../../../app-context";
import {EditModalService, ModalData} from '../../../../components/modals/edit-modal/edit-modal.service';
import {
  CloseEditingDialogComponent
} from '../../../../components/dialogs/close-editing-dialog/close-editing-dialog.component';
import {ActionAddMode} from '../../../../hinterland/types';
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";

interface AddVisitOffCanvasData {
  addMode?: ActionAddMode;
  voyage?: RoadVoyage;
  visit?: RoadVisit;
  handling?: RoadHandling | DetachedHandling;
}

@Component({
  selector: 'app-add-visit-offcanvas',
  templateUrl: './app-add-visit-offcanvas.html',
  styleUrls: ['./app-add-visit-offcanvas.scss']
})
export class AddVisitOffCanvasComponent<T extends ModalData<AddVisitOffCanvasData>> extends HinterlandBaseComponent implements OnInit, ClosableModal {

  utils = HinterlandUtils;

  isModalClosable: boolean = true;
  errors: string[] = [];
  validationErrors: boolean;

  data: T['data'] = {};

  voyage: RoadVoyage;
  visit: RoadVisit;

  terminals: TerminalModel[] = [];

  constructor(
    private element: ElementRef,
    private readonly editModalService: EditModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.errors = [];

    this.utils.getTerminals('road', true, true, true).subscribe(terminals => this.terminals = terminals);

    let isAttached = this.data?.visit;

    this.visit = <RoadVisit>{
      terminal: isAttached ? {...this.data?.visit?.terminal} : this.data?.handling['terminal'],
      visitData: <RoadVisitData>{
        eta: isAttached ? this.data?.visit?.requestedVisitData?.eta : this.data?.handling['eta']
      }
    }

    this.voyage = <RoadVoyage>{
      voyageData: <RoadVoyageData>{}
    }
  }

  declareVisit() {
    this.setModalClosable(true);

    if (checkValidity(this.element)) {
      this.validationErrors = false;
      this.queryAndCommandGateway.upgradeRoadHandling(this.data.handling as any, this.visit.terminal,
        this.voyage.voyageData.truckLicenseId, this.voyage.voyageData.charter, this.visit.visitData.eta,
        () => {
          AppContext.publishSuccessMessage("The visit was created successfully.");
          this.closeModal();
        },
        (error) => {
          this.errors.push(error?.error?.error);
          scrollToTopInPanel();
        }
      );
    } else {
      this.validationErrors = true;
      scrollToTopInPanel();
    }
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

  switchModal() {
    this.closeModal();
  }

  getCloseDialogComponentType(): Type<unknown> {
    return CloseEditingDialogComponent;
  }

}
