<div class="row">
  <div class="col-12">
    <app-form-field label="Name">
      <input [(ngModel)]="companyDetails.name" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>

  <div class="col-6">
    <app-form-field label="Street">
      <input [(ngModel)]="companyDetails.street" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>

  <div class="col-3">
    <app-form-field label="Number" [renderOptional]="false">
      <input [(ngModel)]="companyDetails.streetNumber" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>

  <div class="col-3">
    <app-form-field label="Suffix" [renderOptional]="false">
      <input [(ngModel)]="companyDetails.streetNumberSuffix" class="form-control">
    </app-form-field>
  </div>

  <div class="col-6">
    <app-form-field label="Postal code">
      <input [(ngModel)]="companyDetails.postalCode" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>

  <div class="col-6">
    <app-form-field label="City">
      <input [(ngModel)]="companyDetails.city" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>

  <div class="col-12">
    <app-form-field label="Country">
      <input [(ngModel)]="companyDetails.country" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>

  <ng-container *ngIf="!externalCompany">

    <div class="col-8">
      <app-form-field label="IBAN">
        <input [(ngModel)]="companyDetails.iban" class="form-control">
      </app-form-field>
    </div>

    <div class="col-4">
      <app-form-field label="BIC">
        <input [(ngModel)]="companyDetails.bic" class="form-control">
      </app-form-field>
    </div>

    <div class="col-12">
      <app-form-field label="Chamber of commerce number">
        <input [(ngModel)]="companyDetails.cocNumber" class="form-control">
      </app-form-field>
    </div>

    <div class="col-12">
      <app-form-field label="VAT number">
        <input [(ngModel)]="companyDetails.vatNumber" class="form-control">
      </app-form-field>
    </div>

  </ng-container>
</div>
