<div class="row justify-content-center align-items-center
            sticky-top bg-light border-bottom border-grey py-2 mx-0" style="top: 4.5rem; z-index: 999">
  <div class="col filters">
    <div style="max-width: 400px" class="border rounded border-grey">
      <app-filter [(ngModel)]="filterTerm" [searchFunction]="searchOrFilter" (resultsFound)="renderFilteredItems()"
                  [placeholder]="'Filter'"></app-filter>
    </div>
  </div>

  <div class="col-auto add">
    <button type="button" class="btn btn-primary" (click)="addSubscription();">
      <i class="button-icon fa-light fa-plus"></i> <span class="ps-2 d-none d-md-inline">Add subscription</span>
    </button>
  </div>
</div>

<div class="row g-0 justify-content-center">
  <div class="col">
    <ng-container *ngFor="let item of items;">
      <app-bhg-subscription [subscription]="item"></app-bhg-subscription>
    </ng-container>
    <h6 *ngIf="items.length === 0" class="text-center fw-normal text-muted my-4">
      <span *ngIf="filterTerm; else (initialized ? noItemsYet : initializing)">
        No matching subscriptions found.
      </span>
      <ng-template #noItemsYet>
        No subscriptions found.
        <a href="#" (click)="addSubscription();$event.preventDefault()">Add a subscription</a>.
      </ng-template>
      <ng-template #initializing>
        Loading subscriptions...
        <span class="ms-2 spinner-border spinner-border-sm text-secondary" role="status"></span>
      </ng-template>
    </h6>
  </div>
</div>

