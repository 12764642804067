import {Component} from '@angular/core';
import {AppContext} from "../app-context";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent {
    appContext = AppContext;
}
