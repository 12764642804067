import {Component, ElementRef, OnInit, Type} from '@angular/core';
import {HinterlandUtils, TerminalModel} from "../../../../hinterland/hinterland-utils";
import {
  Charter,
  DeepSeaReference,
  DetachedHandling,
  RoadHandling,
  RoadHandlingData,
  RoadVisit,
  RoadVoyage,
  Terminal,
  TerminalSettings
} from "@portbase/hinterland-service-typescriptmodels";
import {checkValidity, cloneObject, scrollToTopInPanel, sendQuery, uuid} from "../../../../common/utils";
import {filter} from "rxjs/operators";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {ContainerNumberValidator} from "../../../../common/container-number-validator";
import {AppContext} from "../../../../app-context";
import {ActionAddMode, VoyageVisitAndHandling} from "../../../../hinterland/types";
import {AppData} from "../../../app-data";
import {EditHandlingOffCanvasComponent} from "../edit-handling-offcanvas/app-edit-handling-offcanvas";
import { EditModalService, ModalData } from '../../../../components/modals/edit-modal/edit-modal.service';
import {
  CloseEditingDialogComponent
} from '../../../../components/dialogs/close-editing-dialog/close-editing-dialog.component';
import {Subject} from "rxjs";
import {RoadHandlingsUpload} from "../../../../hinterland/road/road-handlings.upload";
import moment from "moment";
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";
import {InjectorProvider} from "../../../../common/injector-provider";

interface AddHandlingOffCanvasData {
  addMode?: ActionAddMode;
  handling?: RoadHandling | DetachedHandling;
  voyage?: RoadVoyage;
  visit?: RoadVisit;
}

@Component({
  selector: 'app-add-handling-offcanvas',
  templateUrl: './app-add-handling-offcanvas.html',
  styleUrls: ['./app-add-handling-offcanvas.scss']
})
export class AddHandlingOffCanvasComponent<T extends ModalData<AddHandlingOffCanvasData>> extends HinterlandBaseComponent implements OnInit, ClosableModal {

  isModalClosable: boolean = true;
  handlingData: RoadHandlingData;

  data: T['data'] = {};

  duplicate: VoyageVisitAndHandling;

  terminals: TerminalModel[];
  terminal: Terminal;
  eta: string;
  truckLicenseId: string;
  charter: Charter;

  terminalSettings: TerminalSettings;
  reefer: boolean = false;
  outOfGauge: boolean = false;
  temperature: boolean = false;

  invalidEquipmentNumberLength: boolean;
  invalidEquipmentNumberCheckDigit: boolean;
  validationErrors: boolean;

  etaInPast: boolean;

  file: File;
  errors: String[] = [];
  uploading: boolean;

  utils = HinterlandUtils;
  appContext = AppContext;
  appData: AppData;
  title: string = 'Add handling';

  disableSubmit: Subject<boolean>;

  constructor(
      private element: ElementRef,
      private readonly editModalService: EditModalService,
  ) {
    super();
    this.disableSubmit = new Subject<boolean>();
  }

  ngOnInit(): void {
    this.appData = InjectorProvider.injector.get(AppData);
    this.errors = [];
    this.utils.getTerminals('road', true, true, true).subscribe(terminals => this.terminals = terminals);

    this.handlingData = {
      modality: 'road',
      dangerousGoods: [],
      shippersOwned: false,
      type: 'loading',
      full: true
    };

    if (this.data) {
      if (this.data?.handling) {
        const incomingHandlingData = this.data.handling.handlingData;
        this.handlingData = cloneObject(this.data.handling.handlingData);

        if (incomingHandlingData.deepSea) {
          this.handlingData.deepSea = incomingHandlingData.deepSea;
        } else if (this.data.handling.deepSea) {
          this.handlingData.deepSea = <DeepSeaReference> {
            imoCode: this.data.handling.deepSea.imoCode,
            shipName: this.data.handling.deepSea.shipName,
            crnNumber: this.data.handling.deepSea.crnNumber
          }
        }

        this.reefer = !!incomingHandlingData.reefer;
        this.outOfGauge = !!incomingHandlingData.outOfGauge;
        this.temperature = !!incomingHandlingData.reefer && !!incomingHandlingData.reefer.temperature;
      }

      if (this.data.visit) {
        this.eta = this.data.visit.visitData.eta;
        this.terminal = this.data.visit.terminal;
        this.setTerminalSettings();
      } else {
        this.eta = this.data.handling['eta'];
        this.terminal = this.data.handling['terminal'];
        this.setTerminalSettings();
      }

      if (this.data.voyage) {
        this.truckLicenseId = this.data.voyage.voyageData.truckLicenseId;
        this.charter = this.data.voyage.voyageData.charter;
      }

      if (this.data.addMode === 'duplicate') {
        this.title = 'Duplicate handling';
        this.handlingData.equipmentNumber = "";
        let terminal = this.terminals?.find(t => t.bicsCode === this.terminal.bicsCode);
        if (!terminal) {
          this.terminal = undefined;
        }
      } else if (this.data.addMode === 'addToVisit') {
        this.title = 'Add new handling';
      }
    }
  }

  onTerminalChanged() {
    this.setTerminalSettings();
    this.checkForDuplicateHandling();
  }

  onEquipmentNumberChanged() {
    this.checkForDuplicateHandling();
  }

  onHandlingTypeChanged() {
    this.setTerminalSettings();
    this.checkForDuplicateHandling();
  }

  checkForDuplicateHandling() {
    for (const roadItem of this.appData.roadItems) {
      if (roadItem.isVoyage) {
        let voyage: RoadVoyage = roadItem.getVoyage();
        const duplicate: VoyageVisitAndHandling = this.checkForDuplicateVoyageHandling(voyage);
        if (duplicate) {
          this.duplicate = duplicate;
          return;
        }
      } else {
        let detachedHandling: DetachedHandling = roadItem.getDetachedHandling();
        const duplicate: VoyageVisitAndHandling = this.checkForDuplicateDetachedHandling(detachedHandling);
        if (duplicate) {
          this.duplicate = duplicate;
          return;
        }
      }
    }

    this.duplicate = undefined;
  }

  checkForDuplicateVoyageHandling(voyage: RoadVoyage): VoyageVisitAndHandling {
    if (!this.terminal || voyage.declarant.shortName !== AppContext.userProfile.organisationShortName) {
      return undefined;
    }

    for (const visit of voyage.visits) {
      if (visit.terminal.bicsCode !== this.terminal.bicsCode || visit.visitCompleted) {
        continue;
      }

      for (const handling of visit.handlings) {
        if (handling.completed === false
          && handling.handlingData.equipmentNumber
          && handling.handlingData.equipmentNumber === this.handlingData.equipmentNumber
          && handling.handlingData.type === this.handlingData.type) {
          return <VoyageVisitAndHandling>{
            voyage: voyage,
            visit: visit,
            handling: handling
          };
        }
      }
    }

    return undefined;
  }

  checkForDuplicateDetachedHandling(detachedHandling: DetachedHandling): VoyageVisitAndHandling {
    if (!this.terminal || detachedHandling.declarant.shortName !== AppContext.userProfile.organisationShortName) {
      return undefined;
    }

    if (detachedHandling.terminal.bicsCode === this.terminal.bicsCode
      && detachedHandling.handlingData.equipmentNumber
      && detachedHandling.handlingData.equipmentNumber === this.handlingData.equipmentNumber
      && detachedHandling.handlingData.type === this.handlingData.type) {
      return <VoyageVisitAndHandling>{
        voyage: undefined,
        visit: undefined,
        handling: detachedHandling
      };
    }

    return undefined;
  }

  openDuplicateHandling() {
    this.editModalService.openModal(EditHandlingOffCanvasComponent, this.duplicate);
  }

  setTerminalSettings() {
    this.terminalSettings = this.terminal?.terminalSettings.find(c =>
      c.full === !!this.handlingData.full
      && c.loading === (this.handlingData.type === 'loading')
      && c.modality === 'road') || <TerminalSettings>{};

    if (!this.terminalSettings || !this.terminalSettings.outOfGaugeAllowed) {
      this.handlingData.outOfGauge = null;
      this.outOfGauge = false;
    }

    if (!this.terminalSettings || !this.terminalSettings.reeferAllowed) {
      this.handlingData.reefer = null;
      this.reefer = false;
      this.temperature = false;
    }

    if (!this.terminalSettings || !this.terminalSettings.grossWeightAllowed) {
      this.handlingData.tareWeight = null;
      this.handlingData.weight = null;
    }

    if (!this.terminalSettings || !this.terminalSettings.sealNumberAllowed) {
      this.handlingData.sealNumber = null;
    }
  }

  reeferChanged() {
    this.handlingData.reefer = this.reefer ? {
      temperatureFixed: false
    } : null;
  }

  outOfGaugeChanged() {
    this.handlingData.outOfGauge = this.outOfGauge ? {} : null;
  }

  equipmentNumberRequired() {
    return this.handlingData.full === true
      || (this.handlingData.type === 'discharge' && !this.utils.isLossenOpRichtlijnAllowed(this.terminal, 'road'));
  }

  onContainerNumberPaste(event: ClipboardEvent) {
    const pastedContainerNumber = event.clipboardData.getData('text');
    this.handlingData.equipmentNumber = pastedContainerNumber.replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
    event.preventDefault();
  }

  addShortName = (charter: Charter) => {
    if (charter && !charter.portbaseId) {
      sendQuery("com.portbase.hinterland.api.common.query.GetOrganisationByEan",
        {ean: charter.ean}).pipe(filter(r => !!r))
        .subscribe(o => charter.portbaseId = o.shortName);
    }
  };

  submitStatusRequest() {
    this.setModalClosable(true);

    if (checkValidity(this.element)) {
      this.validationErrors = false;
      let detachedHandling: DetachedHandling = <DetachedHandling>{
        handlingId: uuid(),
        handlingData: this.handlingData,
        terminal: this.terminal,
        eta: this.eta
      };

      this.queryAndCommandGateway.declareDetachedHandling(detachedHandling, this.terminal, this.eta,
        () => {
          this.errors = [];
          AppContext.publishSuccessMessage("The handling was created successfully.");
          this.editModalService.closeModal();
        },
        (error) => {
          scrollToTopInPanel();
          this.errors.push(error?.error?.error);
        }
      );
    } else {
      this.validationErrors = true;
      scrollToTopInPanel();
    }
    this.enableSubmit();
  }

  private enableSubmit() {
    this.disableSubmit.next(false);
  }

  submitPreNotification() {
    this.setModalClosable(true);

    if (checkValidity(this.element)) {
      this.validationErrors = false;
      let handling: RoadHandling = <RoadHandling>{
        handlingId: uuid(),
        handlingData: this.handlingData,
        preNotification: true,
        handlingDeclarations: []
      };

      if (this.data?.addMode === 'addToVisit') {
        this.queryAndCommandGateway.declareHandling(handling, true, this.data?.voyage?.voyageId, this.data?.visit?.visitId,
          () => {
            this.errors = [];
            AppContext.publishSuccessMessage("The handling was created successfully.");
            this.editModalService.closeModal();
          },
          (error) => {
            scrollToTopInPanel();
            this.errors.push(error?.error?.error);
            this.enableSubmit();
          }
        );
      } else {
        this.queryAndCommandGateway.createRoadVoyage(handling, this.terminal, this.truckLicenseId, this.charter, this.eta,
          () => {
            this.errors = [];
            AppContext.publishSuccessMessage("The handling was created successfully.");
            this.editModalService.closeModal();
          },
          (error) => {
            scrollToTopInPanel();
            this.errors.push(error?.error?.error);
            this.enableSubmit();
          }
        );
      }
    } else {
      this.validationErrors = true;
      scrollToTopInPanel();
      this.enableSubmit();
    }
  }

  validateEquipmentNumber() {
    this.invalidEquipmentNumberLength = !!this.handlingData.equipmentNumber && !this.handlingData.shippersOwned
      && !ContainerNumberValidator.checkCorrectRegex(this.handlingData.equipmentNumber);
    this.invalidEquipmentNumberCheckDigit = !!this.handlingData.equipmentNumber && !this.handlingData.shippersOwned
      && !ContainerNumberValidator.checkDigitCorrect(this.handlingData.equipmentNumber);
  }

  validateEta() {
    this.etaInPast = moment(this.eta).isBefore(moment());
  }

  onUploadFile(excelFile: File) {
    this.errors = [];
    this.file = excelFile;
  }

  onFileDropped($event: Array<any>) {
    this.errors = [];
    this.file = $event[0];
  }

  deleteFile() {
    this.file = null;
  }

  uploadHandlings() {
    const roadHandlingsUpload = new RoadHandlingsUpload();
    this.uploading = true;
    roadHandlingsUpload.upload(this.file, {
        preNotification: true
      },
      (error) => {
        this.errors.push(error);
        this.file = null;
        this.uploading = false;
        scrollToTopInPanel();
      },
      () => {
        this.errors = [];
        this.file = null;
        AppContext.publishSuccessMessage("Handlings were declared successfully.");
        this.editModalService.closeModal();
      }
    );
  }

  uploadDetachedHandlings() {
    const roadHandlingsUpload = new RoadHandlingsUpload();
    this.uploading = true;
    roadHandlingsUpload.uploadDetached(this.file, {
        preNotification: false
      },
      (error) => {
        this.errors.push(error);
        window.scrollTo(0, 100);
        this.file = null;
        this.uploading = false;
        scrollToTopInPanel();
        this.enableSubmit();
      },
      () => {
        AppContext.publishSuccessMessage("Detached handlings were declared successfully.");
        this.editModalService.closeModal();
        this.uploading = false;
      }
    );
  }

  downloadTemplate() {
    window.open("/assets/templates/road-upload-template-2.5.xls");
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

  switchModal() {
    this.closeModal();
  }

  getCloseDialogComponentType(): Type<any> {
    return CloseEditingDialogComponent;
  }

  clearTemperature() {
    const reefer = this.handlingData.reefer;
    if (reefer.temperatureFixed) {
      reefer.minimumTemperature = null;
      reefer.maximumTemperature = null;
    } else {
      reefer.temperature = null;
    }
  }
}
