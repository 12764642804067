import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  AddBinnenhavengeldSubscriptions,
  BinnenhavengeldPaymentMethod,
  BinnenhavengeldSubscription,
  GetBinnenhavengeldOrganisation,
  NewBinnenhavengeldSubscription
} from '@portbase/hinterland-service-typescriptmodels';
import {
  checkValidity,
  cloneObject,
  lodash,
  publishEvent,
  removeItem,
  sendCommand,
  sendQuery,
  uuid
} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {HinterlandUtils} from '../../../hinterland/hinterland-utils';
import moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  BhgSanctionRegulationsModalComponent
} from "../bhg-sanction-regulations-modal/bhg-sanction-regulations-modal.component";
import {EditModalService} from "../../../components/modals/edit-modal/edit-modal.service";
import {ConfirmationModalService} from "../../../components/modals/confirmation-modal/confirmation-modal.service";

@Component({
  selector: 'app-bhg-add-subscriptions',
  templateUrl: './bhg-add-subscriptions.component.html',
  styleUrls: ['../../bhg.scss', './bhg-add-subscriptions.component.css']
})
export class BhgAddSubscriptionsComponent implements OnInit {
  data = {};

  context = AppContext;
  utils = HinterlandUtils;
  command: AddBinnenhavengeldSubscriptions;
  paymentMethods: BinnenhavengeldPaymentMethod[];

  newSubscription: NewBinnenhavengeldSubscription = {subscriptionId: uuid()};

  errors: string[];

  @ViewChild('addForm', {read: ElementRef}) addFormElement;

  constructor(
    private readonly editModalService: EditModalService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
    this.resetCommand();
  }

  ngOnInit() {
    this.errors = [];
  }

  resetCommand = ($event?) => {
    this.command = {
      declarationId: uuid(),
      subscriptions: [],
      declarant: AppContext.isBinnenhavengeldSuperUserOrAdmin()
        ? null : AppContext.binnenhavengeldUserProfile?.organisation,
      skipper: AppContext.isBinnenhavengeldAgent() || AppContext.isBinnenhavengeldSuperUserOrAdmin()
        ? null : AppContext.binnenhavengeldUserProfile?.organisation,
      sanctionRegulationsAccepted: false
    }
  }

  addSubscription(subscription: NewBinnenhavengeldSubscription) {
    if (checkValidity(this.addFormElement)) {
      AppContext.clearAlerts();
      this.errors = [];
      if (!subscription.end) {
        switch (subscription.type) {
          case 'week':
            subscription.end = moment(subscription.start).add(7, 'days').format('YYYY-MM-DD');
            break;
          case 'fortnight':
            subscription.end = moment(subscription.start).add(14, 'days').format('YYYY-MM-DD');
            break;
          case 'month':
            subscription.end = moment(subscription.start).add(1, 'months').format('YYYY-MM-DD');
            break;
          case 'quarter':
            subscription.end = moment(subscription.start).add(3, 'months').format('YYYY-MM-DD');
            break;
          case 'year':
            subscription.end = moment(subscription.start).add(1, 'year').format('YYYY-MM-DD');
            break;
        }
      }
      this.command.subscriptions.splice(0, 0, subscription);
      this.newSubscription = {subscriptionId: uuid(), ship: subscription.ship};
      this.computePaymentMethods();
    } else {
      AppContext.publishValidationErrorMessage();
    }
  }

  send(sendForm: HTMLDivElement) {
    if (checkValidity(sendForm)) {
      AppContext.clearAlerts();
      this.errors = [];
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.AddBinnenhavengeldSubscriptions', this.command,
        () => {
          AppContext.publishSuccessMessage("New subscriptions were added successfully");
          publishEvent('onBinnenhavengeldSubscriptions',
            this.command.subscriptions.map(s => lodash.merge(<BinnenhavengeldSubscription>{},
              this.command, s, {dateRange: {start: s.start, end: s.end}})
            )
          );
          this.editModalService.closeModal();
        },
        (error) => {
          this.errors.push(error?.error?.error);
        });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  }

  selectOrganisation = (organisationId: string) => {
    if (organisationId) {
      sendQuery('com.portbase.hinterland.api.binnenhavengeld.GetBinnenhavengeldOrganisation',
        <GetBinnenhavengeldOrganisation>{organisationId: organisationId, requiredRole: 'BinnenhavengeldSkipper'})
        .subscribe(org => {
            if (!org) {
              AppContext.publishErrorMessage("That debitor number does not belong to a skipper");
              delete this.command.skipper;
              delete this.command.declarant;
            } else {
              this.command.skipper = org;
              this.command.declarant = org;
            }
          },
          (error) => {
            this.errors.push(error?.error?.error);
          });
    } else {
      delete this.command.skipper;
      delete this.command.declarant;
    }
  };

  findOverlappingSubscriptions = (newSubscription: NewBinnenhavengeldSubscription): () => Observable<BinnenhavengeldSubscription[]> =>
    () => sendQuery("com.portbase.hinterland.api.binnenhavengeld.FindOverlappingBinnenhavengeldSubscriptions",
      {"skipperId": this.command.skipper?.organisationId, "subscription": newSubscription}, {caching: false})
      .pipe(map((result: BinnenhavengeldSubscription[]) => {
        result.push(...this.command.subscriptions.filter(s => s !== newSubscription && hasOverlap(s, newSubscription)).map(s => toSubscription(s)));
        return result;
      }));

  removeSubscription(subscription: NewBinnenhavengeldSubscription) {
    removeItem(this.command.subscriptions, subscription);
    this.command.subscriptions = cloneObject(this.command.subscriptions);
    this.computePaymentMethods();
  }

  private computePaymentMethods = () => {
    const result: BinnenhavengeldPaymentMethod[] = ["manually"];
    if (this.command.skipper?.bankAccountNumber) {
      result.splice(0, 0, "directDebit");
      if (this.command.subscriptions.every(s => s.type === 'year')) {
        result.splice(1, 0, "debitInInstallments");
      }
    }
    this.paymentMethods = result;
  };

  hasSubscriptionsAndNeededApprovals = () => (this.context.isBinnenhavengeldSuperUserOrAdmin() || this.command.sanctionRegulationsAccepted) && this.command.subscriptions.length > 0

  showSanctionRegulationsModal() {
    this.confirmationModalService.openModal(BhgSanctionRegulationsModalComponent);
  }
}

function hasOverlap(s1: NewBinnenhavengeldSubscription, s2: NewBinnenhavengeldSubscription) {
  return s1.ship?.eniNumber === s2.ship?.eniNumber
    && moment(s1.start).isBefore(moment(s2.end)) && moment(s1.end).isAfter((moment(s2.start)));
}

function toSubscription(s: NewBinnenhavengeldSubscription): BinnenhavengeldSubscription {
  return {
    subscriptionId: s.subscriptionId,
    ship: s.ship,
    type: s.type,
    dateRange: {
      start: s.start,
      end: s.end
    }
  };
}
