import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AlertType} from './alert';

@Component({
  selector: 'app-status-alert',
  templateUrl: './status-alert.component.html',
  styleUrls: ['./status-alert.component.css']
})
export class StatusAlertComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: AlertType;
  @Input() msShowTime: number;
  @Output() close = new EventEmitter();

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    if (!this.type) {
      throw new Error('Attribute type is required for app-status-alert component');
    }
  }

  ngAfterViewInit(): void {
    const element = $(this.elementRef.nativeElement.querySelector(".alert"));
    element.on('closed.bs.alert', this.sendClose);
    if (this.msShowTime) {
      setTimeout(() => element.alert('close'), this.msShowTime);
    }
  }

  ngOnDestroy(): void {
    const element = $(this.elementRef.nativeElement.querySelector(".alert"));
    element.off('closed.bs.alert', this.sendClose);
  }

  sendClose = () => {
    this.close.emit();
  };
}
