{
  "NLRTM": [
    {
      "code": "BR",
      "name": "Brienenoordbrug"
    },
    {
      "code": "HT",
      "name": "Heinenoordtunnel/Spui"
    },
    {
      "code": "PS",
      "name": "Parksluizen"
    }
  ]
}