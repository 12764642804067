<button class="btn feedback-btn d-sm-none" #refButtonLarge type="button"
        [autoClose]="'outside'" [ngbPopover]="popContent" popoverClass="pop-container-small"
        #popOverSmall="ngbPopover" triggers="manual" (click)="popOverSmall.open(); reset()"
        placement="{{isLocatedInRightHalfOfWindow(refButtonLarge)?'bottom-right':'bottom-left'}}">
  Report issue <span class="fas fa-envelope ms-1"></span>
</button>

<button class="btn feedback-btn d-none d-sm-block" #refButtonSmall type="button"
        [autoClose]="'outside'" [ngbPopover]="popContent" popoverClass="pop-container"
        #popOver="ngbPopover" triggers="manual" (click)="popOver.open(); reset()"
        placement="{{isLocatedInRightHalfOfWindow(refButtonSmall)?'bottom-right':'bottom-left'}}">
  Report issue <span class="fas fa-envelope ms-1"></span>
</button>

<ng-template #popContent>
  <div class="container-fluid py-3">
    <div class="row">
      <div class="col-12">
        <h5 class="mb-3">Report issue</h5>
        <div class="mb-3">Answers to the most frequently asked questions can be found on our <a target="_blank" [href]="getSupportUrl()">support page</a></div>
      </div>
      <div class="col-12">
        <app-form-group *ngIf="getModalityOptions().length > 1">
          <label>Modality</label>
          <app-select [(ngModel)]="modality" [options]="getModalityOptions()" required>
          </app-select>
        </app-form-group>
        <app-form-group>
          <label>Email</label>
          <input required [(ngModel)]="email" class="form-control">
        </app-form-group>
        <app-form-group>
          <label>Issue</label>
          <textarea required [(ngModel)]="feedback" class="form-control" style="min-height: 15rem;"> </textarea>
        </app-form-group>
        <div class="text-end">
          <button class="btn btn-info" (click)="popOver.close(); sendFeedback();">Submit</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
