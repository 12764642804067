<div>
  <div class="row narrow-gutters align-items-center">
    <div class="col">
      <div class="collapsible border border-light" style="border-width: 2px!important; margin-top: -2px"
           [ngClass]="{'ng-invalid border-invalid' : validationModel && validationModel['ngInvalid']}">

        <div class="row g-0 align-items-center">
          <div class="col">
            <ng-content></ng-content>
          </div>
          <div class="col-auto" *ngIf="!hideCollapseIcon">
            <div class="text-end pe-2" [ngStyle]="{'width.rem' : deleteHandle && showing && !collapsing ? 2 : 4}">
              <h4 class="m-0 align-top fa fa-chevron-right text-secondary"></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showing && !collapsing">
      <div class="col-auto" style="width: 2rem" *ngIf="deleteHandle; else deleteViaModal">
        <button class="mx-2 btn p-0" type="button" title="Delete item" (click)="deleteCollapse()" tabindex="-1">
          <span class="fa fa-trash text-secondary"></span>
        </button>
      </div>
      <ng-template #deleteViaModal>
        <div class="col-auto" style="width: 2rem" *ngIf="deleteModal">
          <button class="mx-2 btn p-0" type="button" title="Delete item" data-bs-toggle="modal"
                  [attr.data-bs-target]="deleteModal" tabindex="-1">
            <span class="fa fa-trash text-secondary"></span>
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <!--if rendering is slow make sure you set the collapse input-->
  <div *ngIf="showing && collapse" [appValidator]="validationModel">
    <ng-container *ngTemplateOutlet="collapse"></ng-container>
  </div>
</div>
