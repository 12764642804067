import {Component, Input} from '@angular/core';
import {BinnenhavengeldOrganisation} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-bhg-organisation',
  templateUrl: './bhg-organisation.component.html',
  styleUrls: ['./bhg-organisation.component.css']
})
export class BhgOrganisationComponent {
  @Input() organisation: BinnenhavengeldOrganisation;
  context = AppContext;
}
