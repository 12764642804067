import {Component, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import lodash, {cloneDeep} from 'lodash';
import {
  cloneObject,
  CommandRequest,
  filterByTerm,
  isElementValid,
  removeItem,
  sendCommand,
  sendCommands,
  sendQuery
} from '../../common/utils';
import {ComparatorChain} from '../../common/comparator-chain';
import {
  AcceptVisit,
  AcceptVisitCancellation,
  BargeVisit,
  BargeVisitStatus,
  BargeVoyage,
  CloseVisit,
  CompleteVisit,
  DateTimeRange,
  Modality,
  ProcessVisit,
  RegisterTerminalRemarks,
  RejectVisit,
  ReopenVisit,
  Visit,
  Voyage
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {AppContext} from '../../app-context';
import {downloadVisits} from './visits.download';
import {CommandGateway} from '../../common/command-gateway';
import {HinterlandUtils} from '../hinterland-utils';
import {map} from "rxjs/operators";
import moment from "moment";
import {InjectorProvider} from '../../common/injector-provider';
import {
  GetCurrentUserPreferences,
  RailVisit,
  RailVoyage,
  Terminal
} from "@portbase/hinterland-service-typescriptmodels";
import {RailExcelUtils} from "../rail/rail-excel.utils";

@Component({
  selector: 'app-terminal-overview',
  templateUrl: './terminal-overview.component.html',
  styleUrls: ['./terminal-overview.component.css']
})
export class TerminalOverviewComponent extends AbstractOverviewComponent<TerminalVisitView> implements OnInit {

  private static visitComparator = new ComparatorChain("-rejectCount", "updated", "visit.visitData.eta").compare;
  visitEtaComparator = new ComparatorChain("visit.visitData.eta").compare;
  visitEtaComparatorReversed = new ComparatorChain("-visit.visitData.eta").compare;
  visitIdComparator = new ComparatorChain("visit.visitId").compare;
  visitIdComparatorReversed = new ComparatorChain("-visit.visitId").compare;

  utils = HinterlandUtils;
  appContext = AppContext;
  categories: Status[] = ['cancellation requested', 'updated', 'to be planned', 'requested', 'planned', 'in operation', 'completed', 'rejected'];

  overview: VisitOverview;
  private filteredItems: TerminalVisitView[] = [];
  visitForReject: TerminalVisitView;
  visitForComplete: TerminalVisitView;
  initialEndOfOperationsDate: string;
  actualComparators = {
    'cancellation requested': this.visitEtaComparator,
    'updated': this.visitEtaComparator,
    'to be planned': TerminalOverviewComponent.visitComparator,
    'requested': TerminalOverviewComponent.visitComparator,
    'planned': this.visitEtaComparator,
    'in operation': this.visitEtaComparator
  };
  pagingSupported: boolean = AppContext.isAdmin();
  excludedFilterFields = ['visits'];
  @ViewChild('endOfOperationsComponent') endOfOperationsComponent: any;
  viewAsTerminal: Terminal;

  ngOnInit(): void {
    sendQuery("com.portbase.common.api.authentication.query.GetCurrentUserPreferences", <GetCurrentUserPreferences>{})
      .subscribe(result => {
        AppContext.cachedUserPrefs = result;
        super.ngOnInit();
      });
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<(TerminalVisitView)[]> {
    let type: string;
    let payload: any = {
      dateTimeRange: dateTimeRange,
      excludeHandlings: true,
      terminalShortName: this.viewAsTerminal?.shortName
    }

    if (this.filterTerm) {
      payload.term = this.filterTerm;
      type = AppContext.isAdmin() && !this.viewAsTerminal
        ? 'com.portbase.hinterland.api.common.query.FindVoyages'
        : 'com.portbase.hinterland.api.common.query.FindVoyagesForTerminal';
    } else {
      type = AppContext.isAdmin() && !this.viewAsTerminal
        ? 'com.portbase.hinterland.api.common.query.GetVoyages'
        : 'com.portbase.hinterland.api.common.query.GetVoyagesForTerminal';
    }

    if (this.pagingSupported) {
      payload.from = this.from;
      payload.maxHits = this.maxItems;
    }
    return sendQuery(type, payload, {
      caching: false,
      showSpinner: !this.unsubscribeHandle
    }).pipe(map(v => this.mapToEtaFilteredVisits(v)));
  }

  searchOrFilter = (): (any[] | Observable<any[]>) => {
    if (AppContext.isAdmin()) {
      return this.reload();
    } else {
      return this.getFilteredItems();
    }
  };

  public filterItems() {
    let filteredItems = this.items;
    if (AppContext.isFiltering("Rail")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'rail');
    } else if (AppContext.isFiltering("Barge")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'barge');
    }
    if (this.filterTerm) {
      this.generateSearchableItems();
      filteredItems = filteredItems.filter(filterByTerm(this.filterTerm, this.excludedFilterFields, this.searchableItems));
    }
    if (filteredItems.length < 10) this.loadAndRenderNextPage();
    return filteredItems;
  }

  doRender = (visits: TerminalVisitView[]) => {
    this.overview = this.toOverview(this.filteredItems = visits);
  };

  mapToEtaFilteredVisits = (voyages: Voyage[]) => {
    const visits: TerminalVisitView[] = [];
    const bicsCode = new URL(window.location.toString()).searchParams.get("bicsCode");
    voyages.forEach(voyage => voyage.visits
      .filter(v => v.terminal && !v.terminal.nonPcs && (!bicsCode || v.terminal.bicsCode === bicsCode)
        && moment(v.visitData.eta).isBetween(this.dateRange.start + ' 00:00', this.dateRange.end + ' 23:59'))//
      .forEach(v => visits.push(toVisitView(voyage, v))));
    visits.sort(this.visitEtaComparator);
    return visits;
  };

  loadVoyageWithHandlings(voyage: Voyage) {
    sendQuery("com.portbase.hinterland.api.common.query.GetVoyageForTerminal", {voyageId: voyage.voyageId},
      {caching: false, showSpinner: true})
      .subscribe((voyage: Voyage) => {
        if (voyage) {
          this.updateVoyage(voyage);
        }
      });
  }

  onUpdateVoyage = (update: Voyage) => {
    if (update.modality === 'barge' || update.modality == 'rail') {
      if (AppContext.isHinterlandTerminalAndDeclarant()) {
        update = cloneObject(update);
        update.visits = update.visits.filter(v => v.terminal.shortName === AppContext.userProfile.organisationShortName);
      }
      this.updateVoyage(update);
    }
  };

  private updateVoyage(update: Voyage) {
    this.items = this.mapToEtaFilteredVisits([update]).concat(this.items.filter(v => v.voyage.voyageId !== update.voyageId));

    //Next, manual rendering, to prevent collapsing drop-downs, since trackingBy doesn't work here.

    this.filteredItems.filter(t => t.voyage.voyageId == update.voyageId)
      .forEach(t => {
        if (!update.visits.find(v => v.visitId === t.visit.visitId)) {
          removeItem(this.filteredItems, t);
          removeItem(this.overview[t.status], t);
        }
      });

    update.visits.forEach(t => {
      const old: TerminalVisitView = this.filteredItems.find(v => v.voyage.voyageId === update.voyageId
        && v.visit.visitId === t.visitId);
      const newValue = toVisitView(update, t, old);
      const category = this.overview[newValue.status];
      if (old) {
        if (old.status && old.status !== newValue.status) {
          removeItem(this.overview[old.status], old);
        }
        if (newValue.status && old.status !== newValue.status) {
          category.push(old);
        }

        lodash.assign(old, newValue);
        old.visit = cloneObject(old.visit);

        if (category) {
          category.sort(this.actualComparators[newValue.status]);
        }
      } else if (this.getFilteredItems().some(visitview => visitview.visit.visitId === t.visitId)) {
        this.filteredItems.splice(0, 0, newValue);
        if (category) {
          category.push(newValue);
          category.sort(this.actualComparators[newValue.status]);
        }
      }
    });
  }

  isPriorityWindowInvalid(visit: TerminalVisitView, customErrorText: string): boolean {
    const bargevisitstatus = visit.visit.plannedVisitData as BargeVisitStatus;
    if (!bargevisitstatus || !bargevisitstatus.priority) return false;
    if (!bargevisitstatus.priorityWindow || !bargevisitstatus.priorityWindow.start || !bargevisitstatus.priorityWindow.end) {
      AppContext.registerError("Could not " + customErrorText + ". A barge priority window needs both a start date and time, and a (later) end date and time.");
      return true;
    }
    if (!!bargevisitstatus.priorityWindow.start && !!bargevisitstatus.priorityWindow.end &&
      Date.parse(bargevisitstatus.priorityWindow.start) > Date.parse(bargevisitstatus.priorityWindow.end)) {
      AppContext.registerError("Could not " + customErrorText + ". A barge priority window needs both a start date and time, and a (later) end date and time.");
      return true;
    }
    return false;
  }

  acceptVisit(visit: TerminalVisitView, customErrorText: string = "accept visit") {
    if (!this.isPriorityWindowInvalid(visit, customErrorText)) {
      const command: CommandRequest = this.createAcceptVisitCommand(visit);
      sendCommand(command.type, command.payload, () => {
        AppContext.registerSuccess('The visit has been accepted.');
      });
    }
  }

  acceptVisits(visits: TerminalVisitView[]) {
    const commands: Array<CommandRequest> = [];
    visits.forEach(visit => {
      commands.push(this.createAcceptVisitCommand(visit));
    });

    AppContext.clearAlerts();
    sendCommands(commands, (results) => {
      AppContext.registerCommandResults(results, 'visits have been accepted.');
    });
  }

  createAcceptVisitCommand(visit: TerminalVisitView): CommandRequest {
    let integralPlanningRequested = visit.visit.modality == 'barge' && (visit.voyage as BargeVoyage).integralPlanningRequested;
    let integralPlannedVisit = integralPlanningRequested && !!visit.visit.terminal && visit.visit.terminal.nextlogicParticipant;

    return {
      id: visit.visit.visitId,
      type: 'com.portbase.hinterland.api.common.command.AcceptVisit',
      payload: <AcceptVisit>{
        voyageId: visit.voyage.voyageId,
        visitId: visit.visit.visitId,
        messageId: visit.visit.visitDeclaration?.messageId,
        plannedVisitData: integralPlannedVisit ? visit.visit.plannedVisitData : {...visit.visit.plannedVisitData, ...visit.visit.visitData},
        remarks: visit.visit.plannedVisitData ? (visit.visit as BargeVisit | RailVisit).plannedVisitData.terminalRemarks : null,
        priority: visit.visit.modality == "barge" && visit.visit.plannedVisitData ? (visit.visit as BargeVisit).plannedVisitData.priority : null,
        minimumCallSize: visit.visit.modality == "barge" && visit.visit.plannedVisitData ? (visit.visit as BargeVisit).plannedVisitData.minimumCallSize : null
      }
    }
  }

  reopenVisit(visit: TerminalVisitView) {
    sendCommand('com.portbase.hinterland.api.common.command.ReopenVisit', <ReopenVisit>{
      voyageId: visit.voyage.voyageId,
      visitId: visit.visit.visitId
    }, () => {
      AppContext.registerSuccess('The load-discharge list is reopened');
    });
  }

  reopenLoadDischargeLists(visit: TerminalVisitView[]) {
    visit.forEach(visit => {
      this.reopenVisit(visit);
    });
  }

  closeLoadDischargeLists(visit: TerminalVisitView[]) {
    visit.forEach(visit => {
      sendCommand('com.portbase.hinterland.api.common.command.CloseVisit', <CloseVisit>{
        voyageId: visit.voyage.voyageId,
        visitId: visit.visit.visitId
      }, () => {
        AppContext.registerSuccess('The load-discharge list is closed');
      });
    });
  }

  rejectVisits(visit: TerminalVisitView[]) {
    visit.forEach(visit => {
      sendCommand('com.portbase.hinterland.api.common.command.RejectVisit', <RejectVisit>{
          voyageId: visit.voyage.voyageId,
          visitId: visit.visit.visitId,
          reasons: [{code: 'UI', terminalDescription: "Rejected by administrator."}],
          messageId: visit.visit.visitDeclaration?.messageId,
        }, () => {
          AppContext.registerSuccess('The visit has been rejected');
        }
      )
    });
  }

  acceptCancellation(visit: TerminalVisitView) {
    sendCommand('com.portbase.hinterland.api.common.command.AcceptVisitCancellation', <AcceptVisitCancellation>{
      voyageId: visit.voyage.voyageId,
      visitId: visit.visit.visitId
    }, () => {
      AppContext.registerSuccess('The visit cancellation has been accepted');
      visit.voyage.visits = visit.voyage.visits.filter(v => v.visitId !== visit.visitId);
      this.updateVoyage(visit.voyage); //update manually to remove visit from screen
    });
  }

  rejectVisit(visit: TerminalVisitView) {
    this.visitForReject = visit;
    setTimeout(() => $("#rejectVisitModal").modal('show'), 0);
  }

  completeVisit(visit: TerminalVisitView) {
    this.visitForComplete = visit;
    this.initialEndOfOperationsDate = visit.visit.visitData.etd;
    setTimeout(() => $("#completeVisitModal").modal('show'), 0);
  }

  doCompleteVisit(endOfOperations: string) {
    if (isElementValid(this.endOfOperationsComponent.elementRef)) {
      sendCommand('com.portbase.hinterland.api.common.command.CompleteVisit', <CompleteVisit>{
        voyageId: this.visitForComplete.voyage.voyageId,
        visitId: this.visitForComplete.visit.visitId,
        endOfOperations: endOfOperations
      }, () => {
        AppContext.registerSuccess('The visit completion has been accepted.');
      });
      setTimeout(() => $("#completeVisitModal").modal('hide'), 0);
    }
  }

  sendRemarks(visit: TerminalVisitView) {
    sendCommand('com.portbase.hinterland.api.common.command.RegisterTerminalRemarks', <RegisterTerminalRemarks>{
      voyageId: visit.voyage.voyageId,
      visitId: visit.visit.visitId,
      remarks: (visit.visit as BargeVisit | RailVisit).plannedVisitData.terminalRemarks,
      messageId: visit.visit.visitDeclaration?.messageId,
    }, () => {
      AppContext.registerSuccess('The remarks have been registered');
    });
  }

  doRejectVisit(reason: string) {
    if (this.visitForReject) {
      sendCommand('com.portbase.hinterland.api.common.command.RejectVisit', <RejectVisit>{
        voyageId: this.visitForReject.voyage.voyageId,
        visitId: this.visitForReject.visit.visitId,
        reasons: !reason || reason === '' ? [] : [{code: 'UI', terminalDescription: reason}],
        messageId: this.visitForReject.visit.visitDeclaration?.messageId,
      }, () => {
        AppContext.registerSuccess('The visit has been rejected');
      });
    }
  }

  downloadAndPlan = (views: TerminalVisitView[]) => {
    views = views.filter(v => v.selected);
    views = cloneObject(views);
    const commandResults: Observable<any>[] = views.map(v =>
      InjectorProvider.injector.get(CommandGateway).send('com.portbase.hinterland.api.common.command.ProcessVisit', <ProcessVisit>{
        voyageId: v.voyage.voyageId,
        visitId: v.visit.visitId,
        messageId: v.visit.visitDeclaration?.messageId,
      }));

    AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
      AppContext.registerSuccess('The visits were moved successfully');
    }, error => AppContext.registerError(error));

    downloadVisits(views);
  };

  download = (views: TerminalVisitView[]) => {
    views = views.filter(v => v.selected);
    views = cloneObject(views);
    downloadVisits(views);
  };

  downloadAndAcceptIntegrallyPlanned(category: Status) {
    const visits = cloneObject(this.getIntegrallyPlannedVisits(category));
    const commands: Array<CommandRequest> = visits.map((visit) => this.createAcceptVisitCommand(visit));
    AppContext.clearAlerts();

    sendCommands(commands, (results => {
      AppContext.registerCommandResults(results, 'integrally planned visits have been accepted');
      downloadVisits(visits.filter((visit) =>
        results.find((result) => result.id == visit.visitId && result.success)));
    }));
  }

  downloadTrainComposition(voyage: Voyage, visit: Visit) {
    RailExcelUtils.downloadTrainComposition(<RailVoyage>voyage, <RailVisit>visit);
  }

  asVisitView = (m): TerminalVisitView => <TerminalVisitView>m;

  isActive(category: Status) {
    switch (category) {
      case 'completed':
      case 'rejected':
        return false;
    }
    return true;
  }

  isRequest(category: Status) {
    switch (category) {
      case 'in operation':
      case 'planned':
        return false;
    }
    return this.isActive(category);
  }

  isBulkAcceptable(category: Status): boolean {
    return AppContext.application.integralPlanningEnabled && (category === 'updated' || category === 'to be planned') ? this.hasIntegrallyPlannedVisits(category) : false;
  }

  hasIntegrallyPlannedVisits(category: Status): boolean {
    const integrallyPlannedVisits = this.getIntegrallyPlannedVisits(category);
    return integrallyPlannedVisits && integrallyPlannedVisits.length > 0;
  }

  getIntegrallyPlannedVisits(category: Status): TerminalVisitView[] {
    return this.overview[category].filter(o => {
      const lastDeclaration = o.visit.visitDeclarations[o.visit.visitDeclarations.length - 1];
      return lastDeclaration.declarantType === "INTEGRAL_PLANNER" && lastDeclaration.status === "DECLARED";
    });
  }

  getSelectedViews = (category: Status): TerminalVisitView[] => {
    return this.overview[category].filter(v => v.selected);
  };

  showHeader = (elements: TerminalVisitView[]) => {
    return elements.length > 0;
  }

  timeslot = (visit: TerminalVisitView) => {
    return visit.visit && visit.modality == "rail" ? (visit.visit as RailVisit).visitData.timetableEntry : null;
  }

  showTimeslotHandlingTotals = (visit: TerminalVisitView) => {
    return this.timeslot(visit) && (visit.status == "updated" || visit.status == "requested");
  }

  acknowledgeVisitUpdate = (model: TerminalVisitView) => {
    if (model.status === 'to be planned' && !model.visit.visitDeclaration.acknowledgedByTerminal) {
      InjectorProvider.injector.get(CommandGateway).send('com.portbase.hinterland.api.common.command.ProcessVisit', <ProcessVisit>{
        voyageId: model.voyage.voyageId,
        visitId: model.visit.visitId,
        messageId: model.visit.visitDeclaration?.messageId,
      }).subscribe();
    }
    this.loadVoyageWithHandlings(model.voyage);
  };

  sort(category: Status, comparator) {
    this.actualComparators[category] = comparator;
    this.renderFilteredItems();
  }

  toOverview(visits: TerminalVisitView[]): VisitOverview {
    return <VisitOverview>{
      'cancellation requested': visits.filter(v => v.status === 'cancellation requested').sort(this.actualComparators['cancellation requested']),
      updated: visits.filter(v => v.status === 'updated').sort(this.actualComparators['updated']),
      requested: visits.filter(v => v.status === 'requested').sort(this.actualComparators['requested']),
      'to be planned': visits.filter(v => v.status === 'to be planned').sort(this.actualComparators['to be planned']),
      planned: visits.filter(v => v.status === 'planned').sort(this.actualComparators['planned']),
      'in operation': visits.filter(v => v.status === 'in operation').sort(this.actualComparators['in operation']),
      completed: visits.filter(v => v.status === 'completed'),
      rejected: visits.filter(v => v.status === 'rejected'),
    }
  }

  allSelected = (category): boolean => this.overview[category].every(v => v.selected);
  onlyRailVisits = (views: TerminalVisitView[]): boolean => views.every(view => view.modality == "rail");
  deselectAll = (category) => this.overview[category].forEach(v => v.selected = false);
  selectAll = (category) => this.overview[category].forEach(v => v.selected = true);

  onTerminalChanged(event: Terminal) {
    this.viewAsTerminal = event;
    this.loadAndRender();
  }

}

function toVisitView(voyage: Voyage, visit: Visit, oldView?: TerminalVisitView): TerminalVisitView {
  const status = getStatus(visit);
  return {
    voyage: voyage as RailVoyage | BargeVoyage,
    visit: visit as BargeVisit | RailVisit,
    status: status,
    visitId: visit.visitId,
    updated: visit.visitDeclaration && visit.visitDeclaration.timestamp,
    rejectCount: getRejectCount(visit, status),
    selected: !!oldView && oldView.status === status ? oldView.selected : false,
    modality: voyage.modality
  };

  function getStatus(visit: Visit): Status {
    if (!visit.visitDeclarationStatus) {
      return null;
    }
    if (visit.visitCancelled) {
      return visit.neverAcknowledged ? null : 'cancellation requested';
    }
    if (visit.arrived) {
      return visit.visitCompleted ? 'completed' : 'in operation';
    }
    switch (visit.visitDeclarationStatus) {
      case 'REJECTED':
        return 'rejected';
      case 'ACCEPTED':
        return 'planned';
      case 'DECLARED':
        return cloneDeep(visit.visitDeclarations.map(d => d.status)).reverse().find(s => s !== 'DECLARED') === 'ACCEPTED' ? 'updated'
          : visit.visitDeclarations.some(d => d.acknowledgedByTerminal) ? 'to be planned' : 'requested';
    }
  }

  function getRejectCount(visit: Visit, status: Status): number {
    switch (status) {
      case 'requested':
      case 'updated':
        let count = 0;
        for (const declaration of cloneDeep(visit.visitDeclarations).reverse()) {
          switch (declaration.status) {
            case 'ACCEPTED':
              return count;
            case 'REJECTED':
              count++;
              break;
          }
        }
        return count;
      default:
        return 0;
    }
  }
}

export interface TerminalVisitView {
  visit: RailVisit | BargeVisit,
  voyage: Voyage,
  status: Status,
  visitId: string,
  updated: string,
  rejectCount: number,
  selected: boolean,
  modality: Modality
}

interface VisitOverview {
  'cancellation requested': TerminalVisitView[],
  updated: TerminalVisitView[],
  requested: TerminalVisitView[],
  'to be planned': TerminalVisitView[],
  planned: TerminalVisitView[],
  'in operation': TerminalVisitView[],
  completed: TerminalVisitView[],
}

type Status =
  'cancellation requested'
  | 'updated'
  | 'requested'
  | 'to be planned'
  | 'planned'
  | 'in operation'
  | 'completed'
  | 'rejected';
