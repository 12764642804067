import {Component, Input, OnInit} from '@angular/core';
import {
  BinnenhavengeldSubscriptionType,
  NewBinnenhavengeldSubscription
} from '@portbase/hinterland-service-typescriptmodels';
import {HinterlandUtils} from '../../../hinterland/hinterland-utils';
import {cloneObject, sendQuery, uuid} from '../../../common/utils';
import moment from 'moment';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-bhg-edit-subscription',
  templateUrl: './bhg-edit-subscription.component.html',
  styleUrls: ['./bhg-edit-subscription.component.css']
})
export class BhgEditSubscriptionComponent implements OnInit {
  utils = HinterlandUtils;
  quarters = computeQuarters();
  years = computeYears();
  cutoffDate: string;
  startDateBeforeCutoff: boolean = false;

  @Input() subscription = <NewBinnenhavengeldSubscription>{subscriptionId: uuid()};

  ngOnInit(): void {
    sendQuery("com.portbase.hinterland.api.binnenhavengeld.GetBinnenhavengeldSubscriptionCutoffDate", {})
      .subscribe(v => {
        this.cutoffDate = v;
        if (v) {
          this.quarters = computeQuarters(v);
          this.years = computeYears(v);
        }
      });
  }

  onSubscriptionType(type: BinnenhavengeldSubscriptionType) {
    if (!type) {
      return;
    }
    switch (type) {
      case 'quarter': {
        let defaultValue = moment().startOf('quarter');
        defaultValue = defaultValue.isBefore(moment().subtract(2, 'months'))
          ? defaultValue.add(1, 'quarter') : defaultValue;
        this.subscription.start = defaultValue.format('YYYY-MM-DD');
        break;
      }
      case 'year': {
        let defaultValue = moment().startOf('year');
        defaultValue = defaultValue.isBefore(moment().subtract(2, 'months'))
          ? defaultValue.add(1, 'years') : defaultValue;
        this.subscription.start = defaultValue.format('YYYY-MM-DD');
        break;
      }
      default: {
        this.subscription.start = "null";
        break;
      }
    }
    this.startDateBeforeCutoff = false;
  }

  getYear(): string {
    const start = moment(this.subscription.start);
    return start && start.isValid() ? String(start?.year()) : null;
  }

  selectYear(year: string) {
    this.subscription.start = moment().startOf('year').set('year', Number(year)).format('YYYY-MM-DD');
  }

  getQuarter(): string {
    const start = moment(this.subscription.start);
    return start && start.isValid() ? String(start?.year()) + "–" + String(start?.quarter()) : null;
  }

  selectQuarter(value: string) {
    let [year, quarter] = value.split("–");
    this.subscription.start = moment().startOf('year')
      .set('quarter', Number(quarter))
      .set('year', Number(year))
      .format('YYYY-MM-DD');
  }

  selectDate(date: string) {
    this.subscription.start = date;
    this.startDateBeforeCutoff = !AppContext.isBinnenhavengeldSuperUserOrAdmin()
      && date && moment(date).isBefore(this.cutoffDate ? moment(this.cutoffDate)
        : moment().startOf('day').subtract(35, 'days'));
  }
}

function computeQuarters(cutoffDate?: string): string[] {
  const result: string[] = [];
  const now = moment();
  let min = cutoffDate ? moment(cutoffDate)
    : cloneObject(now).subtract(2, 'months').add(1, 'quarters');
  if (AppContext.isBinnenhavengeldSuperUserOrAdmin()) {
    min = min.subtract(10, 'years');
  }
  const max = cloneObject(now).add(1, 'years').endOf('year');
  const maxString = String(max.year()) + "–" + String(max.quarter())

  let year = min.year();
  let quarter = min.quarter();
  for (let value = String(year) + "–" + String(quarter); value <= maxString; value = String(year) + "–" + String(quarter)) {
    result.push(value);
    quarter = quarter + 1;
    if (quarter > 4) {
      quarter = 1;
      year++;
    }
  }
  return result;
}

function computeYears(cutoffDate?: string): string[] {
  const result: string[] = [];
  const now = moment();
  let min = cutoffDate ? moment(cutoffDate)
    : cloneObject(now).subtract(2, 'months').add(1, 'years');
  if (AppContext.isBinnenhavengeldSuperUserOrAdmin()) {
    min = min.subtract(10, 'years');
  }
  for (let i = min.year(); i <= now.year() + 1; i++) {
    result.push(String(i));
  }
  return result;
}
