import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AcceptDetachedHandling,
  AcceptHandling,
  AttachHandling,
  CancelAndDetachHandling,
  CancelDetachedHandling,
  DeclareHandling,
  DetachHandling,
  FindVisitResult,
  Handling,
  InformByMail,
  MoveHandling,
  RejectDetachedHandling,
  RejectHandling
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {HandlingModel, HandlingStatus, HinterlandUtils} from '../../hinterland-utils';
import {CommandGateway} from '../../../common/command-gateway';
import {combineLatest, Observable} from 'rxjs';
import {AppContext} from '../../../app-context';
import {
  cloneObject,
  CommandRequest,
  lodash,
  removeIf,
  removeItem,
  sendCommand,
  sendCommands,
  uuid
} from '../../../common/utils';
import {ComparatorChain} from '../../../common/comparator-chain';
import {Router} from "@angular/router";
import {AcceptStatusType} from "@portbase/hinterland-service-typescriptmodels";
import {Accept} from "./handling-status/handling-status.component";
import moment from "moment";

export interface CopiedHandling {
  handling: HandlingModel;
  detached: boolean;
}

@Component({
  selector: 'app-handlings',
  templateUrl: './handlings.component.html',
  styleUrls: ['./handlings.component.css']
})
export class HandlingsComponent {

  public static handlingOrder: HandlingStatus[] = HinterlandUtils.handlingStatuses;
  public static handlingOrderReversed: HandlingStatus[] = cloneObject(HinterlandUtils.handlingStatuses).reverse();
  private static statusComparator = (h1: HandlingModel, h2: HandlingModel) => HandlingsComponent.handlingOrder.indexOf(h1.status) - HandlingsComponent.handlingOrder.indexOf(h2.status);
  private static statusComparatorReversed = (h1: HandlingModel, h2: HandlingModel) => HandlingsComponent.handlingOrderReversed.indexOf(h1.status) - HandlingsComponent.handlingOrderReversed.indexOf(h2.status);
  private static visitStatusComparator = (h1: HandlingModel, h2: HandlingModel) => HandlingsComponent.handlingOrder.indexOf(h1.worstVisitHandlingStatus) - HandlingsComponent.handlingOrder.indexOf(h2.worstVisitHandlingStatus);
  private static visitStatusComparatorReversed = (h1: HandlingModel, h2: HandlingModel) => HandlingsComponent.handlingOrderReversed.indexOf(h1.worstVisitHandlingStatus) - HandlingsComponent.handlingOrderReversed.indexOf(h2.worstVisitHandlingStatus);

  @Input() selectedHandlingId;
  @Input() readonly: boolean;
  @Input() detached: boolean;
  @Input() showCategories: boolean;
  @Input() displayVisitInfo: boolean;
  @Input() displayTerminalAndEta = false;
  @Input() sortBy:
    'status' | 'statusReversed' |
    'handling' | 'handlingReversed' |
    'visit' | 'visitReversed' |
    'acceptStatus' | 'acceptStatusReversed' = 'status';
  @Input() hideAccepted: boolean;
  @Input() pagination: boolean;

  @Input() set setHandlings(handlings: HandlingModel[]) {
    this.initHandlings(handlings);
  }

  @Output() onSelect: EventEmitter<HandlingModel> = new EventEmitter<HandlingModel>();
  @Output() onDeselect: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteHandling = new EventEmitter<HandlingModel>();
  @Output() copyHandling = new EventEmitter<CopiedHandling>();

  appContext = AppContext;
  utils = HinterlandUtils;

  sortAcceptStatusesBy: Accept = null;
  visitArrived: boolean;
  completedCount: number = 0;
  viewCompleted: boolean;
  sendHandlingsEmailAddress = this.appContext.userProfile.emailAddress;
  handlings: HandlingModel[];
  attaching: boolean;
  detaching: boolean;
  cancelling: boolean;
  adminAction: string;
  selectableHandlings: number;
  selectedHandlings: string[] = [];
  visitForAttaching: FindVisitResult;
  uuid = uuid();

  comparators = {
    'visit': ["visitData.eta", "visitId", HandlingsComponent.statusComparator, "handlingData.equipmentNumber"],
    'visitReversed': ["-visitData.eta", "-visitId", HandlingsComponent.statusComparatorReversed, "-handlingData.equipmentNumber"],
    'handling': ["handlingData.equipmentNumber", "visitData.eta", HandlingsComponent.statusComparator],
    'handlingReversed': ["-handlingData.equipmentNumber", "-visitData.eta", HandlingsComponent.statusComparatorReversed],
    'status': [HandlingsComponent.visitStatusComparator, "visitData.eta", "visitId"],
    'statusReversed': [HandlingsComponent.visitStatusComparatorReversed, "-visitData.eta", "-visitId"],
    'acceptStatus': [this.acceptStatusComparator()].concat(HandlingsComponent.statusComparator)
  };
  sortDirection = {
    'visit': 'visitReversed',
    'handling': 'handlingReversed',
    'status': 'statusReversed'
  }

  constructor(private router: Router, private commandGateway: CommandGateway) {
  }

  initHandlings = (handlings: HandlingModel[]) => {
    if (!!handlings) {
      this.handlings = handlings;
      this.handlings.sort(this.getComparator(this.handlings));
      // pinned(selected) handlings should remain on the top of the list, make sure sorting does not affect those by sorting them back up. new handlings (null id) should also be created on top.
      this.handlings.sort((h1, h2) =>
        this.selectedHandlings.some(selectedId => selectedId == h1.handlingId) ? -1 : this.selectedHandlings.some(selectedId => selectedId == h2.handlingId) ? 1 : 0);
      this.completedCount = this.handlings.filter(h => h.status === 'accepted' || h.status === 'handled').length;
      this.visitArrived = this.handlings.some(h => h.visitArrived);
      removeIf(this.selectedHandlings, id => !this.handlings.find(h => h.handlingId === id));
      this.updateSelectable();
    }
  };

  acceptStatusComparator() {
    return (h1, h2) => this.sortAcceptStatusesBy == null ? 0 : this.hasAcceptStatus(h1, this.sortAcceptStatusesBy.type) - this.hasAcceptStatus(h2, this.sortAcceptStatusesBy.type);
  }

  hasAcceptStatus(handling: HandlingModel, acceptStatusType: AcceptStatusType): number {
    return handling.handlingDeclaration && handling.handlingDeclaration.acceptStatuses && handling.handlingDeclaration.acceptStatuses.filter(acceptStatus => acceptStatus.ok == false).map(acceptStatus => acceptStatus.type).includes(acceptStatusType) ? -1 : 0;
  }

  toggleAcceptStatusSorting(acceptStatus: Accept) {
    this.sortAcceptStatusesBy = acceptStatus == this.sortAcceptStatusesBy ? null : acceptStatus;
    this.sortBy = this.sortAcceptStatusesBy == null ? "status" : "acceptStatus";
    this.initHandlings(this.handlings);
  }

  sendHandlingsByMail() {
    AppContext.pendingProcesses.push("SendingEmail");
    let selectedHandlingModels = this.handlings.filter(h => this.selectedHandlings.includes(h.handlingId));
    sendCommand('com.portbase.common.api.reporting.InformByMail', {
      '@class': 'io.fluxcapacitor.javaclient.common.Message',
      payload: <InformByMail>{
        handlingIds: this.selectedHandlings,
        terminalName: [...new Set(selectedHandlingModels.map(h => h.terminal.shortName))].join(", "),
        visitId: [...new Set(selectedHandlingModels.map(h => (h.visitStatus && h.visitStatus['tar']) || h.visitId))].join(", "),
        eta: [...new Set(selectedHandlingModels.map(h => moment(h.eta).format('HH:mm DD-MM-YYYY')))].join(", "),
        url: window.location.href,
        to: this.sendHandlingsEmailAddress
      }
    }, () => {
      AppContext.pendingProcesses.splice(AppContext.pendingProcesses.indexOf('SendingEmail'), 1);
      AppContext.registerSuccess("The selected handlings were successfully emailed to '" + this.sendHandlingsEmailAddress);
    }, () => {
      AppContext.pendingProcesses.splice(AppContext.pendingProcesses.indexOf('SendingEmail'), 1);
      AppContext.registerError("An error occurred sending the email to '" + this.sendHandlingsEmailAddress + "'. Please contact Portbase Customer Service");
    });
  }

  updateSelectable() {
    removeIf(this.selectedHandlings, id => !this.selectable(this.handlings.find(h => h.handlingId === id)));
    this.selectableHandlings = this.readonly ? 0 : this.handlings.filter(h => this.selectable(h)).length;
  }

  getFirstSelectedHandling(): HandlingModel {
    if (this.selectedHandlings.length === 0) {
      return null;
    }
    return this.handlings.find(h => h.handlingId === this.selectedHandlings[0])
  }

  private getComparator(handlings: HandlingModel[]) {
    let comparatorChain = new ComparatorChain();

    if (handlings.length > 0 && handlings[0].modality === "barge") {
      if (!this.sortBy.includes("visit")) {
        const voyageComparator = (h1: HandlingModel, h2: HandlingModel) =>
          h1.voyageId !== h2.voyageId || h1.visitId !== h2.visitId ? handlings.indexOf(h1) - handlings.indexOf(h2) : 0;
        comparatorChain.addProperty(voyageComparator);
      }
    }

    if (this.detached) {
      comparatorChain.addProperty('new');
    }

    this.comparators[this.sortBy].forEach(p => comparatorChain.addProperty(p));

    return comparatorChain.compare;
  }

  getSortDirection = (nextSort: string) => {
    this.sortAcceptStatusesBy = null;
    return this.sortBy === nextSort ? this.sortDirection[nextSort] : nextSort;
  }

  selectable = (handling: HandlingModel): boolean => {
    return this.visible(handling) && handling.modality != 'road'
      && !handling.new && !handling.completed && !this.readonly && this.utils.isCargoDeclarant(handling);
  };

  visible = (handling: HandlingModel): boolean => {
    return !this.hideAccepted || this.viewCompleted || this.completedCount < 2 || (handling.status !== 'accepted' && handling.status !== 'handled');
  };

  hasVisibleHandlings() {
    return !this.hideAccepted || this.viewCompleted || this.completedCount < 2 || this.completedCount < this.handlings.length;
  }

  moveSelected = (asPreNotification?: boolean) => {
    if (this.visitForAttaching) {
      this.moveOrAttachHandlings(asPreNotification);
    } else {
      this.detachHandlings();
    }

    this.attaching = false;
    this.detaching = false;
  };

  moveOrAttachHandlings(asPreNotification?: boolean) {
    const commandObservables: Observable<any>[] = [];

    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      if (model.visitId) {
        if (this.visitForAttaching['visitId'] !== model.visitId) {
          commandObservables.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.MoveHandling",
            <MoveHandling>{
              voyageId: model.voyageId,
              visitId: model.visitId,
              handlingId: handlingId,
              nextVisitId: this.visitForAttaching['visitId'],
              nextVoyageId: this.visitForAttaching['voyageId'],
              preNotification: !!asPreNotification
            }));
        }
      } else {
        commandObservables.push(this.commandGateway.send("com.portbase.hinterland.api.detached.command.AttachHandling",
          <AttachHandling>{
            handlingId: handlingId,
            nextVisitId: this.visitForAttaching['visitId'],
            nextVoyageId: this.visitForAttaching['voyageId'],
            preNotification: !!asPreNotification
          }));
      }
    });

    if (commandObservables.length > 0) {
      AppContext.waitForProcess(combineLatest(commandObservables)).subscribe(() => {
          AppContext.registerSuccess('Handlings were moved or attached successfully');
          this.selectedHandlings = [];
          this.visitForAttaching = null;
        },
        error => AppContext.registerError(error));
    }
  }

  detachHandlings() {
    const commandObservables: Observable<any>[] = [];

    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      return commandObservables.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.DetachHandling",
        <DetachHandling>{
          voyageId: model.voyageId,
          visitId: model.visitId,
          handlingId: handlingId
        }));
    });

    if (commandObservables.length > 0) {
      AppContext.waitForProcess(combineLatest(commandObservables)).subscribe(() => {
          AppContext.registerSuccess('Handlings were detached successfully');
          this.selectedHandlings = [];
          this.visitForAttaching = null;
        },
        error => AppContext.registerError(error));
    }
  }

  cancelSelected = () => {
    const cancelAttachedCommands: Array<CommandRequest> = [];
    const cancelDetachedCommands: Array<CommandRequest> = [];
    this.selectedHandlings.forEach(handlingId => {
      const handling = this.handlings.find(h => h.handlingId === handlingId);
      if (handling.visitId) {
        cancelAttachedCommands.push(<CommandRequest>{
          id: handling.handlingId,
          type: "com.portbase.hinterland.api.common.command.CancelAndDetachHandling",
          payload: <CancelAndDetachHandling>{
            voyageId: handling.voyageId,
            visitId: handling.visitId,
            handlingId: handling.handlingId
          }
        });
      } else {
        cancelDetachedCommands.push(<CommandRequest>{
          id: handlingId,
          type: "com.portbase.hinterland.api.common.command.CancelDetachedHandling",
          payload: <CancelDetachedHandling>{
            handlingId: handlingId
          }
        });
      }
    });

    AppContext.clearAlerts();
    if (cancelAttachedCommands.length > 0) {
      sendCommands(cancelAttachedCommands, (results) => {
        AppContext.registerCommandResults(results, 'handlings have been cancelled and detached successfully');
      });
    }
    if (cancelDetachedCommands.length > 0) {
      sendCommands(cancelDetachedCommands, (results) => {
        AppContext.registerCommandResults(results, 'detached handlings have been cancelled successfully');
      });
    }

    this.cancelling = false;
  };

  resendWaitingSelected = () => {
    const commandResults: Observable<any>[] = [];
    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      if (model.visitId) {
        commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.ResendWaitingHandling",
          <any>{
            voyageId: model.voyageId,
            visitId: model.visitId,
            handlingId: handlingId
          }));
      }
    });
    if (commandResults.length > 0) {
      AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
          AppContext.registerSuccess('Handlings were resent successfully');
          this.selectedHandlings = [];
          this.adminAction = null;
        },
        error => AppContext.registerError(error));
    } else {
      this.adminAction = null;
    }
  };

  resendSelected = () => {
    const commandResults: Observable<any>[] = [];
    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      if (model.visitId) {
        commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.ResendHandlingDeclaration",
          <any>{
            voyageId: model.voyageId,
            visitId: model.visitId,
            handlingId: handlingId
          }));
      }
    });
    if (commandResults.length > 0) {
      AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
          AppContext.registerSuccess('Handlings were resent successfully');
          this.selectedHandlings = [];
          this.adminAction = null;
        },
        error => AppContext.registerError(error));
    } else {
      this.adminAction = null;
    }
  };

  rejectSelected = () => {
    const commandResults: Observable<any>[] = [];
    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      if (model.visitId) {
        commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.RejectHandling",
          <RejectHandling>{
            voyageId: model.voyageId,
            visitId: model.visitId,
            handlingId: handlingId
          }));
      } else {
        commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.RejectDetachedHandling",
          <RejectDetachedHandling>{
            handlingId: handlingId
          }));
      }
    });
    if (commandResults.length > 0) {
      AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
          AppContext.registerSuccess('Handlings were rejected successfully');
          this.selectedHandlings = [];
          this.adminAction = null;
        },
        error => AppContext.registerError(error));
    } else {
      this.adminAction = null;
    }
  };

  acceptSelected = () => {
    const commandResults: Observable<any>[] = [];
    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      if (model.visitId) {
        commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.AcceptHandling",
          <AcceptHandling>{
            voyageId: model.voyageId,
            visitId: model.visitId,
            handlingId: handlingId,
            statuses: [],
            updateLatest: model.terminal.apiOnly
          }));
      } else {
        commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.AcceptDetachedHandling",
          <AcceptDetachedHandling>{
            handlingId: handlingId,
            statuses: []
          }));
      }
    });
    if (commandResults.length > 0) {
      AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
          AppContext.registerSuccess('Handlings were accepted successfully');
          this.selectedHandlings = [];
          this.adminAction = null;
        },
        error => AppContext.registerError(error));
    } else {
      this.adminAction = null;
    }
  };

  acceptCancelSelected = () => {
    const commandResults: Observable<any>[] = [];
    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      if (model.visitId) {
        let handlingDeclaration = model.handlingDeclarations.find(d => d.status == "DECLARED");
        if (!!handlingDeclaration) {
          commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.AcceptHandling",
            <AcceptHandling>{
              voyageId: model.voyageId,
              visitId: model.visitId,
              handlingId: handlingId,
              acceptCancel: true,
              updateMessageId: handlingDeclaration.messageId,
              statuses: []
            }));
        }
      }
    });
    if (commandResults.length > 0) {
      AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
          AppContext.registerSuccess('Cancels were accepted successfully');
          this.selectedHandlings = [];
          this.adminAction = null;
        },
        error => AppContext.registerError(error));
    } else {
      this.adminAction = null;
    }
  };

  prenotifySelected = () => {
    const commandResults: Observable<any>[] = [];
    this.selectedHandlings.forEach(handlingId => {
      const model = this.handlings.find(h => h.handlingId === handlingId);
      const handlingData = cloneObject(model.handlingData);
      handlingData.reefer = lodash.isEmpty(handlingData.reefer) ? null : handlingData.reefer;
      handlingData.outOfGauge = lodash.isEmpty(handlingData.outOfGauge) ? null : handlingData.outOfGauge;
      handlingData['inlandTerminal'] = lodash.isEmpty(handlingData['inlandTerminal']) ? null : handlingData['inlandTerminal'];

      if (model.visitId) {
        commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.DeclareHandling",
          <DeclareHandling>{
            voyageId: model.voyageId,
            visitId: model.visitId,
            handlingId: handlingId,
            handlingData: handlingData,
            preNotification: true
          }));
      }
    });
    if (commandResults.length > 0) {
      AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
          AppContext.registerSuccess('Handlings were prenotified successfully');
          this.selectedHandlings = [];
          this.adminAction = null;
        },
        error => AppContext.registerError(error));
    } else {
      this.adminAction = null;
    }
  };

  allHandlingsSelected = () => this.selectedHandlings.length === this.selectableHandlings;

  onlyDetachableHandlingsSelected = (): boolean => this.selectedHandlings.every(h => {
    const handling = this.handlings.find(h2 => h2.handlingId === h);
    return handling && !!handling.visitId && this.statusRequestAllowed(handling);
  });

  statusRequestAllowed(handling: HandlingModel) {
    return handling.modality === "rail" || (handling.modality === "barge" && !handling.terminal.bargeStatusRequestForbidden);
  }

  onlyPrenotifiable = (): boolean => this.selectedHandlings.every(h => {
    const handling = this.handlings.find(h2 => h2.handlingId === h);
    return handling && !!handling.visitId && !handling.preNotification;
  });

  selectItem = (handling: Handling) => {
    this.selectedHandlings.push(handling.handlingId);
    this.onSelect.emit(this.handlings.find(c => c.handlingId === handling.handlingId));
  };

  deselectItem = (handling: Handling) => {
    removeItem(this.selectedHandlings, handling.handlingId);
    this.onDeselect.emit(handling.handlingId);
    if (this.selectedHandlings.length === 0) {
      this.attaching = false;
      this.visitForAttaching = undefined;
      this.detaching = false;
    }
  };

  selectAll = () => {
    this.deselectAll();
    this.handlings.filter(h => this.selectable(h)).forEach(h => {
      this.selectedHandlings.push(h.handlingId)
      this.onSelect.emit(this.handlings.find(c => c.handlingId === h.handlingId));
    });
  };

  deselectAll = () => {
    this.selectedHandlings.forEach(handlingId => {
      this.onDeselect.emit(handlingId);
    });
    this.selectedHandlings = [];
  };

  disabled() {
    return !!this.handlings ? this.handlings[0].modality === "road" : false;
  }

  editVoyage = (handling: HandlingModel) => {
    this.router.navigateByUrl("/rotation/" + handling.voyageId + "?visitId=" + handling.visitId);
  };

  copy = () => {
    this.selectedHandlings.forEach(handlingId => {
      this.copyHandling.emit({handling: this.handlings.find(h => h.handlingId === handlingId), detached: true});
    });

    this.selectedHandlings = [];
  }

  allHandlingsInVisitCancelled = (visitId: string) => {
    return this.handlings.filter(handling => handling.visitId === visitId && !handling.cancelled).length == 0;
  }

  showVisitStatus = (handling: HandlingModel, index: number, previousHandling: HandlingModel) => {
    return index === 0
      || !handling.visitId
      || (!!this.viewCompleted && !this.viewCompleted && handling.visitId == previousHandling.visitId && (previousHandling.status == "accepted" || previousHandling.status == "handled"))
      || handling.visitId !== previousHandling.visitId;
  }

  closeModal() {
    $("#emailHandlingsModal").modal("hide");
  }
}
