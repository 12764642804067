import {Component, Input} from '@angular/core';
import {HinterlandUtils} from '../../hinterland-utils';
import {CargoMovement, Shipment} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-cargo-container',
  templateUrl: './cargo-container.component.html',
  styleUrls: ['./cargo-container.component.css']
})
export class CargoContainerComponent {

  utils = HinterlandUtils;
  @Input() movement: CargoMovement;
  @Input() shipment: Shipment;

}
