[
  {
    "shortName": "APMRTM",
    "interTerminalCode": "APM",
    "bicsCode": "0HPDE",
    "name": "HPD2"
  },
  {
    "shortName": "APMII",
    "interTerminalCode": "NLMVII",
    "bicsCode": "0APMA",
    "name" : "APM2"
  },
  {
    "shortName": "RWG",
    "interTerminalCode": "RWG",
    "bicsCode": "00RWG",
    "name" : "RWG"
  },
  {
    "shortName": "EUROMAX",
    "interTerminalCode": "EMX",
    "bicsCode": "00EMX",
    "name": "Euromax"
  },
  {
    "shortName": "ECTDELTA",
    "interTerminalCode": "DCD",
    "bicsCode": "00DDE",
    "name": "ECT Delta"
  },
  {
    "shortName": "KRAMERDELTA",
    "interTerminalCode": "KRD",
    "bicsCode": "00RCT",
    "name" : "Kramer Delta"
  }
]