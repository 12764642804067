import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {
  AttachHandling,
  CancelShipmentForwarding,
  FindVisitResult,
  ForwardShipment,
  Modality,
  RailVoyageData,
  Shipment,
  UpgradeRoadHandling
} from '@portbase/hinterland-service-typescriptmodels';
import {lodash, sendCommand, sendSameCommands, toTitleCase, uuid} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {HinterlandUtils} from '../../hinterland-utils';
import {map} from 'rxjs/operators';
import {ShipmentExcelUtils} from '../shipment-excel.utils';
import {ComparatorChain} from '../../../common/comparator-chain';
import {CancelShipment, HinterlandOrganisation} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {combineLatest, Observable} from 'rxjs';
import {Arrival} from "../arrivals-overview/arrivals-overview.component";

@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.css']
})
export class ShipmentsComponent implements OnDestroy {

  private comparator = new ComparatorChain("data.cargoDirector.shortName", "data.equipmentNumber").compare;

  appContext = AppContext;
  utils = HinterlandUtils;
  excel = ShipmentExcelUtils;
  items: Shipment[];

  @Output() actionInProgress = new EventEmitter<boolean>();

  @Input("items") set myItems(value: Shipment[]) {
    this.items = value.sort(this.comparator);
  }

  private currentAction: string;
  chosenValue = {};

  get selectedItems(): Shipment[] {
    return this.items.filter(s => !!s['selected'] && !s['hidden']);
  }

  trackByShipmentId(index: number, shipment: Shipment) {
    return lodash.first(shipment.data.consignmentNumbers) + shipment.data.equipmentNumber;
  }

  ngOnDestroy(): void {
    this.selectedItems.forEach(c => delete c['selected'])
  }

  get hiddenItems(): Shipment[] {
    return this.items.filter(g => g['hidden']);
  }

  cancelShipment(item: Shipment) {
    sendCommand('com.portbase.hinterland.api.shipment.command.CancelShipment', {id: item.id});
  }

  get chosenAction() {
    return this.currentAction;
  }

  set chosenAction(action: string | null) {
    this.currentAction = action;
    this.chosenValue = action ? this.chosenValue : {};
    this.actionInProgress.emit(action !== null);
  }

  cancelSelected() {
    sendSameCommands("com.portbase.hinterland.api.shipment.command.CancelShipment",
      this.selectedItems.filter(cancelAllowed).map(s => <CancelShipment>{id: s.id}));
    this.chosenAction = null;
    this.selectedItems.forEach(s => s['selected'] = false);
  }

  /*
    Logic for attaching to hinterland visits
   */

  static acceptableModalities: Modality[] = ['road', 'rail', 'barge'];

  getSelectedModality() {
    return this.selectedItems[0].data.inlandModality;
  }

  isAttachableModality(modality: Modality) {
    return !!modality && ShipmentsComponent.acceptableModalities.indexOf(modality) >= 0
  }

  getAttachableHinterlandMovements(shipment: Shipment) {
    return shipment.movements.filter(m => !!m.portbaseLoadingId && !m.portbaseVoyageId
      && this.isAttachableModality(shipment.data.inlandModality));
  }

  hasAttachableMovementsOfSameModality() {
    let sameModality;
    return lodash.every(this.selectedItems, s => {
      sameModality = sameModality || s.data.inlandModality;
      return sameModality === s.data.inlandModality
        && this.getAttachableHinterlandMovements(s).length !== 0;
    }) ? sameModality : null;
  }

  isSameInlandOperator() {
    const first = this.selectedItems[0].data;
    const inlandOperator = AppContext.isAdmin() ? (!!first.inlandOperator ? first.inlandOperator.shortName : null)
      : AppContext.userProfile.organisationShortName
    if (!inlandOperator) {
      return false;
    }
    return lodash.every(this.selectedItems, s => !!s.data.inlandOperator && s.data.inlandOperator.shortName === inlandOperator);
  }

  attachSelected = (asPreNotification?: boolean) => {
    const visit: FindVisitResult = this.chosenValue['visitForAttaching']
    sendSameCommands("com.portbase.hinterland.api.detached.command.AttachHandling",
      lodash.flatMap(this.selectedItems, s => this.getAttachableHinterlandMovements(s)).map(m => <AttachHandling>{
        handlingId: m.portbaseLoadingId,
        nextVisitId: visit.visitId,
        nextVoyageId: visit.voyageId,
        preNotification: !!asPreNotification
      }));
    this.chosenAction = null;
  };

  visitResultFormatter = (result: FindVisitResult) => {
    if (!result) {
      return "";
    }
    let prefix = toTitleCase(result.modality) + ` visit ${result.visitId} from ${result.terminal.shortName}`;
    if (result.tar) {
      return prefix + ` with TAR ${result.tar}`
    }
    if (result.modality === "rail") {
      const railVoyageData = <RailVoyageData>result.voyageData;
      const trackNumber = railVoyageData.dischargeVoyageNumber || railVoyageData.loadingVoyageNumber;
      return prefix + ` with train ${railVoyageData.train.trainNumber} ${trackNumber}`;
    }
    return prefix;
  }

  findVisits(term: string, modality?: Modality) {
    return HinterlandUtils.findVisits(term, modality)
      .pipe(map(results => results.length > 20 ? results.slice(0, 20) : results));
  }

  /*
    Logic for upgrading road bookings
   */

  upgradeSelected() {
    const asyncCommands: Observable<UpgradeRoadHandling>[] = lodash.flatMap(this.selectedItems, s => this.getAttachableHinterlandMovements(s)
      .map(m => combineLatest([this.utils.getNextTripNumber(), this.utils.getNextVisitId()])
        .pipe(map(([tripNumber, visitId]) => <UpgradeRoadHandling>{
          voyageId: uuid(),
          tripNumber: tripNumber,
          visitId: visitId,
          handlingId: m.portbaseLoadingId,
          visitData: {
            modality: 'road',
            eta: s.data.deepSeaEta
          },
          truckLicenseId: this.chosenValue['truckLicenseId'],
          declarant: AppContext.userProfile.organisation,
          terminal: s.data.deepSeaTerminal
        }))));
    combineLatest(asyncCommands).subscribe((commands) => {
      sendSameCommands('com.portbase.hinterland.api.road.command.UpgradeRoadHandling', commands);
      this.chosenAction = null;
    });
  }

  getOriginalDownload(): string {
    const uploadIds = lodash.uniq(this.selectedItems.filter(s => !!s.upload).map(s => s.upload));
    return uploadIds.length === 1 && "/api/uploads/" + uploadIds[0];
  }

  forwardSelected(organisation: HinterlandOrganisation) {
    if (organisation) {
      const commands: ForwardShipment[] = this.selectedItems
        .filter(forwardingAllowed)
        .map(s => <ForwardShipment>{
          id: s.id,
          inlandOperator: organisation
        });
      sendSameCommands('com.portbase.hinterland.api.shipment.command.ForwardShipment', commands);
      this.selectedItems.forEach(s => s['selected'] = false)
    }
  }

  cancelForwarding() {
    const commands: CancelShipmentForwarding[] = this.selectedItems
      .filter(cancelForwardingAllowed)
      .map(s => <CancelShipmentForwarding>{id: s.id});
    sendSameCommands('com.portbase.hinterland.api.shipment.command.CancelShipmentForwarding', commands);
    this.selectedItems.forEach(s => s['selected'] = false)
  }

  cancelAllowed(item: Shipment)  {
    return cancelAllowed(item);
  }

  cancelSelectedAllowed = () => this.selectedItems.some(cancelAllowed);

  forwardingAllowed = () => this.selectedItems.some(forwardingAllowed);

  cancelForwardingAllowed = () => this.selectedItems.some(cancelForwardingAllowed);

  asShipments = (items): Shipment[] => <Shipment[]>items;
}

function cancelAllowed(s: Shipment): boolean {
  return AppContext.isAdmin() || s.data?.cargoDirector?.shortName === AppContext.userProfile.organisationShortName;
}

function forwardingAllowed(s: Shipment): boolean {
  const inlandOperator = s.data?.inlandOperator?.shortName;
  if (inlandOperator && AppContext.isAdmin()) {
    return true;
  }
  if (inlandOperator !== AppContext.userProfile.organisationShortName) {
    return false;
  }
  const lastForwarder = lodash.last(s.forwarders)?.shortName;
  return !lastForwarder || lastForwarder !== AppContext.userProfile.organisationShortName;
}

function cancelForwardingAllowed(s: Shipment): boolean {
  return (AppContext.isAdmin() && s.forwarders?.length > 0)
    || lodash.last(s.forwarders)?.shortName === AppContext.userProfile.organisationShortName;
}
