import {Component, OnInit} from "@angular/core";
import {AppContext} from "../../app-context";
import {GetOrganisations} from "@portbase/hinterland-service-typescriptmodels";
import {sendQuery} from "../../common/utils";
import {Organisation} from "../../hinterland/hinterland-utils";

@Component({
    selector: 'app-railoperators',
    templateUrl: './railoperators.component.html',
    styleUrls: ['./railoperators.component.css']
})
export class RailOperatorsComponent implements OnInit {
    appContext = AppContext;
    railOperators: Organisation[] = [];

    ngOnInit(): void {
        sendQuery("com.portbase.hinterland.api.common.query.GetOrganisations", <GetOrganisations>{})
            .subscribe(railOperators => this.railOperators = (<Organisation[]>railOperators)
                .filter(org => org.roles.includes("railoperator/hinterlandcargonotification/HinterlandCargoDeclarant"))
                .sort((a, b) => a.organisationData.fullName > b.organisationData.fullName ? 1 : -1));
    }

}
