import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-add-remain-on-truck',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './add-remain-on-truck.component.html',
  styleUrl: './add-remain-on-truck.component.css'
})
export class AddRemainOnTruckComponent {

  callback: (containerNumber: string) => any;

  containerNumber: string;

  add() {
    this.callback(this.containerNumber);
  }

}
