<!-- icon & tooltip -->
<span *ngIf="(totalAmountOfHighPriorityNotifications > 0 || totalAmountOfMediumPriorityNotifications > 0) && compactView" class="notification-summary">
  <ng-container [ngTemplateOutlet]="tooltip"
                [ngTemplateOutletContext]="{ show: showHighPriorityNotifications,
                                           amount: totalAmountOfHighPriorityNotifications,
                                           level: 'HIGH',
                                           notifications: highLevelApplicableNotifications,
                                           visitNotifications: highPriorityVisitNotifications,
                                           handlingNotifications: highPriorityHandlingNotifications }"></ng-container>

  <ng-container [ngTemplateOutlet]="tooltip"
                [ngTemplateOutletContext]="{ show: showMediumPriorityNotifications,
                                           amount: totalAmountOfMediumPriorityNotifications,
                                           level: 'MEDIUM',
                                           notifications: mediumLevelApplicableNotifications,
                                           visitNotifications: mediumPriorityVisitNotifications,
                                           handlingNotifications: mediumPriorityHandlingNotifications }"></ng-container>

  <ng-container [ngTemplateOutlet]="tooltip"
                  [ngTemplateOutletContext]="{ show: showVoyageHighPriorityNotifications,
                                           amount: totalAmountOfHighPriorityVoyageNotifications,
                                           level: 'HIGH',
                                           notifications: highLevelApplicableNotifications,
                                           visitNotifications: [],
                                           handlingNotifications: [] }"></ng-container>

  <ng-container [ngTemplateOutlet]="tooltip"
                [ngTemplateOutletContext]="{ show: showVoyageMediumPriorityNotifications,
                                           amount: totalAmountOfMediumPriorityVoyageNotifications,
                                           level: 'MEDIUM',
                                           notifications: mediumLevelApplicableNotifications,
                                           visitNotifications: [],
                                           handlingNotifications: [] }"></ng-container>
</span>

<ng-template #tooltip let-show="show" let-amount="amount" let-level="level" let-notifications="notifications"
             let-visitNotifications="visitNotifications" let-handlingNotifications="handlingNotifications">
  <app-tooltip [wide]="true" placement="bottom bottom-left">

    <div class="tooltipContent text-start">
      <ul>
        <li *ngIf="notifications.length > 0">
          <b>This {{viewType}} has {{notifications.length}} notification{{sIfMultiple(notifications.length)}} that require{{sIfSingle(notifications.length)}} attention.</b>
        </li>
        <li *ngFor="let notification of notifications"><span class="ms-1 fa fa-exclamation-circle"></span>&nbsp;{{notification.description}}</li>
      </ul>

      <ul *ngIf="visitNotifications.length > 0">
        <li>
          <b>There {{isAre(visitNotifications.length)}} {{visitNotifications.length}} notification{{sIfMultiple(visitNotifications.length)}} on an underlying visit that require{{sIfSingle(visitNotifications.length)}} attention.</b>
        </li>
        <li *ngFor="let notification of visitNotifications"><span class="ms-1 fa fa-exclamation-circle"></span>&nbsp;{{notification}}</li>
      </ul>

      <ul *ngIf="handlingNotifications.length > 0">
        <li>
          <b>There {{isAre(handlingNotifications.length)}} {{handlingNotifications.length}} notification{{sIfMultiple(handlingNotifications.length)}} on an underlying handling that require{{sIfSingle(handlingNotifications.length)}} attention.</b>
        </li>
        <li *ngFor="let notification of handlingNotifications"><span class="ms-1 fa fa-exclamation-circle"></span>&nbsp;{{notification}}</li>
      </ul>
    </div>

    <span *ngIf="show">
      <span class="ms-1 fa" [ngClass]="level == 'HIGH' ? 'fa-minus-circle text-danger': 'fa-exclamation-circle text-warning'"></span> {{amount}}
    </span>
  </app-tooltip>
</ng-template>

<!-- notification bar -->
<div *ngIf="this.applicableNotifications.length > 0 && !compactView" class="row g-0 mt-{{topMargin}}">
  <div class="col">
    <ng-container [ngTemplateOutlet]="alert" [ngTemplateOutletContext]="{ notifications: highLevelApplicableNotifications, cssClass: 'alert-danger'}"></ng-container>
    <ng-container [ngTemplateOutlet]="alert" [ngTemplateOutletContext]="{ notifications: mediumLevelApplicableNotifications, cssClass: 'alert-warning'}"></ng-container>
    <ng-container [ngTemplateOutlet]="alert" [ngTemplateOutletContext]="{ notifications: lowLevelApplicableNotifications, cssClass: 'alert-info'}"></ng-container>
  </div>
</div>

<ng-template #alert let-notifications="notifications" let-cssClass="cssClass">
  <div class="alert {{cssClass}} alert-dismissible fade show py-2 px-4" role="alert" *ngIf="notifications.length > 0">
    <div *ngFor="let notification of notifications; let idx = index;" [ngClass]="idx > 0 ? 'pt-2' : ''">
      <strong><span [innerHTML]="getLabel(notification)"></span></strong> [{{notification.code}}] {{ notification.description }}
      <div *ngIf="idx==0" class="notification-level">Priority: {{notification?.level | titlecase}}</div>
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" style="top: -5px;"></button>
    </div>
  </div>
</ng-template>

