<div class="offcanvas-panel">
  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i data-bs-dismiss="modal"
                                                     class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">Add signalling</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Signalling details</h5>

        <app-error [errors]="errors"></app-error>

        <div class="row">
          <div class="col-12">

            <div *ngIf="context.isBinnenhavengeldSuperUserOrAdmin()">
              <app-form-field label="Signaller">
                <input *ngIf="!command.signalling.signaller; else orgSelected"
                       [ngModel]="command.signalling.signaller?.organisationId"
                       [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="selectOrganisation($event)"
                       required placeholder="Debitor number" class="form-control">
              </app-form-field>
            </div>
            <ng-template #orgSelected>
              <div class="card">
                <div class="card-body small py-2">
                  <app-bhg-organisation [organisation]="command.signalling.signaller"></app-bhg-organisation>

                  <div class="mt-3">
                    <button type="button" class="btn btn-tertiary" (click)="resetCommand();">
                      <i class="fa fa-light fa-edit"></i> <span class="ms-2">Change selection</span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>


            <app-form-field label="Ship">
              <ng-container *ngIf="!command.signalling.ship; else shipSelected">
                <app-search [ngModel]="command.signalling.ship" [searchFunction]="utils.findBinnenhavengeldShips"
                            [inputFormatter]="utils.binnenhavengeldShipFormatter" (ngModelChange)="selectShip($event)"
                            required placeholder="Ship name or ENI"></app-search>
              </ng-container>
              <ng-template #shipSelected>
                <div class="card">
                  <div class="card-body small py-2">
                    <app-bhg-ship [ship]="command.signalling.ship"></app-bhg-ship>

                    <div class="mt-3">
                      <button type="button" class="btn btn-tertiary" (click)="selectShip(undefined); ">
                        <i class="fa fa-light fa-edit"></i> <span class="ms-2">Change selection</span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </app-form-field>
            <app-form-field label="Date and time">
              <app-date-time [(ngModel)]="command.signalling.timestamp" required></app-date-time>
            </app-form-field>
            <app-form-field label="Location">
              <app-new-select [(ngModel)]="command.signalling.location" [options]="binnenhavengeldLocations"
                              [dataKey]="'code'" [formatter]="utils.binnenhavengeldLocationFormatter" required>
              </app-new-select>
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="footer-container-content">
        <div class="button-bar">
          <button class="btn btn-secondary" type="button" (click)="submit(false)">Add</button>
          <button class="btn btn-primary" type="button" (click)="submit(true)">Add and close</button>
        </div>
      </div>
    </div>
  </div>
</div>
