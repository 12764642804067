import {Component, ElementRef, OnInit, Type} from '@angular/core';
import {
  checkValidity,
  cloneObject,
  CommandRequest,
  lodash,
  scrollToTopInPanel,
  sendCommands
} from "../../../../common/utils";
import {
  Charter, DeclareVisit,
  RoadVisit, RoadVisitData,
  RoadVoyage, RoadVoyageData,
  UpdateVoyage
} from "@portbase/hinterland-service-typescriptmodels";
import {HinterlandUtils, TerminalModel} from "../../../../hinterland/hinterland-utils";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {VoyageAndVisit} from "../../../../hinterland/types";
import {AppContext} from "../../../../app-context";
import { EditModalService } from '../../../../components/modals/edit-modal/edit-modal.service';
import {
  CloseEditingDialogComponent
} from '../../../../components/dialogs/close-editing-dialog/close-editing-dialog.component';
import moment from "moment";
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";

@Component({
  selector: 'app-update-visit-offcanvas',
  templateUrl: './app-update-visit-offcanvas.html',
  styleUrls: ['./app-update-visit-offcanvas.scss']
})
export class UpdateVisitOffCanvasComponent extends HinterlandBaseComponent implements OnInit, ClosableModal {

  isModalClosable: boolean = true;
  data: VoyageAndVisit;

  voyage: RoadVoyage;
  visit: RoadVisit;

  truckLicenseIdOnInit: string;
  charterOnInit: Charter;
  etaOnInit: string;

  hasTimeslot: boolean;
  eta: string;
  etaInPast: boolean;
  errors: string[] = [];

  utils = HinterlandUtils;
  terminals: TerminalModel[] = [];

  constructor(
      private element: ElementRef,
      private readonly editModalService: EditModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.errors = [];

    this.voyage = cloneObject(this.data?.voyage);
    this.visit = cloneObject(this.data?.visit);

    this.truckLicenseIdOnInit = this.voyage.voyageData.truckLicenseId;
    this.charterOnInit = this.voyage.voyageData.charter;
    this.etaOnInit = this.visit.visitData.eta;
    this.hasTimeslot = (!!this.visit.plannedVisitData &&
      !!this.visit.plannedVisitData.etd &&
      this.visit.visitData.eta === this.visit.plannedVisitData.eta);
    this.eta = this.determineEta();

    this.utils.getTerminals('road', true, true, true).subscribe(terminals => this.terminals = terminals);
  }

  determineEta() {
    if (!!this.visit.visitData.eta) {
      return this.visit.visitData.eta;
    } else if (!!this.visit.plannedVisitData.eta) {
      return this.visit.plannedVisitData.eta;
    } else {
      return "-";
    }
  }

  validateEta() {
    this.etaInPast = moment(this.visit.visitData.eta).isBefore(moment());
  }

  declareVisit() {
    this.setModalClosable(true);

    if (checkValidity(this.element)) {
      let commandArray: Array<CommandRequest> = [];

      // change on voyage level
      if (this.voyage.voyageData.truckLicenseId != this.truckLicenseIdOnInit
        || !lodash.isEqual(this.charterOnInit, this.voyage.voyageData.charter)) {

        commandArray.push(<CommandRequest>{
          type: 'com.portbase.hinterland.api.common.command.UpdateVoyage',
          payload: <UpdateVoyage>{
            voyageId: this.voyage.voyageId,
            voyageData: <RoadVoyageData>{
              modality: "road",
              truckLicenseId: this.voyage.voyageData.truckLicenseId,
              tripNumber: this.voyage.voyageData.tripNumber,
              charter: this.voyage.voyageData.charter
            }
          }
        });
      }

      // change on visit level
      if (this.visit.visitData.eta != this.etaOnInit) {
        commandArray.push(<CommandRequest>{
          type: 'com.portbase.hinterland.api.common.command.DeclareVisit',
          payload: <DeclareVisit>{
            voyageId: this.voyage.voyageId,
            visitId: this.visit.visitId,
            visitData: <RoadVisitData>{
              modality: "road",
              eta: this.visit.visitData.eta
            },
            terminal: this.visit.terminal
          }
        });
      }

      if (commandArray.length > 0) {
        sendCommands(commandArray, results => {
          const allSuccessful = results.every(result => result.success);
          if (allSuccessful) {
            AppContext.publishSuccessMessage("The visit was updated successfully.");
            this.editModalService.closeModal();
          } else {
            results
              .filter(result => !result.success)
              .forEach(fault => {
                this.errors.push(fault.value?.error?.error);
              });
            scrollToTopInPanel();
          }
        });
      }
    }
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

  switchModal() {
    this.closeModal();
  }

  getCloseDialogComponentType(): Type<unknown> {
    return CloseEditingDialogComponent;
  }

}

