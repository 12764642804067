import {Component, Input} from '@angular/core';
import {CargoMovement} from '@portbase/hinterland-service-typescriptmodels';
import {Accept, mapStatuses} from '../../../common/handlings/handling-status/handling-status.component';
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-container-status',
  templateUrl: './container-status.component.html',
  styleUrls: ['./container-status.component.scss']
})
export class ContainerStatusComponent {
  movementsWithStatus: CargoMovement[] = [];
  errorMovements: CargoMovement[] = [];
  preNotified: boolean;
  appContext = AppContext;

  @Input() set movements(movements: CargoMovement[]) {
    this.movementsWithStatus = movements.filter(i => !!i.pickupStatus);
    this.errorMovements = this.movementsWithStatus.filter(i => i.pickupStatus.status === 'REJECTED'
      || i.pickupStatus.acceptStatuses.some(a => !a.ok));
    this.preNotified = movements.some(m => m.pickupStatus && m.pickupStatus.preNotification);
  }

  mapStatuses(item: CargoMovement): Accept[] {
    return mapStatuses(item.pickupStatus.acceptStatuses, true);
  }
}
