import {Component, Input, ViewChild} from '@angular/core';
import {CargoMovement, Shipment} from '@portbase/hinterland-service-typescriptmodels';
import {FancyCollapseComponent} from '../../../common/fancy-collapse/fancy-collapse.component';

@Component({
  selector: 'app-movement',
  templateUrl: './movement.component.html',
  styleUrls: ['./movement.component.scss']
})
export class MovementComponent {
  @ViewChild('collapse') collapse: FancyCollapseComponent;

  @Input() movement: CargoMovement;
  @Input() shipment: Shipment;
}
