<div class="bg-white border rounded m-3 p-3">
  <div class="row narrow-gutters align-items-center">
    <div class="col-auto">
      <h4 class="m-0 pe-3"><i class="fa-light fa-user-alt"
                              [ngClass]="{'text-secondary' : !user.acceptedUserAgreements?.length}"></i></h4>
    </div>
    <div class="col notranslate">
      <div class="notranslate">
        {{ user?.userId }}
      </div>
      <div class="sub-text">
        Id
      </div>
    </div>
    <div class="col notranslate d-none d-lg-block">
      <div class="notranslate">
        {{ user?.email }}
      </div>
      <div class="sub-text">
        Email
      </div>
    </div>
    <div class="col-1 mx-4 d-none d-lg-block">
      <div class="notranslate">
        {{ user?.organisationId }}
      </div>
      <div class="sub-text">
        Organisation
      </div>
    </div>
    <div class="col-auto mx-4 d-none d-xl-block">
      <div>
        {{ user?.userRoles.includes('BinnenhavengeldSuperUser') ? 'Yes' : 'No' }}
      </div>
      <div class="sub-text">
        HBR
      </div>
    </div>
    <div class="col-auto mx-4 d-none d-xl-block">
      <div>
        {{ user?.userRoles.includes('BinnenhavengeldAdmin') ? 'Yes' : 'No' }}
      </div>
      <div class="sub-text">
        Admin
      </div>
    </div>
    <div class="col-auto me-3">
      <a href="" (click)="editUser.emit(user); $event.preventDefault();">
        <span>Details</span><span class="button-icon fa-light fa-chevron-right details"></span>
      </a>
    </div>
  </div>
</div>
