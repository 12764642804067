<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Remove ‘Remain on Truck’ container</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body">
    <p>Remove 'Remain on truck' container {{ data?.['containerNumber']}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
    <button (click)="remove()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Remove</button>
  </div>
</div>
