import {AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-scroller',
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.css']
})
export class ScrollerComponent implements AfterViewInit {

  @Output() endReached = new EventEmitter();

  @ViewChild("wrapper", {static: true}) private wrapperElement: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    $(window).on('scroll', this.onScroll);
    $(window).on('wheel', this.onWheel);
  }

  private get wrapper() {
    return this.wrapperElement.nativeElement;
  }

  private onScroll = () => {
    if (window.innerHeight + window.scrollY > (this.wrapper.offsetTop + this.wrapper.offsetHeight)) {
      this.endReached.emit();
    }
  };

  private onWheel = () => {
    if (window.document.documentElement.scrollHeight == window.innerHeight) {
      this.endReached.emit();
    }
  };

}
