import {Component} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {AttachHandling, DetachedHandling, RoadHandling, RoadVisit} from "@portbase/hinterland-service-typescriptmodels";
import {EventType} from "../../../../common/event-gateway";
import {ActionMode, VoyageAndVisit, VoyageVisitAndHandling} from "../../../../hinterland/types";
import {AddHandlingOffCanvasComponent} from "../../offcanvas-panel/add-handling-offcanvas/app-add-handling-offcanvas";
import {AddVisitOffCanvasComponent} from "../../offcanvas-panel/add-visit-offcanvas/app-add-visit-offcanvas";
import {AppContext} from "../../../../app-context";
import {EditModalService} from '../../../../components/modals/edit-modal/edit-modal.service';
import {CommandRequest, sendCommands} from "../../../../common/utils";
import {MoveHandling} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.html',
  styleUrls: ['./action-panel.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(10px)'}),
        animate('500ms', style({opacity: 1, transform: 'translateY(0)'})),
      ]),
      transition(':leave', [
        animate('500ms', style({opacity: 0, transform: 'translateY(10px)'})),
      ]),
    ])]
})
export class ActionPanelComponent extends HinterlandBaseComponent {

  actionTitle = "";
  actionDescription = "";
  actionObject: VoyageVisitAndHandling | VoyageVisitAndHandling[];
  actionMode: ActionMode;

  constructor(
      private readonly editModalService: EditModalService,
  ) {
    super();
  }

  onEnterActionMode() {
    $('app-action-panel').addClass('show');
  }

  onEnterEditMode() {
    this.exitActionMode();
  }

  onStartAddingHandlingsToVisit(data: VoyageVisitAndHandling) {
    this.actionObject = data;
    this.actionMode = 'addHandlingToVisitMode';
    this.actionTitle = "Add a handling to visit " + this.visitName(data.visit);
    this.actionDescription = "Find a handling below or create a new handling."
    this.eventGateway.publish(EventType.EnterActionMode, {'visit': data.visit, 'terminal': data.visit.terminal});
  };

  onStartAttachingToVisit(data: VoyageVisitAndHandling | VoyageVisitAndHandling[]) {
    this.actionObject = this.isOne(data) ? data : data.length === 1 ? data[0] : data;
    this.actionMode = 'attachToVisitMode';

    if (this.isOne(this.actionObject)) {
      let isAttached = !this.isDetached(this.actionObject.handling);
      this.actionTitle = "Attach " + this.actionObject.handling?.handlingData?.equipmentNumber + " to visit";
      this.actionDescription = isAttached ? "Find a visit below." : "Find a visit below or create a new visit.";

      const terminal = isAttached ? this.actionObject.visit.terminal : this.actionObject.handling['terminal'];
      this.eventGateway.publish(EventType.EnterActionMode, {'handling': this.actionObject.handling, 'terminal': terminal});
    } else {
      const handlings = this.actionObject.map(vvh => vvh.handling)

      this.actionTitle = `Attach ${handlings.length} handling${handlings.length === 1 ? '' : 's'} to visit`;
      this.actionDescription = "Find a visit below.";

      const onTerminals = this.actionObject.map(vvh => this.isDetached(vvh.handling) ? vvh.handling['terminal'] : vvh.visit.terminal);
      const identical = onTerminals.every((t, _, arr) => t.shortName === arr[0].shortName);

      this.eventGateway.publish(EventType.EnterActionMode, {'handlings': handlings, 'terminal': identical ? onTerminals[0] : null});
    }
  }

  onAddHandlingToVisit(handling: RoadHandling | DetachedHandling) {
    if (this.isOne(this.actionObject)) {
      const visit = this.actionObject.visit;
      const voyage = this.actionObject.voyage;

      if (this.isDetached(handling)) {
        this.queryAndCommandGateway.attachHandling(handling, true,
          voyage.voyageId, visit.visitId,
          () => this.attachHandlingSuccess(handling, visit), (error) => this.addHandlingError(error));
      } else {
        this.queryAndCommandGateway.moveHandling(handling, handling.preNotification,
          voyage.voyageId, visit.visitId,
          () => this.moveHandlingSuccess(handling, visit), (error) => this.addHandlingError(error));
      }
    }
  }

  isDetached(h: RoadHandling | DetachedHandling): h is DetachedHandling {
    return 'attached' in h;
  }
  isOne(o: VoyageVisitAndHandling | VoyageVisitAndHandling[]): o is VoyageVisitAndHandling {
    return !(o instanceof Array);
  }

  onAttachHandlingToVisit(chosenItem: VoyageAndVisit) {
    const nextVoyageId = chosenItem.voyage?.voyageId;
    const nextVisitId = chosenItem.visit?.visitId;

    if (this.isOne(this.actionObject)) {
      if (this.isDetached(this.actionObject.handling)) {
        let detachedHandling = this.actionObject.handling as DetachedHandling;
        this.queryAndCommandGateway.attachHandling(detachedHandling, true,
          nextVoyageId, nextVisitId,
          () => this.attachHandlingSuccess(detachedHandling, chosenItem.visit), (error) => this.addHandlingError(error));
      } else {
        let roadHandling = this.actionObject.handling;
        this.queryAndCommandGateway.moveHandling(roadHandling, roadHandling.preNotification,
          nextVoyageId, nextVisitId,
          () => this.moveHandlingSuccess(roadHandling, chosenItem.visit),
          (error) => this.addHandlingError(error)
        );
      }
    } else {
      const commands: CommandRequest[] = this.actionObject.map(vvh => {
        if (this.isDetached(vvh.handling)) {
          return <CommandRequest>{
            id: vvh.handling.handlingId,
            type: "com.portbase.hinterland.api.detached.command.AttachHandling",
            payload: <AttachHandling>{
              handlingId: vvh.handling.handlingId,
              nextVisitId: nextVisitId,
              nextVoyageId: nextVoyageId,
              preNotification: true
            }
          }
        } else {
          return <CommandRequest>{
            id: vvh.handling.handlingId,
            type: "com.portbase.hinterland.api.common.command.MoveHandling",
            payload: <MoveHandling>{
              handlingId: vvh.handling.handlingId,
              nextVisitId: nextVisitId,
              nextVoyageId: nextVoyageId,
              handlingData: vvh.handling.handlingData,
              preNotification: true
            }
          }
        }
      });

      const containerNumbers = this.actionObject.map(h => h.handling.handlingData?.equipmentNumber).join(", ")
      sendCommands(commands, results => {
        if (results.every(r => r.success)) {
          AppContext.publishSuccessMessage(`Handling${results.length === 1 ? '' : 's' } ${containerNumbers} ${results.length === 1 ? 'was' : 'were' } successfully attached to visit ${this.visitName(chosenItem.visit)}.`);
        } else {
          results.map(r => {
            const vvh = (this.actionObject as VoyageVisitAndHandling[])
              .find(a => a.handling.handlingId === r.id);
            return {
              result: r,
              source: vvh
            }
          }).forEach(o => {
            const visit = this.visitName(chosenItem.visit);
            const handlingData = o.source.handling.handlingData;
            if (o.result.success) {
              AppContext.publishSuccessMessage(`Handling ${handlingData.equipmentNumber} was successfully attached to visit ${visit}.`);
            }
            else {
              AppContext.publishErrorMessage(`Handling ${handlingData.equipmentNumber} could not be attached to visit ${visit}: ${o.result.value?.error?.error}`)
            }
          });
        }
        this.exitActionMode();
      });
    }
  }

  addNewHandling() {
    const data = this.actionObject as VoyageVisitAndHandling
    this.editModalService.openModal(AddHandlingOffCanvasComponent, {
      addMode: 'addToVisit',
      visit: data.visit,
      voyage: data.voyage
    });
    this.exitActionMode();
  }

  addNewVisit() {
    const data = this.actionObject as VoyageVisitAndHandling
    this.editModalService.openModal(AddVisitOffCanvasComponent, {
      voyage: data.voyage,
      visit: data.visit,
      handling: data.handling ? data.handling : (this.actionObject as Array<VoyageVisitAndHandling>)[0].handling
    });
    this.exitActionMode();
  }

  visitName(visit: RoadVisit) {
    return visit?.plannedVisitData?.tar || visit?.visitId;
  }

  attachHandlingSuccess(handling: DetachedHandling, visit: RoadVisit) {
    AppContext.publishSuccessMessage(`Handling ${handling.handlingData.equipmentNumber} was successfully attached to visit ${this.visitName(visit)}.`);
    this.exitActionMode();
  }

  moveHandlingSuccess(handling: RoadHandling, visit: RoadVisit) {
    AppContext.publishSuccessMessage(`Handling ${handling.handlingData.equipmentNumber} was successfully moved to visit ${this.visitName(visit)}.`);
    this.exitActionMode();
  }

  addHandlingError(error) {
    let errorMessage = error?.error?.error;
    AppContext.publishErrorMessage(errorMessage ? errorMessage : 'An error occurred. Please contact Portbase Customer Service.');
  }

  exitActionMode() {
    let panel = $('app-action-panel');
    panel.addClass('hiding');
    panel.removeClass('show');

    setTimeout(() => {
      panel.removeClass('hiding');
      this.eventGateway.publish(EventType.ExitActionMode, this.actionObject);

      this.actionTitle = null;
      this.actionDescription = null;
      this.actionObject = null;
      this.actionMode = null;
    }, 300);
  }

  getHandlings() {
    return this.isOne(this.actionObject) ? [this.actionObject.handling] : this.actionObject.map(o => o.handling)
  }

  isOneDetachedHandling() {
    return this.isOne(this.actionObject) ? !this.actionObject.visit : (this.actionObject.length === 1 && !this.actionObject[0].visit);
  }

}
