<div class="offcanvas-panel" (change)="setModalClosable(false)">

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i (click)="closeModal()" class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">{{title}}</div>
      <div class="d-flex flex-fill"></div>
    </div>
    <div class="tab-bar">
      <div class="tab-pane">
        <nav #nav="ngbNav" class="nav-tabs hide-md-screen" ngbNav>
          <!-- Manual-->
          <ng-container ngbNavItem="manual">
            <a *ngIf="!data" class="tabs" ngbNavLink>Manual</a>
            <ng-template ngbNavContent>
              <div class="ngbNavContentTemplate h-100">
                <div class="nav-content">

                  <app-error [errors]="errors"></app-error>

                  <!-- Handling Details -->
                  <div class="inner-section-header">Handling Details</div>

                  <app-form-field>
                    <label>Terminal</label>
                    <app-new-select [(ngModel)]="terminal" [dataKey]="'quayName'" [formatter]="utils.terminalFormatter"
                                    (ngModelChange)="onTerminalChanged()" [options]="terminals" [optionLabel]="'displayName'"
                                    optionSelectable="shortName" required width="xl"
                                    [disabled]="data?.addMode === 'addToVisit'">
                    </app-new-select>
                  </app-form-field>

                  <app-form-field>
                    <label>Handling type</label>
                    <app-toggle [options]="['loading', 'discharge']" [formatter]="utils.handlingTypeFormatter"
                                [(ngModel)]="handlingData.type" (ngModelChange)="onHandlingTypeChanged()" required>
                    </app-toggle>
                  </app-form-field>

                  <app-form-field>
                    <label>Container status</label>
                    <app-toggle [options]="[true, false]" [formatter]="utils.containerStatusFormatter"
                                [(ngModel)]="handlingData.full" (ngModelChange)="setTerminalSettings()" required>
                    </app-toggle>
                  </app-form-field>

                  <app-form-field>
                    <label>Shipper's owned</label>
                    <app-toggle [options]="[false, true]" [formatter]="utils.yesNoFormatter"
                                [(ngModel)]="handlingData.shippersOwned" (ngModelChange)="validateEquipmentNumber()" required>
                    </app-toggle>
                  </app-form-field>

                  <app-form-field>
                    <label [ngClass]="{'required' : equipmentNumberRequired()}">Container number</label>
                    <div class="d-flex flex-row flex-wrap">
                      <input (blur)="validateEquipmentNumber()" (change)="handlingData.equipmentNumber = $any($event.target).value.toUpperCase()"
                             (paste)="onContainerNumberPaste($event)" [maxLength]="11"
                             [(ngModel)]="handlingData.equipmentNumber" (ngModelChange)="onEquipmentNumberChanged()"
                             [ngClass]="{'validation': invalidEquipmentNumberLength || invalidEquipmentNumberCheckDigit }"
                             [required]="equipmentNumberRequired()"
                             class="form-control lg"
                             placeholder="e.g. MHEU5489231" type="text">
                      <button *ngIf="duplicate" (click)="openDuplicateHandling()" type="button" class="btn btn-secondary ms-3">Open existing container</button>
                    </div>
                    <div *ngIf="duplicate" class="validation-error">
                      This container was recently added within your organisation. Edit the existing container to prevent double costs.
                    </div>
                    <div *ngIf="invalidEquipmentNumberLength else checkDigit" class="validation-error">
                      Container number does not follow format of 4 letters and 7 numbers
                    </div>
                    <ng-template #checkDigit>
                      <div *ngIf="invalidEquipmentNumberCheckDigit" class="validation-error">
                        Container number contains an incorrect check digit
                      </div>
                    </ng-template>
                  </app-form-field>

                  <app-form-field>
                    <label>Shipping company</label>
                    <app-search [(ngModel)]="handlingData.shippingCompany" [resultFormatter]="utils.shippingCompanyFormatter"
                                [searchFunction]="utils.findShippingCompanies"
                                customClass="xl"
                                dataKey="name" placeholder="e.g. Maersk Shipping Line"
                                required>
                    </app-search>
                  </app-form-field>

                  <app-form-field *ngIf="terminalSettings?.sealNumberAllowed || handlingData.sealNumber">
                    <label [ngClass]="{'required' : terminalSettings?.sealNumberAllowed}">Seal number</label>
                    <input [(ngModel)]="handlingData.sealNumber" [maxlength]="(terminal?.maxSealNumberLength) ? terminal.maxSealNumberLength : 15"
                           [required]="terminalSettings?.sealNumberAllowed"
                           class="form-control sm"
                           placeholder="e.g. BE357289"
                           type="text">
                  </app-form-field>

                  <app-form-field tooltip="Code needed to pick-up or drop-off a container. 'Pin code' is used for full pick-up containers, in other cases 'booking number' or 'pick-up/drop-off reference' are often used.">
                    <label>Acceptance reference</label>
                    <input (change)="handlingData.bookingNumber = utils.bookingNumberFormatter($any($event.target).value)" [(ngModel)]="handlingData.bookingNumber"
                           [maxLength]="40" class="form-control sm"
                           placeholder="e.g. A67888" required type="text">
                  </app-form-field>

                  <app-form-field tooltip="Depending on handling type and shipping company, you get access to ETA, ETD and cargo closing by selecting a deep sea vessel.">
                    <label>Deep sea vessel</label>
                    <app-search [(ngModel)]="handlingData.deepSea" [resultFormatter]="utils.seaVesselFormatter"
                                [searchFunction]="utils.findTerminalVisitReferences(terminal)"
                                customClass="xl" dataKey="shipName" placeholder="Vessel name or IMO code">
                    </app-search>
                  </app-form-field>

                  <app-form-field tooltip="You can use ‘Own reference’ to enter an order or reference number that
                          is used within your own company. If filled, you can use your own references to search within this application.">
                    <label>Own reference</label>
                    <input [(ngModel)]="handlingData.transportNumber" [maxLength]="40" class="form-control lg"
                           placeholder="e.g. order-3462" type="text">
                  </app-form-field>
                  <hr class="section-break-line">

                  <!-- Equipment Details  -->
                  <div class="inner-section-header">Equipment details</div>

                  <app-form-field>
                    <label>Size type</label>
                    <app-search [(ngModel)]="handlingData.sizeType" [resultFormatter]="utils.sizeTypeFormatter"
                                [searchFunction]="utils.findSizeTypes"
                                customClass="lg"
                                dataKey="name" placeholder="e.g. 45R1 (40ft high cube reefer)" required>
                    </app-search>
                  </app-form-field>

                  <app-form-field *ngIf="terminalSettings?.reeferAllowed">
                    <label>Reefer</label>
                    <app-toggle [options]="[false, true]" [formatter]="utils.yesNoFormatter"
                                [(ngModel)]="reefer" (ngModelChange)="reeferChanged()" required>
                    </app-toggle>
                  </app-form-field>

                  <div *ngIf="reefer" class="canvas-extra-content">
                    <app-form-field>
                      <label>Temperature</label>
                      <app-toggle [options]="[false, true]" [(ngModel)]="handlingData.reefer.temperatureFixed"
                                  [formatter]="utils.temperatureChoiceFormatter"
                                  (ngModelChange)="clearTemperature()" required>
                      </app-toggle>
                    </app-form-field>

                    <app-form-field *ngIf="handlingData.reefer?.temperatureFixed else temperatureRange" customClass="mb-0">
                      <label>Temperature (&deg;C)</label>
                      <input [(ngModel)]="handlingData.reefer.temperature" [maxLength]="10" class="form-control lg"
                             placeholder="between -70 and 40" type="number">
                    </app-form-field>

                    <ng-template #temperatureRange>
                      <app-form-field>
                        <label>Minimum temperature (&deg;C)</label>
                        <input [(ngModel)]="handlingData.reefer.minimumTemperature" [maxLength]="10" class="form-control lg"
                               placeholder="between -70 and 40" type="number">
                      </app-form-field>

                      <app-form-field customClass="mb-0">
                        <label>Maximum temperature (&deg;C)</label>
                        <input [(ngModel)]="handlingData.reefer.maximumTemperature" [maxLength]="10" class="form-control lg"
                               placeholder="between -70 and 40" type="number">
                      </app-form-field>
                    </ng-template>
                  </div>

                  <app-form-field *ngIf="terminalSettings?.outOfGaugeAllowed"
                                  tooltip="Out of Gauge (OOG) cargo is too large for a standard shipping container.
                                  The cargo is therefore sticking out from at least one side.
                                  Please fill in how much it’s sticking out from each side so the terminal can take this into account for their block planning.">
                    <label>Out of gauge</label>
                    <app-toggle [options]="[false, true]" [formatter]="utils.yesNoFormatter"
                                [(ngModel)]="outOfGauge" (ngModelChange)="outOfGaugeChanged()" required>
                    </app-toggle>
                  </app-form-field>

                  <div *ngIf="outOfGauge" class="canvas-extra-content">
                    <app-form-field>
                      <label>Front (door side) (cm)</label>
                      <input type="number" class="form-control sm" [(ngModel)]="handlingData.outOfGauge.front"
                             placeholder="Sticking out in cm" [maxLength]="10">
                    </app-form-field>

                    <app-form-field>
                      <label>Back (cm)</label>
                      <input type="number" class="form-control sm" [(ngModel)]="handlingData.outOfGauge.rear"
                             placeholder="Sticking out in cm" [maxLength]="10">
                    </app-form-field>

                    <app-form-field>
                      <label>Top (cm)</label>
                      <input type="number" class="form-control sm" [(ngModel)]="handlingData.outOfGauge.height"
                             placeholder="Sticking out in cm" [maxLength]="10">
                    </app-form-field>

                    <app-form-field>
                      <label>Left (cm)</label>
                      <input type="number" class="form-control sm" [(ngModel)]="handlingData.outOfGauge.left"
                             placeholder="Sticking out in cm" [maxLength]="10">
                    </app-form-field>

                    <app-form-field customClass="mb-0">
                      <label>Right (cm)</label>
                      <input type="number" class="form-control sm" [(ngModel)]="handlingData.outOfGauge.right"
                             placeholder="Sticking out in cm" [maxLength]="10">
                    </app-form-field>
                  </div>

                  <app-form-field>
                    <label>Cargo weight (kg)</label>
                    <input [(ngModel)]="handlingData.weight" appDecimalNumber maxDecimals="0" max="99999" class="form-control md"
                           placeholder="Excl. weight of container" [maxlength]="10" [required]="terminalSettings?.grossWeightAllowed">
                  </app-form-field>

                  <app-form-field>
                    <label>Tare weight (kg)</label>
                    <input [(ngModel)]="handlingData.tareWeight" appDecimalNumber maxDecimals="0" max="99999" class="form-control md"
                           placeholder="Weight of container" [maxlength]="10">
                  </app-form-field>

                  <!-- Visit details -->
                  <ng-container *ngIf="data?.addMode !== 'addToVisit'">
                    <hr class="section-break-line">

                    <div class="inner-section-header">Visit details</div>

                    <app-form-field>
                      <div class="adjustable-width md hide-md-screen"><label>ETA date</label></div>
                      <div class="adjustable-width sm hide-md-screen"><label>ETA time</label></div>
                      <div class="adjustable-width sm show-sm-screen"><label>ETA</label></div>
                      <app-date-time [(ngModel)]="eta" (ngModelChange)="validateEta()" customDateClass="md" customTimeClass="sm" [datePickerPlacement]="'top-left'" required></app-date-time>
                      <div *ngIf="etaInPast" class="validation-error">
                        The given ETA is in the past
                      </div>
                    </app-form-field>

                    <app-form-field>
                      <label>Truck license plate</label>
                      <input (change)="truckLicenseId = $any($event.target).value.toUpperCase()" [(ngModel)]="truckLicenseId" [maxLength]="40"
                             class="form-control md"
                             maxlength="15"
                             placeholder="e.g. NL-02-PB" type="text">
                    </app-form-field>

                    <app-form-field customClass="mb-0" tooltip="
                            If another company is transporting this container for you, you can select them as ‘Executing charter’.
                            Your company will stay in charge of planning the visit and the other company will be able to view the visit in HCN Road.">
                      <label>Executing charter</label>
                      <app-search (ngModelChange)="$event ? addShortName($event) : null" [(ngModel)]="charter"
                                  [resultFormatter]="utils.charterFormatter"
                                  [searchFunction]="utils.findCharters"
                                  customClass="lg"
                                  dataKey="name" placeholder="e.g. Transport B.V.">
                      </app-search>
                    </app-form-field>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ng-container>

          <!-- Excel-->
          <ng-container *ngIf="!data" ngbNavItem="excel">
            <a class="tabs hide-md-screen" ngbNavLink>Excel</a>
            <ng-template ngbNavContent>
              <div class="ngbNavContentTemplate h-100">
                <div class="nav-content">
                  <div class="inner-section-header">Upload file</div>

                  <div *ngIf="uploading else upload">
                    <div class="d-flex spinner">
                      <div class="fa-regular fa-circle-notch fa-spin fa-4x"></div>
                    </div>
                  </div>

                  <ng-template #upload>
                    <app-error [errors]="errors"></app-error>

                    <div *ngIf="file" class="drag-and-drop file-details">
                      <div style="vertical-align: middle;">
                        <div class="row file-name">{{file?.name}}</div>
                        <div class="row file-size">{{utils.formatBytes(file?.size)}}</div>
                      </div>
                      <div (click)='deleteFile()' class="btn btn-tertiary delete-file">
                        <span class="fal fa-trash-alt delete-file-btn"></span>
                      </div>
                    </div>

                    <div (fileDropped)="onFileDropped($event)" *ngIf="!file" app-drag-and-drop class="drag-and-drop">
                      <button (click)="upload.click()" class="btn btn-tertiary" tabindex="-1" type="button">Upload <input
                        #upload (change)="onUploadFile($any($event.target).files[0]); $any($event.target).value = '';" hidden type="file">
                      </button>
                      <span class="drag-and-drop-message">&nbsp;or drag and drop an Excel file</span>
                    </div>

                  </ng-template>
                  <div class="excel-container-content">
                    <button (click)='downloadTemplate()' class="col-3 btn btn-tertiary text-start" type="button">
                      <span class="fal fa-file-download me-1"></span>&nbsp;Download Excel template
                    </button>
                    <div class="button-bar p-0">
                      <button (click)="uploadDetachedHandlings()" [disabled]="!file || uploading" class="btn btn-secondary" type="button">Submit as status request</button>
                      <button (click)="uploadHandlings()" [disabled]="!file || uploading" class="btn btn-primary" type="button">Submit as pre-notification</button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </nav>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div [ngbNavOutlet]="nav" class="nav-tabs-content pb-5"></div>
  </div>

  <div *ngIf="nav.activeId !== 'excel'" class="modal-footer">
    <div class="footer-container">
        <div class="footer-container-content">
          <div *ngIf="validationErrors" class="button-bar-text validation-errors hide-md-screen">
            Please correct the errors above and try again
          </div>
          <div *ngIf="!validationErrors && terminal?.roadStatusRequestAllowed && data?.addMode !== 'addToVisit'" class="button-bar-text text-secondary hide-md-screen">
            Learn more about status requests
            <i [ngbTooltip]="statusRequestTooltip" container="body" placement="top" class="fal fa-circle-info info-icon"></i>
          </div>
          <div class="button-bar">
            <button appSingleClick [disableSubmit]="disableSubmit" (click)="submitStatusRequest()" *ngIf="terminal?.roadStatusRequestAllowed && data?.addMode !== 'addToVisit'" class="btn btn-secondary" type="button">
              <span class="hide-md-screen">Submit status request</span>
              <span class="show-sm-screen">Status request</span>
            </button>
            <button appSingleClick [disableSubmit]="disableSubmit" (click)="submitPreNotification()" class="btn btn-primary" type="button">
              <span class="hide-md-screen">Submit pre-notification</span>
              <span class="show-sm-screen">Pre-notification</span>
            </button>
          </div>
      </div>
    </div>
  </div>
</div>

<ng-template #statusRequestTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">With a ‘status request’, you can request the status of a container from the terminal without making an appointment.
      Making a status request is free. You can for example check if a document is available and can plan the ‘status request’ on a visit later.
    </div>
  </div>
</ng-template>
