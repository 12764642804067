import {Component, OnDestroy} from '@angular/core';
import {AppContext} from '../../app-context';
import {RequestGateway} from '../../common/request-gateway';

@Component({
  selector: 'app-bhg-signed-out',
  templateUrl: './bhg-signed-out.component.html',
  styleUrls: ['../bhg.scss', './bhg-signed-out.component.css']
})
export class BhgSignedOutComponent implements OnDestroy {
  context = AppContext;

  constructor() {
    document.title = 'Inland port dues';
    RequestGateway.baseUrl = "/api/proxy/binnenhavengeld";
  }

  ngOnDestroy(): void {
    document.title = 'Hinterland Notification';
    RequestGateway.baseUrl = "/api";
  }
}
