<div appTranslate>
  <!--spinning wheel-->
  <div *ngIf="context.pendingProcesses.length > 0" class="position-fixed d-flex h-100 w-100" style="z-index: 10001;">
    <div class="position-absolute bg-secondary h-100 w-100" style="opacity: 0.3"></div>
    <div class="spinner-border mx-auto text-secondary align-self-center" role="status"
         style="width: 5rem; height: 5rem; border-width: 1rem;"></div>
  </div>

  <div id='screen'>
    <div class="d-none d-md-block position-absolute" style="right: 0.75rem; top: 1rem">
      <app-language-selector></app-language-selector>
    </div>

    <main>
      <router-outlet></router-outlet>
    </main>
  </div>

  <app-edit-modal></app-edit-modal>
  <app-confirmation-modal></app-confirmation-modal>
</div>


