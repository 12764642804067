<div class="offcanvas-panel" (change)="setModalClosable(false)">

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i (click)="closeModal()" class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">Update visit</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="nav-tabs-content nav-tabs pb-5">
      <div class="tab-pane">
        <div class="nav-content">

          <app-error [errors]="errors"></app-error>

          <div class="inner-section-header">Visit details</div>

          <div class="form-group">
            <div class="update-visit-info">
              <i class="fa-light fa-circle-info fa-2x pe-2 update-visit-info-content"></i>
              <span class="update-visit-info-content" *ngIf="hasTimeslot else noTimeSlot">
                Current timeslot:
                <app-formatted-date-range [start]="eta"
                                          [end]="this.visit.plannedVisitData?.etd"
                                          [showClock]="false"
                                          style="color: #004478FF !important;">
                </app-formatted-date-range>

                (Requested:
                <app-date-time [ngModel]="this.visit.requestedVisitData.eta"
                               [showClock]="false"
                               readonly="true"
                               style="color: #004478FF !important;"
                ></app-date-time>)
              </span>
              <ng-template #noTimeSlot>
                <span class="update-visit-info-content">Current ETA:
                  <app-date-time [ngModel]="eta" [readonly]="true" [showClock]="false"></app-date-time>
                </span>
              </ng-template>
            </div>
          </div>

          <app-form-field [renderOptional]="false">
            <label>Terminal</label>
            <app-new-select [(ngModel)]="this?.visit.terminal" [disabled]="true" [formatter]="utils.terminalFormatter"
                            [options]="terminals" [dataKey]="'bicsCode'" width="lg">
            </app-new-select>
          </app-form-field>

          <app-form-field>
            <div class="adjustable-width md hide-md-screen"><label>ETA date</label></div>
            <div class="adjustable-width sm hide-md-screen"><label>ETA time</label></div>
            <div class="adjustable-width sm show-sm-screen"><label>ETA</label></div>
            <app-date-time [(ngModel)]="this.visit.visitData.eta" (ngModelChange)="validateEta()" customDateClass="md" customTimeClass="sm" required></app-date-time>
            <div *ngIf="etaInPast" class="validation-error">
              The given ETA is in the past
            </div>
          </app-form-field>

          <app-form-field>
            <label>Truck license plate</label>
            <input type="text" class="form-control md" placeholder="e.g. NL-02-PB"
                   [(ngModel)]="this.voyage.voyageData.truckLicenseId" maxlength="15"
                   (change)="this.voyage.voyageData.truckLicenseId = $any($event.target).value.toUpperCase()"
                   [maxLength]="40">
          </app-form-field>

          <app-form-field customClass="mb-0" tooltip="
                          If another company is transporting this container for you, you can select them as ‘Executing charter’.
                          Your company will stay in charge of planning the visit and the other company will be able to view the visit in HCN Road.">
            <label>Executing charter</label>
            <app-search [(ngModel)]="this.voyage.voyageData.charter" customClass="lg"
                        [searchFunction]="utils.findCharters"
                        [resultFormatter]="utils.charterFormatter"
                        (ngModelChange)="$event ? utils.addShortName($event) : null"
                        placeholder="e.g. Transport B.V." dataKey="name">
            </app-search>
          </app-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="tab-pane">
        <div class="button-bar">
          <button type="button" class="btn btn-primary" (click)="declareVisit()">Update visit</button>
        </div>
      </div>
    </div>
  </div>
</div>

