<div class="card small">
  <div class="card-body py-2">
    <div class="row">
      <div class="col-auto fw-medium" style="width: 40%">Ship</div>
      <div class="col-auto d-none d-lg-block fw-medium" style="width: 20%">Type</div>
      <div class="col-auto fw-medium" style="min-width: 18%">Start</div>
      <div class="col-auto d-none d-lg-block fw-medium" style="width: 22%">End</div>
    </div>

    <div class="row">
      <div class="col-auto notranslate text-truncate" style="width: 40%">{{subscription.ship.name}}</div>
      <div class="col-auto d-none d-lg-block" style="width: 20%">{{subscription.type}}</div>
      <div class="col-auto notranslate" style="min-width: 18%">{{subscription.start | timestamp:'DD-MM-YYYY'}}</div>
      <div class="col-auto d-none d-lg-block notranslate" style="width: 22%">{{subscription.end | timestamp:'DD-MM-YYYY 12:00'}}</div>
    </div>

    <div class="mt-3">
      <a href="#" class="text-danger text-decoration-none"
         (click)="removeSubscription.emit(subscription); $event.preventDefault()">
        <i class="fa fa-light fa-xmark"></i> <span class="ms-2">Remove subscription</span>
      </a>
    </div>
  </div>

  <div class="card-body pt-2" *ngIf="overlappingSubscriptions.length > 0">
    <app-alert [type]="'warning'" [dismissible]="false">
      <div class="alert-heading">Found overlapping subscription{{overlappingSubscriptions.length > 1 ? 's' : ''}}</div>
      <div *ngFor="let s of overlappingSubscriptions">
        Range:<span class="notranslate"> {{s.dateRange.start | date}} – {{s.dateRange.end | date}} 12:00</span>
      </div>
    </app-alert>
  </div>

</div>
