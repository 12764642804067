<div class="position-relative">
  <div class="alert alert-warning mb-2" *ngIf="showDateRangeWarning">
    Choosing a wide date range can cause longer load times.
  </div>
  <div class="row narrow-gutters mb-3">
    <div class="col-md-4 mb-2 mb-md-0" style="min-width: 19rem">
      <app-date-range [ngModel]="dateRange" (ngModelChange)="onDateSelection($event)"></app-date-range>
    </div>
    <div class="col">
      <app-filter [(ngModel)]="filterTerm" [searchFunction]="searchOrFilter" (resultsFound)="renderFilteredItems()"
                  placeholder="Filter by call ID, container number, etc..."></app-filter>
    </div>
    <div class="col-auto" *ngIf="appContext.isRailDeclarant() && appContext.isBargeDeclarant()">
      <app-modality-toggle
        [visibleModalities]="this.appContext.isFiltering('Barge') ? 'Barge' : this.appContext.isFiltering('Rail') ? 'Rail' : 'all'"></app-modality-toggle>
    </div>
    <div class="col-auto">
      <ng-container *ngIf="appContext.isBargeDeclarant() && appContext.isRailDeclarant(); else singleService">
        <button class="btn btn-info dropdown-toggle" type="button" data-bs-toggle="dropdown" tabindex="-1">
          <span class="pe-2 fa fa-plus-circle"></span><span>New rotation</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-start" style="cursor: pointer">
          <li [routerLink]="['/newRotation']" [queryParams]="{modality: 'barge'}" class="dropdown-item">Barge</li>
          <li [routerLink]="['/newRotation']" [queryParams]="{modality: 'rail'}" class="dropdown-item">Rail</li>
        </ul>
      </ng-container>
      <ng-template #singleService>
        <ng-container *ngIf="appContext.isBargeDeclarant()">
          <a [routerLink]="['/newRotation']" [queryParams]="{modality: 'barge'}" class="btn btn-info"
             tabindex="-1"><span class="pe-2 fa fa-plus-circle"></span>
            <span>New rotation</span></a>
        </ng-container>
        <ng-container *ngIf="appContext.isRailDeclarant()">
          <a [routerLink]="['/newRotation']" [queryParams]="{modality: 'rail'}" class="btn btn-info" tabindex="-1"><span
            class="pe-2 fa fa-plus-circle"></span>
            <span>New rotation</span></a>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <ng-container [ngTemplateOutlet]="category"
                [ngTemplateOutletContext]="{ items : expected, category: 'Expected', showOnLoad: true }"></ng-container>
  <ng-container [ngTemplateOutlet]="category"
                [ngTemplateOutletContext]="{ items : inOperation, category: 'In operation', showOnLoad: true }"></ng-container>
  <ng-container [ngTemplateOutlet]="category"
                [ngTemplateOutletContext]="{ items : completed, category: 'Completed', showOnLoad: false }"></ng-container>
  <ng-container [ngTemplateOutlet]="category"
                [ngTemplateOutletContext]="{ items : cancelled, category: 'Cancelled', showOnLoad: false }"></ng-container>

  <app-scroller (endReached)="loadAndRenderNextPage()">
    <ng-template #category let-items="items" let-category="category" let-showOnLoad="showOnLoad">
      <div *ngIf="asVoyages(items) as voyages" class="my-3">
        <app-collapse [collapse]="collapse" [showOnLoad]="showOnLoad" [animateOnLoad]="false">
          <div class="collapsible py-2 px-3 border border-secondary">
            <div class="row">
              <div class="col-auto">
                <h4 class="m-0 fa fa-chevron-right text-dark"></h4>
              </div>
              <div class="col fw-bold text-dark">{{ category | titlecase }}
                ({{ pagingSupported && !endReached ? '> ' : '' }}{{ voyages.length }})
              </div>
            </div>
          </div>
          <ng-template #collapse>
            <div class="collapse px-sm-2">
              <div class="pt-3" *ngIf="voyages.length > 0">
                <div *ngFor="let voyage of voyages; let index = index">
                  <app-voyage-summary [voyage]="voyage" [summaryContext]="'overview'" (click)="editVoyage(voyage)">
                    <ng-container *ngIf="voyage.voyageStatus as status">
                      <h4 class="m-0" [ngSwitch]="status">
                        <span *ngSwitchCase="'COMPLETED'" class="fa fa-check text-secondary"></span>
                        <span *ngSwitchCase="'IN_OPERATION'" class="fas fa-cog fa-spin text-secondary"></span>
                        <span *ngSwitchCase="'ACCEPTED'" class="fa fa-check-circle text-success"></span>
                        <span *ngSwitchCase="'REJECTED'" class="fa fa-times-circle text-danger"></span>
                        <span *ngSwitchCase="'PROCESSED'" class="fa fa-inbox text-info"></span>
                        <span *ngSwitchCase="'DECLARED'" class="fa fa-paper-plane text-info"></span>
                      </h4>
                    </ng-container>
                  </app-voyage-summary>
                </div>
              </div>
            </div>
          </ng-template>
        </app-collapse>
      </div>
    </ng-template>
  </app-scroller>
</div>
