<ol class="breadcrumb">
  <li class="breadcrumb-item"><a href="support">Support</a></li>
  <li class="breadcrumb-item active"><i class="fa-duotone fa-user-secret"></i> Container detective</li>
</ol>

<div id="MAIN" class="container-fluid p-0">
  <div id="INPUT" class="row pb-2">
    <div class="col">
      <input [(ngModel)]="this.containerNumber" type="text" class="form-control lg" placeholder="Container number">
    </div>
    <div class="col">
      <button (click)="investigate()" type="button" class="btn btn-info py-0">Investigate</button>
    </div>
  </div>

  <ng-container id="SHIPMENTS" *ngFor="let shipment of shipments">
    <ng-container *ngIf="summarize(shipment); let summary">
      <div class="pt-3 pb-1 smaller-text">
        BL: <span class="fw-bold">{{ shipment.data.consignmentNumbers }}</span> / shipment: {{ shipment.id }}
      </div>
      <div class="row border-top border-start border-end flex-nowrap vk-row">
        <div class="col-1 vkred" [ngClass]="{'vk-ok': summary.deepSeaTerminal !== '-'}">Terminal</div>
        <div class="col-2 vkred" [ngClass]="{'vk-ok': summary.deepSeaCarrier !== '-'}">Carrier</div>
        <div class="col-2 vkred" [ngClass]="{'vk-ok': summary.releaseToParty !== '-'}">Release-to party</div>
        <div class="col-2 vkred" [ngClass]="{'vk-ok': summary.cargoDirector !== '-'}">Cargo director</div>
        <div class="col-2 vkred" [ngClass]="{'vk-ok': summary.inlandOperator !== '-'}">Inland operator</div>
        <div class="col-2 vkred" [ngClass]="{'vk-ok': summary.releaseStatus === 'Accepted'}">CR status</div>
        <div class="col-1">Forwarders</div>
      </div>
      <div class="row border-bottom border-start border-end flex-nowrap">
        <div class="col-1">{{ summary.deepSeaTerminal }}</div>
        <div class="col-2">{{ summary.deepSeaCarrier }}</div>
        <div class="col-2">{{ summary.releaseToParty }}</div>
        <div class="col-2">{{ summary.cargoDirector }}</div>
        <div class="col-2">{{ summary.inlandOperator }}</div>
        <div class="col-2">{{ summary.releaseStatus }}</div>
        <div class="col-1">{{ summary.forwarders }}</div>
      </div>
    </ng-container>
  </ng-container>

  <div id="LOG_ITEMS" class="smaller-text">
    <div class="pt-4 row flex-nowrap">
      <div class="col-1"></div>
      <div *ngFor="let column of columns" class="col-2 even-smaller-text py-2">{{ column.id }}</div>
    </div>
    <ng-container *ngFor="let logItem of logItems">
      <div class="dashed-border row flex-nowrap">
        <div class="col-1 even-smaller-text">
          <span>{{ format(logItem.timestamp) }}</span>
        </div>
        <div *ngFor="let i of [].constructor(this.findColumnIndex(logItem))" class="col-2"></div>
        <div class="col-2" [style]="{'color': getUserColor(logItem.user, logItem.organisation)}">
          <span class="nowrap clickable" (click)="toggle(logItem.eventId)">
            {{ logItem.action || logItem.description }}
          </span>
        </div>
      </div>
      <div id="{{logItem.eventId}}" class="d-none">
        @for (param of flatten(logItem) | keyvalue; track param;) {
          <div class="details row">
            <div class="col-4">{{ param.key }}</div>
            <div class="col-4">{{ param.value }}</div>
          </div>
        }
      </div>
    </ng-container>
  </div>

  <ng-container id="ACTORS" *ngIf="this.logItems?.length > 0" class="smaller-text">
    <div class="pt-4 pb-1">Actors</div>
    @for (userColor of userColorMap | keyvalue; track userColor;) {
      <div class="row">
        <div class="col-3" [style]="{'background-color': userColor.value, 'color': 'white'}">
          {{ userColor.key }}
        </div>
      </div>
    }
  </ng-container>

  <div id="NO_RESULTS" *ngIf="this.noResults" class="pt-3">
    <i class="fa-duotone fa-magnifying-glass pe-2"></i> No results found..
  </div>
</div>


