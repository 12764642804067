import {HinterlandOrganisation, UserProfile} from '@portbase/hinterland-service-typescriptmodels';

export const mockProfiles = <UserProfile[]>[
  pcsUser(),
  bop1(),
  bop2(),
  railop1(),
  vrv1(),
  term1(),
  rwg(),
  admin(),
  portAlertTerminalBetaUser(),
  APM2()
];

function pcsUser(): UserProfile {
  return <UserProfile>{
    contact: "PCSUser",
    emailAddress: "pcsuser@portbase.com",
    organisationShortName: "DUMMY",
    userName: "user@DUMMY",
    roles: ["PcsUser"],
    organisation: <HinterlandOrganisation>{
      fullName: "Dummy company",
      shortName: "DUMMY",
      emailAddress: "user@dummy.com",
      address: "Vervoerderkade 1",
      city: "Vervoerder aan zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "1111VV",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "TC",
      customsEORINumber: "11110000",
      ean: "1234567890"
    },
  };
}

function bop1(): UserProfile {
  return <UserProfile>{
    contact: "Barge Operator - 1",
    emailAddress: "vl-test1@portbase.com",
    organisationShortName: "BOP1",
    userName: "tc@BOP1",
    roles: ["CapacityViewer", "HinterlandCargoDeclarant", "HinterlandCargoStatusrequester", "HinterlandCargoviewer", "HinterlandHandlingrequester", "PcsUser"
      , "bargeoperator/hinterlandcargonotification/HinterlandCargoDeclarant"],
    organisation: <HinterlandOrganisation>{
      fullName: "Barge Operator 1",
      shortName: "BOP1",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Vervoerderkade 1",
      city: "Vervoerder aan zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "1111VV",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "TC",
      customsEORINumber: "11110000",
      ean: "450011001"
    },
  };
}

function bop2() {
  return <UserProfile>{
    contact: "Barge Operator - 2",
    emailAddress: "johnsmith@hinterland.com",
    organisationShortName: "BOP2",
    userName: "tc@BOP2",
    roles: ["HinterlandCargoDeclarant", "HinterlandCargoStatusrequester", "HinterlandCargoviewer", "HinterlandHandlingrequester", "PcsUser"
      , "bargeoperator/hinterlandcargonotification/HinterlandCargoDeclarant"],
    organisation: <HinterlandOrganisation>{
      fullName: "Barge Operator 2",
      shortName: "BOP2",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Vervoerderkade 1",
      city: "Vervoerder aan zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "1111VV",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "tc",
      customsEORINumber: "800000001.00",
      ean: "8713755232764"
    },
  }
}


function railop1() {
  return <UserProfile>{
    contact: "Rail Operator - 1",
    emailAddress: "johnsmith@hinterland.com",
    organisationShortName: "RAILOP1",
    userName: "tc@RAILOP1",
    roles: ["HinterlandCargoDeclarant", "HinterlandCargoStatusrequester", "HinterlandCargoviewer", "HinterlandHandlingrequester", "PcsUser"
      , "railoperator/hinterlandcargonotification/HinterlandCargoDeclarant"],
    organisation: <HinterlandOrganisation>{
      fullName: "Rail Operator 2",
      shortName: "RAILOP1",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Vervoerderkade 1",
      city: "Vervoerder aan zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "1111VV",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "tc",
      customsEORINumber: "800000001.00",
      ean: "8713755232764"
    },
  }
}

// Road Operators
function vrv1() {
  return <UserProfile>{
    contact: "Road Operator - 1",
    emailAddress: "johnsmith@hinterland.com",
    organisationShortName: "VRV1",
    userName: "tc@VRV1",
    roles: ["HinterlandCargoDeclarant", "HinterlandCargoviewer", "PcsUser", "roadhaulier/hinterlandcargonotification/HinterlandCargoDeclarant"],
    organisation: <HinterlandOrganisation>{
      fullName: "Road Operator 1",
      shortName: "BOP1",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Vervoerderkade 1",
      city: "Vervoerder aan zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "1111VV",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "tc",
      customsEORINumber: "800000001.00",
      ean: "8713755232764"
    },
  }
}

// Terminal
function term1() {
  return <UserProfile>{
    contact: "Terminal - Terminal 1",
    emailAddress: "johnsmith@portbase.com",
    organisationShortName: "TERM1",
    userName: "tc@TERM1",
    roles: ["HinterlandTerminal", "PcsUser"],
    organisation: {
      fullName: "Terminal 1",
      shortName: "TERM1",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Terminalkade 1",
      city: "Terminal aan Zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3199 LD",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "TC",
      customsEORINumber: "NL100000897",
      ean: "390000003"
    }
  };
}

function rwg() {
  return <UserProfile>{
    contact: "Terminal - RWG",
    emailAddress: "johnsmith@portbase.com",
    organisationShortName: "RWG",
    userName: "tc@RWG",
    roles: ["HinterlandTerminal", "PcsUser"],
    organisation: {
      fullName: "Terminal RWG",
      shortName: "RWG",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Terminalkade 1",
      city: "Terminal aan Zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3199 LD",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "TC",
      customsEORINumber: "NL100000897",
      ean: "8713755270896"
    }
  };
}

export function portAlertTerminalBetaUser() {
  return <UserProfile>{
    contact: "Terminal - RST",
    emailAddress: "johnsmith@portbase.com",
    organisationShortName: "RST",
    userName: "tc@RST",
    roles: ["HinterlandTerminal", "PcsUser"],
    organisation: {
      fullName: "Terminal RST",
      shortName: "RST",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Terminalkade 1",
      city: "Terminal aan Zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3199 LD",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "TC",
      customsEORINumber: "NL100000897",
      ean: "8713755948719"
    }
  };
}

export function APM2(){
  return <UserProfile>{
    contact: "Terminal - APMII",
    emailAddress: "johnsmith@portbase.com",
    organisationShortName: "APMII",
    userName: "tc@APMII",
    roles: ["HinterlandTerminal", "PcsUser"],
    organisation: {
      fullName: "Terminal APMII",
      shortName: "APMII",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Terminalkade 1",
      city: "Terminal aan Zee",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3199 LD",
      phoneNumber: "0102522288",
      faxNumber: "0102522250",
      contact: "TC",
      customsEORINumber: "NL100000897",
      ean: "8713755272258"
    }
  };
}

// Admin
function admin(): UserProfile {
  return {
    contact: "System Administrator",
    emailAddress: "vl-test1@portinfolink.com",
    organisationShortName: "PORTINFOLINK",
    userName: "pi.administrator@PORTINFOLINK",
    roles: ["HinterlandProcessAdministrator"],
    organisation: {
      fullName: "Portbase",
      shortName: "PORTINFOLINK",
      emailAddress: "vl-test1@portinfolink.com",
      address: "Blaak 16",
      city: "Rotterdam",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3195 ND",
      phoneNumber: "+31-10-2522222",
      faxNumber: null,
      contact: "Portbase Customer Service",
      customsEORINumber: "NL811203992",
      ean: "190000111"
    },
    organisationModalities: ['barge', 'rail', 'road']
  };
}
