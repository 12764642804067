import {Component} from '@angular/core';
import {AppContext} from './app-context';
import {NavigationEnd, Router} from '@angular/router';
import {sendQuery, toWebsocketUrl} from './common/utils';
import {CookieService} from 'ngx-cookie';
import * as Sentry from '@sentry/browser';
import {Application} from '@portbase/hinterland-service-typescriptmodels';
import {environment} from '../environments/environment';
import moment from 'moment-timezone';
import {EventGateway, EventType} from './common/event-gateway';
import {NgbNavConfig} from "@ng-bootstrap/ng-bootstrap";
import {BetaUserService} from "./port-alert/services/beta-user.service";
import userflow from 'userflow.js';

declare var Beamer;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  window = window;
  private socket: WebSocket;
  appContext = AppContext;
  pcsDown: boolean;

  constructor(private router: Router, private cookieService: CookieService, private eventGateway: EventGateway, ngbNavConfig: NgbNavConfig, private betaUserService: BetaUserService) {
    moment.tz.setDefault("Europe/Amsterdam");
    AppComponent.startSentry();
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setApplicationContext(e);

        if (AppContext.binnenhavengeld && !e.url?.startsWith("/binnenhavengeld")) {
          this.router.navigateByUrl("/binnenhavengeld")
          return;
        }

        if (!AppContext.binnenhavengeld && !AppContext.userProfile) {
          const jwt = router.parseUrl(e.url).queryParamMap.get('jwt');
          if (jwt) {
            cookieService.put("hcnjwt", jwt);
            router.navigate([], {
              queryParams: {
                jwt: null
              },
              queryParamsHandling: 'merge'
            });
          } else {
            this.getUserProfile();
          }
        }
      }
    });
    ngbNavConfig.animation = false;
  }

  private setApplicationContext(e: NavigationEnd) {
    const filters = ['all'];
    let user_id_prefix = 'mca';
    if (e.url && e.url.startsWith('/message-overview/road')) {
      AppContext.roadMessages = true;
      filters.push('pcs', 'mca', 'mcaroad', 'mca-road');
      user_id_prefix = 'mca-road';
      if (this.appContext.environment === 'kt') filters.push('mca_kt');
    } else if (e.url && e.url.startsWith('/port-alert')) {
      AppContext.road = true;
      filters.push('port-alert');
      user_id_prefix = 'port-alert';
    } else if (e.url && e.url.startsWith('/road')) {
      AppContext.road = true;
      filters.push('pcs', 'mca', 'mcaroad', "mca-road");
      user_id_prefix = 'mca-road';
      if (this.appContext.environment === 'kt') filters.push('mca_kt');
    } else if (e.url?.startsWith("/binnenhavengeld") || window.location.hostname.includes("binnenhavengeld")) {
      AppContext.binnenhavengeld = true;
      filters.push('binnenhavengeld');
      user_id_prefix = 'binnenhavengeld';
    } else if (!this.appContext.userProfile) {
      setTimeout(() => this.setApplicationContext(e), 1_000);
      return;
    } else {
      filters.push('pcs', 'mca');
      if (this.appContext.environment === 'kt') filters.push('mca_kt');
      if (this.appContext.isBargeDeclarant() || this.appContext.isHinterlandTerminal()) filters.push('mcabarge', 'mca-barge');
      if (this.appContext.isRailDeclarant() || this.appContext.isTractionSupplier() || this.appContext.isHinterlandTerminal()) filters.push('mcarail', 'mca-rail');
    }
    Beamer.update({
      user_id: user_id_prefix + "_" + (this.appContext.userProfile?.iamcUserId || this.appContext.userProfile?.userName || "unknown"),
      filter: filters.join(';')
    });

  }

  private static startSentry() {
    if (environment.production) {
      Sentry.init({
        environment: AppContext.environment === 'pcs' ? 'prod' : AppContext.environment,
        dsn: environment.sentryUrl
      });
    }
  }

  private getKeepAliveToken = () => {
    sendQuery('com.portbase.common.api.authentication.query.GetKeepAliveToken', {},
      {caching: false, showSpinner: !AppContext.userProfile, hideError: false}).subscribe(response => {
      if (!AppContext.userProfile) {
        this.getUserProfile();
      } else {
        setTimeout(() => this.getKeepAliveToken(), 290_000); //refresh every ±5 minute
      }
    }, error => {
      if (error.status === 401) {
        const headers = error.headers;
        if (headers && headers.get("pcs-down") === 'true') {
          this.onPcsDown();
        } else {
          AppComponent.redirectToLoginPage();
        }
      } else {
        this.getUserProfile();
      }
    });
  }

  private getUserProfile = () => {
    sendQuery('com.portbase.common.api.authentication.query.GetUserProfile', {},
      {caching: false, showSpinner: !AppContext.userProfile, hideError: false}).subscribe(profile => {
      if (!AppContext.userProfile) {
        this.openWebSocket();
      }
      AppContext.userProfile = profile;
      if (AppContext.useUserflowFeatures()) {
        userflow.init('ct_mozw6wdzjneg3pctsj2yozolya');
        userflow.setPageTrackingDisabled(true);
        userflow.identify(AppContext.userProfile.userName, {
          name: AppContext.userProfile.userName,
          email: AppContext.userProfile.emailAddress,
          locale_code: "en-US"
        });

        userflow.group(AppContext.userProfile.organisation.shortName, {
          name: AppContext.userProfile.organisation.fullName
        })
      }

      setTimeout(() => this.getKeepAliveToken(), 290_000); //refresh every ±5 minute

      this.betaUserService.isPortAlertBetaUser().subscribe(portAlertBetaUser => {
        AppContext.portAlertBetaUser = portAlertBetaUser;
      });

      sendQuery('com.portbase.common.api.authentication.query.GetAuthorisingOrganisations', {organisation: profile?.organisationShortName}, {
        caching: true,
        showSpinner: false,
        hideError: true
      }).subscribe(authorizingOrganisations => {
        AppContext.authorizingOrganisations = authorizingOrganisations
      });
    }, error => {
      if (error.status === 401) {
        const headers = error.headers;
        if (headers && headers.get("pcs-down") === 'true') {
          this.onPcsDown();
        } else {
          AppComponent.redirectToLoginPage();
        }
      } else {
        setTimeout(() => this.getUserProfile(), 1_000);
      }
    });
  };

  private openWebSocket = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl("/api/hinterland-updates"));
      } catch (e) {
        console.info("Could not open websocket. Trying to reconnect...", e);
        setTimeout(this.openWebSocket, 10_000);
        return;
      }
      this.socket.onmessage = (message: MessageEvent) => {
        if (typeof message.data === 'string') {
          const event = JSON.parse(message.data);
          switch (event.type) {
            case 'voyageUpdate':
              this.eventGateway.publish(EventType.UpdateVoyage, event.value);
              break;
            case 'voyagePatch':
              this.eventGateway.publish(EventType.PatchVoyage, event);
              break;
            case 'detachedHandlingUpdate':
              this.eventGateway.publish(EventType.UpdateDetachedHandling, event.value);
              break;
            case 'detachedHandlingPatch':
              this.eventGateway.publish(EventType.PatchDetachedHandling, event);
              break;
            case 'cargoShipmentUpdate':
              this.eventGateway.publish(EventType.UpdateShipment, event);
              break;
            case 'applicationUpdate':
              this.appContext.application = <Application>event.value;
              break;
            default:
              console.debug('Unknown WebSocket event: ' + JSON.stringify(message));
          }
        }
      };
      this.socket.onclose = (event: CloseEvent) => {
        if (event.code === 4001) {
          if (AppContext.environment) {
            console.log("Not authenticated for websocket. Redirecting to login page.");
            AppComponent.redirectToLoginPage();
            return;
          }
          console.log("Not authenticated for websocket. Trying to reconnect...");
        }
        if (!event.wasClean) {
          console.warn("Websocket closed with reason: " + event.reason + " (" + event.code + "). Trying to reconnect...");
        }
        setTimeout(() => this.openWebSocket(), 5_000);
      }
    }
  };

  private onPcsDown() {
    this.pcsDown = true;
  }

  private static redirectToLoginPage() {
    if (environment.production || environment.docker) {
      window.location.href = '/api/login';
    }
  }
}
