import {Component, ElementRef, OnInit} from '@angular/core';
import {SetBinnenhavengeldPassword} from '@portbase/hinterland-service-typescriptmodels';
import {Location} from '@angular/common';
import {checkValidity, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {Router} from '@angular/router';

@Component({
  selector: 'app-bhg-set-password',
  templateUrl: './bhg-set-password.component.html',
  styleUrls: ['./bhg-set-password.component.css']
})
export class BhgSetPasswordComponent implements OnInit {
  command: SetBinnenhavengeldPassword = {};

  errors: string[];

  constructor(public location: Location, private element: ElementRef, private router: Router) {
    const url = router.parseUrl(router.url);
    this.command.recoveryCode = url.queryParamMap.get('recoveryCode');
    this.command.userId = url.queryParamMap.get('userId');
  }

  ngOnInit() {
    this.errors = [];
  }

  setPassword = () => {
    if (checkValidity(this.element)) {
      AppContext.clearAlerts();
      this.errors = [];
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.SetBinnenhavengeldPassword', this.command,
        () => {
          AppContext.publishSuccessMessage("Your request was successful. Please sign in using your new password.");
          this.router.navigateByUrl("/binnenhavengeld/login")
        },
        (error) => {
          this.errors.push(error?.error?.error);
        });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  };

}
