<div *ngIf="actionObject" class="action-panel">
  <div class="action-panel-header row g-0 justify-content-between">
    <div class="col">
      <div class="action-title">
        {{actionTitle}}
      </div>
      <div class="action-description">
        {{actionDescription}}
      </div>
    </div>
    <div class="col-auto action-buttons text-end">
      <button (click)="addNewHandling()" *ngIf="actionMode === 'addHandlingToVisitMode'" class="btn btn-secondary me-4 hide-md-screen" type="button">
        <i class="fal fa-plus fa-lg button-icon pe-2"></i><span>Create new handling</span>
      </button>
      <button (click)="addNewVisit()" *ngIf="actionMode === 'attachToVisitMode' && isOneDetachedHandling()" class="btn btn-secondary me-4 hide-md-screen" type="button">
        <i class="fal fa-plus fa-lg button-icon pe-2"></i><span>Create new visit</span>
      </button>

      <button (click)="exitActionMode()" class="btn btn-tertiary" type="button">
        <i class="fa-light fa-xmark fa-xl"></i>
      </button>
    </div>
  </div>
  <ng-container [ngSwitch]="actionMode">
    <ng-container *ngSwitchCase="'addHandlingToVisitMode'" [ngTemplateOutletContext]="{visit: actionObject.visit}"
                  [ngTemplateOutlet]="visitAction"></ng-container>
    <ng-container *ngSwitchCase="'attachToVisitMode'" [ngTemplateOutletContext]="{handlings: getHandlings()}"
                  [ngTemplateOutlet]="handlingAction"></ng-container>
  </ng-container>
</div>

<ng-template #handlingAction let-handlings="handlings">
  <ng-container *ngFor="let handling of handlings">
  <app-handling-panel
    [actionMode]="actionMode"
    [displayMode]="'attachToVisitMode'"
    [handling]="handling"
    [handlingData]="handling['handlingData']">
  </app-handling-panel>
  </ng-container>
</ng-template>

<ng-template #visitAction let-visit="visit">
  <app-visit-panel class="d-flex flex-column"
    [actionMode]="actionMode"
    [displayMode]="'attachHandling'"
    [visit]="visit"></app-visit-panel>
</ng-template>
