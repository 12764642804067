import {Component, Input, OnInit} from '@angular/core';
import {GetShipmentHistory, ShipmentHistoryItem} from '@portbase/hinterland-service-typescriptmodels';
import {ComparatorChain} from '../../../common/comparator-chain';
import {sendQuery} from '../../../common/utils';

@Component({
  selector: 'app-shipment-history',
  templateUrl: './shipment-history.component.html',
  styleUrls: ['./shipment-history.component.scss']
})
export class ShipmentHistoryComponent implements OnInit {

  items: ShipmentHistoryItem[] = [];
  reverseOrder = true;
  @Input() shipmentId: string;

  private logComparator = new ComparatorChain("timestamp").compare;

  ngOnInit(): void {
    sendQuery("com.portbase.hinterland.api.shipment.query.GetShipmentHistory", <GetShipmentHistory>{shipmentId: this.shipmentId},
      {showSpinner: true, caching: false})
      .subscribe((items: ShipmentHistoryItem[]) => this.items = items.sort(this.logComparator));
  }

  cast = (item: ShipmentHistoryItem): ShipmentHistoryItem => item;

  toggleOrder() {
    this.reverseOrder = !this.reverseOrder;
  }

}
