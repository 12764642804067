import {Component, Input} from '@angular/core';
import {Shipment} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-cargo-goods',
  templateUrl: './cargo-goods.component.html',
  styleUrls: ['./cargo-goods.component.css']
})
export class CargoGoodsComponent {

  @Input() shipment: Shipment;

}
