import {Component, Input} from '@angular/core';
import {sendCommand} from "../../../../common/utils";
import {InformByMail} from "@portbase/hinterland-service-typescriptmodels";
import {FormFieldComponent} from "../../../../components/user-forms/form-field/form-field.component";
import {FormsModule} from "@angular/forms";
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-email-handlings-confirmation',
  templateUrl: './email-handlings-confirmation.component.html',
  styleUrls: ['./email-handlings-confirmation.component.scss'],
  imports: [
    FormFieldComponent,
    FormsModule
  ],
  standalone: true
})
export class EmailHandlingsConfirmationComponent {

  @Input() data;
  emailAddress: string;

  callback: (result: boolean) => any;

  emailHandlings() {
    sendCommand('com.portbase.common.api.reporting.InformByMail', <InformByMail>{
        handlingIds: Array.from(this.data?.handlingIds),
        url: this.data?.href,
        to: this.emailAddress
      },
      () => {
        AppContext.publishSuccessMessage("The selected handlings were successfully emailed to '" + this.emailAddress + "'.");
        this.callback(true);
      }, (error) => {
        let errorMessage = error?.error?.error;
        AppContext.publishErrorMessage(errorMessage ? errorMessage : "An error occurred sending the email to '" + this.emailAddress + "'.")
      });
  }

}
