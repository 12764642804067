import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AcceptStatus, AcceptStatusType, Handling} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {HandlingModel, HandlingStatus} from '../../../hinterland-utils';
import {AppContext} from "../../../../app-context";
import {Terminal} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-handling-status',
  templateUrl: './handling-status.component.html',
  styleUrls: ['./handling-status.component.css']
})
export class HandlingStatusComponent {
  handling: HandlingModel;
  acceptStatuses: Accept[] = [];
  appContext = AppContext;

  @Input() keepTooltipOpen: boolean;
  @Input() hideTooltip;
  @Input() displayReason;
  @Input() status: HandlingStatus;
  @Input() terminal: Terminal;

  @Input() set setHandling(handling: HandlingModel) {
    this.handling = handling;
    this.acceptStatuses = mapAcceptStatuses(handling);
  }

  @Output() selectedAcceptStatusType = new EventEmitter<Accept>();

  selectAcceptStatus(status: Accept) {
    this.selectedAcceptStatusType.emit(status);
  }

  shouldShowWarningIcon(): boolean {
    return this.handling['waitingForCloseVisit']
      || (this.handling.visitDeclarationsMade && this.handling.visitNeverAcknowledged && !this.terminal.apiOnly);
  }

  shouldHideTooltip() {
    return (this.hideTooltip
        || !this.status
        || this.status === 'declared'
        || this.status === 'stuck'
        || this.status === 'unknown'
        || this.status === 'cancelled');
  }
}

// Accept status
export interface Accept {
  ok: boolean;
  type: AcceptStatusType,
  alerts: AcceptStatus[],
  icon: string,
  class: string,
  label: string
}

export function mapAcceptStatuses(handling: Handling): Accept[] {
  return mapStatuses(!handling.handlingDeclaration ? [] : handling.handlingDeclaration.acceptStatuses,
    handling.handlingData.type === 'loading');
}

export function mapStatuses(input : AcceptStatus[], loading: boolean): Accept[] {
  const loadingTypes: AcceptStatusType[] = ["BLOCKED", "CUSTOMS_DOCUMENT", "AVAILABLE", "COMMERCIAL_RELEASE"];
  const dischargeTypes: AcceptStatusType[] = ["BLOCKED", "CUSTOMS_DOCUMENT", "CARGO_OPENING", "BOOKING_APPROVED"];

  const statuses: Accept[] = [];
  const types = loading ? loadingTypes : dischargeTypes;
  types.forEach(type => {
    const acceptStatuses = input.filter(s => s.type === type);
    if (acceptStatuses.length > 0) {
      const ok = acceptStatuses
        .reduce((a, b) => a.ok === null || a.ok === undefined ? b : a, {ok: undefined}).ok;
      statuses.push({
        ok: ok,
        type: type,
        alerts: acceptStatuses.filter(s => s.ok === false && s.remark),
        class: getStatusClass(ok),
        icon: getIcon(type),
        label: getLabel(type),
      });
    } else {
      statuses.push({
        ok: null,
        type: type,
        alerts: acceptStatuses.filter(s => s.ok === false && s.remark),
        class: getStatusClass(null),
        icon: getIcon(type),
        label: getLabel(type),
      });
    }
  });
  const otherStatuses = input.filter(s => types.indexOf(s.type) < 0 && s.ok !== true && s.code !== null);
  let otherStatusOkNull: Accept = null;
  let otherStatusOkFalse: Accept = null;
  otherStatuses.forEach(s => {
    s.ok == null ? otherStatusOkNull = mergeOtherStatus(s.ok, otherStatusOkNull, s) : otherStatusOkFalse = mergeOtherStatus(s.ok, otherStatusOkFalse, s);
  });
  if (!!otherStatusOkNull) statuses.push(otherStatusOkNull);
  if (!!otherStatusOkFalse) statuses.push(otherStatusOkFalse);
  return statuses;
}

function mergeOtherStatus(ok: boolean, otherstatus: Accept, s: AcceptStatus): Accept {
  let mergedAccept: Accept = otherstatus == null ? <Accept>{alerts:[]} : otherstatus;
  mergedAccept.alerts.push(s);
  mergedAccept.icon = 'fa-question';
  mergedAccept.label = 'Other issue';
  mergedAccept.class = getStatusClass(ok);
  mergedAccept.ok = ok;
  return mergedAccept;
}

function getStatusClass(ok: boolean) {
  return ok === undefined || ok === null ? "text-secondary" : ok ? "text-success" : "text-danger";
}

function getIcon(type: AcceptStatusType): string {
  switch (type) {
    case 'BLOCKED': return 'fa-lock-open';
    case 'CUSTOMS_DOCUMENT': return 'fa-file-alt';
    case 'AVAILABLE': return 'fa-check-square';
    case 'COMMERCIAL_RELEASE': return 'fa-euro-sign';
    case 'CARGO_OPENING': return 'fa-clock';
    case 'BOOKING_APPROVED': return 'fa-key';
    default: return 'fa-question'
  }
}

function getLabel(type: AcceptStatusType): string {
  switch (type) {
    case 'BLOCKED': return 'Blocked';
    case 'CUSTOMS_DOCUMENT': return 'Documents available';
    case 'AVAILABLE': return 'Present';
    case 'COMMERCIAL_RELEASE': return 'Commercial release';
    case 'CARGO_OPENING': return 'Cargo opening';
    case 'BOOKING_APPROVED': return 'Acceptance reference';
    case 'SIZETYPE_APPROVED': return 'Size type approved';
    default: return 'Other';
  }
}


