<div class="row">
  <div class="col-12">
    <app-form-field label="Ship">
      <ng-container *ngIf="!subscription.ship; else shipSelected">
        <app-search [(ngModel)]="subscription.ship" [searchFunction]="utils.findBinnenhavengeldShips"
                    [inputFormatter]="utils.binnenhavengeldShipFormatter"
                    required placeholder="Ship name or ENI"></app-search>
      </ng-container>
      <ng-template #shipSelected>
        <div class="card">
          <div class="card-body small py-2">
            <app-bhg-ship [ship]="subscription.ship"></app-bhg-ship>

            <div class="mt-3">
              <button type="button" class="btn btn-tertiary" (click)="subscription.ship = undefined">
                <i class="fa fa-light fa-edit"></i> <span class="ms-2">Change selection</span>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </app-form-field>
  </div>
  <div class="col-6">
    <app-form-field label="Subscription type">
      <app-new-select [(ngModel)]="subscription.type" [options]='subscription.ship?.type === "other"
      ? [undefined, "week", "fortnight", "month", "quarter", "year"] : [undefined, "week", "fortnight", "quarter", "year"]'
                  (ngModelChange)="onSubscriptionType($event)" required></app-new-select>
    </app-form-field>
  </div>
  <div class="col-6">
    <app-form-field label="Start date"
                    *ngIf="subscription.type === 'week' || subscription.type === 'fortnight' || subscription.type === 'month'">
      <app-date-time [ngModel]="subscription.start" (ngModelChange)="selectDate($event)" [dateOnly]="true" required [ngClass]="{'ng-invalid' : startDateBeforeCutoff}"></app-date-time>
      <ng-container *ngIf="subscription.start">
        <div *ngIf="cutoffDate" class="invalid-feedback">Minimum start date is <span>{{cutoffDate | timestamp : 'DD-MM-YYYY'}}</span></div>
        <div *ngIf="!cutoffDate" class="invalid-feedback">Start date is more than 35 days in the past</div>
      </ng-container>
    </app-form-field>
    <app-form-field label="Quarter" *ngIf="subscription.type === 'quarter'">
      <app-new-select [ngModel]="getQuarter()" (ngModelChange)="selectQuarter($event)"
                  [options]=quarters required class="notranslate"></app-new-select>
    </app-form-field>
    <app-form-field label="Year" *ngIf="subscription.type === 'year'">
      <app-new-select [ngModel]="getYear()" (ngModelChange)="selectYear($event)"
                  [options]=years required class="notranslate"></app-new-select>
    </app-form-field>
  </div>
</div>
