<div class="row">
  <div class="col-auto fw-medium" style="width: 40%">Name</div>
  <div class="col-auto fw-medium" style="min-width: 20%">ENI-number</div>
  <div class="col-auto d-none d-lg-block fw-medium" style="width: 20%">Type</div>
  <div class="col-auto d-none d-lg-block fw-medium" style="width: 20%">
    <span>Category</span>

    <app-tooltip [placement]="'left-top'" [keepOpen]="true" cssClass="bhg-category-tooltip" container="false">
      <i class="ms-1 fa fa-info-circle"></i>

      <div class="tooltipContent">
        <table class="bhg-ship-category">
          <thead>
          <tr>
            <td class="notranslate">No.</td>
            <td>Type of Vessel</td>
            <td>Raise/Discount</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="notranslate">C1</td>
            <td>Vessels with propulsion engines that do not meet the CCR2* emission standard.</td>
            <td class="notranslate">+10%</td>
          </tr>
          <tr>
            <td class="notranslate">C2</td>
            <td>Vessels with propulsion engines that do meet the CCR2* emission standard.</td>
            <td class="notranslate">n/a</td>
          </tr>
          <tr>
            <td class="notranslate">C3</td>
            <td>Vessels with a Green Award certificate that at least meet the CCR2* emission standard and have a
              score lower than 400 points for the propulsion engines.
            </td>
            <td class="notranslate">-15%</td>
          </tr>
          <tr>
            <td class="notranslate">C4</td>
            <td>Vessels with propulsion engines that are at least 60% cleaner than the CCR2* emission 4 standard
              and/or Vessels with a Green Award certificate issued later than 17 June 2014 with a score of 400
              points or more for the propulsion engines.
            </td>
            <td class="notranslate">-30%</td>
          </tr>
          <tr>
            <td class="notranslate">C5</td>
            <td>Vessels without propulsion engines / Barges.</td>
            <td class="notranslate">n/a</td>
          </tr>
          <tr>
            <td class="notranslate">C6</td>
            <td>Vessels with propulsion engines with a Green Award Platinum Label.</td>
            <td class="notranslate">-100%</td>
          </tr>
          <tr>
            <td colspan="2">More information on the Environmental Category is available from <a
              href="http://www.greenaward.org" target="_blank" class="notranslate">www.greenaward.org</a></td>
            <td>* of PM and NOX</td>
          </tr>
          </tbody>
        </table>
      </div>
    </app-tooltip>
  </div>
</div>
<div class="row">
  <div class="col-auto notranslate text-truncate" style="width: 40%">{{ship.name}}</div>
  <div class="col-auto notranslate" style="min-width: 20%">{{ship.eniNumber}}</div>
  <div class="col-auto d-none d-lg-block" style="width: 20%">{{formatShipType(ship.type)}}</div>
  <div class="col-auto d-none d-lg-block notranslate" style="width: 20%">{{ship.category}}</div>
</div>

<div class="row d-none d-lg-flex mt-2">
  <div class="col-auto fw-medium" style="width: 20%">Tonnage</div>
  <div class="col-auto fw-medium" style="width: 20%">Surface</div>
  <div class="col-auto fw-medium" style="width: 20%">Length</div>
  <div class="col-auto fw-medium" style="width: 20%">Width</div>
  <div class="col-auto fw-medium text-nowrap" style="width: 20%">Draught</div>
</div>

<div class="row d-none d-lg-flex notranslate">
  <div class="col-auto" style="width: 20%">{{ship.tonnage}}</div>
  <div class="col-auto" style="width: 20%">{{ship.surface}}</div>
  <div class="col-auto" style="width: 20%">{{ship.length}}</div>
  <div class="col-auto" style="width: 20%">{{ship.width}}</div>
  <div class="col-auto" style="width: 20%">{{ship.draught}}</div>
</div>
