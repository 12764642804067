<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Attach to visit</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body" [ngPlural]="numberOfHandlings()">
    <ng-template ngPluralCase="=1">Attaching this handling to another visit will cancel your visit appointment with the terminal.</ng-template>
    <ng-template ngPluralCase="other">Attaching these handlings to another visit will cancel your visit appointment(s) with the terminal.</ng-template>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
    <button (click)="moveHandling()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Attach to visit</button>
  </div>
</div>
