import {AfterViewChecked, AfterViewInit, Component, ElementRef, Input} from '@angular/core';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.css']
})
export class FormGroupComponent implements AfterViewInit, AfterViewChecked {

  @Input() label: String;
  @Input() tooltip: String;
  @Input() disableInvalidFeedback: boolean = false;
  @Input() labelAbove = false;
  @Input() renderOptional = false;

  constructor(public element: ElementRef) {
  }

  ngAfterViewInit(): void {
    const el = this.element.nativeElement.querySelector('textarea, app-date-time, app-radio, app-multiselect, input, select');
    if (el) {
      const label = this.element.nativeElement.querySelector('label');

      if (label) {
        label.classList.remove('required', 'optional');
        if (el.hasAttribute('required')) {
          label.classList.add('required');
        } else if (this.renderOptional) {
          label.classList.add('optional');
        }
      }

      if (!this.disableInvalidFeedback && !this.element.nativeElement.querySelector('.invalid-feedback')){
        const feedbackElement = document.createElement('div');
        el.parentElement.appendChild(feedbackElement);
        feedbackElement.outerHTML = '<div class="invalid-feedback">Please enter ' + (label ? renderLabel(label.innerHTML) : 'a value') + '</div>';
      }
    }

    function renderLabel(name: string): string {
      name = name.toLowerCase();
      switch (name.charAt(0)) {
        case 'a':
        case 'e':
        case 'i':
        case 'o':
        case 'u':
          return 'an ' + name;
      }
      return 'a ' + name;
    }
  }

  ngAfterViewChecked(): void {
    const el = this.element.nativeElement.querySelector('textarea, app-date-time, app-radio, app-multiselect, input, select');
    if (el) {
      const label = this.element.nativeElement.querySelector('label');

      if (label) {
        label.classList.remove('required', 'optional');
        if (el.hasAttribute('required')) {
          label.classList.add('required');
        } else if (this.renderOptional) {
          label.classList.add('optional');
        }
      }
    }
  }
}
