import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements AfterViewInit {
  @Input() wide: boolean;
  @Input() autoShow: boolean;
  @Input() autoOpenDelay: number;
  @Input() placement? : PlacementArray;
  @Input() disabled: boolean;
  @Input() keepOpen: boolean;

  @Input() container: string = "body";
  @Input() cssClass: string;

  @Output() shown = new EventEmitter<{}>();
  @Output() hidden = new EventEmitter<{}>();

  @ViewChild('tooltip', { static: true }) tooltip: NgbTooltip;

  isOpen() {
    return this.tooltip.isOpen();
  }

  ngAfterViewInit(): void {
    if (this.autoShow) {
      setTimeout(() => this.tooltip.open(), this.autoOpenDelay || 0);
    }
  }

}
