import { NgClass } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateRange } from '@portbase/hinterland-service-typescriptmodels/hinterland';
import moment from 'moment';

import { AbstractValueAccessorComponent } from '../../../common/component/value-accessor.component';
import { NlDateParserFormatter } from "../../date-time/nl-date-parser-formatter";

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateRangeComponent), multi: true },
        { provide: NgbDateParserFormatter, useClass: NlDateParserFormatter },
    ],
    standalone: true,
    imports: [
        NgClass,
        NgbInputDatepicker,
        FormsModule,
    ],
})
export class DateRangeComponent extends AbstractValueAccessorComponent<DateRange> {

  @Input() displayFormat = 'MMM DD, YYYY';
  @Input() separator = " - ";
  @Input() id;
  @Input() disabled: boolean;

  picker;
  start: NgbDateStruct;
  end: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  formatted: string;
  numberOfMonths: number = (window.innerWidth > 0 ? window.innerWidth : screen.width) > 500 ? 2 : 1;

  constructor(private calendar: NgbCalendar) {
    super();
  }

  onDateSelection(date: NgbDate, event: Event) {
    if (!this.start && !this.end) {
      this.start = date;
    } else if (this.start && !this.end && !date.before(this.start)) {
      this.end = date;
    } else {
      this.end = null;
      this.start = date;
    }
    if (this.start && this.end) {
      this.picker.toggle();
    }
    this.reformat();
    this.onModelChange();
    event.stopPropagation();
  }

  onDatepickerClose() {
    if (this.start && !this.end) {
      this.end = this.start;
      this.reformat();
      this.onModelChange();
    }
  }

  isHovered(date: NgbDate) {
    return this.start && !this.end && this.hoveredDate && date.after(this.start) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.start) && date.before(this.end);
  }

  isRange(date: NgbDate) {
    return date.equals(this.start) || date.equals(this.end) || this.isInside(date) || this.isHovered(date);
  }

  onInputBlur = (value: string) => {
    const [start, end] = value.split(this.separator);
    this.writeValue({
      start: this.toIsoString(start),
      end: this.toIsoString(end),
    });
    this.onModelChange();
  };

  get value(): DateRange {
    return this.start && this.end ? {
      start: DateRangeComponent.dateToIsoString(this.start),
      end: DateRangeComponent.dateToIsoString(this.end),
    } : null;
  }

  writeValue(value: DateRange): void {
    value = value === undefined ? null : value;
    if (!value) {
      this.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 14);
      this.end = this.calendar.getToday();
      this.reformat();
      this.onModelChange();
      return;
    }
    const [start, end] = [value.start, value.end];
    let m = moment(start);
    this.start = m.isValid() ? { year: m.year(), month: m.month() + 1, day: m.date() } : null;
    m = moment(end);
    this.end = m.isValid() ? { year: m.year(), month: m.month() + 1, day: m.date() } : null;
    this.reformat();
  }

  private reformat = () => {
    const displayFormat = this.displayFormat;
    this.formatted = this.start ? format(this.start) + (this.end ? this.separator + format(this.end) : "") : "";

    function format(date: NgbDateStruct) {
      if (!date) {
        return null;
      }
      const m = moment({ y: date.year, M: date.month - 1, d: date.day });
      if (!m.isValid()) {
        return null;
      }
      return m.format(displayFormat);
    }
  };

  private toIsoString(formattedString: string): string {
    if (formattedString) {
      const m = moment(formattedString, this.displayFormat);
      if (m.isValid()) {
        return m.format("YYYY-MM-DD");
      }
    }
  }

  private static dateToIsoString(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    const m = moment({ y: date.year, M: date.month - 1, d: date.day });
    return m.isValid() ? m.format("YYYY-MM-DD") : null;
  }

}
