<div class="page">
  <div class="w-100 h-100 d-flex justify-content-center align-content-around">
    <div class="login">
      <div class="row mt-4 mb-3">
        <div class="col-auto ps-4">
          <img src="assets/img/port-of-rotterdam-logo.svg" alt="Port of Rotterdam logo"
               class="notranslate port-of-rotterdam-logo">
        </div>
        <div class="col-auto ms-auto pe-4">
          <img src="assets/img/portbase-logo.svg" alt="Port of Rotterdam logo"
               class="notranslate portbase-logo">
        </div>
      </div>

      <div class="card">
        <div class="card-body px-4">
          <div class="row my-3">
            <div class="col">
              <span class="title">inland port dues login</span>
            </div>
            <div class="col-auto d-md-none">
              <app-language-selector></app-language-selector>
            </div>
          </div>
          <app-alert *ngIf="maintenance()" [type]="'warning'" [dismissible]="false">
            <div class="alert-heading">system update</div>
            maintenance message
          </app-alert>
          <app-error [errors]="errors"></app-error>
          <app-form (submit)="signIn()">
            <div class="row">
              <div class="col-12">
                <app-form-field label="Email address">
                  <input [(ngModel)]="email" name="email" placeholder="Enter your email-address" required
                         class="form-control">
                </app-form-field>
              </div>
              <div class="col-12">
                <app-form-field label="Password"
                                tooltip="Your password expires after 140 days for security reasons. Password invalid? Your password might have been expired. Change your password by clicking the 'Reset password' button. Your new password must not be (partially) the same as the previous 5 passwords.">
                  <input [(ngModel)]="password" name="password" placeholder="Enter your password" type="password"
                         required class="form-control">
                </app-form-field>
              </div>
              <div class="col-12 mt-2">
                <button type="submit" class="btn btn-primary">Sign in</button>
              </div>
            </div>
          </app-form>

          <div class="col-12 mt-3">
            <button class="btn btn-tertiary" type="button" routerLink="/binnenhavengeld/forgot-password">Reset password</button>
          </div>
        </div>

        <hr class="my-2">

        <div class="card-body px-4">
          <div class="row">
            <div class="col">
              <span class="title">inland port dues registration</span>
            </div>
            <div class="col-12 mt-3">
              <a href="#" class="btn btn-secondary" routerLink="/binnenhavengeld/request-account">
                request new account
              </a>
            </div>
          </div>
        </div>

        <hr class="my-2">

        <div class="card-body px-4">
          <div class="row mb-1">
            <div class="col-12">
              <button class="btn btn-tertiary" type="button" (click)="getSupport()">Get support</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
