import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-bhg-sanction-regulations-modal',
  templateUrl: './bhg-sanction-regulations-modal.component.html',
  styleUrls: ['./bhg-sanction-regulations-modal.component.css']
})
export class BhgSanctionRegulationsModalComponent {
  @Input() data?: any;

  constructor() {
  }
}
