<div class="mt-3" *ngIf="appContext.isAdmin()">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="support">Support</a></li>
    <li class="breadcrumb-item active">Organisations</li>
  </ol>
  <div class="row">
    <div class="col-md-12">
      <app-search [(ngModel)]="this.entry" [searchFunction]="utils.findOrganisations"
                  placeholder="Find organisation" dataKey="organisationData.fullName" [inputFormatter]="this.searchResultFormatter">
      </app-search>
    </div>
  </div>
  <div class="row" *ngIf="entry">
    <div class="col-md-12">
      <h2>{{entry?.organisationData?.fullName}}</h2>
    </div>
  </div>
  <div class="row mb-4" *ngIf="entry">
    <div class="col-5 col-lg-3 label">Shortname</div>
    <div class="col-4 col-lg-3">{{entry.shortName}}</div>
    <div class="col-5 col-lg-3 label">EAN</div>
    <div class="col-4 col-lg-3">{{entry.organisationData.ean}}</div>
    <div class="col-5 col-lg-3 label">Chamber of commerce number</div>
    <div class="col-4 col-lg-3">{{entry.organisationData.chamberOfCommerceNumber}}</div>
    <div class="col-5 col-lg-3 label" *ngIf="entry.organisationData.authorizedOrganisations">AuthorizedOrganisations</div>
    <div class="col-4 col-lg-3" *ngIf="entry.organisationData.authorizedOrganisations">{{entry.organisationData.authorizedOrganisations}}</div>
    <div class="col-5 col-lg-3 label">Organisation type</div>
    <div class="col-4 col-lg-3"><span *ngIf="isHinterlandTerminal(entry)">Terminal</span> <span *ngIf="isHinterlandDeclarant(entry)">Declarant</span></div>

  </div>
  <div class="row" *ngIf="entry">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem="1" *ngIf="entry && isHinterlandDeclarant(entry)">
        <a ngbNavLink>Organisation preferences</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-md-12">
                <organisation-preferences [shortName]="entry.shortName"></organisation-preferences>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem="2">
          <a ngbNavLink>PCS organisation preferences</a>
          <ng-template ngbNavContent>
            <div class="row" *ngIf="entry">
              <div class="col-12">
                <div class="declaration-block">
                  <h2>Organisation Preferences PCS (not used in MCA!)</h2>
                </div>
                <div class="row">
                  <ng-container *ngFor="let preference of this.entry.preferences; let index = index">
                    <div class="col-6">{{preference.code}}</div>
                    <div class="col-6">{{preference.value}}</div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem="3">
          <a ngbNavLink>PCS organisation roles</a>
          <ng-template ngbNavContent>
            <div class="declaration-block">
              <h2>Organisation roles PCS</h2>
            </div>
            <div class="row">
              <ng-container *ngFor="let role of this.entry.roles; let index = index">
                <div class="col-6" *ngIf="role.indexOf('Hinterland') > -1"><b>{{role}}</b></div>
                <div class="col-6" *ngIf="role.indexOf('Hinterland') == -1">{{role}}</div>
              </ng-container>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
