import {Component, ElementRef, OnInit} from '@angular/core';
import {AppContext} from '../../app-context';
import {HinterlandUtils} from '../../hinterland/hinterland-utils';
import {checkValidity, sendCommand} from '../../common/utils';
import {EditModalService} from "../../components/modals/edit-modal/edit-modal.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-bhg-support',
  templateUrl: './bhg-support.component.html',
  styleUrls: ['../bhg.scss', './bhg-support.component.scss'],
})
export class BhgSupportComponent implements OnInit {
  data = {};

  appContext = AppContext;
  email: string = AppContext.binnenhavengeldUserProfile?.email;
  issue: string;

  errors: string[];

  disableSubmit: Subject<boolean>;

  constructor(
    private readonly editModalService: EditModalService,
    private element: ElementRef) {
    this.disableSubmit = new Subject<boolean>();
  }

  ngOnInit() {
    this.errors = [];
  }

  submit() {
    if (checkValidity(this.element)) {
      AppContext.clearAlerts();
      this.errors = [];
      HinterlandUtils.makeScreenshot("#screen").then((screenshot) => {
        sendCommand('com.portbase.hinterland.api.binnenhavengeld.ReportBinnenhavengeldIssue', {
            '@class': 'io.fluxcapacitor.javaclient.common.Message',
            payload: {
              'email': this.email,
              'issue': this.issue,
              'url': window.location.href
            },
            metadata: {
              'upload': screenshot
            }
          }, () => {
            AppContext.publishSuccessMessage("Your question has been sent");
            this.editModalService.closeModal();
          },
          (error) => {
            this.errors.push(error?.error?.error);
          });
      });
    } else {
      AppContext.publishValidationErrorMessage();
      this.enableSubmit();
    }
  }

  private enableSubmit() {
    this.disableSubmit.next(false);
  }

}
