import {Component, Input, OnInit} from '@angular/core';
import {
  DateRange,
  EmhMessageLogItem,
  Visit
} from '@portbase/hinterland-service-typescriptmodels/hinterland';

import {ComparatorChain} from "../../../common/comparator-chain";
import {sendQuery} from "../../../common/utils";
import {AppContext} from "../../../app-context";
import {HandlingModel} from "../../hinterland-utils";

@Component({
  selector: 'app-message-log-component',
  templateUrl: './message-log.component.html',
  styleUrls: ['./message-log.component.css']
})
export class MessageLogComponent implements OnInit {

  @Input() handling: HandlingModel;
  @Input() visit: Visit;
  @Input() dateRange: DateRange;

  items: EmhMessageLogItem[];
  private logComparator = new ComparatorChain("timestamp").compare;

  ngOnInit(): void {
    if (this.handling != null) {
      this.getHandlingMessagesLog();
    }
    if (this.visit != null) {
      this.getVisitMessagesLog();
    }
  }

  getHandlingMessagesLog(): void {
    sendQuery("com.portbase.hinterland.api.common.query.GetMessageLogForHandling", {
      handlingId: this.handling.handlingId,
      visitId: this.handling.visitId,
      containerNumber: this.handling.handlingData.equipmentNumber
    }, {showSpinner: true, caching: false})
      .subscribe((items: EmhMessageLogItem[]) => {
        this.items = items.sort(this.logComparator);
      });
  }

  getVisitMessagesLog(): void {
    sendQuery("com.portbase.hinterland.api.common.query.GetMessageLogForVisit", {visitId: this.visit.visitId},
      {showSpinner: true, caching: false})
      .subscribe((items: EmhMessageLogItem[]) => {
        this.items = items.sort(this.logComparator);
      });

  }

  cast = (item: EmhMessageLogItem): EmhMessageLogItem => item;

  getTypeLabel(item: EmhMessageLogItem): string {
    switch (item.emhMessage.emhHeader.msgType) {
      case 'mst.hcn.copino.rail.out':
      case 'mst.hcn.copino.out':
      case 'mst.hcn.copino.barge.out':
        return 'Copino Sent';
      case 'mst.hcn.aperak':
        return 'Aperak received';
      case 'mst.codeco':
        return 'Codeco received';
    }
    return null;
  }

  openMessageManagement(processId: string) {
    AppContext.redirectToMessageManagement(processId);
  }

  openMessageManagementCodecoIn(messageId: string) {
    AppContext.redirectToMessageManagementWithMessageId('', messageId);
  }

  openMessageManagementCodecoOut(handlingId: string) {
    AppContext.redirectToMessageManagement(handlingId.split('-').join(''));
  }
}


