import {Component, Input} from '@angular/core';
import {RailCarType} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-edit-rail-car-type',
  templateUrl: './edit-rail-car-type.component.html',
  styleUrls: ['./edit-rail-car-type.component.css']
})
export class EditRailCarTypeComponent {

  @Input() railCarType: RailCarType;

}
