<div class="position-relative">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Arrivals</a>
      <ng-template ngbNavContent>
        <app-arrivals-overview></app-arrivals-overview>
      </ng-template>
    </li>

    <li [ngbNavItem]="2">
      <a ngbNavLink>Shipments</a>
      <ng-template ngbNavContent>
        <app-shipment-overview></app-shipment-overview>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <ul class="nav position-absolute" style="top: 0; right: 1rem">
    <li class="nav-item d-lg-block d-none">
      <a href="/assets/templates/shipments-upload-template-3.1.xlsx"
         class="always-enabled btn btn-secondary border-0 bg-transparent text-secondary py-2"
              style="min-width: 8rem" type="button"><span class="fa fa-file-download me-1"></span> Download template</a>
    </li>
    <li class="nav-item">
      <button (click)="upload.click()" class="btn btn-info border-0 bg-transparent text-info pe-1">
        <span class="pe-1 fa fa-plus-circle"></span> Upload shipments
        <input #upload (change)="excel.uploadShipments($event.target.files[0]); $event.target.value = '';" hidden
               type="file">
      </button>
    </li>
  </ul>
</div>
