import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {NgIf, NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  imports: [
    NgTemplateOutlet,
    NgIf
  ],
  standalone: true
})
export class CollapseComponent implements OnInit, AfterViewInit {

  showing: boolean;
  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();
  @Input() collapse: TemplateRef<any>;
  @Input() showOnLoad: boolean;
  @Input() animateOnLoad: boolean;
  @Input() keepOpen = false;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.showOnLoad || this.keepOpen) {
      this.showing = true;
    }
  }

  ngAfterViewInit(): void {
    const element = <Element>this.elementRef.nativeElement;
    const collapsible = element.querySelector(".collapsible");

    if (!collapsible) {
      console.error("Could not find element with 'collapsible' class", this);
      return;
    }

    this.showOnLoad ? collapsible.classList.remove("collapsed") : collapsible.classList.add("collapsed");

    if (!this.keepOpen) {
      $(collapsible).on('click', e => {
        this.toggle(!this.showing);
      });
    }
    if (this.showOnLoad || this.keepOpen) {
      this.doShow(this.animateOnLoad);
    }
  }

  public toggle = (show: boolean, animate: boolean = true) => {
    if (show !== this.showing) {
      show ? this.doShow(animate) : this.doHide(animate)
    }
  };

  private doShow = (animate: boolean) => {
    const element = <Element>this.elementRef.nativeElement;
    const collapsible = element.querySelector(".collapsible");
    collapsible.classList.remove("collapsed");
    this.showing = true;

    setTimeout(() => {
      const collapse = $(element.querySelector(".collapse"));
      animate ? collapse.removeClass("no-transition") : collapse.addClass("no-transition");
      collapse.collapse('toggle');
    }, 0);
    this.show.emit();
  };

  private doHide = (animate: boolean) => {
    const element = <Element>this.elementRef.nativeElement;
    const collapsible = element.querySelector(".collapsible");
    collapsible.classList.add("collapsed");
    this.showing = true;

    setTimeout(() => {
      const collapse = $(element.querySelector(".collapse"));
      animate ? collapse.removeClass("no-transition") : collapse.addClass("no-transition");
      collapse.on('hidden.bs.collapse', () => this.showing = false);
      collapse.collapse('toggle');
    }, 0);
    this.hide.emit();
  };

}
