import {Component, Input} from '@angular/core';
import {FormFieldComponent} from "../../../../components/user-forms/form-field/form-field.component";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-visit-cancelled-warning-on-detach-component',
  templateUrl: './visit-cancelled-warning-on-detach.component.html',
  styleUrl: './visit-cancelled-warning-on-detach.component.css',
  imports: [
    FormFieldComponent,
    FormsModule
  ],
  standalone: true
})
export class VisitCancelledWarningOnDetachComponent {

  @Input() data;

  callback: (result: boolean) => any;

  keep() {
    this.callback(false);
  }

  detach() {
    this.callback(true);
  }

}
