import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AttachedHandling, DateTimeRange, HandlingData, Visit, Voyage} from '@portbase/hinterland-service-typescriptmodels';
import {filterByTerm, removeItem, uuid} from '../../../common/utils';
import {Observable, of} from 'rxjs';
import {AppContext} from "../../../app-context";
import {HandlingModel, HinterlandUtils} from "../../hinterland-utils";
import {AbstractOverviewComponent} from "../abstract-overview.component";
import {BargeHandlingsUpload} from "../../barge/barge-handlings.upload";
import {RailHandlingsUpload} from "../../rail/rail-handlings.upload";
import {HandlingsUpload} from "../../../common/upload/handlings.upload";
import {FilterValues} from "../../../common/search/dropdown-toggle-filter/dropdown-toggle-filter.component";

@Component({
  selector: 'app-visit-handlings',
  templateUrl: './visit-handlings.component.html',
  styleUrls: ['./visit-handlings.component.css']
})
export class VisitHandlingsComponent extends AbstractOverviewComponent<HandlingModel> implements OnChanges {

  appContext = AppContext;

  @Input() voyage: Voyage;
  @Input() readonly: boolean;

  visit: Visit;
  newHandlings: HandlingModel[] = [];
  statusRequests: HandlingModel[];
  preNotifications: HandlingModel[];
  localStorageKey = undefined;
  uploadedExcelDownloadLink: string;
  handlingFilterValues: FilterValues = null;

  @Input() set setFilterTerm(value: string) {
    if (!!value) {
      this.filterTerm = value;
    }
  }

  @Input() set setVisit(value: Visit) {
    this.visit = value;
  }

  ngOnInit(): void {
    const key: string = this.visit.lastUpload;
    if (key) {
      this.uploadedExcelDownloadLink = "/api/uploads/" + key;
    }
    this.appContext.persistedFilterValuesChanged.subscribe(() => this.renderFilteredItems());
  }

  public filterItems() {
    let filteredItems = this.items;
    if (AppContext.isFiltering("Rail")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'rail');
    } else if (AppContext.isFiltering("Barge")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'barge');
    }
    if (this.filterTerm) {
      this.generateSearchableItems();
      filteredItems = filteredItems.filter(filterByTerm(this.filterTerm, this.excludedFilterFields, this.searchableItems));
    }
    if (filteredItems.length < 10) this.loadAndRenderNextPage();
    return filteredItems;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.visit && this.voyage) {
      this.items = this.visit.handlings.map(h => this.toModel(h));
      const worstVisitHandlingStatus = HinterlandUtils.getWorstVisitHandlingStatus(this.visit);
      this.items.forEach(h => h.worstVisitHandlingStatus = worstVisitHandlingStatus);
      this.loadAndRender();
    }
  }

  addHandling = () => {
    const handling = <AttachedHandling | any>{
      new: true, //used to differentiate new from existing handlings
      handlingId: uuid(),
      handlingData: <HandlingData>{
        dangerousGoods: [],
        shippersOwned: false,
      },
      preNotification: null,
      handlingResult: null,
      completed: false,
      cancelled: false,
      handlingDeclaration: null,
      declarationStatus: null,
      cargoDeclarantShortName: AppContext.userProfile.organisationShortName,
      neverAccepted: null,
      handlingDeclarations: [],
      extra: false
    };
    handling.handlingData.modality = <any>this.voyage.modality;
    this.visit.handlings.splice(0, 0, handling);
    this.setVisit = this.visit;
  };

  deleteHandling = (handling: HandlingModel) => {
    removeItem(this.visit.handlings, handling);
    this.setVisit = this.visit;
  };

  onHandlingFilterChanged(filterValues: FilterValues) {
    this.handlingFilterValues = filterValues;
    this.loadAndRender(false, true);
  }

  getFilteredItems = (): HandlingModel[] => {
    let items: HandlingModel[] = this.filterItems();
    items = items.filter(handing => this.isHandlingFiltered(<HandlingModel>handing, this.mergeHandlingFilters(this.handlingFilterValues, AppContext.persistedFilterValues)));
    return items;
  }

  mergeHandlingFilters = (first: FilterValues, second: FilterValues) => {
    if (!first) return second;
    return {"selected": first.selected.concat(second.selected), "deselected": first.deselected.concat(second.deselected)};
  }

  doRender = (items: HandlingModel[]) => {
    this.newHandlings = items.filter(h => !!h.new);
    this.statusRequests = items.filter(h => !h.new && !h.preNotification);
    this.preNotifications = items.filter(h => !h.new && h.preNotification);
  };

  doLoad(dateTimeRange: DateTimeRange): Observable<(HandlingModel)[]> {
    return of(this.items);
  }

  private toModel(handling: AttachedHandling): HandlingModel {
    const result = <HandlingModel>handling;
    result.visitId = this.visit.visitId;
    result.voyageId = this.voyage.voyageId;
    result.voyageStatus = this.voyage.voyageStatus;
    result.visitStatus = this.visit.plannedVisitData;
    result.modality = this.voyage.modality;
    result.eta = this.visit.visitData.eta;
    result.visitArrived = this.visit.arrived;
    result.visitNeverAcknowledged = this.visit.neverAcknowledged;
    result.visitDeclarationsMade = this.visit.visitDeclarations.length > 0;
    result.terminal = this.visit.terminal;
    result.status = HinterlandUtils.getHandlingStatus(result);
    result.cutOffInMinutes = HinterlandUtils.findTerminalSettings(result).cargoCutOffInMinutes;
    result.visitLoadDischargeListStatus = this.visit['loadDischargeListStatus'];
    result.visitWaitForCloseVisitEnabled = this.visit['waitForCloseVisitEnabled'];
    result.declarant = this.voyage.declarant;
    result.cargoDeclarants = this.voyage.cargoDeclarants;
    return result;
  }

  uploadHandlings(excelFile: File, preNotification: boolean, modality: string) {
    const handlingsUpload: HandlingsUpload = modality === 'barge' ? new BargeHandlingsUpload() : new RailHandlingsUpload();
    handlingsUpload.upload(excelFile, {
      preNotification: preNotification,
      voyageId: this.voyage.voyageId,
      visitId: this.visit.visitId,
    });
  }

}
