<div class="offcanvas-panel">
  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i data-bs-dismiss="modal"
                                                     class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">Add subscription</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Subscription details</h5>

        <app-error [errors]="errors"></app-error>

        <app-form (submit)="addSubscription(newSubscription)" #addForm>
          <div *ngIf="context.isBinnenhavengeldSuperUserOrAdmin(); else notAdmin">
            <app-form-field label="Skipper">
              <input *ngIf="!command.skipper; else orgSelected" [ngModel]="command.skipper?.organisationId"
                     [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="selectOrganisation($event)"
                     required placeholder="Debitor number" class="form-control notranslate">
            </app-form-field>
          </div>
          <ng-template #notAdmin>
            <div *ngIf="context.binnenhavengeldUserProfile.roles.includes('BinnenhavengeldAgent')">
              <app-form-field label="Skipper">
                <app-new-select *ngIf="!command.skipper; else orgSelected" [(ngModel)]="command.skipper"
                            [options]='context.binnenhavengeldUserProfile.organisation?.managedSkippers'
                            [formatter]="utils.binnenhavengeldOrganisationFormatter"
                            required class="notranslate"></app-new-select>
              </app-form-field>
            </div>
          </ng-template>
          <ng-template #orgSelected>
            <div class="card">
              <div class="card-body small py-2">
                <app-bhg-organisation [organisation]="command.skipper"></app-bhg-organisation>

                <div class="mt-3">
                  <button type="button" class="btn btn-tertiary" (click)="resetCommand($event)">
                    <i class="fa fa-light fa-edit"></i> <span class="ms-2">Change selection</span>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
          <div>
            <app-bhg-edit-subscription [subscription]="newSubscription"></app-bhg-edit-subscription>
          </div>
          <div class="my-2 text-end">
            <button type="submit" class="btn btn-secondary" style="min-width: 10rem"
                    [disabled]="!addForm.checkValidity()">
              <i class="fal fa-plus me-2"></i> Add subscription
            </button>
          </div>
        </app-form>
      </div>

      <div #sendForm>
        <div class="card-body border-top" *ngIf="command.subscriptions.length > 0">
          <h5 class="card-title">Selected subscriptions</h5>

          <div class="row">
            <div *ngFor="let subscription of command.subscriptions; let index = index;" class="col-12 my-3">
              <app-bhg-new-subscription [subscription]="subscription" [skipperId]="command.skipper?.organisationId"
                                        [overlappingSubscriptionsQuery]="findOverlappingSubscriptions(subscription)"
                                        (removeSubscription)="removeSubscription(subscription)"></app-bhg-new-subscription>
            </div>
            <div class="col-6">
              <app-form-field label="Payment method">
                <app-new-select [(ngModel)]="command.paymentMethod" [autoSelectFirstOption]="true"
                            [options]="paymentMethods" [formatter]="utils.camelCaseFormatter"
                            required></app-new-select>
              </app-form-field>
            </div>
          </div>
          <div class="row" *ngIf="!context.isBinnenhavengeldSuperUserOrAdmin()">
            <div class="col">
              <div class="custom-control custom-checkbox check-centered">
                <input [(ngModel)]="command.sanctionRegulationsAccepted"
                       id="sanctionRegulationsAccepted" required
                       class="custom-control-input" type="checkbox">
                <label for="sanctionRegulationsAccepted" class="custom-control-label">
                  <a (click)="$event.preventDefault(); this.showSanctionRegulationsModal();">sanction text</a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="footer-container-content">
        <div class="button-bar">
          <div *ngIf="command.subscriptions.length > 0" class="button-bar-text">
            Number of selected subscriptions: <span>{{ command.subscriptions.length }}</span>
          </div>
          <button class="btn btn-primary" type="button"
                  [disabled]="!hasSubscriptionsAndNeededApprovals()" (click)="send(sendForm)">Send
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
