<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Cancel handling</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body">
    Cancelling this handling will also cancel the visit appointment with the terminal.
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Keep handling</button>
    <button (click)="cancelHandling()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Cancel handling</button>
  </div>
</div>
