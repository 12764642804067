<div class="row align-items-center mx-1 mb-2 py-2 bg-light">
  <div class="col-auto" style="width: 4rem"><span class="fa fa-2x" (click)="toggleOrder()" [ngClass]="reverseOrder ? 'fa-caret-up' : 'fa-caret-down'"></span></div>
  <div class="col" style="max-width: 12rem">Timestamp</div>
  <div class="col">Action</div>
  <div class="col-auto" style="width: 16rem">User</div>
  <div class="col-auto" style="width: 16rem">Organisation</div>
</div>

<ng-container *ngFor="let item of reverseOrder ? items.slice().reverse() : items; let index = index">
  <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item : item, index: reverseOrder ? items.length - index - 1 : index }"></ng-container>
</ng-container>

<ng-template #itemTemplate let-logItem="item" let-index="index">
  <div class="text-dark always-enabled" *ngIf="cast(logItem) as item">
    <div class="row align-items-start item mx-1 my-2 py-2 border border-light">
      <div class="col-auto" style="width: 4rem">
        {{index + 1}}.
      </div>
      <div class="col" style="max-width: 12rem">
        <app-date-time [readonly]="true" [ngModel]="item.timestamp"></app-date-time>
      </div>
      <div class="col angular-with-newlines">{{item.description}}</div>
      <div class="col-auto" style="width: 16rem">
        {{item.user}}
      </div>
      <div class="col-auto" style="width: 16rem">
        {{item.organisation}}
      </div>
    </div>
  </div>
</ng-template>
