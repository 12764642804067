<div class="offcanvas-panel" (change)="setModalClosable(false)">

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i (click)="openViewHandlingPanel()" class="overlay-icon fa-regular fa-chevron-left"></i></div>
      <div class="d-flex centered">Edit handling</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="nav-tabs-content nav-tabs pb-5">
      <div class="tab-pane">
        <!-- Handling Details -->
        <div class="nav-content">
          <app-error [errors]="errors"></app-error>

          <div class="inner-section-header">Handling Details</div>

          <app-form-field [renderOptional]="false">
            <label>Terminal</label>
            <app-new-select [(ngModel)]="terminal" [dataKey]="'quayName'" [formatter]="utils.terminalFormatter"
                            (ngModelChange)="onTerminalChanged()" [options]="terminals" [optionLabel]="'displayName'"
                            optionSelectable="shortName" required width="xl" [disabled]="!isDetached">
            </app-new-select>
          </app-form-field>

          <app-form-field *ngIf="isDetached">
            <div class="adjustable-width md hide-md-screen"><label>ETA date</label></div>
            <div class="adjustable-width sm hide-md-screen"><label>ETA time</label></div>
            <div class="adjustable-width sm show-sm-screen"><label>ETA</label></div>
            <app-date-time [(ngModel)]="handling['eta']" customDateClass="md" customTimeClass="sm" required></app-date-time>
          </app-form-field>

          <app-form-field>
            <label>Handling type</label>
            <app-toggle [options]="['loading', 'discharge']" [(ngModel)]="handling.handlingData.type"
              [formatter]="utils.handlingTypeFormatter" (ngModelChange)="onHandlingTypeChanged();" required>
            </app-toggle>
          </app-form-field>

          <app-form-field>
            <label>Container status</label>
            <app-toggle [options]="[true, false]" [(ngModel)]="handling.handlingData.full"
              [formatter]="utils.containerStatusFormatter" (ngModelChange)="setTerminalSettings();" required>
            </app-toggle>
          </app-form-field>

          <app-form-field>
            <label>Shipper's owned</label>
            <app-toggle [options]="[false, true]" [(ngModel)]="handling.handlingData.shippersOwned"
                        (ngModelChange)="validateEquipmentNumber();" [formatter]="utils.yesNoFormatter" required>
            </app-toggle>
          </app-form-field>

          <app-form-field>
            <label [ngClass]="{'required' : equipmentNumberRequired()}">Container number</label>
            <div class="d-flex flex-row flex-wrap">
              <input (blur)="validateEquipmentNumber()" (change)="handling.handlingData.equipmentNumber = $any($event.target).value.toUpperCase()"
                     (paste)="onContainerNumberPaste($event)" [maxLength]="11"
                     [(ngModel)]="handling.handlingData.equipmentNumber" (ngModelChange)="onEquipmentNumberChanged()"
                     [ngClass]="{'validation': invalidEquipmentNumberLength || invalidEquipmentNumberCheckDigit || duplicate}"
                     [required]="equipmentNumberRequired()"
                     class="form-control lg"
                     placeholder="e.g. MHEU5489231" type="text">
              <button *ngIf="duplicate" (click)="openDuplicateHandling()" type="button" class="btn btn-secondary ms-3">Open existing container</button>
            </div>
            <div *ngIf="duplicate" class="validation-error">
              This container was recently added within your organisation. Edit the existing container to prevent double costs.
            </div>
            <div *ngIf="invalidEquipmentNumberLength else checkDigit" class="validation-error">
              Container number does not follow format of 4 letters and 7 numbers
            </div>
            <ng-template #checkDigit>
              <div *ngIf="invalidEquipmentNumberCheckDigit" class="validation-error">
                Container number contains an incorrect check digit
              </div>
            </ng-template>
          </app-form-field>

          <app-form-field>
            <label>Shipping company</label>
            <app-search [(ngModel)]="handling.handlingData.shippingCompany"
                        [resultFormatter]="utils.shippingCompanyFormatter" [searchFunction]="utils.findShippingCompanies" customClass="xl"
                        dataKey="name" placeholder="e.g. Maersk Shipping Line" required>
            </app-search>
          </app-form-field>

          <app-form-field *ngIf="terminalSettings?.sealNumberAllowed || handling.handlingData.sealNumber">
            <label [ngClass]="{'required' : terminalSettings?.sealNumberAllowed}">Seal number</label>
            <input [(ngModel)]="handling.handlingData.sealNumber" [maxlength]="(terminal?.maxSealNumberLength) ? terminal.maxSealNumberLength : 15"
                   [required]="terminalSettings?.sealNumberAllowed"
                   class="form-control sm"
                   placeholder="e.g. BE357289"
                   type="text">
          </app-form-field>

          <app-form-field tooltip="Code needed to pick-up or drop-off a container. 'Pin code' is used for full pick-up containers, in other cases 'booking number' or 'pick-up/drop-off reference' are often used.">
            <label>Acceptance reference</label>
            <input (change)="handling.handlingData.bookingNumber = utils.bookingNumberFormatter($any($event.target).value)"
                   [(ngModel)]="handling.handlingData.bookingNumber"
                   [maxLength]="40" class="form-control sm"
                   placeholder="e.g. A67888" required type="text">
          </app-form-field>

          <app-form-field tooltip="Depending on handling type and shipping company, you get access to ETA, ETD and cargo closing by selecting a deep sea vessel.">
            <label>Deep sea vessel</label>
            <app-search [(ngModel)]="handling.handlingData.deepSea" [resultFormatter]="utils.seaVesselFormatter"
                        [searchFunction]="utils.findTerminalVisitReferences(terminal)"
                        customClass="xl" dataKey="shipName" placeholder="Vessel name or IMO code">
            </app-search>
          </app-form-field>

          <app-form-field tooltip="You can use ‘Own reference’ to enter an order or reference number that
                  is used within your own company. If filled, you can use your own references to search within this application.">
            <label>Own reference</label>
            <input [(ngModel)]="handling.handlingData.transportNumber"
                   [maxLength]="40" class="form-control lg"
                   placeholder="e.g. order-3462"
                   type="text">
          </app-form-field>

          <hr class="section-break-line">

          <!-- Equipment Details  -->
          <div class="inner-section-header">Equipment details</div>

          <app-form-field>
            <label>Size type</label>
            <app-search [(ngModel)]="handling.handlingData.sizeType"
                        [resultFormatter]="utils.sizeTypeFormatter" [searchFunction]="utils.findSizeTypes"
                        customClass="lg" dataKey="name" placeholder="e.g. 45R1 (40ft high cube reefer)" required>
            </app-search>
          </app-form-field>

          <app-form-field *ngIf="terminalSettings?.reeferAllowed || hasReefer">
            <label>Reefer</label>
            <app-toggle [options]="[false, true]" [formatter]="utils.yesNoFormatter"
                        [(ngModel)]="hasReefer" (ngModelChange)="hasReeferChanged()" required>
            </app-toggle>
          </app-form-field>

          <div *ngIf="hasReefer" class="canvas-extra-content">
            <app-form-field>
              <label>Temperature</label>
              <app-toggle [options]="[true, false]" (ngModelChange)="temperatureFixedChanged()" [(ngModel)]="handling.handlingData.reefer.temperatureFixed"
                          [formatter]="utils.temperatureChoiceFormatter" required>
              </app-toggle>
            </app-form-field>

            <app-form-field *ngIf="handling.handlingData.reefer.temperatureFixed else temperatureRange" customClass="mb-0">
              <label>Temperature (&deg;C)</label>
              <input [(ngModel)]="handling.handlingData.reefer.temperature" [maxLength]="10"
                     class="form-control lg"
                     placeholder="between -70 and 40" type="number">
            </app-form-field>

            <ng-template #temperatureRange>
              <app-form-field>
                <label>Minimum temperature (&deg;C)</label>
                <input [(ngModel)]="handling.handlingData.reefer.minimumTemperature" [maxLength]="10"
                       class="form-control lg"
                       placeholder="between -70 and 40" type="number">
              </app-form-field>

              <app-form-field customClass="mb-0">
                <label>Maximum temperature (&deg;C)</label>
                <input [(ngModel)]="handling.handlingData.reefer.maximumTemperature" [maxLength]="10"
                       class="form-control lg"
                       placeholder="between -70 and 40" type="number">
              </app-form-field>
            </ng-template>
          </div>

          <app-form-field *ngIf="terminalSettings?.outOfGaugeAllowed"
                          tooltip="Out of Gauge (OOG) cargo is too large for a standard shipping container.
                          The cargo is therefore sticking out from at least one side.
                          Please fill in how much it’s sticking out from each side so the terminal can take this into account for their block planning.">
            <label>Out of gauge</label>
            <app-toggle [options]="[false, true]" [formatter]="utils.yesNoFormatter"
                        [(ngModel)]="hasOutOfGauge" (ngModelChange)="hasOutOfGaugeChanged()">
            </app-toggle>
          </app-form-field>

          <div *ngIf="hasOutOfGauge" class="canvas-extra-content">
            <app-form-field>
              <label>Front (door side) (cm)</label>
              <input [(ngModel)]="handling.handlingData.outOfGauge.front" [maxLength]="10"
                     class="form-control sm"
                     placeholder="Sticking out in cm" type="number">
            </app-form-field>

            <app-form-field>
              <label>Back (cm)</label>
              <input [(ngModel)]="handling.handlingData.outOfGauge.rear" [maxLength]="10"
                     class="form-control sm"
                     placeholder="Sticking out in cm" type="number">
            </app-form-field>

            <app-form-field>
              <label>Top (cm)</label>
              <input [(ngModel)]="handling.handlingData.outOfGauge.height" [maxLength]="10"
                     class="form-control sm"
                     placeholder="Sticking out in cm" type="number">
            </app-form-field>

            <app-form-field>
              <label>Left (cm)</label>
              <input [(ngModel)]="handling.handlingData.outOfGauge.left" [maxLength]="10"
                     class="form-control sm"
                     placeholder="Sticking out in cm" type="number">
            </app-form-field>

            <app-form-field customClass="mb-0">
              <label>Right (cm)</label>
              <input [(ngModel)]="handling.handlingData.outOfGauge.right" [maxLength]="10"
                     class="form-control sm"
                     placeholder="Sticking out in cm" type="number">
            </app-form-field>
          </div>

          <app-form-field>
            <label>Cargo weight (kg)</label>
            <input [(ngModel)]="handling.handlingData.weight" appDecimalNumber maxDecimals="0" max="99999" class="form-control md"
                   placeholder="Excl. weight of container" [required]="terminalSettings?.grossWeightAllowed">
          </app-form-field>

          <app-form-field customClass="mb-0">
            <label>Tare weight (kg)</label>
            <input [(ngModel)]="handling.handlingData.tareWeight" appDecimalNumber maxDecimals="0" max="99999" class="form-control md"
                   placeholder="Weight of container">
          </app-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="tab-pane">
        <div class="button-bar">
          <div *ngIf="validationErrors" class="button-bar-text validation-errors">
            Please correct the errors above and try again
          </div>
          <button (click)="openViewHandlingPanel()" class="btn btn-secondary" type="button">Cancel</button>
          <button (click)="updateStatusRequest()" *ngIf="isDetached" class="btn btn-primary" type="button">Update status request</button>
          <button (click)="updatePreNotification()" *ngIf="!isDetached" class="btn btn-primary" type="button">Update pre-notification</button>
        </div>
      </div>
    </div>
  </div>
</div>
