<div class="row bg-light">
  <div class="col-auto" style="width: 4rem">#</div>
  <div class="col-2" style="max-width: 12rem">Timestamp</div>
  <div class="col-2">Description</div>
  <div class="col-lg-1">Function</div>
  <div class="col"></div>
</div>

<ng-container *ngFor="let item of items; let index = index">
  <ng-container [ngTemplateOutletContext]="{ item : item, index: index }"
                [ngTemplateOutlet]="itemTemplate"></ng-container>
</ng-container>

<ng-template #itemTemplate let-index="index" let-logItem="item">

  <div *ngIf="cast(logItem) as item" class="row item pb-2 pt-2">
    <div class="col-auto" style="width: 4rem">
      {{index + 1}}.
    </div>
    <div class="col-2" style="max-width: 12rem">
      <app-date-time [ngModel]="item.timestamp" [readonly]="true"></app-date-time>
    </div>
    <div class="col-2">
      {{getTypeLabel(item)}}
    </div>
    <div class="col-lg-1">
      {{item.emhMessage.emhHeader?.msgFunction}}
    </div>
    <div class="col">
      <button (click)="openMessageManagement(item.processId)" *ngIf="item.type.search('Codeco') === -1" class="btn btn-dark mx-2"
              title="View messages in PCS Message Management" type="button">
        <span class="fa fa-exchange-alt">&nbsp;PCS</span>
      </button>
      <button (click)="openMessageManagementCodecoOut(item.handlingId)" *ngIf="item.type.search('SendCodeco') > 0" class="btn btn-dark mx-2"
              title="View messages in PCS Message Management" type="button">
        <span class="fa fa-exchange-alt">&nbsp;PCS</span>
      </button>
      <button (click)="openMessageManagementCodecoIn(item.messageId)" *ngIf="item.type.search('ProcessCodeco') > 0" class="btn btn-dark mx-2"
              title="View messages in PCS Message Management" type="button">
        <span class="fa fa-exchange-alt">&nbsp;PCS</span>
      </button>
    </div>
  </div>

</ng-template>
