import {Component} from '@angular/core';
import {AppContext} from '../app-context';

@Component({
  selector: 'app-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent {
  appContext = AppContext;
}
