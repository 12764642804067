import {Component, Input} from '@angular/core';
import {EventGateway, EventType} from "../../../../common/event-gateway";
import {InjectorProvider} from "../../../../common/injector-provider";
import {VoyageVisitAndHandling} from "../../../../hinterland/types";

@Component({
  selector: 'app-move-handling-confirmation',
  templateUrl: './move-handling-confirmation.component.html',
  styleUrls: ['./move-handling-confirmation.component.scss']
})
export class MoveHandlingConfirmationComponent {

  @Input() data: VoyageVisitAndHandling | Array<VoyageVisitAndHandling>;

  eventGateway: EventGateway;

  constructor() {
    this.eventGateway = InjectorProvider.injector.get(EventGateway);
  }

  numberOfHandlings() {
    return (this.data instanceof Array) ? this.data.length : 1;
  }

  moveHandling() {
    this.eventGateway.publish(EventType.StartAttachingToVisit, this.data);
  }
}
