<div class="btn-group">
  <button class="btn btn-outline-light dropdown-toggle" type="button" data-bs-toggle="dropdown" tabindex="-1" >
    {{appContext.userProfile.contact}}
  </button>
  <div class="dropdown-menu dropdown-menu-end z-top">
    <div *ngIf="appContext.local" class="mx-2">
      <app-select (ngModelChange)="onUserChanged($event)"
                  [options]="mockProfiles" [(ngModel)]="appContext.userProfile" dataKey="contact"
                  (click)="$event.stopPropagation()"></app-select>
    </div>
    <div class="dropdown-divider"></div>
    <a [href]="apiDocUrl()" target="_blank" class="dropdown-item" tabindex="-1">Developer documentation</a>
    <ng-container *ngIf="appContext.isAdmin()">
      <div class="dropdown-divider"></div>
      <a (click)="openSupport()" class="dropdown-item" href="#" tabindex="-1">Support</a>
    </ng-container>
    <div class="dropdown-divider"></div>
    <a href="#" (click)="util.downloadScacs()" class="dropdown-item" tabindex="-1">Shipping companies</a>
    <a href="#" *ngIf="appContext.isHinterlandDeclarant()" (click)="openTransactions()" class="dropdown-item" tabindex="-1">Billable transactions</a>
    <div class="dropdown-divider"></div>
    <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#logOutModal">Log out</a>
  </div>
</div>
