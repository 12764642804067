<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Detach handling{{data.multipleS}}</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body">
    By detaching all handlings on this visit, you will also cancel the visit appointment with the terminal.
  </div>
  <div class="modal-footer">
    <button (click)="keep()" data-bs-dismiss="modal" class="btn btn-secondary" type="button">Cancel</button>
    <button (click)="detach()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Detach handling{{data.multipleS}}</button>
  </div>
</div>
