import {
  ArrayTemplate, Cell, DateTimeField, HardCodedField, MappedField, NonNegativeQuantityField, parseExcel, QuantityField, RequiredConditionallyField,
  RequiredField, RequiredIfField, toBase64,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {HandlingsUpload, UploadData} from "../../common/upload/handlings.upload";
import {sendCommand, sendQuery, uuid} from "../../common/utils";
import {
  DeclareDetachedHandling,
  DeclareHandling,
  DeclareHandlings,
  GetRailInterTerminal,
  OutOfGauge,
  Reefer
} from "@portbase/hinterland-service-typescriptmodels";
import {AppContext} from "../../app-context";
import {map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

export interface RailUploadData extends UploadData {
  voyageId: string;
  visitId: string;
  preNotification: boolean
}

export class RailHandlingsUpload extends HandlingsUpload {
  getVersion(): string {
    return "Rail v2.5";
  }

  getRowCount(): number {
    return 200;
  }

  getTemplate(forDetached:boolean = false): WorkBookTemplate {
    return {
      sheets: [
        {
          name: "Guide",
          template: {
            version: new ValidatedField(new RequiredField('C1'), value => {
              if (value !== this.getVersion()) {
                throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
              }
            })
          }
        },
        {
          name: "Pickup",
          template: {
            handlings: new ArrayTemplate({
              handlingId: new HardCodedField(() => uuid()),
              handlingData: {
                modality: new HardCodedField("rail"),
                type: new HardCodedField("loading"),
                shippingCompany: new MappedField(new RequiredField("B$"), this.shippingCompanyMapper),
                full: new MappedField(new RequiredField("C$"), this.fullEmptyMapper),
                bookingNumber: new MappedField("D$", this.toUpperCaseMapper),
                equipmentNumber: new RequiredIfField(new MappedField("E$", this.toUpperCaseMapper), [new MappedField("C$", this.fullEmptyMapper)], value => !!value),
                shippersOwned: new MappedField("E$", this.shippersOwnedMapper),
                sizeType: new MappedField(new RequiredField("F$"), this.sizeTypeMapper),
                transportNumber: "G$",
                finalDestination: new MappedField("H$", this.locationMapper),
                interTerminalTransportLocation: new MappedField("I$", this.railInterTerminalMapper),
                railServiceCenterCode: "J$",
                inlandTerminal: new MappedField("K$", this.locationMapper),
                remarks: "L$",
                dangerousGoods: [
                  {
                    unCode: "M$",
                    hazardClass: "N$",
                    gevi: "O$"
                  }
                ]
              },
              terminal: new MappedField(new RequiredConditionallyField("P$", forDetached), this.terminalByBicsOrSmdgMapper),
              eta: new MappedField(new DateTimeField("Q$", HandlingsUpload.dateTimeFormat), this.dateTimeToIsoStringMapper)
            }, [4, this.getRowCount()])
          }
        },
        {
          name: "Delivery",
          template: {
            handlings: new ArrayTemplate({
              handlingId: new HardCodedField(() => uuid()),
              handlingData: {
                modality: new HardCodedField("rail"),
                type: new HardCodedField("discharge"),
                shippingCompany: new MappedField(new RequiredField("B$"), this.shippingCompanyMapper),
                full: new MappedField(new RequiredField("C$"), this.fullEmptyMapper),
                bookingNumber: new MappedField("D$", this.toUpperCaseMapper),
                equipmentNumber: new MappedField("E$", this.toUpperCaseMapper),
                shippersOwned: new MappedField("E$", this.shippersOwnedMapper),
                sizeType: new MappedField(new RequiredField("F$"), this.sizeTypeMapper),
                transportNumber: "G$",
                finalDestination: new MappedField("H$", this.locationMapper),
                interTerminalTransportLocation: new MappedField("I$", this.railInterTerminalMapper),
                railServiceCenterCode: "J$",
                weight: new NonNegativeQuantityField("K$"),
                tareWeight: new NonNegativeQuantityField("L$"),
                sealNumber: new MappedField("M$", this.emptyStringToNullMapper),
                outOfGauge: <OutOfGauge>{
                  left: <any>new NonNegativeQuantityField("N$"),
                  front: <any>new NonNegativeQuantityField("O$"),
                  height: <any>new NonNegativeQuantityField("P$"),
                  right: <any>new NonNegativeQuantityField("Q$"),
                  rear: <any>new NonNegativeQuantityField("R$")
                },
                reefer: <Reefer>{
                  temperature: <any>new QuantityField("S$"),
                  temperatureFixed: <any>new MappedField("S$", value => !!value),
                  minimumTemperature: <any>new QuantityField(new RequiredIfField("T$", ["U$"])),
                  maximumTemperature: <any>new QuantityField(new RequiredIfField("U$", ["T$"]))
                },
                remarks: "V$",
                dangerousGoods: [
                  {
                    unCode: "W$",
                    hazardClass: "X$",
                    gevi: "Y$"
                  }
                ]
              },
              terminal: new MappedField(new RequiredConditionallyField("Z$", forDetached), this.terminalByBicsOrSmdgMapper),
              eta: new MappedField(new DateTimeField("AA$", HandlingsUpload.dateTimeFormat), this.dateTimeToIsoStringMapper)
            }, [4, this.getRowCount()])
          }
        }
      ]
    }
  }

  upload(excelFile:File, data:RailUploadData) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
      parseExcel(excelFile, this.getTemplate()).subscribe(model => {
        if (model.handlings.length === 0) {
          AppContext.registerError('No handlings found in uploaded excel');
          return;
        }

        model.handlings.forEach((h: DeclareHandling) => {
          h.voyageId = data.voyageId;
          h.visitId = data.visitId;
          h.preNotification = data.preNotification;
        });

        model.handlings.forEach((handling) => {
          handling.cargoDeclarantShortName = AppContext.userProfile.organisationShortName;
          delete handling.terminal;
          delete handling.eta;
        });

        const command = {
          '@class': 'io.fluxcapacitor.javaclient.common.Message',
          payload:<DeclareHandlings> {
            voyageId: data.voyageId,
            visitId: data.visitId,
            handlings: model.handlings
          },
          metadata: {
            'upload': excelBase64
          }
        }

        sendCommand('com.portbase.hinterland.api.common.command.DeclareHandlings', command, () => {
          AppContext.registerSuccess('Handlings were declared successfully');
        }, (error) => {
          let errorMessage = 'Error(s) occurred during declaring of handlings: ';
          if (error instanceof HttpErrorResponse) {
            let httpErrors = AppContext.getHttpErrors(error);
            httpErrors.forEach(e => errorMessage += `${e}. `)
            AppContext.registerError(errorMessage);
          } else {
            AppContext.registerError(errorMessage + error);
          }
        });
      });
    });
  }

  uploadDetached(excelFile: File, data: UploadData) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
      parseExcel(excelFile, this.getTemplate(true)).subscribe(model => {
        if (model.handlings.length === 0) {
          AppContext.registerError('No handlings found in uploaded excel');
          return;
        }

        model.handlings.forEach((handling:DeclareDetachedHandling) => {
          handling.declarant = AppContext.userProfile.organisation
        });

        const command = {
          '@class': 'io.fluxcapacitor.javaclient.common.Message',
          payload:<DeclareHandlings> {
            handlings: model.handlings
          },
          metadata: {
            'upload': excelBase64
          }
        }

        sendCommand('com.portbase.hinterland.api.detached.command.DeclareDetachedHandlings', command, () => {
          AppContext.registerSuccess('Detached handlings were declared successfully');
        }, (error) => {
          let errorMessage = 'Error(s) occurred during declaring of detached handlings: ';
          if (error instanceof HttpErrorResponse) {
            const httpErrors:Array<string> = AppContext.getHttpErrors(error);
            httpErrors.forEach(e => errorMessage += `${e}. `)
            AppContext.registerError(errorMessage);
          } else {
            AppContext.registerError(errorMessage + error);
          }
        });
      });
    });
  }

  railInterTerminalMapper(code:string, cell:Cell) {
    if (!code) {return null;}
    return sendQuery('com.portbase.hinterland.api.common.query.GetRailInterTerminal', <GetRailInterTerminal>{interTerminalCode:code})
      .pipe(map(terminal => {
      if (!terminal) {
        throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown inter-terminal location code: ${code}`
      }
      return terminal;
    }));
  }
}
