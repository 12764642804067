<div class="mx-auto mb-5 mt-4">

  <!-- List -->
  <div *ngIf="template == undefined" class="mt-5">
    <div *ngIf="!railVoyageTemplates || railVoyageTemplates.length === 0">Loading rail rotation templates..</div>
    <div *ngIf="railVoyageTemplates && railVoyageTemplates.length !== 0" class="row bg-light py-2 mb-2">
      <div class="col-auto" style="min-width: 2rem">
        <div class="custom-check custom-check-inline">
          <input [ngModel]="allItemsSelected()"
                 (ngModelChange)="$event ? selectAll() : deselectAll()"
                 class="custom-check-input" type="checkbox">
        </div>
      </div>
      <div class="col-8 col-sm-10 col-md-11">
        <div style="margin-left: 2px; margin-right: 2px">
          <div class="header row narrow-gutters mx-0">
            <div class="col" style="width: 40px; min-width: 40px; max-width: 40px"></div>
            <div class="col">Train - Shuttle</div>
            <div class="col bg-light">First Departure Day</div>
            <div class="col d-none d-lg-block">Template ID</div>
            <div class="col d-none d-xl-block"></div>
            <div class="col-auto d-none d-sm-block" style="min-width: 2.5rem"></div>
          </div>
        </div>
      </div>
    </div>
    <app-infinite-scroller [initialCount]="50">
      <ng-container *ngFor="let railVoyageTemplate of railVoyageTemplates; let index = index">
        <div class="row">
          <div class="col-auto" style="min-width: 2rem">
            <div class="custom-check custom-check-inline mt-4">
              <input [ngModel]="isSelected(railVoyageTemplate)"
                     (ngModelChange)="$event ? selectItem(railVoyageTemplate) : deselectItem(railVoyageTemplate)"
                     class="custom-check-input" type="checkbox">
            </div>
          </div>
          <div class="col-8 col-sm-10 col-md-11">
            <app-voyage-summary [voyage]="railVoyageTemplate" [summaryContext]="'detail'" [hasRailTemplate]="true" (click)="template=railVoyageTemplate"></app-voyage-summary>
          </div>
        </div>
      </ng-container>
    </app-infinite-scroller>
  </div>

  <div *ngIf="template" [ngClass]="this.showEntry?'':'d-none'">
    <app-voyage-details [setVoyageTemplate]="template" (initialized)="delayedShowEntry()"></app-voyage-details>
    <div class="row mt-5">
      <div class="col-md-auto mb-3">
        <button (click)="deleteRailVoyageTemplate()" class="btn btn-danger btn-block" tabindex="-1">
          <span>Delete template</span></button>
      </div>
      <div class="col-md-auto mb-3 ms-auto">
        <button (click)="cancel()" class="btn btn-outline-secondary btn-block" tabindex="-1">
          <span>Cancel</span></button>
      </div>
      <div class="col-md-auto mb-3">
        <button (click)="saveRailVoyageTemplate()" class="btn btn-info btn-block" tabindex="-1">
          <span>Save template</span></button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!template">
    <div class="row mt-5">
      <div class="col-auto ms-auto" *ngIf="selectedTemplates.length > 0">
        <button class="btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#dateRangeModal" (click)="calculateAmountOfVoyages()">
          <ng-container *ngIf="!searchResult">Generate rotations</ng-container>
        </button>
      </div>
      <div class="col-auto" [ngClass]="selectedTemplates.length == 0?'ms-auto':''">
        <button class="btn btn-info" type="button" (click)="newEntry()">
          <ng-container *ngIf="!searchResult">New template</ng-container>
        </button>
      </div>
    </div>
  </ng-container>
</div>


<div id="dateRangeModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Generate rotations</h5>
      </div>
      <div class="modal-body">
        <p>
          Please select the date range for which you want to roll out the selected template{{this.selectedTemplates.length>1?'s':''}}.
        </p>
        <app-date-range [ngModel]="dateRange" (ngModelChange)="onDateSelection($event)"></app-date-range>
      </div>
      <div class="modal-footer">
        <div class="container-fluid px-0">
          <div class="row g-0 justify-content-between">
            <div class="col-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="width: 8rem">Cancel</button>
            </div>
            <div class="col-auto" *ngIf="!dateRange">
              Please select a start date and an end date.
            </div>
            <div class="col-auto" *ngIf="dateRange && amountOfVoyagesToBeCreated == 0">
              Select a wider range to create any voyages.
            </div>
            <div class="col-auto" *ngIf="dateRange && amountOfVoyagesToBeCreated > 0">
              <button type="button" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem"
                      (click)="generateVoyages()">Create {{amountOfVoyagesToBeCreated}} rotations
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
