<div class="mx-auto mb-5 mt-4">

  <div class="mt-3">
    <div class="row">
      <div class="col">
        <app-search [(ngModel)]="searchResult" [searchFunction]="search" [inputFormatter]="searchResultFormatter"
                    (ngModelChange)="onEntryChange()" placeholder="Find a bollard"></app-search>
      </div>
      <div class="col-auto">
        <button class="btn btn-info" type="button" (click)="newEntry()">
          <ng-container *ngIf="searchResult">Duplicate bollard</ng-container>
          <ng-container *ngIf="!searchResult">Add new bollard</ng-container>
        </button>
      </div>
    </div>
  </div>


  <div class="mt-5" *ngIf="entry == undefined">
    <div class="row align-items-center mx-1 mb-2 py-2 bg-light">
      <div class="col-2">Bollard</div>
      <div class="col-2">Latitude</div>
      <div class="col-2">Longitude</div>
      <div class="col">Meters to next bollard</div>
      <div class="col">Mooring side</div>
      <div class="col-2">Quay</div>
    </div>
    <div *ngIf="bollards">
      <app-infinite-scroller [initialCount]="50">
        <ng-container *ngFor="let item of bollards; let index = index">
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item : item, index: index }"></ng-container>
        </ng-container>
      </app-infinite-scroller>


      <span class="mb-2">Total number of bollards: {{bollards?.length}}</span>
      <ng-template #itemTemplate let-bollardItem="item" let-index=index>
        <div class="row align-items-center item mx-1 my-2 py-2 border border-light" *ngIf="cast(bollardItem) as item" (click)="onEntrySelected(item)">
          <div class="col-2">
            <span>{{item.number}}</span>
          </div>
          <div class="col-2">
            {{item.coordinates.latitude}}
          </div>
          <div class="col-2">
            {{item.coordinates.longitude}}
          </div>
          <div class="col">
            {{item.metersToNextBollard}}
          </div>
          <div class="col">
            {{item.mooringSide}}
          </div>
          <div class="col-2">
            <span title="{{item.bicsCode}}">{{quayName(item.bicsCode)}}</span>
          </div>
        </div>
      </ng-template>
      <div></div>
    </div>
  </div>

  <div *ngIf="!!entry" class="mt-5">
    <div class="declaration-block">
      <h2>Bollard details</h2>
    </div>
    <div class="row">
      <div class="col-md-6">
        <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="entry.id" disabled required></app-form-group>
        <app-form-group><label>Bollard number
          <app-tooltip [wide]="true" placement="bottom bottom-left">
            <div class="tooltipContent text-start">The bollard number should consist of a prefix (e.g. terminal name) and the number on the physical bollard</div>
            <span class="ms-1 fa fa-question-circle"></span></app-tooltip>
        </label><input class="form-control" placeholder="e.g. TERM-123" [(ngModel)]="entry.number" required></app-form-group>
      </div>
      <div class="col-md-6">
        <app-form-group>
          <label for="quayName">Quay</label>
          <app-select [(ngModel)]="selectedQuay" [disabled]="quays().length == 1"
                      [options]="quays()" required dataKey="bicsCode" optionLabel="quayName" id="quayName"
                      optionSelectable="shortName"></app-select>
        </app-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <app-form-group><label>Latitude</label><input class="form-control" placeholder="e.g. 51,958095" [(ngModel)]="entry.coordinates.latitude" appDecimalNumber [maxDecimals]="6" required>
        </app-form-group>
        <app-form-group>
          <label>Longitude</label><input class="form-control" placeholder="e.g. 4,047405" [(ngModel)]="entry.coordinates.longitude" appDecimalNumber [maxDecimals]="6" required>
        </app-form-group>
      </div>
      <div class="col-md-6">
        <app-form-group>
          <label>Meters to next bollard</label><input class="form-control" placeholder="e.g. 8,11" [(ngModel)]="entry.metersToNextBollard" appDecimalNumber [maxDecimals]="2" required>
        </app-form-group>
        <app-form-group>
          <label>Mooring side</label>
          <app-select id="mooringSide" [(ngModel)]="entry.mooringSide" [options]="mooringSides()" [formatter]="toTitleCase">
          </app-select>
        </app-form-group>
      </div>
    </div>

    <div class="my-2 row" *ngIf="entry">
      <div class="col-auto me-auto">
        <button class="btn btn-danger px-4" type="button" *ngIf="searchResult" (click)="deleteEntry()">
          Delete bollard
        </button>
      </div>
      <div class="col-auto">
        <button class="btn btn-outline-info px-4" type="button" (click)="cancel()">
          Cancel
        </button>
      </div>
      <div class="col-auto" *ngIf="!searchResult">
        <button class="btn btn-info px-4" type="button" (click)="addEntry()">
          Add bollard
        </button>
      </div>
      <div class="col-auto" *ngIf="searchResult">
        <button class="btn btn-info px-4" type="button" (click)="updateEntry()">
          Save bollard
        </button>
      </div>
    </div>

  </div>

</div>
