<div *ngIf="appContext.isAdmin()" class="mx-auto mb-5 mt-4" style="max-width: 1150px">
  <div class="card">
    <div class="card-header bg-secondary text-light">
      <span class="fas fa-sliders-h px-4"></span>Preferences
    </div>
    <div class="card-body">
      <p class="card-text">Viewing or setting preferences for MCA</p>
      <span class="p-1"><a class="btn btn-outline-secondary btn-rounded" href="/support/organisation-preferences" style="min-width: 10rem;">Organisations</a></span>
      <span class="p-1"><a class="btn btn-outline-secondary btn-rounded" href="/support/terminals" style="min-width: 10rem;">Terminals</a></span>
    </div>
  </div>
  <div class="card">
    <div class="card-header bg-secondary text-light">
      <span class="fas fa-envelope double px-4"></span>Messaging
    </div>
    <div class="card-body">
      <p class="card-text">Insight into EDIFACT messaging sent and received in MCA via EMH.</p>
      <span class="p-1"><a class="btn btn-outline-secondary btn-rounded" href="/message-overview">Pending declarations</a></span>
    </div>
  </div>
  <div class="card">
    <div class="card-header bg-secondary text-light">
      <span class="fas fa-envelope double px-4"></span>Troubleshooting
    </div>
    <div class="card-body">
      <p class="card-text">Logs</p>
      <span class="p-1"><a class="btn btn-outline-secondary btn-rounded" [href]="kibanaUrl">Kibana</a></span>
    </div>
  </div>
  <div class="card">
    <div class="card-header bg-secondary text-light">
      <span class="fas fa-book-open px-4"></span>Reference data
    </div>
    <div class="card-body">
      <p class="card-text">Insight into Reference data used within MCA. Most reference data is synced from Bezoekschip and can be changed there.</p>
      <span class="p-1"><a class="btn btn-outline-secondary btn-rounded" href="/refdata">Reference data (Barge)</a></span>
    </div>
  </div>
  <div class="card">
    <div class="card-header bg-secondary text-light">
      <span class="fas fa-book-open px-4"></span>Lists
    </div>
    <div class="card-body">
      <p class="card-text">Useful lists of things</p>
      <span class="p-1"><a class="btn btn-outline-secondary btn-rounded" href="/support/railoperators">All rail operators</a></span>
    </div>
  </div>
  <div class="card">
    <div class="card-header bg-secondary text-light">
      <span class="fas fa-book-open px-4"></span>Container detective
    </div>
    <div class="card-body">
      <p class="card-text">Investigate container events</p>
      <span class="p-1"><a class="btn btn-outline-secondary btn-rounded" href="/support/container-detective"><i class="fa-duotone fa-user-secret"></i> Investigate!</a></span>
    </div>
  </div>
</div>
