import {Component} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {
  CancelAndDetachHandling,
  CancelDetachedHandling,
  RoadHandling,
  RoadVisit
} from "@portbase/hinterland-service-typescriptmodels";
import {DetachedHandling, RoadVoyage} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {EventHandler, EventType} from "../../../../common/event-gateway";
import {SelectAllValue, VoyageVisitAndHandling} from "../../../../hinterland/types";
import {CommandRequest, CommandResponse, lodash, sendCommands} from "../../../../common/utils";
import {AddHandlingOffCanvasComponent} from "../../offcanvas-panel/add-handling-offcanvas/app-add-handling-offcanvas";
import {AppContext} from "../../../../app-context";
import {EditModalService} from '../../../../components/modals/edit-modal/edit-modal.service';
import {AddVisitOffCanvasComponent} from "../../offcanvas-panel/add-visit-offcanvas/app-add-visit-offcanvas";
import {ConfirmationModalService} from "../../../../components/modals/confirmation-modal/confirmation-modal.service";
import {
  MoveHandlingConfirmationComponent
} from "../../dialog/move-handling-confirmation-dialog/move-handling-confirmation.component";
import {
  EmailHandlingsConfirmationComponent
} from "../../dialog/email-handlings-confirmation-dialog/email-handlings-confirmation.component";
import {
  VisitCancelledWarningOnDetachComponent
} from "../../dialog/visit-cancelled-warning-dialog/visit-cancelled-warning-on-detach.component";
import {
  VisitCancelledWarningOnCancelComponent
} from "../../dialog/visit-cancelled-warning-on-cancel-dialog/visit-cancelled-warning-on-cancel.component";

@Component({
  selector: 'app-handling-action-panel',
  templateUrl: './handling-action-panel.html',
  styleUrls: ['./handling-action-panel.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(10px)'}),
        animate('500ms', style({opacity: 1, transform: 'translateY(0)'})),
      ]),
      transition(':leave', [
        animate('500ms', style({opacity: 0, transform: 'translateY(10px)'})),
      ]),
    ])]
})
export class HandlingActionPanelComponent extends HinterlandBaseComponent {

  selectAllValue: SelectAllValue = 'none';

  voyages: Map<string, RoadVoyage> = new Map<string, RoadVoyage>();
  visits: Map<string, RoadVisit> = new Map<string, RoadVisit>();
  handlings: Map<string, RoadHandling | DetachedHandling> = new Map<string, RoadHandling | DetachedHandling>();

  selects: Array<Partial<VoyageVisitAndHandling>> = []

  showVisitWillBeCancelledWarning: boolean = null;
  multipleS: string = '';

  emailAddress: string;

  constructor(
    private readonly editModalService: EditModalService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
    super();
  }

  // noinspection JSUnusedGlobalSymbols -> EventHandler
  onHandlingSelected: EventHandler<EventType.HandlingSelected> = (voyageVisitAndHandling: VoyageVisitAndHandling) => {
    if (!!voyageVisitAndHandling.voyage) this.voyages.set(voyageVisitAndHandling.handling.handlingId, voyageVisitAndHandling.voyage);
    if (!!voyageVisitAndHandling.visit) this.visits.set(voyageVisitAndHandling.handling.handlingId, voyageVisitAndHandling.visit);
    if (!!voyageVisitAndHandling.handling) this.handlings.set(voyageVisitAndHandling.handling.handlingId, voyageVisitAndHandling.handling);

    this.selects.push(voyageVisitAndHandling);

    $('app-handling-action-panel').addClass('show');
    this.selectAllValue = this.selectAllValue === 'all' ? 'all' : 'some';

    this.selectionsChanged();
  }

  // noinspection JSUnusedGlobalSymbols -> EventHandler
  onHandlingDeselected: EventHandler<EventType.HandlingDeselected> = (voyageVisitAndHandling: VoyageVisitAndHandling) => {
    this.voyages.delete(voyageVisitAndHandling.handling.handlingId);
    this.visits.delete(voyageVisitAndHandling.handling.handlingId);
    this.handlings.delete(voyageVisitAndHandling.handling.handlingId);
    this.selectAllValue = this.selectAllValue === 'none' ? 'none' : 'some';

    this.selects = this.selects.filter(e => !lodash.isEqual(e, voyageVisitAndHandling))

    this.selectionsChanged();

    if (this.handlings.size === 0) {
      this.exitHandlingActionMode(false);
    }
  }

  selectionsChanged() {
    this.showVisitWillBeCancelledWarning = this.allVisitHandlingsSelected();
    this.multipleS = this.handlings.size > 1 ? 's' : '';
  }

  // noinspection JSUnusedGlobalSymbols -> EventHandler
  onSelectAllHandlingsChanged: EventHandler<EventType.SelectAllHandlingsChanged> = (value: SelectAllValue) => {
    this.selectAllValue = value;
  }

  exitHandlingActionMode(rerender: boolean) {
    let panel = $('app-handling-action-panel');
    panel.addClass('hiding');
    panel.removeClass('show');

    this.voyages = new Map<string, RoadVoyage>();
    this.visits = new Map<string, RoadVisit>();
    this.handlings = new Map<string, RoadHandling>();
    this.selects = [];
    this.showVisitWillBeCancelledWarning = false;
    this.multipleS = '';

    setTimeout(() => {
      panel.removeClass('hiding');
      if (rerender) {
        this.eventGateway.publish(EventType.ExitHandlingActionMode);
      }
      this.eventGateway.publish(EventType.DeselectAllHandlings);
    }, 1);
  }

  toggleSelectAllHandlings() {
    if (this.selectAllValue === 'all') {
      this.eventGateway.publish(EventType.DeselectAllHandlings);
    } else {
      this.eventGateway.publish(EventType.SelectAllHandlings);
    }
  }

  openEmailModal() {
    this.confirmationModalService.openModalWithCallback(() => {
      this.exitHandlingActionMode(true);
    }, EmailHandlingsConfirmationComponent, {
      handlingIds: this.handlings.keys(),
      href: window.location.href,
      multipleS: this.multipleS
    });
  }

  openDetachHandlingsModal() {
    if (this.showVisitWillBeCancelledWarning) {
      this.confirmationModalService.openModalWithCallback((detach) => {
        if (detach) {
          this.showVisitWillBeCancelledWarning = false;
          this.detachHandlings();
        }
      }, VisitCancelledWarningOnDetachComponent, {
        multipleS: this.multipleS
      });
    } else {
      this.detachHandlings();
    }
  }

  detachHandlings() {
    let handlingsToDetach = Array.from(this.handlings.values()).filter(handling => {
      let visit = this.visits.get(handling.handlingId);
      return !!visit && visit.terminal.roadStatusRequestAllowed;
    });

    this.queryAndCommandGateway.detachHandlings(
      handlingsToDetach, (results) => this.handleResults(results, 'detached'));
  }

  openCancelHandlingsModal() {
    if (this.showVisitWillBeCancelledWarning) {
      this.confirmationModalService.openModalWithCallback((cancel) => {
        if (cancel) {
          this.showVisitWillBeCancelledWarning = false;
          this.cancelHandlings();
        }
      }, VisitCancelledWarningOnCancelComponent, {
        multipleS: this.multipleS
      });
    } else {
      this.cancelHandlings();
    }
  }

  cancelHandlings() {
    let handlingsToCancel = Array.from(this.handlings.values())
      .filter(handling => !handling.cancelled && (!this.isRoadHandling(handling) || !handling.completed));

    let commandArray: Array<CommandRequest> = [];
    for (const handling of handlingsToCancel) {
      if ('attached' in handling) {
        commandArray.push(<CommandRequest>{
          type: 'com.portbase.hinterland.api.common.command.CancelDetachedHandling',
          payload: <CancelDetachedHandling>{
            handlingId: handling.handlingId
          }
        });
      } else {
        commandArray.push(<CommandRequest>{
          type: 'com.portbase.hinterland.api.common.command.CancelAndDetachHandling',
          payload: <CancelAndDetachHandling>{
            voyageId: this.voyages.get(handling.handlingId)?.voyageId,
            visitId: this.visits.get(handling.handlingId)?.visitId,
            handlingId: handling.handlingId
          }
        });
      }
    }
    sendCommands(commandArray, (results) => this.handleResults(results, 'cancelled'));
  }

  private isRoadHandling(h: RoadHandling | DetachedHandling): h is RoadHandling {
    return !('attached' in h);
  }

  duplicateHandling() {
    const handling = this.handlings.values().next().value;
    this.editModalService.openModal(AddHandlingOffCanvasComponent, {
      addMode: 'duplicate',
      handling: handling,
      visit: this.visits.get(handling.handlingId),
      voyage: this.voyages.get(handling.handlingId)
    });
    this.exitHandlingActionMode(true);
  }

  allVisitHandlingsSelected(): boolean {
    return Array.from(this.visits.values()).some(visit => visit.handlings.every(handling => handling['selected'] === true));
  };

  anyHandlingAllowsCancel(): boolean {
    return Array.from(this.handlings.values()).some(handling => !handling.cancelled && (!this.isRoadHandling(handling) || !handling.completed));
  };

  anyHandlingAllowsDetach(): boolean {
    return Array.from(this.visits.values()).some(visit => visit.terminal.roadStatusRequestAllowed);
  };

  handleResults(results: Array<CommandResponse>, action: string) {
    if (results.length === 1) {
      if (results[0].success) {
        AppContext.publishSuccessMessage(`Handling ${action}.`);
      } else {
        AppContext.publishErrorMessage(`Handling could not be ${action}.`);
      }
    } else if (results.length > 1) {
      const successful = results.filter(result => result.success);
      if (successful.length > 0) {
        AppContext.publishSuccessMessage(`${successful.length} handling(s) were ${action} successfully.`);
      }

      const error = results.filter(result => !result.success);
      if (error.length > 0) {
        AppContext.publishErrorMessage(`${error.length} handling(s) could not be ${action}.`);
      }
    }

    this.exitHandlingActionMode(true);
  }

  createPreNotification() {
    const handling = this.handlings.values().next().value;

    this.editModalService.openModal(AddVisitOffCanvasComponent, {
      addMode: 'createPreNotification',
      handling: handling,
      visit: null,
      voyage: null
    });

    this.exitHandlingActionMode(true);
  }

  attachOrMoveHandlingsToExistingVisit() {
    const data = Array.from(this.selects)

    if (this.allVisitHandlingsSelected()) {
      this.confirmationModalService.openModal(MoveHandlingConfirmationComponent, data);
    } else {
      this.eventGateway.publish(EventType.StartAttachingToVisit, data);
    }

    this.exitHandlingActionMode(false);
  }

}
