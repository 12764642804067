<div class="row g-0 bg-light py-2">
  <div class="col required"><span class="ps-2">Name</span></div>
  <div class="col-3 padded-col d-none d-xl-block">Address</div>
  <div class="col-3 padded-col d-none d-lg-block">City</div>
  <div class="col-2 padded-col d-none d-md-block">Country</div>
  <div class="col-auto" style="min-width: 16px"></div>
</div>

<div *ngFor="let cargoDeclarant of cargoDeclarants; trackBy : trackByIndex; let idx = index;"
     class="row g-0 my-2 mx-0">
  <div class="col pe-3">
    <app-search (ngModelChange)="cargoDeclarantsChanged()" [(ngModel)]="cargoDeclarants[idx]"
                [searchFunction]="utils.findForwarders" [disabled]="isTemplateDeclarant(cargoDeclarant?.shortName)"
                dataKey="fullName" placeholder="Find organisation" required>
    </app-search>
  </div>
  <div class="col-3 padded-col d-none d-xl-block">
    <div class="padded-col">{{cargoDeclarant?.address}}</div>
  </div>
  <div class="col-3 padded-col d-none d-lg-block">
    <div class="padded-col">{{cargoDeclarant?.city}}</div>
  </div>
  <div class="col-2 padded-col d-none d-md-block">
    <div class="padded-col">{{cargoDeclarant?.countryName}}</div>
  </div>
  <div class="col-auto text-end px-0" style="min-width: 16px">
    <button (click)="cargoDeclarants.splice(idx, 1)" *ngIf="!isTemplateDeclarant(cargoDeclarant?.shortName)"
            class="btn px-0"
            tabindex="-1"
            title="Remove declarant" type="button"><span class="fa fa-trash text-secondary"></span>
    </button>
  </div>
</div>
<div class="row justify-content-between my-3">
  <div class="col-auto"></div>
  <div class="col-auto">
    <button (click)="addCargoDeclarant()" class="btn btn-info" style="min-width: 10rem" type="button">
      <span class="fa fa-plus-circle pe-2"></span> Add declarant
    </button>
  </div>
</div>
