import { Component, ElementRef, OnInit, Type } from '@angular/core';
import {
  DeepSea,
  DeepSeaReference,
  Terminal,
  UpdateDeepSeaReference,
  UpdateDeepSeaReferenceDetached
} from "@portbase/hinterland-service-typescriptmodels";
import { HinterlandBaseComponent } from "../../../../common/hinterland-base-component";
import { checkValidity, cloneObject, scrollToTopInPanel, sendCommand } from "../../../../common/utils";
import { VoyageVisitAndHandling } from "../../../../hinterland/types";
import { ViewHandlingOffCanvasComponent } from "../view-handling-details-offcanvas/app-view-handling-offcanvas";
import { SwitchEditingDialogComponent } from "../../../../components/dialogs/switch-editing-dialog/switch-editing-dialog.component";
import { AppContext } from "../../../../app-context";
import { HinterlandUtils } from "../../../../hinterland/hinterland-utils";
import { EditModalService } from '../../../../components/modals/edit-modal/edit-modal.service';
import { ConfirmationModalService } from '../../../../components/modals/confirmation-modal/confirmation-modal.service';
import {
  CloseEditingDialogComponent
} from '../../../../components/dialogs/close-editing-dialog/close-editing-dialog.component';
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'change-vessel-offcanvas',
  templateUrl: './change-vessel-offcanvas.html',
  styleUrls: ['./change-vessel-offcanvas.scss']
})
export class ChangeVesselOffCanvasComponent extends HinterlandBaseComponent implements OnInit, ClosableModal {

  appContext=  AppContext;
  utils = HinterlandUtils;

  isModalClosable: boolean = true;
  data: VoyageVisitAndHandling;

  isDetached: boolean;

  validationErrors: boolean;
  errors: String[] = [];

  deepSea: DeepSea;
  terminal: Terminal;

  constructor(
      private element: ElementRef,
      private readonly editModalService: EditModalService,
      private readonly confirmationModalService: ConfirmationModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.errors = [];
    this.isDetached = !this.data.voyage;
    this.isModalClosable = true;
    this.terminal = this.isDetached ? this.data.handling['terminal'] : this.data.visit?.terminal;

    if (this.data?.handling?.deepSea) {
      this.deepSea = cloneObject(this.data?.handling?.deepSea);
    } else if (this.data.handling.deepSea) {
      this.deepSea = cloneObject(this.data.handling.deepSea);
    }
  }

  changeVessel() {
    this.setModalClosable(true);

    if (checkValidity(this.element)) {
      this.validationErrors = false;

      if (this.isDetached) {
        sendCommand('com.portbase.hinterland.api.common.command.UpdateDeepSeaReferenceDetached',
          <UpdateDeepSeaReferenceDetached>{
            handlingId: this.data.handling.handlingId,
            deepSeaReference: <DeepSeaReference> {
              imoCode: this.deepSea.imoCode,
              shipName: this.deepSea.shipName,
              crnNumber: this.deepSea.crnNumber
            },
          }, () => {
            this.errors = [];
            AppContext.publishSuccessMessage('Deep sea vessel updated successfully.');
            this.editModalService.closeModal();
          }, (error) => {
            scrollToTopInPanel();
            this.errors.push(error?.error?.error);
          });
      } else {
        sendCommand('com.portbase.hinterland.api.common.command.UpdateDeepSeaReference',
          <UpdateDeepSeaReference>{
            voyageId: this.data.voyage.voyageId,
            visitId: this.data.visit.visitId,
            handlingId: this.data.handling.handlingId,
            deepSeaReference: <DeepSeaReference> {
              imoCode: this.deepSea.imoCode,
              shipName: this.deepSea.shipName,
              crnNumber: this.deepSea.crnNumber
            },
          }, () => {
            this.errors = [];
            AppContext.publishSuccessMessage('Deep sea vessel updated successfully.');
            this.editModalService.closeModal();
          }, (error) => {
            scrollToTopInPanel();
            this.errors.push(error?.error?.error);
          });
      }
    } else {
      this.validationErrors = true;
      scrollToTopInPanel();
    }
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

  switchModal() {
    this.isModalClosable = true;
    this.openViewHandlingPanel();
  }

  getCloseDialogComponentType(): Type<any> {
    return CloseEditingDialogComponent;
  }

  openViewHandlingPanel() {
    if (this.isModalClosable) {
      this.editModalService.openModal(ViewHandlingOffCanvasComponent, <VoyageVisitAndHandling>{
        'voyage': this.data?.voyage,
        'visit': this.data?.visit,
        'handling': this.data?.handling
      });
    } else {
      this.confirmationModalService.openModal(SwitchEditingDialogComponent, <ClosableModal>this);
    }
  }

}
