<div class="header composition-grid">
  <div class="grid-item">Wagon</div>
  <div class="grid-item"></div>
  <div class="grid-item"></div>
  <div class="grid-item">Incoming train</div>
  <div class="grid-item grid-header">Outgoing train</div>
</div>

<div class="sub-header composition-grid">
  <div class="grid-item">Position</div>
  <div class="grid-item">Number</div>
  <div class="grid-item">Slot</div>
  <div class="grid-item">Container number</div>
  <div class="grid-item grid-header">Container number</div>
</div>

<div *ngFor="let wagon of trainComposition.wagons; let idx = index" class="wagon-position">
  <div *ngFor="let slotNumber of [].constructor(wagon.numberOfSlots); let ix = index;"
       class="wagon-slot composition-grid">
    <div>{{ix == 0 ? wagon['position'] : ''}}</div>
    <div>{{ix == 0 ? wagon['wagonNumber'] : ''}}</div>
    <div>{{ix + 1}}</div>
    <div class="equipment-grid">
      <span class="equipmentNumber">{{getEquipmentNumber(ix + 1, wagon.slots, 'incoming')}}</span>
      <span [class]="getType(ix + 1, wagon.slots, 'incoming')" class="fa"></span>
    </div>
    <div class="equipment-grid">
      <span>{{getEquipmentNumber(ix + 1, wagon.slots, 'outgoing')}}</span>
      <span [class]="getType(ix + 1, wagon.slots, 'outgoing')" class="direction fa"></span>
    </div>
  </div>
</div>
