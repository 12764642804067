<h3 class="py-2">Billable transactions</h3>

<div class="col">
  <div class="row">
    <div class="col-3">
      <app-date-range [ngModel]="dateRange" (ngModelChange)="onDateSelection($event)" class="date-range"></app-date-range>
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      <a (click)="downloadBilling()" class="btn btn-outline-info mt-2">Download</a>
    </div>
  </div>
</div>


