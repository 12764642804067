<div class="row narrow-gutters align-items-center">
  <div class="col col-9">
    <div class="row narrow-gutters">
      <div class="col" style="min-width: 16rem">
        <ng-container>
          <div class="row">
            <div class="col-4"><span class="px-2 badge rounded-pill bg-light text-dark" style="min-width: 5rem"
                                     title="Quay">{{utils.getSlicedTerminalDisplayName(handling.terminal, handling.modality)}}</span>
            </div>
            <div class="col-8"><span class="ps-2" style="white-space: nowrap"><app-formatted-date-range
              [end]="handling.etd" [start]="handling.eta"></app-formatted-date-range></span>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="callId"></ng-container>
        </ng-container>
        <ng-template #callId>
          <div *ngIf="!!handling.visitId" class="row">
            <ng-container>
              <div *ngIf="(this.appContext.isHinterlandTerminal())" class="col-4 my-1">
                <span class="px-2 badge rounded-pill bg-light text-dark d-none d-md-block"
                      title="Declarant">{{getCargoDeclarant()}}</span></div>
              <div class="col-8">
                <span class="px-1 badge rounded-pill bg-light text-dark" style="min-width: 4rem">Call id</span>&nbsp;
                <span [ngClass]="{'strikethrough' : allHandlingsInVisitCancelled}"
                      class="ps-2">{{handling.visitId}}</span>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
