import {Component, Input} from '@angular/core';
import {BinnenhavengeldUser, DeleteBinnenhavengeldUser} from '@portbase/hinterland-service-typescriptmodels';
import {cloneObject, publishEvent, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {ConfirmationModalService} from "../../../components/modals/confirmation-modal/confirmation-modal.service";

@Component({
  selector: 'app-bhg-delete-user',
  templateUrl: './bhg-delete-user.component.html',
  styleUrls: ['./bhg-delete-user.component.css']
})
export class BhgDeleteUserComponent {
  @Input() data: BinnenhavengeldUser;

  constructor(
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
  }

  delete() {
    sendCommand('com.portbase.hinterland.api.binnenhavengeld.DeleteBinnenhavengeldUser', <DeleteBinnenhavengeldUser>{
      userId: this.data.userId
    }, () => {
      AppContext.publishSuccessMessage("User deleted successfully");
      publishEvent('onBinnenhavengeldUserDeleted', cloneObject(this.data));
      this.confirmationModalService.closeModal();
    });
  }
}
