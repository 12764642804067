<div class="row">
  <div class="col-12 my-3">
    <span style="font-size: 1.15rem; line-height: 1.5rem;">Company details</span>
  </div>

  <div class="col-12">
    <app-bhg-company-details [companyDetails]="accountDetails.companyDetails"></app-bhg-company-details>
  </div>
</div>

<hr class="my-3 mx-n4">

<div class="row mt-3">
  <div class="col-12 my-3">
    <span style="font-size: 1.15rem; line-height: 1.5rem;">Contact details</span>
  </div>

  <div class="col-6">
    <app-form-field label="Landline phone number" [renderOptional]="false">
      <input [(ngModel)]="accountDetails.landlinePhoneNumber" class="form-control">
    </app-form-field>
  </div>
  <div class="col-6">
    <app-form-field label="Mobile phone number">
      <input [(ngModel)]="accountDetails.mobilePhoneNumber" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>

  <div class="col-6">
    <app-form-field label="Email for login">
      <input [(ngModel)]="accountDetails.loginEmail" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>
  <div class="col-6" *ngIf="newAccount">
    <app-form-field label="Repeat email for login">
      <input #loginEmailRepeated class="form-control" required
             [ngClass]="{'ng-invalid' : loginEmailRepeated.value !== accountDetails.loginEmail}">
      <div class="invalid-feedback">Value differs from email for login</div>
    </app-form-field>
  </div>

  <div class="col-6">
    <app-form-field label="Email for invoice">
      <input [(ngModel)]="accountDetails.invoiceEmail" [required]="newAccount" class="form-control">
    </app-form-field>
  </div>
  <div class="col-6">
    <app-form-field label="Language for messaging">
      <app-new-select [(ngModel)]="accountDetails.language" [options]="['Dutch', 'English', 'German', 'French']"
                      [required]="newAccount"></app-new-select>
    </app-form-field>
  </div>
</div>


<hr class="my-3 mx-n4">

<div class="row mt-3">
  <div class="col-12 my-3">
    <span style="font-size: 1.15rem; line-height: 1.5rem;">Declaration for other parties</span>
  </div>

  <div class="col-12">
    <app-form-field label="Declaration for skipper">
      <input class="form-control d-none">
    </app-form-field>
  </div>

  <div class="col-auto" *ngFor="let party of accountDetails.otherParties; let index = index;">
    <div class="card mb-4">
      <div class="card-body">
        <app-bhg-company-details [companyDetails]="party" [externalCompany]="true"></app-bhg-company-details>

        <div class="mt-3">
          <a href="#" class="text-danger text-decoration-none"
             (click)="accountDetails.otherParties.splice(index, 1); $event.preventDefault()">
            <i class="button-icon fa-light fa-trash-alt pe-2"></i> Remove
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="col-auto">
    <button type="button" class="btn btn-secondary" (click)="accountDetails.otherParties.push({})"
            style="min-width: 10rem">
      <i class="button-icon fa-light fa-plus pe-2"></i> Add skipper
    </button>
  </div>

</div>

