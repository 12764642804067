<ng-container *ngIf="startTimestamp || endTimestamp">
  <span [ngClass]="muted ? 'extra-date-time white-space: nowrap': customClass + ' white-space: nowrap' ">
    <span [ngClass]="{'me-2 fa fa-clock': showClock}"></span>

    <ng-container *ngIf="startTimestamp; else unknown">
      <span *ngIf="startTimestamp.date">{{startTimestamp.date}}</span>
      <span *ngIf="startTimestamp.date && showTime" class="me-1">,</span>
      <span *ngIf="showTime">{{startTimestamp.time}}</span>
    </ng-container>

    <span *ngIf="!startOnly">
        <span> - </span>
        <ng-container *ngIf="endTimestamp; else unknown">
          <span *ngIf="endTimestamp.date">{{endTimestamp.date}}</span>
          <span *ngIf="endTimestamp.date && showTime" class="me-1">,</span>
          <span *ngIf="showTime">{{endTimestamp.time}}</span>
        </ng-container>
    </span>
  </span>
</ng-container>

<ng-template #unknown><span class="me-1" [ngClass]="muted ? 'extra-date-time': customClass">?</span></ng-template>
