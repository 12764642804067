<div (click)="toggleQuickFilters()" class="app-road-navbar-left-button button">
  <span [class]="showQuickFilter ? 'fa-times' : 'fa-filter-list'" class="far fa-fw"></span>
</div>

<ng-container *ngIf="showQuickFilter">
  <div class="quick-filter-overlay">
    <app-road-navbar-left [dateRange]="selectedDateRange" (toggleQuickfilters)="toggleQuickFilters()"
                          [onDateRangeChanged]="onDateRangeChanged" [onQuickFilterChanged]="onQuickFilterChanged"
                          [quickFilterType]="quickFilterType"></app-road-navbar-left>
  </div>
  <div (click)="toggleQuickFilters()" class="quick-filter-backdrop button">&nbsp;</div>
</ng-container>

<main class="h-100 d-flex flex-column">
  <div class="d-flex h-100 overflow-hidden" role="main">
    <div class="col secondary-navbar-left d-block p-0 m-0">
      <app-road-navbar-left [dateRange]="selectedDateRange"
                            [onDateRangeChanged]="onDateRangeChanged"
                            [onQuickFilterChanged]="onQuickFilterChanged"
                            [quickFilterType]="quickFilterType"></app-road-navbar-left>
    </div>
    <div class="col flex-column d-flex p-0 m-0 h-100">
      <app-action-panel></app-action-panel>

      <app-handling-action-panel></app-handling-action-panel>

      <app-road-navbar-top
        (changePage)="changePage($event)"
        [filterTerm]="filterTerm"
        [maxHits]="maxHits"
        [onFilterTermChanged]="onFilterTermChanged"
        [onSelectedHandlingTypeChanged]="onSelectedHandlingTypeChanged"
        [onSelectedSortTypeChanged]="onSelectedSortTypeChanged"
        [onSelectedTerminalChanged]="onSelectedTerminalChanged"
        [pageSize]="pageSize"
        [page]="page"
        [selectedHandlingType]="selectedHandlingType"
        [selectedSort]="selectedSortType"
        [selectedTerminal]="selectedTerminal"
        [showSort]="quickFilterType !== 'all'"
        [totalCount]="filteredSortedItems.length">
      </app-road-navbar-top>

      <div *ngIf="isFetching === false; else spinner" class="d-flex overview-panel main-container overflow-auto">
        <app-infinite-scroller *ngIf="pageItems && pageItems.length !== 0" [initialCount]="initialCount"
                               class="d-flex flex-column">
          <ng-container *ngFor="let item of pageItems; let index = index">
            <app-voyage-panel *ngIf="item.isVoyage"
                              [actionMode]="actionMode"
                              [voyage]="item.getVoyage()">
            </app-voyage-panel>

            <app-handling-panel *ngIf="!item.isVoyage"
                                [actionMode]="actionMode"
                                [handlingData]="item.getDetachedHandling().handlingData"
                                [handling]="item.getDetachedHandling()">
            </app-handling-panel>
          </ng-container>
          <div *ngIf="pageItems && filteredSortedItems.length === maxHits"
               class="d-flex broaden-results-container">
            <div *ngIf="quickFilterType === 'all' || appContext.isAdmin() || appContext.isHinterlandTerminal()"
                 class="flex-column search-all">
              Showing the first {{ maxHits }} results. <span class="hide-md-screen">Use the search bar or date filter to further refine your search results.</span>
            </div>
          </div>
        </app-infinite-scroller>

        <div *ngIf="pageItems && pageItems.length === 0" class="d-flex centered-container">
          <div class="flex-column p-4 bg-circular-grey">
            <div class="fa-regular fa-search fa-3x"></div>
          </div>
          <div class="flex-column mt-4 no-results">No results found<span
            class="hide-md-screen"> in '{{ formattedQuickFilter() }}'</span></div>
          <div class="flex-column mt-2 no-results-sub hide-md-screen">Adjust search term, filters or search within 'All
            handlings'.
          </div>
          <button (click)="onQuickFilterChanged('all')" *ngIf="quickFilterType !== 'all'"
                  class="flex-column btn btn-tertiary older-results hide-md-screen" type="button">
            <span class="fal fa-file-search pe-1"></span>Search 'All handlings'
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="road-navbar-footer d-md-none overflow-hidden ">
    <app-road-navbar-left showNavbarAsFooter=true [dateRange]="selectedDateRange" [activeFilters]="this.selectedDateRange?1:0"
                          [onDateRangeChanged]="onDateRangeChanged" [onQuickFilterChanged]="onQuickFilterChanged"
                          [quickFilterType]="quickFilterType" (toggleQuickfilters)="toggleQuickFilters()"></app-road-navbar-left>
  </div>
</main>

<ng-template #spinner>
  <div class="d-flex overview-panel centered-container">
    <div class="fa-regular fa-circle-notch fa-spin fa-4x"></div>
  </div>
</ng-template>
