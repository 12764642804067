<app-collapse [keepOpen]="hasRailTemplate" [showOnLoad]="showOnLoad">
  <div class="location row g-0 vertical-align">
    <div class="bullet col-auto">
      <span class="bg-white route">
      <span class="fa fa-circle text-white"></span>
      <ng-container [ngSwitch]="status">
        <span *ngSwitchCase="'COMPLETED'"
              class="fa fa-stack-1x fa-check text-secondary"
              title="Completed"></span>
        <span *ngSwitchCase="'IN_OPERATION'"
              class="fas fa-stack-1x fa-cog fa-spin text-secondary"
              title="In Operation"></span>
        <ng-container *ngSwitchCase="'ACCEPTED'">
          <span class="fa fa-stack-1x fa-check-circle text-success" title="Accepted"></span>
          <span *ngIf="visit['loadDischargeListStatus'] === 'closed'"
                class="fa fa-stack-1x fa-lock text-secondary exponent-icon"
                title="Accepted and closed"></span>
        </ng-container>
          <span *ngSwitchCase="'REJECTED'"
                class="fa fa-stack-1x fa-times-circle text-danger"
                title="Rejected"></span>
        <ng-container *ngSwitchCase="'PROCESSED'">
          <span class="fa fa-stack-1x fa-inbox text-info"
                title="Processed"></span>
          <span *ngIf="visit['loadDischargeListStatus'] === 'closed'"
                class="fa fa-stack-1x fa-lock text-secondary exponent-icon"
                title="Processed and closed"></span>
        </ng-container>
        <ng-container *ngSwitchCase="'DECLARED'">
          <span class="fa fa-stack-1x fa-paper-plane text-info"
                title="Declared"></span>
          <span *ngIf="visit['loadDischargeListStatus'] === 'closed'"
                class="fa fa-stack-1x fa-lock text-secondary exponent-icon"
                title="Declared and closed"></span>
        </ng-container>
        <ng-container *ngSwitchCase="'EXPECTED'">
          <span *ngIf="visitDeclarations.length === 0"
                class="fa fa-stack-1x fa-save text-dark"
                title="Saved, not declared">
          </span>
          <span *ngIf="visitDeclarations.length > 0"
                class="fa fa-stack-1x fa-circle text-dark"
                title="Declared">
          </span>
        </ng-container>
        <span *ngSwitchDefault
              class="fa fa-stack-1x fa-circle text-dark"
              title="Unsaved visit"></span>
      </ng-container>
    </span>
    </div>
    <div class="col item">
      <div class="d-block">
        <div [ngClass]="{'cancelled' : visit.visitCancelled}"
             [ngStyle]="{'cursor': hasRailTemplate ? 'auto' : 'pointer'}"
             class="visit-summary collapsible ms-0 row narrow-gutters align-items-center border border-light py-2">
          <ng-container *ngIf="!!visit.visitId || (isModalityRail && !!visit.terminal); else newentry">
            <div *ngIf="isModalityBarge" class="col pe-0">
              <span *ngIf="visit.acknowledgedByOperator === false" class="fa fa-circle me-2 text-info"></span>
              <span
                *ngIf="!visit.requestedTerminal || visit.terminal?.quayName === visit.requestedTerminal?.quayName">{{visit.terminal?.quayName}}</span>
              <app-tooltip
                *ngIf="visit.requestedTerminal && visit.terminal?.quayName !== visit.requestedTerminal?.quayName">
                <b>{{visit.terminal?.quayName}}</b>&nbsp;<span class="fa fa-info-circle"
                                                               style="color:#004462">&nbsp;</span>
                <div class="tooltipContent text-start">Quay different than requested!</div>
              </app-tooltip>
              <notifications [forVisit]="visit"
                             [notificationContext]="'detail'"
                             compactView="true">
              </notifications>
            </div>

            <div *ngIf="isModalityRail" class="col pe-0">
              <span *ngIf="visit.acknowledgedByOperator === false" class="fa fa-circle me-2 text-info"></span>
              <span>{{terminalDisplayName}}</span>
              <span *ngIf="!visit.visitData['timetableEntry'] && visit.usingVisitDeclarations"
                    class="fa-solid fa-calendar-circle-plus ps-3 text-warning"
                    title="Rail visit without a timetable slot"></span>
            </div>

            <div *ngIf="isModalityRoad" class="col pe-0">
              <span *ngIf="visit.acknowledgedByOperator === false" class="fa fa-circle me-2 text-info"></span>
              <span>{{terminalDisplayName}}</span>
            </div>

            <div class="col">
              <app-visit-date-range *ngIf="!hasRailTemplate"
                                    [firstDepartureDate]="voyage?.voyageData['firstDepartureDate']"
                                    [integralPlanningRequested]="integralPlanningRequested"
                                    [integralPlanningStatus]="visit['integralPlanningStatus']"
                                    [modality]="visit.modality"
                                    [plannedVisitData]="visit?.plannedVisitData"
                                    [requestedVisitData]="visit?.requestedVisitData"
                                    [status]="status"
                                    [terminal]="visit.terminal"
                                    [visitData]="visit.visitData">
              </app-visit-date-range>
            </div>

            <div class="col d-none d-lg-block">
              <app-handling-total *ngIf="!hasRailTemplate"
                                  [handlingStats]="visit.handlingSummary"
                                  [inOperation]="visit.arrived"
                                  [visit]="visit">
              </app-handling-total>
            </div>

            <div class="col d-none d-lg-block gu-transit-show">
              <span *ngIf="!!visit.visitId"><span class="me-2 badge rounded-pill bg-light text-dark"
                                                  style="width: 5rem">Call ID</span>&nbsp;{{visit.visitId}}</span>
            </div>
          </ng-container>
          <ng-template #newentry>
            <div class="col pe-0 new-visit">
              New visit {{hasRailTemplate ? 'template' : ''}}
            </div>
          </ng-template>

          <div *ngIf="!hasRailTemplate" class="col-auto d-none d-sm-block" style="min-width: 2.5rem">
            <h4 class="my-0 fa fa-chevron-right text-secondary"></h4>
          </div>
          <div *ngIf="hasRailTemplate" class="col-auto d-none d-sm-block" style="cursor:pointer;min-width: 2.5rem">
            <h5 (click)="removeVisitTemplate(visit)" class="my-0 fas fa-trash-alt text-secondary"
                title="Remove visit template"></h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="location row g-0">
    <div class="bullet col-auto">
      <span class="fa fa-circle spacer"></span>
    </div>
    <div class="col item">
      <ng-container #visitCollapse>
        <div class="collapse">
          <div class="pb-1 pt-2">
            <div class="row g-0 me-n1">
              <div class="col">
                <ul #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" ngbNav>
                  <li ngbNavItem="visitTab">
                    <a ngbNavLink>Visit {{hasRailTemplate ? 'template ' : ''}}details</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <div *ngIf="visit.terminal?.nonPcs"
                             class="alert alert-warning alert-dismissible fade show" role="alert">
                          Note that this terminal is not connected to Portbase and will not be notified of your visit.
                          <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
                        </div>
                        <div
                          *ngIf="integralPlanningRequested && !!visitDeclaration && visitDeclaration.status === 'REJECTED'"
                          class="alert alert-warning alert-dismissible fade show" role="alert">
                          Note that this visit is rejected by the terminal and any restrictions for this visit are
                          removed.
                          <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
                        </div>
                        <div *ngFor="let rejectReason of visit.visitDeclaration?.rejectReasons"
                             class="alert alert-danger alert-dismissible fade show"
                             role="alert">
                          <strong>{{terminalDisplayName}}</strong> {{rejectReason.parsedReason}}
                          <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
                        </div>
                        <div
                          *ngIf="!!visit.visitId && visit.plannedVisitData && visit.plannedVisitData['terminalRemarks']"
                          class="alert alert-info alert-dismissible fade show" role="alert">
                          <strong>{{terminalDisplayName}}</strong> {{visit.plannedVisitData['terminalRemarks']}}
                          <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
                        </div>

                        <ng-container *ngIf="isModalityBarge">
                          <notifications [forVisit]="visit"
                                         [notificationContext]="'detail'">
                          </notifications>
                          <integral-planning-status
                            [integralPlanningVisitStatus]="visit['integralPlanningStatus']"
                            [terminal]="visit.terminal">
                          </integral-planning-status>
                          <app-visit-admin
                            [isModalityBarge]="isModalityBarge"
                            [originalVoyageId]="originalVoyageId"
                            [visitId]="visit.visitId"
                            [visitTerminalShortname]="visit.terminal?.shortName"
                            [voyageId]="voyageId">
                          </app-visit-admin>
                          <app-edit-barge-visit-operator
                            (deletedEventEmitter)="visitDeletedEventEmitter.emit($event)"
                            (etaUpdatedEventEmitter)="visitEtaUpdatedEventEmitter.emit()"
                            [integralPlanningRequested]="integralPlanningRequested"
                            [nextlogicEnabled]="nextlogicEnabled"
                            [shareWithNextlogicRequested]="shareWithNextlogicRequested"
                            [visit]="visit"
                            [voyageCancelled]="voyageCancelled"
                            [voyageData]="voyageData"
                            [voyageDeclarantShortname]="voyageDeclarantShortname"
                            [voyageId]="voyageId">
                          </app-edit-barge-visit-operator>
                        </ng-container>
                        <ng-container *ngIf="isModalityRail">
                          <app-edit-rail-visit-operator
                            (deletedEventEmitter)="visitDeletedEventEmitter.emit($event)"
                            (etaUpdatedEventEmitter)="visitEtaUpdatedEventEmitter.emit()"
                            [hasTemplate]="hasRailTemplate"
                            [visit]="visit"
                            [voyageDeclarantShortname]="voyageDeclarantShortname"
                            [voyageFirstDepartureDate]="voyage?.voyageData['firstDepartureDate']"
                            [voyageId]="voyageId">
                          </app-edit-rail-visit-operator>
                        </ng-container>
                        <ng-container *ngIf="isModalityRoad">
                          <app-view-road-visit
                            [visit]="visit">
                          </app-view-road-visit>
                        </ng-container>
                      </div>
                    </ng-template>
                  </li>

                  <li *ngIf="!visit.terminal?.nonPcs && !hasRailTemplate"
                      ngbNavItem="handlingsTab">
                    <a ngbNavLink>Handlings</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <app-visit-handlings-operator
                          [declareCargoAllowed]="declareCargoAllowed"
                          [handlings]="visit.handlings"
                          [openHandlingId]="openHandlingId"
                          [visitStatus]="status"
                          [visit]="visit"
                          [voyageCancelled]="voyageCancelled"
                          [voyageCargoDeclarants]="voyageCargoDeclarants"
                          [voyageDeclarant]="voyageDeclarant"
                          [voyageId]="voyageId"
                          [voyageStatus]="voyageStatus">
                        </app-visit-handlings-operator>
                        <div *ngIf="!status" class="text-center text-secondary py-3">
                          Please save this visit before adding handlings.
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li *ngIf="isModalityRail && !hasRailTemplate && !appContext.isPhone()" class="hide-md-screen" ngbNavItem="trainCompositionTab">
                    <a ngbNavLink>Train composition</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <div class="row">
                          <div class="col-12 mb-2 text-end">
                            <button
                              [disabled]="status === 'COMPLETED' || !trainCompositionUploadEnabled"
                              (click)="uploadFileInput.click()" class="btn btn-info mx-1" type="button" title="{{uploadButtonTitle()}}">
                              <span>Upload</span>
                            </button>
                            <button
                              [disabled]="!visit['trainComposition']"
                              (click)="downloadLoadDischargePlan()" class="btn btn-info mx-1" type="button">
                              <span>Download train composition</span>
                            </button>
                            <button *ngIf="status === 'COMPLETED'" [disabled]="!visit['trainComposition']"
                                    (click)="downloadTrainCompositionAfterOperations()" class="btn btn-info mx-1" type="button">
                              <span>Download train composition (after operations)</span>
                            </button>
                            <input type="file" hidden (change)="uploadTrainComposition($event.target.files[0]);$event.target.value='';" #uploadFileInput>
                          </div>
                          <div class="col-12 mb-2 text-end">
                            <a href="/assets/templates/train-composition-template-2.4.xlsx"
                               class="align-middle pe-2 text-portbase-dark-blue ms-2"
                               tabindex="-1">Download Excel template <span class="fa fa-file-download"></span></a>
                          </div>
                          <div class="col-md-12">
                            <app-train-composition *ngIf="visit.trainComposition" [trainComposition]="visit.trainComposition"></app-train-composition>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li *ngIf="!appContext.isPhone() && !appContext.isHistoricalView() && !!visit.visitId"
                      ngbNavItem="historyTab">
                    <a ngbNavLink>History</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <div class="row">
                          <div class="declaration-block col-md-12">
                            <h2>Visit history</h2>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <app-history-component [visitId]="visit.visitId"></app-history-component>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</app-collapse>
