import {Location} from '@angular/common';
import {Component, ElementRef, OnInit} from '@angular/core';
import {checkValidity, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {RequestBinnenhavengeldAccount} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-bhg-create-account',
  templateUrl: './bhg-request-account.component.html',
  styleUrls: ['./bhg-request-account.component.css']
})
export class BhgRequestAccountComponent implements OnInit {

  command : RequestBinnenhavengeldAccount = {
    accountDetails: {
      language: AppContext.getBinnenhavengeldLanguage(),
      companyDetails: {},
      otherParties: []
    }
  };

  errors: string[];

  constructor(public location: Location, private element: ElementRef) {
  }

  ngOnInit() {
    this.errors = [];
  }

  requestAccount = () => {
    if (checkValidity(this.element)) {
      AppContext.clearAlerts();
      this.errors = [];
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.RequestBinnenhavengeldAccount', this.command,
        () => {
          AppContext.publishSuccessMessage("Your request has been submitted to the Port of Rotterdam for review");
          AppContext.navigateToUrl("/binnenhavengeld");
        },
        (error) => {
          this.errors.push(error?.error?.error);
        });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  };
}
