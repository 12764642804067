import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  HinterlandOrganisation,
  RailVoyageData,
  Visit,
  VisitData
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {ActivatedRoute, Router} from '@angular/router';
import {checkValidity, sendCommand, sortIntoSeparateIndices} from '../../../common/utils';
import {EventGateway, EventHandler, EventType} from "../../../common/event-gateway";
import {AppContext} from '../../../app-context';
import {HinterlandUtils, VoyageUnion} from '../../hinterland-utils';
import {DragulaService} from "ng2-dragula";
import {RailVoyage, RailVoyageTemplate, RoadVoyage,} from "@portbase/hinterland-service-typescriptmodels";
import {getHistoricalVoyage, getVoyage, updateVoyage} from "../../../common/query-utils";
import {applyJsonPatch} from "../../../common/json-utils";
import {ComparatorChain} from "../../../common/comparator-chain";

@Component({
  selector: 'app-voyage-details',
  templateUrl: './voyage-details.component.html',
  styleUrls: ['./voyage-details.component.scss']
})
export class VoyageDetailsComponent implements OnDestroy, OnInit {

  static visitComparator: ComparatorChain = new ComparatorChain("visitData.eta");

  appContext = AppContext;
  utils = HinterlandUtils;

  voyage: VoyageUnion;
  sortedVisitIndices: number[] = [];
  title: string = 'Voyage details';
  isModalityBarge: boolean;
  isModalityRail: boolean;
  isModalityRoad: boolean;
  isDisabled: boolean;
  hasArrived: boolean;
  voyageActionsAllowed: boolean;
  seeVoyageHistoryAllowed: boolean;
  declareCargoAllowed: boolean;
  visitsForRestrictions: Visit[];

  eventId: string;
  hasRailTemplate: boolean = false;
  openHandlingId: string;
  openVisitId: string;

  charter: string;

  private unsubscribeHandle;

  @Output() initialized = new EventEmitter();

  @Input() set setVoyageTemplate(voyageTemplate: RailVoyageTemplate) {
    this.hasRailTemplate = true;
    this.initVoyage(<RailVoyage><unknown>voyageTemplate);
    this.title = 'Voyage template details';
    this.initialized.emit(true);
  }

  @ViewChild('editVoyageComponent') editVoyageComponent: any;

  constructor(private router: Router, private route: ActivatedRoute, private eventGateway: EventGateway, private dragulaService: DragulaService) {
  }

  ngOnInit() {
    if (!this.hasRailTemplate) {
      this.route.params.subscribe(params => {
        this.route.queryParams.subscribe(queryParams => {
          this.eventId = queryParams['eventId'];
          this.openVisitId = queryParams['visitId'];
          this.openHandlingId = queryParams['handlingId'];

          if (this.eventId) {
            getHistoricalVoyage(params['voyageId'], this.eventId).subscribe((voyage: VoyageUnion) => {
              this.appContext.historicalViewTimestamp = voyage.updated;
              this.initVoyage(voyage);
            });
          } else if (window.history.state && window.history.state.voyage && window.history.state.voyage.voyageId === params['voyageId']) {
            this.initVoyage(window.history.state.voyage);
          } else {
            getVoyage(params['voyageId']).subscribe((voyage: VoyageUnion) => {
              this.initVoyage(voyage);
            });
          }

          if (window.history.state && window.history.state.voyage) {
            delete window.history.state.voyage;
          }
        });
      });

      this.unsubscribeHandle = this.eventGateway.registerLocalHandler(this);
    }
  }

  private initVoyage(voyage: VoyageUnion) {
    this.voyage = voyage;
    this.updateFrontendProperties();
    if (this.voyage.visits.length === 0) {
      this.addVisit();
    }
    this.sortVisits();
    this.initDragula();
  }

  private updateFrontendProperties() {
    this.isModalityBarge = this.voyage.modality === 'barge';
    this.isModalityRail = this.voyage.modality === 'rail';
    this.isModalityRoad = this.voyage.modality === 'road';

    var updateVoyageAllowed = AppContext.isAdmin() || this.voyage.declarant.shortName === AppContext.userProfile.organisationShortName;
    this.seeVoyageHistoryAllowed = updateVoyageAllowed || ((this.voyage.voyageData as RailVoyageData).tractionSupplier && (this.voyage.voyageData as RailVoyageData).tractionSupplier.shortName === AppContext.userProfile.organisationShortName);
    this.voyageActionsAllowed = !this.hasRailTemplate && !this.voyage.cancelled && updateVoyageAllowed && !this.isModalityRoad;
    this.declareCargoAllowed = updateVoyageAllowed || this.voyage.cargoDeclarants.some(declarant => declarant.shortName === AppContext.userProfile.organisationShortName);
    this.isDisabled = !!this.eventId || this.voyage.cancelled || !updateVoyageAllowed;
    this.hasArrived = this.voyage.visits.some(v => v.arrived);

    let roadCharter = this.isModalityRoad ? (this.voyage as RoadVoyage).voyageData?.charter : undefined;
    this.charter = roadCharter ? `${roadCharter.name} (${roadCharter.ean})`: undefined;
  }

  addVisit = () => {
    let visitData: VisitData = <VisitData>{
      modality: this.voyage.modality,
    };
    if (this.isModalityBarge) {
      if (this.voyage['integralPlanningRequested']) {
        visitData['estimatedHandlings'] = {estimatedSizes: {}};
        visitData['cranesUsable'] = 1;
      } else {
        visitData['estimatedHandlings'] = {};
      }
    }
    if (this.hasRailTemplate) {
      visitData['etaTimeOfWeek'] = {};
      visitData['etdTimeOfWeek'] = {};
    }
    if (this.isModalityRail) {
      visitData['estimatedHandlings'] = {};
    }
    this.voyage.visits.push(<any>{
      visitId: null,
      visitData: visitData,
      requestedVisitData: visitData,
      visitDeclarations: [],
      visitDeclaration: null,
      visitCancelled: false,
      visitCompleted: false,
      visitDeclarationStatus: null,
      modality: this.voyage.modality,
      arrived: false,
      handlings: []
    });
    this.sortVisits();
  };

  onPatchVoyage: EventHandler<EventType.PatchVoyage> = (patch: any) => {
    if (patch.modality !== 'barge' && patch.modality !== 'rail') {
      return;
    } else if (!!this.eventId || patch.id !== this.voyage?.voyageId) {
      return;
    }

    if (patch.value.length === 1 && patch.value[0].op === 'add' && patch.value[0].path.split('/').length === 2) {
      //Backend simulates voyage WITHOUT previous as 'add', since most ui components will work with a list of voyages.
      //However this component works with a single voyage, hence this logic.
      this.initVoyage(patch.value[0].value);
    } else {
      applyJsonPatch(this.voyage, patch);
    }

    this.sortVisits();
    this.updateFrontendProperties();
  }

  updateVoyage = () => {
    if (checkValidity(this.editVoyageComponent.elementRef)) {
      updateVoyage(this.voyage.voyageId, this.voyage.voyageData);
    }
  };

  ngOnDestroy(): void {
    this.appContext.historicalViewTimestamp = null;
    if (this.unsubscribeHandle) {
      this.unsubscribeHandle();
    }
  }

  cancelVoyage = () => {
    sendCommand('com.portbase.hinterland.api.common.command.CancelVoyage',
      {voyageId: this.voyage.voyageId}, () => {
        AppContext.registerSuccess('The voyage was cancelled successfully');
        this.router.navigateByUrl("/rotations");
      });
  };

  sortVisits = () => {
    this.sortedVisitIndices = sortIntoSeparateIndices(this.voyage.visits, VoyageDetailsComponent.visitComparator);
    this.visitsForRestrictions = this.sortedVisitIndices.map(index => this.voyage.visits[index]).filter(visit => !visit.visitCancelled)
  };

  deleteVisit = () => {
    for (let i = this.voyage.visits.length - 1; i >= 0; i--) {
      if (!this.voyage.visits[i].visitId) {
        this.voyage.visits.splice(i, 1);
      }
    }
    this.sortVisits();
  }

  private initDragula() {
    setTimeout(() =>
      this.dragulaService.find('restrictions').options.moves = (el, target) => {
        return this.restrictionTabIsOpen()
          && el.id !== 'entrypoint' && el.id !== 'exitpoint'
          && el.getElementsByClassName('new-visit').length === 0;
      }, 0);
  }

  private restrictionTabIsOpen = () => {
    return this.dragulaService.find('restrictions').drake.containers.some(c => c.id === 'restrictions');
  };

  onCargoDeclarantsChanged(cargoDeclarants: HinterlandOrganisation[]) {
    this.voyage.cargoDeclarants = cargoDeclarants;
  }
}
