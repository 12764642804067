import {Component, Input} from '@angular/core';
import {lodash} from '../utils';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {

  @Input() maxPerPage = 20;
  @Input() page: number = 1;
  @Input() items: any[] = [];

  getItemsOnPage(): any[] {
    if (this.items.length === 0) {
      this.page = 1;
      return [];
    }
    const chunks = lodash.chunk(this.items, this.maxPerPage);
    if (this.page > chunks.length) {
      this.page = chunks.length;
    }
    return chunks[this.page - 1];
  }

}
