import {Component, Input} from '@angular/core';
import {TransportOrder} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-transport-order',
  templateUrl: './transport-order.component.html',
  styleUrls: ['./transport-order.component.css']
})
export class TransportOrderComponent {

  @Input() order: TransportOrder;

}
