import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AcknowledgeTerminalUpdate,
  DayOfWeek,
  DeclareVisit, GetAvailableTimetableSlots,
  RailVisit,
  RailVisitDataTemplate,
  RailVisitStatus,
  RailVisitTemplate,
  RailVoyage,
  SaveVisit,
  Terminal,
  TimetableEntry
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {HinterlandUtils} from '../../hinterland-utils';
import {checkValidity, clearValidation, sendCommand, sendQuery, toTitleCase} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {Observable, of} from 'rxjs';


@Component({
  selector: 'app-edit-rail-visit',
  templateUrl: './edit-rail-visit.component.html',
  styleUrls: ['./edit-rail-visit.component.css']
})
export class EditRailVisitComponent implements OnInit, OnChanges {

  appContext = AppContext;
  utils = HinterlandUtils;

  @Input() voyage: RailVoyage;
  @Input() visit: RailVisit;
  @Input() terminalView: boolean = false;
  @Input() hasTemplate: boolean = false;

  @Output() timeUpdatedEventEmitter = new EventEmitter();
  @Output() deletedEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  timetableEntries: TimetableEntry[] = [];
  terminals: Terminal[] = [];
  isNew: boolean = false;
  isDisabled: boolean;

  timetableEntry;

  ngOnInit(): void {
    if (this.terminalView) {
      this.timetableEntries = this.visit.visitData.timetableEntry ? [this.visit.visitData.timetableEntry] : [];
    } else {
      this.getAvailableTimetableSlots();
    }

    this.timetableEntry = this.formatTimetableEntry(this.visit.visitData?.timetableEntry);

    HinterlandUtils.getTerminals('rail', false, true, true)
      .subscribe(terminals => this.terminals = terminals);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visit) {
      this.isNew = !this.visit.visitId;
      this.isDisabled = this.visit.arrived;
      this.timetableEntry = this.formatTimetableEntry(this.visit.visitData?.timetableEntry);

      if (this.visit.plannedVisitData === undefined) {
        this.visit.plannedVisitData = <any>{
          eta: this.visit.visitData.eta,
          etd: this.visit.visitData.etd,
          modality: this.visit.modality,
          unsaved: true
        };
      }

      if (!this.terminalView && this.visit.visitData.estimatedHandlings === undefined) {
        this.visit.visitData.estimatedHandlings = {
          dischargeEmpty: 0,
          dischargeTotal: 0,
          handlingTotal: 0,
          loadingEmpty: 0,
          loadingTotal: 0
        };
      }
    }
  }

  formatTimetableEntry = (timetableEntry: TimetableEntry) => {
    return timetableEntry == null
      ? 'None'
      : timetableEntry.code + " (" + this.formatDay(timetableEntry.window.start.day) + ' ' + this.formatTime(timetableEntry.window.start.time) + '-' + (timetableEntry.window.end.day != timetableEntry.window.start.day ? this.formatDay(timetableEntry.window.end.day) + ' ' : '') + this.formatTime(timetableEntry.window.end.time) + ") d:" + timetableEntry.totalDischarge + ', l:' + timetableEntry.totalLoading;
  };

  formatDay = (dayOfWeek: DayOfWeek) => {
    return dayOfWeek == null || dayOfWeek.length < 3 ? dayOfWeek : toTitleCase(dayOfWeek.substring(0, 3));
  }

  formatTime = (time: string) => {
    return time == null || time.lastIndexOf(':') < 4 ? time : time.substring(0, time.lastIndexOf(':'));
  }

  getVisitDataTemplate(): RailVisitDataTemplate {
    let railVisitTemplate = <RailVisitTemplate><unknown>this.visit;
    if (!railVisitTemplate.visitData) {
      railVisitTemplate.visitData = <RailVisitDataTemplate><unknown>{
        etaTimeOfWeek: {dayOfWeek: null, timeOfDay: null},
        etdTimeOfWeek: {dayOfWeek: null, timeOfDay: null}
      };
    }
    return railVisitTemplate.visitData;
  }

  constructor(private element: ElementRef) {
  }

  onChangeTerminalRemarks($event: any) {
    this.visit.plannedVisitData = this.visit.plannedVisitData == null ? <RailVisitStatus>{} : this.visit.plannedVisitData;
    this.visit.plannedVisitData.terminalRemarks = $event;
  }

  saveVisit = () => {
    clearValidation(this.element);
    this.saveOrSendVisit('com.portbase.hinterland.api.common.command.SaveVisit');
  };

  declareVisit = () => {
    if (checkValidity(this.element)) {
      this.saveOrSendVisit('com.portbase.hinterland.api.common.command.DeclareVisit');
    }
  };

  acknowledgeUpdate = () => {
    sendCommand('com.portbase.hinterland.api.common.command.AcknowledgeTerminalUpdate',
      <AcknowledgeTerminalUpdate>{voyageId: this.voyage.voyageId, visitId: this.visit.visitId}, () => {
        AppContext.registerSuccess('The update to the visit has been acknowledged successfully');
      });
  };

  private saveOrSendVisit = (commandClass: string) => {
    if (checkValidity(this.element)) {
      this.getVisitId().subscribe(visitId => {
        sendCommand(commandClass, {
          '@class': 'io.fluxcapacitor.javaclient.common.Message',
          payload: <DeclareVisit | SaveVisit>{
            voyageId: this.voyage.voyageId,
            visitId: visitId,
            visitData: this.visit.visitData,
            terminal: this.visit.terminal
          }
        }, () => {
          this.deletedEventEmitter.emit(visitId);
          AppContext.registerSuccess('The visit was saved successfully');
        });
      });
    }
  };

  private getVisitId = (): Observable<string> =>
    this.visit.visitId ? of(this.visit.visitId) : HinterlandUtils.getNextVisitId();

  cancelVisit = () => {
    if (this.isNew) {
      this.deletedEventEmitter.emit(this.visit.visitId);
    } else {
      sendCommand('com.portbase.hinterland.api.common.command.CancelVisit',
        {voyageId: this.voyage.voyageId, visitId: this.visit.visitId}, () => {
          AppContext.registerSuccess('The visit was cancelled successfully');
        });
    }
  };

  adoptTimetableEntryData = () => {
    if (!this.hasTemplate && this.visit.visitData.timetableEntry) {
      if (!this.visit.visitData.eta) {
        this.visit.visitData.eta = HinterlandUtils.nextMoment(this.voyage.voyageData.firstDepartureDate, this.visit.visitData.timetableEntry.window.start);
      }
      if (!this.visit.visitData.etd) {
        this.visit.visitData.etd = HinterlandUtils.nextMoment(this.voyage.voyageData.firstDepartureDate, this.visit.visitData.timetableEntry.window.end);
      }
      if (!this.visit.visitData.estimatedHandlings.loadingTotal) {
        this.visit.visitData.estimatedHandlings.loadingTotal = this.visit.visitData.timetableEntry.totalLoading;
      }
      if (!this.visit.visitData.estimatedHandlings.dischargeTotal) {
        this.visit.visitData.estimatedHandlings.dischargeTotal = this.visit.visitData.timetableEntry.totalDischarge;
      }
    }
  }

  getAvailableTimetableSlots = () => {
    if (this.terminalView) {
      this.timetableEntries = this.visit.visitData.timetableEntry ? [this.visit.visitData.timetableEntry] : [];
    } else {
      this.timetableEntries = [];
      if (!!this.visit.terminal) {
        sendQuery("com.portbase.hinterland.api.refdata.query.GetAvailableTimetableSlots", <GetAvailableTimetableSlots>{
          'firstDepartureDate': this.voyage.voyageData.firstDepartureDate,
          'terminalShortName': this.visit.terminal.shortName,
          'declarantShortName': this.voyage.declarant.shortName
        }, {caching: false})
          .subscribe(result => {
            if (result.length > 0) {
              this.timetableEntries = result;
              if (!result.includes(null)) this.timetableEntries.unshift(null);
            }
          });
      }
    }
  }

  weekDays = () => HinterlandUtils.weekdays;
  toTitleCase = (value) => toTitleCase(value);

}
