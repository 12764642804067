<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Copy visit as text</div>
  </div>
  <div class="modal-body mt-0">
    <div class="pt-3 copy-grid">
      <div class="copy-text select-all" [innerHTML]="formattedText"></div>
      <div (click)="copy()" class="copy-button"><span class="fal fa-copy"></span></div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
    <button (click)="copy()" class="btn btn-primary" data-bs-dismiss="modal" type="button">Copy text</button>
  </div>
</div>
