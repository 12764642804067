<nav class="navbar navbar-light bg-white p-0 prevent-select">
  <div class="navbar-col col p-0 page-title">
    <div class="d-flex h-100 align-items-center justify-content-between text-secondary px-3">
      <div class="col-auto navbar-title">
        Inland port dues
      </div>
    </div>
  </div>

  <div class="navbar-col d-flex justify-content-end align-items-center user-icon">
    <button title="Open Beamer" class="mx-1 beamer-button hide-md-screen" app-icon-button color="muted" tabindex="0">
      <i class="fal fa-bullhorn"></i>
    </button>

    <button title="Open support" class="mx-1 hide-md-screen" app-icon-button color="muted" tabindex="1"
            (click)="openSupport()">
      <i class="fal fa-headset"></i>
    </button>

    <div title="Select language" class="mx-1 dropdown" tabindex="2">
      <app-language-selector></app-language-selector>
    </div>

    <div class="dropdown">
      <button app-icon-button color="muted" title="Open user menu" class="mx-1 hide-md-screen" data-bs-toggle="dropdown"
              tabindex="3">
        <i class="fal fa-user"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-end user-menu">
        <div class="dropdown-header hide-md-screen" style="line-height: 1.5rem">
          <div class="row">
            <div style="width: 60%" class="col-auto fw-medium">Username</div>
            <div *ngIf="!currentUser?.organisation?.organisationName" class="col-auto fw-medium" style="width: 40%">
              HBR number
            </div>
            <div style="width: 60%" class="col-auto notranslate">{{ currentUser.userId }}</div>
            <div *ngIf="!currentUser?.organisation?.organisationName" style="width: 40%"
                 class="col-auto notranslate">{{ currentUser.organisationId }}
            </div>
          </div>
          <div class="mt-2" *ngIf="currentUser?.organisation?.organisationName">
            <app-bhg-organisation [organisation]="currentUser.organisation"></app-bhg-organisation>
          </div>
        </div>
        <div class="dropdown-divider hide-lg-screen"></div>
        <div class="mock-users">
          <app-new-select *ngIf="local" class="notranslate" [ngModel]="currentUser" [options]="mockUsers"
                          [dataKey]="'userId'" (ngModelChange)="setCurrentUser($event)"
                          (click)="$event.stopPropagation()">
          </app-new-select>
        </div>
        <div *ngIf="local" class="dropdown-divider"></div>
        <div *ngIf="context.isBinnenhavengeldAdmin()" class="dropdown-item"
             (click)="$event.preventDefault(); editApplicationConfig()">
          Configure application
        </div>
        <div *ngIf="context.binnenhavengeldUserProfile?.organisation?.organisationName" class="dropdown-item"
             (click)="updateAccount()">
          Manage account
        </div>
        <div class="dropdown-item" (click)="openTermsAndConditions()">Terms and Conditions</div>
        <div (click)="signOut()" class="dropdown-item">Sign out</div>
      </div>
    </div>

    <div class="dropdown hamburger">
      <button title="Open services" class="mx-1 show-lg-screen" data-bs-toggle="dropdown" app-icon-button color="muted" tabindex="3">
        <i class="fal fa-bars"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-end mt-3 py-0">
        <app-bhg-services></app-bhg-services>
      </div>
    </div>
  </div>
</nav>
