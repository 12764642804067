<span *ngIf="$any(vessel) as vessel" class="fw-bold">
  <ng-container [ngSwitch]="vessel.modality">
    <ng-container *ngSwitchCase="'sea'">
      <span class="fa fa-fw fa-anchor"></span><span *ngIf="!hideName" class="ms-2">{{vessel.name || "Unknown" | titlecase}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'barge'">
      <span class="fa fa-fw fa-ship"></span><span *ngIf="!hideName" class="ms-2">{{vessel.name || "Unknown" | titlecase}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'rail'">
      <span class="fa fa-fw fa-train"></span><span *ngIf="!hideName" class="ms-2">{{vessel.trainNumber || "Unknown" | titlecase}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'road'">
      <span class="fa fa-fw fa-truck"></span><span *ngIf="!hideName" class="ms-2">{{vessel.truckLicenseId || "Unknown" | titlecase}}</span>
    </ng-container>
  </ng-container>
</span>
