import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {filterByTerm, replaceItem, sendQuery} from '../../common/utils';
import {DateTimeRange, FindVoyages, GetVoyages, Voyage} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {Router} from '@angular/router';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {HinterlandUtils, voyageComparator, VoyageUnion} from '../hinterland-utils';
import {AppContext} from "../../app-context";
import {InjectorProvider} from '../../common/injector-provider';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-operator-overview',
  templateUrl: './operator-overview.component.html',
  styleUrls: ['./operator-overview.component.css']
})
export class OperatorOverviewComponent extends AbstractOverviewComponent<Voyage> implements OnInit {

  appContext = AppContext;
  expected: Voyage[] = [];
  inOperation: Voyage[] = [];
  completed: Voyage[] = [];
  cancelled: Voyage[] = [];
  pagingSupported: boolean = AppContext.isAdmin();

  ngOnInit() {
    super.ngOnInit();
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<(Voyage)[]> {
    let type = 'com.portbase.hinterland.api.common.query.GetVoyages';
    const payload = <GetVoyages>{dateTimeRange: dateTimeRange, excludeVisits: true};
    if (this.pagingSupported) {
      payload.from = this.from;
      payload.maxHits = this.maxItems;
      if (this.filterTerm) {
        (<FindVoyages>payload).term = this.filterTerm;
        type = 'com.portbase.hinterland.api.common.query.FindVoyages';
      }
    }
    return sendQuery(type, payload, {caching: false, showSpinner: !this.unsubscribeHandle})
      .pipe(map(results => results.filter(voyage => AppContext.shouldProcessBargeOrRailVoyage(voyage))
        .sort(voyageComparator)));
  }

  searchOrFilter = (): (any[] | Observable<any[]>) => {
    if (AppContext.isAdmin()) {
      return this.reload();
    } else {
      return this.getFilteredItems();
    }
  };

  public filterItems() {
    let filteredItems = this.items;
    if (AppContext.isFiltering("Rail")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'rail');
    } else if (AppContext.isFiltering("Barge")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'barge');
    }
    if (this.filterTerm) {
      this.generateSearchableItems();
      filteredItems = filteredItems.filter(filterByTerm(this.filterTerm, this.excludedFilterFields, this.searchableItems));
    }
    if (filteredItems.length < 10) this.loadAndRenderNextPage();
    return filteredItems;
  }

  doRender = (voyages: Voyage[]) => {
    this.inOperation = voyages.filter(v => v.voyageStatus === 'IN_OPERATION');
    this.completed = voyages.filter(v => v.voyageStatus === 'COMPLETED');
    this.cancelled = voyages.filter(v => v.voyageStatus === 'CANCELLED');
    this.expected = voyages.filter(v => {
      switch (v.voyageStatus) {
        case 'IN_OPERATION':
        case 'COMPLETED':
        case 'CANCELLED':
          return false;
        default:
          return true;
      }
    });
  };

  editVoyage = (voyage: Voyage) => {
    InjectorProvider.injector.get(Router).navigateByUrl("/rotation/" + voyage.voyageId);
  };

  asVoyages = (items): VoyageUnion[] => <VoyageUnion[]>items;

  onUpdateVoyage = (update: VoyageUnion) => {
    if (AppContext.shouldProcessBargeOrRailVoyage(update) && HinterlandUtils.hasEtaInRange(update, this.dateRange.start, this.dateRange.end)) {
      if (AppContext.isHinterlandTerminalAndDeclarant()) {
        update = HinterlandUtils.removeWhatDeclarantShouldNotSee(update, AppContext.userProfile.organisationShortName);
      }
      if (!!update) {
        const old = this.items.find(v => v.voyageId === update.voyageId);
        if (old) {
          replaceItem(this.items, old, update);
        } else {
          this.items.push(update);
        }
        this.items.sort(voyageComparator);
        this.renderFilteredItems();
      }
    }
  };


}
