import {Component, ElementRef, OnInit} from '@angular/core';
import {
  AddBinnenhavengeldSignalling,
  GetBinnenhavengeldOrganisation
} from '@portbase/hinterland-service-typescriptmodels';
import {checkValidity, cloneObject, now, publishEvent, sendCommand, sendQuery, uuid} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {HinterlandUtils} from '../../../hinterland/hinterland-utils';
import {BinnenhavengeldLocation, BinnenhavengeldShip} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {EditModalService} from "../../../components/modals/edit-modal/edit-modal.service";

@Component({
  selector: 'app-bhg-add-signalling',
  templateUrl: './bhg-add-signalling.component.html',
  styleUrls: ['../../bhg.scss', './bhg-add-signalling.component.css']
})
export class BhgAddSignallingComponent implements OnInit {
  data = {};

  context = AppContext;
  utils = HinterlandUtils;
  command: AddBinnenhavengeldSignalling;

  binnenhavengeldLocations: BinnenhavengeldLocation[];

  errors: string[];

  ngOnInit() {
    this.utils.getBinnenhavengeldLocations().subscribe(locations => this.binnenhavengeldLocations = locations);
    this.errors = [];
  }

  constructor(
    private readonly editModalService: EditModalService,
    private element: ElementRef) {
    this.resetCommand();
  }

  resetCommand = () => {
    this.command = <AddBinnenhavengeldSignalling>{
      signalling: {
        signallingId: uuid(),
        signaller: AppContext.isBinnenhavengeldSuperUserOrAdmin() ? null : AppContext.binnenhavengeldUserProfile?.organisation,
        timestamp: null,
      }
    }
  }

  selectOrganisation = (organisationId: string) => {
    if (organisationId) {
      sendQuery('com.portbase.hinterland.api.binnenhavengeld.GetBinnenhavengeldOrganisation',
        <GetBinnenhavengeldOrganisation>{organisationId: organisationId, requiredRole: 'BinnenhavengeldSignaller'})
        .subscribe(org => {
            if (!org) {
              AppContext.publishErrorMessage("That debitor number does not belong to a signaller");
              delete this.command.signalling.signaller;
            } else {
              this.command.signalling.signaller = org;
            }
          },
          (error) => {
            this.errors.push(error?.error?.error);
          });
    } else {
      delete this.command.signalling.signaller;
    }
  };

  selectShip = (ship: BinnenhavengeldShip) => {
    this.command.signalling.ship = ship;
    this.command.signalling.timestamp = AppContext.isBinnenhavengeldSuperUserOrAdmin() ? null : !!ship ? now() : null;
  };

  submit(close: boolean) {
    if (checkValidity(this.element)) {
      AppContext.clearAlerts();
      this.errors = [];
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.AddBinnenhavengeldSignalling', this.command, () => {
          AppContext.publishSuccessMessage("Signalling sent successfully");
          publishEvent('onBinnenhavengeldSignalling', this.command.signalling);
          if (close) {
            this.editModalService.closeModal();
          } else {
            const command = cloneObject(this.command);
            command.signalling.signallingId = uuid();
            delete command.signalling.timestamp;
            if (!AppContext.isBinnenhavengeldSuperUserOrAdmin()) {
              delete command.signalling.ship;
            }
            this.command = command;
          }
        },
        (error) => {
          this.errors.push(error?.error?.error);
        });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  }

}
