import {Component} from '@angular/core';
import {AbstractOverviewComponent} from '../../hinterland/common/abstract-overview.component';
import {BinnenhavengeldSubscription, DateTimeRange} from '@portbase/hinterland-service-typescriptmodels';
import {Observable} from 'rxjs';
import {sendQuery} from '../../common/utils';
import {BhgAddSubscriptionsComponent} from './bhg-add-subscriptions/bhg-add-subscriptions.component';
import {EditModalService} from "../../components/modals/edit-modal/edit-modal.service";

@Component({
  selector: 'app-bhg-subscriptions',
  templateUrl: './bhg-subscriptions.component.html',
  styleUrls: ['../bhg.scss', './bhg-subscriptions.component.css']
})
export class BhgSubscriptionsComponent extends AbstractOverviewComponent<BinnenhavengeldSubscription> {

  constructor(
    private readonly editModalService: EditModalService,
  ) {
    super();
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<BinnenhavengeldSubscription[]> {
    return sendQuery('com.portbase.hinterland.api.binnenhavengeld.FindBinnenhavengeldSubscriptions',
      {term: this.filterTerm}, {caching: false});
  }

  doRender(entries: BinnenhavengeldSubscription[]) {
  }

  searchOrFilter = (): (BinnenhavengeldSubscription[] | Observable<BinnenhavengeldSubscription[]>) => {
    return this.reload();
  };

  'onBinnenhavengeldSubscriptions' = (subscriptions: BinnenhavengeldSubscription[]) => {
    this.items.splice(0, 0, ...subscriptions);
  }

  addSubscription() {
    this.editModalService.openModal(BhgAddSubscriptionsComponent);
  }

}
