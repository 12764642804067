import {Injectable} from '@angular/core';
import {QueryHandler} from '../common/query-handler';
import terminals from '@portbase/hinterland-service-typescriptmodels/refdata/terminals.json';
import railInterTerminals from '@portbase/hinterland-service-typescriptmodels/refdata/rail-interterminals.json';
import bargeAccessPoints from '@portbase/hinterland-service-typescriptmodels/refdata/bargeaccesspoints.json';
import binnenhavengeldLocations
  from '@portbase/hinterland-service-typescriptmodels/refdata/binnenhavengeld-locations.json';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GetBargeAccessPoints, GetRailInterTerminals, Terminal} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from "../app-context";
import {cloneObject} from '../common/utils';
import {Modality} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {HinterlandUtils} from "../hinterland/hinterland-utils";

@Injectable()
export class HardcodedRefdataQueryHandler extends QueryHandler {

  'com.portbase.hinterland.api.common.query.GetTerminals' = () => {
    let result: Terminal[];
    if (!AppContext.local && (!AppContext.environment || !terminals[AppContext.environment])) {
      result = <Terminal[]><unknown>terminals["default"];
    } else {
      let defaultMap = new Map(terminals["default"].map(i => [i.quayName, i]));
      let environment = AppContext.local ? "local" : AppContext.environment;
      terminals[environment].forEach(t => defaultMap.set(t.quayName, t));
      result = <Terminal[]><unknown>Array.from(defaultMap.values());
    }

    if (AppContext.road) {
      result.sort((a, b) =>
        a.shortName === 'NONPCS_OTHER' ? 1
          : HinterlandUtils.getTerminalDisplayName(a, 'road').localeCompare(HinterlandUtils.getTerminalDisplayName(b, 'road')));
    } else {
      result.sort((a, b) =>
        (a.nonPcs === b.nonPcs) ? a.quayName.localeCompare(b.quayName)
          : a.nonPcs ? 1 : -1)
    }
    result = cloneObject(result);

    for (let terminal of result) {
      if (terminal.nonPcs && !AppContext.road) {
        result.splice(result.indexOf(terminal), 0, <Terminal>{
          quayName: "----------------------",
          allowedModalities: ['barge', 'road'],
          nonPcs: true
        });
        break
      }
    }
    return result;
  };

  'com.portbase.hinterland.api.refdata.query.GetTerminalByCode' = (query: { code: string, modality: Modality }) => {
    const terminals = this['com.portbase.hinterland.api.common.query.GetTerminals']();
    const code = query.code.startsWith("NLRTM") ? query.code.substring(5) : query.code;
    return terminals
      .find(t => (t.smdgCode === code || t.bicsCode === code || t.quayName === code || t.shortName === code)
        && (!query.modality || t.allowedModalities.indexOf(query.modality) >= 0));
  };

  'com.portbase.hinterland.api.common.query.GetRailInterTerminals' = (query: GetRailInterTerminals) => {
    return of(railInterTerminals);
  };

  'com.portbase.hinterland.api.barge.query.GetBargeAccessPoints' = (query: GetBargeAccessPoints) => {
    return of(bargeAccessPoints).pipe(map(v => v[query.portUnCode]));
  };

  "com.portbase.hinterland.api.binnenhavengeld.GetBinnenhavengeldLocations" = () => {
    return of(binnenhavengeldLocations);
  }
}
