import {Component, Input} from "@angular/core";
import {Handling} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import moment from "moment";
import {HandlingWindow} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-deep-sea-overview',
  templateUrl: './deep-sea-overview.component.html',
  styleUrls: ['./deep-sea-overview.component.css']
})
export class DeepSeaOverviewComponent {

  @Input() handling: Handling;

  get eta() {
    return this.handling?.deepSea?.eta ? DeepSeaOverviewComponent.formatDateTime(this.handling?.deepSea.eta) : null;
  }

  get etd() {
    return this.handling?.deepSea?.etd ? DeepSeaOverviewComponent.formatDateTime(this.handling?.deepSea.etd) : null;
  }

  get source() {
    return this.handling?.deepSea?.source;
  }

  get lastUpdated() {
    return this.handling?.deepSea?.lastUpdated ? DeepSeaOverviewComponent.formatDateTime(this.handling?.deepSea.lastUpdated) : null;
  }

  get ultimateReleaseDate() {
    let end = this.handling?.handlingStatus?.window?.end;
    return !!end ? DeepSeaOverviewComponent.formatDateTime(end) : null;
  }

  get formattedWindow() {
    let window = this.handling?.handlingStatus?.window;
    return !!window ? DeepSeaOverviewComponent.formatDateTime(window.start) + " - " + DeepSeaOverviewComponent.formatDateTime(window.end)  : null;
  }

  get shipName() {
    return this.handling?.deepSea?.shipName;
  }

  get imoCode() {
    return this.handling?.deepSea?.imoCode;
  }

  get window(): HandlingWindow {
    if (this.handling?.handlingStatus?.window) {
      return this.handling.handlingStatus.window;
    }
    if (this.handling?.deepSea?.cargoOpeningTime && this.handling?.deepSea?.cargoClosingTime) {
      return {
        start: this.handling.deepSea.cargoOpeningTime,
        end: this.handling.deepSea.cargoClosingTime
      }
    }
    return null;
  }

  private static formatDateTime(dateTime: string) {
    const m = moment(dateTime);
    return m.format(`${m.year() === moment().year() ? 'DD MMM' : 'DD MMM [’]YY'} - HH:mm`);
  }

}
