<div class="mt-3" *ngIf="this.applicationSettings && appContext.isAdmin()">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="admin">Admin</a></li>
    <li class="breadcrumb-item active">Application settings</li>
  </ol>
  
  <div class="declaration-block">
    <h2>Application Settings</h2>
  </div>
  <div class="row mt-1">
    <div class="col-md-12">
      <app-form-group>
        <label><app-info [wide]="true">Activate or de-activate Integral Planning <div class="tooltipContent">
          When disabling integral planning, creating new rotations will result in bilateral planned rotations. Note that this is a system wide setting and will disable integral planning for everyone!<br/>
          Currently integrally planned rotations will continue to receive planning updates.<br/>
          When enabling integral planning, note that a barge operator needs to enable integral planning in the organisation preferences themselves!
        </div></app-info></label>
        <button class="btn btn-info mt-2" style="width: 100px;" (click)="toggleIntegralPlanning()"><span *ngIf="this.applicationSettings.integralPlanningEnabled">Disable</span><span *ngIf="!this.applicationSettings.integralPlanningEnabled">Enable</span></button>
      </app-form-group>
    </div>
  </div>
  
</div>