<div class="p-3 border border-top-0 rounded-bottom mb-3">
  <div *ngIf="shipment.goods.length === 0" class="text-center text-muted" style="padding: 20px 0">
    Goods have not been reported yet.
  </div>

  <div *ngFor="let good of shipment.goods as goods" class="my-2">
    <app-fancy-collapse [collapse]="goodsTemplate" [showOnLoad]="goods.length == 1">
      <div class="row pe-3 g-0 align-items-center">
        <div class="col-auto me-2 p-2">
          <span class="fa fa-fw fa-boxes text-dark"></span>
        </div>
        <div class="col text-dark">
          <span class="align-top text-truncate d-inline-block" style="width: 30rem; max-width: 100%">{{good.unCode | titlecase}}</span>
        </div>
        <div class="col-auto me-n3 d-none d-md-block text-dark">
          <app-cargo-summary [good]="good"></app-cargo-summary>
        </div>
      </div>
    </app-fancy-collapse>

    <ng-template #goodsTemplate>
      <div class="collapse">
        <div class="mx-3 pt-3">
          <app-cargo-goods-details [good]="good"></app-cargo-goods-details>
        </div>
      </div>
    </ng-template>
  </div>
</div>
