import {Component, Input} from '@angular/core';
import {RoadVoyage} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {ActionMode} from "../../../../hinterland/types";

@Component({
  selector: 'app-voyage-panel',
  templateUrl: './app-voyage-panel.html',
  styleUrls: ['./app-voyage-panel.scss']
})
export class VoyagePanelComponent {
  @Input() voyage: RoadVoyage;
  @Input() actionMode: ActionMode;
}
