<div class="bg-white border rounded m-3 p-3">
  <div class="row narrow-gutters align-items-center">
    <div class="col-auto">
      <h4 class="m-0 pe-3"><i class="fa-light fa-ship" [ngClass]="{'text-secondary' : subscription['legacy'] && superOrAdmin}"></i></h4>
    </div>
    <div class="col text-truncate">
      <div class="notranslate">
        {{subscription?.ship?.name}}
      </div>
      <div class="sub-text">
        Ship
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <div class="notranslate">
        {{subscription?.ship?.eniNumber}}
      </div>
      <div class="sub-text notranslate">
        ENI
      </div>
    </div>
    <div class="col d-none d-lg-block" *ngIf="superOrAdmin">
      <div class="text notranslate">
        {{subscription?.skipper?.organisationId}}
      </div>
      <div class="sub-text">
        Skipper
      </div>
    </div>
    <div class="col">
      <div>
        {{subscription?.type | convertCamelCase}}
      </div>
      <div class="sub-text">
        Type
      </div>
    </div>
    <div class="col" style="min-width: 6rem">
      <div class="notranslate">
        {{subscription?.dateRange?.start | timestamp:'DD-MM-YYYY'}}
      </div>
      <div class="sub-text">
        Start
      </div>
    </div>
    <div class="col d-none d-xl-block" style="min-width: 6rem">
      <div class="notranslate">
        {{subscription?.dateRange?.end | timestamp:'DD-MM-YYYY'}} 12:00
      </div>
      <div class="sub-text">
        End
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <div>
        {{subscription?.paymentMethod | convertCamelCase}}
      </div>
      <div class="sub-text">
        Payment
      </div>
    </div>
  </div>
</div>
