import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HinterlandUtils, VoyageUnion} from '../../hinterland-utils';
import {AdditionalCapacity, BargeAccessPoint, BargeVoyage, BargeVoyageData, IntegralPlanningVoyageStatus} from "@portbase/hinterland-service-typescriptmodels";
import {Barge} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {getVoyage} from "../../../common/query-utils";
import {sendQuery} from "../../../common/utils";

@Component({
  selector: 'app-edit-barge-voyage',
  templateUrl: './edit-barge-voyage.component.html',
  styleUrls: ['./edit-barge-voyage.component.css']
})
export class EditBargeVoyageComponent implements OnInit, OnChanges {

  /**
   * 24-11-2021 Potential improvements that can be made:
   *  - Refactor anything related to hasIntegralPlanningProperty()...
   *  - Have Save/Cancel button's as part of component (like most other components do)
   */

  utils = HinterlandUtils;

  @Input() isNew: boolean = false;
  @Input() voyageData: BargeVoyageData;
  @Input() integralPlanningStatus: IntegralPlanningVoyageStatus;
  @Input() integralPlanningEnabled: boolean;
  @Input() previousVoyageId: string;

  bargeAccessPoints: BargeAccessPoint[];
  previousVoyage: BargeVoyage;
  staysInPort: boolean;

  additionalCapacity: AdditionalCapacity = <AdditionalCapacity> {teuCapacity: null, length: null};
  additionalTotalCapacity: number;
  additionalTotalLength: number;

  constructor(public elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.voyageData.barge) {
      this.voyageData.barge = <Barge>{};
    }
    if (!this.voyageData.additionalCapacity) {
      this.voyageData.additionalCapacity = <AdditionalCapacity> {
        teuCapacity: null,
        length: null
      };
    }
    this.utils.getBargeAccessPoints.subscribe(accessPoints => {
      this.bargeAccessPoints = accessPoints;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.voyageData && this.voyageData.additionalCapacity) {
      this.additionalCapacity = this.voyageData.additionalCapacity;
    }
    if (changes.previousVoyageId) {
      if (this.previousVoyageId) {
        getVoyage(this.previousVoyageId).subscribe((voyage: VoyageUnion) => {
          this.previousVoyage = <BargeVoyage> voyage;
        });
      } else {
        this.previousVoyage = null;
      }
    }
    if (changes.integralPlanningEnabled) {
      this.staysInPort = !!this.previousVoyageId && this.integralPlanningEnabled;
    }
  }

  findPreviousVoyages = (term) => HinterlandUtils.findPreviousVoyages(term, (<BargeVoyageData>this.voyageData));

  private initStaysInPort(): boolean {
    const bargeVoyageData = <BargeVoyageData>this.voyageData;
    if (bargeVoyageData && bargeVoyageData.previousVoyageId) {
      sendQuery("com.portbase.hinterland.api.common.query.GetVoyage", {voyageId: bargeVoyageData.previousVoyageId},
        {caching: false, showSpinner: true})
        .subscribe((voyage: BargeVoyage) => {
          if (voyage) {
            this.previousVoyage = voyage;
          }
        });
    }
    return !!bargeVoyageData && this.integralPlanningEnabled && !!bargeVoyageData.previousVoyageId;
  }

  resetEntryPointOrPreviousVoyageId() {
    if (this.staysInPort) {
      this.voyageData.entryPoint = null;
      this.voyageData.exitPoint = null;
    } else {
      this.voyageData.previousVoyageId = null;
    }
  }

  hasIntegralPlanningProperty(name: string) {
    return this.integralPlanningStatus
      && (this.integralPlanningStatus.handled != undefined && this.integralPlanningStatus.handled === false)
      && this.integralPlanningStatus.hasOwnProperty(name) ? this.integralPlanningStatus[name] : false;
  }

  updatePreviousVoyage() {
    if (this.previousVoyage) {
      this.voyageData.previousVoyageId = this.previousVoyage.voyageId;
      this.voyageData.eta = this.previousVoyage.voyageData.etd;
    } else {
      this.voyageData.previousVoyageId = null;
    }
  }

  updateAdditionalCapacity() {
    this.voyageData.additionalCapacity = this.additionalCapacity;
    this.additionalTotalCapacity = Number(this.voyageData.barge.teuCapacity || 0) + Number(this.additionalCapacity.teuCapacity || 0) - Number(this.voyageData.reservedTeu || 0);
    this.additionalTotalLength = Number(this.voyageData.barge.length || 0) + Number(this.additionalCapacity.length || 0);
  }
}
