<div class="row">
  <div class="col-2">
    <app-select [(ngModel)]="type" [options]="getRefDataTypes()"
                (ngModelChange)="entry = undefined; searchResult = undefined"></app-select>
  </div>
  <div class="col">
    <app-search [(ngModel)]="searchResult" [searchFunction]="search" [inputFormatter]="searchResultFormatter"
                (ngModelChange)="entry = searchResult" placeholder="Find a {{type}}"></app-search>
  </div>
  <div class="col-auto">
    <button (click)="newEntry()" *ngIf="!!type" class="btn btn-info" type="button">
      <ng-container *ngIf="searchResult">Duplicate</ng-container>
      <ng-container *ngIf="!searchResult">Add new</ng-container>
      {{type}}
    </button>
  </div>
</div>

<div class="mt-5" [ngSwitch]="type" *ngIf="entry">
  <div class="declaration-block">
    <h2>{{type | titlecase}} details</h2>
  </div>

  <div *ngSwitchCase="'barge'">
    <app-edit-barge [barge]="entry"></app-edit-barge>
  </div>

  <div *ngSwitchCase="'railCarType'">
    <app-edit-rail-car-type [railCarType]="entry"></app-edit-rail-car-type>
  </div>

</div>

<div class="my-2 row" *ngIf="entry">
  <div class="col-auto me-auto">
    <button class="btn btn-danger px-4" type="button" *ngIf="searchResult" (click)="deleteEntry()">
      Delete {{type}}
    </button>
  </div>
  <div class="col-auto">
    <button class="btn btn-outline-info px-4" type="button" (click)="cancel()">
      Cancel
    </button>
  </div>
  <div class="col-auto" *ngIf="!searchResult">
    <button class="btn btn-info px-4" type="button" (click)="addEntry()">
      Add {{type}}
    </button>
  </div>
  <div class="col-auto" *ngIf="searchResult">
    <button class="btn btn-info px-4" type="button" (click)="updateEntry()">
      Save {{type}}
    </button>
  </div>
</div>
