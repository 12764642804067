<div class="voyage-panel">
  <div *ngIf="voyage?.visits">
    <ng-container *ngFor="let visit of voyage.visits">
      <app-visit-panel *ngIf="visit.handlings && visit.handlings.length !== 0 && visit.terminal?.shortName != 'NONPCS_OTHER'"
         [voyage]="voyage"
         [visit]="visit"
         [actionMode]="actionMode">
      </app-visit-panel>
    </ng-container>
  </div>
</div>
