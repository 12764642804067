<!--<pre> {{voyage | json}} </pre>-->

<div [ngClass]="{'disabled': !!eventId}">
  <div *ngIf="!!voyage" class="fieldset">
    <app-collapse [collapse]="voyageCollapse" [keepOpen]="hasRailTemplate">
      <div class="my-3">
        <app-voyage-summary [hasRailTemplate]="hasRailTemplate" [ngClass]="hasRailTemplate ? 'auto-cursor' : ''" [summaryContext]="'detail'"
                            [voyage]="voyage" class="collapsible">
          <h4 *ngIf="!hasRailTemplate" class="m-0 fa fa-chevron-right text-secondary"></h4>
        </app-voyage-summary>
      </div>

      <ng-template #voyageCollapse>
        <div class="collapse">
          <div class="pb-4 pt-2">
            <div class="row g-0 me-n1">
              <div class="col">
                <ul #nav="ngbNav" class="nav-tabs" ngbNav>
                  <li ngbNavItem="1">
                    <a ngbNavLink>{{hasRailTemplate ? 'Rotation template' : 'Voyage'}} details</a>
                    <ng-template ngbNavContent>
                      <div [ngClass]="{'disabled': isDisabled}" class="p-3 border border-top-0 rounded-bottom">

                        <ng-container *ngIf="isModalityBarge">
                          <notifications
                            [alerts]="voyage.integralPlanningStatus?.alerts"
                            [handlingSummary]="voyage.handlingSummary"
                            [visitSummary]="voyage.visitSummary">
                          </notifications>

                          <integral-planning-status
                            [integralPlanningVoyageStatus]="voyage.integralPlanningStatus">
                          </integral-planning-status>

                          <app-edit-barge-voyage #editVoyageComponent
                                                 [integralPlanningEnabled]="voyage.integralPlanningRequested"
                                                 [integralPlanningStatus]="voyage.integralPlanningStatus"
                                                 [previousVoyageId]="voyage.voyageData?.previousVoyageId"
                                                 [voyageData]="voyage.voyageData">
                          </app-edit-barge-voyage>
                        </ng-container>

                        <ng-container *ngIf="isModalityRail">
                          <app-edit-rail-voyage #editVoyageComponent
                                                [hasTemplate]="hasRailTemplate"
                                                [voyageData]="voyage.voyageData">
                          </app-edit-rail-voyage>
                        </ng-container>

                        <ng-container *ngIf="isModalityRoad">
                          <div class="fieldset">
                            <div class="row">
                              <div class="col-md-6">
                                <app-form-group *ngIf="charter">
                                  <label>Charter</label>
                                  <input [(ngModel)]="charter" class="form-control" disabled>
                                </app-form-group>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <div *ngIf="voyageActionsAllowed" class="row mt-3">
                          <div class="col-md-auto me-auto mb-3 mb-md-0">
                            <button *ngIf="!hasArrived" class="btn btn-danger btn-block" data-bs-target="#cancelVoyageModal"
                                    data-bs-toggle="modal" style="min-width: 10rem" tabindex="-1"
                                    type="button">
                              <span>Cancel rotation</span>
                            </button>
                          </div>
                          <div class="col-md-auto">
                            <button (click)="updateVoyage()" class="btn btn-info btn-block" style="min-width: 10rem;"
                                    tabindex="-1" type="button">
                              <span>Save rotation</span>
                            </button>
                          </div>
                        </div>

                      </div>
                    </ng-template>
                  </li>
                  <ng-container *ngIf="isModalityBarge || isModalityRail">
                    <li *ngIf="!hasRailTemplate" ngbNavItem="2">
                      <a ngbNavLink>Cargo declarants</a>
                      <ng-template ngbNavContent>
                        <div [ngClass]="{'disabled': isDisabled}" class="p-3 border border-top-0 rounded-bottom">
                          <app-edit-cargo-declarants
                            [cargoDeclarants]="voyage.cargoDeclarants"
                            [declarant]="voyage.declarant"
                            [voyageId]="voyage.voyageId">
                          </app-edit-cargo-declarants>
                        </div>
                      </ng-template>
                    </li>

                    <li *ngIf="hasRailTemplate" ngbNavItem="2">
                      <a ngbNavLink>Cargo declarants</a>
                      <ng-template ngbNavContent>
                        <div [ngClass]="{'disabled': isDisabled}" class="p-3 border border-top-0 rounded-bottom">
                          <app-edit-rail-voyage-template-cargo-declarants
                            [cargoDeclarants]="voyage.cargoDeclarants"
                            (cargoDeclarantsChange)="onCargoDeclarantsChanged($event)"
                            [declarant]="voyage.declarant">
                          </app-edit-rail-voyage-template-cargo-declarants>
                        </div>
                      </ng-template>
                    </li>

                    <li *ngIf="voyage.integralPlanningRequested" ngbNavItem="3">
                      <a ngbNavLink>
                        <span>Restrictions</span>
                        <app-info>
                          <div class="tooltipContent text-start">
                            <p>Prevents changes to visit order by integral planning.</p>

                            <p>Reefer restrictions also prevents changes to visit times. </p>

                            <p>Restrictions are automatically updated on the move of a visit, the cancellation of a
                              visit
                              and the rejection of a visit.</p>
                          </div>
                        </app-info>
                      </a>

                      <ng-template ngbNavContent>
                        <div [ngClass]="{'disabled': isDisabled}" class="p-3 border border-top-0 rounded-bottom">
                          <app-edit-barge-restrictions
                            [restrictions]="voyage.restrictions"
                            [visits]="voyage.visits"
                            [voyageId]="voyage.voyageId">
                          </app-edit-barge-restrictions>
                        </div>
                      </ng-template>
                    </li>
                  </ng-container>

                  <li *ngIf="!appContext.isPhone() && !eventId && !hasRailTemplate && seeVoyageHistoryAllowed"
                      ngbNavItem="5">
                    <a ngbNavLink>History</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <app-voyage-history
                          [voyage]="voyage">
                        </app-voyage-history>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-collapse>

    <div class="ps-2 pe-1">
      <div class="location row g-0" id="entrypoint">
        <ng-container *ngIf="isModalityBarge">
          <div class="bullet col-auto">
            <span class="fa fa-circle"></span>
          </div>
          <div class="col item">
            {{voyage.voyageData.eta | timestamp:'D MMMM YYYY' }}{{voyage.voyageData.entryPoint ? ', ' + voyage.voyageData.entryPoint.name : ''}}
          </div>
        </ng-container>

        <ng-container *ngIf="isModalityRail">
          <div class="bullet col-auto">
            <span class="fa fa-sign-in-alt text-secondary"></span>
          </div>
        </ng-container>

        <ng-container *ngIf="isModalityRoad">
          <div class="bullet col-auto">
            <span class="fa fa-circle spacer"></span>
          </div>
        </ng-container>
      </div>

      <div [dragulaModel]="visitsForRestrictions" dragula="restrictions" id="visits">
        <ng-container *ngFor="let sortedIndex of sortedVisitIndices">
          <app-visit-details (visitDeletedEventEmitter)="deleteVisit($event)"
                             (visitEtaUpdatedEventEmitter)="sortVisits()"
                             *ngIf="voyage.visits[sortedIndex] && !voyage.visits[sortedIndex]['visitCancelled']"
                             [declareCargoAllowed]="declareCargoAllowed"
                             [hasRailTemplate]="hasRailTemplate"
                             [integralPlanningRequested]="voyage.integralPlanningRequested"
                             [nextlogicEnabled]="voyage.nextlogicEnabled"
                             [openHandlingId]="openHandlingId"
                             [openVisitId]="openVisitId"
                             [originalVoyageId]="voyage['originalVoyageId']"
                             [shareWithNextlogicRequested]="voyage.shareWithNextlogicRequested"
                             [visitDeclarationStatus]="voyage.visits[sortedIndex]['visitDeclarationStatus']"
                             [visitDeclaration]="voyage.visits[sortedIndex]['visitDeclaration']"
                             [visitDeclarations]="voyage.visits[sortedIndex]['visitDeclarations']"
                             [visitStatus]="voyage.visits[sortedIndex]['status']"
                             [visitTerminal]="voyage.visits[sortedIndex]['terminal']"
                             [visit]="voyage.visits[sortedIndex]"
                             [visits]="voyage.visits"
                             [voyageCancelled]="voyage.cancelled"
                             [voyageCargoDeclarants]="voyage.cargoDeclarants"
                             [voyageData]="voyage.voyageData"
                             [voyageDeclarantShortname]="voyage.declarant.shortName"
                             [voyageDeclarant]="voyage.declarant"
                             [voyageId]="voyage.voyageId"
                             [voyageStatus]="voyage.voyageStatus"
                             [voyage]="voyage">
          </app-visit-details>
        </ng-container>

        <div class="location row g-0" id="exitpoint">
          <ng-container *ngIf="isModalityBarge">
            <div class="bullet col-auto"><span class="fa fa-circle"></span></div>
            <div class="col item">
              {{voyage.voyageData.etd | timestamp:'D MMMM YYYY' }}{{voyage.voyageData.etd && voyage.voyageData.exitPoint ? ', ' : ''}}{{voyage.voyageData.exitPoint?.name}}
            </div>
          </ng-container>
          <ng-container *ngIf="isModalityRail">
            <div class="bullet col-auto">
              <span class="fa fa-sign-out-alt text-secondary"></span>
            </div>
          </ng-container>
          <ng-container *ngIf="isModalityRoad">
            <div class="bullet col-auto">
              <span class="fa fa-circle spacer"></span>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="appContext.isHinterlandDeclarant()" class="row mt-4">
        <div class="col-md-auto">
          <button (click)="addVisit()" class="btn btn-info btn-block" tabindex="-1">
            <span class="pe-2 fa fa-plus-circle"></span> <span>Add visit {{hasRailTemplate ? 'template' : ''}}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="modal fade" id="cancelVoyageModal" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Are you sure that you want to cancel this rotation?</h5>
          </div>
          <div class="modal-body">
            <p>Visits attached to this rotation will be cancelled automatically.</p>
          </div>
          <div class="modal-footer">
            <div class="container-fluid px-0">
              <div class="row g-0 justify-content-between">
                <div class="col-auto">
                  <button class="btn btn-secondary" data-bs-dismiss="modal" style="width: 8rem" type="button">No</button>
                </div>
                <div class="col-auto">
                  <button (click)="cancelVoyage()" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem"
                          type="button">Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
