import {RouterModule, Routes} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injector, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppPreferencesComponent} from './preferences/app-preferences/app-preferences.component';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InjectorProvider} from './common/injector-provider';
import {EventGateway} from './common/event-gateway';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {SelectComponent} from './common/select/select.component';
import {SearchComponent} from './common/search/search.component';
import {FormGroupComponent} from './common/form-group/form-group.component';
import {MultiselectComponent} from './common/multiselect/multiselect.component';
import {YesNoComponent} from './common/yes-no/yes-no.component';
import {DragulaModule} from 'ng2-dragula';
import {DecimalNumberDirective} from './common/number-input/decimal-number.directive';
import {InfoComponent} from './common/info/info.component';
// noinspection ES6UnusedImports
import * as bootstrap from 'bootstrap';
import {StatusAlertComponent} from './common/status-alert/status-alert.component';
import {HardcodedRefdataQueryHandler} from './refdata/hardcoded-refdata-query-handler';
import {DefaultValueDirective} from './common/default-value.directive';
import {CookieModule} from 'ngx-cookie';
import {ValidatorDirective} from './common/validator.directive';
import {SentryErrorHandler} from './sentry-error-handler';
import {FilterComponent} from './common/search/filter/filter.component';
import {ReportIssueComponent} from './report-issue/report-issue.component';
import {RefdataImportComponent} from './refdata/refdata-import/refdata-import.component';
import {RefdataComponent} from './refdata/refdata.component';
import {RefdataUpdateComponent} from './refdata/refdata-update/refdata-update.component';
import {BollardComponent} from "./preferences/bollard/bollard.component";
import {HandlingStatusComponent} from './hinterland/common/handlings/handling-status/handling-status.component';
import {HandlingSummaryComponent} from './hinterland/common/handlings/handling-summary/handling-summary.component';
import {TooltipComponent} from './common/tooltip/tooltip.component';
import {OperatorOverviewComponent} from './hinterland/operator-overview/operator-overview.component';
import {CreateVoyageComponent} from './hinterland/common/create-voyage/create-voyage.component';
import {EditBargeVoyageComponent} from './hinterland/barge/edit-voyage-data/edit-barge-voyage.component';
import {VoyageDetailsComponent} from './hinterland/common/voyage-details/voyage-details.component';
import {VoyageSummaryComponent} from './hinterland/common/voyage-summary/voyage-summary.component';
import {EditBargeVisitTerminalComponent} from './hinterland/barge/edit-visit/edit-barge-visit-terminal.component';
import {HandlingsComponent} from './hinterland/common/handlings/handlings.component';
import {EditHandlingComponent} from "./hinterland/common/handlings/edit-handling/edit-handling.component";
import {TerminalOverviewComponent} from './hinterland/terminal-overview/terminal-overview.component';
import {HandlingTotalComponent} from './hinterland/common/handling-total/handling-total.component';
import {NavigationComponent} from './navigation/navigation.component';
import {AlertingComponent} from './alerting/alerting.component';
import {CompareComponent} from './common/compare/compare.component';
import {FormattedDateRangeComponent} from './common/datetime/formatted-date-range/formatted-date-range.component';
import {VisitDateRangeComponent} from './common/datetime/visit-date-range/visit-date-range.component';
import {MessageOverviewComponent} from "./message-overview/message-overview.component";
import {VisitHandlingsComponent} from './hinterland/common/visit-handlings/visit-handlings.component';
import {VisitStatusComponent} from './hinterland/common/handlings/visit-status/visit-status.component';
import {UserMenuComponent} from './navigation/user-menu/user-menu.component';
import {HandlingAdminComponent} from "./hinterland/common/handlings/handling-admin/handling-admin.component";
import {VisitAdminComponent} from "./hinterland/common/visit-admin/visit-admin.component";
import {HandlingFilterComponent} from "./hinterland/common/handling-filter/handling-filter.component";
import {VoyageHistoryComponent} from './hinterland/common/voyage-details/voyage-history/voyage-history.component';
import {InfiniteScrollerComponent} from './common/infinite-scroller/infinite-scroller.component';
import {EditBargeComponent} from "./refdata/edit-barge/edit-barge.component";
import {RibbonComponent} from "./ribbon/ribbon.component";
import {FixedWindowComponent} from "./preferences/fixed-window/fixed-window.component";
import {NotificationsComponent} from "./hinterland/barge/notifications/notifications.component";
import {ScrollerComponent} from './common/scroller/scroller.component';
import {HandlingOverviewComponent} from './hinterland/handling-overview/handling-overview.component';
import {SendEmailTokenComponent} from './navigation/send-email-token/send-email-token.component';
import {
  IntegralPlanningStatusComponent,
} from "./hinterland/barge/integral-planning-status/integral-planning-status.component";
import {HistoryFilter} from "./hinterland/common/history-filter/history-filter.component";
import {HistoryOverviewComponent} from "./hinterland/common/history-overview/history-overview.component";
import {MessageLogComponent} from "./hinterland/common/message-log/message-log.component";
import {EditCargoDeclarantsComponent} from './hinterland/barge/edit-cargo-declarants/edit-cargo-declarants.component';
import {
  EditBargeRestrictionsComponent,
} from './hinterland/barge/edit-barge-restrictions/edit-barge-restrictions.component';
import {EditRailVoyageComponent} from './hinterland/rail/edit-rail-voyage/edit-rail-voyage.component';
import {EditRailVisitComponent} from './hinterland/rail/edit-rail-visit/edit-rail-visit.component';
import {
  HandlingStatusSummaryComponent,
} from "./hinterland/common/handlings/handling-status/handling-status-summary/handling-status-summary.component";
import {PaginationComponent} from './common/pagination/pagination.component';
import {CargoShipmentComponent} from './hinterland/shipment/cargo-shipment/cargo-shipment.component';
import {RefdataExportComponent} from "./refdata/refdata-export/refdata-export.component";
import {ApplicationSettingsComponent} from "./admin/application-settings/application-settings.component";
import {FancyCollapseComponent} from './common/fancy-collapse/fancy-collapse.component';
import {OrganisationComponent} from "./support/organisation/organisation.component";
import {DropdownToggleFilterComponent} from "./common/search/dropdown-toggle-filter/dropdown-toggle-filter.component";
import {TerminalsComponent} from "./support/terminals/terminals.component";
import {SupportComponent} from "./support/support.component";
import {AdminComponent} from "./admin/admin.component";
import {UsersComponent} from "./support/users/users.component";
import {CastTerminalPipe} from "./common/casting/cast-terminal.pipe";
import {CastTerminalsPipe} from "./common/casting/cast-terminals.pipe";
import {CastTerminalSettingsPipe} from "./common/casting/cast-terminal-settings.pipe";
import {
  OrganisationPreferencesComponent,
} from "./support/organisation/organisation-preferences/organisation-preferences.component";
import {RailVoyageTemplateComponent} from "./preferences/rail-voyage-template/rail-voyage-template.component";
import {VisitDetailsComponent} from './hinterland/common/visit-details/visit-details.component';
import {EditBargeVisitOperatorComponent} from "./hinterland/barge/edit-visit/edit-barge-visit-operator.component";
import {TimetableComponent} from "./preferences/timetable/timetable.component";
import {ModalityToggleComponent} from "./navigation/modality-toggle/modality-toggle.component";
import {MovementComponent} from './hinterland/shipment/movement/movement.component';
import {StopComponent} from './hinterland/shipment/stop/stop.component';
import {CargoSummaryComponent} from './hinterland/shipment/cargo-summary/cargo-summary.component';
import {CompareDirective} from './common/compare/compare.directive';
import {CargoContainerComponent} from './hinterland/shipment/cargo-container/cargo-container.component';
import {CargoGoodsComponent} from './hinterland/shipment/cargo-goods/cargo-goods.component';
import {
  CargoGoodsDetailsComponent,
} from "./hinterland/shipment/cargo-goods/cargo-goods-details/cargo-goods-details.component";
import {ShipmentOverviewComponent} from "./hinterland/shipment/shipment-overview/shipment-overview.component";
import {ArrivalsOverviewComponent} from './hinterland/shipment/arrivals-overview/arrivals-overview.component';
import {
  ContainerStatusComponent,
} from './hinterland/shipment/cargo-summary/container-status/container-status.component';
import {CargoShipmentsComponent} from './hinterland/shipment/cargo-shipments.component';
import {ShipmentsComponent} from './hinterland/shipment/shipments/shipments.component';
import {VesselComponent} from './hinterland/shipment/modality/vessel.component';
import {PaginationBackendComponent} from './common/pagination-backend/pagination-backend.component';
import {RailOperatorsComponent} from "./support/railoperators/railoperators.component";
import {DeepSeaOverviewComponent} from "./hinterland/barge/deepsea-overview/deep-sea-overview.component";
import {EditRailVisitOperatorComponent} from "./hinterland/rail/edit-rail-visit/edit-rail-visit-operator.component";
import {TransportOrderComponent} from './hinterland/shipment/transport-order/transport-order.component';
import {DaysComponent} from './common/days/days.component';
import {NewMcaComponent} from "./new-hinterland/new-mca.component";
import {MainPanelRoadComponent} from "./new-hinterland/view-components/main-panel-road/main-panel-road.component";
import {
  RoadNavbarTopComponent,
} from "./new-hinterland/view-components/main-panel-road/road-navbar-top/road-navbar-top.component";
import {
  RoadNavbarLeftComponent,
} from "./new-hinterland/view-components/main-panel-road/road-navbar-left/road-navbar-left.component";
import {VisitPanelComponent} from "./new-hinterland/view-components/main-panel-road/visit-panel/app-visit-panel";
import {
  HandlingPanelComponent,
} from "./new-hinterland/view-components/main-panel-road/handling-panel/app-handling-panel";
import {
  RemainOnTruckComponent,
} from "./new-hinterland/view-components/main-panel-road/remain-on-truck-panel/app-remain-on-truck-panel";
import {
  HandlingStatusPanelComponent,
} from "./new-hinterland/view-components/main-panel-road/handling-status-panel/app-handling-status-panel";
import {VoyagePanelComponent} from "./new-hinterland/view-components/main-panel-road/voyage-panel/app-voyage-panel";
import {
  AddHandlingOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/add-handling-offcanvas/app-add-handling-offcanvas";
import {
  UpdateVisitOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/update-visit-offcanvas/app-update-visit-offcanvas";
import {
  OverviewFilterComponent,
} from "./new-hinterland/view-components/main-panel-road/road-navbar-top/search/overview-filter.component";
import {
  ViewHandlingOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/view-handling-details-offcanvas/app-view-handling-offcanvas";
import {ActionPanelComponent} from "./new-hinterland/view-components/main-panel-road/action-panel/action-panel";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {BhgSignedInComponent} from './binnenhavengeld/bhg-signed-in.component';
import {BhgServicesComponent} from './binnenhavengeld/bhg-menu/bhg-services/bhg-services.component';
import {BhgMenuComponent} from './binnenhavengeld/bhg-menu/bhg-menu.component';
import {
  BhgEditSubscriptionComponent,
} from './binnenhavengeld/bhg-subscriptions/bhg-add-subscription/bhg-edit-subscription.component';
import {BhgSubscriptionsComponent} from './binnenhavengeld/bhg-subscriptions/bhg-subscriptions.component';
import {
  BhgSubscriptionComponent,
} from './binnenhavengeld/bhg-subscriptions/bhg-subscription/bhg-subscription.component';
import {
  BhgAddSubscriptionsComponent,
} from './binnenhavengeld/bhg-subscriptions/bhg-add-subscriptions/bhg-add-subscriptions.component';
import {ConvertCamelCasePipe} from './common/convert-camel-case.pipe';
import {
  BhgAddSignallingComponent,
} from './binnenhavengeld/bhg-signallings/bhg-add-signalling/bhg-add-signalling.component';
import {BhgSignallingsComponent} from './binnenhavengeld/bhg-signallings/bhg-signallings.component';
import {BhgSignallingComponent} from './binnenhavengeld/bhg-signallings/bhg-signalling/bhg-signalling.component';
import {BhgLoginComponent} from './binnenhavengeld/bhg-login/bhg-login.component';
import {LanguageSelectorComponent} from './common/language-selector/language-selector.component';
import {
  BhgRequestAccountComponent,
} from './binnenhavengeld/bhg-login/bhg-request-account/bhg-request-account.component';
import {
  BhgAccountDetailsComponent,
} from './binnenhavengeld/bhg-login/bhg-account-details/bhg-account-details.component';
import {
  BhgCompanyDetailsComponent,
} from './binnenhavengeld/bhg-login/bhg-account-details/bhg-company-details/bhg-company-details.component';
import {
  BhgForgotPasswordComponent,
} from './binnenhavengeld/bhg-login/bhg-forgot-password/bhg-forgot-password.component';
import {BhgUpdateAccountComponent} from './binnenhavengeld/bhg-login/bhg-update-account/bhg-update-account.component';
import {BhgSetPasswordComponent} from './binnenhavengeld/bhg-login/bhg-set-password/bhg-set-password.component';
import {BhgUsersComponent} from './binnenhavengeld/bhg-admin/bhg-users.component';
import {BhgEditUserComponent} from './binnenhavengeld/bhg-admin/bhg-edit-user/bhg-edit-user.component';
import {BhgSignedOutComponent} from './binnenhavengeld/bhg-signed-out/bhg-signed-out.component';
import {BhgOrganisationComponent} from './binnenhavengeld/bhg-login/bhg-organisation/bhg-organisation.component';
import {BhgShipComponent} from './binnenhavengeld/bhg-subscriptions/bhg-ship/bhg-ship.component';
import {
  BhgNewSubscriptionComponent,
} from './binnenhavengeld/bhg-subscriptions/bhg-new-subscription/bhg-new-subscription.component';
import {BhgAcceptTermsComponent} from './binnenhavengeld/bhg-login/bhg-accept-terms/bhg-accept-terms.component';
import {FormComponent} from './common/form/form.component';
import {
  HandlingActionPanelComponent,
} from "./new-hinterland/view-components/main-panel-road/handling-action-panel/handling-action-panel";
import {TranslateDirective} from './common/translate.directive';
import {
  DragAndDropDirective,
} from "./new-hinterland/view-components/offcanvas-panel/add-handling-offcanvas/drag-and-drop/drag-and-drop.directive";
import {BhgDeleteUserComponent} from './binnenhavengeld/bhg-admin/bhg-delete-user/bhg-delete-user.component';
import {BhgUserComponent} from './binnenhavengeld/bhg-admin/bhg-user/bhg-user.component';

import {
  EditHandlingOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/edit-handling-offcanvas/app-edit-handling-offcanvas";
import {
  AddVisitOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/add-visit-offcanvas/app-add-visit-offcanvas";
import {
  BhgEditApplicationConfigComponent,
} from './binnenhavengeld/bhg-admin/bhg-edit-application-config/bhg-edit-application-config.component';
import {
  ViewHandlingHistoryOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/view-handling-history-offcanvas/app-view-handling-history-offcanvas";
import {
  ViewMessageHistoryOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/view-message-history-offcanvas/app-view-message-history-offcanvas";
import {
  ChangeVesselOffCanvasComponent,
} from "./new-hinterland/view-components/offcanvas-panel/change-vessel-offcanvas/change-vessel-offcanvas";
import {ConfirmationToggleComponent} from './common/confirmation-toggle/confirmation-toggle.component';
import {
  CancelHandlingConfirmationComponent,
} from "./new-hinterland/view-components/dialog/cancel-handling-confirmation-dialog/cancel-handling-confirmation.component";
import {
  EmailHandlingComponent,
} from "./new-hinterland/view-components/dialog/email-handling-dialog/email-handling.component";
import {
  DetachHandlingConfirmationComponent,
} from "./new-hinterland/view-components/dialog/detach-handling-confirmation-dialog/detach-handling-confirmation.component";
import {
  MoveHandlingConfirmationComponent,
} from "./new-hinterland/view-components/dialog/move-handling-confirmation-dialog/move-handling-confirmation.component";
import {TransactionsComponent} from "./transactions/transactions.component";
import {ShipmentHistoryComponent} from './hinterland/shipment/shipment-history/shipment-history.component';
import {optionalModules} from "../build-specifics";
import {TrainCompositionComponent} from './hinterland/rail/train-composition/train-composition.component';
import {
  EditRailVoyageTemplateCargoDeclarantsComponent
} from "./hinterland/barge/edit-rail-voyage-template-cargo-declarants/edit-rail-voyage-template-cargo-declarants.component";
import {ViewRoadVisitComponent} from "./hinterland/road/view-road-visit/view-road-visit.component";
import {VisitHandlingsOperatorComponent} from "./hinterland/common/visit-handlings/visit-handlings-operator.component";
import {DateRangeDialogComponent} from "./new-hinterland/view-components/dialog/date-range-dialog/date-range-dialog";
import {
  CopyVisitAsTextDialogComponent
} from "./new-hinterland/view-components/dialog/copy-visit-as-text-dialog/copy-visit-as-text-dialog";
import {
  BhgSanctionRegulationsModalComponent
} from "./binnenhavengeld/bhg-subscriptions/bhg-sanction-regulations-modal/bhg-sanction-regulations-modal.component";
import {EditRailCarTypeComponent} from "./refdata/edit-rail-car-type/edit-rail-car-type.component";

import {MainPanelComponent} from './components/layout/main-panel/main-panel.component';
import {DateTimeComponent} from "./components/date-time/date-time.component";
import {TimestampPipe} from "./components/date-time/pipes/timestamp.pipe";
import {TimePipe} from "./components/date-time/pipes/time.pipe";
import {FormFieldComponent} from "./components/user-forms/form-field/form-field.component";
import {NewSelectComponent} from "./components/user-input/new-select/new-select.component";
import {ToggleComponent} from "./components/user-input/toggle/toggle.component";
import {DatepickerRangeComponent} from "./common/date-picker-range/date-picker-range";
import {RadioComponent} from "./components/user-input/radio/radio.component";
import {SingleClickDirective} from './common/single-click.directive';
import {DateRangeComponent} from './components/user-input/date-range/date-range.component';
import {EditModalComponent} from "./components/modals/edit-modal/edit-modal.component";
import {ConfirmationModalComponent} from "./components/modals/confirmation-modal/confirmation-modal.component";
import {ErrorComponent} from "./components/errors/error/error.component";
import {BhgSupportComponent} from "./binnenhavengeld/bhg-support/bhg-support.component";
import {ButtonComponent} from "./components/button/button.component";
import {IconButtonComponent} from "./components/icon-button/icon-button.component";
import {DialogComponent} from "./components/dialog/dialog.component";
import {ContainerDetectiveComponent} from "./support/container-detective/container-detective.component";
import {CopinoComparatorComponent} from "./support/copino-comparator/copino-comparator.component";
import {CollapseComponent} from "./common/collapse/collapse.component";
import {AlertComponent} from "./common/alert/alert.component";

const appRoutes: Routes = [
  {path: '', component: AppComponent},
  {path: 'rotation/:voyageId', component: VoyageDetailsComponent},
  {path: 'road-archived/history/:voyageId', component: VoyageDetailsComponent},
  {path: 'preferences/application', component: AppPreferencesComponent},
  {path: 'preferences/bollard', component: BollardComponent},
  {path: 'preferences/fixedwindow', component: FixedWindowComponent},
  {path: 'preferences/timetable', component: TimetableComponent},
  {path: 'preferences/railvoyagetemplate', component: RailVoyageTemplateComponent},
  {path: 'refdata', component: RefdataComponent},
  {path: 'newRotation', component: CreateVoyageComponent},
  {path: 'rotations', component: OperatorOverviewComponent},
  {path: 'handlings', component: HandlingOverviewComponent},
  {path: 'road', component: NewMcaComponent},
  {path: 'road-legacy', redirectTo: '/road'},
  {path: 'road-archived', redirectTo: '/road'},
  {path: 'terminal-overview', component: TerminalOverviewComponent},
  {path: 'message-overview', component: MessageOverviewComponent},
  {path: 'message-overview/road', component: MessageOverviewComponent},
  {path: 'shipments', component: CargoShipmentsComponent},
  {path: 'admin', component: AdminComponent},
  {path: 'support', component: SupportComponent},
  {path: 'transactions', component: TransactionsComponent},
  {path: 'admin/application-settings', component: ApplicationSettingsComponent},
  {path: 'support/organisation-preferences', component: OrganisationComponent},
  {path: 'support/terminals', component: TerminalsComponent},
  {path: 'support/railoperators', component: RailOperatorsComponent},
  {path: 'support/container-detective', component: ContainerDetectiveComponent},
  {path: 'support/users', component: UsersComponent},
  {path: 'support/copino-comparator', component: CopinoComparatorComponent},
  {
    path: 'port-alert',
    loadChildren: () => import('./port-alert/port-alert.routing'),
  },
  {
    path: 'binnenhavengeld',
    component: BhgSignedInComponent,
    children: [
      {path: 'update-account', component: BhgUpdateAccountComponent},
      {path: 'subscriptions', component: BhgSubscriptionsComponent},
      {path: 'signallings', component: BhgSignallingsComponent},
      {path: 'users', component: BhgUsersComponent},
    ],
  },
  {
    path: 'binnenhavengeld',
    component: BhgSignedOutComponent,
    children: [
      {path: 'login', component: BhgLoginComponent},
      {path: 'request-account', component: BhgRequestAccountComponent},
      {path: 'forgot-password', component: BhgForgotPasswordComponent},
      {path: 'set-password', component: BhgSetPasswordComponent},
      {path: 'accept-terms', component: BhgAcceptTermsComponent},
      {path: '**', redirectTo: ''},
    ],
  },
  {path: '**', redirectTo: ''},
];

@NgModule({
  declarations: [
    AppComponent,
    AppPreferencesComponent,
    BollardComponent,
    FixedWindowComponent,
    TimetableComponent,
    RailVoyageTemplateComponent,
    SelectComponent,
    SearchComponent,
    FormGroupComponent,
    MultiselectComponent,
    YesNoComponent,
    DecimalNumberDirective,
    InfoComponent,
    StatusAlertComponent,
    DefaultValueDirective,
    FilterComponent,
    ReportIssueComponent,
    TooltipComponent,
    HandlingFilterComponent,
    InfiniteScrollerComponent,
    ScrollerComponent,
    AlertingComponent,
    CompareComponent,
    FormattedDateRangeComponent,
    ApplicationSettingsComponent,
    OrganisationComponent,
    TerminalsComponent,
    RailOperatorsComponent,
    UsersComponent,
    SupportComponent,
    TransactionsComponent,
    AdminComponent,
    RefdataImportComponent,
    RefdataComponent,
    RefdataUpdateComponent,
    EditBargeComponent,
    EditRailCarTypeComponent,
    RefdataExportComponent,
    DateRangeDialogComponent,
    CopyVisitAsTextDialogComponent,
    MessageOverviewComponent,
    MessageLogComponent,
    HandlingStatusComponent,
    HandlingStatusSummaryComponent,
    HandlingSummaryComponent,
    HandlingAdminComponent,
    OperatorOverviewComponent,
    CreateVoyageComponent,
    EditCargoDeclarantsComponent,
    EditRailVoyageTemplateCargoDeclarantsComponent,
    EditBargeRestrictionsComponent,
    EditBargeVoyageComponent,
    VoyageDetailsComponent,
    VoyageSummaryComponent,
    EditBargeVisitTerminalComponent,
    HandlingsComponent,
    EditHandlingComponent,
    TerminalOverviewComponent,
    HandlingTotalComponent,
    NavigationComponent,
    RibbonComponent,
    VisitDateRangeComponent,
    VisitHandlingsComponent,
    VisitStatusComponent,
    VisitAdminComponent,
    ModalityToggleComponent,
    UserMenuComponent,
    VoyageHistoryComponent,
    NotificationsComponent,
    IntegralPlanningStatusComponent,
    HandlingOverviewComponent,
    SendEmailTokenComponent,
    HistoryFilter,
    HistoryOverviewComponent,
    EditRailVoyageComponent,
    EditRailVisitComponent,
    TrainCompositionComponent,
    PaginationComponent,
    DropdownToggleFilterComponent,
    OrganisationPreferencesComponent,
    CastTerminalPipe,
    CastTerminalsPipe,
    CastTerminalSettingsPipe,
    CargoShipmentComponent,
    MovementComponent,
    StopComponent,
    CargoSummaryComponent,
    CompareDirective,
    CargoContainerComponent,
    CargoGoodsComponent,
    CargoGoodsDetailsComponent,
    ShipmentOverviewComponent,
    ArrivalsOverviewComponent,
    ContainerStatusComponent,
    VisitDetailsComponent,
    EditBargeVisitOperatorComponent,
    EditRailVisitOperatorComponent,
    ViewRoadVisitComponent,
    VisitHandlingsOperatorComponent,
    CargoShipmentsComponent,
    ShipmentsComponent,
    VesselComponent,
    PaginationBackendComponent,
    VesselComponent,
    TransportOrderComponent,
    DaysComponent,
    DaysComponent,
    VesselComponent,
    DeepSeaOverviewComponent,
    NewMcaComponent,
    MainPanelRoadComponent,
    RoadNavbarTopComponent,
    RoadNavbarLeftComponent,
    VisitPanelComponent,
    HandlingPanelComponent,
    RemainOnTruckComponent,
    HandlingStatusPanelComponent,
    VoyagePanelComponent,
    AddHandlingOffCanvasComponent,
    ViewHandlingOffCanvasComponent,
    EditHandlingOffCanvasComponent,
    ChangeVesselOffCanvasComponent,
    AddVisitOffCanvasComponent,
    UpdateVisitOffCanvasComponent,
    OverviewFilterComponent,
    BhgSanctionRegulationsModalComponent,
    BhgSignedInComponent,
    BhgServicesComponent,
    BhgMenuComponent,
    BhgEditSubscriptionComponent,
    BhgSubscriptionsComponent,
    BhgSupportComponent,
    BhgSubscriptionComponent,
    BhgAddSubscriptionsComponent,
    ConvertCamelCasePipe,
    BhgAddSignallingComponent,
    BhgSignallingsComponent,
    BhgSignallingComponent,
    BhgLoginComponent,
    LanguageSelectorComponent,
    BhgRequestAccountComponent,
    BhgAccountDetailsComponent,
    BhgCompanyDetailsComponent,
    BhgForgotPasswordComponent,
    BhgUpdateAccountComponent,
    BhgSetPasswordComponent,
    BhgUsersComponent,
    BhgUserComponent,
    BhgEditUserComponent,
    BhgSignedOutComponent,
    OverviewFilterComponent,
    ActionPanelComponent,
    HandlingActionPanelComponent,
    BhgOrganisationComponent,
    BhgShipComponent,
    BhgNewSubscriptionComponent,
    BhgAcceptTermsComponent,
    FormComponent,
    TranslateDirective,
    DragAndDropDirective,
    BhgDeleteUserComponent,
    CancelHandlingConfirmationComponent,
    DetachHandlingConfirmationComponent,
    MoveHandlingConfirmationComponent,
    EmailHandlingComponent,
    BhgEditApplicationConfigComponent,
    ViewHandlingHistoryOffCanvasComponent,
    ViewMessageHistoryOffCanvasComponent,
    ConfirmationToggleComponent,
    ShipmentHistoryComponent,
    ContainerDetectiveComponent,
    AlertComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {enableTracing: false}),
    CookieModule.withOptions(),
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 600000,
      extendedTimeOut: 0,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    EditModalComponent,
    ConfirmationModalComponent,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    DragulaModule.forRoot(),
    ReactiveFormsModule,
    NgbNavModule,
    ...optionalModules,
    MainPanelComponent,
    DateTimeComponent,
    TimestampPipe,
    TimePipe,
    FormFieldComponent,
    NewSelectComponent,
    ToggleComponent,
    DatepickerRangeComponent,
    RadioComponent,
    SingleClickDirective,
    DateRangeComponent,
    ButtonComponent,
    IconButtonComponent,
    ErrorComponent,
    DialogComponent,
    FancyCollapseComponent,
    CollapseComponent,
    ValidatorDirective
  ],
  providers: [
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    TitleCasePipe,
    ConvertCamelCasePipe,
    CastTerminalPipe,
    CastTerminalsPipe,
    CastTerminalSettingsPipe,
    EventGateway,
    HardcodedRefdataQueryHandler,
  ],
  exports: [
    DefaultValueDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    InjectorProvider.injector = injector;
    injector.get(HardcodedRefdataQueryHandler);
  }
}
