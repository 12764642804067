<div id="MAIN" class="container-fluid p-0">

  <div id="INPUT" class="search pb-5">
    <div class="search-input">
      <input [(ngModel)]="handlingId" type="text" class="form-control lg"
             placeholder="Search specific copinos by handling id ...">

    </div>
    <div class="search-differences">
      <input [(ngModel)]="differencesOnly"
             class="form-check-input"
             id="select-all-handlings-checkbox"
             type="checkbox">
      Warnings only
    </div>
    <div class="search-button">
      <button (click)="search()" type="button" class="btn btn-info">Search</button>
    </div>
  </div>

  <ng-container *ngFor="let item of result">
    <app-fancy-collapse [collapse]="details">
      <div class="m-2 info d-flex">
        <div style="width: 80%">{{ item.handlingId }}</div>
        <div style="width: 10%">{{ item.newCopinos.length }} - {{ item.legacyCopinos.length }}
        </div>
        <div style="width: 10%">
          <span *ngIf="!item.sameSize || !item.samePayloads" class="fa fa-triangle-exclamation"></span>
          <span *ngIf="item.sameSize && item.samePayloads" class="fa fa-circle-check"></span>
        </div>
      </div>
    </app-fancy-collapse>

    <ng-template #details>
      <div class="collapse d-flex">
        <div class="new">
          <span>New COPINO Handler</span>
          <ng-container *ngFor="let copino of item.newCopinos">
            <div class="copino">
              <div class="data data-header">{{ copino.emhHeader?.msgFunction }}</div>
              <div class="data">Process id: {{ copino.processId }}</div>
              <div class="data">Message function: {{ copino.emhHeader?.msgFunction }}</div>
              <div class="data">Modality: {{ copino.modality }}</div>
              <div class="data">Terminal: {{ copino.terminal }}</div>
              <div class="data">Pre-notification: {{ copino.data?.preNotification }}</div>
              <div class="data">Message id: {{ copino.data?.messageId }}</div>
              <div class="data">Declarant: {{ copino.data?.declarantShortName }}</div>
            </div>
          </ng-container>
        </div>
        <div class="legacy">
          <span>Legacy COPINO sender</span>
          <ng-container *ngFor="let copino of item.legacyCopinos">
            <div class="copino">
              <div class="data data-header">{{ copino.emhHeader?.msgFunction }}</div>
              <div class="data">Process id: {{ copino.processId }}</div>
              <div class="data">Message function: {{ copino.emhHeader?.msgFunction }}</div>
              <div class="data">Modality: {{ copino.modality }}</div>
              <div class="data">Terminal: {{ copino.terminal }}</div>
              <div class="data">Pre-notification: {{ copino.data?.preNotification }}</div>
              <div class="data">Message id: {{ copino.data?.messageId }}</div>
              <div class="data">Declarant: {{ copino.data?.declarantShortName }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>

  </ng-container>


</div>
