import {AfterViewInit, Directive, ElementRef} from '@angular/core';
import {AppContext} from '../app-context';
import translations from 'src/assets/translation/translations.json';

@Directive({
  selector: '[appTranslate]'
})
export class TranslateDirective implements AfterViewInit {

  static translations = translations;

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.tryTranslate(this.elementRef.nativeElement);

    const observer = new MutationObserver(list => {
      this.tryTranslate(this.elementRef.nativeElement);
    });
    observer.observe(this.elementRef.nativeElement, {attributes: false, childList: true, subtree: true});
  }

  tryTranslate(element: Element) {
    if (element.classList.contains("notranslate")) {
      return;
    }
    if (!this.translateContent(element)) {
      if (element.childElementCount > 0) {
        for (let i = 0; i < element.children.length; i++) {
          this.tryTranslate(element.children.item(i));
        }
        for (let i = 0; i < element.childNodes.length; i++) {
          this.translateNode(element.childNodes.item(i));
        }
      }
    }
    this.translatePlaceholder(element);
    this.translateTitle(element);
  }

  private translateContent = (element: Element) : boolean => {
    const key = element.innerHTML.trim().toLowerCase();
    const language = AppContext.getPreferredLanguage();
    const mapping = TranslateDirective.translations[key];
    const translation = mapping && mapping[language];
    if (translation) {
      element.innerHTML = translation;
      element.classList.add('notranslate');
      return true;
    }
    return false;
  };

  private translateNode = (node: Node) => {
    if (node.nodeType !== Node.TEXT_NODE) {
      return;
    }
    const text = node.nodeValue.trim();
    const key = text.toLowerCase();
    const language = AppContext.getPreferredLanguage();
    const mapping = TranslateDirective.translations[key];
    const translation = mapping && mapping[language];
    if (translation) {
      node.nodeValue = node.nodeValue.replace(text, translation);
      node.parentElement.setAttribute("translate", "no");
    }
  };

  private translatePlaceholder = (element: Element) => {
    const placeholder = element.attributes?.getNamedItem('placeholder');
    if (placeholder) {
      const key = placeholder?.value?.trim().toLowerCase();
      const language = AppContext.getPreferredLanguage();
      const mapping = TranslateDirective.translations[key];
      const translation = mapping && mapping[language];
      if (translation) {
        placeholder.value = translation;
        element.setAttribute("translate", "no");
      }
    }
  };

  private translateTitle = (element: Element) => {
    const title = element['title'];
    const key = title && title.trim().toLowerCase();
    const language = AppContext.getPreferredLanguage();
    const mapping = TranslateDirective.translations[key];
    const translation = mapping && mapping[language];
    if (translation) {
      element['title'] = translation;
      element.setAttribute("translate", "no");
    }
  };
}
