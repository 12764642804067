<div class="row align-items-center mx-1 mb-2 py-2 bg-light">
  <div class="col-auto" style="width: 4rem">#</div>
  <div class="col" style="max-width: 12rem">Timestamp
    <span [ngClass]="sortDirection == 'ascending' ? 'fa fa-sort-down': 'fa fa-sort-up'" (click)="toggleSort()"></span></div>
  <div class="col">Action</div>
  <div class="col" style="max-width: 8rem">Call Id</div>
  <div class="col" style="max-width: 16rem">Handling</div>
  <div class="col-auto" style="width: 16rem">User</div>
  <div class="col-auto" style="min-width: 4rem"></div>
</div>
<div class="row align-items-center mx-1 mb-2 py-2 bg-light">
  <div class="col-auto" style="width: 4rem"><h4 class="fa fa-filter pt-0 ps-1 ms-1"></h4></div>
  <div class="col" style="max-width: 12rem">&nbsp;</div>
  <div class="col">&nbsp;</div>
  <div class="col" style="max-width: 8rem">
    <div class="form-group row">
      <app-select [(ngModel)]="filter.visitId" [nullOption]="true" [options]="visitIds" class="input-group"
                  style="min-width: 6rem;"></app-select>
    </div>
  </div>
  <div class="col" style="max-width: 16rem">
    <app-form-group class="d-flex justify-content-right"><label>Handlings</label>
      <app-yes-no [(ngModel)]="filter.showHandlings" [inline]="true" name="showHandlings"></app-yes-no>
    </app-form-group>
  </div>
  <div class="col-auto" style="width: 16rem">
    <div class="form-group row"><input [(ngModel)]="filter.handlingFilterTerm" [disabled]="!filter.showHandlings"
                                       class="form-control" placeholder="container nr/booking nr" type="text"/></div>
  </div>
  <div class="col-auto" style="min-width: 4rem"></div>
</div>
<app-infinite-scroller *ngIf="logItems" [initialCount]="50">
  <ng-container
    *ngFor="let logItem of logItems | historyFilter: filter.showHandlings: filter?.visitId: filter?.handlingFilterTerm; let index = index">
    <ng-container
      [ngTemplateOutletContext]="{ logItem : logItem, index: index }"
      [ngTemplateOutlet]="itemTemplate">
    </ng-container>
  </ng-container>
</app-infinite-scroller>

<ng-template #itemTemplate let-index="index" let-logItem="logItem">
  <div *ngIf="cast(logItem) as item" class="row item pb-2 pt-2">
    <div class="col-auto" style="width: 4rem">
      {{index + 1}}.
    </div>
    <div class="col" style="max-width: 12rem">
      <app-date-time [ngModel]="item.timestamp" [readonly]="true"></app-date-time>
    </div>
    <div class="col-3">
      {{item.action}}
    </div>
    <div class="col" style="max-width: 8rem">
      {{item.visitId}}
    </div>
    <div class="col-3" style="max-width: 16rem">
      <app-handling-summary *ngIf="item.handlingData"
                            [handling]="{ handlingData : item.handlingData}"></app-handling-summary>
    </div>
    <div class="col" style="width: 16rem">
      {{item.user}}
    </div>
    <div class="col-auto text-center">
      <a [href]="['/rotation/' + item.voyageId + '?eventId=' + item.eventId]"
         class="text-dark"
         target="_blank" title="View status at this point in time">
        <h5 class="m-0 btn btn-outline-secondary fa fa-external-link-alt "></h5>
      </a>
    </div>
  </div>
</ng-template>
