<div class="mx-auto mb-5 mt-4">

  <!-- Search -->
  <div class="mt-3">
    <div class="row">
      <div class="col">
        <app-search [(ngModel)]="searchResult" [searchFunction]="search" [inputFormatter]="searchResultFormatter"
                    (ngModelChange)="onEntryChange()" placeholder="Find a fixed window"></app-search>
      </div>
      <div class="col-auto">
        <button class="btn btn-info" type="button" (click)="newEntry()">
          <ng-container *ngIf="searchResult">Duplicate fixed window</ng-container>
          <ng-container *ngIf="!searchResult">Add fixed window</ng-container>
        </button>
      </div>
    </div>
  </div>

  <!-- List -->
  <div *ngIf="entry == undefined" class="mt-5">

    <div class="row align-items-center mx-1 mb-2 py-2 bg-light">
      <div class="col-3 col-lg-2" (click)="sortBy = getSortDirection('terminal'); setFixedWindows = fixedWindows">
        Terminal <span *ngIf="sortBy === 'terminal'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'terminalReversed'" class="fa fa-sort-up"></span>
      </div>
      <div class="d-none d-md-block col-md-2 col-lg-2" (click)="sortBy = getSortDirection('code'); setFixedWindows = fixedWindows">
        Code <span *ngIf="sortBy === 'code'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'codeReversed'" class="fa fa-sort-up"></span>
      </div>
      <div class="col-md-3 col-lg-2 col-4" (click)="sortBy = getSortDirection('declarant'); setFixedWindows = fixedWindows">
        Barge operator <span *ngIf="sortBy === 'declarant'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'declarantReversed'" class="fa fa-sort-up"></span>
      </div>
      <div class="col-1" (click)="sortBy = getSortDirection('handling'); setFixedWindows = fixedWindows">
        Handlings <span *ngIf="sortBy === 'handling'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'handlingReversed'" class="fa fa-sort-up"></span>
      </div>
      <div class="d-none d-lg-block col-lg-2" (click)="sortBy = getSortDirection('periodOfValidity'); setFixedWindows = fixedWindows">
        Period of validity <span *ngIf="sortBy === 'periodOfValidity'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'periodOfValidityReversed'" class="fa fa-sort-up"></span>
      </div>
      <div class="col-lg-3 col-4 col-md-3" (click)="sortBy = getSortDirection('window'); setFixedWindows = fixedWindows">
        Time window <span *ngIf="sortBy === 'window'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'windowReversed'" class="fa fa-sort-up"></span>
      </div>
    </div>

    <app-infinite-scroller [initialCount]="50">
      <ng-container *ngFor="let item of fixedWindows; let index = index">
        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item : item, index: index }"></ng-container>
      </ng-container>
    </app-infinite-scroller>

    <ng-template #itemTemplate let-fixedWindowItem="item" let-declarantFullName="" let-index="index">
      <div class="row align-items-center item mx-1 my-2 py-2 border border-light" *ngIf="cast(fixedWindowItem) as item" (click)="onEntrySelected(item)">
        <div class="col-3 col-lg-2 text-wrap text-truncate" title="{{item.terminal.shortName}}">
          {{item.terminal.shortName}}
        </div>
        <div class="col-2 col-lg-2 text-wrap text-truncate" title="{{item.code}}">
          <span>{{item.code}}</span>
        </div>
        <div class="col-md-3 col-lg-2 col-4 text-wrap text-truncate" title="{{item.declarant?.fullName}}">
          {{item.declarant?.fullName}}
        </div>
        <div class="col-1">
          {{item.handlings}} {{!!item.minimumCallSize ? "(min: "+item.minimumCallSize + ")" : ""}}
        </div>
        <div class="d-none d-lg-block col-lg-2">
          <span class="text-nowrap">{{item.periodOfValidity.start | timestamp:'DD-MM-YYYY'}}</span> &nbsp;&rarr;&nbsp;
          <span class="text-nowrap">{{item.periodOfValidity.end | timestamp:'DD-MM-YYYY'}}</span>
        </div>
        <div class="col-lg-3 col-4 col-md-3">
          <span class="text-nowrap">{{item.window.start.day | titlecase}}  {{item.window.start.time}}</span> -
          <span class="text-nowrap"><span *ngIf="item.window.start.day != item.window.end.day">{{item.window.end.day | titlecase}}</span> {{item.window.end.time}}</span>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- Edit -->
  <div *ngIf="!!entry" class="mt-5">
    <div class="declaration-block">
      <h2>Fixed window details</h2>
    </div>
    <div class="row">
      <div class="col-md-6">
        <app-form-group *ngIf="appContext.isAdmin()">
          <label for="id">Id</label>
          <input id="id" class="form-control" [(ngModel)]="entry.id" disabled required>
        </app-form-group>
        <app-form-group>
          <label for="code">Fixed window code</label>
          <input id="code" class="form-control" [(ngModel)]="entry.code" required>
        </app-form-group>
        <app-form-group>
          <label for="terminal">Terminal</label>
          <app-search id="terminal" [(ngModel)]="entry.terminal" [searchFunction]="findTerminals" [inputFormatter]="terminalFormatter" placeholder="Find a terminal" [disabled]="!appContext.isAdmin()" required></app-search>
        </app-form-group>
        <app-form-group>
          <label for="declarant">Barge operator</label>
          <app-search id="declarant" [(ngModel)]="entry.declarant" [searchFunction]="findDeclarants" [inputFormatter]="declarantFormatter" placeholder="Find a barge operator" required></app-search>
        </app-form-group>
      </div>
      <div class="col-md-6">
        <app-form-group>
          <label for="handlings">Handling amount</label>
          <input id="handlings" class="form-control" [(ngModel)]="entry.handlings" required>
        </app-form-group>
        <app-form-group>
          <label for="minCallSize">Minimum call size</label>
          <input id="minCallSize" class="form-control" [(ngModel)]="entry.minimumCallSize">
        </app-form-group>
        <app-form-group>
          <label class="col-form-label">Period of validity</label>
          <app-date-time [(ngModel)]="entry.periodOfValidity.start" dateOnly="true" required class="d-inline-flex date-period"></app-date-time>
          <app-date-time [(ngModel)]="entry.periodOfValidity.end" dateOnly="true" required class="d-inline-flex date-period date-end"></app-date-time>
        </app-form-group>
        <app-form-group class="day-and-time-range">
          <label for="windowStartDay" class="col-form-label">Window start</label>
          <app-select id="windowStartDay" [(ngModel)]="entry.window.start.day" [options]="weekDays()" [formatter]="toTitleCase" required>
          </app-select>
          <app-date-time [(ngModel)]="entry.window.start.time" timeOnly="true" required></app-date-time>
        </app-form-group>
        <app-form-group class="day-and-time-range">
          <label for="windowEndDay">Window end</label>
          <app-select class="input-group" id="windowEndDay" [(ngModel)]="entry.window.end.day" [options]="weekDays()" [formatter]="toTitleCase" required>
          </app-select>
          <app-date-time [(ngModel)]="entry.window.end.time" timeOnly="true" required></app-date-time>
        </app-form-group>

      </div>
    </div>
  </div>
  <div class="my-2 row" *ngIf="entry">
    <div class="col-auto me-auto">
      <button class="btn btn-danger px-4" type="button" *ngIf="searchResult" (click)="deleteEntry()">
        Delete fixed window
      </button>
    </div>
    <div class="col-auto">
      <button class="btn btn-outline-info px-4" type="button" (click)="cancel()">
        Cancel
      </button>
    </div>
    <div class="col-auto" *ngIf="!searchResult">
      <button class="btn btn-info px-4" type="button" (click)="addEntry()">
        Add fixed window
      </button>
    </div>
    <div class="col-auto" *ngIf="searchResult">
      <button class="btn btn-info px-4" type="button" (click)="updateEntry()">
        Save fixed window
      </button>
    </div>
  </div>

</div>
