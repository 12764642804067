<div class="offcanvas-panel" (change)="setModalClosable(false)">

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i (click)="closeModal()" class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">{{ data?.addMode !== 'createPreNotification' ? "Create new visit" : "Create pre-notification" }}</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="nav-tabs-content nav-tabs">
      <div class="tab-pane">
        <div class="nav-content">

          <app-error [errors]="errors"></app-error>

          <div class="inner-section-header">Visit details</div>

          <app-form-field>
            <label>Terminal</label>
            <app-new-select [(ngModel)]="this.visit.terminal" [dataKey]="'quayName'" [formatter]="utils.terminalFormatter"
                            [options]="terminals" [optionLabel]="'displayName'" optionSelectable="shortName"
                            required width="lg">
            </app-new-select>
          </app-form-field>

          <app-form-field>
            <div class="adjustable-width md hide-md-screen"><label>ETA date</label></div>
            <div class="adjustable-width sm hide-md-screen"><label>ETA time</label></div>
            <div class="adjustable-width sm show-sm-screen"><label>ETA</label></div>
            <app-date-time [(ngModel)]="this.visit?.visitData.eta" customDateClass="md" customTimeClass="sm" required></app-date-time>
          </app-form-field>

          <app-form-field>
            <label>Truck license plate</label>
            <input type="text" class="form-control md" placeholder="e.g. NL-02-PB"
                   [(ngModel)]="this.voyage.voyageData.truckLicenseId" maxlength="15"
                   (change)="this.voyage.voyageData.truckLicenseId = $any($event.target).value.toUpperCase()"
                   [maxLength]="40">
          </app-form-field>

          <app-form-field customClass="mb-0" tooltip="
                          If another company is transporting this container for you, you can select them as ‘Executing charter’.
                          Your company will stay in charge of planning the visit and the other company will be able to view the visit in HCN Road.">
            <label>Executing charter</label>
            <app-search [(ngModel)]="this.voyage.voyageData.charter" customClass="lg"
                        [searchFunction]="utils.findCharters"
                        [resultFormatter]="utils.charterFormatter"
                        (ngModelChange)="$event ? utils.addShortName($event) : null"
                        placeholder="e.g. Transport B.V." dataKey="name">
            </app-search>
          </app-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="tab-pane">
        <div class="footer-container-content">
          <div *ngIf="validationErrors" class="button-bar-text validation-errors">
            Please correct the errors above and try again
          </div>
          <div class="button-bar">
            <button type="button" class="btn btn-primary" (click)="declareVisit()">
              <ng-container *ngIf="data?.addMode !== 'createPreNotification'; else preNotification">
                <span>Add visit</span>
              </ng-container>
              <ng-template #preNotification>
                <span class="hide-md-screen">Submit pre-notification</span>
                <span class="show-sm-screen">Pre-notification</span>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
