import {Component, Input} from '@angular/core';
import {CargoItem, CargoMovement, ContainedGood, Shipment} from '@portbase/hinterland-service-typescriptmodels';
import {lodash} from '../../../common/utils';

@Component({
  selector: 'app-cargo-summary',
  templateUrl: './cargo-summary.component.html',
  styleUrls: ['./cargo-summary.component.css']
})
export class CargoSummaryComponent {

  @Input() set cargoShipment(shipment: Shipment) {
    this.cargoShipments = [shipment];
    this.containerCount = () => null;
    this.shipmentCount = () => null;
  }

  @Input() set cargoShipments(cargoShipments: Shipment[]) {
    this.movements = () => lodash.flatMap(cargoShipments, c => c.movements);
    this.items = this.movements().map(m => m.item);
    this.shipmentCount = () => cargoShipments.length;
    this.orderAvailable = () => cargoShipments.some(s => !!s.data.transportOrder);
    this.goods = lodash.flatMap(cargoShipments, s => s.goods);
  }

  @Input() set movement(movement: CargoMovement) {
    this.items = [movement.item];
    this.movements = () => [movement];
    this.containerCount = () => null;
  }

  @Input() set item(item: CargoItem) {
    this.containerEmpty = () => item.empty;
    this.reefers = () => !!item.temperature;
    this.cargoItems = () => [item];
  }

  set items(items: CargoItem[]) {
    this.cargoItems = () => items;
    const containers = lodash.uniqBy(items, i => i.containerNumber);
    this.containerCount = () => containers.length;
  }

  @Input() set good(good: ContainedGood) {
    this.goods = [good];
  }

  set goods(goods: ContainedGood[]) {
    this.weight = () => lodash.sumBy(goods, g => g.grossWeight);
    this.packageCount = () => lodash.sumBy(goods, g => g.numberOfPackages);
    this.dangerousCargo = () => goods.some(g => !!g.unCode || !!g.hazardClass);
  }

  packageCount = (): number => 0;

  containerCount = (): number => 0;

  orderAvailable = (): boolean => false;

  weight = (): number => 0;

  dangerousCargo = (): boolean => false;

  reefers = (): boolean => false;

  containerEmpty = (): boolean => false;

  shipmentCount = (): number => 0;

  movements = (): CargoMovement[] => [];

  cargoItems = (): CargoItem[] => [];

}
