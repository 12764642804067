import {Component, OnInit} from "@angular/core";
import {AppContext} from "../../../app-context";
import {
  DateRange,
  DetachedHandling,
  EventSourcedModel,
  Handling,
  HandlingType,
  RoadVisit,
  RoadVoyage,
  Terminal
} from "@portbase/hinterland-service-typescriptmodels";
import {EventHandler, EventType} from "../../../common/event-gateway";
import {ComparatorChain} from "../../../common/comparator-chain";
import {HinterlandBaseComponent} from "../../../common/hinterland-base-component";
import {ActionMode, QuickFilterType, SelectAllValue, SortType, VoyageVisitAndHandling} from "../../../hinterland/types";
import {HandlingStatus, HinterlandUtils} from "../../../hinterland/hinterland-utils";
import {AppData} from "../../app-data";
import _ from "lodash";
import moment from "moment";
import {InjectorProvider} from "../../../common/injector-provider";

@Component({
  selector: 'app-main-panel-road',
  templateUrl: './main-panel-road.component.html',
  styleUrls: ['./main-panel-road.component.scss']
})
export class MainPanelRoadComponent extends HinterlandBaseComponent implements OnInit {

  static handlingStatusesOrdered: Array<HandlingStatus> =
    ["stuck", "declared", "rejected", "acceptedWithWarnings", "accepted", "inOperation", "cancelled", "handled", "unknown"];

  appContext = AppContext;
  appData: AppData;
  filteredSortedItems: SortableItem[] = [];
  pageItems: PageItem[] = [];
  isFetching: boolean = false;

  page: number = 0;
  pageSize: number = 25;
  initialCount: number = 100;

  selectedDateRange: DateRange;
  dateRangePreferenceKey: string = "road-overview";
  maxHits: number;

  showQuickFilter = false;
  quickFilterType: QuickFilterType = AppContext.isAdmin() ? "all" : "todo";
  filterTerm: string = "";
  oldFilterTerm: string = "";
  excludedVisit: RoadVisit;
  excludedHandlingIds: string[] = [];
  selectedTerminal: Terminal = undefined;
  selectedHandlingType: string = undefined;
  selectedSortType: SortType = "timeslot";

  actionMode: ActionMode;

  ngOnInit() {
    this.appData = InjectorProvider.injector.get(AppData);
    if (!AppContext.isHinterlandDeclarant() && !AppContext.isHinterlandTerminal()) {
      AppContext.publishErrorMessage("No access rights for Hinterland Road, please contact the Portbase Customer Service.");
      return;
    }

    this.selectedSortType = AppContext.getSortType() || "timeslot";
    this.loadDateRangePreference();
    this.fetchItems();
  }


  /* Data fetching, filtering, sorting and pagination */

  fetchItemsDebounced: Function = _.debounce(() => {
    this.fetchItems();
  }, 200, {leading: true, trailing: false});

  fetchItems() {
    if (this.isFetching) {
      return;
    }
    if (this.filterTerm && this.filterTerm.length < 3) {
      return;
    }

    this.isFetching = true;

    this.maxHits = (AppContext.isAdmin() || AppContext.isHinterlandTerminal()) ? 200 : this.selectedDateRange ? undefined : 1000;

    const adjustedDateRange: DateRange = !this.selectedDateRange ? null : {
      "start": moment(this.selectedDateRange.start).startOf("day").format(),
      'end': moment(this.selectedDateRange.end).endOf("day").format()
    };
    this.appData.fetchRoadItems(adjustedDateRange, this.filterTerm, AppContext.isAdmin() || AppContext.isHinterlandTerminal(), this.maxHits, this.selectedTerminal).subscribe(() => {
      this.setItems();
    });
  }

  setItems() {
    this.filterAndSortItems(true);
    this.isFetching = false;
  }

  filterAndSortItemsDebounced: Function = _.debounce(() => {
    this.filterAndSortItems();
  }, 500);

  filterAndSortItems(resetPage: boolean = false) {
    //Filter
    const filterTerms = this.filterTerm?.split(" ") || [];
    const filteredVoyages: RoadVoyage[] = [];
    const filteredDetachedHandlings: DetachedHandling[] = [];

    for (const item of this.appData?.roadItems || []) {
      if (this.filterTerm && filterTerms.some(term => item.searchString.indexOf(term.toLowerCase()) === -1)) {
        continue;
      }
      if (item.isVoyage) {
        const voyage = item.getVoyage();
        if (this.isVoyageFiltered(voyage)) {
          filteredVoyages.push(voyage);
        }
      } else {
        const detachedHandling = item.getDetachedHandling();
        if (this.isDetachedHandlingFiltered(detachedHandling)) {
          filteredDetachedHandlings.push(detachedHandling);
        }
      }
    }

    //Build Wrapped sorting Objects
    let filteredSortedItems: SortableItem[] = []
    for (const voyage of filteredVoyages) {
      filteredSortedItems.push(new SortedVoyage(voyage));
    }
    for (const detachedHandling of filteredDetachedHandlings) {
      filteredSortedItems.push(new SortedDetachedHandling(detachedHandling));
    }

    //Sort
    const chain = new ComparatorChain();
    if (this.quickFilterType === "all") {
      chain.addProperty(MainPanelRoadComponent.reverseTimeslotComparator);
    } else if (this.selectedSortType === "timeslot") {
      chain.addProperty(MainPanelRoadComponent.timeslotComparator);
    } else if (this.selectedSortType === "updated") {
      chain.addProperty("-updated");
    } else if (this.selectedSortType === "status") {
      chain.addProperty(MainPanelRoadComponent.statusComparator);
    } else {
      chain.addProperty("-smallestEquipmentNumber");
    }
    this.filteredSortedItems = filteredSortedItems.sort(chain.compare);

    const page = resetPage ? 0 : this.page;
    this.onPageChanged(page);
  }

  changePage(page: number) {
    this.page = page;
    this.onPageChanged(page);
  }

  onPageChanged(page: number) {
    const pageItems: PageItem[] = [];
    if (this.filteredSortedItems.length === 0) {
      this.page = 0;
      this.deselectHandlingsNotOnPage(this.pageItems, pageItems);
      this.pageItems = pageItems;
      this.checkSelectAllCheckboxValue();
      return;
    }

    let fromIndex: number = this.pageSize * page;
    if (fromIndex >= this.filteredSortedItems.length) {
      page = Math.ceil(this.filteredSortedItems.length / this.pageSize);
      fromIndex = this.pageSize * page;
    }

    let tillIndex: number = fromIndex + this.pageSize;
    if (tillIndex >= this.filteredSortedItems.length) {
      tillIndex = this.filteredSortedItems.length;
    }

    for (let i = fromIndex; i < tillIndex; ++i) {
      const filteredSortedItem = this.filteredSortedItems[i];
      if (filteredSortedItem.isVoyage()) {
        pageItems.push(new PageItem(filteredSortedItem.getVoyage(), true));
      } else {
        pageItems.push(new PageItem(filteredSortedItem.getDetachedHandling(), false));
      }
    }

    this.deselectHandlingsNotOnPage(this.pageItems, pageItems);
    this.pageItems = pageItems;
    this.page = page;
    this.checkSelectAllCheckboxValue();
  }

  isVoyageFiltered(voyage: RoadVoyage) {
    if (voyage.cancelled === true || this.quickFilterType === "statusRequests") {
      return false;
    }

    let hasVisitWithinDateRange = false;
    let hasVisitWithSelectedTerminal = this.selectedTerminal === undefined || this.selectedTerminal === null;
    let hasHandlingWithSelectedHandlingType = this.selectedHandlingType === undefined || this.selectedHandlingType === null;
    let hasNonReadyHandling = this.quickFilterType !== "notReady";
    let isCompleted = this.quickFilterType !== "completed";
    let requiresWork = this.quickFilterType !== "todo";
    let handlingCount = 0;

    for (const visit of voyage.visits) {
      if (this.excludedVisit?.visitId === visit.visitId && voyage.visits.length == 1) {
        return false;
      }
      const requestedEta = visit.requestedVisitData && visit.requestedVisitData.eta ? moment(visit.requestedVisitData.eta) : null;
      if ((!this.selectedDateRange) || (requestedEta && requestedEta.isBetween(moment(this.selectedDateRange.start), moment(this.selectedDateRange.end).endOf("day")))) {
        hasVisitWithinDateRange = true;
      }
      const plannedEta = visit.plannedVisitData && visit.plannedVisitData.eta ? moment(visit.plannedVisitData.eta) : null;
      if ((!this.selectedDateRange) || (plannedEta && plannedEta.isBetween(moment(this.selectedDateRange.start), moment(this.selectedDateRange.end).endOf("day")))) {
        hasVisitWithinDateRange = true;
      }
      if (this.selectedTerminal && this.selectedTerminal.bicsCode === visit.terminal.bicsCode) {
        hasVisitWithSelectedTerminal = true;
      }

      for (const handling of visit.handlings) {
        if (this.selectedHandlingType && handling.handlingData.type === this.selectedHandlingType) {
          hasHandlingWithSelectedHandlingType = true;
        }

        if (this.excludedHandlingIds.some(id => id === handling.handlingId)
          || this.excludedHandlingIds.length === 1 && this.excludedHandlingIds[0] === handling.handlingId && voyage.visits.length == 1
        ) {
          return false;
        }

        handlingCount++;
      }

      if (this.quickFilterType === "notReady" && (!!this.selectedDateRange || !this.visitOlderThanDays(visit, 1))) {
        for (const handling of visit.handlings) {
          if (handling.cancelled === true) {
            continue;
          }

          if (handling.declarationStatus === "REJECTED") {
            hasNonReadyHandling = true;
          } else if (handling.handlingDeclaration !== undefined) {
            for (const acceptStatus of handling.handlingDeclaration.acceptStatuses) {
              if (acceptStatus.ok === false) {
                hasNonReadyHandling = true;
                break;
              }
            }
          }
        }
      }

      if (this.quickFilterType === "todo") {
        requiresWork = this.handlingOrVisitRequiresWork(visit) && (!!this.selectedDateRange || !this.visitOlderThanDays(visit, 1));
      }

      if (this.quickFilterType === "completed") {
        isCompleted = this.handlingOrVisitCompleted(visit);
      }
    }
    return hasVisitWithinDateRange && hasVisitWithSelectedTerminal && hasHandlingWithSelectedHandlingType
      && hasNonReadyHandling && isCompleted && requiresWork && handlingCount !== 0;
  }

  handlingOrVisitRequiresWork(visit: RoadVisit) {
    return !(visit.visitCompleted || visit.handlings.every(h => h.completed || !!h.handlingResult)
      || (this.visitOlderThanDays(visit, 7) && visit.handlings.every(h => h.declarationStatus === "ACCEPTED" && h.allStatussesOK)));
  }

  handlingOrVisitCompleted(visit: RoadVisit) {
    if (visit.visitCompleted || visit.handlings.every(h => h.completed || !!h.handlingResult)) {
      return true;
    }

    return this.visitOlderThanDays(visit, 7)
      && visit.handlings.some(h => h.declarationStatus === "ACCEPTED" && h.allStatussesOK);
  }

  private visitOlderThanDays(visit: RoadVisit, numberOfDays: number) {
    return this.olderThanDays(visit.plannedVisitData?.eta, numberOfDays)
      && this.olderThanDays(visit.plannedVisitData?.etd, numberOfDays)
      && this.olderThanDays(visit.requestedVisitData?.eta, numberOfDays);
  }

  olderThanDays(timestamp: string, numberOfDays: number): boolean {
    return !timestamp ? true : moment().isAfter((moment(timestamp).add(numberOfDays, "days")));
  }

  isDetachedHandlingFiltered(detachedHandling: DetachedHandling) {
    if (this.quickFilterType === "visits" || this.quickFilterType === "completed") {
      return false;
    }

    if (!!this.selectedDateRange) {
      if (detachedHandling.eta) {
        const eta = moment(detachedHandling.eta);
        if (!eta.isBetween(moment(this.selectedDateRange.start), moment(this.selectedDateRange.end).endOf("day"))) {
          return false;
        }
      } else {
        const updatedTimestamp = moment(detachedHandling.updated);
        if (!updatedTimestamp.isBetween(moment(this.selectedDateRange.start), moment(this.selectedDateRange.end).endOf("day"))) {
          return false;
        }
      }
    } else if ((this.quickFilterType === "todo" || this.quickFilterType === "notReady") && this.olderThanDays(detachedHandling.updated, 1)) {
      return false;
    }

    if (this.selectedTerminal && detachedHandling.terminal.bicsCode !== this.selectedTerminal.bicsCode) {
      return false;
    }

    if (this.selectedHandlingType && detachedHandling.handlingData.type !== this.selectedHandlingType) {
      return false;
    }

    if (this.quickFilterType === "statusRequests" && (detachedHandling.cancelled || this.olderThanDays(detachedHandling.updated, 90))) {
      return false;
    }

    if (this.quickFilterType === "notReady") {
      let hasNonOkStatus = detachedHandling.declarationStatus === "REJECTED";
      if (hasNonOkStatus === false && detachedHandling.handlingDeclaration !== undefined) {
        for (const acceptStatus of detachedHandling.handlingDeclaration.acceptStatuses) {
          if (acceptStatus.ok === false) {
            hasNonOkStatus = true;
          }
        }
      }

      if (hasNonOkStatus === false) {
        return false;
      }
    }

    return true;
  }

  static timeslotComparator = (itemA: SortableItem, itemB: SortableItem) => {
    if (itemA.worstStatus === "handled") {
      if (itemB.worstStatus !== "handled") {
        return 1;
      }
    } else if (itemB.worstStatus === "handled") {
      return -1;
    }

    if (itemA.worstStatus === "cancelled") {
      if (itemB.worstStatus !== "cancelled") {
        return 1;
      }
    } else if (itemB.worstStatus === "cancelled") {
      return -1;
    }

    if (itemA.latestEta === undefined) {
      if (itemB.latestEta !== undefined) {
        return 1;
      } else {
        return 0;
      }
    } else if (itemB.latestEta === undefined) {
      return -1;
    }

    return itemA.latestEta.diff(itemB.latestEta, "milliseconds");
  }

  static reverseTimeslotComparator = (itemA: SortableItem, itemB: SortableItem) => {
    if (itemA.worstStatus === "cancelled") {
      if (itemB.worstStatus !== "cancelled") {
        return 1;
      }
    } else if (itemB.worstStatus === "cancelled") {
      return -1;
    }

    if (itemB.latestEta === undefined) {
      if (itemA.latestEta !== undefined) {
        return 1;
      } else {
        return 0;
      }
    } else if (itemA.latestEta === undefined) {
      return -1;
    }

    return itemB.latestEta.diff(itemA.latestEta, "milliseconds");
  }

  static statusComparator = (itemA: SortableItem, itemB: SortableItem) => {
    const itemAStatusIndex = MainPanelRoadComponent.handlingStatusesOrdered.indexOf(itemA.worstStatus);
    const itemBStatusIndex = MainPanelRoadComponent.handlingStatusesOrdered.indexOf(itemB.worstStatus);
    if (itemAStatusIndex === itemBStatusIndex) {
      if (itemA.smallestEquipmentNumber === undefined) {
        return 0;
      } else {
        return itemA.smallestEquipmentNumber.localeCompare(itemB.smallestEquipmentNumber);
      }
    }

    return itemAStatusIndex > itemBStatusIndex ? 1 : -1;
  }

  /* Event Listeners */

  onPatchApplied: EventHandler<EventType.PatchApplied> = () => {
    this.filterAndSortItemsDebounced();
  }

  onEnterActionMode: EventHandler<EventType.EnterActionMode> = (data) => {
    this.selectedHandlingType = undefined;
    this.selectedTerminal = data.terminal;

    this.filterTerm = "";
    this.oldFilterTerm = "";

    if (!!data.visit) {
      this.excludedVisit = data.visit as RoadVisit;
      this.getOrFilterItems();
      this.actionMode = "addHandlingToVisitMode";
    } else if (!!data.handling) {
      this.excludedHandlingIds = [data.handling.handlingId];
      this.quickFilterType = "visits";
      this.getOrFilterItems();
      this.actionMode = "attachToVisitMode";
    } else if (!!data.handlings) {
      this.excludedHandlingIds = data.handlings.map(h => h.handlingId);
      this.quickFilterType = 'visits';
      this.getOrFilterItems();
      this.actionMode = "attachToVisitMode";
    }
  }

  onExitActionMode: EventHandler<EventType.ExitActionMode> = () => {
    this.excludedVisit = null;
    this.excludedHandlingIds = [];
    this.selectedTerminal = undefined;
    this.filterTerm = undefined;
    this.oldFilterTerm = undefined;
    this.quickFilterType = AppContext.isAdmin() ? "all" : "todo";
    this.actionMode = undefined;

    this.filterAndSortItems(true);

    this.eventGateway.publish(EventType.DeselectAllHandlings);
  }

  onExitHandlingActionMode: EventHandler<EventType.ExitHandlingActionMode> = () => {
    this.excludedVisit = null;
    this.excludedHandlingIds = [];
    this.selectedTerminal = undefined;
    this.filterTerm = undefined;
    this.oldFilterTerm = undefined;
    this.quickFilterType = AppContext.isAdmin() ? "all" : "todo";
    this.actionMode = undefined;

    this.eventGateway.publish(EventType.DeselectAllHandlings);
  }

  private getOrFilterItems(reload?: boolean) {
    if (reload || AppContext.isAdmin() || AppContext.isHinterlandTerminal() ) {
      this.fetchItemsDebounced();
    } else {
      this.filterAndSortItems(true);
    }
  }

  onQuickFilterChanged = (quickFilterType: QuickFilterType): any => {
    this.eventGateway.publish(EventType.DeselectAllHandlings);
    const reload = this.quickFilterType == quickFilterType;
    this.quickFilterType = quickFilterType;
    this.getOrFilterItems(reload);
    if (quickFilterType == "completed" && this.selectedSortType == "status") {
      this.selectedSortType = "updated";
    }
  }

  loadDateRangePreference() {
    const dateRangePref = JSON.parse(localStorage.getItem(this.dateRangePreferenceKey))
    this.selectedDateRange = !!dateRangePref ? {
      'start': moment().startOf("day").subtract(dateRangePref.daysBefore, "d").format(),
      'end': moment().startOf("day").add(dateRangePref.daysAfter, "d").format()
    } : undefined;
  }


  onDateRangeChanged = (dateRange: DateRange): any => {
    this.selectedDateRange = dateRange;
    if (dateRange == null) {
      localStorage.removeItem(this.dateRangePreferenceKey);
    } else {
      let daysBefore = moment().startOf("day").diff(moment(dateRange.start), "days");
      let daysAfter = moment(dateRange.end).diff(moment().startOf("day"), "days");
      localStorage.setItem(this.dateRangePreferenceKey, JSON.stringify({
        daysBefore: daysBefore,
        daysAfter: daysAfter
      }));
    }
    this.getOrFilterItems(true);
  }

  onFilterTermChanged = (filterTerm: string): any => {
    if (this.oldFilterTerm != filterTerm) {
      this.filterTerm = filterTerm;

      if (filterTerm == undefined || filterTerm == "") {
        this.filterTerm = undefined;
        this.oldFilterTerm = undefined;
      }

      this.getOrFilterItems();
      this.oldFilterTerm = filterTerm;
    }
  };

  onSelectedHandlingTypeChanged = (selectedHandlingType: HandlingType): any => {
    this.selectedHandlingType = selectedHandlingType;
    this.getOrFilterItems();
  }

  onSelectedTerminalChanged = (selectedTerminal: Terminal): any => {
    this.selectedTerminal = selectedTerminal;
    this.getOrFilterItems();
  }

  onSelectedSortTypeChanged = (selectedSortType: SortType): any => {
    this.selectedSortType = selectedSortType;
    AppContext.saveSortType(selectedSortType);
    this.getOrFilterItems();
  }

  onHandlingSelected: EventHandler<EventType.HandlingSelected> = () => {
    const allPageHandlings = this.getAllPageHandlings();
    if (allPageHandlings.every(handling => handling["selected"] === true)) {
      this.eventGateway.publish(EventType.SelectAllHandlingsChanged, <SelectAllValue>"all");
    }
  }

  onHandlingDeselected: EventHandler<EventType.HandlingDeselected> = () => {
    const allPageHandlings = this.getAllPageHandlings();
    if (allPageHandlings.every(handling => handling["selected"] !== true)) {
      this.eventGateway.publish(EventType.SelectAllHandlingsChanged, <SelectAllValue>"none");
    }
  }

  onSelectAllHandlings: EventHandler<EventType.SelectAllHandlings> = () => {
    for (const pageItem of this.pageItems) {
      if (pageItem.isVoyage) {
        const voyage = pageItem.getVoyage();
        for (const visit of voyage.visits) {
          for (const handling of visit.handlings) {
            if (handling["selected"] !== true) {
              handling["selected"] = true;
              this.eventGateway.publish(EventType.HandlingSelected, <VoyageVisitAndHandling>{
                "voyage": voyage,
                "visit": visit,
                "handling": handling
              });
            }
          }
        }
      } else {
        const detachedHandling = pageItem.getDetachedHandling();
        if (detachedHandling["selected"] !== true) {
          detachedHandling["selected"] = true;
          this.eventGateway.publish(EventType.HandlingSelected, <VoyageVisitAndHandling>{
            "voyage": undefined,
            "visit": undefined,
            "handling": detachedHandling
          });
        }
      }
    }

    this.eventGateway.publish(EventType.SelectAllHandlingsChanged, <SelectAllValue>"all");
  }

  onDeselectAllHandlings: EventHandler<EventType.DeselectAllHandlings> = () => {
    for (const pageItem of this.pageItems) {
      if (pageItem.isVoyage) {
        const voyage = pageItem.getVoyage();
        for (const visit of voyage.visits) {
          for (const handling of visit.handlings) {
            if (handling["selected"] === true) {
              handling["selected"] = false;
              this.eventGateway.publish(EventType.HandlingDeselected, <VoyageVisitAndHandling>{
                "voyage": voyage,
                "visit": visit,
                "handling": handling
              });
            }
          }
        }
      } else {
        const detachedHandling = pageItem.getDetachedHandling();
        if (detachedHandling["selected"] === true) {
          detachedHandling["selected"] = false;
          this.eventGateway.publish(EventType.HandlingDeselected, <VoyageVisitAndHandling>{
            "voyage": undefined,
            "visit": undefined,
            "handling": detachedHandling
          });
        }
      }
    }

    this.eventGateway.publish(EventType.SelectAllHandlingsChanged, <SelectAllValue>"none");
  }

  /* Private methods */

  getAllPageHandlings(): Handling[] {
    const handlings: Handling[] = [];

    for (const pageItem of this.pageItems) {
      if (pageItem.isVoyage) {
        const voyage = pageItem.getVoyage();
        for (const visit of voyage.visits) {
          for (const handling of visit.handlings) {
            handlings.push(handling);
          }
        }
      } else {
        handlings.push(pageItem.getDetachedHandling());
      }
    }

    return handlings;
  }

  checkSelectAllCheckboxValue() {
    const allPageHandlings = this.getAllPageHandlings();
    if (allPageHandlings.some(handling => handling["selected"] === true)) {
      if (allPageHandlings.every(handling => handling["selected"] === true)) {
        this.eventGateway.publish(EventType.SelectAllHandlingsChanged, <SelectAllValue>"all");
      } else {
        this.eventGateway.publish(EventType.SelectAllHandlingsChanged, <SelectAllValue>"some");
      }
    } else {
      this.eventGateway.publish(EventType.SelectAllHandlingsChanged, <SelectAllValue>"none");
    }
  }

  deselectHandlingsNotOnPage(previousPageItems: PageItem[], newPageItems: PageItem[]) {
    for (const previousPageItem of previousPageItems) {
      let stillOnPage = false;
      for (const newPageItem of newPageItems) {
        if (newPageItem.getId() === previousPageItem.getId()) {
          stillOnPage = true;
          break;
        }
      }

      if (!stillOnPage) {
        if (previousPageItem.isVoyage) {
          const voyage = previousPageItem.getVoyage();
          for (const visit of voyage.visits) {
            for (const handling of visit.handlings) {
              if (handling["selected"] === true) {
                handling["selected"] = false;
                this.eventGateway.publish(EventType.HandlingDeselected, <VoyageVisitAndHandling>{
                  "voyage": voyage,
                  "visit": visit,
                  "handling": handling
                });
              }
            }
          }
        } else {
          const detachedHandling = previousPageItem.getDetachedHandling();
          if (detachedHandling["selected"] === true) {
            detachedHandling["selected"] = false;
            this.eventGateway.publish(EventType.HandlingDeselected, <VoyageVisitAndHandling>{
              "voyage": undefined,
              "visit": undefined,
              "handling": detachedHandling
            });
          }
        }
      }
    }
  }

  formattedQuickFilter(): string {
    switch (this.quickFilterType) {
      case "all":
        return "All handlings";
      case "todo":
        return "To do";
      case "statusRequests":
        return "Status requests";
      case "visits":
        return "Visits";
      case "notReady":
        return "Not ready";
      case "completed":
        return "Completed";
      default:
        return this.quickFilterType;
    }
  }

  toggleQuickFilters() {
    this.showQuickFilter = !this.showQuickFilter;
  }

  protected trackById(index: number, item: PageItem) {
     return item.getId()
  }
}

//Object wrappers

interface SortableItem {
  isVoyage(): boolean

  getVoyage(): RoadVoyage

  getDetachedHandling(): DetachedHandling

  getId(): string;

  latestEta: moment.Moment;
  updated: string;
  worstStatus: HandlingStatus
  smallestEquipmentNumber: string;
}

class SortedDetachedHandling implements SortableItem {
  private readonly _detachedHandling: DetachedHandling;
  private readonly _id: string;

  latestEta: moment.Moment
  updated: string
  worstStatus: HandlingStatus
  smallestEquipmentNumber: string

  constructor(detachedHandling: DetachedHandling) {
    this._detachedHandling = detachedHandling;
    this._id = detachedHandling.handlingId;

    if (detachedHandling.eta) {
      this.latestEta = moment(detachedHandling.eta);
    }

    this.updated = detachedHandling.updated;
    this.worstStatus = HinterlandUtils.determineStatus(detachedHandling);
    this.smallestEquipmentNumber = detachedHandling.handlingData.equipmentNumber;
  }

  isVoyage(): boolean {
    return false;
  }

  getDetachedHandling(): DetachedHandling {
    return this._detachedHandling;
  }

  getVoyage(): RoadVoyage {
    return undefined;
  }

  getId(): string {
    return this._detachedHandling.handlingId;
  }
}

class SortedVoyage implements SortableItem {
  private readonly _roadVoyage: RoadVoyage;

  latestEta: moment.Moment;
  updated: string
  worstStatus: HandlingStatus
  smallestEquipmentNumber: string

  constructor(roadVoyage: RoadVoyage) {
    this._roadVoyage = roadVoyage;

    let latestEta: moment.Moment = undefined;
    let worstStatusIndex: number = undefined;
    let smallestEquipmentNumber: string = undefined;
    for (const visit of roadVoyage.visits) {
      const eta: moment.Moment = moment(visit.visitData.eta);
      if (latestEta === undefined || eta.isBefore(latestEta)) {
        latestEta = eta;
      }

      for (const handling of visit.handlings) {
        const status = HinterlandUtils.determineStatus(handling, visit);
        const statusIndex = MainPanelRoadComponent.handlingStatusesOrdered.indexOf(status);
        if (worstStatusIndex === undefined || statusIndex > worstStatusIndex) {
          worstStatusIndex = statusIndex;
        }

        const equipmentNumber = handling.handlingData.equipmentNumber;
        if (equipmentNumber && (smallestEquipmentNumber === undefined || equipmentNumber.localeCompare(smallestEquipmentNumber) < 0)) {
          smallestEquipmentNumber = equipmentNumber;
        }
      }
    }

    this.latestEta = latestEta;
    this.updated = roadVoyage.updated;
    this.worstStatus = MainPanelRoadComponent.handlingStatusesOrdered[worstStatusIndex];
    this.smallestEquipmentNumber = smallestEquipmentNumber;
  }

  isVoyage(): boolean {
    return true;
  }

  getDetachedHandling(): DetachedHandling {
    return undefined;
  }

  getVoyage(): RoadVoyage {
    return <RoadVoyage>this._roadVoyage;
  }

  getId(): string {
    return this._roadVoyage.voyageId;
  }
}

class PageItem {
  private readonly _item: EventSourcedModel<any>;
  private readonly _isVoyage: boolean;

  constructor(item: EventSourcedModel<any>, isVoyage: boolean) {
    this._item = item;
    this._isVoyage = isVoyage;
  }

  get isVoyage(): boolean {
    return this._isVoyage;
  }

  getVoyage(): RoadVoyage {
    return <RoadVoyage>this._item;
  }

  getDetachedHandling(): DetachedHandling {
    return <DetachedHandling>this._item;
  }

  getId(): string {
    if (this.isVoyage) {
      return this._item["voyageId"];
    } else {
      return this._item["handlingId"];
    }
  }
}
