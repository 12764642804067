<div class="offcanvas-panel">
  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i data-bs-dismiss="modal"
                                                     class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">Contact form</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>


  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Ask your question</h5>

        <app-error [errors]="errors"></app-error>

        <div class="my-4">
          <p>Answers to frequently asked questions can be found on our <a target="_blank"
                                                                          [href]="appContext.getBinnenhavengeldSupportLink()">support
            page</a>.</p>

          <p>If your question is not listed in the 'frequently asked questions', please contact the Harbour Dues
            department using the form below. Please describe your question in as much detail as possible.</p>

          <p>The Harbour Dues department will contact you as soon as possible.</p>
        </div>

        <div class="row">
          <div class="col-12">
            <app-form-field label="Email">
              <input required [(ngModel)]="email" placeholder="Enter your email" class="form-control">
            </app-form-field>
            <app-form-field label="Question">
          <textarea required [(ngModel)]="issue" placeholder="Describe your question in as much detail as possible"
                    class="form-control" style="min-height: 15rem;"> </textarea>
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="footer-container-content">
        <div class="button-bar">
          <button appSingleClick [disableSubmit]="disableSubmit" class="btn btn-primary" type="button" (click)="submit()">Send</button>
        </div>
      </div>
    </div>
  </div>
</div>
