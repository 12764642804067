import * as XLSX from 'xlsx';
import {CellAddress, CellObject, WorkBook, WorkSheet} from 'xlsx';
import moment from 'moment';
import lodash from 'lodash';
import {combineLatest, Observable, of, Subscriber} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AppContext} from '../../app-context';

/**
 * Export an array of Array as Excel file.
 * @param data
 * @param fileName
 */
export function exportDataAsExcel(data: any[][], fileName: string = "export.xlsx") {
  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workBook, workSheet);
  XLSX.writeFile(workBook, fileName);
}

/**
 * Export an Excel file from a base file using template definition and data.
 * @param url pointing to a base Excel file
 * @param template Template definition describing the layout of the final Excel file
 * @param data the data required by the template
 * @param name custom name for the document - default is export.xlsx
 */
export function exportExcel(url: string, template: WorkBookTemplate, data: any, name?: string): void {
  downloadWorkbook(url).subscribe(workBook => {
    template.sheets.forEach(template => {
      const sheet = workBook.Sheets[template.name];
      template.resizeSheet?.(sheet);
      exportAny(template.template, data, sheet, name => name);
      computeSheetRange(sheet);
    });
    XLSX.writeFile(workBook, name ? name : "export.xlsx", {cellStyles: true});
  });
}

function computeSheetRange(sheet: XLSX.WorkSheet) {
  let max_column = 0, max_row = 0;
  Object.keys(sheet)
    .filter(k => !k.startsWith('!'))
    .map(k => XLSX.utils.decode_cell(k))
    .forEach(ca => {
      if (ca.r > max_row) max_row = ca.r;
      if (ca.c > max_column) max_column = ca.c;
    });

  sheet['!ref'] = XLSX.utils.encode_range({s: {r: 0, c: 0}, e: {r: max_row, c: max_column}});
}

function exportAny(template: any, data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
  if (!data) {
    return;
  }
  if (Array.isArray(template)) {
    exportArray(template, data, sheet, cellNameFunction);
  } else if (template instanceof Field) {
    template.export(data, sheet, cellNameFunction);
  } else if (typeof template === 'string') {
    exportCell(template, data, sheet, cellNameFunction);
  } else {
    exportObject(template, data, sheet, cellNameFunction);
  }

  function exportArray(template: any[], data, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    template.forEach(t => exportAny(t, data, sheet, cellNameFunction));
  }

  function exportObject(template: any, data, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    Object.keys(template).forEach(key => {
      const templateValue = template[key];
      const dataSelection = data && data[key];
      exportAny(templateValue, dataSelection, sheet, cellNameFunction);
    })
  }

  function exportCell(cellName: string, data, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    cellName = cellNameFunction(cellName);
    let value: CellObject = <any>{v: data};
    switch (typeof data) {
      case 'string':
        value.t = 's';
        break;
      case 'number':
        value.t = 'n';
        break;
      case 'boolean':
        value.t = 's';
        value.v = data ? 'Yes' : 'No';
        break;
    }
    sheet[cellName] = value;
  }
}

/**
 * Parse an Excel file according to the template definition.
 * @param file the Excel file to read
 * @param template the template declaring required or to parse fields
 */
export function parseExcel(file: File, template: WorkBookTemplate): Observable<any> {

  return new Observable((subscriber: Subscriber<any>) => {
    getWorkbook(file).subscribe(workBook => {
      let result = {};
      const errors = [];
      const observables: Observable<any>[] = [];

      if (!workBook) {
        errors.push(new Error('Could not parse Excel file: ' + file && file.name));
      } else {
        template.sheets.forEach(sheet => {
          if (workBook.SheetNames.indexOf(sheet.name) < 0) {
            errors.push(new Error('Sheet not found: ' + sheet.name));
            return;
          }
          const parser = new Parser(workBook, sheet.name);
          const model = parser.mapAny(sheet.template, cellName => cellName);
          let onComplete: Observable<any> = parser.observables.length > 0 ? combineLatest(parser.observables) : of(null);
          onComplete = onComplete.pipe(tap(() => {
            result = lodash.mergeWith(result, model, (objValue, srcValue) => {
              if (lodash.isArray(objValue)) {
                return objValue.concat(srcValue);
              }
            });
            parser.errors.forEach(e => errors.push(e));
          }));
          observables.push(onComplete);
        });
      }
      const onComplete: Observable<any> = observables.length > 0 ? combineLatest(observables) : of(null);
      onComplete.subscribe({
        next: () => {
          if (errors.length > 0) {
            const filteredErrors = Array.from(new Set(errors));
            filteredErrors.forEach(e => AppContext.registerError(e));
            subscriber.error(filteredErrors);
          } else {
            subscriber.next(result);
          }
        },
        complete: () => subscriber.complete()
      });
    });
  });
}

function downloadWorkbook(url: string): Observable<WorkBook> {
  const reader: FileReader = new FileReader();
  return new Observable((s: Subscriber<WorkBook>) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = () => {
      reader.readAsArrayBuffer(request.response);
    };
    request.send();

    reader.onload = (e: any) => {
      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      let workBook: WorkBook;
      try {
        workBook = XLSX.read(binary, {type: 'binary', cellDates: true, cellStyles: true});
      } catch (e) {
        AppContext.registerError(e);
        return;
      }
      s.next(workBook);
      s.complete();
    };
  });
}

/**
 * Read a file as XLSX WorkBook
 * @param file
 */
export function getWorkbook(file: File): Observable<WorkBook> {
  const reader: FileReader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Observable((s: Subscriber<WorkBook>) => {
    reader.onload = (e: any) => {
      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      let workBook: WorkBook;
      try {
        workBook = XLSX.read(binary, {type: 'binary', cellDates: true});
      } catch (e) {
        AppContext.registerError(e);
        return;
      }
      s.next(workBook);
      s.complete();
    };
  });
}

export function toBase64(file: File): Observable<String> {
  const reader: FileReader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Observable((s: Subscriber<String>) => {
    reader.onload = (e: any) => {
      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      s.next(btoa(binary));
      s.complete();
    };
  });
}

export interface WorkBookTemplate {
  sheets: SheetTemplate[];
}

export interface SheetTemplate {
  name: string;
  template: any;
  resizeSheet?: (sheet: WorkSheet) => void;
}

export abstract class Field<T> {
  protected template: any;

  protected constructor(template: any) {
    this.template = template;
  }

  abstract getValue(parser: Parser, cellNameFunction): T;

  abstract isEmpty(parser: Parser, cellNameFunction);

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    if (this.template) {
      exportAny(this.template, data, sheet, cellNameFunction);
    }
  }

  static cellOrNull(possibleCell: any) {
    return possibleCell instanceof Cell ? possibleCell : null;
  }

  static getCellValue(possibleCell: any) {
    return possibleCell instanceof Cell ? possibleCell.value : possibleCell;
  }
}

export class ArrayTemplate extends Field<any[]> {
  template: any;
  ranges: [number, number][];

  constructor(private pattern: any, ...ranges: [number, number][]) {
    super(pattern);
    this.ranges = ranges;
  }

  getValue(parser: Parser, cellNameFunction): any[] {
    let newArray = [];
    this.ranges.forEach(range => {
      for (let i = range[0]; i <= range[1]; i++) {
        if (parser.isEmpty(this.template, cellName => cellNameFunction(cellName).replace('$', String(i)))) {
          break;
        }
        let field = parser.mapAny(this.template, cellName => cellNameFunction(cellName).replace('$', String(i)));
        if (field instanceof Cell) {
          field = field.value;
        }
        const index = newArray.push(field) - 1;
        if (field instanceof Observable) {
          field = field.pipe(catchError(e => {
            parser.registerError(e);
            return of(null);
          }));
          field = field.pipe(map(r => newArray[index] = r));
          parser.registerObservable(field);
        }
      }
    });
    return newArray;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    for (const range of this.ranges) {
      for (let i = range[0]; i <= range[1]; i++) {
        if (isEmptyRow(i, parser.workSheet)) {
          return true;
        }
        if (!parser.isEmpty(this.template, cellName => cellNameFunction(cellName).replace('$', String(i)))) {
          return false;
        }
      }
    }
    return true;
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    for (const range of this.ranges) {
      const dataRows: any[] = data;
      for (let i = range[0], j = 0; i <= range[1]; i++, j++) {
        if (j >= dataRows.length) {
          break;
        }
        super.export(dataRows[j], sheet, cellName => cellNameFunction(cellName).replace('$', String(i)));
      }
    }
  }
}

export class UppercaseField extends Field<Cell> {

  constructor(private field: any) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): Cell {
    let cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (!(cell instanceof Cell)) {
      parser.registerError(new Error('Unexpected error while parsing a field in an Excel sheet. Please contact support.'));
    }
    if (cell.value != null) {
      cell.value = String(cell.value).toUpperCase();
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class LowercaseField extends Field<Cell> {

  constructor(private field: any) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): Cell {
    let cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (!(cell instanceof Cell)) {
      parser.registerError(new Error('Unexpected error while parsing a field in an Excel sheet. Please contact support.'));
    }
    if (cell.value != null) {
      cell.value = String(cell.value).toLowerCase();
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class DateField extends Field<Cell> {

  constructor(private field: any, private dateFormat: string = "DD/MM/YYYY") {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): Cell {
    let cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (!(cell instanceof Cell)) {
      parser.registerError(new Error('Unexpected error while parsing a date field in an Excel sheet. Please contact support.'));
    }
    if (cell.value != null) {
      let mom = moment(cell.value, this.dateFormat);
      if (mom.isValid()) {
        cell.value = mom.format('YYYY-MM-DD');
      } else {
        parser.registerError(new Error('Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an invalid date. The format should be ' + this.dateFormat));
      }
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class TimeField extends Field<Cell> {

  constructor(private field: any, private dateTimeFormat: string = "HH:mm") {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): Cell {
    let cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (!(cell instanceof Cell)) {
      parser.registerError(new Error('Unexpected error while parsing a date field in an Excel sheet. Please contact support.'));
    }
    if (cell.value != null) {
      let mom = moment(cell.value, this.dateTimeFormat);
      if (mom.isValid()) {
        cell.value = mom.format('HH:mm');
      } else {
        parser.registerError(new Error('Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an invalid date. The format should be ' + this.dateTimeFormat));
      }
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class TimestampField extends Field<Cell> {

  constructor(private field: any, private dateTimeFormat: string = "DD/MM/YYYY HH:mm") {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): Cell {
    let cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (!(cell instanceof Cell)) {
      parser.registerError(new Error('Unexpected error while parsing a date field in an Excel sheet. Please contact support.'));
    }
    if (cell.value != null) {
      let mom = moment(cell.value, this.dateTimeFormat);
      if (mom.isValid()) {
        cell.value = mom.toISOString();
      } else {
        parser.registerError(new Error('Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an invalid date. The format should be ' + this.dateTimeFormat));
      }
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class DateTimeField extends Field<Cell> {

  constructor(private field: any, private format: string) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): Cell {
    let cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (!(cell instanceof Cell)) {
      parser.registerError(new Error('Unexpected error while parsing a datetime field in an Excel sheet. Please contact support.'));
    }
    if (cell.value != null) {
      let mom = moment(cell.value, this.format, true);
      if (!mom.isValid()) {
        parser.registerError(new Error('Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an invalid datetime. The format should be ' + this.format));
      }
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class MappedDependantField extends Field<any> {

  constructor(private field: any,
              private dependentField: any,
              private mapper: (initialFieldValue, alternativeFieldValue, initialCell?: Cell, alternativeCell?: Cell, parser?: Parser) => any) {
    super(field)
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    let dependentValue = parser.mapAny(this.dependentField, cellNameFunction);

    let mappedValue;
    const cell: Cell = Field.cellOrNull(value);
    const dependentCell: Cell = Field.cellOrNull(dependentValue);
    try {
      mappedValue = this.mapper(Field.getCellValue(value), Field.getCellValue(dependentValue), cell, dependentCell, parser);
    } catch (e) {
      parser.registerError(cell ? 'Cell ' + cell.cell + ' and ' + dependentCell ? 'Cell ' + dependentCell.cell + ' in sheet "' + cell.sheetName + '\" could not be mapped: ' + parseError(e) : e : e);
    }
    return mappedValue;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction) && parser.isEmpty(this.dependentField, cellNameFunction);
  }
}

export class MappedField extends Field<any> {
  constructor(private field: any, private mapper: (fieldValue, cell?: Cell, parser?: Parser) => any, private exporter?: (mappedValue) => any) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    let mappedValue;
    const cell: Cell = Field.cellOrNull(value);
    try {
      mappedValue = this.mapper(Field.getCellValue(value), cell, parser);
      if (cell) {
        cell.value = mappedValue;
      } else {
        value = mappedValue;
      }
    } catch (e) {
      parser.registerError(cell ? 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" could not be mapped: ' + parseError(e) : e);
    }
    return value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    super.export(this.exporter ? this.exporter(data) : data, sheet, cellNameFunction);
  }
}

export class RequiredField extends Field<any> {
  field: any;

  constructor(field: any) {
    super(field);
    this.field = field;
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    if (Field.getCellValue(value) === undefined) {
      const cell = Field.cellOrNull(value);
      parser.registerError(cell ? 'Please fill out cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\".'
        : 'A required object is missing. Please fill out ' + JSON.stringify(this.field));
    }
    return value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class RequiredConditionallyField extends Field<any> {
  field: any;
  required: boolean;

  constructor(field: any, required: boolean) {
    super(field);
    this.field = field;
    this.required = required;
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    if (this.required && Field.getCellValue(value) === undefined) {
      const cell = Field.cellOrNull(value);
      parser.registerError(cell ? 'Please fill out cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\".'
        : 'A required object is missing. Please fill out ' + JSON.stringify(this.field));
    }
    return value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class HardCodedField extends Field<any> {
  value: any;
  private readonly isFunction: boolean;

  constructor(value: (any | (() => any))) {
    super(undefined);
    this.value = value;
    this.isFunction = typeof value === "function";
  }

  getValue(parser: Parser, cellNameFunction): any {
    return this.isFunction ? this.value() : this.value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return true;
  }
}

export class ValidatedField extends Field<any> {

  constructor(private field: any, private validator) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    try {
      this.validator(Field.getCellValue(value), Field.cellOrNull(value));
    } catch (e) {
      const cell = Field.cellOrNull(value);
      parser.registerError('Error for cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\". ' + e);
    }
    return value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class RequiredIfField extends Field<any> {
  constructor(private field: any, private dependentFields: any[], private test?: (otherValue) => boolean) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    if (parser.isEmpty(this.field, cellNameFunction)) {
      if (this.dependentFieldsAreFilled(parser, cellNameFunction)) {
        const cell = Field.cellOrNull(value);
        parser.registerError(cell ? 'Please fill out cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\".'
          : 'A conditional required object is missing. Please fill out ' + JSON.stringify(this.field));
      }
    }
    return value;
  }

  private dependentFieldsAreFilled = (parser: Parser, cellNameFunction): boolean => {
    const check = this.test
      ? cell => this.test(Field.getCellValue(parser.mapAny(cell, cellNameFunction)))
      : cell => !parser.isEmpty(cell, cellNameFunction);
    return this.dependentFields.some(check);
  };

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class RequiredIfFieldEmpty extends Field<any> {
  constructor(private field: any, private dependentFields: string[]) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    let valueOthers = this.dependentFieldsAreEmpty(parser, cellNameFunction);
    let value = parser.mapAny(this.field, cellNameFunction);
    if (valueOthers && parser.isEmpty(this.field, cellNameFunction)) {
      const cell = Field.cellOrNull(value);
      parser.registerError(cell ? 'Please fill out cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\".'
        : 'A conditional required object is missing. Please fill out ' + JSON.stringify(this.field));
    }
    return value;
  }

  private dependentFieldsAreEmpty(parser: Parser, cellNameFunction): boolean {
    let valueOthers = false;
    this.dependentFields.forEach(value1 => {
      if (parser.isEmpty(value1, cellNameFunction)) {
        valueOthers = true;
      }
    });
    return valueOthers;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class NonNegativeQuantityField extends Field<number> {
  constructor(private field: any, private defaultValue: number = null) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    const cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (cell.value == null) {
      cell.value = this.defaultValue;
    } else if (isNaN(cell.value) || cell.value < 0) {
      parser.registerError('Please fill out cell ' + cell.cell + ' of sheet "' + cell.sheetName + '\" with a valid positive number.')
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class QuantityField extends Field<number> {
  constructor(private field: any, private defaultValue: number = null) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    const cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (cell.value == null) {
      cell.value = this.defaultValue;
    } else if (isNaN(cell.value)) {
      parser.registerError('Please fill out cell ' + cell.cell + ' of sheet "' + cell.sheetName + '\" with a valid number.')
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class Cell {
  value: any;
  cell: any;
  sheetName: any;
  address: CellAddress;

  constructor(value: any, cell: any, sheetName: any, address: CellAddress) {
    this.value = value;
    this.cell = cell;
    this.sheetName = sheetName;
    this.address = address;
  }
}

export class Parser {
  sheetName: string;
  workSheet: WorkSheet;
  errors: Error[] = [];
  observables: Observable<any>[] = [];

  constructor(workBook: WorkBook, sheetName: string) {
    this.sheetName = sheetName;
    this.workSheet = workBook.Sheets[sheetName];
  }

  mapAny = (value: any, cellNameFunction) => {
    switch (typeof value) {
      case 'string' :
        const cellName = cellNameFunction(value);
        const cell = this.workSheet[cellName];
        const address = XLSX.utils.decode_cell(cellName);
        return new Cell(cell && (cell.t === 's' ? cell.v.trim() : cell.v), cellName, this.sheetName, address);
      default :
        return Array.isArray(value) ? this.mapArray(value, cellNameFunction)
          : value instanceof Field ? value.getValue(this, cellNameFunction)
            : this.mapObject(value, cellNameFunction);
    }
  };

  private mapArray(array: any[], cellNameFunction) {
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      let field = this.mapAny(array[i], cellNameFunction);
      if (notNull(field)) {
        if (field instanceof Cell) {
          field = field.value;
        }
        const index = newArray.push(field) - 1;
        if (field instanceof Observable) {
          field = field.pipe(catchError(e => {
            this.registerError(e);
            return of(null);
          }));
          field = field.pipe(map(r => newArray[index] = r));
          this.registerObservable(field);
        }
      }
    }
    return newArray;
  }

  private mapObject(object: any, cellNameFunction): any {
    const result = {};
    let emptyObject = true;
    Object.keys(object).forEach(key => {
      let field = this.mapAny(object[key], cellNameFunction);
      if (field instanceof Cell) {
        field = field.value;
      }
      if (isNonEmpty(field)) {
        emptyObject = false;
      }
      result[key] = field;
      if (field instanceof Observable) {
        field = field.pipe(catchError(e => {
          this.registerError(e);
          return of(null);
        }));
        field = field.pipe(map(r => result[key] = r));
        this.registerObservable(field);
      }
    });
    return emptyObject ? null : result;

    function isNonEmpty(field) {
      if (field === 0 || Array.isArray(field)) {
        return true;
      }
      return !field ? false : typeof field === 'object' ? !lodash.isEmpty(field) : true;
    }
  }

  isEmpty = (value: any, cellNameFunction) => {
    switch (typeof value) {
      case 'string' :
        const cellName = cellNameFunction(value);
        const cellValue = this.workSheet[cellName];
        return !cellValue || (cellValue.t === 's' && !cellValue.v.trim());
      default :
        return Array.isArray(value) ? this.isEmptyArray(value, cellNameFunction)
          : value instanceof Field ? value.isEmpty(this, cellNameFunction)
            : this.isEmptyObject(value, cellNameFunction);
    }
  };

  private isEmptyArray(array: any[], cellNameFunction) {
    for (let i = 0; i < array.length; i++) {
      if (!this.isEmpty(array[i], cellNameFunction)) {
        return false;
      }
    }
    return true;
  }

  private isEmptyObject(object: any, cellNameFunction) {
    for (const key of Object.keys(object)) {
      if (!this.isEmpty(object[key], cellNameFunction)) {
        return false;
      }
    }
    return true;
  }

  registerError(error: any): void {
    this.errors.push(error);
  }

  registerObservable(observable: Observable<any>): void {
    this.observables.push(observable);
  }
}

function parseError(e: any) {
  return e instanceof Error ? e.message : typeof e === 'string' ? e : JSON.stringify(e);
}

function notNull<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

function isEmptyRow(row: number, workSheet: WorkSheet) {
  const regExp = new RegExp('^[A-Z]+' + row + '$');
  let filledFields = Object.keys(workSheet).filter(name => regExp.test(name));
  return filledFields.length === 0;
}
