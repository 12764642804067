<div class="row always-enabled mt-2">
  <div class="col-md-12">
    <div class="row g-0 justify-content-end">
      <div class="col-md-auto">
      </div>
      <div *ngIf="appContext.isAdmin()" class="dropdown col-md-auto my-2 my-md-0">
        <button class="btn btn-secondary dropdown-toggle" type="button"
                style="min-width: 10rem;" data-bs-toggle="dropdown">Messages
          <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li *ngIf="!!handling.voyageId"
              (click)="openOutgoingPrenotification()" class="dropdown-item">COPINO out -> APERAK in (prenotification)
          </li>
          <li (click)="openOutgoingRequests()" class="dropdown-item">COPINO out -> APERAK in (request)</li>
          <li *ngIf="!!handling?.handlingDeclaration?.processId"
              (click)="openIncomingCopinoMessages()" class="dropdown-item">COPINO in -> APERAK out
          </li>
          <li *ngIf="!!handling?.handlingResult?.messageId"
              (click)="openCodecoInMessages()" class="dropdown-item">CODECO in
          </li>
          <li *ngIf="!!handling?.handlingResult?.messageId"
              (click)="openCodecoOutMessages()" class="dropdown-item">CODECO out
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
