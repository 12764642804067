<div class="mx-auto mb-5 mt-4">

  <!-- Search -->
  <div class="mt-3">
    <div class="row">
      <div class="col-md-3 d-sm-block pb-2 pb-md-0">
          <app-date-time required [dateOnly]="true" [(ngModel)]="date" (ngModelChange)="getCurrentTimetableSlots()"></app-date-time>
      </div>
      <div class="col-md-5 d-sm-block pb-2 pb-md-0">
        <app-search [(ngModel)]="searchResult" [searchFunction]="search" [inputFormatter]="searchResultFormatter"
                    (ngModelChange)="onEntryChange()" placeholder="Find a timetable entry"></app-search>
      </div>
      <div class="ms-auto col-auto">
        <button class="btn btn-info" type="button" (click)="newEntry()">
          <ng-container *ngIf="searchResult">Duplicate timetable entry</ng-container>
          <ng-container *ngIf="!searchResult">Add timetable entry</ng-container>
        </button>
      </div>
      <div class="ms-auto col-auto" *ngIf="!entry">
        <button class="btn btn-info" type="button" data-bs-toggle="modal" data-bs-target="#uploadModal" (click)="uploading=true">Upload timetable</button>
      </div>

    </div>

    <div class="row" *ngIf="!appContext.isPhone() && !entry">
      <div class="ms-auto col-auto"><a href="/assets/templates/rail-timetable-upload-template-1.2.xlsx"
                                       class="align-middle pe-2 text-portbase-dark-blue ms-2 small"
                                       tabindex="-1">Download timetable template <span class="fa fa-file-download"></span></a>
      </div>
    </div>


    <!-- List -->
    <div *ngIf="entry === undefined" class="mt-5 cursor-pointer">
      <div class="row align-items-center mx-1 mb-2 py-2 bg-light">
        <div class="col-sm-4 col-md-3 col-lg-2"
             (click)="sortBy = getSortDirection('terminal'); setTimetableEntries = timetableEntries">
          Terminal <span *ngIf="sortBy === 'terminal'" class="fa fa-sort-down"></span><span
          *ngIf="sortBy === 'terminalReversed'" class="fa fa-sort-up"></span>
        </div>
        <div class="d-none d-md-block col-md-3 col-lg-2"
             (click)="sortBy = getSortDirection('code'); setTimetableEntries = timetableEntries">
          Code <span *ngIf="sortBy === 'code'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'codeReversed'"
                                                                                    class="fa fa-sort-up"></span>
        </div>
        <div class="col-4 col-md-3 col-lg-2"
             (click)="sortBy = getSortDirection('declarant'); setTimetableEntries = timetableEntries">
          Rail operator <span *ngIf="sortBy === 'declarant'" class="fa fa-sort-down"></span><span
          *ngIf="sortBy === 'declarantReversed'" class="fa fa-sort-up"></span>
        </div>
        <div class="d-none d-lg-block col-lg-1"
             (click)="sortBy = getSortDirection('discharge'); setTimetableEntries = timetableEntries">
          #Discharge <span *ngIf="sortBy === 'totalDischarge'" class="fa fa-sort-down"></span><span
          *ngIf="sortBy === 'dischargeReversed'" class="fa fa-sort-up"></span>
        </div>
        <div class="d-none d-lg-block col-lg-1"
             (click)="sortBy = getSortDirection('loading'); setTimetableEntries = timetableEntries">
          #Loading <span *ngIf="sortBy === 'totalLoading'" class="fa fa-sort-down"></span><span
          *ngIf="sortBy === 'loadingReversed'" class="fa fa-sort-up"></span>
        </div>
        <div class="d-none d-lg-block col-lg-2"
             (click)="sortBy = getSortDirection('startDate'); setTimetableEntries = timetableEntries">
          Start date <span *ngIf="sortBy === 'startDate'" class="fa fa-sort-down"></span><span
          *ngIf="sortBy === 'startDateReversed'" class="fa fa-sort-up"></span>
        </div>
        <div class="col-4 col-md-3 col-lg-2"
             (click)="sortBy = getSortDirection('window'); setTimetableEntries = timetableEntries">
          Time window <span *ngIf="sortBy === 'window'" class="fa fa-sort-down"></span><span
          *ngIf="sortBy === 'windowReversed'" class="fa fa-sort-up"></span>
        </div>
      </div>

      <app-infinite-scroller [initialCount]="50">
        <ng-container *ngFor="let item of timetableEntries; let index = index">
          <ng-container [ngTemplateOutlet]="itemTemplate"
                        [ngTemplateOutletContext]="{ item : item, index: index }"></ng-container>
        </ng-container>
      </app-infinite-scroller>

      <ng-template #itemTemplate let-timetableEntry="item" let-index="index">
        <div class="row align-items-center item mx-1 my-2 py-2 border border-light" *ngIf="cast(timetableEntry) as item"
             (click)="onEntrySelected(timetableEntry)">
          <div class="col-sm-4 col-md-3 col-lg-2 text-wrap text-truncate" title="{{item.terminal.shortName}}">
            {{item.terminal.shortName}}
          </div>
          <div class="d-none d-md-block col-md-3 col-lg-2 text-wrap text-truncate" title="{{item.code}}">
            <span>{{item.code}}</span>
          </div>
          <div class="col-4 col-md-3 col-lg-2 text-wrap text-truncate" title="{{item.declarant?.fullName}}">
            {{item.declarant?.fullName || '??'}}
          </div>
          <div class="d-none d-lg-block col-lg-1">
            {{item.totalDischarge}}
          </div>
          <div class="d-none d-lg-block col-lg-1">
            {{item.totalLoading}}
          </div>
          <div class="d-none d-lg-block col-lg-2">
            <span class="text-nowrap" *ngIf="item.terminated">terminated on&nbsp;</span>
            <span class="text-nowrap">{{item.startDate | timestamp:'DD-MM-YYYY'}}</span>
              </div>
          <div class="col-4 col-md-3 col-lg-2">
            <span class="text-nowrap">{{item.window.start.day | titlecase}}  {{item.window.start.time | time: 'HH:mm':'HH:mm:ss'}}</span> -
            <span class="text-nowrap"><span *ngIf="item.window.start.day !== item.window.end.day">{{item.window.end.day | titlecase}}</span> {{item.window.end.time | time: 'HH:mm':'HH:mm:ss'}}</span>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Edit -->
    <div class="mx-0 row mt-5" style="display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: space-between" *ngIf="!!entry">
      <div style="width:150px">
        <button class="btn btn btn-outline-info" type="button" *ngIf="hasPreviousEntry()" (click)="previousEntry()">
          << Previous
        </button>
      </div>
      <div style="padding-top:8px;font-size: 14px;font-weight: bold;text-transform: uppercase;color: #666;">
        <span *ngIf="this.slot">{{entry.code}} <span *ngIf="slotIndex(entry)>-1">({{slotIndex(entry)+1}}/{{slot.length}})</span> <span *ngIf="entry.terminated" class="text-portbase-red"> (terminated)</span></span>
      </div>
      <div style="width:150px">
        <button class="btn btn btn-outline-info" style="float:right" type="button" *ngIf="hasNextEntry()" (click)="nextEntry()">
          Next >>
        </button>
      </div>
    </div>
    <div *ngIf="!!entry">
      <div class="declaration-block">
        <h2>Timetable entry details</h2>
      </div>
      <div class="row">
        <div class="col-md-6">
          <app-form-group *ngIf="appContext.isAdmin()">
            <label for="id">Id</label>
            <input id="id" class="form-control" [(ngModel)]="entry.id" disabled required>
          </app-form-group>
          <app-form-group>
            <label for="code">Timetable code</label>
            <input id="code" class="form-control" [(ngModel)]="entry.code" [disabled]="entry.terminated" required>
          </app-form-group>
          <app-form-group>
            <label for="terminal">Terminal</label>
            <app-search id="terminal" [(ngModel)]="entry.terminal" [searchFunction]="findTerminals"
                        [inputFormatter]="terminalFormatter" placeholder="Find a terminal"
                        [disabled]="!appContext.isAdmin() || entry.terminated" required></app-search>
          </app-form-group>
          <app-form-group>
            <label for="declarant">Rail operator</label>
            <app-search id="declarant" [(ngModel)]="entry.declarant" [searchFunction]="findDeclarants"
                        [inputFormatter]="declarantFormatter" placeholder="Find a rail operator" required [disabled]="entry.terminated"></app-search>
          </app-form-group>
        </div>
        <div class="col-md-6">
          <app-form-group>
            <label for="totalDischarge">Total discharge handlings</label>
            <input id="totalDischarge" class="form-control" [(ngModel)]="entry.totalDischarge" required [disabled]="entry.terminated">
          </app-form-group>
          <app-form-group>
            <label for="totalLoading">Total loading handlings</label>
            <input id="totalLoading" class="form-control" [(ngModel)]="entry.totalLoading" required [disabled]="entry.terminated">
          </app-form-group>
          <app-form-group>
            <label class="col-form-label">Start date</label>
            <app-date-time [(ngModel)]="entry.startDate" dateOnly="true" required [disabled]="entry.terminated"></app-date-time>
          </app-form-group>
          <app-form-group class="day-and-time-range">
            <label for="windowStartDay" class="col-form-label">Window start</label>
            <app-select id="windowStartDay" [(ngModel)]="entry.window.start.day" [options]="weekDays()"
                        [formatter]="toTitleCase" required [disabled]="entry.terminated">
            </app-select>
            <app-date-time [(ngModel)]="entry.window.start.time" timeOnly="true" required [disabled]="entry.terminated"></app-date-time>
          </app-form-group>
          <app-form-group class="day-and-time-range">
            <label for="windowEndDay">Window end</label>
            <app-select class="input-group" id="windowEndDay" [(ngModel)]="entry.window.end.day" [options]="weekDays()"
                        [formatter]="toTitleCase" required [disabled]="entry.terminated">
            </app-select>
            <app-date-time [(ngModel)]="entry.window.end.time" timeOnly="true" required [disabled]="entry.terminated"></app-date-time>
          </app-form-group>

        </div>
      </div>
    </div>
    <div class="my-2 row" *ngIf="entry">
      <div class="col-auto" *ngIf="searchResult">
        <button class="btn btn-danger px-4" type="button" *ngIf="searchResult" (click)="deleteEntry()">
          Delete
        </button>
      </div>
      <div class="col-auto" *ngIf="searchResult && !entry.terminated">
        <button class="btn btn-outline-danger" type="button" data-bs-toggle="modal" data-bs-target="#terminationDateModal">
          Terminate
        </button>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-outline-info px-4" type="button" (click)="cancel()">
          Cancel
        </button>
      </div>
      <div class="col-auto" *ngIf="!searchResult">
        <button class="btn btn-info px-4" type="button" (click)="addEntry()">
          Add timetable entry
        </button>
      </div>
      <div class="col-auto" *ngIf="searchResult && !entry.terminated">
        <button class="btn btn-info px-4" type="button" (click)="updateEntry()">
          Save
        </button>
      </div>
    </div>

  </div>

  <div id="terminationDateModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="!!entry">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Terminate timetable entry</h5>
        </div>
        <div class="modal-body">
          <p>
            Please select the date on which this timetable entry should no longer be valid.
          </p>
          <app-date-time [(ngModel)]="terminationDate" dateOnly="true"></app-date-time>
        </div>
        <div class="modal-footer">
          <div class="container-fluid px-0">
            <div class="row g-0 justify-content-between">
              <div class="col-auto">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="width: 8rem">Cancel</button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem"
                        (click)="terminate()">Terminate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="uploadModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="uploading">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload timetable slots</h5>
        </div>
        <div class="modal-body">
          <p>
            Select the start date for the timetable slots in this upload:
          </p>
          <app-date-time [(ngModel)]="this.timetableUploadData.startDate" dateOnly="true" required></app-date-time>
          <p class="pt-4">
            Select the terminal for which you are uploading:
          </p>
          <app-search id="uploadTerminal" [(ngModel)]="this.timetableUploadData.terminal" [searchFunction]="findTerminals"  [inputFormatter]="terminalFormatter" placeholder="Find a terminal" required></app-search>
          <p class="pt-4">
            Select the document to upload:
          </p>
          <input id="timetableFile" [(ngModel)]="this.timetableUploadData.fileName" (change)="this.timetableUploadData.file=$event.target.files[0]" type="file" required>
        </div>
        <div class="modal-footer">
          <div class="container-fluid px-0">
            <div class="row g-0 justify-content-between">
              <div class="col-auto">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="uploading=false" style="width: 8rem">Cancel</button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem" (click)="uploadTimetableEntries($event)">Upload timetable
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
