<div [ngClass]="{'disabled': visit.arrived || integralPlannedVisit}" class="fieldset">
  <div class="row">
    <div class="col-md-6">
      <app-form-group>
        <label>Quay</label>
        <app-compare [disabled]="!visit?.requestedTerminal || !visit.terminal"
                     [otherValue]="visit?.requestedTerminal?.quayName"
                     [value]="visit?.terminal?.quayName" mode="PREVIOUS">
          <input [ngModel]="utils.getTerminalDisplayName(visit.terminal, visit.modality)" class="form-control" disabled>
        </app-compare>
      </app-form-group>

      <app-form-group *ngIf="visit.visitId">
        <label>Call ID</label>
        <input [(ngModel)]="visit.visitId" class="form-control" disabled>
      </app-form-group>
      <app-form-group>
        <label>Operator</label>
        <input [(ngModel)]="voyage.declarant.contact" class="form-control" disabled>
      </app-form-group>

      <app-form-group *ngIf="!!fixedWindows || !!visit.requestedVisitData.fixedWindow">
        <label for="fixedWindow">Fixed window</label>
        <app-compare [disabled]="!previousRequested || !current" [otherValue]="previousRequested?.fixedWindow?.code"
                     [value]="current.fixedWindow?.code" mode="PREVIOUS">
          <app-select [(ngModel)]="visit.requestedVisitData.fixedWindow" [formatter]="utils.fixedWindowFormatter"
                      [nullOption]="false" [options]="fixedWindows" dataKey="code" id="fixedWindow"></app-select>
        </app-compare>
      </app-form-group>

      <app-form-group>
        <label>ETA</label>
        <app-compare [disabled]="!previous || !current" [otherValue]="previous?.eta | timestamp"
                     [value]="current.eta | timestamp" mode="PREVIOUS">
          <app-date-time [(ngModel)]="integralPlannedVisit ? visit.plannedVisitData.eta : visit.visitData.eta"
                         required></app-date-time>
        </app-compare>
      </app-form-group>

      <app-form-group>
        <label>ETD</label>
        <app-compare [disabled]="!previous || !current" [otherValue]="previous?.etd | timestamp"
                     [value]="current.etd | timestamp" mode="PREVIOUS">
          <app-date-time [(ngModel)]="integralPlannedVisit ? visit.plannedVisitData.etd : visit.visitData.etd"
                         required></app-date-time>
        </app-compare>
      </app-form-group>

      <app-form-group *ngIf="voyage.integralPlanningRequested" disabled>
        <label>Has appointment</label>
        <app-compare [disabled]="!previous || !current" [otherValue]="previous?.appointment ? 'Yes' : 'No'"
                     [value]="current?.appointment ? 'Yes' : 'No'" mode="PREVIOUS">
          <app-yes-no [(ngModel)]="visit.requestedVisitData.appointment" [inline]="true" id="appointment"></app-yes-no>
        </app-compare>
      </app-form-group>

    </div>

    <div class="col-md-6">
      <app-form-group>
        <label>Barge</label>
        <app-compare [disabled]="!visit.previousVoyageData"
                     [otherValue]="utils.bargeFormatter(visit.previousVoyageData?.barge)"
                     [value]="voyage.voyageData.barge" mode="PREVIOUS">
          <app-search [(ngModel)]="voyage.voyageData.barge" [inputFormatter]="utils.bargeFormatter"
                      [searchFunction]="utils.findBarges"
                      dataKey="name" disabled></app-search>
        </app-compare>
      </app-form-group>
      <app-form-group>
        <label>In-house discharge number</label>
        <app-compare [disabled]="!visit.previousVoyageData"
                     [otherValue]="visit.previousVoyageData?.inHouseDischargeNumber"
                     [value]="voyage.voyageData.inHouseDischargeNumber" mode="PREVIOUS">
          <input [(ngModel)]="voyage.voyageData.inHouseDischargeNumber" class="form-control" disabled>
        </app-compare>
      </app-form-group>
      <app-form-group>
        <label>In-house loading number</label>
        <app-compare [disabled]="!visit.previousVoyageData"
                     [otherValue]="visit.previousVoyageData?.inHouseLoadingNumber"
                     [value]="voyage.voyageData.inHouseLoadingNumber" mode="PREVIOUS">
          <input [(ngModel)]="voyage.voyageData.inHouseLoadingNumber" class="form-control" disabled>
        </app-compare>
      </app-form-group>
      <app-form-group [ngClass]="{'enabled': enabled}">
        <label>Terminal remarks</label>
        <textarea (ngModelChange)="onChangeTerminalRemarks($event)" [ngModel]="visit.plannedVisitData?.terminalRemarks"
                  class="form-control"
                  placeholder="Type any remarks for the operator here"
                  style="min-height: 6rem"></textarea>
      </app-form-group>
      <app-form-group *ngIf="visit.loadDischargeListEnabled" [ngClass]="{'enabled': enabled}">
        <label>Minimum call size</label>
        <input [(ngModel)]="visit.plannedVisitData.minimumCallSize" [placeholder]="visit.minimumCallSize"
               class="form-control">
      </app-form-group>
      <app-form-group *ngIf="voyage.integralPlanningRequested" [ngClass]="{'enabled': enabled}">
        <label>Has priority</label>
        <app-yes-no (ngModelChange)="onPriorityChange()" [(ngModel)]="visit.plannedVisitData.priority"
                    [inline]="true"></app-yes-no>
      </app-form-group>
      <app-form-group *ngIf="voyage.integralPlanningRequested && visit.plannedVisitData.priority"
                      [ngClass]="{'enabled': enabled}">
        <label>Priority window start</label>
        <app-date-time (ngModelChange)="onChangePriorityWindowStart($event)"
                       [ngModel]="visit.plannedVisitData.priorityWindow?.start"></app-date-time>
      </app-form-group>
      <app-form-group *ngIf="voyage.integralPlanningRequested && visit.plannedVisitData.priority"
                      [ngClass]="{'enabled': enabled}">
        <label>Priority window end</label>
        <app-date-time (ngModelChange)="onChangePriorityWindowEnd($event)"
                       [ngModel]="visit.plannedVisitData.priorityWindow?.end"></app-date-time>
      </app-form-group>
      <app-form-group *ngIf="this.viaTerminals.length > 1 && voyage.integralPlanningRequested"
                      [ngClass]="{'enabled': enabled}">
        <label>Via terminal</label>
        <app-select [(ngModel)]="visit.plannedVisitData.viaTerminal" [formatter]="terminalFormatter(visit.modality)"
                    [options]="viaTerminals" [selectedFormatter]="terminalFormatter(visit.modality)">
        </app-select>
      </app-form-group>
    </div>

    <ng-container *ngIf="integralPlannedVisit">
      <div class="col-md-6 declaration-block">
        <h2>Discharge</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
          <div class="col"># Out of gauge</div>
          <div class="col"># Twinnable</div>
          <div class="col"># In bundles</div>
        </div>

        <div class="row narrow-gutters py-2">
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.dischargeTotal"
                         [value]="current.estimatedHandlings.dischargeTotal" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.dischargeTotal" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.dischargeEmpty"
                         [value]="current.estimatedHandlings.dischargeEmpty" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.dischargeEmpty" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.dischargeOutOfGauge"
                         [value]="current.estimatedHandlings.dischargeOutOfGauge" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.dischargeOutOfGauge" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.dischargeTwinnable"
                         [value]="current.estimatedHandlings.dischargeTwinnable" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.dischargeTwinnable" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div *ngIf="voyage.integralPlanningRequested" class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.dischargeStacked"
                         [value]="current.estimatedHandlings.dischargeStacked" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.dischargeStacked" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
        </div>

        <app-form-group *ngIf="totalHandlings.dischargeStacked>0">
          <label>Amount of bundles</label>
          <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.dischargeBundles"
                       [value]="current.estimatedHandlings.dischargeBundles" mode="PREVIOUS">
            <input [(ngModel)]="totalHandlings.dischargeBundles" [maxDecimals]="0"
                   appDecimalNumber class="form-control" min="0">
          </app-compare>
        </app-form-group>
      </div>
      <div class="col-md-6 declaration-block">
        <h2>Loading</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
          <div class="col"># Out of gauge</div>
          <div class="col"># Twinnable</div>
          <div class="col"># In bundles</div>
        </div>

        <div class="row narrow-gutters py-2">
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingTotal"
                         [value]="current.estimatedHandlings.loadingTotal" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.loadingTotal" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingEmpty"
                         [value]="current.estimatedHandlings.loadingEmpty" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.loadingEmpty" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.loadingOutOfGauge"
                         [value]="current.estimatedHandlings.loadingOutOfGauge" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.loadingOutOfGauge" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.loadingTwinnable"
                         [value]="current.estimatedHandlings.loadingTwinnable" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.loadingTwinnable" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div *ngIf="voyage.integralPlanningRequested" class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingStacked"
                         [value]="current.estimatedHandlings.loadingStacked" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.loadingStacked" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
        </div>

        <div *ngIf="voyage.integralPlanningRequested">
          <app-form-group *ngIf="current.estimatedHandlings.loadingStacked>0">
            <label>Amount of bundles</label>
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingBundles"
                         [value]="current.estimatedHandlings.loadingBundles" mode="PREVIOUS">
              <input [(ngModel)]="totalHandlings.loadingBundles" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </app-form-group>
        </div>
      </div>
      <div class="col-md-6 declaration-block">
        <h2 *ngIf="voyage.integralPlanningRequested">Other</h2>
        <app-form-group>
          <label>Shifters</label>
          <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.shifters"
                       [value]="current.estimatedHandlings.shifters" mode="PREVIOUS">
            <input [(ngModel)]="totalHandlings.shifters" [maxDecimals]="0" appDecimalNumber
                   class="form-control" min="0">
          </app-compare>
        </app-form-group>
        <app-form-group *ngIf="voyage.integralPlanningRequested">
          <label>Cranes usable</label>
          <app-compare [disabled]="!previous || !current" [otherValue]="previous?.cranesUsable"
                       [value]="current.cranesUsable" mode="PREVIOUS">
            <input [(ngModel)]="visit.visitData.cranesUsable" [maxDecimals]="0" appDecimalNumber
                   class="form-control"
                   min="0">
          </app-compare>
        </app-form-group>
      </div>
    </ng-container>

    <ng-container *ngIf="!integralPlannedVisit">
      <div class="col-md-6 declaration-block">
        <h2>Discharge</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
          <div class="col"># Out of gauge</div>
          <div class="col"># Twinnable</div>
          <div *ngIf="voyage.integralPlanningRequested" class="col"># In bundles</div>
        </div>

        <div class="row narrow-gutters py-2">
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.dischargeTotal"
                         [value]="current.estimatedHandlings.dischargeTotal" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeTotal" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.dischargeEmpty"
                         [value]="current.estimatedHandlings.dischargeEmpty" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeEmpty" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.dischargeOutOfGauge"
                         [value]="current.estimatedHandlings.dischargeOutOfGauge" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeOutOfGauge" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.dischargeTwinnable"
                         [value]="current.estimatedHandlings.dischargeTwinnable" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeTwinnable" [maxDecimals]="0"
                     appDecimalNumber class="form-control" min="0">
            </app-compare>
          </div>
          <div *ngIf="voyage.integralPlanningRequested" class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.dischargeStacked"
                         [value]="current.estimatedHandlings.dischargeStacked" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeStacked" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
        </div>

        <div *ngIf="voyage.integralPlanningRequested">
          <app-form-group *ngIf="current.estimatedHandlings.dischargeStacked>0">
            <label>Amount of bundles</label>
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.dischargeBundles"
                         [value]="current.estimatedHandlings.dischargeBundles" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeBundles" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </app-form-group>
        </div>
      </div>
      <div class="col-md-6 declaration-block">
        <h2>Loading</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
          <div class="col"># Out of gauge</div>
          <div class="col"># Twinnable</div>
          <div *ngIf="voyage.integralPlanningRequested" class="col"># In bundles</div>
        </div>

        <div *ngIf="visit.modality === 'barge' && visit.plannedVisitData.estimatedHandlings"
             class="row narrow-gutters py-2">
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingTotal"
                         [value]="current.estimatedHandlings.loadingTotal" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingTotal" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingEmpty"
                         [value]="current.estimatedHandlings.loadingEmpty" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingEmpty" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.loadingOutOfGauge"
                         [value]="current.estimatedHandlings.loadingOutOfGauge" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingOutOfGauge" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
          <div class="col">
            <app-compare [disabled]="!previous || !current"
                         [otherValue]="previous?.estimatedHandlings?.loadingTwinnable"
                         [value]="current.estimatedHandlings.loadingTwinnable" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingTwinnable" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
          <div *ngIf="voyage.integralPlanningRequested" class="col">
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingStacked"
                         [value]="current.estimatedHandlings.loadingStacked" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingStacked" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </div>
        </div>

        <div *ngIf="voyage.integralPlanningRequested">
          <app-form-group *ngIf="current.estimatedHandlings.loadingStacked>0">
            <label>Amount of bundles</label>
            <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.loadingBundles"
                         [value]="current.estimatedHandlings.loadingBundles" mode="PREVIOUS">
              <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingBundles" [maxDecimals]="0"
                     appDecimalNumber
                     class="form-control" min="0">
            </app-compare>
          </app-form-group>
        </div>
      </div>
      <div class="col-md-6 declaration-block">
        <h2>Other</h2>
        <app-form-group>
          <label>Shifters</label>
          <app-compare [disabled]="!previous || !current" [otherValue]="previous?.estimatedHandlings?.shifters"
                       [value]="current.estimatedHandlings.shifters" mode="PREVIOUS">
            <input [(ngModel)]="visit.visitData.estimatedHandlings.shifters" [maxDecimals]="0" appDecimalNumber
                   class="form-control" min="0">
          </app-compare>
        </app-form-group>
        <app-form-group *ngIf="voyage.integralPlanningRequested">
          <label>Cranes usable</label>
          <app-compare [disabled]="!previous || !current" [otherValue]="previous?.cranesUsable"
                       [value]="current.cranesUsable" mode="PREVIOUS">
            <input [(ngModel)]="visit.visitData.cranesUsable" [maxDecimals]="0" appDecimalNumber
                   class="form-control"
                   min="0">
          </app-compare>
        </app-form-group>
      </div>
    </ng-container>
  </div>

</div>
