import {Component, Input} from '@angular/core';
import {BinnenhavengeldSubscription} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-bhg-subscription',
  templateUrl: './bhg-subscription.component.html',
  styleUrls: ['./bhg-subscription.component.scss']
})
export class BhgSubscriptionComponent {
  superOrAdmin = AppContext.isBinnenhavengeldSuperUserOrAdmin();

  @Input() subscription: BinnenhavengeldSubscription;

}
