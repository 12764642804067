import {Component, ElementRef, OnInit} from '@angular/core';
import {
  CreateVoyage,
  GetCurrentOrganisationPreferences,
  Modality,
  OrganisationPreferences
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {ActivatedRoute, Router} from '@angular/router';
import {checkValidity, sendCommand, sendQuery, uuid} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {HinterlandUtils} from '../../hinterland-utils';

@Component({
  selector: 'app-create-voyage',
  templateUrl: './create-voyage.component.html',
  styleUrls: ['./create-voyage.component.css']
})
export class CreateVoyageComponent implements OnInit {

  appContext = AppContext;
  utils = HinterlandUtils;
  command: CreateVoyage;
  integralPlanningEnabled: boolean;
  shareWithNextlogicEnabled: boolean;

  modality: Modality;

  constructor(private element: ElementRef, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.modality = queryParams.modality || 'barge';
      this.command = <CreateVoyage>{
        voyageId: uuid(),
        declarant: this.appContext.userProfile.organisation,
        voyageData: { modality: this.modality },
        restrictions: []
      };
      if (this.modality === 'barge') {
        sendQuery("com.portbase.common.api.authentication.query.GetCurrentOrganisationPreferences", <GetCurrentOrganisationPreferences>{})
          .subscribe((result: OrganisationPreferences) => {
            this.integralPlanningEnabled = result.shareWithNextlogicEnabled
              && result.integralPlanningEnabled && this.appContext.application.integralPlanningEnabled;
            this.shareWithNextlogicEnabled = result.shareWithNextlogicEnabled;
          });
      }
    });
  }

  createVoyage() {
    if (checkValidity(this.element)) {
      const command = this.modality === 'barge' ? {
        '@class': 'io.fluxcapacitor.javaclient.common.Message',
        payload: this.command,
        metadata: {
          'shareWithNextlogicRequested': this.shareWithNextlogicEnabled,
          'integralPlanningRequested': this.integralPlanningEnabled
        }
      } : this.command;

      sendCommand('com.portbase.hinterland.api.common.command.CreateVoyage', command,
        () => {
          this.appContext.registerSuccess('The voyage was created successfully');
          this.router.navigateByUrl("/rotation/" + this.command.voyageId, {
            state: {
              voyage: {
                voyageId: this.command.voyageId,
                declarant: this.command.declarant,
                voyageData: this.command.voyageData,
                visits: [],
                modality: this.modality,
                type: this.modality + "Voyage",
                cancelled: false,
                cargoDeclarants: [this.command.declarant],
                integralPlanningRequested: this.integralPlanningEnabled,
                shareWithNextlogicRequested: this.shareWithNextlogicEnabled
              }
            }
          });
        });
    }
  }
}
