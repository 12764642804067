import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {lodash} from "../../../../../common/utils";
import {AppContext} from "../../../../../app-context";
import {AbstractValueAccessorComponent} from "../../../../../common/component/value-accessor.component";

/**
 * Component used to filter (search in) a list of results
 */
@Component({
  selector: 'app-overview-filter',
  templateUrl: './overview-filter.component.html',
  styleUrls: ['./overview-filter.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OverviewFilterComponent), multi: true}
  ],
  host: {'class': 'input-group'}
})
export class OverviewFilterComponent extends AbstractValueAccessorComponent<string> implements OnInit {

  filterTerm: string;

  @Input() searchFunction: (arg: string) => Observable<any[]> | any[];
  @Input() getAllFunction: (arg: void) => Observable<any[]> | any[];

  @Input() placeholder = '';
  @Input() disabled: boolean;
  @Input() minCharacters: number = 1;
  @Input() id;

  @Output() resultsFound = new EventEmitter<any[]>();
  @Output() filterTermChanged = new EventEmitter<String>();
  typingDelayTimer;
  searching: boolean = false;

  ngOnInit() {
    if (!this.searchFunction) {
      throw new Error('Attribute searchFunction is required for app-filter component');
    }
    if (!this.getAllFunction) {
      this.getAllFunction = () => this.searchFunction('');
    }
  }

  get value(): string {
    return this.filterTerm;
  }

  writeValue(value: string): void {
    this.filterTerm = value || '';
  }

  onInput(value: string) {
    const oldValue = this.filterTerm;
    this.filterTerm = value || '';
    this.filterTermChanged.emit(this.filterTerm);
    this.onModelChange();
    if(!AppContext.isAdmin() || this.filterTerm.length == 0 || this.filterTerm.length > 2){
      clearTimeout(this.typingDelayTimer);
      this.typingDelayTimer = setTimeout(this.search, 300, oldValue);
    }
  }

  search = (oldValue: string) => {
    if (!this.searching) {
      this.searching = true;
      const lastTerm = this.filterTerm;
      let o = lastTerm && lastTerm.length >= this.minCharacters ? this.searchFunction(lastTerm)
        : oldValue && oldValue.length >= this.minCharacters ? this.getAllFunction() : null;
      if (o) {
        if (lodash.isArray(o)) {
          this.onResults(<any[]> o);
        } else {
          (<Observable<any[]>>o).subscribe((results : any[]) => {
            this.onResults(results);
            if (lastTerm !== this.filterTerm) {
              this.search(lastTerm);
            }
          })
        }
      } else {
        this.searching = false;
      }
    }
  };

  private onResults(results : any[]) {
    this.resultsFound.emit(results);
    this.searching = false;
  }
}
