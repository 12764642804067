import {forkJoin, Observable} from "rxjs";
import {InjectorProvider} from "../common/injector-provider";
import {QueryAndCommandGateway} from "../hinterland/query-and-command-gateway.service";
import {
  DateRange,
  DetachedHandling,
  EventSourcedModel,
  RoadVoyage
} from "@portbase/hinterland-service-typescriptmodels";
import {switchMap} from "rxjs/operators";
import {EventGateway, EventHandler, EventType} from "../common/event-gateway";
import {applyJsonPatchNewMca} from "../common/json-utils";
import {Terminal} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import moment from "moment/moment";
import {Injectable} from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class AppData {

  public roadItems: SearchableItem[] = [];

  private eventGateway: EventGateway;
  private queryAndCommandGateway: QueryAndCommandGateway;
  private dateRange: DateRange;
  private isAdminOrTerminal: boolean;

  public fetchRoadItems(dateRange: DateRange, filterTerm: string, isAdminOrTerminal: boolean, maxHits: number, terminal: Terminal): Observable<SearchableItem[]> {
    if (!this.eventGateway) {
      this.eventGateway = InjectorProvider.injector.get(EventGateway);
      this.queryAndCommandGateway = InjectorProvider.injector.get(QueryAndCommandGateway);
      this.eventGateway.registerLocalHandler(this);
    }
    this.dateRange = dateRange;
    this.isAdminOrTerminal = isAdminOrTerminal;
    return this.getRoadItems(dateRange, filterTerm, isAdminOrTerminal, maxHits, terminal)
      .pipe(switchMap(result => this.combineAndSetRoadItems(result[0], result[1])))
  }

  private getRoadItems(dateRange: DateRange, filterTerm: string, isAdminOrTerminal: boolean, maxHits: number, terminal: Terminal): Observable<any> {
    const maxHitsPerQuery = !!maxHits && maxHits > 0 ? maxHits / 2 : maxHits;
    if (isAdminOrTerminal) {
      return forkJoin([
        this.queryAndCommandGateway.findRoadVoyages(dateRange, filterTerm, maxHitsPerQuery, terminal),
        this.queryAndCommandGateway.findRoadDetachedHandlings(dateRange, filterTerm, false, maxHitsPerQuery)
      ]);
    } else {
      return forkJoin([
        this.queryAndCommandGateway.getRoadVoyages(dateRange, maxHitsPerQuery, terminal),
        this.queryAndCommandGateway.getRoadDetachedHandlings(dateRange, false, maxHitsPerQuery)
      ]);
    }
  }

  private combineAndSetRoadItems(voyages: RoadVoyage[], detachedHandlings: DetachedHandling[]): Observable<SearchableItem[]> {
    return new Observable<SearchableItem[]>((subscriber) => {
      const items: SearchableItem[] = [];
      for (const voyage of voyages) {
        items.push(new SearchableItem(voyage, voyage.voyageId, true));
      }
      for (const detachedHandling of detachedHandlings) {
        items.push(new SearchableItem(detachedHandling, detachedHandling.handlingId, false));
      }
      this.roadItems = items;
      subscriber.next(items)
    })
  }

  public onPatchVoyage: EventHandler<EventType.PatchVoyage> = (patch: any) => {
    if (patch.modality !== 'road') {
      return;
    }

    const searchableItem: SearchableItem = this.roadItems.find(item => item.isVoyage && item.id === patch.id);
    let voyage: RoadVoyage = undefined;

    try {
      if (searchableItem !== undefined) {
        voyage = searchableItem.getVoyage();
        applyJsonPatchNewMca(voyage, patch);
        searchableItem.makeSearchable();
      } else if (this.isNewObjectPatch(patch)) {
        const tempVoyages = [];
        const success = applyJsonPatchNewMca(tempVoyages, patch);
        if (success && tempVoyages.length === 1) {
          voyage = tempVoyages[0];
          const searchableItem = new SearchableItem(voyage, voyage.voyageId, true);
          this.roadItems.push(searchableItem);
        }
      } else if (!this.isAdminOrTerminal && (!this.dateRange || this.patchBringsVisitWithinDateRange(patch))) {
        this.queryAndCommandGateway.getVoyage(patch.id)
          .subscribe(voyage => {
              this.roadItems.push(new SearchableItem(voyage, voyage.voyageId, true));
              this.eventGateway.publish(EventType.PatchApplied, patch.id);
            }
          );
      }

      if (voyage !== undefined) {
        this.eventGateway.publish(EventType.PatchApplied, patch.id);
      }
    } catch (error) {
      // don't know how to handle JsonPatchErrors
    }
  }

  public onPatchDetachedHandling: EventHandler<EventType.PatchDetachedHandling> = (patch) => {
    if (patch.modality !== 'road') {
      return;
    }

    const searchableItem: SearchableItem = this.roadItems.find(item => !item.isVoyage && item.id === patch.id);
    let detachedHandling: DetachedHandling = undefined;

    if (searchableItem !== undefined) {
      detachedHandling = searchableItem.getDetachedHandling();
      const success = applyJsonPatchNewMca(detachedHandling, patch);
      if (success) {
        if (detachedHandling.attached === true) {
          this.removeRoadItem(detachedHandling.handlingId);
        } else {
          searchableItem.makeSearchable();
        }
      }
    } else if (this.isNewObjectPatch(patch)) {
      const tempDetachedHandlings = [];
      const success = applyJsonPatchNewMca(tempDetachedHandlings, patch);
      if (success && tempDetachedHandlings.length === 1) {
        detachedHandling = tempDetachedHandlings[0];
        this.roadItems.push(new SearchableItem(detachedHandling, detachedHandling.handlingId, false));
      }
    }

    if (detachedHandling !== undefined) {
      this.eventGateway.publish(EventType.PatchApplied, patch.id);
    }
  }

  /**
   * A patch is for a new object if:
   *  - there is only 1 patch entry
   *  - the patch entry has operation 'add'
   *  - the path is on root level. So /0 or /99999999999 etc. AND NOT for example /visits/0 etc.
   */
  private isNewObjectPatch(patch): boolean {
    const patches = patch.value;
    if (patches.length === 1 && patches[0].op === 'add') {
      //path: /0     path.split: undefined & 0      so we want a length of 2
      return patches[0].path.split('/').length === 2;
    }
    return false;
  }

  private removeRoadItem(id) {
    for (let i = 0; i < this.roadItems.length; ++i) {
      const item = this.roadItems[i];
      if (item.id === id) {
        this.roadItems.splice(i, 1);
        return;
      }
    }

    console.error(`Cannot remove Item because not found (ID: ${id})`)
  }

  private patchBringsVisitWithinDateRange(patch: any) {
    return patch.value.filter(item => item.path.endsWith("visitData/eta"))
      .map(item => item.value)
      .some(eta => moment(eta).isBetween(this.dateRange.start, this.dateRange.end));
  }
}

export class SearchableItem {
  private readonly _item: EventSourcedModel<any>;
  private readonly _id: string;
  private readonly _isVoyage: boolean;

  private _searchString: string;

  constructor(item: EventSourcedModel<any>, id: string, isVoyage: boolean) {
    this._item = item;
    this._id = id;
    this._isVoyage = isVoyage;

    this.makeSearchable();
  }

  get id(): string {
    return this._id;
  }

  get isVoyage(): boolean {
    return this._isVoyage;
  }

  getVoyage(): RoadVoyage {
    return <RoadVoyage>this._item;
  }

  getDetachedHandling(): DetachedHandling {
    return <DetachedHandling>this._item;
  }

  get searchString(): string {
    return this._searchString;
  }

  makeSearchable() {
    let jsonValues = '';
    JSON.stringify(this._item, (key, value) => {
      if (typeof value !== 'object') { //TODO Sub objects?
        jsonValues += `${value} `;
      }
      return value;
    });

    this._searchString = jsonValues.toLowerCase();
  }
}
