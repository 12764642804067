import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {HinterlandUtils} from '../../hinterland-utils';
import {RailVoyageData, Train} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import { toTitleCase } from 'src/app/common/utils';

@Component({
  selector: 'app-edit-rail-voyage',
  templateUrl: './edit-rail-voyage.component.html',
  styleUrls: ['./edit-rail-voyage.component.css']
})
export class EditRailVoyageComponent implements OnInit {
  utils = HinterlandUtils;

  @Input() isNew: boolean = false;
  @Input() voyageData: RailVoyageData;
  @Input() hasTemplate = false;

  constructor(public elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.voyageData.train) {
      this.voyageData.train = <Train>{};
    }
  }

  weekDays = () => HinterlandUtils.weekdays;
  toTitleCase = (value) => toTitleCase(value);

  formatIncrement = (increment) => {
    switch (increment) {
      case "NONE": return "No";
      case "LOADING_VOYAGE_ONLY": return "Loading voyage only";
      case "BOTH": return "Discharge and loading voyage";
    }
  }
}
