<!-- VOYAGE -->
<div class="row g-0 me-3 ms-3" *ngIf="!!integralPlanningVoyageStatus && !!integralPlanningVoyageStatus.pta">
  <div class="col">
    <div class="alert alert-dismissible fade show row integral-planning py-2 px-4" role="alert" style="">
      <app-collapse [collapse]="collapse" [showOnLoad]="false" [animateOnLoad]="false">
        <div class="row collapsible">
          <div class="col">
            <div><strong>Nextlogic</strong></div>
            <div>
              <span *ngIf="integralPlanningVoyageStatus.pta"><strong>PTA</strong> {{integralPlanningVoyageStatus.pta | timestamp}} </span>
              <span *ngIf="integralPlanningVoyageStatus.ptd"><strong>PTD</strong> {{integralPlanningVoyageStatus.ptd | timestamp}} </span>
            </div>
          </div>
          <div class="col-auto expand" style="">
            <h4 class="m-0 fa fa-chevron-right text-secondary"></h4>
          </div>
        </div>
        <div class="row">
          <ng-template #collapse>
            <div class="collapse">
              <div class="extra-information">
                <div class="row" *ngIf="integralPlanningVoyageStatus.eta">
                  <span class="col-sm-6"><strong>ETA</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.eta | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.etd">
                  <span class="col-sm-6"><strong>ETD</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.etd | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.ata">
                  <span class="col-sm-6"><strong>ATA</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.ata | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.atd">
                  <span class="col-sm-6"><strong>ATD</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.atd | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.plannedEntryPoint">
                  <span class="col-sm-6"><strong>Planned entry point</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.plannedEntryPoint.name}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.plannedExitPoint">
                  <span class="col-sm-6"><strong>Planned exit point</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.plannedExitPoint.name}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.actualEntryPoint">
                  <span class="col-sm-6"><strong>Actual entry point</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.actualEntryPoint.name}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.actualExitPoint">
                  <span class="col-sm-6"><strong>Actual exit point</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.actualExitPoint.name}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.netTime">
                  <span class="col-sm-6"><strong>Net time</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.netTime}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.slackTime">
                  <span class="col-sm-6"><strong>Slack time</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.slackTime}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVoyageStatus.normTime">
                  <span class="col-sm-6"><strong>Norm time</strong></span>
                  <span class="col-6">{{integralPlanningVoyageStatus.normTime}}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </app-collapse>
    </div>
  </div>
</div>

<!-- VISIT -->
<div class="row g-0 mt-1" *ngIf="visitView">
  <div class="col">
    <div class="alert alert-dismissible fade show integral-planning py-2 px-4" role="alert" style="">
      <app-collapse [collapse]="collapse" [showOnLoad]="false" [animateOnLoad]="false">
        <div class="row collapsible">
          <div class="col">
            <div><strong>Nextlogic</strong></div>
            <div>
              <span *ngIf="integralPlanningVisitStatus.pta"><strong>PTA</strong> {{integralPlanningVisitStatus.pta | timestamp}} </span>
              <span *ngIf="integralPlanningVisitStatus.ptd"><strong>PTD</strong> {{integralPlanningVisitStatus.ptd | timestamp}} </span>
            </div>
            <div class="update">
              <span *ngIf="integralPlanningVisitStatus.lastUpdate"><strong>Last update</strong> {{integralPlanningVisitStatus.lastUpdate | timestamp}}</span>
            </div>
          </div>
          <div class="col-auto expand" style="">
            <h4 class="m-0 fa fa-chevron-right text-secondary"></h4>
          </div>
        </div>
        <div>
          <ng-template #collapse>
            <div class="collapse">
              <div class="extra-information">
                <div class="row" *ngIf="integralPlanningVisitStatus.fromBollard">
                  <span class="col-sm-3"><strong>From bollard</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.fromBollard.number}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.toBollard">
                  <span class="col-sm-3"><strong>To bollard</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.toBollard.number}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.bestMooringPosition">
                  <span class="col-3"><strong>Best mooring position</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.bestMooringPosition.number}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.eta">
                  <span class="col-3"><strong>ETA</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.eta | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.etd">
                  <span class="col-3"><strong>ETD</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.etd | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.ata">
                  <span class="col-3"><strong>ATA</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.ata | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.atd">
                  <span class="col-3"><strong>ATD</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.atd | timestamp}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.totalNetTeu">
                  <span class="col-3"><strong>Total net teu</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.totalNetTeu}}</span>
                </div>
                <div class="row" *ngIf="integralPlanningVisitStatus.cranes">
                  <span class="col-3"><strong>Cranes</strong></span>
                  <span class="col-3">{{integralPlanningVisitStatus.cranes.length}}</span>
                </div>
                <div class="extra-information"></div>
                <div *ngIf="integralPlanningVisitStatus.plannedHandlings">
                  <div class="row">
                    <span class="col-3"><strong>Planned handlings</strong></span>
                    <span class="col-3"></span>
                  </div>
                  <div class="row">
                    <span class="col-sm-3 indent">Discharge total</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.dischargeTotal}}</span>
                    <span class="col-sm-3 indent">Loading total</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.loadingTotal}}</span>
                  </div>
                  <div class="row">
                    <span class="col-sm-3 indent"># Empty</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.dischargeEmpty}}</span>
                    <span class="col-sm-3 indent"># Empty</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.loadingEmpty}}</span>
                  </div>
                  <div class="row">
                    <span class="col-3 indent"># Out of gauge</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.dischargeOutOfGauge}}</span>
                    <span class="col-3 indent"># Out of gauge</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.loadingOutOfGauge}}</span>
                  </div>
                  <div class="row">
                    <span class="col-3 indent"># Twinnable</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.dischargeTwinnable}}</span>
                    <span class="col-3 indent"># Twinnable</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.loadingTwinnable}}</span>
                  </div>
                  <div class="row">
                    <span class="col-3 indent"># In bundles</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.dischargeStacked}}</span>
                    <span class="col-3 indent"># In bundles</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.loadingStacked}}</span>
                  </div>
                  <div class="row">
                    <span class="col-3 indent">Amount of bundles</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.dischargeBundles}}</span>
                    <span class="col-3 indent">Amount of bundles</span>
                    <span class="col-3">{{integralPlanningVisitStatus.plannedHandlings.loadingBundles}}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </app-collapse>
    </div>
  </div>
</div>
