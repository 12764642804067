import {AppContext} from '../../app-context';
import {
  ArrayTemplate,
  Cell,
  DateField,
  exportExcel,
  getWorkbook,
  LowercaseField,
  MappedField,
  NonNegativeQuantityField,
  parseExcel,
  RequiredField,
  RequiredIfField,
  TimestampField,
  toBase64,
  UppercaseField,
  ValidatedField
} from '../../common/upload/excel.utils';
import {cloneObject, lodash, sendCommand, sendQuery} from '../../common/utils';
import {
  GetLocation,
  GetOrganisation,
  GetShippingCompany,
  GetSizeType,
  SeaVessel,
  Shipment
} from '@portbase/hinterland-service-typescriptmodels';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Organisation} from '../hinterland-utils';
import {DeclareShipments, HinterlandOrganisation} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import moment from 'moment';
import {RowInfo, utils, WorkBook, WorkSheet} from 'xlsx';


export class ShipmentExcelUtils {
  static uploadShipments(excelFile: File) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
      getWorkbook(excelFile).subscribe((workBook : WorkBook) => {
        const guide = workBook.Sheets[workBook.SheetNames.find(name => name === 'Guide')];
        if (!guide) {
          AppContext.registerError('Could not find guide sheet in the uploaded Excel file');
          return;
        }
        const version = guide['C1']?.v?.trim();
        if (!version) {
          AppContext.registerError('Could not find the version of the uploaded workbook');
          return;
        }
        const template
          = lodash.startsWith(version, "v2.") ? excelTemplate_v2
          : lodash.startsWith(version, "v3.0") ? excelTemplate_v3_0
            : excelTemplate_v3_1();

        parseExcel(excelFile, template).subscribe((command: DeclareShipments) => {
          if (command.shipments.length === 0) {
            AppContext.registerError('No shipments found in uploaded excel');
            return;
          }
          const message = {
            '@class': 'io.fluxcapacitor.javaclient.common.Message',
            payload: command,
            metadata: { 'upload': excelBase64 }
          };
          sendCommand("com.portbase.hinterland.api.shipment.command.DeclareShipments", message,
            () => AppContext.registerSuccess('Upload successful'));
        });
      });
    });
  }

  static downloadShipments(shipments: Shipment[]) {
    const data = { shipments: shipments.map(s => {
        const result = cloneObject(s.data);
        const seaMovement = s.movements.find(m => m.vessel?.modality === 'sea');
        const seaVessel = <SeaVessel> seaMovement.vessel;
        const item = seaMovement.item;
        const dropOff = lodash.last(seaMovement.itinerary);
        result.deepSeaEta = moment(dropOff.eta || s.data.deepSeaEta).format("DD/MM/YYYY HH:mm");
        (<any>result).deepSeaTerminal = result.deepSeaTerminal?.bicsCode || result.deepSeaTerminal?.smdgCode;
        (<any>result).deepSeaCarrier = result.deepSeaCarrier?.scacCode;
        result.deepSeaVesselName = seaVessel.name;
        result.deepSeaVesselImo = seaVessel.imoCode || result.deepSeaVesselImo;
        result.deepSeaVoyageNumber = seaMovement.transportReference || result.deepSeaVoyageNumber;
        (<any>result).sizeType = item.sizeType?.code;
        (<any>result).inlandTerminalLocation = result.inlandTerminalLocation?.locationUnCode;
        (<any>result).finalDestination = result.finalDestination?.locationUnCode || result.finalDestination?.name;
        (<any>result).releaseToParty = parseOrganisation(s.releaseToParty);
        (<any>result).cargoDirector = parseOrganisation(result.cargoDirector);
        (<any>result).inlandOperator = parseOrganisation(result.inlandOperator);

        (<any>result).goodName = concatGoodsProperty(s, "name");
        (<any>result).goodUnCode = concatGoodsProperty(s, "unCode");
        (<any>result).goodHazardClass = concatGoodsProperty(s, "hazardClass");
        (<any>result).goodRemarks = concatGoodsProperty(s, "remarks");
        (<any>result).goodPackaging = concatGoodsProperty(s, "packageType.name");
        (<any>result).goodPackages = concatGoodsProperty(s, "numberOfPackages");
        (<any>result).goodCommodityCode = concatGoodsProperty(s, "classification.code");

        (<any>result).sealNumber = seaMovement?.carrierSealNumber;
        (<any>result).cargoWeight = lodash.sumBy(s.goods, g => g.grossWeight) || result.cargoWeight;
        (<any>result).tareWeight = item.tareWeight || result.tareWeight;

        (<any>result).crn = seaMovement?.portbaseVoyageId;
        (<any>result).deepSeaEtd = moment(dropOff.etd).format("DD/MM/YYYY HH:mm");

        const order = result.transportOrder;
        if (order) {
          order.demurrage = order.demurrage && <any> moment.duration(order.demurrage).asDays();
          order.detention = order.detention && <any> moment.duration(order.detention).asDays();
          order.releaseExpiration = order.releaseExpiration && moment(order.releaseExpiration).format("DD/MM/YYYY HH:mm");
          order.destinationTargetDate = order.destinationTargetDate && moment(order.destinationTargetDate).format("DD/MM/YYYY");
          order.documentExpiration = order.documentExpiration && moment(order.documentExpiration).format("DD/MM/YYYY");
        }
        return result;
      }) };
    return exportExcel("/assets/templates/shipments-upload-template-3.1-download.xlsx", excelTemplate_v3_1(shipments.length), data);

    function parseOrganisation(o : HinterlandOrganisation) : string {
      return o && (o.customsEORINumber || o.chamberOfCommerceNumber || o.scacCode || o.ean || o.shortName);
    }

    function concatGoodsProperty(shipment: Shipment, propertyName: string) {
      return lodash.join(shipment.goods.map(g => lodash.get(g, propertyName)).map(g => g ? g : ''), "; ");
    }
  };
}

function sizeTypeMapper(code: string, cell: Cell) {
  if (!code) {
    return null;
  }
  return sendQuery('com.portbase.hinterland.api.common.query.GetSizeType', <GetSizeType>{code: code})
    .pipe(map(sizeType => {
      if (!sizeType) {
        throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown size type: ${code}`
      }
      return sizeType;
    }));
}

function locationMapper(locationUnCode: string) {
  if (!locationUnCode) {
    return null;
  }
  return sendQuery('com.portbase.hinterland.api.refdata.query.GetLocation', <GetLocation>{unCode: locationUnCode})
    .pipe(map(location => {
      return location;
    }));
}

function finalDestinationMapper(value: string) {
  if (!value) {
    return null;
  }
  return sendQuery('com.portbase.hinterland.api.refdata.query.GetLocation', <GetLocation>{unCode: value})
    .pipe(map(location => {
      return location || { name: value };
    }));
}

function shippingCompanyMapper(scacCode: string, cell: Cell) {
  if (!scacCode) {
    return null;
  }
  return sendQuery('com.portbase.hinterland.api.common.query.GetShippingCompany', <GetShippingCompany>{scacCode: scacCode})
    .pipe(map(shippingCompany => {
      if (!shippingCompany) {
        throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown Shipping Company: ${scacCode}`
      }
      return shippingCompany;
    }));
}

function organisationMapper(term: string, cell: Cell): Observable<Organisation> {
  if (!term) {
    return of(null);
  }
  return sendQuery('com.portbase.hinterland.api.common.query.GetOrganisation', <GetOrganisation>{term: term})
    .pipe(map(organisation => {
      if (!organisation) {
        throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown Organisation: ${term}`
      }
      return organisation;
    }));
}

function terminalMapper(code: string, cell: Cell) {
  if (!code) {
    return null;
  }
  return sendQuery('com.portbase.hinterland.api.refdata.query.GetTerminalByCode', {code: code})
    .pipe(map(terminal => {
      if (!terminal) {
        throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown terminal: ${code}`
      }
      return terminal;
    }));
}

const excelTemplate_v3_1 = (rows = 200) => ({
  sheets: [
    {
      name: "Guide",
      template: {
        version: new ValidatedField(new RequiredField('C1'), value => {
          if (value !== "v3.1") {
            throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
          }
        })
      }
    },
    {
      name: "Pickup",
      template: {
        shipments: new ArrayTemplate({
          transportReference: "B$",

          consignmentNumbers: [new UppercaseField(new RequiredField("F$"))],
          equipmentNumber: new UppercaseField(new RequiredField("G$")),
          deepSeaEta: new TimestampField(new RequiredField("H$")),
          deepSeaTerminal: new MappedField(new RequiredField("I$"), terminalMapper),
          deepSeaCarrier: new MappedField(new RequiredField("J$"), shippingCompanyMapper),
          sizeType: new MappedField(new RequiredField("K$"), sizeTypeMapper),
          deepSeaVesselName: "L$",
          deepSeaVesselImo: "M$",
          deepSeaVoyageNumber: "N$",
          releaseReference: "O$",

          inlandModality: new LowercaseField("U$"),
          inlandTerminalLocation: new RequiredIfField(new MappedField("V$", locationMapper), ["U$"], modality => modality === 'barge'),
          inlandTerminalCode: "W$",
          finalDestination: new MappedField("X$", finalDestinationMapper),

          cargoDirector: new MappedField("AB$", (o, cell) => organisationMapper(o || AppContext.userProfile.organisationShortName, cell)),
          inlandOperator: new MappedField("AC$", organisationMapper),

          transportOrder: {
            invoiceReference: "C$",
            debtorName: "D$",
            consigneeName: "E$",
            releaseExpiration: new TimestampField("P$"),
            demurrage: new MappedField(new NonNegativeQuantityField("Q$"), days => days && moment.duration(days, 'days').toISOString()),
            detention: new MappedField(new NonNegativeQuantityField("R$"), days => days && moment.duration(days, 'days').toISOString()),
            returnTerminal: "S$",
            returnReference: "T$",
            destinationTargetDate: new DateField("Y$"),
            destinationReference: "Z$",
            destinationInstructions: "AA$",
            customsCategory: "AD$",
            documentNumber: "AE$",
            documentExpiration: new DateField("AF$"),
          },

          //for download
          goodName: "AG$",
          goodUnCode: "AH$",
          goodHazardClass: "AI$",
          goodRemarks: "AJ$",
          goodPackaging: "AK$",
          goodPackages: "AL$",
          goodCommodityCode: "AM$",
          sealNumber: "AN$",
          cargoWeight: "AO$",
          tareWeight: "AP$",
          crn: "AQ$",
          deepSeaEtd: "AR$",
          releaseToParty: new MappedField("AS$", organisationMapper)
        }, [4, 4 + Math.max(200, rows) - 1])
      },
      resizeSheet: (sheet: WorkSheet) => {
        if (rows > 200) {
          const insertedRows = Array.from({ length: rows - 200 }).map((_, i) => [201 + i]);
          utils.sheet_add_aoa(sheet, insertedRows, { origin: -1 });
        }
        if (rows > 999) {
          sheet["!cols"][0].width = 5;
        }
      }
    }
  ]
});

const excelTemplate_v2 = {
  sheets: [
    {
      name: "Guide",
      template: {
        version: new ValidatedField(new RequiredField('C1'), value => {
          if (value !== "v2.4") {
            throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
          }
        })
      }
    },
    {
      name: "Pickup",
      template: {
        shipments: new ArrayTemplate({
          transportReference: "B$",
          consignmentNumbers: [new UppercaseField(new RequiredField("C$"))],
          equipmentNumber: new UppercaseField(new RequiredField("D$")),
          deepSeaEta: new TimestampField(new RequiredField("E$")),
          deepSeaTerminal: new MappedField(new RequiredField("F$"), terminalMapper),
          deepSeaCarrier: new MappedField(new RequiredField("G$"), shippingCompanyMapper),
          deepSeaVesselName: "H$",
          deepSeaVesselImo: "I$",
          deepSeaVoyageNumber: "J$",
          releaseReference: "K$",
          sizeType: new MappedField(new RequiredField("M$"), sizeTypeMapper),
          cargoWeight: new NonNegativeQuantityField(new RequiredField("N$")),
          tareWeight: new NonNegativeQuantityField(new RequiredField("O$")),
          inlandModality: new LowercaseField(new RequiredField("P$")),
          inlandTerminalLocation: new RequiredIfField(new MappedField("Q$", locationMapper), ["P$"], modality => modality === 'barge'),
          inlandTerminalCode: "R$",
          finalDestination: new MappedField("S$", finalDestinationMapper),
          cargoDirector: new MappedField(new RequiredField("W$"), organisationMapper),
          inlandOperator: new MappedField("X$", organisationMapper),
        }, [4, 203])
      }
    }
  ]
}

const excelTemplate_v3_0 = {
  sheets: [
    {
      name: "Guide",
      template: {
        version: new ValidatedField(new RequiredField('C1'), value => {
          if (value !== "v3.0") {
            throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
          }
        })
      }
    },
    {
      name: "Pickup",
      template: {
        shipments: new ArrayTemplate({
          transportReference: "B$",

          consignmentNumbers: [new UppercaseField(new RequiredField("F$"))],
          equipmentNumber: new UppercaseField(new RequiredField("G$")),
          deepSeaEta: new TimestampField(new RequiredField("H$")),
          deepSeaTerminal: new MappedField(new RequiredField("I$"), terminalMapper),
          deepSeaCarrier: new MappedField(new RequiredField("J$"), shippingCompanyMapper),
          sizeType: new MappedField(new RequiredField("K$"), sizeTypeMapper),
          deepSeaVesselName: "L$",
          deepSeaVesselImo: "M$",
          deepSeaVoyageNumber: "N$",
          releaseReference: "O$",

          inlandModality: new LowercaseField("U$"),
          inlandTerminalLocation: new RequiredIfField(new MappedField("V$", locationMapper), ["U$"], modality => modality === 'barge'),
          inlandTerminalCode: "W$",
          finalDestination: new MappedField("X$", finalDestinationMapper),

          cargoDirector: new MappedField("AC$", (o, cell) => organisationMapper(o || AppContext.userProfile.organisationShortName, cell)),
          inlandOperator: new MappedField("AD$", organisationMapper),

          transportOrder: {
            invoiceReference: "C$",
            debtorName: "D$",
            consigneeName: "E$",
            releaseExpiration: new TimestampField("P$"),
            demurrage: new MappedField(new NonNegativeQuantityField("Q$"), days => days && moment.duration(days, 'days').toISOString()),
            detention: new MappedField(new NonNegativeQuantityField("R$"), days => days && moment.duration(days, 'days').toISOString()),
            returnTerminal: "S$",
            returnReference: "T$",
            destinationTargetDate: new DateField("Y$"),
            destinationReference: "Z$",
            destinationInstructions: "AA$",
            customsCategory: "AE$",
            documentNumber: "AF$",
            documentExpiration: new DateField("AG$"),
          },
        }, [4, 203])
      }
    }
  ]
}
