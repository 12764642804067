import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'app-infinite-scroller',
  templateUrl: './infinite-scroller.component.html',
  styleUrls: ['./infinite-scroller.component.css']
})
export class InfiniteScrollerComponent implements AfterViewInit {
  @Input() initialCount = 10;
  count = this.initialCount;

  @ViewChild("wrapper", {static: true}) private wrapperElement: ElementRef<HTMLDivElement>;

  private get wrapper() {
    return this.wrapperElement.nativeElement;
  }

  ngOnInit(): void {
    this.count = this.initialCount;
  }

  ngAfterViewInit(): void {
    this.render();
  }

  private render = () => {
    const n = this.wrapper.children.length;
    for (let i = 0; i < n; i++) {
      if (i < this.count) {
        this.wrapper.children.item(i).classList.remove('d-none');
      } else {
        this.wrapper.children.item(i).classList.add('d-none');
      }
    }
  };

  onEndReached() {
    const newCount = Math.min(this.count + this.initialCount, this.wrapper.children.length);
    if (newCount > this.count) {
      this.count = newCount;
      this.render();
    }
  }
}
