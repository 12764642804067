<div *ngIf="!!context.binnenhavengeldUserProfile;" appTranslate>
  <div *ngIf="context.pendingProcesses.length > 0" class="position-fixed d-flex h-100 w-100 pending">
    <div class="position-absolute bg-secondary h-100 w-100 spinner-background"></div>
    <div class="spinner-border mx-auto text-secondary align-self-center spinner" role="status"></div>
  </div>

  <div class="row g-0" id='screen'>
    <div class="col-auto d-none d-lg-block">
      <div class="row g-0 sticky-top side-panel">
        <div class="col-auto w-100 sidebar d-flex justify-content-center align-items-center logo"
             style="">
          <img alt="Portbase logo" class="notranslate portbase-logo" src="assets/logo-portbase.png">
        </div>
        <div class="col bhg-services">
          <app-bhg-services></app-bhg-services>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="row g-0">
        <div class="col-12 sticky-top">
          <app-bhg-menu></app-bhg-menu>
        </div>
        <div class="col">
          <main class="h-100 bg-light main-panel" role="main">
            <router-outlet></router-outlet>
          </main>
        </div>
      </div>
    </div>
  </div>

  <app-edit-modal class="edit-modal"></app-edit-modal>
  <app-confirmation-modal class="edit-modal"></app-confirmation-modal>
</div>




