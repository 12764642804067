import {Component, OnDestroy, OnInit} from "@angular/core";
import {toTitleCase} from 'src/app/common/utils';
import {sendQuery} from "../../common/utils";
import {DetachedHandling, FindVoyages, Handling, Visit} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {
  FindDetachedHandlings,
  FindShipments,
  GetHandlingLog,
  GetShipmentHistory,
  LogItem,
  Shipment,
  ShipmentHistoryItem,
  Voyage
} from "@portbase/hinterland-service-typescriptmodels";
import moment from "moment/moment";
import {forkJoin, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

type HistoryItem = LogItem | ShipmentHistoryItem;
type ShipmentSummary = {
  deepSeaTerminal: string,
  deepSeaCarrier: string,
  releaseToParty: string,
  cargoDirector: string,
  inlandOperator: string,
  releaseStatus: string,
  forwarders: string[]
}

@Component({
  selector: 'app-container-detective',
  templateUrl: './container-detective.component.html',
  styleUrls: ['./container-detective.component.css']
})
export class ContainerDetectiveComponent implements OnInit, OnDestroy {
  containerNumber: string;
  columns: { id: string, timestamp: string }[];
  logItems: HistoryItem[];
  shipments: Shipment[];
  colors = ['#3cb44b', '#4363d8', '#f58231', '#911eb4', '#e6194b', '#ffe119', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
  userColorMap = {};
  filteredProps = ['terminalSettings'];
  noResults = false;
  searchRange = {
    start: moment(moment.now()).subtract(7, 'week').toISOString(),
    end: moment(moment.now()).add(2, 'week').toISOString()
  }
  routeSub: Subscription;
  URL_PARAM = 'query'

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.routeSub = this.route.queryParams.subscribe(params => {
      const param = params[this.URL_PARAM];
      if (param && this.containerNumber !== param) {
        this.containerNumber = param;
        this.investigate(false);
      }
    })
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  investigate(updateURL = true) {
    if (updateURL) window.history.pushState('', '', `${window.location.pathname}?${this.URL_PARAM}=${this.containerNumber}`);
    forkJoin(this.initQueries())
      .subscribe((result: [Shipment[], Voyage[], Voyage[], DetachedHandling[]]) => {
        this.shipments = result[0];
        this.shipments.map(shipment => shipment.id)
          .forEach(shipmentId => sendQuery("com.portbase.hinterland.api.shipment.query.GetShipmentHistory", <GetShipmentHistory>{
            shipmentId: shipmentId
          }).subscribe((logItems: ShipmentHistoryItem[]) => {
            this.addLogItems(shipmentId, logItems
              .filter(item => !item.description.startsWith("Container"))
              .map(item => ({...item, shipmentId: shipmentId})))
          }));
        const handlings = this.extractHandlings(result[1].concat(result[2])).concat(result[3])
        this.noResults = handlings.length == 0 && this.shipments.length == 0;
        this.fetchHandlingLogs(handlings);
      });
  }

  private initQueries() {
    this.columns = [];
    this.logItems = [];
    this.shipments = [];
    this.userColorMap = {};
    this.noResults = false;

    return [sendQuery("com.portbase.hinterland.api.shipment.query.FindShipments", <FindShipments>{
      term: this.containerNumber,
      dateTimeRange: this.searchRange
    }), sendQuery("com.portbase.hinterland.api.common.query.FindVoyages", <FindVoyages>{
      term: this.containerNumber,
      modality: "road",
      dateTimeRange: this.searchRange
    }), sendQuery("com.portbase.hinterland.api.common.query.FindVoyages", <FindVoyages>{
      term: this.containerNumber,
      dateTimeRange: this.searchRange
    }), sendQuery("com.portbase.hinterland.api.common.query.FindDetachedHandlings", <FindDetachedHandlings>{
      term: this.containerNumber,
      modality: ["road", "barge", "rail"],
      dateTimeRange: this.searchRange
    })]
  }

  private extractHandlings(voyages: Voyage[]): Handling[] {
    return voyages.flatMap(voyage => voyage.visits)
      .flatMap((visit: Visit) => visit.handlings)
      .filter((handling: Handling) => handling.handlingData.equipmentNumber == this.containerNumber)
  }

  private fetchHandlingLogs(handlings: Handling[]) {
    handlings.forEach(handling => sendQuery("com.portbase.hinterland.api.common.query.GetHandlingLog", <GetHandlingLog>{
      handlingId: handling.handlingId
    }).subscribe((logItems: LogItem[]) => {
      this.addLogItems(handling.handlingId, logItems);
    }));
  }

  private addLogItems(id: string, logItems: HistoryItem[]) {
    const temporal = () => {
      return (a: HistoryItem, b: HistoryItem) => moment(a.timestamp).isBefore(moment(b.timestamp)) ? -1 : 1;
    }

    this.noResults = logItems.length == 0;
    this.columns.push({id: id, timestamp: logItems[0].timestamp});
    this.columns = this.columns.sort(temporal());
    this.logItems = this.logItems.concat(logItems);
    this.logItems = this.logItems.sort(temporal());
  }

  getUserColor(user: string, org: string) {
    const full = `${user || 'system'}${org ? ` (${org})` : ''}`;
    return this.userColorMap[full] || (this.userColorMap[full] = this.colors[Object.keys(this.userColorMap).length]);
  }

  format(timestamp: string) {
    return moment(timestamp).format('ddd D MMM HH:mm:ss');
  }

  toggle(itemId: string) {
    document.getElementById(itemId).classList.toggle("d-none");
  }

  findColumnIndex(historyItem: HistoryItem) {
    return this.columns.findIndex(col => col.id == (historyItem['handlingId'] || historyItem['shipmentId']));
  }

  flatten(obj: Object, prefix = '') {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + '.' : '';
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        if (this.filteredProps.indexOf(k) < 0) Object.assign(acc, this.flatten(obj[k], pre + k));
      } else {
        if (!!obj[k]) acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  }

  summarize(shipment: Shipment): ShipmentSummary {
    const crStatus = shipment.commercialRelease?.releaseStatus;

    return {
      deepSeaTerminal: shipment.data?.deepSeaTerminal?.shortName || "-",
      deepSeaCarrier: shipment.data?.deepSeaCarrier?.name || "-",
      releaseToParty: shipment.releaseToParty?.fullName || (crStatus?.status === "ACCEPTED" || crStatus?.status === "DECLARED" ? shipment.commercialRelease?.releaseData?.releaseToParty?.name : null) || "-",
      cargoDirector: shipment.data?.cargoDirector?.fullName || "-",
      inlandOperator: shipment.data?.inlandOperator?.fullName || "-",
      releaseStatus: toTitleCase((crStatus?.cancelled ? "CANCELLED" : crStatus?.status)) || "-",
      forwarders: shipment.forwarders?.map(forwarder => forwarder.fullName) || ["-"]
    }
  }
}
