import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  BargeVisit,
  BargeVisitData,
  BargeVisitStatus,
  BargeVoyage,
  EstimatedHandlings,
  FixedWindow,
  PlannedHandlings,
  PriorityWindow,
  Terminal,
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {HinterlandUtils} from '../../hinterland-utils';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-edit-barge-visit-terminal',
  templateUrl: './edit-barge-visit-terminal.component.html',
  styleUrls: ['./edit-barge-visit-terminal.component.css']
})
export class EditBargeVisitTerminalComponent implements OnInit, OnChanges {

  utils = HinterlandUtils;
  context = AppContext;

  @Input() voyage: BargeVoyage;
  @Input() visit: BargeVisit;
  @Input() previous: BargeVisitData | BargeVisitStatus;
  @Input() previousRequested: BargeVisitData;
  @Input() current: BargeVisitData;

  fixedWindows: FixedWindow[] = [];
  terminals: Terminal[] = [];
  viaTerminals: Terminal[] = [];
  selectableTerminals: Terminal[] = [];
  tooltipContent: string;
  integralPlannedVisit: boolean;
  enabled: boolean;

  totalHandlings: EstimatedHandlings | PlannedHandlings;

  init() {
    this.fixedWindows = this.visit.visitData.fixedWindow ? [this.visit.visitData.fixedWindow] : [];
    this.integralPlannedVisit = this.voyage.integralPlanningRequested && !!this.visit.terminal && this.visit.terminal.nextlogicParticipant;
    this.visit.plannedVisitData = <BargeVisitStatus>{
      eta: this.visit.visitData.eta,
      etd: this.visit.visitData.etd,
      terminalRemarks: this.visit.plannedVisitData?.terminalRemarks,
      minimumCallSize: this.visit.plannedVisitData?.minimumCallSize,
      estimatedHandlings: this.visit.plannedVisitData?.estimatedHandlings ? this.visit.plannedVisitData?.estimatedHandlings :
        this.integralPlannedVisit ? null : this.visit.requestedVisitData?.estimatedHandlings,
      priority: this.integralPlannedVisit ? this.visit.plannedVisitData?.priority : false,
      priorityWindow: this.integralPlannedVisit ? this.visit.plannedVisitData?.priorityWindow : null,
      viaTerminal: this.integralPlannedVisit ? this.visit.plannedVisitData?.viaTerminal : null,
      cranesUsable: this.integralPlannedVisit ? this.visit.plannedVisitData?.cranesUsable : null,
      modality: this.visit.modality
    }
  }

  ngOnInit() {
    this.init();

    this.totalHandlings = this.integralPlannedVisit ? this.visit.integralPlanningStatus?.plannedHandlings
      ? this.visit.integralPlanningStatus.plannedHandlings : this.visit.requestedVisitData.estimatedHandlings : undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
    if (this.visit && this.voyage && this.current) {
      HinterlandUtils.getTerminals(this.visit.modality, this.visit.modality === 'barge'
        && (this.voyage.shareWithNextlogicRequested || this.voyage.nextlogicEnabled), true, false)
        .subscribe(terminals => {
          this.terminals = terminals;
          this.viaTerminals = terminals.filter(t => {
            return this.context.userProfile.organisationShortName === t.shortName
              || this.context.authorizingOrganisations.some(o => o === t.shortName)
              || AppContext.isAdmin();
          });
        });

      HinterlandUtils.getTerminals(this.visit.modality, this.visit.modality === 'barge'
        && (this.voyage.shareWithNextlogicRequested || this.voyage.nextlogicEnabled), true, false)
        .subscribe(terminals => this.selectableTerminals = terminals);

      this.tooltipContent = 'BO value';
      this.enabled = !this.visit.arrived && !this.voyage.cancelled && this.voyage.integralPlanningRequested;
    }
  }

  onPriorityChange() {
    if (!this.visit.plannedVisitData.priority) {
      this.visit.plannedVisitData.priorityWindow = null;
    } else if (this.visit.plannedVisitData.priority && !!this.visit.plannedVisitData.priorityWindow) {
      this.visit.plannedVisitData.priorityWindow = <PriorityWindow>{};
    }
  }

  onChangePriorityWindowStart($event: any) {
    this.visit.plannedVisitData = this.visit.plannedVisitData == null ? <BargeVisitStatus>{} : this.visit.plannedVisitData;
    if (this.visit.plannedVisitData.priorityWindow == null && $event != null) {
      this.visit.plannedVisitData.priorityWindow = <PriorityWindow>{};
    }
    this.visit.plannedVisitData.priorityWindow.start = $event;
  }

  onChangePriorityWindowEnd($event: any) {
    this.visit.plannedVisitData = this.visit.plannedVisitData == null ? <BargeVisitStatus>{} : this.visit.plannedVisitData;
    if (this.visit.plannedVisitData.priorityWindow == null && $event != null) {
      this.visit.plannedVisitData.priorityWindow = <PriorityWindow>{};
    }
    this.visit.plannedVisitData.priorityWindow.end = $event;
  }

  onChangeTerminalRemarks($event: any) {
    this.visit.plannedVisitData = this.visit.plannedVisitData == null ? <BargeVisitStatus>{} : this.visit.plannedVisitData;
    this.visit.plannedVisitData.terminalRemarks = $event;
  }

  terminalFormatter(modality: string) {
    return function (terminal: Terminal) {
      return HinterlandUtils.getTerminalDisplayName(terminal, modality);
    }
  }
}
