import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AddEquipmentNumber,
  CancelAndDetachHandling,
  CancelDetachedHandling,
  Container,
  DangerousGood,
  DeclareDetachedHandling,
  DeclareHandling,
  DeepSea,
  FindVisitResult,
  HandlingData,
  HandlingStatus,
  Modality,
  Terminal,
  TerminalSettings
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {checkValidity, cloneObject, lodash, sendCommand, sendQuery} from '../../../../common/utils';
import {AppContext} from '../../../../app-context';
import {HandlingModel, HinterlandUtils} from '../../../hinterland-utils';
import {
  Charter,
  DeepSeaReference,
  HandlingType,
  HandlingWindow,
  UpdateDeepSeaReference,
  UpdateDeepSeaReferenceDetached
} from "@portbase/hinterland-service-typescriptmodels";
import {ContainerNumberValidator, digitAlert, regexAlert} from "../../../../common/container-number-validator";
import {Accept, mapAcceptStatuses} from "../handling-status/handling-status.component";
import {CopiedHandling} from '../handlings.component';
import {filter} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-edit-handling',
  templateUrl: './edit-handling.component.html',
  styleUrls: ['./edit-handling.component.css']
})
export class EditHandlingComponent implements OnInit {
  appContext = AppContext;
  utils = HinterlandUtils;

  context = AppContext;
  handling: HandlingModel;
  @Input() readonly: boolean;
  handlingOnInit: HandlingModel;
  acceptStatuses: Accept[] = [];

  // Terminal sees (readonly) container-info-field-data from handlingStatus if there is any
  handlingData: HandlingData;
  deepSea: DeepSea;

  kibanaVoyageUrl: String;
  kibanaVisitUrl: String;
  kibanaHandlingUrl: String;

  @Input() set setHandling(handling: HandlingModel) {
    this.handling = handling;
    this.handlingData = !this.readonly ? this.handling.handlingData : lodash.mergeWith({}, this.handling.handlingData, this.handling.handlingStatus, (a, b) => b === null ? a : undefined);
    this.handlingOnInit = cloneObject(handling);
    this.handling.handlingData['inlandTerminal'] = handling.handlingData['inlandTerminal'] || <any>{};
    !handling.voyageData ? this.handling.voyageData = <any>{} : this.handling.voyageData = handling.voyageData;
    !handling.visitData ? this.handling.visitData = <any>{} : this.handling.visitData = handling.visitData;

    if (this.handling.deepSea) {
      this.deepSea = this.handling.deepSea;
    } else {
      this.deepSea = {};
    }

    this.setKibanaUrls();
  }

  terminalSettings: TerminalSettings;
  linkTo: boolean = null;
  linkToItem: 'visit' | 'trip' = 'visit';
  itemForAttaching: FindVisitResult;

  @Output() deleteHandling = new EventEmitter();
  @Input() copyHandling: EventEmitter<CopiedHandling>;

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    this.linkTo = this.handling.copy ? false : null;
    this.onChange();
  }

  onChange() {
    this.terminalSettings = this.getSettingsForTerminal();

    if (this.handling.deepSea) {
      this.deepSea = this.handling.deepSea;
    } else {
      this.deepSea = {};
    }
  }

  onTerminalChanged() {
    let settings: TerminalSettings = this.getSettingsForTerminal();
    if (!settings.sealNumberAllowed) {
      delete this.handling.handlingData.sealNumber;
    }

    if (!settings.grossWeightAllowed) {
      delete this.handling.handlingData.weight;
      delete this.handling.handlingData.tareWeight;
    }
  }

  private getSettingsForTerminal() {
    return this.handling.terminal.terminalSettings.find(
      c => c.full === !!this.handling.handlingData.full
        && c.loading === (this.handling.handlingData.type === 'loading')
        && c.modality === this.handling.handlingData.modality) || <TerminalSettings>{};
  }

  disable() {
    return this.readonly || this.handling.modality === 'road' || this.handling.visitArrived
      || !HinterlandUtils.isCargoDeclarant(this.handling) || this.isUpdateForbidden()
      || (this.handling.visitLoadDischargeListStatus == 'closed' && !this.addEquipmentNumberAllowed());
  }

  addEquipmentNumberAllowed() {
    return this.utils.isLossenOpRichtlijnAllowed(this.handling.terminal, this.handling.modality)
      && !this.handling.handlingData.full
      && this.handling.handlingData.type === 'discharge'
      && !this.handlingOnInit.handlingData.equipmentNumber;
  }

  enableCancel() {
    return !(this.readonly || this.handling.modality === 'road' || this.handling.completed || this.handling.cancelled
      || !HinterlandUtils.isCargoDeclarant(this.handling));
  }

  mapAcceptStatus = () => {
    return mapAcceptStatuses(this.handling);
  }

  tryGetContainer = () => {
    this.checkValidContainerNumber();
    return this.getContainer();
  };

  checkValidContainerNumber = () => {
    AppContext.closeAlerts(regexAlert, digitAlert);
    if (!this.handling.handlingData.shippersOwned) {
      if (!ContainerNumberValidator.checkCorrectRegex(this.handlingData.equipmentNumber)) {
        AppContext.addAlert(regexAlert);
        return false;
      } else if (!ContainerNumberValidator.checkDigitCorrect(this.handling.handlingData.equipmentNumber)) {
        AppContext.addAlert(digitAlert);
        return false;
      }
    }
    return true;
  }

  getContainer = () => {
    if (!this.handling.handlingData.sizeType || !this.handling.handlingData.sizeType.code) {
      this.utils.getContainer(this.handling.handlingData.equipmentNumber)
        .subscribe(containerKnownToUs => {
          let c = <Container>containerKnownToUs;
          if (!!c && !!c.sizeType) {
            this.handling.handlingData.sizeType = c.sizeType;
          }
          if (!!c && !!c.tareWeight && this.terminalSettings && this.terminalSettings.grossWeightAllowed) {
            this.handling.handlingData.tareWeight = c.tareWeight;
          }
        });
    }
  };

  alwaysEnable() {
    return !this.disable() && this.handling.voyageStatus !== 'CANCELLED'
  }

  isContainerNumberRequired = (): boolean => {
    return this.handling.handlingData.full === true
      || (this.handling.handlingData.type === 'discharge'
        && (this.handling.terminal == null || !this.utils.isLossenOpRichtlijnAllowed(this.handling.terminal, this.handling.modality)));
  }

  isEtaRequired = (): boolean => this.handling.preNotification === true;

  onHandlingTypeChanged = (type: HandlingType) => {
    switch (type) {
      case 'discharge':
        delete this.handling.handlingData['inlandTerminal'];
        break;
      case 'loading':
        this.handling.handlingData['inlandTerminal'] = <any>{};
        delete this.handling.handlingData.weight;
        delete this.handling.handlingData.tareWeight;
        delete this.handling.handlingData.sealNumber;
        break;
    }
  };

  declareHandlingAsPrenotification = () => {
    this.handling.preNotification = true;
    this.declareHandling();
  }

  declareHandling = () => {
    if (checkValidity(this.element)) {
      const handlingData = cloneObject(this.handling.handlingData);
      handlingData.reefer = lodash.isEmpty(handlingData.reefer) ? null : handlingData.reefer;
      handlingData.outOfGauge = lodash.isEmpty(handlingData.outOfGauge) ? null : handlingData.outOfGauge;
      handlingData['inlandTerminal'] = lodash.isEmpty(handlingData['inlandTerminal']) ? null : handlingData['inlandTerminal'];

      if (this.handling.visitId) {
        sendCommand('com.portbase.hinterland.api.common.command.DeclareHandling', <DeclareHandling>{
          voyageId: this.handling.voyageId,
          visitId: this.handling.visitId,
          handlingId: this.handling.handlingId,
          handlingData: handlingData,
          preNotification: this.handling.preNotification
        }, () => {
          if (this.handling.new) {
            AppContext.registerSuccess('The handling was created successfully');
            this.deleteHandling.emit();
          } else {
            AppContext.registerSuccess('The handling was updated successfully');
          }
        });
      } else if (this.handling.new && this.itemForAttaching) {
        sendCommand('com.portbase.hinterland.api.common.command.DeclareHandling', <DeclareHandling>{
          voyageId: this.itemForAttaching.voyageId,
          visitId: this.itemForAttaching['visitId'],
          handlingId: this.handling.handlingId,
          handlingData: handlingData,
          preNotification: this.handling.preNotification
        }, () => {
          AppContext.registerSuccess('The handling was created successfully');
          this.deleteHandling.emit();
        });
      } else {
        sendCommand('com.portbase.hinterland.api.detached.command.DeclareDetachedHandling', <DeclareDetachedHandling>{
          terminal: this.handling.terminal,
          declarant: AppContext.userProfile.organisation,
          modality: this.handling.modality,
          eta: this.handling.eta,
          handlingId: this.handling.handlingId,
          handlingData: handlingData
        }, () => {
          AppContext.registerSuccess('The handling was created successfully');
          this.deleteHandling.emit();
        });
      }
    }
  };

  addEquipmentNumber = () => {
    sendCommand('com.portbase.hinterland.api.common.command.AddEquipmentNumber', <AddEquipmentNumber>{
      voyageId: this.handling.voyageId,
      visitId: this.handling.visitId,
      handlingId: this.handling.handlingId,
      equipmentNumber: this.handling.handlingData.equipmentNumber
    }, () => {
      AppContext.registerSuccess('The handling was updated successfully');
    });
  }

  cancelHandling = () => {
    if (this.handling.visitId) {
      sendCommand('com.portbase.hinterland.api.common.command.CancelAndDetachHandling',
        <CancelAndDetachHandling>{
          voyageId: this.handling.voyageId,
          visitId: this.handling.visitId,
          handlingId: this.handling.handlingId
        }, () => {
          AppContext.registerSuccess('The handling was cancelled and detached successfully');
        });
    } else {
      sendCommand('com.portbase.hinterland.api.common.command.CancelDetachedHandling',
        <CancelDetachedHandling>{
          handlingId: this.handling.handlingId
        }, () => {
          AppContext.registerSuccess('The handling was cancelled successfully');
        });
    }
  };

  updateDeepSea = () => {
    if (this.handling.visitId) {
      sendCommand('com.portbase.hinterland.api.common.command.UpdateDeepSeaReference',
        <UpdateDeepSeaReference>{
          voyageId: this.handling.voyageId,
          visitId: this.handling.visitId,
          handlingId: this.handling.handlingId,
          deepSeaReference: <DeepSeaReference>{
            imoCode: this.deepSea.imoCode,
            shipName: this.deepSea.shipName,
            crnNumber: this.deepSea.crnNumber
          },
        }, () => {
          AppContext.registerSuccess('Deep sea details were added successfully');
        });
    } else {
      sendCommand('com.portbase.hinterland.api.common.command.UpdateDeepSeaReferenceDetached',
        <UpdateDeepSeaReferenceDetached>{
          handlingId: this.handling.handlingId,
          deepSeaReference: <DeepSeaReference>{
            imoCode: this.deepSea.imoCode,
            shipName: this.deepSea.shipName,
            crnNumber: this.deepSea.crnNumber
          },
        }, () => {
          AppContext.registerSuccess('Deep sea details were added successfully');
        });
    }
  }

  onLinkTo = (linkTo: boolean) => {
    if (!linkTo) {
      this.itemForAttaching = null;
      this.handling.modality = null;
      this.handling.terminal = <Terminal>{terminalSettings: []};
      this.handling.eta = null;
      this.handling.preNotification = false;
    } else {
      this.handling.preNotification = null;
    }
  };

  onItemForAttachingFound = (itemForAttaching) => {
    if (itemForAttaching && itemForAttaching['visitId']) {
      this.handling.modality = itemForAttaching.modality;
      this.handling.eta = itemForAttaching['eta'];
      this.handling.terminal = itemForAttaching['terminal'];
      this.handling.handlingData.modality = <any>itemForAttaching.modality;
      this.linkToItem = 'visit';
    }
  }

  isUpdateForbidden = () => HinterlandUtils.isHandlingUpdateForbidden(this.handling, this.handlingOnInit);

  isUpdateByPassAllowed = () => HinterlandUtils.isHandlingUpdateByPassAllowed(this.handling);

  removeDangerousGood(index) {
    this.handling.handlingData.dangerousGoods.splice(index, 1);
  }

  addDangerousGood() {
    this.handling.handlingData.dangerousGoods.push(<DangerousGood>{});
  }

  onChangeWindowStart($event: any) {
    this.checkWindowInitialized();
    this.handling.handlingStatus.window.start = $event;
  }

  onChangeWindowEnd($event: any) {
    this.checkWindowInitialized();
    this.handling.handlingStatus.window.end = $event;
  }

  private checkWindowInitialized() {
    this.handling.handlingStatus = this.handling.handlingStatus == null ? <HandlingStatus>{} : this.handling.handlingStatus;
    this.handling.handlingStatus.window = this.handling.handlingStatus.window == null ? <HandlingWindow>{} : this.handling.handlingStatus.window;
  }

  hasIntegralPlanningDetails() {
    let status = this.handling.handlingStatus;
    return !!status && (!!status.masterBundleContainerNumber || !!status.closestBollard || !!status.releasedForModality)
  }

  hasIntegralPlanning() {
    return this.handling && this.handling.modality == 'barge' && AppContext.application
      && AppContext.application.integralPlanningEnabled && this.handling.terminal && this.handling.terminal.nextlogicParticipant
  }

  terminalSelected() {
    return !!this.handling.terminal && this.handling.terminal.shortName != null;
  }

  getModalityOptions(): Modality[] {
    const result = [];
    if (AppContext.isBargeDeclarant()) {
      result.push('barge');
    }
    if (AppContext.isRailDeclarant()) {
      result.push('rail');
    }
    return result;
  }

  allowedToAddToVisit() {
    return !!this.handling.visitId && !this.handling.visitNeverAcknowledged && !this.handling.visitArrived && !this.handling.cancelled;
  }

  addShortName = (charter: Charter) => {
    if (charter && !charter.portbaseId) {
      sendQuery("com.portbase.hinterland.api.common.query.GetOrganisationByEan",
        {ean: charter.ean}).pipe(filter(r => !!r))
        .subscribe(o => charter.portbaseId = o.shortName);
    }
  };

  findVisits(term: string, modality: Modality): Observable<any> {
    return HinterlandUtils.findVisits(term, modality);
  }

  seaVesselSelected() {
    this.handlingData.deepSea = <DeepSeaReference>{
      imoCode: this.deepSea.imoCode,
      shipName: this.deepSea.shipName,
      crnNumber: this.deepSea.crnNumber
    }
  }

  getCargoDeclarant() {
    return this.handling.cargoDeclarants?.find(c => c.shortName === this.handling.cargoDeclarantShortName)?.fullName || this.handling.declarant.fullName;
  }

  isCargoDeclarantOfHandling() {
    return AppContext.userProfile.organisationShortName === this.handling?.cargoDeclarantShortName;
  }

  setKibanaUrls() {
    this.kibanaVoyageUrl = this.utils.buildKibanaUrl(this.handling.voyageId);
    this.kibanaVisitUrl = this.utils.buildKibanaUrl(this.handling.visitId);
    this.kibanaHandlingUrl = this.utils.buildKibanaUrl(this.handling.handlingId);
  }

  clearTemperature() {
    const reefer = this.handlingData.reefer;
    if (reefer.temperatureFixed) {
      reefer.minimumTemperature = null;
      reefer.maximumTemperature = null;
    } else {
      reefer.temperature = null;
    }
  }

  copyToClipboard(id: string) {
    navigator.clipboard.writeText(id);
  }
  calculateTotalWeight() {
    if(!!this.handlingData.weight && !!this.handlingData.tareWeight) {
      this.handlingData.totalContainerWeight = Number(this.handlingData.weight) + Number(this.handlingData.tareWeight);
    } else {
      this.handlingData.totalContainerWeight = undefined;
    }
  }
}
