<div class="offcanvas-panel" (change)="setModalClosable(false)">

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i (click)="openViewHandlingPanel()" class="overlay-icon fa-regular fa-chevron-left"></i></div>
      <div class="d-flex centered">Change vessel</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="nav-tabs-content nav-tabs pb-5">
      <div class="tab-pane">
        <!-- Handling Details -->
        <div class="nav-content">
          <app-error [errors]="errors"></app-error>

          <div class="inner-section-header">Change vessel</div>
          <div class="mb-4">Adding a deep sea vessel to your handling allows you to have up to date ETA information.</div>

          <app-form-field tooltip="Depending on handling type and shipping company, you get access to ETA, ETD and cargo closing by selecting a deep sea vessel.">
            <label>Deep sea vessel</label>
            <app-search [(ngModel)]="deepSea" [resultFormatter]="utils.seaVesselFormatter"
                        [searchFunction]="utils.findTerminalVisits(terminal)"
                        customClass="xl" required
                        dataKey="shipName" placeholder="Vessel name or IMO code">
            </app-search>
          </app-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="tab-pane">
        <div class="button-bar">
          <div *ngIf="validationErrors" class="button-bar-text validation-errors">
            Please correct the errors above and try again
          </div>
          <button (click)="openViewHandlingPanel()" class="btn btn-secondary" type="button">Cancel</button>
          <button (click)="changeVessel()" class="btn btn-primary" type="button">Change vessel</button>
        </div>
      </div>
    </div>
  </div>
</div>
