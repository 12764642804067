import {Component, Input} from '@angular/core';
import {HandlingModel, HinterlandUtils} from "../../../hinterland-utils";
import moment from "moment";
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-handling-summary',
  templateUrl: './handling-summary.component.html',
  styleUrls: ['./handling-summary.component.scss']
})
export class HandlingSummaryComponent {

  @Input() handling: HandlingModel;
  @Input() displayTerminalAndEta = false;

  time = 'HH:mm';
  date = 'DD-MM-YYYY';

  utils = HinterlandUtils;
  appContext = AppContext;

  updatedEquipmentNumber(): string {
    return !!this.handling.handlingStatus && !!this.handling.handlingStatus.equipmentNumber
      ? this.handling.handlingStatus.equipmentNumber : this.handling.handlingData.equipmentNumber;
  }

  windowStartTime(): string {
    return moment(this.handling.handlingStatus.window.start).format(this.time);
  }

  windowStartDate(): string {
    return moment(this.handling.handlingStatus.window.start).format(this.date);
  }

  windowEndTime(): string {
    return moment(this.handling.handlingStatus.window.end).format(this.time);
  }

  windowEndDate(): string {
    return moment(this.handling.handlingStatus.window.end).format(this.date);
  }

  numberOfColumns(): number {
    return 2 + (!!this.handling.handlingStatus?.window || !!this.handling.deepSea ? 1 : 0) + (this.displayTerminalAndEta ? 1 : 0);
  }

}
