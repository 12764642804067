import {Component, Input, OnInit} from '@angular/core';
import {LogItem, Voyage} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {ComparatorChain} from "../../../../common/comparator-chain";

import {getVoyageLog} from "../../../../common/query-utils";
import {HistoryFilterOptions} from "../../history-filter/history-filter-options";

@Component({
  selector: 'app-voyage-history',
  templateUrl: './voyage-history.component.html',
  styleUrls: ['./voyage-history.component.scss']
})
export class VoyageHistoryComponent implements OnInit {

  @Input() voyage: Voyage;
  filter: HistoryFilterOptions = new HistoryFilterOptions();

  visitIds: string[] = [];
  logItems: LogItem[];

  private logComparator = new ComparatorChain("timestamp").compare;
  private reverseLogComparator = new ComparatorChain("-timestamp").compare;

  sortDirection: SortType = "ascending";

  ngOnInit(): void {
    getVoyageLog(this.voyage.voyageId).subscribe((items: LogItem[]) => {
      this.logItems = items.sort(this.getComparator());

      this.visitIds = [];
      let uniqueIds = [];
      for (let i = 1; i < items.length; i++) {
        if (!uniqueIds[items[i].visitId] && null !== items[i].visitId) {
          uniqueIds[items[i].visitId] = 1;
          this.visitIds.push(items[i].visitId);
        }
      }
      this.visitIds.sort().unshift(null);

      this.filter.showHandlings = false;
    });
  }

  toggleSort() {
    this.sortDirection = this.sortDirection == "ascending" ? "descending" : "ascending";
    this.logItems.sort(this.getComparator());
  }

  getComparator() {
    return this.sortDirection == 'ascending' ? this.logComparator : this.reverseLogComparator;
  }

  cast = (item: LogItem): LogItem => item;
}

export type SortType = 'descending' | 'ascending';
