<div class="bg-portbase-lighter-grey" style="min-height: 100vh">
  <div class="w-100 h-100 d-flex justify-content-center align-content-around">
    <div style="width: 600px" class="mb-5">
      <div class="row mt-4 mb-3">
        <div class="col-auto ps-4">
          <img src="assets/img/port-of-rotterdam-logo.svg" style="height: 35px" alt="Port of Rotterdam logo" class="notranslate">
        </div>
        <div class="col-auto ms-auto pe-4">
          <img src="assets/img/portbase-logo.svg" style="height: 30px" alt="Port of Rotterdam logo" class="notranslate">
        </div>
      </div>

      <app-form (submit)="updateAccount()">
        <div class="card">
          <div class="card-header bg-white py-4">
            <div class="row">
              <div class="col-12 my-3">
                <span style="font-size: 1.25rem; line-height: 1.5rem; font-weight: 500">Manage account</span>
              </div>

              <app-error [errors]="errors"></app-error>

              <div class="col-12">
                You only need to complete changed fields here. You can also request to declare for other skippers.
                If you have permission to declare for other skippers and no longer require this,
                you should contact the Port of Rotterdam Authority by telephone or email.
              </div>
            </div>
          </div>

          <div class="card-body px-4">
            <div class="row">
              <div class="col-12 my-3">
                <span style="font-size: 1.15rem; line-height: 1.5rem;">Reason for update</span>
              </div>

              <div class="col-12">
                <app-form-field label="Reason">
                  <textarea [(ngModel)]="command.reasonForUpdate" class="form-control"></textarea>
                </app-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-bhg-account-details [accountDetails]="command.accountDetails"></app-bhg-account-details>
              </div>
            </div>
          </div>

          <div class="card-footer bg-white py-4">
            <div class="row narrow-gutters justify-content-between">
              <div class="col-auto">
                <button type="button" class="btn btn-secondary" (click)="location.back()" style="min-width: 10rem">
                  Cancel
                </button>
              </div>

              <div class="col-auto">
                <button type="submit" class="btn btn-primary" style="min-width: 10rem">Update</button>
              </div>
            </div>
          </div>

        </div>
      </app-form>

    </div>


  </div>
</div>
