<div class="fieldset" [ngClass]="{'disabled': isDisabled}">
  <div class="row">
    <div class="col-md-6">
      <app-form-group>
        <label>Quay</label>
        <app-compare [disabled]="!visit.requestedTerminal || !visit.terminal"
                     [value]="visit.terminal?.quayName"
                     [otherValue]="visit.requestedTerminal?.quayName"
                     mode="PREVIOUS" label="Requested">
          <app-select [disabled]="!!visit.visitDeclaration"
                      [(ngModel)]="visit.terminal"
                      (ngModelChange)="updateFixedWindowsForSelectedTerminal()"
                      [options]="terminals" required
                      dataKey="bicsCode"
                      optionLabel="quayName"
                      optionSelectable="shortName">
          </app-select>
        </app-compare>
      </app-form-group>

      <app-form-group *ngIf="visit.visitId">
        <label>Call ID</label>
        <input class="form-control" [(ngModel)]="visit.visitId" disabled>
      </app-form-group>

      <app-form-group>
        <label>ETA</label>
        <app-compare [value]="visit.requestedVisitData?.eta | timestamp"
                     [otherValue]="(integralPlanningRequested ? visit.terminal?.nonPcs ? visit.integralPlanningStatus?.eta : visit.integralPlanningStatus?.pta: visit.plannedVisitData?.eta) | timestamp"
                     [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
          <app-date-time [(ngModel)]="visit.requestedVisitData.eta" required
                         (ngModelChange)="etaUpdatedEventEmitter.emit()"></app-date-time>
        </app-compare>
      </app-form-group>

      <app-form-group>
        <label>ETD</label>
        <app-compare [value]="visit.requestedVisitData?.etd | timestamp"
                     [otherValue]="(integralPlanningRequested ? visit.terminal?.nonPcs ? visit.integralPlanningStatus?.etd : visit.integralPlanningStatus?.ptd: visit.plannedVisitData?.etd) | timestamp"
                     [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
          <app-date-time [(ngModel)]="visit.requestedVisitData.etd" required
                         (ngModelChange)="etaUpdatedEventEmitter.emit()"></app-date-time>
        </app-compare>
      </app-form-group>

      <app-form-group *ngIf="!!fixedWindows || !!visit.requestedVisitData?.fixedWindow">
        <label for="fixedWindow">Fixed window</label>
        <app-select id="fixedWindow" [(ngModel)]="visit.requestedVisitData.fixedWindow" [options]="fixedWindows" [nullOption]="false" dataKey="code" [formatter]="utils.fixedWindowFormatter"></app-select>
      </app-form-group>

      <app-form-group *ngIf="integralPlanningRequested"
                      [ngClass]="{'disabled': visit.arrived || voyageCancelled || !integralPlanningRequested}">
        <label>Has appointment</label>
        <app-compare [value]="visit.requestedVisitData.appointment ? 'Yes' : 'No'"
                     [otherValue]="visit.visitData?.appointment ? 'Yes' : 'No'" mode="TERMINAL">
          <app-yes-no [(ngModel)]="visit.requestedVisitData.appointment" [inline]="true" id="appointment"></app-yes-no>
        </app-compare>
      </app-form-group>

    </div>

    <div class="col-md-6">
      <app-form-group>
        <label>Operator remarks</label>
        <textarea class="remarks form-control" [(ngModel)]="visit.requestedVisitData.operatorRemarks"
                  placeholder="Type any remarks for the terminal here"></textarea>
      </app-form-group>
      <app-form-group *ngIf="integralPlanningRequested && visit.plannedVisitData?.priorityWindow?.start" [ngClass]="{'disabled': visit.arrived || voyageCancelled || !integralPlanningRequested}">
        <label>Priority window start</label>
        <app-date-time [ngModel]="visit.plannedVisitData.priorityWindow.start" id="priorityWindowStart" readonly></app-date-time>
      </app-form-group>
      <app-form-group *ngIf="integralPlanningRequested && visit.plannedVisitData?.priorityWindow?.end" [ngClass]="{'disabled': visit.arrived || voyageCancelled || !integralPlanningRequested}">
        <label>Priority window end</label>
        <app-date-time [ngModel]="visit.plannedVisitData.priorityWindow.end" id="priorityWindowEnd" readonly></app-date-time>
      </app-form-group>
    </div>

    <div class="col-md-6 declaration-block">
      <h2>Discharge</h2>

      <div class="row narrow-gutters bg-light py-2">
        <div class="col"><span class="ps-2">Total</span></div>
        <div class="col"># Empty</div>
        <div class="col"># Out of gauge</div>
        <div class="col"># Twinnable</div>
        <div class="col" *ngIf="integralPlanningRequested"># In bundles</div>
      </div>

      <div class="row narrow-gutters py-2">
        <div class="col" (change)="updateDischargeSizes()">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeTotal"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.dischargeTotal : visit.plannedVisitData?.estimatedHandlings?.dischargeTotal"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeTotal" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeEmpty"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.dischargeEmpty : visit.plannedVisitData?.estimatedHandlings?.dischargeEmpty"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeEmpty" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeOutOfGauge"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.dischargeOutOfGauge : visit.plannedVisitData?.estimatedHandlings?.dischargeOutOfGauge"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeOutOfGauge" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeTwinnable"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.dischargeTwinnable : visit.plannedVisitData?.estimatedHandlings?.dischargeTwinnable"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeTwinnable" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col" *ngIf="integralPlanningRequested" (change)="updateDischargeAmountOfBundles()">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeStacked"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.dischargeStacked : visit.plannedVisitData?.estimatedHandlings?.dischargeStacked"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeStacked" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
      </div>

      <div *ngIf="integralPlanningRequested">
        <app-form-group *ngIf="visit.requestedVisitData.estimatedHandlings?.dischargeStacked > 0">
          <label>Amount of bundles</label>
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeBundles"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.dischargeBundles : visit.plannedVisitData?.estimatedHandlings?.dischargeBundles"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeBundles" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </app-form-group>
      </div>
    </div>

    <div class="col-md-6 declaration-block">
      <h2>Loading</h2>

      <div class="row narrow-gutters bg-light py-2">
        <div class="col"><span class="ps-2">Total</span></div>
        <div class="col"># Empty</div>
        <div class="col"># Out of gauge</div>
        <div class="col"># Twinnable</div>
        <div class="col" *ngIf="integralPlanningRequested"># In bundles</div>
      </div>

      <div class="row narrow-gutters py-2" *ngIf="visit.requestedVisitData?.estimatedHandlings">
        <div class="col" (change)="updateLoadingSizes()">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingTotal"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.loadingTotal : visit.plannedVisitData?.estimatedHandlings?.loadingTotal"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingTotal" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingEmpty"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.loadingEmpty : visit.plannedVisitData?.estimatedHandlings?.loadingEmpty"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingEmpty" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingOutOfGauge"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.loadingOutOfGauge : visit.plannedVisitData?.estimatedHandlings?.loadingOutOfGauge"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingOutOfGauge" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingTwinnable"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.loadingTwinnable : visit.plannedVisitData?.estimatedHandlings?.loadingTwinnable"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingTwinnable" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
        <div class="col" *ngIf="integralPlanningRequested" (change)="updateLoadingAmountOfBundles()">
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingStacked"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.loadingStacked : visit.plannedVisitData?.estimatedHandlings?.loadingStacked"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingStacked" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </div>
      </div>

      <div *ngIf="integralPlanningRequested">
        <app-form-group *ngIf="visit.requestedVisitData.estimatedHandlings?.loadingStacked > 0">
          <label>Amount of bundles</label>
          <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingBundles"
                       [otherValue]="integralPlanningRequested ? visit.integralPlanningStatus?.plannedHandlings?.loadingBundles : visit.plannedVisitData?.estimatedHandlings?.loadingBundles"
                       [mode]="integralPlanningRequested ? 'INTEGRAL_PLANNING': 'TERMINAL'">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingBundles" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </app-compare>
        </app-form-group>
      </div>
    </div>

    <ng-container *ngIf="integralPlanningRequested">
      <div class="col-md-6 declaration-block">
        <h2>Discharge sizes</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2"></span>&#8804; 20ft</div>
          <div class="col">21ft - 30ft</div>
          <div class="col">31ft - 40ft</div>
          <div class="col">&gt; 40ft</div>
        </div>

        <div class="row narrow-gutters py-2" (change)="updateDischargeSizes()">
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.discharge20ftAndUnder" appDecimalNumber [maxDecimals]="0" min="0" class="form-control" disabled>
          </div>
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.discharge20To30ft" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </div>
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.discharge30To40ft" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </div>
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.dischargeOver40ft" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </div>
        </div>
      </div>

      <div class="col-md-6 declaration-block">

        <h2>Loading sizes</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2"></span>&#8804; 20ft</div>
          <div class="col">21ft - 30ft</div>
          <div class="col">31ft - 40ft</div>
          <div class="col">&gt; 40ft</div>
        </div>

        <div class="row narrow-gutters py-2" (change)="updateLoadingSizes()">
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.loading20ftAndUnder" appDecimalNumber [maxDecimals]="0" min="0" class="form-control" disabled>
          </div>
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.loading20To30ft" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </div>
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.loading30To40ft" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </div>
          <div class="col">
            <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.loadingOver40ft" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <app-form-group *ngIf="visit.requestedVisitData?.estimatedHandlings?.dischargeOutOfGauge > 0">
          <label>Extra TEU regained</label>
          <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.dischargeTeuLost" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
        </app-form-group>
      </div>

      <div class="col-md-6">
        <app-form-group *ngIf="visit.requestedVisitData?.estimatedHandlings?.loadingOutOfGauge > 0">
          <label>Extra TEU lost</label>
          <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.estimatedSizes.loadingTeuLost" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
        </app-form-group>
      </div>
    </ng-container>

    <div class="col-md-6 declaration-block">
      <h2 *ngIf="integralPlanningRequested">Other</h2>
      <app-form-group>
        <label>Shifters</label>
        <app-compare [value]="visit.requestedVisitData.estimatedHandlings?.shifters"
                     [otherValue]="visit.integralPlanningStatus?.plannedHandlings?.shifters"
                     mode="INTEGRAL_PLANNING">
          <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.shifters" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
        </app-compare>
      </app-form-group>
      <app-form-group *ngIf="integralPlanningRequested">
        <label>Cranes usable</label>
        <app-compare [disabled]="visit.integralPlanningStatus?.cranes?.length < 1" [value]="visit.requestedVisitData.cranesUsable"
                     [otherValue]="visit.integralPlanningStatus?.cranes?.length"
                     mode="INTEGRAL_PLANNING">
          <input [(ngModel)]="visit.requestedVisitData.cranesUsable" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
        </app-compare>
      </app-form-group>
    </div>
    <div class="col-md-6 declaration-block"></div>

    <!-- Additional capacity -->
    <div class="col-md-6 declaration-block" *ngIf="integralPlanningRequested">
      <div class="custom-check custom-check-inline">
        <input [(ngModel)]="showAdditionalCapacityOverride"
               (ngModelChange)="onSelect()"
               class="custom-check-input" type="checkbox">Override additional barge length
      </div>
      <app-info [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent text-start">The additional barge length specific for the visit.
          Value will override the value given on the voyage.
          Integral planning checks visit specified capacity before voyage specified capacity.</div>
      </app-info>
    </div>
    <div class="col-md-6 declaration-block"></div>

    <div class="col-md-6 declaration-block" *ngIf="integralPlanningRequested && showAdditionalCapacityOverride">
      <h2>TEU Capacity</h2>

      <div class="row narrow-gutters bg-light py-2">
        <div class="col-xl-4 col-form-label">&nbsp;</div>
        <div class="col">Barge
          <app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
            <div class="tooltipContent">Maximum physical TEU capacity of the barge.</div>
          </app-info>
        </div>
        <div class="col">Reserved *
          <app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
            <div class="tooltipContent text-start">For integral planning this amount will be subtracted from the total TEU capacity. The estimated amount of TEU reserved for other purposes. For instance, containers to be discharged at other ports or containers to be loaded in other ports.</div>
          </app-info>
        </div>
        <div class="col">Additional
          <app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
            <div class="tooltipContent text-start">TEU capacity added by the pushed barge(s).</div>
          </app-info>
        </div>
        <div class="col total">Total</div>
      </div>

      <div class="row narrow-gutters py-2 form-group" (change)="updateAdditionalCapacity()">
        <div class="col-xl-4 col-form-label"><label>Maximum capacity
          <app-info [placement]="'right-top'" class="pt-2">
            <div class="tooltipContent text-start">Maximum TEU capacity = Barge TEU Capacity - Reserved TEU capacity + Additional TEU Capacity</div>
          </app-info>
        </label></div>
        <div class="col">
          <input [(ngModel)]="voyageData.barge.teuCapacity" (ngModelChange)="updateAdditionalCapacity()" readonly appDecimalNumber [maxDecimals]="0" class="form-control" *ngIf="voyageData.barge">
        </div>
        <div class="col">
          <input [(ngModel)]="voyageData.reservedTeu" (ngModelChange)="updateAdditionalCapacity()" readonly [maxlength]=5 [maxValue]=99999 oninput="validity.valid||(value=value);" required appDecimalNumber [maxDecimals]="0" class="form-control" ngNativeValidate>
          <div class="invalid-feedback">Value should be between 0 and 10000!</div>
        </div>
        <div class="col">
          <input [(ngModel)]="voyageData.additionalCapacity.teuCapacity" (ngModelChange)="updateAdditionalCapacity()" readonly [minValue]=0 [maxLength]=5 [maxValue]=99999 oninput="validity.valid||(value=value);" appDecimalNumber [maxDecimals]="0" class="form-control">
          <div class="invalid-feedback">Value should be between 0 and 10000!</div>
        </div>
        <div class="col">
          <input [(ngModel)]="additionalTotalCapacity" readonly appDecimalNumber [maxDecimals]="0" class="form-control result validatable" [ngClass]="{'ng-invalid': additionalTotalCapacity < 0}">
          <div class="invalid-feedback">Maximum capacity should be a positive number!</div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-6 declaration-block" *ngIf="integralPlanningRequested && showAdditionalCapacityOverride">
      <h2>Length</h2>

      <div class="row narrow-gutters bg-light py-2">
        <div class="col-xl-4 col-form-label">&nbsp;</div>
        <div class="col">Barge
          <app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
            <div class="tooltipContent text-start">Length of the barge in meters.</div>
          </app-info>
        </div>
        <div class="col text-start">Additional
          <app-info [placement]="'right-top'" class="pt-2">
            <div class="tooltipContent text-start">Length in meters of extra pushed barge(s) only when these are not moored alongside the barge during the visit</div>
          </app-info>
        </div>
        <div class="col">Total</div>
      </div>

      <div class="row narrow-gutters py-2" (change)="updateAdditionalCapacity()">
        <div class="col-xl-4 col-form-label"><label>Maximum length
          <app-info [placement]="'right-top'" class="pt-2">
            <div class="tooltipContent text-start">Maximum length = Barge length + Additional length (pushed barges)</div>
          </app-info>
        </label></div>
        <div class="col capacity">
          <input [(ngModel)]="voyageData.barge.length" (ngModelChange)="updateAdditionalCapacity()" readonly appDecimalNumber [maxDecimals]="0" class="form-control" *ngIf="voyageData.barge">
        </div>
        <div class="col capacity">
          <input [(ngModel)]="additionalCapacityOverride.length" (ngModelChange)="updateAdditionalCapacity()" [minValue]=0 [maxLength]=5 [maxValue]=99999 oninput="validity.valid||(value=value);" appDecimalNumber [maxDecimals]="0" class="form-control">
          <div class="invalid-feedback">Value should be between 0 and 10000!</div>
        </div>
        <div class="col total">
          <input [(ngModel)]="additionalTotalLength" readonly appDecimalNumber [maxDecimals]="0" class="form-control result validatable" [ngClass]="{'ng-invalid': additionalTotalLength < 0}">
          <div class="invalid-feedback">Total length should be a positive number!</div>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="utils.isVoyageDeclarantByShortName(voyageDeclarantShortname)">
    <div class="row narrow-gutters mt-3">
      <ng-container *ngIf="!visit.arrived; else visitArrived">
        <div class="col-md-auto mb-3 mb-md-0">
          <button *ngIf="!!visit.visitId; else cancelUnsavedVisit"
                  data-bs-toggle="modal" [attr.data-bs-target]="'#cancelVisitModal-' + visit.visitId"
                  type="button" class="btn btn-danger btn-block" style="min-width: 10rem" tabindex="-1">Cancel visit
          </button>
          <ng-template #cancelUnsavedVisit>
            <button (click)="cancelVisit()"
                    type="button" class="btn btn-secondary btn-block" tabindex="-1" style="min-width: 10rem;">Cancel
              visit
            </button>
          </ng-template>
        </div>
        <div class="col-md-auto mb-3 mb-md-0" *ngIf="isMoveVisitAllowed">
          <button (click)="isMovingVisit = true;"
                  data-bs-toggle="modal" [attr.data-bs-target]="'#moveVisitModal-' + visit.visitId"
                  type="button" class="btn btn-info btn-block" style="min-width: 10rem" tabindex="-1">Move visit
          </button>
        </div>
        <div class="col-md-auto mb-md-0 ms-auto"></div>
        <div class="col-md-auto mb-3 mb-md-0" *ngIf="!visit.visitDeclaration">
          <button (click)="saveVisit()" type="button" class="btn btn-info btn-block" tabindex="-1"
                  style="min-width: 10rem;"><span>Save visit</span></button>
        </div>
        <div class="col-md-auto mb-3 mb-md-0 enabled" *ngIf="visit.acknowledgedByOperator === false">
          <button (click)="acknowledgeUpdate()" type="button" class="btn btn-outline-info btn-block" tabindex="-1"
                  style="min-width: 10rem;"><span>Acknowledge update</span></button>
        </div>

        <div class="col-md-auto mb-3 mb-md-0 enabled" *ngIf="visit.loadDischargeListEnabled">
          <button (click)="sendLoadDischargeList()" type="button" class="btn btn-info btn-block" *ngIf="(visit.loadDischargeListStatus === 'open' || visit.loadDischargeListStatus === 'sent') && visit.waitForCloseVisitEnabled" tabindex="-1"
                  style="min-width: 10rem;"><span>Send load-discharge list</span></button>
          <button (click)="finalizeLoadDischargeList()" *ngIf="visit.loadDischargeListStatus === 'reopened'" type="button" class="btn btn-info btn-block" tabindex="-1"
                  style="min-width: 10rem;"><span>Finalize load-discharge list</span></button>
        </div>
        <div class="col-md-auto ms-0">
          <button (click)="declareVisit()" type="button" class="btn btn-info btn-block" tabindex="-1"
                  style="min-width: 10rem;"><span>Save & send visit</span></button>
        </div>
      </ng-container>

      <ng-template #visitArrived>
        <div class="ms-auto col-md-auto mb-3 mb-md-0 enabled" *ngIf="visit.acknowledgedByOperator === false">
          <button (click)="acknowledgeUpdate()" type="button" class="btn btn-outline-info btn-block" tabindex="-1"
                  style="min-width: 10rem;"><span>Acknowledge update</span></button>
        </div>
      </ng-template>
    </div>
  </ng-container>


  <div *ngIf="visit.visitId && !visit.arrived"
       [id]="'cancelVisitModal-' + visit.visitId" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure that you want to cancel this visit?</h5>
        </div>
        <div class="modal-body">
          <p>Handlings attached to this visit need to be moved, detached or cancelled before the visit can be cancelled.</p>
        </div>
        <div class="modal-footer">
          <div class="container-fluid px-0">
            <div class="row g-0 justify-content-between">
              <div class="col-auto">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="width: 8rem">No</button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem"
                        (click)="cancelVisit()">Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isMovingVisit">
    <div class="fixed-bottom bg-white py-3 border-top">
      <div class="container-fluid">
        <div class="row narrow-gutters justify-content-center align-items-center">
          <div class="col-auto d-none d-sm-block">
            <div>Move visit. Are you sure?</div>
            <div class="small" style="max-width: 24rem">Find by europe id, barge name, loading number or discharge number. The visit will be moved to the new voyage.</div>
          </div>
          <div class="col-auto" style="min-width: 15rem">
            <app-search [(ngModel)]="moveToVoyage" [searchFunction]="findVoyagesForMovingVisit" [placement]="'top-left'"
                        placeholder="Find voyage" dataKey="name" [inputFormatter]="utils.voyageBargeFormatter">
            </app-search>
          </div>
        </div>

        <div class="row narrow-gutters justify-content-center align-items-center mt-2">
          <div class="col-auto">
            <button (click)="isMovingVisit = false; moveToVoyage = null" type="button" class="btn btn-secondary mx-2" tabindex="-1" style="min-width: 8rem">Cancel</button>
            <button [disabled]="!moveToVoyage" (click)="moveVisit()" type="button" class="btn btn-info mx-2" tabindex="-1" style="min-width: 8rem">Move</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
