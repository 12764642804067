<div *ngIf="this.handlings.size > 0" class="overview-navbar d-flex align-items-center border-bottom">
  <div class="custom-check-inline h-100 me-0 pe-2 select-all-aligned">
    <div class="form-check">
      <input (click)="toggleSelectAllHandlings()" [checked]="this.selectAllValue === 'all'" [indeterminate]="this.selectAllValue === 'some'"
             class="form-check-input"
             id="select-all-handlings-checkbox"
             type="checkbox">
    </div>
  </div>

  <div class="btn btn-secondary action-button" type="button" (click)="openEmailModal()">
    <i class="button-icon fa-light fa-envelope pe-2"></i>Email <span class="hide-lg-screen">handling{{handlings.size > 1 ? 's' : ''}}</span>
  </div>
  <div *ngIf="handlings.size === 1" class="btn btn-secondary action-button" (click)="duplicateHandling()" type="button">
    <i class="button-icon fa-light fa-copy pe-2"></i> Duplicate <span class="hide-lg-screen">handling</span>
  </div>
  <div *ngIf="anyHandlingAllowsCancel()" (click)="openCancelHandlingsModal()" class="btn btn-secondary action-button" type="button">
    <i class="button-icon fa-light fa-ban pe-2"></i>Cancel <span class="hide-lg-screen">handling{{multipleS}}</span>
  </div>
  <div *ngIf="handlings.size >= 1" class="btn btn-secondary action-button" type="button" (click)="attachOrMoveHandlingsToExistingVisit()">
    <i class="button-icon fa-light fa-arrow-from-left pe-2"></i> Attach <span class="hide-lg-screen">to {{visits.size > 0 ? 'other' : ''}} visit</span>
  </div>
  <div *ngIf="handlings.size === 1 && visits.size === 0" (click)="createPreNotification()" class="btn btn-secondary action-button" type="button">
    <i class="button-icon fa-light fa-plus pe-2"></i> Create pre-notification
  </div>
  <div *ngIf="anyHandlingAllowsDetach()" (click)="openDetachHandlingsModal()" class="btn btn-secondary action-button" type="button">
    <i class="button-icon fa-light fa-calendar-times pe-2"></i>Detach <span class="hide-lg-screen">from visit</span>
  </div>
</div>
