<div class="row">
  <div class="col-md-6">
    <app-form-group>
      <label>Barge
        <app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
          <div class="tooltipContent">
            <div class="text-start row justify-content-between">
                <span class="col-12 col-md-8">
                  <span class="row g-0" style="color:#C0C0C0;">Name: </span>
                  <span class="row g-0">{{voyageData.barge.name}}</span>
                  <span class="row g-0" style="color:#C0C0C0;">Europe id: </span>
                  <span class="row g-0">{{voyageData.barge.europeId}}</span>
                  <span class="row g-0" style="color:#C0C0C0;">Flag: </span>
                  <span class="row g-0">{{voyageData.barge.flagCode ? voyageData.barge.flagCode : "-"}}</span>
                  <span class="row g-0" style="color:#C0C0C0;">MMSI number: </span>
                  <span class="row g-0">{{voyageData.barge.mmsi ? voyageData.barge.mmsi : "-"}}</span>
                  <span class="row g-0" style="color:#C0C0C0;">Teu capacity: </span>
                  <span class="row g-0">{{voyageData.barge.teuCapacity ? voyageData.barge.teuCapacity : "-"}}</span>
                </span>
              <span class="col-12 col-md-8">
                  <span class="row g-0" style="color:#C0C0C0;">Width: </span>
                  <span class="row g-0">{{voyageData.barge.width ? voyageData.barge.width : "-"}} m</span>
                  <span class="row g-0" style="color:#C0C0C0;">Length: </span>
                  <span class="row g-0">{{voyageData.barge.length ? voyageData.barge.length : "-"}} m</span>
                  <span class="row g-0" style="color:#C0C0C0;">Speed empty: </span>
                  <span class="row g-0">{{voyageData.barge.downStreamSpeed ? voyageData.barge.downStreamSpeed : "-"}} km/h</span>
                  <span class="row g-0" style="color:#C0C0C0;">Speed full: </span>
                  <span class="row g-0">{{voyageData.barge.upstreamSpeed ? voyageData.barge.upstreamSpeed : "-"}} km/h</span>
                </span>
            </div>
          </div>
        </app-info>
      </label>

      <app-search [(ngModel)]="voyageData.barge" [searchFunction]="utils.findBarges"
                  [resultFormatter]="utils.bargeFormatter" [inputFormatter]="utils.bargeFormatter"
                  required [disabled]="!isNew" placeholder="Barge name or ENI" dataKey="name"></app-search>

    </app-form-group>
  </div>

  <div class="col-md-6">
    <app-form-group>
      <label [ngClass]="{'required': !voyageData.inHouseLoadingNumber}">In-house discharge number</label>
      <input [(ngModel)]="voyageData.inHouseDischargeNumber" [required]="!voyageData.inHouseLoadingNumber" [maxLength]="17"
             class="form-control">
    </app-form-group>

    <app-form-group>
      <label [ngClass]="{'required': !voyageData.inHouseDischargeNumber}">In-house loading number</label>
      <input [(ngModel)]="voyageData.inHouseLoadingNumber" [required]="!voyageData.inHouseDischargeNumber" [maxLength]="17"
             class="form-control">
    </app-form-group>
  </div>

  <div class="col-md-6 declaration-block">
    <app-form-group>
      <label>Arrival date</label>
      <app-compare [value]="voyageData.eta | timestamp" [otherValue]="integralPlanningStatus?.pta | timestamp" mode="INTEGRAL_PLANNING">
        <app-date-time [(ngModel)]="voyageData.eta" required></app-date-time>
      </app-compare>
    </app-form-group>
  </div>

  <div class="col-md-6 declaration-block">
    <app-form-group>
      <label>Departure date</label>
      <app-compare [value]="voyageData.etd | timestamp" [otherValue]="integralPlanningStatus?.ptd | timestamp" mode="INTEGRAL_PLANNING">
        <app-date-time [(ngModel)]="voyageData.etd" required></app-date-time>
      </app-compare>
    </app-form-group>
  </div>

  <div class="col-md-6 declaration-block">
    <app-form-group *ngIf="!(integralPlanningEnabled && staysInPort)">
      <label>Entry point</label>
      <app-compare [value]="voyageData.entryPoint?.name" [otherValue]="integralPlanningStatus?.plannedEntryPoint?.name" mode="INTEGRAL_PLANNING">
        <app-select [(ngModel)]="voyageData.entryPoint"
                    [options]="bargeAccessPoints" [nullOption]="true"
                    dataKey="name" placeholder="-- No entry point --">
        </app-select>
      </app-compare>
    </app-form-group>

    <app-form-group *ngIf="integralPlanningEnabled && staysInPort">
      <label>Previous voyage</label>
      <app-search [(ngModel)]="previousVoyage" [searchFunction]="findPreviousVoyages" (ngModelChange)="updatePreviousVoyage()"
                  [resultFormatter]="utils.previousVoyageBargeFormatter" [inputFormatter]="utils.previousVoyageBargeFormatter" [searchOnClick]="false"
                  placeholder="Inhouse number" dataKey="voyageId"></app-search>
    </app-form-group>
  </div>

  <div class="col-md-6 declaration-block">
    <app-form-group>
      <label>Exit point</label>
      <app-compare [value]="voyageData.exitPoint?.name" [otherValue]="integralPlanningStatus?.plannedExitPoint?.name" mode="INTEGRAL_PLANNING">
        <app-select [(ngModel)]="voyageData.exitPoint"
                    [options]="bargeAccessPoints" [nullOption]="true"
                    dataKey="name" placeholder="-- No exit point --">
        </app-select>
      </app-compare>
    </app-form-group>
  </div>

  <div class="col-md-6 declaration-block">
    <app-form-group *ngIf="integralPlanningEnabled && voyageData.barge?.europeId">
      <label>Barge stays in port</label>
      <app-yes-no [(ngModel)]="staysInPort" (change)="resetEntryPointOrPreviousVoyageId()" [inline]="true" id="staysInPort"></app-yes-no>
    </app-form-group>

  </div>

  <div class="col-md-6 declaration-block">
    <app-form-group *ngIf="integralPlanningEnabled">
      <label>Planning not before arrival date</label>
      <app-yes-no [(ngModel)]="voyageData.planningNotBeforeEta" [inline]="true" id="disablePlanningBeforeArrivalTime"></app-yes-no>
    </app-form-group>
  </div>

  <div class="col-md-6 declaration-block" *ngIf="integralPlanningEnabled">
    <h2>TEU Capacity</h2>

    <div class="row narrow-gutters bg-light py-2">
      <div class="col-xl-4 col-form-label">&nbsp;</div>
      <div class="col">Barge<app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent">Maximum physical TEU capacity of the barge.</div></app-info>
      </div>
      <div class="col">Reserved *<app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent text-start">For integral planning this amount will be subtracted from the total TEU capacity. The estimated amount of TEU reserved for other purposes. For instance, containers to be discharged at other ports or containers to be loaded in other ports.</div></app-info></div>
      <div class="col">Additional<app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent text-start">TEU capacity added by the pushed barge(s).</div></app-info></div>
      <div class="col total">Total</div>
    </div>

    <div class="row narrow-gutters py-2 form-group" (change)="updateAdditionalCapacity()">
      <div class="col-xl-4 col-form-label"><label>Maximum capacity <app-info [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent text-start">Maximum TEU capacity = Barge TEU Capacity - Reserved TEU capacity + Additional TEU Capacity</div></app-info></label></div>
      <div class="col">
        <input [(ngModel)]="voyageData.barge.teuCapacity" readonly appDecimalNumber [maxDecimals]="0" class="form-control" *ngIf="voyageData.barge">
      </div>
      <div class="col">
        <input [(ngModel)]="voyageData.reservedTeu"  [maxlength]=5  [maxValue]=99999 oninput="validity.valid||(value=value);" required appDecimalNumber [maxDecimals]="0" class="form-control" ngNativeValidate>
        <div class="invalid-feedback">Value should be between 0 and 10000!</div>
      </div>
      <div class="col">
        <input [(ngModel)]="additionalCapacity.teuCapacity" [minValue]=0 [maxLength]=5 [maxValue]=99999 oninput="validity.valid||(value=value);" appDecimalNumber [maxDecimals]="0" class="form-control">
        <div class="invalid-feedback">Value should be between 0 and 10000!</div>
      </div>
      <div class="col">
        <input [(ngModel)]="additionalTotalCapacity" readonly appDecimalNumber [maxDecimals]="0" class="form-control result validatable" [ngClass]="{'ng-invalid': additionalTotalCapacity < 0}">
        <div class="invalid-feedback">Maximum capacity should be a positive number!</div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-sm-6 declaration-block" *ngIf="integralPlanningEnabled">
    <h2>Length</h2>

    <div class="row narrow-gutters bg-light py-2">
      <div class="col-xl-4 col-form-label">&nbsp;</div>
      <div class="col">Barge<app-info *ngIf="voyageData.barge?.europeId" [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent text-start">Length of the barge in meters.</div></app-info></div>
      <div class="col text-start">Additional<app-info [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent text-start">Length in meters of extra pushed barge(s) only when these are not moored alongside the barge during the visits at the port.</div></app-info></div>
      <div class="col">Total</div>
    </div>

    <div class="row narrow-gutters py-2" >
      <div class="col-xl-4 col-form-label"><label>Maximum length <app-info [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent text-start">Maximum length = Barge length  + Additional length (pushed barges)</div></app-info></label></div>
      <div class="col capacity">
        <input [(ngModel)]="voyageData.barge.length" (ngModelChange)="updateAdditionalCapacity()" readonly appDecimalNumber [maxDecimals]="0" class="form-control" *ngIf="voyageData.barge">
      </div>
      <div class="col capacity">
        <input [(ngModel)]="additionalCapacity.length" (ngModelChange)="updateAdditionalCapacity()" [minValue]=0 [maxLength]=5 [maxValue]=99999 oninput="validity.valid||(value=value);" appDecimalNumber [maxDecimals]="0" class="form-control">
        <div class="invalid-feedback">Value should be between 0 and 10000!</div>
      </div>

      <div class="col total">
        <input [(ngModel)]="additionalTotalLength" readonly appDecimalNumber [maxDecimals]="0" class="form-control result validatable" [ngClass]="{'ng-invalid': additionalTotalLength < 0}">
        <div class="invalid-feedback">Total length should be a positive number!</div>
      </div>
    </div>
  </div>
  <div class="col-md-6 declaration-block">
    <app-form-group *ngIf="integralPlanningEnabled">
      <label class="required">TEU loaded at entry<app-info [placement]="'right-top'" class="pt-2">
        <div class="tooltipContent">The estimated amount of loaded TEU on the barge when passing the entry point. Only count loaded TEU to be discharged at this port, do not include reserved TEU.</div></app-info></label>
      <input [(ngModel)]="voyageData.teuOnArrival" required [minValue]=0 [maxLength]=5 [maxValue]=99999 oninput="validity.valid||(value=value);"
             appDecimalNumber [maxDecimals]="0" class="form-control">
    </app-form-group>
  </div>
</div>
