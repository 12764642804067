import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AttachedHandling, DateTimeRange, HandlingData, Visit} from '@portbase/hinterland-service-typescriptmodels';
import {removeItem, uuid} from '../../../common/utils';
import {Observable, of} from 'rxjs';
import {AppContext} from "../../../app-context";
import {HandlingModel, HinterlandUtils} from "../../hinterland-utils";
import {AbstractOverviewComponent} from "../abstract-overview.component";
import {BargeHandlingsUpload} from "../../barge/barge-handlings.upload";
import {RailHandlingsUpload} from "../../rail/rail-handlings.upload";
import {HandlingsUpload} from "../../../common/upload/handlings.upload";
import {FilterValues} from "../../../common/search/dropdown-toggle-filter/dropdown-toggle-filter.component";
import {HinterlandOrganisation, VoyageStatus} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Component({
  selector: 'app-visit-handlings-operator',
  templateUrl: './visit-handlings-operator.component.html',
  styleUrls: ['./visit-handlings-operator.component.css']
})
export class VisitHandlingsOperatorComponent extends AbstractOverviewComponent<HandlingModel> implements OnChanges {

  appContext = AppContext;

  @Input() voyageId: string;
  @Input() voyageCancelled: boolean;
  @Input() voyageDeclarant: HinterlandOrganisation;
  @Input() voyageCargoDeclarants: HinterlandOrganisation[];
  @Input() voyageStatus: VoyageStatus;
  @Input() visit: Visit;
  @Input() visitStatus: string;
  @Input() handlings: AttachedHandling[];
  @Input() openHandlingId: string;
  @Input() declareCargoAllowed: boolean;

  readonly: boolean = false;
  uploadedExcelDownloadLink: string;

  isModalityBarge: boolean;
  isModalityRail: boolean;

  newHandlings: HandlingModel[] = [];
  statusRequests: HandlingModel[] = [];
  preNotifications: HandlingModel[] = [];
  handlingFilterValues: FilterValues = null;
  localStorageKey = undefined;

  excludedFilterFields: string[] = ["cargoDeclarants"];

  ngOnInit(): void {
    const key: string = this.visit.lastUpload;
    if (key) {
      this.uploadedExcelDownloadLink = "/api/uploads/" + key;
    }
    this.appContext.persistedFilterValuesChanged.subscribe(() => this.renderFilteredItems());

    this.isModalityBarge = this.visit.modality === 'barge';
    this.isModalityRail = this.visit.modality === 'rail';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openHandlingId) {
      this.filterTerm = this.openHandlingId;
    }
    if (changes.handlings) {
      //Remove 'new' handlings that actually got added with JSON patching
      const newHandlingIds: string[] = this.handlings.filter(h => h['new'] === true).map(h => h.handlingId);
      const notNewHandlingIds: string[] = this.handlings.filter(h => !h['new'] && newHandlingIds.includes(h.handlingId)).map(h => h.handlingId);
      for (let i = this.handlings.length - 1; i >= 0; i--) {
        if (this.handlings[i]['new'] === true && notNewHandlingIds.includes(this.handlings[i].handlingId)) {
          this.handlings.splice(i, 1);
        }
      }
      this.searchableItems = {};
    }
    if (changes.handlings || changes.visit || changes.voyageStatus || changes.voyageDeclarant || changes.voyageCargoDeclarants) {
      this.items = this.handlings.map(h => this.toModel(h));
      const worstVisitHandlingStatus = HinterlandUtils.getWorstVisitHandlingStatus(this.visit);
      this.items.forEach(h => h.worstVisitHandlingStatus = worstVisitHandlingStatus);
      this.loadAndRender();
    }
    if (changes.visitStatus) {
      this.readonly = !this.visitStatus;
    }
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<(HandlingModel)[]> {
    const worstVisitHandlingStatus = HinterlandUtils.getWorstVisitHandlingStatus(this.visit);
    this.items.forEach(h => h.worstVisitHandlingStatus = worstVisitHandlingStatus);
    return of(this.items);
  }

  doRender = (items: HandlingModel[]) => {
    this.newHandlings = items.filter(h => !!h.new);
    this.statusRequests = items.filter(h => !h.new && !h.preNotification);
    this.preNotifications = items.filter(h => !h.new && h.preNotification);
  };

  getFilteredItems = (): HandlingModel[] => {
    let handlings: HandlingModel[] = this.filterTerm ? this.filterItems() : this.items;
    handlings = handlings.filter(handling => this.isHandlingFiltered(handling, this.mergeHandlingFilters(this.handlingFilterValues, AppContext.persistedFilterValues)));
    return handlings;
  }

  addHandling = () => {
    const handling = <any>{
      handlingId: uuid(),
      handlingData: <HandlingData>{
        dangerousGoods: [],
        shippersOwned: false,
        modality: this.visit.modality
      },
      preNotification: null,
      handlingResult: null,
      completed: false,
      cancelled: false,
      handlingDeclaration: null,
      declarationStatus: null,
      cargoDeclarantShortName: AppContext.userProfile.organisationShortName,
      neverAccepted: null,
      handlingDeclarations: [],
      extra: false,
      new: true
    };
    this.visit.handlings.push(handling);
    this.visit.handlings = [...this.visit.handlings];
  };

  deleteHandling = (handling: HandlingModel) => {
    removeItem(this.visit.handlings, handling);
    this.visit.handlings = [...this.visit.handlings];
    this.loadAndRender();
  };

  onHandlingFilterChanged(filterValues: FilterValues) {
    this.handlingFilterValues = filterValues;
    this.loadAndRender(false, true);
  }

  mergeHandlingFilters = (first: FilterValues, second: FilterValues) => {
    if (!first) return second;
    return {"selected": first.selected.concat(second.selected), "deselected": first.deselected.concat(second.deselected)};
  }

  private toModel(handling: AttachedHandling): HandlingModel {
    const result = <HandlingModel>handling;
    result.visitId = this.visit.visitId;
    result.voyageId = this.voyageId;
    result.visitStatus = this.visit.plannedVisitData;
    result.voyageStatus = this.voyageStatus;
    result.modality = this.visit.modality;
    result.eta = this.visit.visitData.eta;
    result.visitArrived = this.visit.arrived;
    result.visitNeverAcknowledged = this.visit.neverAcknowledged;
    result.visitDeclarationsMade = this.visit.visitDeclarations.length > 0;
    result.terminal = this.visit.terminal;
    result.status = HinterlandUtils.getHandlingStatus(result);
    result.cutOffInMinutes = HinterlandUtils.findTerminalSettings(result).cargoCutOffInMinutes;
    result.visitLoadDischargeListStatus = this.visit['loadDischargeListStatus'];
    result.visitWaitForCloseVisitEnabled = this.visit['waitForCloseVisitEnabled'];
    let declarant = this.voyageCargoDeclarants.find(c => c.shortName === handling.cargoDeclarantShortName);
    result.declarant = !!declarant ? declarant : this.voyageDeclarant;
    result.cargoDeclarants = this.voyageCargoDeclarants;
    return result;
  }

  uploadHandlings(excelFile: File, preNotification: boolean, modality: string) {
    const handlingsUpload: HandlingsUpload = modality === 'barge' ? new BargeHandlingsUpload() : new RailHandlingsUpload();
    handlingsUpload.upload(excelFile, {
      preNotification: preNotification,
      voyageId: this.voyageId,
      visitId: this.visit.visitId,
    });
  }

  newHandlingsAllowed() {
    return !this.readonly && !this.visit.arrived && !this.visit.visitCancelled && !this.voyageCancelled && this.appContext.isHinterlandDeclarant()
    && (!this.isModalityBarge || this.visit['loadDischargeListStatus'] != "closed");
  }

}
