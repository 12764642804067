<div class="declaration-block" *ngIf="isNew">
  <h2>Create a new rail voyage</h2>
</div>

<div class="row">
  <div class="col-md-6">
    <app-form-group label="Train number">
      <input class="form-control" [(ngModel)]="voyageData.train.trainNumber" required>
    </app-form-group>
    <ng-container *ngIf="hasTemplate">
      <app-form-group class="duo-form-group" label="Discharge prefix/suffix" *ngIf="hasTemplate">
        <input class="form-control" [(ngModel)]="voyageData['dischargeVoyageNumberPrefix']" placeholder="AB" required>
        <input class="form-control" [(ngModel)]="voyageData['dischargeVoyageNumberSuffix']" placeholder="11" required>
      </app-form-group>
      <app-form-group class="duo-form-group" label="Loading prefix/suffix" *ngIf="hasTemplate">
        <input class="form-control" [(ngModel)]="voyageData['loadingVoyageNumberPrefix']" placeholder="BA" required>
        <input class="form-control" [(ngModel)]="voyageData['loadingVoyageNumberSuffix']" placeholder="21" required>
      </app-form-group>
      <app-form-group>
        <label>
          Increment week number <app-info [placement]="'right-top'" wide="true" ss="pt-2">
            <div class="tooltipContent text-start">
              Indicates that the loading voyage or both voyages take place in the week after the first departure day.
                <div><span class="bullet"><b>No</b>:</span>  Both discharge and loading voyages are in the same week as the week of the first departure day.</div>
                <div><span class="bullet"><b>Loading voyage only</b>:</span>  The loading voyage is in the week following the discharge voyage.</div>
                <div><span class="bullet"><b>Discharge and loading voyage</b>:</span>  Both voyages are in week following the week of the first departure day.</div>
            </div>
          </app-info>
        </label>
        <app-select [(ngModel)]="voyageData['voyageWeekNumberIncrement']" [options]="['NONE','LOADING_VOYAGE_ONLY','BOTH']" [formatter]="formatIncrement"></app-select>
      </app-form-group>
      <app-form-group label="First departure day">
        <app-select id="firstDepartureDay" [(ngModel)]="voyageData['firstDepartureDay']" [options]="weekDays()" [formatter]="toTitleCase" required></app-select>
      </app-form-group>
    </ng-container>
    <ng-container *ngIf="!hasTemplate">
      <app-form-group>
        <label [ngClass]="{'required' : !voyageData.loadingVoyageNumber}">Discharge voyage number</label>
        <input class="form-control" [(ngModel)]="voyageData.dischargeVoyageNumber" [required]="!voyageData.loadingVoyageNumber">
      </app-form-group>
      <app-form-group>
        <label [ngClass]="{'required' : !voyageData.dischargeVoyageNumber}">Loading voyage number</label>
        <input class="form-control" [(ngModel)]="voyageData.loadingVoyageNumber" [required]="!voyageData.dischargeVoyageNumber">
      </app-form-group>
      <app-form-group label="First departure date">
        <app-date-time required [dateOnly]="true" [(ngModel)]="voyageData.firstDepartureDate"></app-date-time>
      </app-form-group>
    </ng-container>
    <app-form-group label="Traction supplier">
      <app-search [(ngModel)]="voyageData.tractionSupplier" [searchFunction]="utils.findTractionSuppliers"
                  placeholder="Search for traction supplier" dataKey="fullName"></app-search>
    </app-form-group>
  </div>

  <div class="col-md-6">
    <app-form-group label="Shuttle">
      <input class="form-control" [(ngModel)]="voyageData.shuttle">
    </app-form-group>
    <app-form-group label="Own discharge reference" *ngIf="!hasTemplate">
      <input class="form-control" [(ngModel)]="voyageData.dischargeReference">
    </app-form-group>
    <app-form-group label="Own loading reference" *ngIf="!hasTemplate">
      <input class="form-control" [(ngModel)]="voyageData.loadingReference">
    </app-form-group>
    <app-form-group label="Origin">
      <app-search [(ngModel)]="voyageData.origin" [searchFunction]="utils.findLocations"
                  [inputFormatter]="utils.locationFormatter"
                  placeholder="Search for location"
                  minCharacters="2">
      </app-search>
    </app-form-group>
    <app-form-group label="Destination">
      <app-search [(ngModel)]="voyageData.destination" [searchFunction]="utils.findLocations"
                  [inputFormatter]="utils.locationFormatter"
                  placeholder="Search for location"
                  minCharacters="2">
      </app-search>
    </app-form-group>
  </div>
</div>
