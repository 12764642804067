<div class="nav-content">
  <div class="inner-section-header p-3">History</div>

  <div class="d-flex flex-row px-3 mt-2">
    <div class="flex-column col-3 p-0 label-text">Handling ID</div>
    <div class="flex-column col-9 p-0 select-all">{{handling.handlingId}}
      <span (click)="copyToClipboard(handling.handlingId)" class="fal fa-copy ps-2"></span>
    </div>
  </div>

  <div class="d-flex flex-row p-3 mt-3 history-header">
    <div class="flex-column col-2 p-0 header-text">Timestamp</div>
    <div class="flex-column col-4 p-0 header-text">Action</div>
    <div class="flex-column col-1 p-0 header-text d-flex horizontally-centered">Queued</div>
    <div class="flex-column col-1 p-0 header-text d-flex horizontally-centered">ETA</div>
    <div class="flex-column col-3 p-0 header-text">Status</div>
    <div class="flex-column col-1 p-0"></div>
  </div>
  <div *ngFor="let item of historyItems" class="d-flex flex-row p-3 history-row">
    <div class="flex-column col-2 p-0">
      <div class="normal-row-text">{{item.formattedDate}}</div>
      <div class="secondary-row-text mt-1">{{item.formattedTime}}</div>
    </div>
    <div class="flex-column col-4 p-0">
      <div class="flex-row">
        <span class="normal-row-text">{{item.action}}</span>
        <span *ngIf="item.tar" class="secondary-row-text ms-1">(TAR: {{item.tar}})</span>
        <span *ngIf="item.visitId && !item.tar" class="secondary-row-text ms-1">(Call ID: {{item.visitId}})</span>
      </div>
      <div class="secondary-row-text mt-1">By {{item.user}}</div>
    </div>
    <div class="flex-column d-flex col-1 p-0 centered">
      <i *ngIf="item.queued" class="fal fa-clock fa-lg history-icon"></i>
    </div>
    <div class="flex-column d-flex col-1 p-0 centered">
      <div *ngIf="item.formattedEta" [ngbTooltip]="etaTooltip" class="fal fa-calendar fa-lg history-icon"
           placement="bottom" container="body"></div>
      <ng-template #etaTooltip>
        <div class="tooltip-card">
          <div class="tooltip-card-title">ETA</div>
          <div class="tooltip-card-body text-nowrap">{{item.formattedEta}}</div>
        </div>
      </ng-template>
    </div>
    <div class="flex-column d-flex col-3 p-0 vertically-centered">
      <app-handling-status-panel *ngIf="!!item.handlingForStatusComponent"
                                 [disableTooltip]="true"
                                 [handling]="item.handlingForStatusComponent"
                                 [visit]=item.visitForStatusComponent>

      </app-handling-status-panel>
    </div>
    <div class="flex-column d-flex col-1 p-0 align-right">
      <div *ngIf="!!item.href" aria-expanded="false" class="btn btn-tertiary fixed-button-width" data-bs-toggle="dropdown">
        <i class="fal fa-ellipsis-vertical fa-xl grey-icon"></i>
      </div>
      <div *ngIf="!!item.href" class="dropdown-menu dropdown-menu-end">
        <a [href]="item.href" class="dropdown-item" target="_blank" title="View status at this point in time">View
          snapshot at this point in time</a>
      </div>
    </div>
  </div>
</div>
