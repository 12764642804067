import {Component, Input} from '@angular/core';

import {ConfirmationModalService} from '../../modals/confirmation-modal/confirmation-modal.service';
import {ClosableModal} from "../../modals/edit-modal/edit-modal.component";
import {DialogComponent} from "../../dialog/dialog.component";

@Component({
  selector: 'app-switch-editing-dialog',
  templateUrl: './switch-editing-dialog.component.html',
  styleUrls: ['./switch-editing-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogComponent
  ]
})
export class SwitchEditingDialogComponent {

  @Input() data: ClosableModal;

  constructor(
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
  }

  switchWithoutSaving() {
    this.confirmationModalService.closeModal();
    this.data.switchModal();
  }

  closeDialog() {
    this.confirmationModalService.closeModal();
  }
}
