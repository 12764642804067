import {Component} from '@angular/core';
import {AppContext} from '../../app-context';
import {BhgSupportComponent} from '../bhg-support/bhg-support.component';
import {EditModalService} from "../../components/modals/edit-modal/edit-modal.service";
import {environment} from "../../../environments/environment";
import {mockBinnenhavengeldProfiles, setCurrentBinnenhavengeldUser} from "../../mocking/binnenhavengeld.mock";
import {HinterlandUtils} from "../../hinterland/hinterland-utils";
import {BinnenhavengeldUserProfile} from "@portbase/hinterland-service-typescriptmodels";
import {
  BhgEditApplicationConfigComponent
} from "../bhg-admin/bhg-edit-application-config/bhg-edit-application-config.component";

@Component({
  selector: 'app-bhg-menu',
  templateUrl: './bhg-menu.component.html',
  styleUrls: ['../bhg.scss', './bhg-menu.component.scss'],
})
export class BhgMenuComponent {

  initials: string;
  local = !environment.production && !environment.docker;
  mockUsers = mockBinnenhavengeldProfiles;
  utils = HinterlandUtils;
  currentUser = AppContext.binnenhavengeldUserProfile;
  context = AppContext;

  constructor(
    private readonly editModalService: EditModalService,
  ) {
    let parts = AppContext.binnenhavengeldUserProfile.email.split("@");
    this.initials = (parts[0].substring(0, 1) + parts[1]?.substring(0, 1)).toUpperCase();
  }

  openSupport() {
    this.editModalService.openModal(BhgSupportComponent);
  }

  setCurrentUser(userProfile: BinnenhavengeldUserProfile) {
    setCurrentBinnenhavengeldUser(userProfile);
  }

  updateAccount() {
    window.location.href = "/binnenhavengeld/update-account";
  }

  openTermsAndConditions() {
    window.open(this.context.getBinnenhavengeldSupportLink(), '_blank').focus();
  }

  signOut = () => {
    if (environment.production || environment.docker) {
      window.location.href = '/api/proxy/binnenhavengeld/logout';
    }
  };

  editApplicationConfig() {
    this.editModalService.openModal(BhgEditApplicationConfigComponent);
  }

}
