<div class="row narrow-gutters">
  <div class="col always-enabled">
    <app-filter [(ngModel)]="filterTerm"
                [searchFunction]="getFilteredItems"
                (resultsFound)="renderFilteredItems()"
                placeholder="Filter by container number, reference, etc...">
    </app-filter>
  </div>
  <div class="col-auto">
    <dropdown-toggle-filter (changed)="onHandlingFilterChanged($event)"></dropdown-toggle-filter>
  </div>
  <div *ngIf="newHandlingsAllowed()" [ngClass]="{'enabled': declareCargoAllowed}" class="col-auto pb-2">
    <button (click)="addHandling()" class="btn btn-info" tabindex="-1" style="width: 12rem"><span class="pe-2 fa fa-plus-circle"></span> Add handling</button>

    <ng-container *ngIf="!appContext.isPhone()">
      <span class="dropdown mx-1">
        <button class="btn btn-info dropdown-toggle" type="button" data-bs-toggle="dropdown" style="width: 12rem">
          <span class="pe-2 fa fa fa-file-upload"></span> Upload handlings
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li *ngIf="visit.modality != 'barge' || !visit.terminal.bargeStatusRequestForbidden"><button class="dropdown-item" type="button" (click)="uploadStatus.click()">Status requests <input
            type="file" hidden (change)="uploadHandlings($event.target.files[0], false, visit.modality); $event.target.value = '';" #uploadStatus></button></li>
          <li><button class="dropdown-item" type="button" (click)="uploadPre.click()">Pre-notifications <input
            type="file" hidden (change)="uploadHandlings($event.target.files[0], true, visit.modality); $event.target.value = '';" #uploadPre></button></li>
        </ul>
      </span>
    </ng-container>
  </div>
</div>

<div class="text-end small" *ngIf="!readonly && !appContext.isPhone() && (visit.modality === 'rail' || visit.modality === 'barge') && appContext.isHinterlandDeclarant()">
  <a *ngIf="appContext.isAdmin() && uploadedExcelDownloadLink" download="{{visit.visitId}} + '_handlings.xlsx'"
     href="{{uploadedExcelDownloadLink}}" class="align-middle pe-2 text-portbase-dark-blue ms-2 me-4"
     tabindex="-1">Download uploaded sheet <span class="fa fa-file-download"></span>
  </a>

  <a *ngIf="isModalityRail && newHandlingsAllowed()"
     href="/assets/templates/rail-upload-template-2.5.xlsx" class="align-middle pe-2 text-portbase-dark-blue ms-2"
     tabindex="-1">Download XLS template <span class="fa fa-file-download"></span>
  </a>
  <a *ngIf="isModalityBarge && newHandlingsAllowed()"
     href="/assets/templates/barge-upload-template-2.4.xlsx" class="align-middle pe-2 text-portbase-dark-blue ms-2"
     tabindex="-1">Download XLS template <span class="fa fa-file-download"></span>
  </a>
</div>

<div *ngIf="newHandlings.length > 0" class="pb-2 handlings-container">
  <div class="declaration-block"></div>
  <app-handlings [setHandlings]="newHandlings"
                 [readonly]="readonly"
                 (deleteHandling)="deleteHandling($event)">
  </app-handlings>
</div>

<div *ngIf="statusRequests.length > 0" class="pb-2 handlings-container">
  <div class="declaration-block">
    <h2>Status requests ({{statusRequests.length}})</h2>
  </div>
  <app-handlings
    [hideAccepted]="true"
    [setHandlings]="statusRequests"
    [readonly]="this.readonly"
    (deleteHandling)="deleteHandling($event)"
    class="mt-3">
  </app-handlings>
</div>

<div *ngIf="preNotifications.length > 0" class="pb-2 handlings-container">
  <div class="declaration-block">
    <h2>Pre-notifications ({{preNotifications.length}})</h2>
  </div>
  <app-handlings
    [hideAccepted]="true"
    [setHandlings]="preNotifications"
    [readonly]="this.readonly"
    (deleteHandling)="deleteHandling($event)">
  </app-handlings>
</div>
