import {QueryGateway} from './query-gateway';
import {Injectable, Injector} from "@angular/core";

@Injectable()
export abstract class QueryHandler {
  constructor(injector: Injector) {
    const queryGateway = injector.get(QueryGateway);
    queryGateway.registerLocalHandler(this);
  }
}
