<div class="row g-0 ms-3">
  <div class="col">
    <ng-container *ngIf="movement.item as item">
      <app-fancy-collapse [collapse]="containerTemplate" [showOnLoad]="true">
        <div class="row g-0 py-1 px-3 align-items-center text-dark">
          <div class="col">
            <span class="fa fa-fw fa-cube pe-2 me-2"></span>
            <span class="d-inline-block align-top text-truncate">{{item.containerNumber}}</span>
          </div>
          <div *ngIf="item.sizeType" class="col d-none d-xl-flex text-dark">
            <span *ngIf="item.sizeType" class="d-none d-sm-inline-block align-top text-truncate"><span class="fa fa-expand-alt ms-3 me-2"></span>{{item.sizeType.code}}</span>
          </div>
          <div class="col text-end me-3 d-none d-md-block text-dark">
            <app-cargo-summary [movement]="movement"></app-cargo-summary>
          </div>
        </div>
      </app-fancy-collapse>

      <ng-template #containerTemplate>
        <div class="collapse">
          <div class="mx-3 pt-3">
            <fieldset disabled>
              <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li ngbNavItem="1">
                  <a ngbNavLink>Container</a>
                  <ng-template ngbNavContent>
                    <div>
                      <app-cargo-container [movement]="movement" [shipment]="shipment"></app-cargo-container>
                    </div>
                  </ng-template>
                </li>

                <li ngbNavItem="2">
                  <a ngbNavLink>Goods</a>
                  <ng-template ngbNavContent>
                    <div>
                      <app-cargo-goods [shipment]="shipment"></app-cargo-goods>
                    </div>
                  </ng-template>
                </li>

                <li ngbNavItem="3" *ngIf="movement.vessel?.modality !== 'sea' && shipment.data.transportOrder as order">
                  <a ngbNavLink>Transport order</a>
                  <ng-template ngbNavContent>
                    <div class="p-3 border border-top-0 rounded-bottom mb-3">
                      <app-transport-order [order]="order"></app-transport-order>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav"></div>
            </fieldset>
          </div>
        </div>
      </ng-template>
    </ng-container>

  </div>
</div>
