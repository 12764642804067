<div class="row no-gutters bg-light py-2">
  <div class="col required"><span class="ps-2">Name</span></div>
  <div class="col-3 d-none padded-col d-xl-block">Address</div>
  <div class="col-3 d-none padded-col d-lg-block">City</div>
  <div class="col-2 d-none padded-col d-md-block">Country</div>
  <div class="col-auto" style="min-width: 16px"></div>
</div>

<div *ngFor="let cargoDeclarant of cargoDeclarants; trackBy : trackByIndex; let idx = index;" class="row g-0 my-2 mx-0">
  <div class="col pe-3">
    <app-search [(ngModel)]="cargoDeclarants[idx]" [searchFunction]="utils.findForwarders" required [disabled]="isVoyageDeclarant(cargoDeclarant?.shortName)"
                placeholder="Find organisation" dataKey="fullName">
    </app-search>
  </div>
  <div class="col-3 d-none d-xl-block">
    <div class="padded-col">{{cargoDeclarant?.address}}</div>
  </div>
  <div class="col-3 d-none d-lg-block">
    <div class="padded-col">{{cargoDeclarant?.city}}</div>
  </div>
  <div class="col-2 d-none d-md-block">
    <div class="padded-col">{{cargoDeclarant?.countryName}}</div>
  </div>
  <div class="col-auto text-end px-0" style="min-width: 16px">
    <button *ngIf="!isVoyageDeclarant(cargoDeclarant?.shortName)" class="btn px-0" type="button"
            title="Remove declarant"
            (click)="cargoDeclarants.splice(idx, 1)" tabindex="-1"><span class="fa fa-trash text-secondary"></span>
    </button>
  </div>
</div>
<div class="row justify-content-between my-3">
  <div class="col-auto"></div>
  <div class="col-auto">
    <button (click)="addCargoDeclarant()" type="button" class="btn btn-info" style="min-width: 10rem">
      <span class="fa fa-plus-circle pe-2"></span> Add declarant
    </button>
  </div>
</div>

<div class="mt-3 text-end">
  <button (click)="updateCargoDeclarants()" type="button" class="btn btn-info" tabindex="-1"
          style="min-width: 10rem;"><span>Save declarants</span></button>
</div>
