<h3 class="py-2">Import new reference data</h3>

<h5>Beware! This will replace all existing data of the selected type.</h5>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row py-4">
    <label class="col-auto">
      Type:
      <select name="collection" formControlName="collection">
        <option *ngFor="let uploadType of uploadTypes" [ngValue]="uploadType.value">{{uploadType.text}}</option>
      </select>
    </label>
    <div class="col-auto">
      <input type="file" name="data" (change)="onFileChange($event)"/>
    </div>
    <div class="col-auto">
      <input type="submit">
    </div>
  </div>
</form>
