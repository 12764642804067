<ng-container *ngIf="appContext.binnenhavengeld; else mca">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #mca>
  <div id='screen' class="container-fluid pb-5" *ngIf="!appContext.road">
    <!--spinning wheel-->
    <div [hidden]="appContext.pendingProcesses.length === 0" class="position-fixed row h-100 w-100" style="z-index: 10000;">
      <div class="position-absolute bg-secondary h-100 w-100" style="opacity: 0.3"></div>
      <div class="spinner-border mx-auto text-secondary align-self-center" role="status"
           style="width: 5rem; height: 5rem; border-width: 1rem;"></div>
    </div>

    <ng-container *ngIf="appContext.userProfile">
      <app-navigation class="position-relative" style="z-index: 1030"></app-navigation>

      <div class="row page-section pt-2 mx-n3 mx-sm-auto">
        <div class="col-12 sticky-top">
          <app-alerting></app-alerting>
        </div>
        <div class="col-12">
          <!--overview screen-->
          <!--crazy ngFor here to force rerender if userProfile changes-->
          <div *ngFor="let ignored of [appContext.userProfile.contact]">
            @if(window.location.pathname === '/'){
              @if(appContext.isAdmin() || appContext.isHinterlandTerminal()){
                <app-terminal-overview></app-terminal-overview>
              } @else { <app-operator-overview></app-operator-overview> }
            } @else { <router-outlet></router-outlet> }
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="appContext.userProfile && appContext.road">
    <router-outlet></router-outlet>
  </ng-container>

  <app-ribbon></app-ribbon>
  <app-send-email-token-component [visible]="pcsDown"></app-send-email-token-component>
</ng-template>
