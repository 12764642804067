import { Component, OnInit } from '@angular/core';
import moment from "moment";
import { HinterlandUtils } from "../../../../hinterland/hinterland-utils";
import {
  CancelAndDetachHandling,
  CancelDetachedHandling,
  DeepSea,
  DetachedHandling,
  DetachHandling,
  ResendHandlingDeclaration,
  ResendWaitingHandling, RoadHandling,
  RoadVisit,
  RoadVoyage,
  TransportOrder
} from "@portbase/hinterland-service-typescriptmodels";
import { HinterlandBaseComponent } from "../../../../common/hinterland-base-component";
import { VoyageVisitAndHandling } from "../../../../hinterland/types";
import { AppContext } from "../../../../app-context";
import { sendCommand } from "../../../../common/utils";
import { EditHandlingOffCanvasComponent } from "../edit-handling-offcanvas/app-edit-handling-offcanvas";
import { ChangeVesselOffCanvasComponent } from "../change-vessel-offcanvas/change-vessel-offcanvas";
import {
  AcceptDetachedHandling,
  AcceptHandling,
  RejectDetachedHandling,
  RejectHandling
} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import { AddHandlingOffCanvasComponent } from "../add-handling-offcanvas/app-add-handling-offcanvas";
import { EmailHandlingComponent } from "../../dialog/email-handling-dialog/email-handling.component";
import {
  CancelHandlingConfirmationComponent
} from "../../dialog/cancel-handling-confirmation-dialog/cancel-handling-confirmation.component";
import {
  DetachHandlingConfirmationComponent
} from "../../dialog/detach-handling-confirmation-dialog/detach-handling-confirmation.component";
import { EventType } from "../../../../common/event-gateway";
import {
  MoveHandlingConfirmationComponent
} from "../../dialog/move-handling-confirmation-dialog/move-handling-confirmation.component";
import { EditModalService } from '../../../../components/modals/edit-modal/edit-modal.service';
import { ConfirmationModalService } from '../../../../components/modals/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-view-handling-offcanvas',
  templateUrl: './app-view-handling-offcanvas.html',
  styleUrls: ['./app-view-handling-offcanvas.scss']
})
export class ViewHandlingOffCanvasComponent extends HinterlandBaseComponent implements OnInit {

  appContext = AppContext;
  utils = HinterlandUtils;

  data: VoyageVisitAndHandling;
  voyage: RoadVoyage;
  visit: RoadVisit;
  handling: RoadHandling | DetachedHandling;

  transportOrder: TransportOrder;
  transportOrderDemurrage: number;
  transportOrderDetention: number;
  transportOrderReleaseExpiration: string;

  isPickup: boolean;
  isDetached: boolean;
  isHandlingEditable: boolean;
  isHandlingUpdateForbidden: boolean;
  isHandled: boolean;
  isVesselEditable: boolean;

  isDifferentGrossWeight: boolean;
  isDifferentTareWeight: boolean;
  isDifferentShippingCompany: boolean;
  isDifferentSizeType: boolean;

  isRequestedEtaOutsideTimeslot: boolean;
  terminalName: string;
  eta: string;

  deepSea: DeepSea;
  cargoOpeningTime: string;
  cargoClosingTime: string;

  showAdminFunctions: boolean = false;

  emailAddress: string;

  constructor(
      private readonly editModalService: EditModalService,
      private readonly confirmationModalService: ConfirmationModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.voyage = this.data?.voyage;
    this.visit = this.data?.visit;
    this.handling = this.data?.handling;

    this.isDetached = !this.voyage;
    this.isPickup = this.handling.handlingType === "loading";

    this.isDifferentShippingCompany = !!this.handling.handlingStatus?.shippingCompany && this.handling.handlingData?.shippingCompany.scacCode !== this.handling.handlingStatus?.shippingCompany.scacCode;
    this.isDifferentSizeType = !!this.handling.handlingStatus?.sizeType && this.handling.handlingData?.sizeType?.code !== this.handling.handlingStatus?.sizeType?.code;
    this.isDifferentGrossWeight = !!this.handling.handlingStatus?.weight && this.handling.handlingData?.weight !== this.handling.handlingStatus?.weight;
    this.isDifferentTareWeight = !!this.handling.handlingStatus?.tareWeight && this.handling.handlingData?.tareWeight !== this.handling.handlingStatus?.tareWeight;

    this.isRequestedEtaOutsideTimeslot = this.etaOutsideWindow();
    this.terminalName = this.getTerminalDisplayName();
    this.eta = this.isDetached ? this.handling['eta'] : this.visit?.requestedVisitData?.eta;

    let terminal;
    if (this.isDetached) {
      terminal = (<DetachedHandling>this.handling).terminal;
    } else {
      terminal = this.visit.terminal;
    }

    this.isHandlingUpdateForbidden = terminal.handlingUpdateForbidden !== undefined && terminal.handlingUpdateForbidden.indexOf("road") !== -1;
    this.isHandled = 'handlingResult' in this.handling && !!this.handling['handlingResult'];
    this.isHandlingEditable = this.utils.isCargoDeclarant(this.handling)
      && (this.isDetached || !this.isHandlingUpdateForbidden)
      && !this.visit?.visitCompleted
      && !this.isHandled;

    this.isVesselEditable = this.utils.isCargoDeclarant(this.handling)
      && !this.visit?.visitCompleted
      && !this.isHandled;

    this.showAdminFunctions = AppContext.isAdmin()
      && !this.visit?.visitCompleted
      && !this.isHandled;

    this.transportOrder = this.data.handling.transportOrder;
    if (this.transportOrder) {
      this.transportOrderDemurrage = parseInt(this.transportOrder?.demurrage.match(/\d+/)[0]) / 24;
      this.transportOrderDetention = parseInt(this.transportOrder?.detention.match(/\d+/)[0]) / 24;
      this.transportOrderReleaseExpiration = this.transportOrder?.releaseExpiration.replace("T", " ").slice(0, -1);
    }

    this.getDeepSea();
    this.getWindow();
  }

  private etaOutsideWindow() {
    return moment(this.visit?.requestedVisitData?.eta).isBefore(moment(this.visit?.plannedVisitData?.eta))
      || moment(this.visit?.requestedVisitData?.eta).isAfter(moment(this.visit?.plannedVisitData?.etd));
  }

  private getTerminalDisplayName() {
    let terminal = this.isDetached ? (<DetachedHandling>this.handling).terminal : this.visit.terminal;
    return HinterlandUtils.getTerminalDisplayName(terminal, 'road');
  }

  private getDeepSea() {
    if (this.handling.deepSea) {
      this.deepSea = this.handling.deepSea;
    }
  }

  private getWindow() {
    if (!!this.deepSea?.cargoOpeningTime || !!this.deepSea?.cargoClosingTime) {
      this.cargoOpeningTime = this.deepSea.cargoOpeningTime;
      this.cargoClosingTime = this.deepSea.cargoClosingTime;
    } else if (!!this.handling.handlingStatus?.window) {
      this.cargoOpeningTime = this.handling.handlingStatus?.window?.start;
      this.cargoClosingTime = this.handling.handlingStatus?.window?.end;
    }
  }

  formatDateTime(dateTime: string) {
    const m = moment(dateTime);
    return m.format(`${m.year() === moment().year() ? 'DD MMM' : 'DD MMM [’]YY'}, HH:mm`);
  }

  isChartered() {
    return !!this.voyage && this.voyage?.voyageData?.charter?.portbaseId === this.appContext.userProfile.organisationShortName
      && this.voyage?.declarant?.shortName !== this.appContext.userProfile.organisationShortName;
  }

  resendPendingMessages = () => {
    sendCommand("com.portbase.hinterland.api.common.command.ResendWaitingHandling",
      <ResendWaitingHandling>{
        voyageId: this.voyage.voyageId,
        visitId: this.visit.visitId,
        handlingId: this.handling.handlingId
      },
      () => AppContext.publishSuccessMessage('Handling was resent successfully'),
      error => AppContext.publishErrorMessage(error)
    );
  }

  resendHandlingDeclaration = () => {
    sendCommand("com.portbase.hinterland.api.common.command.ResendHandlingDeclaration",
      <ResendHandlingDeclaration>{
        voyageId: this.voyage.voyageId,
        visitId: this.visit.visitId,
        handlingId: this.handling.handlingId
      },
      () => AppContext.publishSuccessMessage('Handling declaration was resent successfully'),
      error => AppContext.publishErrorMessage(error)
    );
  }

  rejectHandling = () => {
    if (this.isDetached) {
      sendCommand("com.portbase.hinterland.api.common.command.RejectDetachedHandling",
        <RejectDetachedHandling>{
          handlingId: this.handling.handlingId
        },
        () => AppContext.publishSuccessMessage('Handling was rejected successfully'),
        error => AppContext.publishErrorMessage(error?.error?.error)
      );
    } else {
      sendCommand("com.portbase.hinterland.api.common.command.RejectHandling",
        <RejectHandling>{
          voyageId: this.voyage.voyageId,
          visitId: this.visit.visitId,
          handlingId: this.handling.handlingId
        },
        () => AppContext.publishSuccessMessage('Handling was rejected successfully'),
        error => AppContext.publishErrorMessage(error?.error?.error)
      );
    }
  }

  acceptHandling = () => {
    if (this.isDetached) {
      sendCommand("com.portbase.hinterland.api.common.command.AcceptDetachedHandling",
        <AcceptDetachedHandling>{
          handlingId: this.handling.handlingId,
          statuses: []
        },
        () => AppContext.publishSuccessMessage('Handling was accepted successfully'),

        error => {
          let errorMessage = error?.error?.error;
          AppContext.publishErrorMessage(errorMessage ? errorMessage : 'An error occurred. Please contact Portbase Customer Service.');
        }
      );
    } else {
      sendCommand("com.portbase.hinterland.api.common.command.AcceptHandling",
        <AcceptHandling>{
          voyageId: this.voyage.voyageId,
          visitId: this.visit.visitId,
          handlingId: this.handling.handlingId,
          statuses: [],
          updateLatest: this.visit.terminal.apiOnly
        },
        () => AppContext.publishSuccessMessage('Handling was accepted successfully'),
        error => {
          let errorMessage = error?.error?.error;
          AppContext.publishErrorMessage(errorMessage ? errorMessage : 'An error occurred. Please contact Portbase Customer Service.');
        }
      );
    }
  }

  openEditHandlingPanel() {
    this.editModalService.openModal(EditHandlingOffCanvasComponent, <VoyageVisitAndHandling>{
      'voyage': this.voyage,
      'visit': this.visit,
      'handling': this.handling
    });
  }

  openChangeVesselPanel() {
    this.editModalService.openModal(ChangeVesselOffCanvasComponent, <VoyageVisitAndHandling>{
      'voyage': this.voyage,
      'visit': this.visit,
      'handling': this.handling
    });
  }

  emailHandling() {
    this.confirmationModalService.openModal(EmailHandlingComponent, {
      'handlingId': this.handling.handlingId
    });
  }

  duplicateHandling() {
    this.editModalService.openModal(AddHandlingOffCanvasComponent, {
      voyage: this.voyage,
      visit: this.visit,
      handling: this.handling,
      addMode: 'duplicate'
    });
  }

  cancelHandling() {
    if (this.isDetached) {
      sendCommand("com.portbase.hinterland.api.common.command.CancelDetachedHandling",
        <CancelDetachedHandling>{
          handlingId: this.handling.handlingId
        },
        () => {
          AppContext.publishSuccessMessage('Handling cancelled.');
          this.editModalService.closeModal();
        },
        error => AppContext.publishErrorMessage(error?.error?.error)
      );
    } else {
      if (this.visit?.handlings.length > 1) {
        sendCommand("com.portbase.hinterland.api.common.command.CancelAndDetachHandling",
          <CancelAndDetachHandling>{
            voyageId: this.voyage?.voyageId,
            visitId: this.visit?.visitId,
            handlingId: this.handling.handlingId
          },
          () => {
            AppContext.publishSuccessMessage('Handling cancelled.');
            this.editModalService.closeModal();
          },
          error => AppContext.publishErrorMessage(error?.error?.error)
        );
      } else {
        this.confirmationModalService.openModal(CancelHandlingConfirmationComponent, {
          'voyageId': this.voyage?.voyageId,
          'visitId': this.visit?.visitId,
          'handlingId': this.handling.handlingId
        });
      }
    }
  }

  attachToVisit() {
    if (this.visit?.handlings.length > 1 || this.isDetached) {
      this.eventGateway.publish(EventType.StartAttachingToVisit, this.data);
    } else {
      this.confirmationModalService.openModal(MoveHandlingConfirmationComponent, this.data);
    }
    this.editModalService.closeModal();
  }

  detachFromVisit() {
    if (this.visit?.handlings.length > 1) {
      sendCommand("com.portbase.hinterland.api.common.command.DetachHandling",
        <DetachHandling>{
          voyageId: this.voyage?.voyageId,
          visitId: this.visit?.visitId,
          handlingId: this.handling.handlingId
        },
        () => {
          AppContext.publishSuccessMessage('Handling detached.');
          this.editModalService.closeModal();
        },
        error => AppContext.publishErrorMessage(error?.error?.error)
      );
    } else {
      this.confirmationModalService.openModal(DetachHandlingConfirmationComponent, {
        'voyageId': this.voyage?.voyageId,
        'visitId': this.visit?.visitId,
        'handlingId': this.handling.handlingId
      });
    }
  }

}
