import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CancelAndDetachHandling,
  GetTerminalByCode,
  HandlingData,
  Notification,
  NotificationLevel,
  RoadHandling,
  RoadVisit
} from "@portbase/hinterland-service-typescriptmodels";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {EventHandler, EventType} from "../../../../common/event-gateway";
import {
  RoadVisitStatus,
  RoadVoyage,
  ShippingCompany,
  SizeType
} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {HinterlandUtils} from "../../../../hinterland/hinterland-utils";
import {CommandRequest, CommandResponse, sendCommand, sendCommands, sendQuery, uuid} from "../../../../common/utils";
import {UpdateVisitOffCanvasComponent} from "../../offcanvas-panel/update-visit-offcanvas/app-update-visit-offcanvas";
import {ActionMode, VisitPanelDisplayMode, VoyageAndVisit, VoyageVisitAndHandling} from "../../../../hinterland/types";
import {AppContext} from "../../../../app-context";
import moment from "moment/moment";
import mustache from "mustache"
import {EditModalService} from '../../../../components/modals/edit-modal/edit-modal.service';
import {ConfirmationModalService} from "../../../../components/modals/confirmation-modal/confirmation-modal.service";
import {CopyVisitAsTextDialogComponent} from "../../dialog/copy-visit-as-text-dialog/copy-visit-as-text-dialog";
import {AddRemainOnTruckComponent} from "../../dialog/add-remain-on-truck-dialog/add-remain-on-truck.component";
import {
  RemoveRemainOnTruckContainerComponent
} from "../../dialog/remove-remain-on-truck-container-dialog/remove-remain-on-truck-container.component";
import {
  RemoveRemainOnTruckContainersComponent
} from "../../dialog/remove-remain-on-truck-containers-dialog/remove-remain-on-truck-containers.component";

@Component({
  selector: 'app-visit-panel',
  templateUrl: './app-visit-panel.html',
  styleUrls: ['./app-visit-panel.scss']
})
export class VisitPanelComponent extends HinterlandBaseComponent implements OnInit {

  utils = HinterlandUtils;
  appContext = AppContext;

  @Input() voyage: RoadVoyage;
  @Input() visit: RoadVisit;
  @Input() displayMode: VisitPanelDisplayMode = 'standard';
  @Input() actionMode: ActionMode;

  @Output() handlingSelected = new EventEmitter<string>();

  hasTimeslot: boolean;
  hasTar: boolean;
  eta: string;
  containerNumber: string;
  firstHandling: RoadHandling;
  remainOnTruckContainers: RoadHandling[] = [];

  applicableNotifications: Notification[] = [];
  highLevelApplicableNotifications: Notification[] = [];
  mediumLevelApplicableNotifications: Notification[] = [];
  lowLevelApplicableNotifications: Notification[] = [];

  constructor(
    private readonly editModalService: EditModalService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.hasTimeslot = (!!this.visit.plannedVisitData &&
      !!this.visit.plannedVisitData.etd &&
      this.visit.visitData.eta === this.visit.plannedVisitData.eta);
    this.hasTar = (this.visit.plannedVisitData?.tar &&
      this.visit.plannedVisitData?.tar !== '1');
    this.eta = (!!this.visit.visitData.eta) ? this.visit.visitData.eta : this.visit.requestedVisitData?.eta;
    this.firstHandling = this.visit?.handlings[0];
    this.initNotifications();
    this.remainOnTruckContainers = this.voyage?.visits
      .filter(v => v.visitId != this.visit.visitId)
      .flatMap(v => v.handlings) || [];
  }

  onEnterActionMode: EventHandler<EventType.EnterActionMode> = (data) => {
    if (!!data.handling) {
      this.visit['handlingIdToAttach'] = data.handling.handlingId;
    }
  }

  onExitActionMode: EventHandler<EventType.ExitActionMode> = () => {
    this.visit['handlingIdToAttach'] = undefined;
  }

  updateVisitAllowed() {
    return HinterlandUtils.isVoyageDeclarant(this.voyage) && !this.visit.arrived && !this.isReadOnly();
  }

  startAddingHandlingsToVisit() {
    this.eventGateway.publish(EventType.StartAddingHandlingsToVisit, {
      'voyage': this.voyage,
      'visit': this.visit
    } as VoyageVisitAndHandling);
  }

  openUpdateVisitCanvas() {
    this.editModalService.openModal(UpdateVisitOffCanvasComponent, {
      'voyage': this.voyage,
      'visit': this.visit
    } as VoyageAndVisit);
  }

  copyVisitAsText() {
    fetch('assets/templates/template.mustache')
      .then((response) => response.text())
      .then((template) => {
        let remainOnTruckContainers = this.voyage.remainOnTruckContainers ? this.voyage.remainOnTruckContainers[this.visit.visitId] : null;

        var handlingCount = this.visit.handlings ? this.visit.handlings.length : 0;
        var remainOnTruckCount = remainOnTruckContainers ? remainOnTruckContainers.length : 0;

        var totalCount = handlingCount + remainOnTruckCount;
        var handlingsIndex = 0;

        var remainIndex = 0;
        const text = mustache.render(template, {
          visit: this.visit,
          tar: this.visit.plannedVisitData?.tar,
          timeslot: this.formatTimeslot(this.visit.plannedVisitData),
          terminal: HinterlandUtils.getTerminalDisplayName(this.visit.terminal, 'road'),
          handlings: this.formatHandlings(this.visit.handlings),
          remainOnTruckContainers: remainOnTruckContainers,
          showCommaHandlings: function () {
            handlingsIndex++;

            return handlingsIndex != totalCount;
          },
          showCommaRemain: function () {
            remainIndex++;
            return remainIndex != remainOnTruckCount;
          }
        });

        if (this.isiOS()) {
          this.confirmationModalService.openModal(CopyVisitAsTextDialogComponent, {"text": text});
        } else {
          navigator.clipboard.writeText(text);
          AppContext.publishSuccessMessage("Visit successfully copied to clipboard", "Visit copied");
        }
      });
  }

  isiOS() {
    var isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    var isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    return isSafari || isiOS;
  }

  formatTimeslot(plannedVisitData: RoadVisitStatus) {
    if (!plannedVisitData || !plannedVisitData.eta) {
      return null;
    }

    if (moment(plannedVisitData.eta).date() === moment(plannedVisitData.etd).date()) {
      return moment(plannedVisitData.eta).format('DD MMM YYYY [between] HH:mm-')
        + moment(plannedVisitData.etd).format('HH:mm');
    } else {
      return 'Between '
        + moment(plannedVisitData.eta).format('DD MMM YYYY HH:mm - ')
        + moment(plannedVisitData.etd).format('DD MMM YYYY HH:mm');
    }
  }

  formatSlotTime(eta: string, ptd: string) {
    if (!eta && !ptd) {
      return '-';
    }

    let start = moment(eta).format('HH:mm');
    return ptd ? `${start} - ${moment(ptd).format('HH:mm')}` : `${start}`;
  }

  formatSlotDate(eta: string) {
    if (!eta) {
      return '-';
    }

    return moment(eta).format('DD-MM-yyyy');
  }

  addRemainOnTruckModal() {
    this.confirmationModalService.openModalWithCallback((containerNumber) => {
      if (!!containerNumber) {
        this.containerNumber = containerNumber;
        this.addRemainOnTruck();
      }
    }, AddRemainOnTruckComponent);
  }

  addRemainOnTruck() {
    let etaBeforeVisitEta = undefined;
    if (!!this.eta) {
      const etaMoment = moment(this.eta);
      etaBeforeVisitEta = etaMoment.subtract('1', 'minutes');
    }

    sendQuery('com.portbase.hinterland.api.refdata.query.GetTerminalByCode', <GetTerminalByCode>{
      code: 'OTHER',
      modality: "road"
    })
      .subscribe(otherTerminal => {
        const newHandling: RoadHandling = <RoadHandling>{
          handlingId: uuid(),
          handlingData: <HandlingData>{
            modality: 'road',
            full: true,
            type: 'loading',
            equipmentNumber: this.containerNumber,
            shippersOwned: true,
            bookingNumber: "-",
            shippingCompany: <ShippingCompany>{
              name: 'Unknown Carrier',
              scacCode: 'UNKU',
              smdgCode: 'UNK'
            },
            sizeType: <SizeType>{
              name: '20\'x8\'x8\'6\" General Purpose Container',
              code: '22G1'
            }
          },
          preNotification: true,
          handlingDeclarations: []
        };

        this.queryAndCommandGateway.createRoadVisit(newHandling, otherTerminal, null, this.voyage.voyageId, etaBeforeVisitEta, function () {
          AppContext.publishSuccessMessage(this.containerNumber + ' added as \'Remain on Truck\' container', 'Remain on Truck');
        }.bind(this));
      });
  }

  initNotifications() {
    if (!!this.visit?.plannedVisitData?.notifications) {
      this.applicableNotifications = this.visit?.plannedVisitData?.notifications.filter(a => (this.appContext.isAdmin() || this.appContext.isHinterlandTerminalAndDeclarant()) ? true :
        this.appContext.isHinterlandDeclarant() ? a.recipients.includes("INLAND_TRANSPORT_OPERATOR") : this.appContext.isHinterlandTerminal() ? a.recipients.includes("TERMINAL") : false);

      this.lowLevelApplicableNotifications = this.getNotificationsForLevel('LOW');
      this.mediumLevelApplicableNotifications = this.getNotificationsForLevel('MEDIUM');
      this.highLevelApplicableNotifications = this.getNotificationsForLevel('HIGH');
    }
  }

  getNotificationClass() {
    if (this.highLevelApplicableNotifications.length > 0) {
      return "notification-danger";
    } else if (this.mediumLevelApplicableNotifications.length > 0) {
      return "notification-warning";
    } else {
      return "notification-info";
    }
  }

  getNotificationsForLevel(level: NotificationLevel): Notification[] {
    return this.applicableNotifications.filter(a => a.level == level || (level == "LOW" && !a.level));
  }

  isChartered() {
    return this.voyage?.voyageData?.charter?.portbaseId === this.appContext.userProfile.organisationShortName && this.voyage?.declarant?.shortName !== this.appContext.userProfile.organisationShortName;
  }

  isReadOnly() {
    if (this.appContext.isAdmin()) return false;
    return this.voyage?.declarant?.shortName !== this.appContext.userProfile.organisationShortName;
  }

  addToThisVisit() {
    this.eventGateway.publish(EventType.AttachHandlingToVisit, {voyage: this.voyage, visit: this.visit});
  }

  visitContainsHandlingAlready() {
    return this.visit?.handlings?.some(h => h.handlingId === this.visit['handlingIdToAttach']);
  }

  private formatHandlings(handlings: RoadHandling[]): any[] {
    var templateHandlings: any[] = [];

    handlings.forEach(h => {
      let handling = h;
      handling.handlingData['formattedType'] = h.handlingType === 'loading' ? 'Pick-up' : 'Drop-off';
      templateHandlings.push(handling);
    });

    return templateHandlings;
  }

  hasOneRemainOnTruckContainer() {
    return this.visit.remainOnTruckContainers?.length == 1;
  }

  hasMultipleRemainOnTruckContainers() {
    return this.visit.remainOnTruckContainers?.length > 1;
  }

  toggleForRemoval(container) {
    container['forRemoval'] = !container['forRemoval'];
  }

  removeRemainOnTruckContainerModal() {
    this.confirmationModalService.openModalWithCallback((remove) => {
      if (remove) {
        this.removeRemainOnTruckContainer();
      }
    }, RemoveRemainOnTruckContainerComponent, {"containerNumber": this.remainOnTruckContainers[0]?.handlingData?.equipmentNumber});
  }

  removeRemainOnTruckContainer() {
    sendCommand('com.portbase.hinterland.api.common.command.CancelAndDetachHandling',
      {
        handlingId: this.remainOnTruckContainers[0].handlingId
      }, () => {
        AppContext.publishSuccessMessage('Remain on truck handling removed successfully.');
      }, () => {
        AppContext.publishErrorMessage(`Remain on truck handling could not be removed.`);
      });
  }

  removeRemainOnTruckContainersModal() {
    this.confirmationModalService.openModalWithCallback((remainOnTruckContainers) => {
      if (!!remainOnTruckContainers) {
        this.remainOnTruckContainers = remainOnTruckContainers;
        this.removeRemainOnTruckContainers();
      }
    }, RemoveRemainOnTruckContainersComponent, {"remainOnTruckContainers": this.remainOnTruckContainers});
  }

  removeRemainOnTruckContainers() {
    let commandArray: Array<CommandRequest> = [];
    for (const handling of this.remainOnTruckContainers.filter(h => h['forRemoval'])) {
      commandArray.push(<CommandRequest>{
        type: 'com.portbase.hinterland.api.common.command.CancelAndDetachHandling',
        payload: <CancelAndDetachHandling>{
          handlingId: handling.handlingId
        }
      });
    }
    sendCommands(commandArray, (results) => this.handleResults(results));
  }

  handleResults(results: Array<CommandResponse>) {
    if (results.length === 1) {
      if (results[0].success) {
        AppContext.publishSuccessMessage(`Remain on truck handling removed successfully.`);
      } else {
        AppContext.publishErrorMessage(`Remain on truck handling could not be removed.`);
      }
    } else if (results.length > 1) {
      const successful = results.filter(result => result.success);
      if (successful.length > 0) {
        AppContext.publishSuccessMessage(`${successful.length} remain on truck  handling(s) were cancelled successfully.`);
      }

      const error = results.filter(result => !result.success);
      if (error.length > 0) {
        AppContext.publishErrorMessage(`${error.length} remain on truck handling(s) could not be cancelled.`);
      }
    }
  }

}
