import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AcknowledgeTerminalUpdate,
  CancelVisit,
  DeclareVisit,
  RailVisitData,
  SaveVisit,
  Terminal,
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {HinterlandUtils} from '../../hinterland-utils';
import {clearValidation, sendCommand, sendQuery, toTitleCase} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {Observable, of} from 'rxjs';
import {
  DayOfWeek,
  GetAvailableTimetableSlots,
  RailEstimatedHandlings,
  RailVisit,
  RailVisitDataTemplate,
  RailVisitTemplate,
  TimetableEntry
} from "@portbase/hinterland-service-typescriptmodels";

// Declarations of terminals who are not yet using visit declarations - when editing this list, also update the list in the backend
enum terminals_NOT_UsingRailVisitDeclarations {
  APMII
}

@Component({
  selector: 'app-edit-rail-visit-operator',
  templateUrl: './edit-rail-visit-operator.component.html',
  styleUrls: ['./edit-rail-visit-operator.component.css']
})
export class EditRailVisitOperatorComponent implements OnInit, OnChanges {
  utils = HinterlandUtils;
  context = AppContext;

  @Input() visit: RailVisit;
  @Input() voyageId: string;
  @Input() voyageCancelled: boolean;
  @Input() voyageFirstDepartureDate: string;
  @Input() voyageDeclarantShortname: string;
  @Input() hasTemplate: boolean = false;

  @Output() etaUpdatedEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() deletedEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  timetableEntries: TimetableEntry[] = [];
  terminals: Terminal[] = [];

  isDisabled: boolean;
  isNew: boolean = false;

  constructor(
    private element: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    HinterlandUtils.getTerminals('rail', false, true, true)
      .subscribe(terminals => this.terminals = terminals);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visit) {
      this.isNew = !this.visit.visitId;
      this.isDisabled = this.visit.arrived;

      if (this.visit.requestedVisitData == null && !!this.visit.visitData) {
        this.visit.requestedVisitData = this.visit.visitData;
      } else if (!this.visit.requestedVisitData) {
        this.visit.requestedVisitData = <RailVisitData>{
          estimatedHandlings: <RailEstimatedHandlings>{}
        };
      }
    }
    if (changes.visit || changes.voyageDeclarantShortname) {
      this.fetchAvailableTimetableSlots();
    }
  }

  saveVisit = () => this.saveOrDeclareVisit("com.portbase.hinterland.api.common.command.SaveVisit");

  declareVisit = () => this.saveOrDeclareVisit("com.portbase.hinterland.api.common.command.DeclareVisit");

  saveOrDeclareVisit = (commandClass: string) => {
    clearValidation(this.element);

    this.getVisitId().subscribe(visitId => {
      sendCommand(commandClass, {
        '@class': 'io.fluxcapacitor.javaclient.common.Message',
        payload: <DeclareVisit | SaveVisit>{
          voyageId: this.voyageId,
          visitId: visitId,
          visitData: this.visit.requestedVisitData,
          terminal: this.visit.terminal
        },
        metadata: {
          'usingRailVisitDeclarations': !Object.values(terminals_NOT_UsingRailVisitDeclarations).includes(this.visit.terminal.shortName)
        }
      }, () => {
        this.deletedEventEmitter.emit(visitId);
        AppContext.registerSuccess('The visit was ' + (commandClass.indexOf("SaveVisit") > -1 ? 'saved' : 'declared') + ' successfully');
      });
    });
  };

  cancelVisit = () => {
    if (this.isNew) {
      //TODO Uniquely identify new visit... now all new visits will be removed.
      this.deletedEventEmitter.emit(this.visit.visitId);
    } else {
      sendCommand('com.portbase.hinterland.api.common.command.CancelVisit',
        <CancelVisit>{
          voyageId: this.voyageId,
          visitId: this.visit.visitId
        }, () => AppContext.registerSuccess('The visit was cancelled successfully'));
    }
  };

  acknowledgeUpdate = () => {
    sendCommand('com.portbase.hinterland.api.common.command.AcknowledgeTerminalUpdate',
      <AcknowledgeTerminalUpdate>{
        voyageId: this.voyageId,
        visitId: this.visit.visitId
      }, () => {
        AppContext.registerSuccess('The update to the visit has been acknowledged successfully');
      });
  };

  getVisitDataTemplate(): RailVisitDataTemplate {
    let railVisitTemplate = <RailVisitTemplate><unknown>this.visit;
    if (!railVisitTemplate.visitData) {
      railVisitTemplate.visitData = <RailVisitDataTemplate><unknown>{
        etaTimeOfWeek: {day: null, time: null},
        etdTimeOfWeek: {day: null, time: null}
      };
    }
    return railVisitTemplate.visitData;
  }

  getVisitId = (): Observable<string> => this.visit.visitId ? of(this.visit.visitId) : HinterlandUtils.getNextVisitId();

  onTerminalChanged = () => {
    this.visit.requestedVisitData.timetableEntry = null;
    this.timetableEntries = [];
    this.fetchAvailableTimetableSlots();
  }

  fetchAvailableTimetableSlots = () => {
    if (!!this.visit.terminal) {
      sendQuery("com.portbase.hinterland.api.refdata.query.GetAvailableTimetableSlots", <GetAvailableTimetableSlots>{
        'firstDepartureDate': this.voyageFirstDepartureDate,
        'terminalShortName': this.visit.terminal.shortName,
        'declarantShortName': this.voyageDeclarantShortname
      }, {caching: false}).subscribe(result => {
        if (result.length > 0) {
          this.timetableEntries = result;
          if (!result.includes(null)) this.timetableEntries.unshift(null);
        }
      });
    }
  }

  onTimetableEntryChanged = () => {
    if (!this.hasTemplate && this.visit.requestedVisitData.timetableEntry) {
      if (!this.visit.requestedVisitData.eta) {
        this.visit.requestedVisitData.eta = HinterlandUtils.nextMoment(this.voyageFirstDepartureDate, this.visit.requestedVisitData.timetableEntry.window.start);
      }
      if (!this.visit.requestedVisitData.etd) {
        this.visit.requestedVisitData.etd = HinterlandUtils.nextMoment(this.voyageFirstDepartureDate, this.visit.requestedVisitData.timetableEntry.window.end);
      }

      if (!this.visit.requestedVisitData.estimatedHandlings) {
        this.visit.requestedVisitData.estimatedHandlings = {
          loadingTotal: this.visit.requestedVisitData.timetableEntry.totalLoading,
          dischargeTotal: this.visit.requestedVisitData.timetableEntry.totalDischarge
        };
      }

      if (!this.visit.requestedVisitData.estimatedHandlings.loadingTotal) {
        this.visit.requestedVisitData.estimatedHandlings.loadingTotal = this.visit.requestedVisitData.timetableEntry.totalLoading;
      }
      if (!this.visit.requestedVisitData.estimatedHandlings.dischargeTotal) {
        this.visit.requestedVisitData.estimatedHandlings.dischargeTotal = this.visit.requestedVisitData.timetableEntry.totalDischarge;
      }
    }

    if (this.hasTemplate && this.visit.requestedVisitData.timetableEntry) {
      this.changeDetector.detectChanges();
    }
  }

  formatTimetableEntry = (timetableEntry: TimetableEntry) => {
    return timetableEntry == null ? 'None' : timetableEntry.code + " (" + this.formatDay(timetableEntry.window.start.day) + ' ' + this.formatTime(timetableEntry.window.start.time) + '-' + (timetableEntry.window.end.day != timetableEntry.window.start.day ? this.formatDay(timetableEntry.window.end.day) + ' ' : '') + this.formatTime(timetableEntry.window.end.time) + ") d:" + timetableEntry.totalDischarge + ', l:' + timetableEntry.totalLoading;
  };

  formatDay = (dayOfWeek: DayOfWeek) => {
    return dayOfWeek == null || dayOfWeek.length < 3 ? dayOfWeek : toTitleCase(dayOfWeek.substring(0, 3));
  }

  formatTime = (time: string) => {
    return time == null || time.lastIndexOf(':') < 4 ? time : time.substring(0, time.lastIndexOf(':'));
  }

  weekDays = (withEmpty: boolean = false) => withEmpty ? [null, ...HinterlandUtils.weekdays] : HinterlandUtils.weekdays;

  toTitleCase = (value) => toTitleCase(value);
}
