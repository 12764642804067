import {Component, Input} from '@angular/core';
import {ContainedGood} from '@portbase/hinterland-service-typescriptmodels';
import {HinterlandUtils} from "../../../hinterland-utils";

@Component({
  selector: 'app-cargo-goods-details',
  templateUrl: './cargo-goods-details.component.html',
  styleUrls: ['./cargo-goods-details.component.css']
})
export class CargoGoodsDetailsComponent {

  @Input() good: ContainedGood;
  refData = HinterlandUtils;

  onOuterPackageChange() {
    if (this.good.packageType && this.good.packageType.bulk) {
      this.good.numberOfPackages = 1;
    }
  }
}
