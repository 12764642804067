import {Component, OnInit} from "@angular/core";
import {AppContext} from "../../app-context";
import {Terminal} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {HinterlandUtils} from "../../hinterland/hinterland-utils";
import {TerminalSettings} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.css']
})
export class TerminalsComponent implements OnInit {

  appContext = AppContext;
  utils = HinterlandUtils;

  bargeTerminals: Terminal[];
  roadTerminals: Terminal[];
  railTerminals: Terminal[];

  ngOnInit(): void {
    HinterlandUtils.getTerminals("barge", true, true, false)
      .subscribe(terminals => this.bargeTerminals = terminals.filter(s => !s.quayName.startsWith("-")));
    HinterlandUtils.getTerminals("road", true, true, false)
      .subscribe(terminals => this.roadTerminals = terminals);
    HinterlandUtils.getTerminals("rail", true, true, false)
      .subscribe(terminals => this.railTerminals = terminals);
  }

  getSettings(settings: TerminalSettings[], modality: string) {
    if (settings) {
      let filteredSettings = settings.filter(s => s.modality === modality);
      return filteredSettings.length > 0 ? filteredSettings : null;
    } else {
      return null;
    }
  }

  getModalitySetting(list: string[], modality: string) {
    if (!list) {
      return null;
    }
    return list.filter(s => s === modality).length > 0;
  }

  getLossenOpRichtlijnAllowed(settings: TerminalSettings[], modality: string) {
    return settings?.some(s => !s.full && !s.loading && s.modality == modality && s.lossenOpRichtlijnAllowed);
  }

}
