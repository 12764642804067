import {Component, Input, OnInit} from '@angular/core';
import {BargeVisit, HandlingStats, RailVisit, Visit} from '@portbase/hinterland-service-typescriptmodels';
import moment from "moment";
import {TimetableEntry} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {mapAcceptStatuses} from "../handlings/handling-status/handling-status.component";

@Component({
  selector: 'app-handling-total',
  templateUrl: './handling-total.component.html',
  styleUrls: ['./handling-total.component.css']
})
export class HandlingTotalComponent implements OnInit {

  @Input() inOperation: boolean;
  @Input() template = false;
  @Input() handlingStats: HandlingStats;
  @Input() visit: Visit | BargeVisit;
  @Input() terminalView = false;

  //Rail
  timeslot: TimetableEntry;
  showSlotTotals: boolean;
  showDischargeWarning = false;
  showLoadingWarning = false;

  //Barge
  pastLoadDischargeListClosing = false;

  ngOnInit(): void {
    this.timeslot = this.visit && this.visit.modality == "rail" ? (this.visit as RailVisit).visitData.timetableEntry : null;
    this.showSlotTotals = this.timeslot && (this.visit.visitDeclarationStatus == "DECLARED");
    this.showDischargeWarning = this.showSlotTotals && this.timeslot.totalDischarge != this.handlingStats.dischargeEstimated;
    this.showLoadingWarning = this.showSlotTotals && this.timeslot.totalLoading != this.handlingStats.loadingEstimated;
    this.pastLoadDischargeListClosing = this.visit && this.visit.modality == "barge" && (this.visit as BargeVisit).loadDischargeListClosing ? moment().isAfter((this.visit as BargeVisit).loadDischargeListClosing) : false;
    this.handlingStats?.errorHandlings.forEach(h => h['acceptStatuses'] = mapAcceptStatuses(h));
  }

}
