import {Component, Input, OnInit} from '@angular/core';
import {AcceptStatusType, TerminalRejectReason} from "@portbase/hinterland-service-typescriptmodels";
import moment from "moment/moment";
import {HandlingStatus} from "../../../../hinterland/hinterland-utils";
import {AcceptStatus, Handling, Visit} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {HandlingStatusDisplayMode} from "../../../../hinterland/types";
import {EventGateway, EventHandler, EventType} from "../../../../common/event-gateway";
import _ from "lodash";
import {InjectorProvider} from "../../../../common/injector-provider";

@Component({
  selector: 'app-handling-status-panel',
  templateUrl: './app-handling-status-panel.html',
  styleUrls: ['./app-handling-status-panel.scss']
})
export class HandlingStatusPanelComponent implements OnInit {

  @Input() visit: Visit;
  @Input() handling: Handling;
  @Input() displayMode: HandlingStatusDisplayMode = 'standard';
  @Input() disableTooltip: boolean;

  loadingStatusTypes: AcceptStatusType[] = ["BLOCKED", "CUSTOMS_DOCUMENT", "AVAILABLE", "COMMERCIAL_RELEASE"];
  dischargeStatusTypes: AcceptStatusType[] = ["BLOCKED", "CUSTOMS_DOCUMENT", "CARGO_OPENING", "BOOKING_APPROVED"];

  green: string = 'status-icon-green';
  red: string = 'status-icon-red';
  blue: string = 'status-icon-blue';
  grey: string = 'status-icon-grey';

  status: HandlingStatus = 'unknown';
  text: string = 'Text';
  color: string = this.green;
  icon: string = 'fa-check-circle';

  rejectReasons: TerminalRejectReason[] = [];
  handledDetails: HandledDetails;
  acceptStatuses: AcceptStatusView[] = [];

  showTooltip: boolean;
  attached: boolean;

  ngOnInit() {
    this.reinitialize();
    InjectorProvider.injector.get(EventGateway).registerLocalHandler(this);
  }

  onVisitUpdated: EventHandler<EventType.VisitUpdated> = (visitId: string) => {
    if (this.visit && this.visit.visitId === visitId) {
      this.reinitializeDebounced();
    }
  }

  onHandlingUpdated: EventHandler<EventType.HandlingUpdated> = (handlingId: string) => {
    if (this.handling.handlingId === handlingId) {
      this.reinitializeDebounced();
    }
  }

  reinitializeDebounced: Function = _.debounce(() => {
    this.reinitialize();
  }, 500);

  reinitialize() {
    this.attached = !!this.visit;
    this.determineStatus();
    this.buildStatusView();

    if (this.status === 'acceptedWithWarnings' || this.status === 'accepted' || this.status === 'rejected') {
      this.initAcceptStatuses();
      this.showTooltip = !this.disableTooltip;
    }
    if (this.status === 'handled') {
      this.showTooltip = !this.disableTooltip;
    }
  }

  determineStatus() {
    if ('handlingResult' in this.handling && !!this.handling['handlingResult']) {
      this.status = 'handled';
    } else if (this.handling.cancelled) {
      this.status = 'cancelled';
    } else if (this.handling.declarationStatus === 'REJECTED') {
      this.status = 'rejected';
    } else if (!!this.visit && this.visit.arrived) {
      this.status = 'inOperation';
    } else if (this.handling.declarationStatus === 'ACCEPTED') {
      this.status = this.handling.handlingDeclaration.acceptStatuses.every(s => !!s.ok) ? 'accepted' : 'acceptedWithWarnings';
    } else if (this.handling.declarationStatus === 'DECLARED') {
      if (!!this.visit && this.visit.terminal?.shortName.startsWith("NONPCS")) {
        this.status = 'unknown';
      } else {
        this.status = moment(this.handling.handlingDeclaration.timestamp)
          .isBefore(moment().subtract(1, 'hours')) ? 'stuck' : 'declared';
      }
    }
  }

  buildStatusView() {
    switch (this.status) {
      case 'stuck':
        this.text = 'No response yet';
        this.icon = 'fa-question-circle';
        this.color = this.blue;
        break;
      case 'declared':
        this.text = 'Sent';
        this.icon = 'fa-info-circle';
        this.color = this.blue;
        break;
      case 'rejected':
        this.text = 'Rejected';
        this.icon = 'fa-times-circle';
        this.color = this.red;
        this.rejectReasons = this.handling.handlingDeclaration.rejectReasons;
        break;
      case 'acceptedWithWarnings':
        this.text = 'Accepted with warnings';
        this.icon = 'fa-exclamation-circle';
        this.color = this.red;
        break;
      case 'accepted':
        this.text = this.attached ? 'Accepted' : 'Ready for pre-notification';
        this.icon = 'fa-check-circle';
        this.color = this.green;
        break;
      case 'inOperation':
        this.text = 'In operation';
        this.icon = 'fa-location-circle';
        this.color = this.blue;
        break;
      case 'handled':
        this.text = 'Completed';
        this.icon = 'fa-check-circle';
        this.color = this.blue;

        this.handledDetails = new HandledDetails();
        let actualTimeOfHandling: string = this.handling['handlingResult']['actualTimeOfHandling'];

        if (!!actualTimeOfHandling) {
          this.handledDetails.type = this.handling.handlingType === 'loading' ? 'Loaded' : 'Discharged';
          this.handledDetails.time = this.formatDateTime(this.handling['handlingResult']['actualTimeOfHandling']);
        }

        let truckLicenseId = this.handling['handlingResult']['truckLicenseId'];
        if (!!truckLicenseId) {
          this.handledDetails.licenseId = truckLicenseId;
        }

        break;
      case 'cancelled':
        this.text = 'Cancelled';
        this.icon = 'fa-times-circle';
        this.color = this.grey;
        break;
      case 'unknown':
        this.text = 'Unknown';
        this.icon = 'fa-question-circle';
        this.color = this.blue;
        break;
      default:
        this.text = 'Unknown';
        this.icon = 'fa-question-circle';
        this.color = this.blue;
        break;
    }
  }

  initAcceptStatuses() {
    this.acceptStatuses = [];
    let declarationAcceptStatuses = this.handling.handlingDeclaration.acceptStatuses;
    this.buildDefaultAcceptStatuses();
    this.addActualStatus(declarationAcceptStatuses);
  }

  private addActualStatus(declarationAcceptStatuses: AcceptStatus[]) {
    declarationAcceptStatuses.forEach(s => {
        let acceptStatus = this.acceptStatuses.find(a => {
          return a.type == s.type || a.type == 'OTHER';
        });
        if (acceptStatus != null) {
          acceptStatus.color = s.ok && acceptStatus.color != this.red ? this.green : this.red;
          acceptStatus.icon = s.ok && acceptStatus.icon != 'fa-times-circle' ? 'fa-check-circle' : 'fa-times-circle';
          if (s.remark != null) {
            acceptStatus.remarks.push(s.code + ": " + s.remark.charAt(0).toUpperCase() + s.remark.slice(1).toLowerCase());
          }
        } else if (!s.ok) {
          acceptStatus = new AcceptStatusView();
          acceptStatus.type = 'OTHER';
          acceptStatus.text = 'Other';
          acceptStatus.title = 'Oth';
          acceptStatus.color = this.red;
          acceptStatus.icon = 'fa-times-circle';
          if (s.remark != null) {
            acceptStatus.remarks = [s.code + ": " + s.remark.charAt(0).toUpperCase() + s.remark.slice(1).toLowerCase()];
          }
          this.acceptStatuses.push(acceptStatus);
        }
      }
    )
  }

  private buildDefaultAcceptStatuses() {
    let defaultTypes = this.handling.handlingData.type == 'loading' ? this.loadingStatusTypes : this.dischargeStatusTypes;

    defaultTypes.forEach(t => {
      let acceptStatus = new AcceptStatusView();
      acceptStatus.type = t;
      switch (t) {
        case 'AVAILABLE':
          acceptStatus.text = 'Container present';
          acceptStatus.title = 'Pre';
          break;
        case 'BOOKING_APPROVED':
          acceptStatus.text = 'Acceptance reference';
          acceptStatus.title = 'Ref';
          break;
        case 'COMMERCIAL_RELEASE':
          acceptStatus.text = 'Commercial release';
          acceptStatus.title = 'Rel';
          break;
        case 'CUSTOMS_DOCUMENT':
          acceptStatus.text = 'Documents available';
          acceptStatus.title = 'Doc';
          break;
        case 'BLOCKED':
          acceptStatus.text = 'Blocked';
          acceptStatus.title = 'Blo';
          break;
        case 'CARGO_OPENING':
          acceptStatus.text = 'Cargo window open';
          acceptStatus.title = 'Ope';
          break;
        default:
          acceptStatus.text = 'Unknown';
          acceptStatus.title = 'Unk';
          break;
      }

      acceptStatus.color = this.green;
      acceptStatus.icon = 'fa-check-circle';
      acceptStatus.remarks = [];

      this.acceptStatuses.push(acceptStatus);
    })
  }

  formatDateTime(dateTime: string) {
    const m = moment(dateTime);
    return m.format(`${m.year() === moment().year() ? 'DD MMM' : 'DD MMM [’]YY'}, HH:mm`);
  }

}

export class AcceptStatusView {
  type: string;
  title: string;
  text: string;
  color: string;
  icon: string;
  remarks: string[];
}

export class HandledDetails {
  type: string;
  time: string;
  licenseId: string;
}
