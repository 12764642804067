import {Component, OnInit} from '@angular/core';
import {
  BinnenhavengeldApplicationConfig,
  UpdateBinnenhavengeldApplicationConfig
} from '@portbase/hinterland-service-typescriptmodels';
import {sendCommand, sendQuery} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {EditModalService} from "../../../components/modals/edit-modal/edit-modal.service";

@Component({
  selector: 'app-bhg-edit-application-config',
  templateUrl: './bhg-edit-application-config.component.html',
  styleUrls: ['../../bhg.scss', './bhg-edit-application-config.component.css']
})
export class BhgEditApplicationConfigComponent implements OnInit {
  data = {};
  config : BinnenhavengeldApplicationConfig;

  errors: string[];

  constructor(
    private readonly editModalService: EditModalService,
  ) {
  }

  ngOnInit(): void {
    sendQuery("com.portbase.hinterland.api.binnenhavengeld.GetBinnenhavengeldApplicationConfig",
      {}, {caching: false}).subscribe(config => this.config = config);
    this.errors = [];
  }

  update() {
    this.errors = [];
    sendCommand("com.portbase.hinterland.api.binnenhavengeld.UpdateBinnenhavengeldApplicationConfig",
      <UpdateBinnenhavengeldApplicationConfig>{ config : this.config},
      () => {
        AppContext.publishSuccessMessage("The configuration was updated successfully");
        this.editModalService.closeModal();
      },
      (error) => {
        this.errors.push(error?.error?.error);
      });
  }
}
