<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Add ‘Remain on Truck’ container</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body">
    <p>Enter a container below that remains on truck at the terminal.</p>
    <input [(ngModel)]="containerNumber" class="form-control" id="rotContainerNumber" maxlength="11"
           placeholder="Enter container number" type="text">
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
    <button (click)="add()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Save</button>
  </div>
</div>
