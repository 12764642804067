import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
  DayOfWeek,
  DeclarantOrganisation,
  FindDeclarantOrganisations,
  GetCurrentTimetableSlots, GetTimetableSlot,
  TerminalOrganisation,
  TimetableEntry
} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {AppContext} from "../../app-context";
import {checkValidity, cloneObject, lodash, sendCommand, sendQuery, toTitleCase, uuid} from "../../common/utils";
import {of} from "rxjs";
import {ComparatorChain} from "../../common/comparator-chain";
import moment, {now} from "moment";
import {HinterlandUtils, TerminalModel} from "../../hinterland/hinterland-utils";
import {map} from "rxjs/operators";
import {RailTimetableUpload, TimetableUploadData} from "./rail-timetable.upload";

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.css']
})
export class TimetableComponent implements OnInit {

  private static dayOrder: DayOfWeek[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
  private static dayOrderReversed: DayOfWeek[] = ["SUNDAY", "SATURDAY", "FRIDAY", "THURSDAY", "WEDNESDAY", "TUESDAY", "MONDAY"];
  private static dayComparator = (f1: TimetableEntry, f2: TimetableEntry) => TimetableComponent.dayOrderReversed.indexOf(f2.window.start.day) - TimetableComponent.dayOrderReversed.indexOf(f1.window.start.day);
  private static dayComparatorReversed = (f1: TimetableEntry, f2: TimetableEntry) => TimetableComponent.dayOrder.indexOf(f1.window.start.day) - TimetableComponent.dayOrder.indexOf(f2.window.start.day);

  appContext = AppContext;
  entry: TimetableEntry;
  slot: TimetableEntry[];
  searchResult: TimetableEntry;
  timetableEntries: TimetableEntry[];
  timetableUploadData: TimetableUploadData = {
    terminal: null,
    startDate: moment().format("YYYY-MM-DD"),
    file: null,
    fileName: ''
  };
  uploading = false;
  date = moment().format("YYYY-MM-DD");
  terminationDate;

  @Input() sortBy: 'code' | 'codeReversed' | 'terminal' | 'terminalReversed' = 'terminal';

  @Input() set setTimetableEntries(timeTableEntries: TimetableEntry[]) {
    this.sortTimeTableEntries(timeTableEntries);
  }

  comparators = {
    'code': ["code", "startDate"],
    'codeReversed': ["-code", "-startDate"],
    'terminal': ["terminal.shortName", "declarant.fullName", "code", "startDate"],
    'terminalReversed': ["-terminal.shortName", "-declarant.fullName", "-code", "-startDate"],
    'declarant': ["declarant.fullName"],
    'declarantReversed': ["-declarant.fullName"],
    'discharge': ["totalDischarge"],
    'dischargeReversed': ["-totalDischarge"],
    'loading': ["totalLoading"],
    'loadingReversed': ["-totalLoading"],
    'startDate': ["startDate"],
    'startDateReversed': ["-startDate"],
    'window': [TimetableComponent.dayComparator],
    'windowReversed': [TimetableComponent.dayComparatorReversed]
  };

  sortDirection = {
    'code': 'codeReversed',
    'terminal': 'terminalReversed',
    'declarant': 'declarantReversed',
    'discharge': 'dischargeReversed',
    'loading': 'loadingReversed',
    'startDate': 'startDateReversed',
    'window': 'windowReversed'
  }

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    this.getCurrentTimetableSlots();
    if (!this.appContext.isAdmin()) {
      this.findTerminals(this.appContext.userProfile.organisationShortName)
        .subscribe((terminals: TerminalOrganisation[]) =>
          this.timetableUploadData.terminal = terminals.filter(terminal => terminal.shortName == this.appContext.userProfile.organisationShortName).pop());
    }
  }

  getCurrentTimetableSlots = () => {
    sendQuery('com.portbase.hinterland.api.refdata.query.GetCurrentTimetableSlots', <GetCurrentTimetableSlots>{date: this.date}, {caching: false})
      .subscribe((timeTableEntries: TimetableEntry[]) => {
        this.sortTimeTableEntries(timeTableEntries);
      });
  }

  private getComparator() {
    let comparatorChain = new ComparatorChain();
    this.comparators[this.sortBy].forEach(p => comparatorChain.addProperty(p));
    return comparatorChain.compare;
  }

  sortTimeTableEntries(timeTableEntries: TimetableEntry[]) {
    (this.timetableEntries = timeTableEntries).sort(this.getComparator());
  }

  getSortDirection = (nextSort: string) => {
    return this.sortBy === nextSort ? this.sortDirection[nextSort] : nextSort;
  }

  searchResultFormatter = (result: any) => {
    const typedResult = result as TimetableEntry;
    return typedResult.code + " - "
      + (AppContext.isAdmin() ? typedResult.terminal.shortName + ', ' : '')
      + typedResult.declarant.fullName
      + ' (' + toTitleCase(typedResult.window.start.day)
      + ' ' + typedResult.window.start.time + ' - '
      + (typedResult.window.end.day != typedResult.window.start.day ? toTitleCase(typedResult.window.end.day) + ' ' : '')
      + typedResult.window.end.time + ")";
  };

  cast = (item: TimetableEntry): TimetableEntry => item;

  search = term => {
    return of(this.timetableEntries.filter(timetableEntry => JSON.stringify(timetableEntry).toLowerCase().indexOf(term.toLowerCase()) > -1));
  };

  newEntry = () => {
    if (this.searchResult) {
      this.entry = cloneObject(this.searchResult);
      this.entry.id = uuid();
    } else {
      this.entry = <TimetableEntry>{window: {start: {}, end: {}}, startDate: moment(now()).format("YYYY-MM-DD")};
    }
    if (this.entry.terminal == null) this.entry.terminal = this.timetableUploadData.terminal;
    this.entry.id = uuid();
    this.searchResult = undefined;
  };

  onEntryChange() {
    this.entry = this.searchResult;
    this.loadTimetableSlot(this.entry);
  }

  cancel = () => {
    this.clear();
  };

  terminate = () => {
    if (checkValidity(this.element)) {
      let newEntry = cloneObject(this.searchResult);
      newEntry.id = uuid();
      newEntry.startDate = this.terminationDate;
      newEntry.terminated = true;
      sendCommand('com.portbase.hinterland.api.refdata.command.AddTimetableEntry', {entity: this.fixEmptyDisplayNameOverrides(newEntry)}, () => {
        AppContext.registerSuccess('Timetable entry terminated on ' + moment(this.terminationDate).format("DD-MM-YYYY") + '.');
        this.clear();
      });
    }
  };

  addEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.AddTimetableEntry', {entity: this.fixEmptyDisplayNameOverrides(this.entry)}, () => {
        AppContext.registerSuccess('Timetable entry was added successfully');
        this.timetableEntries.push(this.entry);
        this.clear();
      });
    }
  };

  updateEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.UpdateTimetableEntry', {entity: this.fixEmptyDisplayNameOverrides(this.entry)}, () => {
        AppContext.registerSuccess('Timetable entry was updated successfully');
        this.clear();
      });
    }
  };

  deleteEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.DeleteTimetableEntry', {entity: this.fixEmptyDisplayNameOverrides(this.entry)}, () => {
        AppContext.registerSuccess('Timetable entry was deleted successfully');
        this.timetableEntries = this.timetableEntries.filter(f => f['id'] != this.entry.id);
        this.clear();
      });
    }
  };

  loadTimetableSlot = (entry: TimetableEntry) => {
    sendQuery('com.portbase.hinterland.api.refdata.query.GetTimetableSlot', <GetTimetableSlot>{
      terminalShortName: entry.terminal.shortName,
      code: entry.code
    }, {caching: false})
      .subscribe((timetableSlot: TimetableEntry[]) => {
        this.slot = timetableSlot;
      });
  }

  fixEmptyDisplayNameOverrides = (timetableEntry: TimetableEntry) => {
    let displayNameOverrides = timetableEntry.terminal.displayNameOverrides
    if (!!displayNameOverrides && Object.keys(displayNameOverrides).toString() == "@class") {
      delete timetableEntry.terminal.displayNameOverrides["@class"];
    }
    timetableEntry.terminal['terminalSettings'] = [];
    return timetableEntry;
  }

  uploadTimetableEntries(event: Event) {
    if (checkValidity(document.getElementById('uploadModal'))) {
      const railTimetableUpload = new RailTimetableUpload();
      railTimetableUpload.upload(this.timetableUploadData, this.getCurrentTimetableSlots);
      this.timetableUploadData.fileName = '';
      this.uploading = false;
    } else {
      event.stopPropagation()
    }
  }

  private clear = () => {
    this.entry = undefined;
    this.slot = undefined;
    this.searchResult = undefined;
  };

  onEntrySelected(entry: TimetableEntry) {
    this.searchResult = entry;
    this.entry = entry;
    this.loadTimetableSlot(entry);
  }

  hasPreviousEntry = () => {
    return this.slot && this.slotIndex(this.entry) > 0;
  }

  hasNextEntry = () => {
    if (!this.slot) return false;
    var index = this.slotIndex(this.entry);
    return index > -1 && index < this.slot.length - 1;
  }

  slotIndex = (entry: TimetableEntry) => {
    if (!this.slot) return -1;
    return this.slot.findIndex(e => e.id == entry.id);
  }

  nextEntry = () => {
    this.entry = this.slot[this.slotIndex(this.entry) + 1];
  }

  previousEntry = () => {
    this.entry = this.slot[this.slotIndex(this.entry) - 1];
  }

  findDeclarants = term => sendQuery("com.portbase.hinterland.api.common.query.FindDeclarantOrganisations", <FindDeclarantOrganisations>{
    term: term,
    modality: 'rail'
  });
  findTerminals = term => sendQuery("com.portbase.hinterland.api.common.query.GetTerminals", {})
    .pipe(map((r: TerminalModel[]) => r.filter(t =>
      !!t.shortName
      && t.shortName.toUpperCase().includes(term.toUpperCase())
      && t.allowedModalities.includes("rail"))))
    .pipe(map((r: TerminalModel[]) => lodash.uniqBy(r, 'shortName')));
  declarantFormatter = (organisation: DeclarantOrganisation) => organisation.fullName;
  terminalFormatter = (terminal: TerminalOrganisation) => terminal.shortName;

  weekDays = () => HinterlandUtils.weekdays;
  toTitleCase = (value) => toTitleCase(value);
}

