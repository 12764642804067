<div *ngIf="displayMode === 'verbose'" class="d-flex section-group mouse-arrow">

  <div *ngIf="this.status != 'acceptedWithWarnings'"
       class="d-flex justify-content-left align-items-start h-200 nav-content single-section col-md">
    <div class="grid-container">
      <div class="grid-item">
        <div class="row g-0">
          <div class="col-auto accept-status-icon fa {{color}} {{icon}}"></div>
          <div class="col status-text ps-0">{{ text }}</div>
        </div>
        <ng-container *ngIf="status === 'accepted' && !this.attached">
          <div class="status-remarks row g-0">
            <div class="col-auto accept-status-icon">&nbsp;</div>
            <div class="col">With a 'status request', you can request the status of a container (handling) from the
              terminal for free. To pick-up or drop-off this container at the terminal, attach this handling to an
              existing visit or create a pre-notification for a new visit.
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="status === 'rejected'">
          <div *ngFor="let reason of rejectReasons; let index = index" class="status-remarks row g-0">
            <div class="col-auto accept-status-icon">&nbsp;</div>
            <div class="col accept-status-remark">{{ reason.parsedReason }}</div>
          </div>
          <div *ngIf="rejectReasons.length === 0" class="status-remarks row g-0">
            <div class="col-auto accept-status-icon">&nbsp;</div>
            <div class="col">No terminal remarks</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="this.status == 'acceptedWithWarnings'"
       class="d-flex justify-content-left align-items-start h-200 nav-content left-section col-md">
    <div class="grid-container">
      <ng-container *ngFor="let acceptStatus of acceptStatuses; let index = index">
        <ng-container
          *ngIf="acceptStatus.type === 'BLOCKED' || acceptStatus.type === 'CUSTOMS_DOCUMENT' || acceptStatus.type === 'OTHER'">
          <div class="grid-item">
            <div class="row g-0">
              <div class="col-auto accept-status-icon fa {{acceptStatus.color}} {{acceptStatus.icon}}"></div>
              <div class="col status-text accept-status-category ps-0">{{ acceptStatus.text }}</div>
            </div>
            <div *ngFor="let remark of acceptStatus.remarks; let idx = idx" class="status-remarks row g-0">
              <div class="col-auto accept-status-icon">&nbsp;</div>
              <div class="col accept-status-remark">{{ remark }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div *ngIf="this.status == 'acceptedWithWarnings'"
       class="d-flex justify-content-left align-items-start h-200 nav-content right-section col-md">
    <div class="grid-container">
      <ng-container *ngFor="let acceptStatus of acceptStatuses; let index = index">
        <ng-container
          *ngIf="acceptStatus.type != 'BLOCKED' && acceptStatus.type != 'CUSTOMS_DOCUMENT' && acceptStatus.type != 'OTHER'">
          <div class="grid-item">
            <div class="row g-0">
              <div class="col-auto accept-status-icon fa {{acceptStatus.color}} {{acceptStatus.icon}}"></div>
              <div class="col status-text accept-status-category ps-0">{{ acceptStatus.text }}</div>
            </div>
            <div *ngFor="let remark of acceptStatus.remarks; let idx = idx" class="status-remarks row g-0">
              <div class="col-auto accept-status-icon">&nbsp;</div>
              <div class="col accept-status-remark">{{ remark }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="displayMode === 'mobile'" class="section-group">
  <div class="d-flex justify-content-left align-items-start h-200 nav-content">
    <div *ngIf="this.status == 'acceptedWithWarnings' else singleStatus">
      <ng-container *ngFor="let acceptStatus of acceptStatuses; let index = index">
        <ng-container>
          <div class="grid-item">
            <div class="row g-0">
              <div class="col-auto accept-status-icon fa {{acceptStatus.color}} {{acceptStatus.icon}}"></div>
              <div class="col status-text accept-status-category ps-0">{{ acceptStatus.text }}</div>
            </div>
            <div *ngFor="let remark of acceptStatus.remarks; let idx = idx" class="status-remarks row g-0">
              <div class="col-auto accept-status-icon">&nbsp;</div>
              <div class="col accept-status-remark">{{ remark }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #singleStatus>
      <div class="grid-container">
        <div class="grid-item">
          <div class="row g-0">
            <div class="col-auto accept-status-icon fa {{color}} {{icon}}"></div>
            <div class="col status-text ps-0">{{ text }}</div>
          </div>
          <ng-container *ngIf="status === 'accepted' && !this.attached">
            <div class="status-remarks row g-0">
              <div class="col-auto accept-status-icon">&nbsp;</div>
              <div class="col">With a 'status request', you can request the status of a container (handling) from the
                terminal for free. To pick-up or drop-off this container at the terminal, attach this handling to an
                existing visit or create a pre-notification for a new visit.
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="status === 'rejected'">
            <div *ngFor="let reason of rejectReasons; let index = index" class="status-remarks row g-0">
              <div class="col-auto accept-status-icon">&nbsp;</div>
              <div class="col accept-status-remark">{{ reason.parsedReason }}</div>
            </div>
            <div *ngIf="rejectReasons.length === 0" class="status-remarks row g-0">
              <div class="col-auto accept-status-icon">&nbsp;</div>
              <div class="col">No terminal remarks</div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="displayMode === 'standard'" [ngClass]="{'mouse-pointer': showTooltip, 'mouse-arrow': !showTooltip}"
     class="d-flex justify-content-left align-items-center h-100">
  <div *ngIf="this.status !== 'acceptedWithWarnings'" [disableTooltip]="!showTooltip" [ngbTooltip]="tooltip"
       class="single-status" placement="bottom">
    <span class="status-icon fa {{color}} {{icon}}"></span>
    <span class="status-text">{{text}}</span>
  </div>

  <div *ngIf="this.status === 'acceptedWithWarnings' && displayMode === 'standard'" [disableTooltip]="!showTooltip"
       [ngbTooltip]="tooltip" class="d-flex" placement="bottom">
    <div *ngFor="let acceptStatus of acceptStatuses; let index = index" class="status-item text-center">
      <div class="status-icon fa {{acceptStatus.color}} {{acceptStatus.icon}}"></div>
      <div class="sub-text">{{ acceptStatus.title }}</div>
    </div>
  </div>
</div>

<div *ngIf="displayMode === 'summary'" [ngClass]="{'mouse-pointer': showTooltip, 'mouse-arrow': !showTooltip}"
     class="d-flex justify-content-end align-items-center h-100">
  <div class="single-status">
    <span [disableTooltip]="!showTooltip" [ngbTooltip]="tooltip" class="status-icon fa {{color}} {{icon}}"
          placement="bottom-right"></span>
  </div>
</div>

<ng-template #tooltip>
  <div class="accept-status-list">
    <div *ngIf="status === 'accepted' && !this.attached" class="status-remarks">
      <div class="status-remarks accept-status-remark">With a 'status request', you can request the status of a
        container (handling) from the terminal
        for free. To pick-up or drop-off this container at the terminal, attach this handling to an existing visit or
        create a pre-notification for a new visit.
      </div>
    </div>
    <div *ngIf="status === 'rejected'" class="status-remarks">
      <div class="tooltip-header pb-1">Reject remarks:</div>

      <ng-container *ngFor="let reason of rejectReasons; let index = index">
        <div class="status-remarks accept-status-remark">{{ reason.parsedReason }}</div>
      </ng-container>
      <div *ngIf="rejectReasons.length === 0" class="status-tooltip-reject-reason">
        <span class="status-remarks">No terminal remarks</span>
      </div>

      <div *ngIf="!!acceptStatuses" class="tooltip-header rejection-status pb-1">Status at rejection:</div>
    </div>

    <div *ngIf="status === 'handled'" class="status-remarks">
      <div class="status-remarks">{{ handledDetails.type }} on: {{ handledDetails.time }}</div>
      <div *ngIf="handledDetails.licenseId" class="status-remarks">
        Vehicle license nr: {{ handledDetails.licenseId }}
      </div>
    </div>

    <div *ngFor="let acceptStatus of acceptStatuses; let index = index" class="accept-status-item">
      <div class="row g-0">
        <div class="col-auto accept-status-icon">
          <span class="fa-stack" style="vertical-align: top;">
            <span class="status-tooltip-icon fa fa-circle text-white fa-stack-1x"></span>
            <span class="status-tooltip-icon fa {{acceptStatus.icon}} {{acceptStatus.color}} fa-stack-2x"></span>
          </span>
        </div>
        <div class="col accept-status-category">{{ acceptStatus.text }}</div>
      </div>
      <div *ngFor="let remark of acceptStatus.remarks; let idx = idx" class="row g-0 status-remarks">
        <div class="col-auto accept-status-icon">&nbsp;</div>
        <div class="col accept-status-remark">{{ remark }}</div>
      </div>
    </div>

  </div>
</ng-template>
