import {BargeVisit, BargeVoyage, RailVisit, RailVoyage} from '@portbase/hinterland-service-typescriptmodels';
import moment from 'moment';
import {downloadFile, lodash, toCsv} from '../../common/utils';
import {TerminalVisitView} from './terminal-overview.component';
import {Voyage} from "@portbase/hinterland-service-typescriptmodels/hinterland";

export function downloadVisits(terminalViews: TerminalVisitView[]) {
  const csv = toCsv(terminalViews.map(view => convertVisit(view.visit as BargeVisit | RailVisit, view.voyage)));
  downloadFile('portinfolink-report.csv', 'data:text/csv;charset=UTF-8,' + '\uFEFF' + encodeURIComponent(csv));
}

function convertVisit(visit: BargeVisit | RailVisit, voyage: Voyage) {
  function formatTimestamp(timestamp: string): string {
    if (timestamp) {
      return moment(timestamp).local().format('DD-MM-YYYY HH:mm');
    }
  }

  function removeLineBreaks(text: string): string {
    if (text) {
      return text.replace(/(\r\n|\n|\r)/gm, "");
    }
  }

  var convertedVisit = {};

  if (visit.modality == "barge") {
    let bargeVoyage = <BargeVoyage>voyage;
    convertedVisit = {
      ...convertedVisit,
      'Schip': bargeVoyage.voyageData.barge.name,
      'Integrale planning aangevraagd': bargeVoyage.integralPlanningRequested ? "Ja" : "Nee",
      'Barge operator': bargeVoyage.declarant.fullName,
      'Europa nummer': bargeVoyage.voyageData.barge.europeId,
    }
  }

  if(visit.modality == "rail") {
    let railVoyage = <RailVoyage>voyage;
    convertedVisit = {
      ...convertedVisit,
      'Train': railVoyage.voyageData.train.trainNumber,
      'Track number': railVoyage.voyageData.dischargeVoyageNumber ? railVoyage.voyageData.dischargeVoyageNumber : railVoyage.voyageData.loadingVoyageNumber,
      'Rail operator': railVoyage.declarant.fullName,
    }
  }

  convertedVisit = {
    ...convertedVisit,
    'Reis-id':
    voyage.voyageId
  }

  if (visit.modality == "barge") {
    let bargeVoyage = <BargeVoyage>voyage;
    convertedVisit = {
      ...convertedVisit,
      'Reisnr lossen': bargeVoyage.voyageData.inHouseDischargeNumber,
      'Reisnr laden': bargeVoyage.voyageData.inHouseLoadingNumber
    }
  }

  convertedVisit = {
    ...convertedVisit,
    'Terminal / depot': visit.terminal.shortName,
    'ETA': formatTimestamp(visit.requestedVisitData.eta),
    'ETD': formatTimestamp(visit.requestedVisitData.etd),
    'PTA': formatTimestamp(visit.plannedVisitData && visit.plannedVisitData.eta),
    'PTD': formatTimestamp(visit.plannedVisitData && visit.plannedVisitData.etd)
  }

  if (visit.modality == "barge") {
    let bargeVisit = <BargeVisit>visit;
    convertedVisit = {
      ...convertedVisit,
      'Integrale PTA': formatTimestamp(bargeVisit.integralPlanningStatus && bargeVisit.integralPlanningStatus.pta),
      'Integrale PTD':  formatTimestamp(bargeVisit.integralPlanningStatus && bargeVisit.integralPlanningStatus.ptd)
    }
  }

  convertedVisit = {
    ...convertedVisit,
    'Call-id': visit.visitId
  }

  if (visit.modality == "barge") {
    let bargeVisit = <BargeVisit>visit;
    convertedVisit = {
      ...convertedVisit,
    'Datum gemeld': formatTimestamp(visit.visitDeclaration.timestamp)
    }
  }

  convertedVisit = {
      ...convertedVisit,
    'Kade': visit.terminal.quayName,
    'Vlg': '',
    'Zeeschip lossen 1': '',
    'Zeeschip No lossen 1': '',
    'Zeeschip lossen 2': '',
    'Zeeschip No lossen 2': '',
    'Zeeschip lossen 3': '',
    'Zeeschip No lossen 3': '',
    'Zeeschip lossen 4': '',
    'Zeeschip No lossen 4': '',
    'Zeeschip lossen 5': '',
    'Zeeschip No lossen 5': ''
  }

  if (visit.modality == "barge") {
    let bargeVisit = <BargeVisit>visit;
    convertedVisit = {
      ...convertedVisit,
      'Lossen vol': lodash.subtract(lodash.subtract(bargeVisit.visitData.estimatedHandlings.dischargeTotal || 0,
        bargeVisit.visitData.estimatedHandlings.dischargeOutOfGauge || 0), bargeVisit.visitData.estimatedHandlings.dischargeEmpty || 0),
      'Lossen leeg': (bargeVisit.visitData.estimatedHandlings.dischargeEmpty || 0),
      'Lossen offsize': (bargeVisit.visitData.estimatedHandlings.dischargeOutOfGauge || 0),
      'Lossen totaal': (bargeVisit.visitData.estimatedHandlings.dischargeTotal || 0)
    }
  }

  if (visit.modality == "rail") {
    let railVisit = <RailVisit>visit;
    convertedVisit = {
      ...convertedVisit,
      'Lossen vol': lodash.subtract(railVisit.visitData.estimatedHandlings.dischargeTotal || 0,
        railVisit.visitData.estimatedHandlings.dischargeEmpty || 0),
      'Lossen leeg': (railVisit.visitData.estimatedHandlings.dischargeEmpty || 0),
      'Lossen totaal': (railVisit.visitData.estimatedHandlings.dischargeTotal || 0)
    }
  }

  convertedVisit = {
    ...convertedVisit,
    'Zeeschip laden 1': '',
    'Zeeschip No laden 1': '',
    'Zeeschip laden 2': '',
    'Zeeschip No laden 2': '',
    'Zeeschip laden 3': '',
    'Zeeschip No laden 3': '',
    'Zeeschip laden 4': '',
    'Zeeschip No laden 4': '',
    'Zeeschip laden 5': '',
    'Zeeschip No laden 5': ''
  }

  if (visit.modality == "barge") {
    let bargeVisit = <BargeVisit>visit;
    convertedVisit = {
      ...convertedVisit,
      'Laden vol': lodash.subtract(lodash.subtract(bargeVisit.visitData.estimatedHandlings.loadingTotal || 0,
          bargeVisit.visitData.estimatedHandlings.loadingOutOfGauge || 0), bargeVisit.visitData.estimatedHandlings.loadingEmpty || 0),
      'Laden leeg': (bargeVisit.visitData.estimatedHandlings.loadingEmpty || 0),
      'Laden offsize': (bargeVisit.visitData.estimatedHandlings.loadingOutOfGauge || 0),
      'Laden totaal': (bargeVisit.visitData.estimatedHandlings.loadingTotal || 0),
      'Shift': (bargeVisit.visitData.estimatedHandlings.shifters || 0),
      'Opmerkingen': removeLineBreaks(bargeVisit.visitData.operatorRemarks),
      'Fixed Window':  bargeVisit.visitData.fixedWindow ? bargeVisit.visitData.fixedWindow.code : ''
    }
  }

  if (visit.modality == "rail") {
    let railVisit = <RailVisit>visit;
    convertedVisit = {
      ...convertedVisit,
      'Laden vol': lodash.subtract(railVisit.visitData.estimatedHandlings.loadingTotal || 0,
        railVisit.visitData.estimatedHandlings.loadingEmpty || 0),
      'Laden leeg': (railVisit.visitData.estimatedHandlings.loadingEmpty || 0),
      'Laden totaal': (railVisit.visitData.estimatedHandlings.loadingTotal || 0),
    }
  }

  return convertedVisit;
}


