<div class="row">
   <div class="col-md-6">
      <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="barge['id']" disabled required></app-form-group>
   </div>
</div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="barge.name" required></app-form-group>
    <app-form-group><label>Europe Id</label><input class="form-control" [(ngModel)]="barge.europeId" maxlength="9" pattern="([0-9]{0,9})" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Flag code</label><input class="form-control" [(ngModel)]="barge.flagCode" maxlength="3" required></app-form-group>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <app-form-group><label title="Maritime Mobile Service Identity">MMSI number <span class="badge bg-secondary">Integral planning</span></label><input class="form-control" appDecimalNumber [maxDecimals]="0" maxlength="9" [(ngModel)]="barge.mmsi"></app-form-group>
    <app-form-group><label>Teu capacity <span class="badge bg-secondary">Integral planning</span></label><input class="form-control" [(ngModel)]="barge.teuCapacity" appDecimalNumber [maxDecimals]="0" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Length (m) <span class="badge bg-secondary">Integral planning</span></label><input class="form-control" [(ngModel)]="barge.length" appDecimalNumber [maxDecimals]="2" required></app-form-group>
    <app-form-group><label>Width (m) <span class="badge bg-secondary">Integral planning</span></label><input class="form-control" [(ngModel)]="barge.width" appDecimalNumber [maxDecimals]="2" required></app-form-group>
    <app-form-group><label>Speed empty (km/h)<span class="badge bg-secondary">Integral planning</span></label><input class="form-control" [(ngModel)]="barge.downStreamSpeed" appDecimalNumber [maxDecimals]="2"></app-form-group>
    <app-form-group><label>Speed full (km/h)<span class="badge bg-secondary">Integral planning</span></label><input class="form-control" [(ngModel)]="barge.upstreamSpeed" appDecimalNumber [maxDecimals]="2"></app-form-group>
  </div>
</div>
