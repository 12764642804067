<div class="mx-auto mb-5 mt-4" style="max-width: 1150px">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Update</a>
      <ng-template ngbNavContent><app-refdata-update></app-refdata-update></ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="appContext.isAdmin()">
      <a ngbNavLink>Import</a>
      <ng-template ngbNavContent><app-refdata-import class="my-2"></app-refdata-import></ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>Export</a>
      <ng-template ngbNavContent><app-refdata-export class="my-2"></app-refdata-export></ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
