{
  "default": [
    {
      "shortName": "APMRTM",
      "ean": "8713755000394",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "0HPDE",
      "quayName": "HPD2",
      "smdgCode": "HPD2",
      "roadStatusRequestAllowed": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "APMII",
      "ean": "8713755272258",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "0APMA",
      "quayName": "APM2",
      "smdgCode": "NLMVII",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 35,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "visitCloseDelayInMinutes": 60,
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "APMII",
      "ean": "8713755272258",
      "allowedModalities": [
        "road"
      ],
      "prenotificationRestrictedToDeclarants": {
        "road": [
          "PORTINFOLINK",
          "APMII",
          "APMIITEST",
          "STARCS"
        ]
      },
      "bicsCode": "EHDII",
      "quayName": "APM2 EHD",
      "smdgCode": "EHDII",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 35,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ],
      "visitCloseDelayInMinutes": 60,
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "BROEKMANDISTRI",
      "ean": "8713755502218",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "0GEVB",
      "quayName": "CLdN Distriport",
      "smdgCode": "BDP",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": false,
      "maxSealNumberLength": 15,
      "roadStatusRequestAllowed": false,
      "bargeStatusRequestForbidden": true,
      "acceptAfterRejectForbidden": false,
      "updateAllowed": true,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ],
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "CTTR",
      "ean": "8713755283179",
      "bicsCode": "00PCT",
      "smdgCode": "CTTR",
      "quayName": "CTT Rotterdam",
      "nextlogicParticipant": false,
      "tarRequired": true,
      "nonPcs": true,
      "apiOnly": true,
      "updateAllowed": true,
      "roadStatusRequestAllowed": true,
      "acceptAfterRejectForbidden": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": true,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "reeferAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "reeferAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "road",
          "reeferAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "reeferAllowed": true
        }
      ]
    },
    {
      "shortName": "CTVSTEINWEG",
      "ean": "8713755502287",
      "bicsCode": "CTVRW",
      "smdgCode": "CTVA",
      "quayName": "CTVrede Amsterdam",
      "tarRequired": true,
      "nonPcs": false,
      "apiOnly": true,
      "updateAllowed": true,
      "prenotificationRestrictedToDeclarants": {
        "road": [
          "PORTINFOLINK",
          "CTVSTEINWEG"
        ]
      },
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Amsterdam",
        "locationUnCode": "NLAMS"
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        }
      ]
    },
    {
      "shortName": "CTVSTEINWEG",
      "ean": "8713755502287",
      "bicsCode": "CTVRE",
      "smdgCode": "CTVH",
      "quayName": "CTVrede Hoogtij",
      "tarRequired": true,
      "nonPcs": false,
      "apiOnly": true,
      "updateAllowed": true,
      "prenotificationRestrictedToDeclarants": {
        "road": [
          "PORTINFOLINK",
          "CTVSTEINWEG"
        ]
      },
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Westzaan",
        "locationUnCode": "NLWTZ"
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        }
      ]
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "barge"
      ],
      "bicsCode": "00DDN",
      "quayName": "DDN",
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "roadStatusRequestAllowed": true,
      "handlingUpdateForbidden": [
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "loadDischargeListSettings": {
        "handlingUpdateBypassAllowed": true,
        "autoRejectEnabled": true
      },
      "minimumCallSize": 10,
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true,
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "00DDE",
      "quayName": "DDE",
      "displayNameOverrides": {
        "road": "ECTDELTA"
      },
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "handlingUpdateForbidden": [
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "loadDischargeListSettings": {
        "handlingUpdateBypassAllowed": true,
        "autoRejectEnabled": true
      },
      "minimumCallSize": 15,
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "cutoffPeriod": "PT2H1M",
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road"
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail"
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true,
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false,
      "remainOnTruckAllowed": true
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "barge"
      ],
      "bicsCode": "000LT",
      "quayName": "LT",
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "quaySelectByDeclarantForbidden": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "handlingUpdateForbidden": [
        "barge",
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge"
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "barge"
      ],
      "bicsCode": "00DBF",
      "quayName": "DBF",
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "quaySelectByDeclarantForbidden": true,
      "roadStatusRequestAllowed": true,
      "handlingUpdateForbidden": [
        "barge",
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge"
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "EUROMAX",
      "ean": "8713755502201",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "00EMX",
      "quayName": "EUROMAX",
      "smdgCode": "EMX",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "roadStatusRequestAllowed": true,
      "minimumCallSize": 10,
      "updateAllowed": true,
      "handlingUpdateForbidden": [
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "loadDischargeListSettings": {
        "handlingUpdateBypassAllowed": true,
        "autoRejectEnabled": true
      },
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road"
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail"
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true,
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false,
      "remainOnTruckAllowed": true
    },
    {
      "shortName": "INTERFORESTRTM",
      "ean": "8713755234607",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "handlingUpdateForbidden": [
        "barge"
      ],
      "handlingUpdateBypassAllowed": [
        "barge"
      ],
      "bicsCode": "00MAT",
      "quayName": "Matrans",
      "smdgCode": "MATR",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "maxSealNumberLength": 15,
      "roadStatusRequestAllowed": false,
      "bargeStatusRequestForbidden": true,
      "acceptAfterRejectForbidden": false,
      "updateAllowed": true,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ],
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "KCLBV",
      "ean": "8713755948719",
      "bicsCode": "0KRAD",
      "smdgCode": "OQEP",
      "quayName": "DCS | Kramer Group",
      "displayNameOverrides": {
        "road": "DCS | Kramer Group"
      },
      "nextlogicParticipant": false,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      }
    },
    {
      "shortName": "KRAMERHOMEDEPOT",
      "ean": "8713755297305",
      "bicsCode": "0KRAW",
      "smdgCode": "RSN",
      "quayName": "KCD | Kramer Group",
      "nextlogicParticipant": false,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      }
    },
    {
      "shortName": "RBCT",
      "ean": "8713755299484",
      "bicsCode": "00RBC",
      "smdgCode": "CETA",
      "quayName": "RBC",
      "displayNameOverrides": {
        "road": "RBC/Cetem"
      },
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ]
    },
    {
      "shortName": "RBCT",
      "ean": "8713755232023",
      "bicsCode": "CETEM",
      "smdgCode": "CETA",
      "quayName": "Cetem",
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ]
    },
    {
      "shortName": "RCT",
      "ean": "8713755955137",
      "bicsCode": "00RCT",
      "smdgCode": "KRM",
      "quayName": "RCT | Kramer Group",
      "displayNameOverrides": {
        "road": "RCT | Kramer Group"
      },
      "nextlogicParticipant": false,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      }
    },
    {
      "shortName": "RSC",
      "ean": "8713755000158",
      "bicsCode": "00RSC",
      "smdgCode": "RSC",
      "quayName": "RSC",
      "nextlogicParticipant": false,
      "nonPcs": false,
      "apiOnly": true,
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": false,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "RST",
      "ean": "8713755259860",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "RSTNO",
      "quayName": "RST ZONE 10",
      "displayNameOverrides": {
        "road": "RST"
      },
      "smdgCode": "RST",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "bargeStatusRequestForbidden": true,
      "handlingUpdateForbidden": [
        "barge"
      ],
      "handlingUpdateBypassAllowed": [
        "barge"
      ],
      "tarRequired": true,
      "maxSealNumberLength": 20,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ],
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "RST",
      "ean": "8713755259860",
      "bicsCode": "RSTZU",
      "quayName": "RST ZONE 8/9",
      "nextlogicParticipant": false,
      "nonPcs": false,
      "bargeStatusRequestForbidden": true,
      "handlingUpdateForbidden": [
        "barge"
      ],
      "handlingUpdateBypassAllowed": [
        "barge"
      ],
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "maxSealNumberLength": 20,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ]
    },
    {
      "shortName": "RWG",
      "ean": "8713755270896",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "00RWG",
      "quayName": "RWG",
      "smdgCode": "RWG",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "visitCloseDelayInMinutes": 30,
      "minimumCallSize": 15,
      "nextlogicParticipant": true,
      "nonPcs": false,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ]
    },
    {
      "shortName": "UWT",
      "ean": "8713755903541",
      "bicsCode": "00UCT",
      "smdgCode": "UWT",
      "quayName": "UWT Eemhaven",
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 360
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 360,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        }
      ]
    },
    {
      "shortName": "UWT",
      "ean": "8713755903541",
      "bicsCode": "UWTFR",
      "smdgCode": "UWT",
      "quayName": "UWT Frisohaven",
      "displayNameOverrides": {
        "road": "UWT Bunschotenweg"
      },
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "barge",
        "road"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 360
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 360,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "XOCGAJKUSOYZ",
      "ean": "8713755964382",
      "bicsCode": "UWTYH",
      "smdgCode": "UWTYH",
      "quayName": "UWT Maasvlakte",
      "nextlogicParticipant": false,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "roadStatusRequestAllowed": true,
      "bargeStatusRequestForbidden": false,
      "tarRequired": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "UWT",
      "ean": "8713755047085",
      "bicsCode": "0WHTW",
      "smdgCode": "WHT",
      "quayName": "UWT Waalhaven",
      "nextlogicParticipant": true,
      "roadStatusRequestAllowed": true,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "barge",
        "road"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 360
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 360,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "NONPCS_ACCES",
      "bicsCode": "ACCES",
      "quayName": "Access World",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_ALCONET",
      "bicsCode": "0HAND",
      "quayName": "Alconet Seinehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_ALUCHEMIE",
      "bicsCode": "0ALUB",
      "quayName": "Aluchemie 3e Petroleumhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_BCW",
      "bicsCode": "00BCW",
      "quayName": "Barge Center Waalhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_BROEKMAN",
      "bicsCode": "0GEVW",
      "quayName": "Broekman Project Services Waalhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_CCIS",
      "bicsCode": "0CCIS",
      "quayName": "CCIS 1",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_DFDS",
      "bicsCode": "0DFDS",
      "quayName": "DFDS Vulcaanhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_HIWA",
      "bicsCode": "0HIWA",
      "quayName": "HIWA Ijsselhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_HOOYM",
      "bicsCode": "HOOYM",
      "quayName": "Hooymeijer Stevedoring",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Vlaardingen",
        "locationUnCode": "NLVLA"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_JCMEIJERS",
      "bicsCode": "MEIJW",
      "quayName": "J.C. Meijers",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_KRAMER",
      "bicsCode": "00DCS",
      "quayName": "Kramer Amazonehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_MCS",
      "bicsCode": "0MCSC",
      "quayName": "MCS City",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_MCS",
      "bicsCode": "0MCSP",
      "quayName": "MCS Pernis",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_MEDREPAIR",
      "bicsCode": "MEDRE",
      "quayName": "Medrepair",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RFW",
      "bicsCode": "0FRUM",
      "quayName": "Rotterdam Fruit Wharf",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RHENUS",
      "bicsCode": "0RDSO",
      "quayName": "Rhenus Maasvlakte Oostzijde",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RHENUS",
      "bicsCode": "0RDSZ",
      "quayName": "Rhenus Maasvlakte Zuidzijde",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RHENUS",
      "bicsCode": "SPHW1",
      "quayName": "Rhenus Spoorhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_ROTTERDAM",
      "bicsCode": "0RHBW",
      "quayName": "Rotterdams Haven Bedrijf",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEBA",
      "quayName": "Steinweg Beatrixhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEBE",
      "quayName": "Steinweg Beneluxhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEBO",
      "quayName": "Steinweg Botlek",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEMI",
      "quayName": "Steinweg Mississipihaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STESE",
      "quayName": "Steinweg Seinehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW1",
      "quayName": "Steinweg Waalhaven pier 1",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW2",
      "quayName": "Steinweg Waalhaven pier 2",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW5",
      "quayName": "Steinweg Waalhaven pier 5",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW6",
      "quayName": "Steinweg Waalhaven pier 6",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW7",
      "quayName": "Steinweg Waalhaven pier 7",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_UDEN",
      "bicsCode": "UDENM",
      "quayName": "Van Uden Merwehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_WBT",
      "bicsCode": "00WBT",
      "quayName": "Waalhaven Botlek Terminal",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_WHWS",
      "bicsCode": "0WHWS",
      "quayName": "Westerstuw",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_METTR",
      "bicsCode": "0MTRW",
      "quayName": "Metaal Transport",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_OTHER",
      "bicsCode": "OTHER",
      "quayName": "Other terminal",
      "displayNameOverrides": {
        "road": "Other"
      },
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "terminalSettings": []
    }
  ],
  "kt": [
    {
      "shortName": "APMRTM",
      "ean": "8713755000394",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "0HPDE",
      "quayName": "HPD2",
      "smdgCode": "HPD2",
      "roadStatusRequestAllowed": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "APMII",
      "ean": "8713755272258",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "0APMA",
      "quayName": "APM2",
      "smdgCode": "NLMVII",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 35,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "visitCloseDelayInMinutes": 60,
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "GMPAQQJRHLGF",
      "ean": "8713755272262",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "prenotificationRestrictedToDeclarants": {
        "road": [
          "PORTINFOLINK",
          "APMEXP",
          "GMPAQQJRHLGF"
        ]
      },
      "bicsCode": "A2T11",
      "quayName": "A2T11",
      "smdgCode": "A2T11",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 35,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "visitCloseDelayInMinutes": 60,
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "APMII",
      "ean": "8713755272258",
      "allowedModalities": [
        "road"
      ],
      "prenotificationRestrictedToDeclarants": {
        "road": [
          "PORTINFOLINK",
          "APMII",
          "APMEXP",
          "APMEXP1",
          "APMIIPP",
          "APMIITEST"
        ]
      },
      "bicsCode": "EHDII",
      "quayName": "APM2 EHD",
      "smdgCode": "EHDII",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 35,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ],
      "visitCloseDelayInMinutes": 60,
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "APMIITEST",
      "ean": "8713755272259",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "APM2T",
      "quayName": "APM2T",
      "smdgCode": "MVIIT12",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 35,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "outOfGaugeAllowed": true,
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "outOfGaugeAllowed": true,
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "ATTFAARVFFOD",
      "ean": "8713755283179",
      "bicsCode": "00PCT",
      "smdgCode": "CTTR",
      "quayName": "CTT Rotterdam",
      "nextlogicParticipant": false,
      "tarRequired": true,
      "nonPcs": true,
      "apiOnly": true,
      "updateAllowed": true,
      "roadStatusRequestAllowed": true,
      "acceptAfterRejectForbidden": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "linkHandlingsAllowed": true,
      "linkedTransportCompanies": [
        "CTTR",
        "AVV"
      ],
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": true,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "reeferAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "reeferAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "road",
          "reeferAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "reeferAllowed": true
        }
      ]
    },
    {
      "shortName": "RBQLSMIHANGW",
      "ean": "8713755502287",
      "bicsCode": "CTVRW",
      "smdgCode": "CTVA",
      "quayName": "CTVrede Amsterdam",
      "tarRequired": true,
      "nonPcs": false,
      "apiOnly": true,
      "updateAllowed": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Amsterdam",
        "locationUnCode": "NLAMS"
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        }
      ]
    },
    {
      "shortName": "RBQLSMIHANGW",
      "ean": "8713755502287",
      "bicsCode": "CTVRE",
      "smdgCode": "CTVH",
      "quayName": "CTVrede Hoogtij",
      "tarRequired": true,
      "nonPcs": false,
      "apiOnly": true,
      "updateAllowed": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Westzaan",
        "locationUnCode": "NLWTZ"
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        }
      ]
    },
    {
      "shortName": "BROEKMANDISTRI",
      "ean": "8713755502218",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "0GEVB",
      "quayName": "CLdN Distriport",
      "smdgCode": "BDP",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": false,
      "maxSealNumberLength": 15,
      "roadStatusRequestAllowed": false,
      "bargeStatusRequestForbidden": true,
      "acceptAfterRejectForbidden": false,
      "updateAllowed": true,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ],
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "barge"
      ],
      "bicsCode": "00DDN",
      "quayName": "DDN",
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "handlingUpdateForbidden": [
        "barge"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true,
        "handlingUpdateBypassAllowed": true
      },
      "minimumCallSize": 2,
      "acceptAfterRejectForbidden": true,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true,
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "00DDE",
      "quayName": "DDE",
      "displayNameOverrides": {
        "road": "ECTDELTA"
      },
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "handlingUpdateForbidden": [
        "barge",
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true,
        "handlingUpdateBypassAllowed": true
      },
      "minimumCallSize": 2,
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true,
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false,
      "remainOnTruckAllowed": true
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "barge"
      ],
      "bicsCode": "000LT",
      "quayName": "LT",
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "quaySelectByDeclarantForbidden": true,
      "roadStatusRequestAllowed": true,
      "handlingUpdateForbidden": [
        "barge",
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge"
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "ECTDELTA",
      "ean": "8713755000059",
      "allowedModalities": [
        "barge"
      ],
      "bicsCode": "00DBF",
      "quayName": "DBF",
      "smdgCode": "DCD",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "quaySelectByDeclarantForbidden": true,
      "roadStatusRequestAllowed": true,
      "handlingUpdateForbidden": [
        "barge",
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge"
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "EUROMAX",
      "ean": "8713755502201",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "00EMX",
      "quayName": "EUROMAX",
      "smdgCode": "EMX",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "roadStatusRequestAllowed": true,
      "minimumCallSize": 2,
      "updateAllowed": true,
      "handlingUpdateForbidden": [
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true,
        "handlingUpdateBypassAllowed": true
      },
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true,
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false,
      "remainOnTruckAllowed": true
    },
    {
      "shortName": "EUROMAXN4",
      "ean": "8713755502202",
      "allowedModalities": [
        "barge",
        "road",
        "rail"
      ],
      "bicsCode": "EMXN4",
      "quayName": "EUROMAX N4",
      "smdgCode": "EMXN4",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "roadStatusRequestAllowed": true,
      "minimumCallSize": 2,
      "updateAllowed": true,
      "prenotificationRestrictedToDeclarants": {
        "road": [
          "PORTINFOLINK",
          "TESTORGANISATIE",
          "ECTROAD",
          "AVV"
        ]
      },
      "handlingUpdateForbidden": [
        "road",
        "rail"
      ],
      "handlingUpdateBypassAllowed": [
        "rail"
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true,
        "handlingUpdateBypassAllowed": true
      },
      "acceptAfterRejectForbidden": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "reeferAllowed": true,
          "grossWeightAllowed": true,
          "outOfGaugeAllowed": true,
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 480
        }
      ],
      "nextlogicParticipant": false,
      "nonPcs": false,
      "remainOnTruckAllowed": true
    },
    {
      "shortName": "CJKMQANKISTH",
      "ean": "8713755235031",
      "allowedModalities": [
        "road"
      ],
      "bicsCode": "NLRTMYLEI",
      "quayName": "Hapag Lloyd Rotterdam",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "maxSealNumberLength": 15,
      "nextlogicParticipant": false,
      "nonPcs": false,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "road"
        },
        {
          "loading": true,
          "full": false,
          "modality": "road"
        },
        {
          "loading": true,
          "full": true,
          "modality": "road"
        }
      ]
    },
    {
      "shortName": "INTERFORESTRTM",
      "ean": "8713755234607",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "handlingUpdateForbidden": [
        "barge"
      ],
      "handlingUpdateBypassAllowed": [
        "barge"
      ],
      "handlingMoveBypassAllowed": [
        "road"
      ],
      "bicsCode": "00MAT",
      "quayName": "Matrans",
      "smdgCode": "MATR",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "maxSealNumberLength": 15,
      "roadStatusRequestAllowed": false,
      "bargeStatusRequestForbidden": true,
      "acceptAfterRejectForbidden": false,
      "updateAllowed": true,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        }
      ],
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "KCLBV",
      "ean": "8713755948719",
      "bicsCode": "0KRAD",
      "smdgCode": "OQEP",
      "quayName": "DCS | Kramer Group",
      "displayNameOverrides": {
        "road": "DCS | Kramer Group"
      },
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      }
    },
    {
      "shortName": "KRAMERHOMEDEPOT",
      "ean": "8713755297305",
      "bicsCode": "0KRAW",
      "smdgCode": "RSN",
      "quayName": "KCD | Kramer Group",
      "displayNameOverrides": {
        "road": "KCD | Kramer Group"
      },
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      }
    },
    {
      "shortName": "ZBLVPKUFSZQQ",
      "ean": "8713755299484",
      "bicsCode": "00RBC",
      "smdgCode": "CETA",
      "quayName": "RBC",
      "displayNameOverrides": {
        "road": "RBC/Cetem"
      },
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ]
    },
    {
      "shortName": "ZBLVPKUFSZQQ",
      "ean": "8713755232023",
      "bicsCode": "CETEM",
      "smdgCode": "CETA",
      "quayName": "Cetem",
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ]
    },
    {
      "shortName": "RCT",
      "ean": "8713755955137",
      "bicsCode": "00RCT",
      "smdgCode": "KRM",
      "quayName": "RCT | Kramer Group",
      "displayNameOverrides": {
        "road": "RCT | Kramer Group"
      },
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road",
        "barge"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 1080
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      }
    },
    {
      "shortName": "RSC",
      "ean": "8713755000158",
      "bicsCode": "00RSC",
      "smdgCode": "RSC",
      "quayName": "RSC",
      "nextlogicParticipant": false,
      "nonPcs": false,
      "apiOnly": true,
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": false,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "RST",
      "ean": "8713755259860",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "RSTNO",
      "quayName": "RST ZONE 10",
      "displayNameOverrides": {
        "road": "RST"
      },
      "smdgCode": "RST",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "bargeStatusRequestForbidden": true,
      "handlingUpdateForbidden": [
        "barge"
      ],
      "handlingUpdateBypassAllowed": [
        "barge"
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": false
      },
      "maxSealNumberLength": 20,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "RST",
      "ean": "8713755259860",
      "allowedModalities": [
        "barge"
      ],
      "bicsCode": "RSTZU",
      "quayName": "RST ZONE 8/9",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "bargeStatusRequestForbidden": true,
      "handlingUpdateForbidden": [
        "barge"
      ],
      "handlingUpdateBypassAllowed": [
        "barge"
      ],
      "maxSealNumberLength": 20,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "RWG",
      "ean": "8713755270896",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "00RWG",
      "quayName": "RWG",
      "smdgCode": "RWG",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "minimumCallSize": 15,
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "RWG1",
      "ean": "8713755270892",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "01RWG",
      "quayName": "RWG1",
      "smdgCode": "RWG1",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "minimumCallSize": 2,
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "RWG2",
      "ean": "8713755270897",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "02RWG",
      "quayName": "RWG2",
      "smdgCode": "RWG2",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "minimumCallSize": 2,
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "RWG3",
      "ean": "8713755270898",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "03RWG",
      "quayName": "RWG3",
      "smdgCode": "RWG3",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "minimumCallSize": 2,
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "RWG4",
      "ean": "8713755270890",
      "allowedModalities": [
        "road",
        "rail",
        "barge"
      ],
      "bicsCode": "04RWG",
      "quayName": "RWG4",
      "smdgCode": "RWG4",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "apiOnly": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "rail",
          "sealNumberAllowed": true
        },
        {
          "loading": false,
          "full": false,
          "modality": "rail",
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "minimumCallSize": 2,
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": false,
      "nonPcs": false
    },
    {
      "shortName": "APMTR",
      "ean": "201701262000",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "APMTS",
      "quayName": "APMTR",
      "smdgCode": "APMTS",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "roadStatusRequestAllowed": true,
      "updateAllowed": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "APMTRDEV",
      "ean": "201701263000",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "APMDV",
      "quayName": "APMTRDEV",
      "smdgCode": "DEV",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "updateAllowed": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 120
        }
      ],
      "visitCloseDelayInMinutes": 30,
      "nextlogicParticipant": true,
      "nonPcs": false
    },
    {
      "shortName": "UWT",
      "ean": "8713755903541",
      "bicsCode": "00UCT",
      "smdgCode": "UWT",
      "quayName": "UWT Eemhaven",
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 360
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 360,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        }
      ]
    },
    {
      "shortName": "UWT",
      "ean": "8713755903541",
      "bicsCode": "UWTFR",
      "smdgCode": "UWT",
      "quayName": "UWT Frisohaven",
      "displayNameOverrides": {
        "road": "UWT Bunschotenweg"
      },
      "nextlogicParticipant": true,
      "nonPcs": false,
      "apiOnly": true,
      "roadStatusRequestAllowed": true,
      "allowedModalities": [
        "barge",
        "road"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 360
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 360,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "XOCGAJKUSOYZ",
      "ean": "8713755964382",
      "bicsCode": "UWTYH",
      "smdgCode": "UWTYH",
      "quayName": "UWT Maasvlakte",
      "nextlogicParticipant": false,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "roadStatusRequestAllowed": true,
      "bargeStatusRequestForbidden": false,
      "tarRequired": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "UWT",
      "ean": "8713755047085",
      "bicsCode": "0WHTW",
      "smdgCode": "WHT",
      "quayName": "UWT Waalhaven",
      "nextlogicParticipant": true,
      "roadStatusRequestAllowed": true,
      "nonPcs": false,
      "apiOnly": true,
      "allowedModalities": [
        "barge",
        "road"
      ],
      "tarRequired": true,
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "acceptAfterRejectForbidden": true,
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "sealNumberAllowed": true,
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 360
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 360,
          "lossenOpRichtlijnAllowed": true
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 720
        },
        {
          "loading": false,
          "full": false,
          "modality": "road",
          "lossenOpRichtlijnAllowed": true
        }
      ]
    },
    {
      "shortName": "NONPCS_ALCONET",
      "bicsCode": "0HAND",
      "quayName": "Alconet Seinehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_ALUCHEMIE",
      "bicsCode": "0ALUB",
      "quayName": "Aluchemie 3e Petroleumhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_BCW",
      "bicsCode": "00BCW",
      "quayName": "Barge Centre Waalhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_BROEKMAN",
      "bicsCode": "0GEVW",
      "quayName": "Broekman Project Services Waalhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_CROPORTS",
      "bicsCode": "00CRO",
      "quayName": "C.RO Ports Nederland (Brittanniehaven)",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_DFDS",
      "bicsCode": "DFDSV",
      "quayName": "DFDS Vulcaanhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_JCMEIJERS",
      "bicsCode": "MEIJW",
      "quayName": "J.C. Meijers",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_HIWA",
      "bicsCode": "HIWA",
      "quayName": "HIWA Ijsselhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_KRAMER",
      "bicsCode": "00DCS",
      "quayName": "Kramer Amazonehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_MCS",
      "bicsCode": "0MCSC",
      "quayName": "MCS City",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_MCS",
      "bicsCode": "0MCSP",
      "quayName": "MCS Pernis",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_MEDREPAIR",
      "bicsCode": "MEDRE",
      "quayName": "Medrepair",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RFW",
      "bicsCode": "0FRUM",
      "quayName": "Rotterdam Fruit Wharf",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RHENUS",
      "bicsCode": "0RDSO",
      "quayName": "Rhenus Maasvlakte Oostzijde",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RHENUS",
      "bicsCode": "0RDSZ",
      "quayName": "Rhenus Maasvlakte Zuidzijde",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_RHENUS",
      "bicsCode": "SPHW1",
      "quayName": "Rhenus Spoorhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_ROTTERDAM",
      "bicsCode": "00RHB",
      "quayName": "Rotterdams Haven Bedrijf",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEBA",
      "quayName": "Steinweg Beatrixhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEBE",
      "quayName": "Steinweg Beneluxhaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEBO",
      "quayName": "Steinweg Botlek",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEMI",
      "quayName": "Steinweg Mississipihaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STESE",
      "quayName": "Steinweg Seinehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW1",
      "quayName": "Steinweg Waalhaven pier 1",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW2",
      "quayName": "Steinweg Waalhaven pier 2",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW5",
      "quayName": "Steinweg Waalhaven pier 5",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW6",
      "quayName": "Steinweg Waalhaven pier 6",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_STEINWEG",
      "bicsCode": "STEW7",
      "quayName": "Steinweg Waalhaven pier 7",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_UDEN",
      "bicsCode": "UDENM",
      "quayName": "Van Uden Merwehaven",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_WBT",
      "bicsCode": "00WBT",
      "quayName": "Waalhaven Botlek Terminal",
      "nextlogicParticipant": true,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_WHWS",
      "bicsCode": "0WHWS",
      "quayName": "Westerstuw",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_METTR",
      "bicsCode": "0MTRW",
      "quayName": "Metaal Transport",
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "barge"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": []
    },
    {
      "shortName": "NONPCS_OTHER",
      "bicsCode": "OTHER",
      "quayName": "Other terminal",
      "displayNameOverrides": {
        "road": "Other"
      },
      "nextlogicParticipant": false,
      "nonPcs": true,
      "allowedModalities": [
        "road"
      ],
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "updateAllowed": true,
      "terminalSettings": []
    }
  ],
  "sc": [
    {
      "shortName": "APMRTM",
      "ean": "8713755000394",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "0HPDE",
      "quayName": "HPD2",
      "smdgCode": "HPD2",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "nextlogicParticipant": true,
      "nonPcs": false
    }
  ],
  "local": [
    {
      "shortName": "APMRTM",
      "ean": "8713755000394",
      "allowedModalities": [
        "road",
        "barge"
      ],
      "bicsCode": "0HPDE",
      "quayName": "HPD2",
      "smdgCode": "HPD2",
      "location": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM"
      },
      "tarRequired": true,
      "updateAllowed": true,
      "maxSealNumberLength": 15,
      "terminalSettings": [
        {
          "loading": false,
          "full": true,
          "modality": "road",
          "grossWeightAllowed": true
        },
        {
          "loading": false,
          "full": true,
          "modality": "barge",
          "grossWeightAllowed": true,
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": false,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 60
        },
        {
          "loading": true,
          "full": false,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        },
        {
          "loading": true,
          "full": true,
          "modality": "barge",
          "cargoCutOffInMinutes": 240
        }
      ],
      "loadDischargeListSettings": {
        "autoRejectEnabled": true
      },
      "nextlogicParticipant": true,
      "nonPcs": false
    }
  ]
}
