<ol class="breadcrumb">
  <li class="breadcrumb-item"><a href="/support">Support</a></li>
  <li class="breadcrumb-item active">Terminals</li>
</ol>
<div class="row">
  <div class="col-12">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Barge</a>
        <ng-template ngbNavContent>
          <ng-container [ngTemplateOutlet]="terminalsTemplate" [ngTemplateOutletContext]="{ terminals : bargeTerminals, modality: 'barge' }"></ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Road</a>
        <ng-template ngbNavContent>
          <ng-container [ngTemplateOutlet]="terminalsTemplate" [ngTemplateOutletContext]="{ terminals : roadTerminals, modality: 'road' }"></ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Rail</a>
        <ng-template ngbNavContent>
          <ng-container [ngTemplateOutlet]="terminalsTemplate" [ngTemplateOutletContext]="{ terminals : railTerminals, modality: 'rail' }"></ng-container>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>

<ng-template #terminalsTemplate let-terminals="terminals" let-modality="modality">
  <div class="row">
    <div class="col-3 overflow-auto" style="overflow-y: scroll;height:600px">
      <div class="nav flex-column nav-pills" id="v-pills-terminals" role="tablist" aria-orientation="vertical">
        <ng-container *ngFor="let terminal of terminals | asTerminals; let index = index;">
          <a class="nav-link" id="v-pills-{{terminal.shortName}}-{{terminal.bicsCode}}-tab" data-bs-toggle="pill"
             href="#v-pills-{{terminal.bicsCode}}" role="tab">{{utils.getTerminalDisplayName(terminal, modality)}}</a>

        </ng-container>
      </div>
    </div>

    <div class="col-9">
      <div class="tab-content" id="v-terminals-tabContent">
        <ng-container *ngFor="let terminal of terminals | asTerminals; let index = index">

          <div class="tab-pane fade show terminalsetting" id="v-pills-{{terminal.bicsCode}}" role="tabpanel">
            <div class="declaration-block"><h1>{{utils.getTerminalDisplayName(terminal, modality)}}</h1>
              <h2>Quay configuration</h2></div>
            <ng-container [ngTemplateOutlet]="terminalOrganisationTemplate"
                          [ngTemplateOutletContext]="{ terminal : terminal, index: index, modality: modality }"></ng-container>

            <div *ngIf="modality==='barge'">
              <div class="declaration-block"><h2>Load-discharge list settings</h2></div>
              <div class="row">
                <div class="col-6">
                  <app-tooltip [placement]="'right'">
                    <div class="tooltipContent">When enabled, Portbase rejects the containers which have not been accepted yet on behalf of the terminal when the Load-Discharge List closes at cutoff and sends an APERAK+RE to the Barge Operator.</div>
                    Autoreject enabled
                  </app-tooltip>
                </div>
                <div class="col">{{terminal?.loadDischargeListSettings?.autoRejectEnabled}}</div>
              </div>
              <div class="row">
                <div class="col-6">
                  <app-tooltip [placement]="'right'">
                    <div class="tooltipContent">Number of minutes the terminal can still send a reject via EDIFACT after cutoff. After that the visit is closed and no modifications can be made without reopening the Load-Discharge List.</div>
                    Nr of minutes delay before visit is closed
                  </app-tooltip>
                </div>
                <div class="col">{{terminal?.visitCloseDelayInMinutes}}</div>
              </div>
              <div class="row">
                <div class="col-6">
                  <app-tooltip [placement]="'right'">
                    <div class="tooltipContent">Minimum callsize the visit has to have (number of prenotifications that are accepted without warnings), before the Load-Discharge List can be closed automatically.</div>
                    Minimum callsize
                  </app-tooltip>
                </div>
                <div class="col">{{terminal?.minimumCallSize}}</div>
              </div>

            </div>

            <div class="declaration-block"><h2>General handling settings</h2></div>
            <div class="row">
              <div class="col-6">Declaration updates allowed</div>
              <div class="col">
                <ng-container [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : !getModalitySetting(terminal.handlingUpdateForbidden, modality)}"></ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-6">Handling update bypass allowed</div>
              <div class="col">
                <ng-container [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : getModalitySetting(terminal.handlingUpdateBypassAllowed, modality)}"></ng-container>
              </div>
            </div>
            <div class="row" *ngIf="modality !== 'rail'">
              <div class="col-6">Status requests</div>
              <div class="col">
                <ng-container *ngIf="modality==='barge'" [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : !terminal.bargeStatusRequestForbidden}"></ng-container>
                <ng-container *ngIf="modality==='road'" [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : terminal.roadStatusRequestAllowed}"></ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-6">Allow accept after reject</div>
              <div class="col">
                <ng-container [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : !terminal.acceptAfterRejectForbidden}"></ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-6">Allow 'lossen op richtlijn'</div>
              <div class="col">
                <ng-container [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : getLossenOpRichtlijnAllowed(terminal.terminalSettings, modality)}"></ng-container>
              </div>
            </div>
            <div class="row" *ngIf="modality==='road'">
              <div class="col-6">TAR required</div>
              <div class="col">
                <ng-container [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : terminal.tarRequired}"></ng-container>
              </div>
            </div>
            <div class="row" *ngIf="modality==='road'">
              <div class="col-6">Remain on truck allowed</div>
              <div class="col">
                <ng-container [ngTemplateOutlet]="settingTemplate"
                              [ngTemplateOutletContext]="{ setting : terminal.remainOnTruckAllowed}"></ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-6">Maximum seal number length</div>
              <div class="col">{{terminal?.maxSealNumberLength}}</div>
            </div>

            <div *ngIf="getSettings(terminal.terminalSettings,modality)">
              <div class="declaration-block"><h2>Handling settings per type</h2></div>
              <ng-container
                [ngTemplateOutlet]="terminalSettingsTemplate"
                [ngTemplateOutletContext]="{ settings : getSettings(terminal.terminalSettings, modality), index: index, modality: modality }"></ng-container>
            </div>


            <div *ngIf="terminal?.prenotificationRestrictedToDeclarants && terminal?.prenotificationRestrictedToDeclarants[modality]">
              <div class="declaration-block"><h2>Prenotification restricted to specific declarants</h2></div>
              <div class="row">
                <div class="col-3">
                  <app-tooltip [placement]="'right'">
                    <div class="tooltipContent">When this terminal is only allowed for specific declarants (i.e. to be able to pilot a new terminal), this
                      setting contains the list of declarant organisation shortnames that are allowed to declare prenotifications to this terminal.
                    </div>
                    Declarants
                  </app-tooltip>
                </div>
                <div class="col" *ngIf="terminal?.prenotificationRestrictedToDeclarants[modality]">
                  <ul>
                    <li *ngFor="let organisation of terminal.prenotificationRestrictedToDeclarants[modality];">{{organisation}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #terminalOrganisationTemplate let-terminalItem="terminal" let-modality="modality">
  <div class="row">
    <div class="col-6">Organisation shortname</div>
    <div class="col-6">{{(terminalItem | asTerminal).shortName}}</div>
  </div>
  <div class="row">
    <div class="col-6">EAN</div>
    <div class="col-6">{{(terminalItem | asTerminal).ean}}</div>
  </div>
  <div class="row">
    <div class="col-6">Quay name</div>
    <div class="col-6">{{(terminalItem | asTerminal).quayName}}</div>
  </div>
  <div class="row" *ngIf="!!(terminalItem | asTerminal).displayNameOverrides">
    <div class="col-6">Display name</div>
    <div class="col-6">{{utils.getTerminalDisplayName(terminalItem, modality)}}</div>
  </div>


  <div class="row">
    <div class="col-6">BICS code</div>
    <div class="col">{{(terminalItem | asTerminal).bicsCode}}</div>
  </div>
  <div class="row">
    <div class="col-6">SMDG code</div>
    <div class="col">{{(terminalItem | asTerminal).smdgCode}}</div>
  </div>
  <div class="row">
    <div class="col-6">Portbase customer</div>
    <div class="col">
      <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : !(terminalItem | asTerminal).nonPcs}"></ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-6">API only</div>
    <div class="col">
      <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : (terminalItem | asTerminal).apiOnly}"></ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-6">Integral Planning participant</div>
    <div class="col">
      <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : (terminalItem | asTerminal).nextlogicParticipant}"></ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-6">Quay selectable by declarant?</div>
    <div class="col">
      <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : !(terminalItem | asTerminal).quaySelectByDeclarantForbidden}"></ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-6">Update Allowed (Deprecated)</div>
    <div class="col">
      <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : !(terminalItem | asTerminal).updateAllowed}"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #terminalSettingsTemplate let-settings="settings" let-modality="modality">
  <div class="row border-bottom bg-dark text-light">
    <div class="col">Setting</div>
    <div class="col table-header" *ngFor="let containerSettings of settings | asTerminalSettings; let index = index">
      <div>
        <span *ngIf="containerSettings.loading">Loading</span>
        <span *ngIf="!containerSettings.loading">Discharge</span>
        <span *ngIf="containerSettings.full">Full</span>
        <span *ngIf="!containerSettings.full">Empty</span>
      </div>
    </div>
  </div>
  <div class="row terminalsetting">
    <div class="col border-end">
      <div class="row">
        <div class="col">Seal number</div>
      </div>
      <div class="row">
        <div class="col">Reefer</div>
      </div>
      <div class="row">
        <div class="col">Gross weight</div>
      </div>
      <div class="row">
        <div class="col">Out of gauge</div>
      </div>
      <div class="row">
        <div class="col">Dangerous goods</div>
      </div>
      <div class="row" *ngIf="modality==='barge'">
        <div class="col">Cargo cutoff (minutes)</div>
      </div>
    </div>
    <div class="col" *ngFor="let containerSettings of settings | asTerminalSettings; let index = index">
      <div class="row terminalsetting">
        <div class="col-1">
          <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : containerSettings.sealNumberAllowed}"></ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : containerSettings.reeferAllowed}"></ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : containerSettings.grossWeightAllowed}"></ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : containerSettings.outOfGaugeAllowed}"></ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <ng-container [ngTemplateOutlet]="settingTemplate" [ngTemplateOutletContext]="{ setting : containerSettings.dangerousGoodsAllowed}"></ng-container>
        </div>
      </div>
      <div class="row" *ngIf="containerSettings.cargoCutOffInMinutes">
        <div class="col-1">{{containerSettings.cargoCutOffInMinutes}}</div>
      </div>
      <div class="row" *ngIf="!containerSettings.cargoCutOffInMinutes && modality=='barge'">
        <div class="col-1">&nbsp;</div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #settingTemplate let-setting="setting">
  <span *ngIf="setting" class="fa fa-check-circle text-success"></span><span *ngIf="!setting" class="fa fa-times-circle text-danger"></span>
</ng-template>
