<div *ngIf="command">
  <div class="row mt-2">
    <div class="col-12">
      <app-edit-barge-voyage *ngIf="modality === 'barge'"
        [isNew]="true"
        [voyageData]="command.voyageData"
        [integralPlanningEnabled]="integralPlanningEnabled"
        [previousVoyageId]="command.voyageData['previousVoyageId']">
      </app-edit-barge-voyage>
      <app-edit-rail-voyage *ngIf="modality === 'rail'"
        [isNew]="true"
        [voyageData]="command.voyageData">
      </app-edit-rail-voyage>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-auto me-auto"></div>
    <div class="col-sm-auto">
      <button (click)="createVoyage()" type="button" class="btn btn-info btn-block" tabindex="-1"
              style="min-width: 10rem"><span>Save rotation</span><span class="ps-2 fa fa-chevron-right"></span></button>
    </div>
  </div>
</div>
