import {Component, Input, OnInit} from '@angular/core';
import {
  EmhHeader,
  EmhMessage,
  EmhMessageLogItem,
  Handling,
  RoadVisit,
  RoadVoyage,
  Terminal
} from "@portbase/hinterland-service-typescriptmodels";
import moment from "moment/moment";
import _ from "lodash";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {getHandlingMessageLog} from "../../../../common/query-utils";
import {HinterlandUtils} from "../../../../hinterland/hinterland-utils";
import {AppContext} from "../../../../app-context";
import {EventHandler, EventType} from "../../../../common/event-gateway";
import {ConfirmationModalService} from '../../../../components/modals/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'app-view-message-history-offcanvas',
  templateUrl: './app-view-message-history-offcanvas.html',
  styleUrls: ['./app-view-message-history-offcanvas.scss']
})
export class ViewMessageHistoryOffCanvasComponent extends HinterlandBaseComponent implements OnInit {
  @Input() voyage: RoadVoyage;
  @Input() visit: RoadVisit;
  @Input() handling: Handling;

  messageLogItems: MessageLogItem[] = [];
  terminal: Terminal;
  kibanaVoyageUrl: string;
  kibanaVisitUrl: string;
  kibanaHandlingUrl: string;

  constructor(
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.terminal = this.visit ? this.visit.terminal : this.handling['terminal'];

    if (this.voyage) {
      this.kibanaVoyageUrl = HinterlandUtils.buildKibanaUrl(this.voyage.voyageId);
    }
    if (this.visit) {
      this.kibanaVisitUrl = HinterlandUtils.buildKibanaUrl(this.visit.visitId);
    }
    this.kibanaHandlingUrl = HinterlandUtils.buildKibanaUrl(this.handling.handlingId);

    this.fetchLogItems();
  }

  onVoyageUpdated: EventHandler<EventType.VoyageUpdated> = (voyageId: string) => {
    if (this.voyage && this.voyage.voyageId === voyageId) {
      this.fetchLogItemsDebounced();
    }
  }

  onVisitUpdated: EventHandler<EventType.VisitUpdated> = (visitId: string) => {
    if (this.visit && this.visit.visitId === visitId) {
      this.fetchLogItemsDebounced();
    }
  }

  onHandlingUpdated: EventHandler<EventType.HandlingUpdated> = (handlingId: string) => {
    if (this.handling.handlingId === handlingId) {
      this.fetchLogItemsDebounced();
    }
  }

  fetchLogItemsDebounced: Function = _.debounce(() => {
    this.fetchLogItems();
  }, 500);

  fetchLogItems() {
    this.messageLogItems = [];
    if (!this.terminal.apiOnly && !this.terminal.nonPcs) {
      getHandlingMessageLog(this.handling, this.visit).subscribe((emhMessageLogItems: EmhMessageLogItem[]) => {
        this.setLogItems(emhMessageLogItems);
      });
    }
  }

  setLogItems(logItems: EmhMessageLogItem[]) {
    for (const logItem of logItems) {
      const emhMessage: EmhMessage = logItem.emhMessage;
      const emhHeader: EmhHeader = emhMessage.emhHeader;
      const timestamp: moment.Moment = moment(logItem.timestamp);
      const formattedDate = timestamp.format('ddd DD-MM-YY');
      const formattedTime = timestamp.format('HH:mm');
      const typeSplitted = logItem.type.split('.');
      const command = typeSplitted[typeSplitted.length - 1];
      const action = this.getActionText(emhHeader);

      let organisation;
      if (logItem.messageDirection === "OUTGOING") {
        organisation = 'Portbase';
      } else if (emhHeader.senderOrgShortName) {
        organisation = emhHeader.senderOrgShortName;
      } else {
        organisation = logItem.metadata['organisation'];
      }

      this.messageLogItems.push(<MessageLogItem>{
        formattedDate: formattedDate,
        formattedTime: formattedTime,
        command: command,
        action: action,
        messageId: logItem.messageId,
        processId: logItem.processId,
        organisation: organisation,
        timestamp: timestamp,
        sourceEmhMessageLogItem: logItem
      });
    }

    this.messageLogItems.sort(function (itemA, itemB) { //Chronologically descending
      return itemA.timestamp.diff(itemB.timestamp);
    });
  }

  getActionText(emhHeader: EmhHeader) {
    let action = '?';
    switch (emhHeader.msgType) {
      case 'mst.hcn.copino.rail.out':
      case 'mst.hcn.copino.out':
      case 'mst.hcn.copino.barge.out':
        action = 'Copino Sent';
        break;
      case 'mst.hcn.aperak':
        action = 'Aperak received';
        break;
      case 'mst.codeco':
        action = 'Codeco received';
        break
    }
    const messageFunction = emhHeader.msgFunction.charAt(0).toUpperCase() + emhHeader.msgFunction.slice(1);
    return `${action} (${messageFunction})`
  }

  openPcsMessageManagement(messageLogItem: MessageLogItem) {
    //Not sure if fully correct implementation. See message-log.component.ts for old implementation which was kinda weird.
    if (messageLogItem.command === 'ProcessCodeco' && messageLogItem.messageId) {
      AppContext.redirectToMessageManagementWithMessageId('', messageLogItem.messageId);
    } else if (messageLogItem.command === 'SendCodeco') {
      const handlingIdWithoutDashes = this.handling.handlingId.split('-').join('');
      AppContext.redirectToMessageManagement(handlingIdWithoutDashes);
    } else if (messageLogItem.processId) {
      AppContext.redirectToMessageManagement(messageLogItem.processId);
    } else {
      const handlingIdWithoutDashes = this.handling.handlingId.split('-').join('');
      AppContext.redirectToMessageManagement(handlingIdWithoutDashes);
    }
  }
}

interface MessageLogItem {
  formattedDate: string;
  formattedTime: string;
  command: string,
  action: string;
  organisation: string;
  processId: string,
  messageId: string,
  timestamp: moment.Moment;
  sourceEmhMessageLogItem: EmhMessageLogItem
}
