import {Component} from '@angular/core';
import {AppContext} from '../../../app-context';
import {environment} from '../../../../environments/environment';
import {BhgSupportComponent} from '../../bhg-support/bhg-support.component';
import {EditModalService} from "../../../components/modals/edit-modal/edit-modal.service";

@Component({
  selector: 'app-bhg-services',
  templateUrl: './bhg-services.component.html',
  styleUrls: ['./bhg-services.component.scss']
})
export class BhgServicesComponent {

  constructor(
    private readonly editModalService: EditModalService,
  ) {
  }

  get visible() : BhgService[]  {
    const roles = AppContext.binnenhavengeldUserProfile.roles;
    if (roles.includes('BinnenhavengeldAdmin')) {
      return ['subscriptions', 'signallings', 'users'];
    }
    if (roles.includes('BinnenhavengeldSuperUser')) {
      return ['subscriptions', 'signallings'];
    }
    if (roles.includes('BinnenhavengeldSignaller')) {
      return ['signallings'];
    }
    return ['subscriptions'];
  }

  get active() : BhgService  {
    const path = window.location.pathname;
    if (path.endsWith("/signallings")) {
      return 'signallings'
    }
    if (path.endsWith("/users")) {
      return 'users'
    }
    if (path.endsWith("/subscriptions")) {
      return 'subscriptions'
    }
    return null;
  }

  openSupport() {
    this.editModalService.openModal(BhgSupportComponent);
  }

  signOut() {
    if (environment.production || environment.docker) {
      window.location.href = '/api/proxy/binnenhavengeld/logout';
    }
  }
}

export type BhgService = 'users' | 'subscriptions' | 'signallings';
