<div class="mb-3" xmlns="http://www.w3.org/1999/html">
  <div class="declaration-block">
    <h2>Tracker status (API)</h2>
  </div>

  <div class="declaration-block pt-1">
    Overview of Terminal trackers. If a terminal is far behind or stopped updating this could indicate there is a delay
    in processing declarations.
  </div>
  <div class="declaration-block pt-2 pb-2">
    Note:
    <ul>
      <li><span class="fw-medium">ECTDELTA</span> is also the tracker for the <span class="fw-medium">EUROMAX</span>
        terminal.
      </li>
      <li><span class="fw-medium">RCT</span> is also the tracker for the <span class="fw-medium">KCLBV (DCS)</span>
        terminal and the <span class="fw-medium">KRAMERHOMEDEPOT (KCD)</span>terminal.
      </li>
      <li><span class="fw-medium">UWT</span> is also the tracker for the <span class="fw-medium">XOCGAJKUSOYZ (UWT Maasvlakte)</span>
        terminal.
      </li>
      <li><span class="fw-medium">NEXTLOGIC</span> is not a terminal but relevant for the planning barge visits
      </li>
    </ul>
  </div>

  <div class="row tracker-status-header">
    <div class="col">Tracker</div>
    <div class="col">
      Alerts
      <span [ngbTooltip]="trackerTooltip" container="body" class="info-icon">
        <i class="fa-light fa-circle-info"></i>
      </span>
    </div>
    <div class="col">Last updated</div>
  </div>

  <ng-template #trackerTooltip>
    <div class="tooltip-card">
      <div class="tooltip-card-body">
        <div class="tooltip-card">FAR_BEHIND - Tracker has been last updated more than ten minutes ago</div>
        <div class="tooltip-card">STOPPED_UPDATING - Tracker did not change position since last update</div>
        <div class="tooltip-card">UNFINISHED_CALLS - Tracker has multiple failing or unfinished calls</div>
        <div class="tooltip-card">MANY_TRACKERS - Tracker is used for more than eight clients</div>
      </div>
    </div>
  </ng-template>

  <div *ngFor="let stat of trackerStats">
    <div class="row tracker-status">
      <div class="col">{{ stat.organisation }} ({{ stat.user }})</div>
      <div class="col">{{ stat.alerts }}</div>
      <div class="col">{{ formatDate(stat.lastUpdate) }}</div>
    </div>
  </div>

  <div class="declaration-block">
    <h2>Pending declarations overview (EDIFACT)</h2>
  </div>
  <div class="row">
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'HPD2', status: 'OK', queueSize: getTerminalQueueSize('APMRTM') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'APMII', status: 'OK', queueSize: getTerminalQueueSize('APMII') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'ECTDELTA', status: 'OK', queueSize: getTerminalQueueSize('ECTDELTA') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'EUROMAX', status: 'OK', queueSize: getTerminalQueueSize('EUROMAX') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'RWG', status: 'OK', queueSize: getTerminalQueueSize('RWG') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'RST', status: 'OK', queueSize: getTerminalQueueSize('RST') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'MATRANS', status: 'OK', queueSize: getTerminalQueueSize('INTERFORESTRTM') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
    <ng-container
      [ngTemplateOutletContext]="{ terminal : 'CLdN Distri', status: 'OK', queueSize: getTerminalQueueSize('BROEKMANDISTRI') }"
      [ngTemplateOutlet]="terminalStatusTemplate"></ng-container>
  </div>

  <ng-template #terminalStatusTemplate let-index="index" let-queueSize="queueSize" let-status="status"
               let-terminal="terminal">
    <div *ngIf="appContext.roadMessages"
         [ngClass]="queueSize>60?'text-danger': queueSize>40?'text-warning':'text-success'"
         class="col">
      <b class="px-2">{{ terminal }}</b>
      <span [ngClass]="queueSize>60?'bg-danger text-light': queueSize>40?'bg-warning':'bg-success'"
            class="badge rounded-pill">{{ queueSize }}</span>
    </div>
    <div *ngIf="!appContext.roadMessages"
         [ngClass]="queueSize>120?'text-danger': queueSize>70?'text-warning':'text-success'" class="col">
      <b class="px-2">{{ terminal }}</b>
      <span [ngClass]="queueSize>120?'bg-danger text-light': queueSize>70?'bg-warning':'bg-success'"
            class="badge rounded-pill">{{ queueSize }}</span>
    </div>
  </ng-template>

  <div class="declaration-block pt-2 pb-2">
    Overview of pending declarations today, meaning declarations that have not been answered for the past X hours or
    minutes as selected in the filter below.
  </div>

  <div class="row my-3 pb-1">
    <div class="col-auto me-n4 align-self-center" style="height: 1.5rem">
      <input (click)="allDeclarationsChecked() ? deselectAll() : selectAll()" [ngModel]="allDeclarationsChecked()"
             class="custom-check-input"
             type="checkbox">
    </div>
    <div class="col-md">
      <app-select [(ngModel)]="filter.declarationType" [nullOption]="true" [options]="declarationTypes"
                  class="input-group"></app-select>
    </div>
    <div class="col-md">
      <app-select [(ngModel)]="filter.terminal" [nullOption]="true" [options]="terminals"
                  class="input-group"></app-select>
    </div>
    <div class="col-md">
      <input [(ngModel)]="filter.minimumAge" [maxDecimals]="0" appDecimalNumber class="form-control">
    </div>
    <div class="col-md">
      <app-select [(ngModel)]="filter.minimumAgeUnit" [nullOption]="true" [options]="['minutes', 'hours']"
                  class="input-group"></app-select>
    </div>
    <div class="col-auto">
      <button (click)="resubmit()" [disabled]="noEntriesChecked()" class="btn btn-pending" style="min-width: 5rem"
              type="button">Resubmit
      </button>
    </div>
    <div class="col-auto">
      <button (click)="remove()" [disabled]="noEntriesChecked()" class="btn btn-pending" style="min-width: 5rem"
              type="button">Remove
      </button>
    </div>
  </div>
  <!--Messages -->
  <div class="row pending-header">
    <div class="col">Pending declarations (Total: {{ getPendingDeclarations().length }})</div>
  </div>

  <ng-container *ngIf="pendingDeclarations">
    <ng-container *ngFor="let pendingDeclaration of getPendingDeclarations(); trackBy:trackByHandlingId">
      <div class="row pending-checkbox">
        <div class="col-auto p-0 align-self-center">
          <input [(ngModel)]="pendingDeclaration['selected']" style="height: 2rem"
                 class="align-self-center custom-check-input" type="checkbox">
        </div>
        <div class="col">
          <div class="row pending h-100">
            <div class="col-sm-1">
              <div>{{ pendingDeclaration.type }}</div>
            </div>
            <div class="col-sm">
              <div>{{ pendingDeclaration.terminal }}</div>
            </div>
            <div class="col-sm">
              <div>{{ pendingDeclaration.visitId }}</div>
            </div>
            <div class="col-sm">
              <div><span
                *ngIf="pendingDeclaration.containerNumber">{{ pendingDeclaration.containerNumber }}</span><span
                *ngIf="!pendingDeclaration.containerNumber">{{ pendingDeclaration.visitId }}</span></div>
            </div>
            <div class="col-sm-3">
              <div>{{ pendingDeclaration.timestamp | timestamp }}</div>
            </div>
            <div class="col-sm-1">
              <div><span *ngIf="pendingDeclaration.modality === 'rail'"
                         class="fa fa-train ms-1 ps-1"
                         title="Rail"></span><span
                *ngIf="pendingDeclaration.modality === 'barge' || !pendingDeclaration.modality"
                class="fa fa-ship ms-1 ps-1" title="Barge"></span><span
                *ngIf="pendingDeclaration.modality === 'road'" class="fa fa-truck ms-1 ps-1" title="Road"></span>
              </div>
            </div>
            <div class="col-sm-auto">
              <a [href]="getLink(pendingDeclaration)" class="mb-2 text-decoration-none">
                <span class="fa fa-external-link-alt"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

