import {Component, Input} from '@angular/core';
import {CancelDetachedHandling} from "@portbase/hinterland-service-typescriptmodels";
import {sendCommand} from "../../../../common/utils";
import {AppContext} from "../../../../app-context";
import {EditModalService} from '../../../../components/modals/edit-modal/edit-modal.service';
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";

@Component({
  selector: 'app-cancel-handling-confirmation',
  templateUrl: './cancel-handling-confirmation.component.html',
  styleUrls: ['./cancel-handling-confirmation.component.scss']
})
export class CancelHandlingConfirmationComponent {

  @Input() data: ClosableModal;

  constructor(
    private readonly editModalService: EditModalService,
  ) {
  }

  cancelHandling() {
    sendCommand("com.portbase.hinterland.api.common.command.CancelAndDetachHandling",
      <CancelDetachedHandling>{
        voyageId: this.data['voyageId'],
        visitId: this.data['visitId'],
        handlingId: this.data['handlingId']
      },
      () => {
        AppContext.publishSuccessMessage('Handling cancelled.');
        this.editModalService.closeModal();
      },
      error => AppContext.publishErrorMessage(error)
    );
  }
}
