import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-visit-cancelled-warning-on-cancel',
  standalone: true,
  templateUrl: './visit-cancelled-warning-on-cancel.component.html',
  styleUrl: './visit-cancelled-warning-on-cancel.component.css'
})
export class VisitCancelledWarningOnCancelComponent {

  @Input() data;

  callback: (result: boolean) => any;

  keep() {
    this.callback(false);
  }

  confirmAndCancel() {
    this.callback(true);
  }

}
