<div class="nav-content">
  <div class="inner-section-header p-3">Messages (admin only)</div>

  <div *ngIf="voyage" class="d-flex flex-row px-3">
    <div class="flex-column col-3 p-0 label-text">Voyage ID</div>
    <div class="flex-column col-9 p-0">
      <a class="link-text" href="{{kibanaVoyageUrl}}" target="_blank">{{voyage.voyageId}}
        <span class="fal fa-up-right-from-square ps-2"></span></a>
    </div>
  </div>
  <div *ngIf="visit" class="d-flex flex-row px-3 mt-2">
    <div class="flex-column col-3 p-0 label-text">Call ID</div>
    <div class="flex-column col-9 p-0">
      <a class="link-text" href="{{kibanaVisitUrl}}" target="_blank">{{visit.visitId}}
      <span class="fal fa-up-right-from-square ps-2"></span></a>
    </div>
  </div>
  <div class="d-flex flex-row px-3 mt-2">
    <div class="flex-column col-3 p-0 label-text">Handling ID</div>
    <div class="flex-column col-9 p-0">
      <a class="link-text" href="{{kibanaHandlingUrl}}" target="_blank">{{handling.handlingId}}
        <span class="fal fa-up-right-from-square ps-2"></span></a>
    </div>
  </div>

  <div *ngIf="terminal.apiOnly" class="p-3 mt-3 normal-row-text"><b>There are no messages since the terminal for this handling only uses API</b></div>
  <div *ngIf="terminal.nonPcs && !terminal.apiOnly" class="p-3 mt-3 normal-row-text"><b>There are no messages since the terminal for this handling is not connected to Portbase</b></div>
  <div *ngIf="messageLogItems.length !== 0" class="d-flex flex-row p-3 mt-3 history-header">
    <div class="flex-column col-3 p-0 header-text">Timestamp</div>
    <div class="flex-column col-8 p-0 header-text">Action</div>
    <div class="flex-column col-1 p-0"></div>
  </div>
  <div *ngFor="let item of messageLogItems; let index = index" class="d-flex flex-row p-3 history-row">
    <div class="flex-column col-3 p-0">
      <div class="normalRowText">{{item.formattedDate}}</div>
      <div class="secondaryRowText mt-1">{{item.formattedTime}}</div>
    </div>
    <div class="flex-column col-8 p-0">
      <div class="normalRowText">{{item.action}}</div>
      <div class="secondaryRowText mt-1">By {{item.organisation}}</div>
    </div>
    <div class="flex-column d-flex col-1 p-0 align-right">
      <div class="btn btn-tertiary fixed-button-width" aria-expanded="false" data-bs-toggle="dropdown">
        <i class="fa fa-ellipsis-vertical fa-lg grey-icon"></i>
      </div>
      <div class="dropdown-menu dropdown-menu-end">
        <a (click)="openPcsMessageManagement(item)" class="dropdown-item" title="View in PCS Message Management">View in PCS Message Management</a>
      </div>
    </div>
  </div>
</div>
