import {
  ArrayTemplate,
  Cell,
  HardCodedField,
  MappedField,
  MappedDependantField,
  parseExcel,
  RequiredField,
  RequiredIfFieldEmpty,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {sendCommand, sendQuery, uuid} from "../../common/utils";
import {
  AddTimetableEntries,
  GetDeclarantByEan,
  GetDeclarantByShortName,
  GetRailInterTerminal
} from "@portbase/hinterland-service-typescriptmodels";
import {AppContext} from "../../app-context";
import {map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import moment from "moment";
import {HinterlandUtils} from "../../hinterland/hinterland-utils";
import {TerminalOrganisation} from "@portbase/hinterland-service-typescriptmodels/hinterland";

export interface TimetableUploadData {
  terminal: TerminalOrganisation;
  startDate: string;
  file: File;
  fileName: string;
}

export class RailTimetableUpload {
  static dateFormat:string = "DD-MM-YYYY";
  static timeFormat:string = "HH:mm";

  getVersion(): string {
    return "Timetable template v1.2";
  }

  getRowCount(): number {
    return 200;
  }

  getTemplate(data: TimetableUploadData): WorkBookTemplate {
    return {
      sheets: [
        {
          name: "Guide",
          template: {
            version: new ValidatedField(new RequiredField('C1'), value => {
              if (value !== this.getVersion()) {
                throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
              }
            })
          }
        },
        {
          name: "Timetable slots",
          template: {
            updates: new ArrayTemplate({
              "@class": new HardCodedField("com.portbase.hinterland.api.refdata.command.AddTimetableEntry"),
              entity: {
                id: new HardCodedField(() => uuid()),
                terminal: new HardCodedField(data.terminal),
                code: new RequiredField("B$"),
                declarant: new MappedDependantField(new RequiredIfFieldEmpty("C$", ["K$"]), new RequiredIfFieldEmpty("K$", ["C$"]), this.declarantMapper),
                totalDischarge: new MappedField(new RequiredField("D$"), this.numberMapper),
                totalLoading: new MappedField(new RequiredField("E$"), this.numberMapper),
                window: {
                  start: {
                    day: new MappedField(new RequiredField("F$"), this.dayOfWeekMapper),
                    time: new MappedField(new RequiredField("G$"), this.timeMapper)
                  },
                  end: {
                    day: new MappedField(new RequiredField("H$"), this.dayOfWeekMapper),
                    time: new MappedField(new RequiredField("I$"), this.timeMapper)
                  }
                },
                startDate: new HardCodedField(data.startDate),
                terminated: new MappedField("J$", this.yesMapper)
              }
            }, [4, this.getRowCount()])
          }
        }
      ]
    }
  }

  upload(data: TimetableUploadData, successHook) {
    AppContext.clearAlerts();
    return parseExcel(data.file, this.getTemplate(data)).subscribe(model => {
        const typedModel = model as AddTimetableEntries;
        if (typedModel.updates.length === 0) {
          AppContext.registerError('No timetable entries found in uploaded excel');
          return;
        }
        const command = {
          updates: typedModel.updates,
          checkValidity: true
        } as AddTimetableEntries;
        sendCommand('com.portbase.common.api.refdata.command.AddTimetableEntries', command, () => {
          successHook();
          AppContext.registerSuccess(model.updates.length + ' timetable entries were added successfully');
        }, (error) => {
          let errorMessage = 'Error(s) occurred during adding of timetable entries: ';
          if (error instanceof HttpErrorResponse) {
            let httpErrors = AppContext.getHttpErrors(error);
            httpErrors.forEach(e => errorMessage += `${e}. `)
            AppContext.registerError(errorMessage);
          } else {
            AppContext.registerError(errorMessage + error);
          }
        });
      }
    );
  }

  yesMapper(value:string){
    return value != null && value.toUpperCase() == "YES";
  }

  declarantMapper(shortName:string, ean:string, shortNameCell:Cell, eanCell:Cell) {

    function getDeclarantByEan() {
      return sendQuery('com.portbase.hinterland.api.common.query.GetDeclarantByEan', <GetDeclarantByEan>{ean: ean, modality: "rail"})
        .pipe(map(declarant => {
          if (!declarant) {
            throw `Cell ${eanCell.cell} in sheet "${eanCell.sheetName}" contains an unknown declarant: ${ean}`
          }
          return declarant;
        }));
    }

    function getDeclarantByShortName() {
      return sendQuery('com.portbase.hinterland.api.common.query.GetDeclarantByShortName', <GetDeclarantByShortName>{shortName: shortName})
        .pipe(map(declarant => {
          if (!declarant) {
            throw `Cell ${shortNameCell.cell} in sheet "${shortNameCell.sheetName}" contains an unknown declarant: ${shortName}`
          }
          return declarant;
        }));
    }

    if(!shortName) {
      if (!ean) {
        return null;
      }
      return getDeclarantByEan();
    }
    return getDeclarantByShortName();
  }

  dayOfWeekMapper(dayOfWeek:string, cell: Cell): string {
    if (!dayOfWeek) {return null;}
    if(!HinterlandUtils.weekdays.includes(dayOfWeek.toUpperCase())){
      throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an invalid day of week value: ${dayOfWeek}`
    }
    return dayOfWeek.toUpperCase();
  }

  numberMapper(value: any, cell: Cell): number {
    if (value === 0) { return 0 }
    if (!value) { return null; }
    const numberValue: number = parseInt(value);
    if (!(Number.isInteger(numberValue))) {
      throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an invalid number: ${value}`
    }
    return numberValue;
  }

  timeMapper(time:string, cell: Cell): string {
    if (!time) {return null;}
    if(!moment(time, RailTimetableUpload.timeFormat, true).isValid()){
      throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an invalid time value: ${time}`
    }
    return time;
  }

  railInterTerminalMapper(code:string, cell:Cell) {
    if (!code) {return null;}
    return sendQuery('com.portbase.hinterland.api.common.query.GetRailInterTerminal', <GetRailInterTerminal>{interTerminalCode:code})
      .pipe(map(terminal => {
      if (!terminal) {
        throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown inter-terminal location code: ${code}`
      }
      return terminal;
    }));
  }
}
