import * as jsonPatch from "fast-json-patch";
import {JsonPatchError} from "fast-json-patch";
import moment from 'moment';
import {InjectorProvider} from "./injector-provider";
import {EventGateway, EventType} from "./event-gateway";
import * as Sentry from '@sentry/browser';
import {environment} from "../../environments/environment";

export function applyJsonPatch(object:any, patch:any):boolean {
  if (!isPatchApplicableForObject(object, patch)) {
    return false;
  }

  const appliedPatchItems:any[] = applyJsonPatchLazy(object, patch);
  const uniqueChangedPaths:string[] = getUniqueChangedPaths(object, appliedPatchItems, true);
  for (const path of uniqueChangedPaths) {
    const pathSplitted:string[] = path.split('/');

    if (pathSplitted.length === 4 && pathSplitted[0] === 'visits' && pathSplitted[2] === 'handlings') {
      //Replacing a voyage's handling gives Angular template rendering issues on old UI screens, which would take way too much time to fix properly, so we have this workaround instead
      //Example: visits/0/handlings/0
      continue;
    }

    replaceForChangeDetection(object, pathSplitted);
  }

  return appliedPatchItems.length !== 0;
}

export function applyJsonPatchNewMca(object:any, patch:any) {
  if (!isPatchApplicableForObject(object, patch)) {
    return false;
  }

  let appliedPatchItems:any[] = [];
  try {
    appliedPatchItems = applyJsonPatchLazy(object, patch);
  } catch (error) {
    // cannot handle further after JsonPatchError
  }

  const uniqueChangedPaths:string[] = getUniqueChangedPaths(object, appliedPatchItems, false);
  const eventGateway:EventGateway = InjectorProvider.injector.get(EventGateway);
  for (const path of uniqueChangedPaths) {
    const pathSplitted:string[] = path.split('/');
    tryPublishUpdatedEvents(object, pathSplitted, eventGateway);
  }

  return appliedPatchItems.length !== 0;
}

function isPatchApplicableForObject(object:any, patch:any): boolean {
  if (object.updated === patch.applyOnUpdatedTimestamp) {
    return true;
  }

  const updated = moment(object.updated);
  const applyOnUpdated = moment(patch.applyOnUpdatedTimestamp);
  const msDifference = applyOnUpdated.diff(updated, 'ms', true);
  if (msDifference > 0) {
    console.warn('Applying Patch with timestamp after objects timestamp (ms difference: ' + msDifference + ')');
    return true;
  } else {
    console.warn('Skipping Patch with timestamp before objects timestamp (ms difference: ' + msDifference + ')');
    return false;
  }
}

/**
 Applies patch items one by one.
 When one or more patch operations fail it will not result in the whole patch failing (which is what would happen when using the .applyPatch() method)

 An example when this could occur is when you programmatically modify a voyage/visit/handling in the frontend, for example by removing a property.
 Note: You shouldn't do that!!! But at least the patching mechanism is still robust enough to deal with that.
 **/
function applyJsonPatchLazy(object:any, patch:any):any[] {
  const appliedPatchItems = [];
  const errorPatchItems = [];

  for (let i = 0; i < patch.value.length; i++) {
    const patchItem = patch.value[i];
    const error:JsonPatchError = jsonPatch.validate([patchItem], object);
    if (error) {
      errorPatchItems.push(patchItem);
      console.debug(error);

      if(environment.production) {
        Sentry.captureException(error)
      }
    } else {
      jsonPatch.applyOperation(object, patchItem, false, true, true);
      appliedPatchItems.push(patchItem);
    }
  }

  if (errorPatchItems.length > 0) {
    console.warn(errorPatchItems.length + ' errors occurred while applying json patch');
    console.debug(errorPatchItems);
  }

  return appliedPatchItems;
}

/**
 * Build a list of unique paths to replace, mainly to circumvent replacing the same object/array multiple times
 */
function getUniqueChangedPaths(object:any, appliedPatchItems:any[], ignoreRootChanges:boolean) {
  const uniqueChangedPaths: string[] = [];
  for (const patchItem of appliedPatchItems) {
    const path = getPathToParentObjectOrArray(object, patchItem, ignoreRootChanges);
    if (path !== undefined && !uniqueChangedPaths.includes(path)) {
      uniqueChangedPaths.push(path);
    }
  }
  uniqueChangedPaths.sort(function(pathA:string, pathB:string) {
    return pathB.length - pathA.length;
  });
  return uniqueChangedPaths;
}

/**
 * For a given patchItem path, gets the path to the deepest parent that is an object or array.
 * Example: replace with false: /visits/0/handlings/1/accepted  --> visits/0/handlings/1
 */
function getPathToParentObjectOrArray(object:any, patchItem:any, ignoreRootChanges:boolean): string {
  //TO-DO Can't you just get the path of the second-to-last item, which should always be a non-primitive and thus an object/array???

  const path:string = patchItem.path.substring(1);  //Remove leading /
  const pathSplitted:string[] = path.split('/');
  if (pathSplitted.length < 2) {
    if (ignoreRootChanges) {
      return undefined;
    } else {
      return '';
    }
  }

  //Remove trailing numeric. Example:
  // remove /visits/0/handlings/1/handlingDeclarations/0 --> we want the handlingDeclarations object.
  const lastPathItem = pathSplitted[pathSplitted.length - 1];
  if ((patchItem.op === 'remove' || patchItem.op === 'copy' || typeof patchItem.value === 'object' || Array.isArray(patchItem.value))
      && isNumeric(lastPathItem) && Number(lastPathItem) >= 0) {
    pathSplitted.pop();
  }

  //Rebuild path until a primitive value is encountered
  let resultPathSplitted: string[];
  let currentValue = object;
  let currentPathSplitted: string[] = [];
  for (const path of pathSplitted) {
    currentValue = currentValue[path];
    currentPathSplitted.push(path);
    if (Array.isArray(currentValue) || typeof currentValue === 'object') {
      resultPathSplitted = [...currentPathSplitted];
    } else {
      break;
    }
  }

  return resultPathSplitted.join('/');
}

function tryPublishUpdatedEvents(object:any, pathSplitted:string[], eventGateway:EventGateway) {
  if (pathSplitted.length === 1 && pathSplitted[0] === '') {
    if (object.voyageId !== undefined) {
      eventGateway.publish(EventType.VoyageUpdated, object.voyageId);
    } else if (object.handlingId !== undefined) {
      eventGateway.publish(EventType.HandlingUpdated, object.handlingId);
    }
  } else if (pathSplitted.length === 2 && pathSplitted[0] === 'visits') {
    const visitIndex = parseInt(pathSplitted[1], 10);
    const visit = object['visits'][visitIndex];
    if (visit && visit.visitId !== undefined) {
      eventGateway.publish(EventType.VisitUpdated, visit.visitId);
    }
  } else if (pathSplitted.length === 4 && pathSplitted[0] === 'visits' && pathSplitted[2] === 'handlings') {
    const visitIndex = parseInt(pathSplitted[1], 10);
    const handlingIndex = parseInt(pathSplitted[3], 10);
    const handling = object['visits'][visitIndex]['handlings'][handlingIndex];
    if (handling && handling.handlingId !== undefined) {
      eventGateway.publish(EventType.HandlingUpdated, handling.handlingId);
    }
  }
}

function replaceForChangeDetection(object:any, pathSplitted:string[]) {
  if (pathSplitted.length === 1 && pathSplitted[0] === '') {
    replaceObjectInstance(object, []);
    return;
  }

  let lastObjectOrArray = object;
  for (const path of pathSplitted) {
    lastObjectOrArray = lastObjectOrArray[path];
  }
  if (Array.isArray(lastObjectOrArray)) {
    replaceArrayInstance(object, pathSplitted);
  } else if (typeof lastObjectOrArray === 'object') {
    replaceObjectInstance(object, pathSplitted);
  }
}

function replaceObjectInstance(object:any, paths:string[]) {
  switch (paths.length) {
    case 0:
      object = {...object};
      break;
    case 1:
      object[paths[0]] = {...object[paths[0]]};
      break;
    case 2:
      object[paths[0]][paths[1]] = {...object[paths[0]][paths[1]]};
      break;
    case 3:
      object[paths[0]][paths[1]][paths[2]] = {...object[paths[0]][paths[1]][paths[2]]};
      break;
    case 4:
      object[paths[0]][paths[1]][paths[2]][paths[3]] = {...object[paths[0]][paths[1]][paths[2]][paths[3]]};
      break;
    case 5:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]] = {...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]]};
      break;
    case 6:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]] = {...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]]};
      break;
    case 7:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]] = {...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]]};
      break;
    case 8:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]] = {...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]]};
      break;
    case 9:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]][paths[8]] = {...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]][paths[8]]};
      break;
  }
}

function replaceArrayInstance(object:any, paths:string[]) {
  switch (paths.length) {
    case 0:
      object = [...object];
      break;
    case 1:
      object[paths[0]] = [...object[paths[0]]];
      break;
    case 2:
      object[paths[0]][paths[1]] = [...object[paths[0]][paths[1]]];
      break;
    case 3:
      object[paths[0]][paths[1]][paths[2]] = [...object[paths[0]][paths[1]][paths[2]]];
      break;
    case 4:
      object[paths[0]][paths[1]][paths[2]][paths[3]] = [...object[paths[0]][paths[1]][paths[2]][paths[3]]];
      break;
    case 5:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]] = [...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]]];
      break;
    case 6:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]] = [...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]]];
      break;
    case 7:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]] = [...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]]];
      break;
    case 8:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]] = [...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]]];
      break;
    case 9:
      object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]][paths[8]] = [...object[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]][paths[8]]];
      break;
  }
}

function isNumeric(input) {
  return !isNaN(parseFloat(input)) && isFinite(input);
}
