<app-main-panel class="new-mca">
  <ng-container topTitle>
    <span class="hide-md-screen">Hinterland - </span>Road
  </ng-container>

  <ng-container *ngIf="appContext.isHinterlandDeclarant()" topContentEnd>
    <button (click)="createHandling();" app-button iconPosition="icon-start">
      <i class="button-icon fa-light fa-plus pe-2"></i> Add handling
    </button>
  </ng-container>

  <app-main-panel-road id="main-menu-screenshot-root"></app-main-panel-road>

</app-main-panel>
