import {Component, Input} from '@angular/core';
import {UiStop} from '../cargo-shipment/cargo-shipment.component';

@Component({
  selector: 'app-stop',
  templateUrl: './stop.component.html',
  styleUrls: ['./stop.component.scss']
})
export class StopComponent {
  @Input() stop: UiStop;
  @Input() type : StopType;
  @Input() arrived: boolean;

  get timestamp() {
    switch (this.type) {
      case 'first': return this.stop.atd || this.stop.etd;
      case 'inner': return this.stop.atd || (this.stop.ata > this.stop.etd ? this.stop.ata : this.stop.etd) || this.stop.eta;
      case 'last': return this.stop.ata || this.stop.eta;
    }
  }
}

export type StopType = 'first' | 'inner' | 'last';

