import {Component, Input, OnInit} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";

@Component({
  selector: 'app-copy-visit-as-text-dialog',
  templateUrl: 'copy-visit-as-text-dialog.component.html',
  styleUrls: ['copy-visit-as-text-dialog.scss']
})
export class CopyVisitAsTextDialogComponent implements OnInit {

  appContext = AppContext;

  @Input() data: ClosableModal;

  text: string;
  formattedText: string;

  ngOnInit() {
    this.text = this.data['text']
    this.formattedText = this.text.replace(new RegExp('\n', 'g'), "<br />");
  }

  copy() {
    navigator.clipboard.writeText(this.text);
    AppContext.publishSuccessMessage("Visit successfully copied to clipboard", "Visit copied");
  }
}
