<div class="alert alert-warning mb-2" *ngIf="showDateRangeWarning">Choosing a wide date range can cause longer load times.</div>

<div class="row narrow-gutters">
  <div class="col my-0 my-1 date-filter">
    <app-date-range [ngModel]="dateRange" (ngModelChange)="onDateSelection($event)"></app-date-range>
  </div>
  <div class="col my-0 my-1 text-filter">
    <app-filter [(ngModel)]="filterTerm" [searchFunction]="searchOrFilter" (resultsFound)="renderFilteredItems()"
                placeholder="Filter by container number, reference, etc..."></app-filter>
  </div>
  <div class="col-auto my-0 my-1" *ngIf="appContext.isRailDeclarant() && appContext.isBargeDeclarant()">
    <app-modality-toggle [visibleModalities]="this.appContext.isFiltering('Barge') ? 'Barge' : this.appContext.isFiltering('Rail') ? 'Rail' : 'all'"></app-modality-toggle>
  </div>
  <div class="col-auto my-0 my-1" *ngIf="this.appContext.isHinterlandDeclarant()">
    <button (click)="addHandling(); detachedCollapse.toggle(true, true)" class="btn btn-info" tabindex="-1"><span class="pe-2 fa fa-plus-circle"></span> Add handling</button>
  </div>
  <div class="col-auto my-0 my-1" *ngIf="!appContext.isPhone() && (appContext.isBargeDeclarant() || appContext.isRailDeclarant())">
    <ng-container>
     <span class="dropdown mx-1">
        <button class="btn btn-info dropdown-toggle" type="button" data-bs-toggle="dropdown" style="width: 12rem">
          <span class="pe-2 fa fa fa-file-upload"></span> Upload handlings
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li *ngIf="appContext.isBargeDeclarant()">
            <button class="dropdown-item" type="button" (click)="uploadBarge.click()">Detached Barge handlings
              <input type="file" hidden (change)="uploadDetachedHandlings($event.target.files[0], 'barge'); $event.target.value = '';" #uploadBarge>
            </button>
          </li>
          <li *ngIf="appContext.isRailDeclarant()">
            <button class="dropdown-item" type="button" (click)="uploadRail.click()">Detached Rail handlings
              <input type="file" hidden (change)="uploadDetachedHandlings($event.target.files[0], 'rail'); $event.target.value = '';" #uploadRail>
            </button>
          </li>
        </ul>
      </span>
    </ng-container>
  </div>
</div>

<div class="row narrow-gutters" *ngIf="!appContext.isPhone() && (appContext.isBargeDeclarant() || appContext.isRailDeclarant())">
  <div class="col-md-4 mb-2 mb-md-0" style="min-width: 19rem"></div>
  <div class="col"></div>
  <div class="col-auto" *ngIf="appContext.isBargeDeclarant()"><a href="/assets/templates/barge-upload-template-2.4.xlsx" class="align-middle pe-2 text-portbase-dark-blue small ms-2"
                           tabindex="-1">Download Barge XLS template <span class="fa fa-file-download"></span></a></div>
  <div class="col-auto" *ngIf="appContext.isRailDeclarant()"><a href="/assets/templates/rail-upload-template-2.5.xlsx" class="align-middle pe-2 text-portbase-dark-blue small ms-2"
                           tabindex="-1">Download Rail XLS template <span class="fa fa-file-download"></span></a></div>
</div>

<app-scroller (endReached)="loadAndRenderNextPage()">
  <div class="my-3">
    <app-collapse #detachedCollapse [collapse]="collapse" [showOnLoad]="false" [animateOnLoad]="false">
      <div class="collapsible py-2 px-3 border border-secondary">
        <div class="row">
          <div class="col-auto">
            <h4 class="m-0 fa fa-chevron-right text-dark"></h4>
          </div>
          <div class="col fw-bold text-dark">Detached ({{pagingSupported && !endReached ? '> ' : ''}}{{detached.length}})
            <div class="float-end">
              <div class="custom-check d-inline-block">
                <input id="excludeEmptyEta" [(ngModel)]="excludeEmptyEta" (click)="$event.stopPropagation()"
                       type="checkbox">
              </div>
              <label class="ms-2 d-inline-block" for="excludeEmptyEta" (click)="$event.stopPropagation()">Exclude empty ETA</label>
            </div>
          </div>

        </div>
      </div>
      <ng-template #collapse>
        <div class="collapse px-sm-3">
          <div class="mt-2 handlings-container" *ngIf="detached.length > 0">
            <app-infinite-scroller [initialCount]="100">
              <app-handlings [hideAccepted]="true" [setHandlings]="detached" [displayVisitInfo]="this.appContext.isHinterlandTerminal()"
                             (deleteHandling)="deleteHandling($event)"></app-handlings>
            </app-infinite-scroller>
          </div>
        </div>
      </ng-template>
    </app-collapse>
    <div [ngTemplateOutlet]="category"
         [ngTemplateOutletContext]="{ items : expected, category: 'Expected', showOnLoad: true, displayVisitInfo: true}"></div>
    <div [ngTemplateOutlet]="category"
         [ngTemplateOutletContext]="{ items : inOperation, category: 'In operation', showOnLoad: true, displayVisitInfo: true}"></div>
    <div [ngTemplateOutlet]="category"
         [ngTemplateOutletContext]="{ items : completed, category: 'Completed', showOnLoad: false, displayVisitInfo: true}"></div>
  </div>
</app-scroller>

<ng-template #category let-items="items" let-category="category" let-showOnLoad="showOnLoad" let-displayVisitInfo="displayVisitInfo">
  <div class="my-3">
    <app-collapse [collapse]="collapse" [showOnLoad]="showOnLoad" [animateOnLoad]="false">
      <div class="collapsible py-2 px-3 border border-secondary">
        <div class="row">
          <div class="col-auto">
            <h4 class="m-0 fa fa-chevron-right text-dark"></h4>
          </div>
          <div class="col fw-bold text-dark">{{category | titlecase}} ({{pagingSupported && !endReached ? '> ' : ''}}{{countHandling(items)}})</div>
        </div>
      </div>
      <ng-template #collapse>
        <div class="collapse px-sm-2">
          <div *ngIf="items['length'] > 0" class="pt-3">
            <ng-container *ngFor="let voyage of items; trackBy: trackByVoyageId">
              <app-collapse [collapse]="voyagecollapse"
                            (show)="fetchHandlings(voyage)" (hide)="voyage['handlings'] = undefined">
                <app-voyage-summary [voyage]="voyage" [summaryContext]="'overview'" class="collapsible">
                  <h4 class="m-0 fa fa-chevron-right text-secondary"></h4>
                </app-voyage-summary>
              </app-collapse>
              <ng-template #voyagecollapse>
                <div class="collapse">
                  <div class="px-sm-3 mt-3 mb-5 handlings-container">
                    <app-handlings [hideAccepted]="true" [setHandlings]="voyage['handlings']"
                                   [displayVisitInfo]="displayVisitInfo" (deleteHandling)="deleteHandling($event)">
                    </app-handlings>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-collapse>
  </div>
</ng-template>
