import {
  ArrayTemplate,
  Cell,
  DateField,
  HardCodedField,
  MappedField,
  NonNegativeQuantityField,
  parseExcel,
  RequiredField,
  RequiredIfField,
  RequiredIfFieldEmpty,
  TimeField,
  toBase64,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {sendCommand, sendQuery, uuid} from '../../common/utils';
import {AppContext} from '../../app-context';
import {CreateRoadVoyage, DeclareHandling, DeclareHandlings, GetRoadVoyageByTarAndTerminal} from "@portbase/hinterland-service-typescriptmodels";
import {map} from "rxjs/operators";
import {HinterlandUtils} from "../../hinterland/hinterland-utils";
import moment from "moment";
import {HandlingsUpload, UploadData} from "../../common/upload/handlings.upload";
import {HttpErrorResponse} from "@angular/common/http";

export interface RoadUploadData extends UploadData {
  preNotification: boolean;
}

export class RoadHandlingsUpload extends HandlingsUpload {
  getRowCount(): number {
    return 300;
  }

  getVersion(): string {
    return "Road v2.5";
  }

  getTemplate(forDetached: boolean = false): WorkBookTemplate {
    return {
      sheets: [
        {
          name: "Guide",
          template: {
            version: new ValidatedField('C1', value => {
              if (value !== this.getVersion()) {
                throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
              }
            })
          }
        },
        {
          name: "Pickup",
          template: {
            containers: new ArrayTemplate({
              terminal: new MappedField(new RequiredField("C$"), this.roadTerminalMapper),
              visitStatus: {
                tar: "E$"
              },
              visitData: <any>{
                etaDate: new DateField(new RequiredIfFieldEmpty("F$", ["E$"]), "YYYYMMDD"),
                etaTime: new TimeField(new RequiredIfFieldEmpty("G$", ["E$"]), "HHmm")
              },
              handlingData: {
                modality: new HardCodedField("road"),
                type: new HardCodedField("loading"),
                shippingCompany: new MappedField(new RequiredField("A$"), this.shippingCompanyMapper),
                full: new MappedField(new RequiredField("B$"), this.fullEmptyMapper),
                bookingNumber: new MappedField("D$", this.toUpperCaseMapper),
                equipmentNumber: new RequiredIfField(new MappedField("H$", this.toUpperCaseMapper), [new MappedField("B$", this.fullEmptyMapper)], value => !!value),
                shippersOwned: new MappedField("H$", this.shippersOwnedMapper),
                sizeType: new MappedField(new RequiredField("I$"), this.sizeTypeMapper),
                transportNumber: "J$",
              }
            }, [4, this.getRowCount()])
          }
        },
        {
          name: "Delivery",
          template: {
            containers: new ArrayTemplate({
              terminal: new MappedField(new RequiredField("C$"), this.roadTerminalMapper),
              visitStatus: {
                tar: "E$"
              },
              visitData: <any>{
                etaDate: new DateField(new RequiredIfFieldEmpty("F$", ["E$"]), "YYYYMMDD"),
                etaTime: new TimeField(new RequiredIfFieldEmpty("G$", ["E$"]), "HHmm")
              },
              handlingData: {
                modality: new HardCodedField("road"),
                type: new HardCodedField("discharge"),
                shippingCompany: new MappedField(new RequiredField("A$"), this.shippingCompanyMapper),
                full: new MappedField(new RequiredField("B$"), this.fullEmptyMapper),
                bookingNumber: new MappedField("D$", this.toUpperCaseMapper),
                equipmentNumber: new RequiredIfField(new MappedField("H$", this.toUpperCaseMapper), [new MappedField("B$", this.fullEmptyMapper)], value => !!value),
                shippersOwned: new MappedField("H$", this.shippersOwnedMapper),
                sizeType: new MappedField(new RequiredField("I$"), this.sizeTypeMapper),
                weight: new NonNegativeQuantityField("J$"),
                sealNumber: new MappedField("K$", this.emptyStringToNullMapper),
                outOfGauge: {
                  height: new NonNegativeQuantityField("L$"),
                  left: new NonNegativeQuantityField("M$"),
                  right: new NonNegativeQuantityField("N$"),
                  front: new NonNegativeQuantityField("O$"),
                  rear: new NonNegativeQuantityField("P$")
                },
                reefer: {
                  temperatureFixed: new MappedField("Q$", this.temperatureFixedMapper),
                  temperature: new NonNegativeQuantityField("Q$"),
                  minimumTemperature: new NonNegativeQuantityField(new RequiredIfField("R$", ["S$"])),
                  maximumTemperature: new NonNegativeQuantityField(new RequiredIfField("S$", ["R$"]))
                },
                transportNumber: "T$",
              }
            }, [4, this.getRowCount()])
          }
        }
      ]
    }
  }

  upload(excelFile: File, data: RoadUploadData, errorHandler?: (error: any) => void, successHandler?: () => void) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
        parseExcel(excelFile, this.getTemplate()).subscribe(model => {
            if (model.containers.length === 0) {
              let error = 'No containers found in uploaded excel';
              if (errorHandler) errorHandler(error);
              AppContext.registerError(error);
              return;
            }

            model.containers.filter(container => container.terminal).forEach(container => {
              if (container.visitStatus && container.visitStatus.tar) {
                this.declareHandlingByTar(container, excelBase64, errorHandler, successHandler);
              } else {
                this.createRoadVoyage(container, excelBase64, errorHandler, successHandler);
              }
            });
          },
          errorHandler ? errorHandler : (error) => {
          }
        );
      },
      errorHandler ? errorHandler : (error) => {
      }
    );
  }

  uploadDetached(excelFile: File, data: UploadData, errorHandler?: (error: any) => void, successHandler?: () => void) {
    AppContext.clearAlerts();
    toBase64(excelFile).subscribe(excelBase64 => {
      parseExcel(excelFile, this.getTemplate()).subscribe(model => {
        if (model.containers.length === 0) {
          let errorMessage = 'No containers found in uploaded excel';
          if (errorHandler) errorHandler(errorMessage);
          AppContext.registerError(errorMessage);
          return;
        }
        if (this.validateRoadStatusRequestAllowed(model, errorHandler)) {
          this.declareDetachedHandlings(model, excelBase64, errorHandler, successHandler);
        }
      });
    });
  }

  declareDetachedHandlings(model, excelBase64, errorHandler?: (error: any) => void, successHandler?: () => void) {
    model.containers.forEach((handling) => {
      handling.eta = moment(handling.visitData.etaDate + ' ' + handling.visitData.etaTime, "YYYY-MM-DD HH:mm", true).toISOString(true);
      handling.handlingId = uuid();
      handling.declarant = AppContext.userProfile.organisation
    });

    const command = {
      '@class': 'io.fluxcapacitor.javaclient.common.Message',
      payload: <DeclareHandlings>{
        handlings: model.containers
      },
      metadata: {
        'upload': excelBase64
      }
    }
    sendCommand('com.portbase.hinterland.api.detached.command.DeclareDetachedHandlings', command, () => {
      if (successHandler) successHandler();
      AppContext.registerSuccess('Detached handlings were declared successfully');
    }, (error) => {
      let errorMessage = 'Error(s) occurred during declaring of detached handlings: ';
      if (error instanceof HttpErrorResponse) {
        const httpErrors: Array<string> = AppContext.getHttpErrors(error);
        httpErrors.forEach(e => errorMessage += `${e}. `)
        if (errorHandler) errorHandler(errorMessage);
        AppContext.registerError(errorMessage);
      } else {
        if (errorHandler) errorHandler(errorMessage + error);
        AppContext.registerError(errorMessage + error);
      }
    });
  }

  declareHandlingByTar(container, excelBase64, errorHandler?: (error: any) => void, successHandler?: () => void) {
    this.getVoyageByTarAndTerminal(container.visitStatus.tar, container.terminal.shortName).subscribe((voyage) => {
      const declareHandling = <DeclareHandling>{
        voyageId: voyage.voyageId,
        visitId: voyage.visits[0].visitId,
        handlingId: uuid(),
        declarant: AppContext.userProfile.organisation,
        visitStatus: container.visitStatus,
        visitData: {
          modality: "road",
          eta: voyage.visits[0].visitData.eta,
        },
        handlingData: container.handlingData,
        preNotification: true,
        terminal: container.terminal,
        declarationId: null,
        processId: null,
        shipmentId: null,
        deepSea: null,
        transportOrder: null,
      };

      const command = {
        '@class': 'io.fluxcapacitor.javaclient.common.Message',
        payload: declareHandling,
        metadata: {
          'upload': excelBase64
        }
      };

      sendCommand('com.portbase.hinterland.api.common.command.DeclareHandling', command, () => {
        AppContext.registerSuccess('Container ' + container.handlingData.bookingNumber + (container.handlingData.equipmentNumber ? ', equipmentNr: ' + container.handlingData.equipmentNumber : '') + ' succesfully added to existing visit');
        if (successHandler) successHandler();
      }, (error) => {
        let errorMessage = 'Handling with bookingnumber: ' + container.handlingData.bookingNumber
          + (container.handlingData.equipmentNumber ? ', equipmentNr: '
            + container.handlingData.equipmentNumber : '')
          + (container.handlingData.sizeType ? ' and sizeType: '
            + container.handlingData.sizeType.code : '')
          + (container.terminal ? ' for terminal ' + container.terminal.shortName : '') + ' failed with error:' + JSON.stringify(error?.error?.error);
        if (errorHandler) errorHandler(errorMessage);
        AppContext.registerError(errorMessage);
      });
    }, () => {
      let errorMessage = 'TAR not found for handling with bookingnumber: ' + container.handlingData.bookingNumber + (container.handlingData.equipmentNumber ? ', equipmentNr: ' + container.handlingData.equipmentNumber : '') + (container.handlingData.sizeType ? ' and sizeType: ' + container.handlingData.sizeType.code : '') + (container.terminal ? ' for terminal ' + container.terminal.shortName : '');
      if (errorHandler) errorHandler(errorMessage);
      AppContext.registerError(errorMessage);
    });
  }

  createRoadVoyage(container, excelBase64, errorHandler?: (error: any) => void, successHandler?: () => void) {
    HinterlandUtils.getNextTripNumber().pipe(map(t => {
      return t
    })).subscribe(tripNumber => {
      HinterlandUtils.getNextVisitId().subscribe(visitId => {
        const createRoadVoyage = <CreateRoadVoyage>{
          voyageId: uuid(),
          tripNumber: tripNumber,
          visitId: visitId,
          handlingId: uuid(),
          declarant: AppContext.userProfile.organisation,
          visitStatus: container.visitStatus,
          visitData: {
            modality: "road",
            eta: (container.visitData ? moment(container.visitData.etaDate + container.visitData.etaTime, "YYYYMMDDHHmm").toISOString() : null),
            charter: null
          },
          handlingData: container.handlingData,
          terminal: container.terminal,
          declarationId: null,
          processId: null,
          truckLicenseId: null,
          charter: null,
        };

        const command = {
          '@class': 'io.fluxcapacitor.javaclient.common.Message',
          payload: createRoadVoyage,
          metadata: {
            'upload': excelBase64
          }
        };

        sendCommand('com.portbase.hinterland.api.road.command.CreateRoadVoyage', command, () => {
          AppContext.registerSuccess('Container ' + container.handlingData.bookingNumber + (container.handlingData.equipmentNumber ? ', equipmentNr: ' + container.handlingData.equipmentNumber : '') + ' was imported successfully');
          if (successHandler) successHandler();
        }, (error) => {
          let errorMessage = 'Handling with bookingnumber: ' + container.handlingData.bookingNumber + (container.handlingData.equipmentNumber ? ', equipmentNr: ' + container.handlingData.equipmentNumber : '') + (container.handlingData.sizeType ? ' and sizeType: ' + container.handlingData.sizeType.code : '') + (container.terminal ? ' for terminal ' + container.terminal.shortName : '') + ' failed with error:' + JSON.stringify(error?.error?.error);
          if (errorHandler) errorHandler(errorMessage);
          AppContext.registerError(errorMessage);
        });
      }, (error) => {
        let errorMessage = 'No visitId for handling with bookingnumber: ' + container.handlingData.bookingNumber + (container.handlingData.equipmentNumber ? ', equipmentNr: ' + container.handlingData.equipmentNumber : '') + (container.handlingData.sizeType ? ' and sizeType: ' + container.handlingData.sizeType.code : '') + (container.terminal ? ' for terminal ' + container.terminal.shortName : '');
        if (errorHandler) errorHandler(errorMessage);
        AppContext.registerError(errorMessage);
      });
    });
  }

  roadTerminalMapper(code: string, cell: Cell) {
    if (!code) {
      return null;
    }
    code = code.slice(5);
    return sendQuery('com.portbase.hinterland.api.refdata.query.GetTerminalByCode', {code: code, modality: "road"})
      .pipe(map(terminal => {
        if (!terminal) {
          throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown terminal location (SMDG code unknown): ${code}`
        }
        return terminal;
      }));
  }

  temperatureFixedMapper(value: string, cell: Cell): boolean {
    return !!value;
  }

  getVoyageByTarAndTerminal(tar, terminal) {
    if (!tar) {
      return null;
    }
    return sendQuery('com.portbase.hinterland.api.road.query.GetRoadVoyageByTarAndTerminal', <GetRoadVoyageByTarAndTerminal>{tar: tar, terminal: terminal})
      .pipe(map(voyage => {
        if (!voyage) {
          throw `Tar ${tar} not found for terminal: ${terminal}`;
        }
        return voyage;
      }));
  }

  validateRoadStatusRequestAllowed(model, errorHandler?: (error: any) => void): boolean {
    const invalidTerminals = model.containers
      .filter(container => !container.terminal.roadStatusRequestAllowed)
      .map(container => container.terminal.quayName)
      .filter((val, index, self) => self.indexOf(val) == index); // this last filter removes duplicates
    if (invalidTerminals.length !== 0) {
      let errorMessage = "Terminals [" + invalidTerminals + "] don't support status requests";
      if (errorHandler) errorHandler(errorMessage);
      AppContext.registerError(errorMessage);
      return false;
    }
    return true;
  }
}
