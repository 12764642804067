import {Component, Input} from '@angular/core';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-modality-toggle',
  templateUrl: './modality-toggle.component.html',
  styleUrls: ['./modality-toggle.component.css']
})
export class ModalityToggleComponent {
  appContext = AppContext;
  @Input() visibleModalities: VisibleModalities;

  onModalityToggled = (modality: VisibleModalities) => {
    this.appContext.removePersistentFilter("Rail");
    this.appContext.removePersistentFilter("Barge");
    if (modality != "all") {
      this.appContext.togglePersistentFilter(modality);
    }
  }

}

export type VisibleModalities = "Rail" | "Barge" | "all";
