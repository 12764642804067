import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
  DateRange,
  DeclarantOrganisation,
  GetRailVoyageTemplates,
  RailVoyageTemplate,
  SaveRailVoyageTemplate
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {AppContext} from "../../app-context";
import {checkValidity, cloneObject, removeItem, sendCommand, sendQuery} from "../../common/utils";
import {of} from "rxjs";
import moment from "moment-timezone";
import {HinterlandUtils} from "../../hinterland/hinterland-utils";

@Component({
  selector: 'app-rail-voyage-template',
  templateUrl: './rail-voyage-template.component.html',
  styleUrls: ['./rail-voyage-template.component.css']
})
export class RailVoyageTemplateComponent implements OnInit {

  appContext = AppContext;

  @Input() sortBy: 'code' | 'codeReversed' | 'terminal' | 'terminalReversed' = 'terminal';

  template: RailVoyageTemplate;
  railVoyageTemplates: RailVoyageTemplate[] = [];
  selectedTemplates: String[] = [];

  newTemplateNumber = 0;
  searchResult: RailVoyageTemplate;
  showEntry = false;
  dateRange: DateRange = {start: moment().format('YYYY-MM-DD'), end: moment().add(7, 'd').format('YYYY-MM-DD')};
  amountOfVoyagesToBeCreated = 0;

  @Input() set setRailVoyageTemplates(railVoyageTemplates: RailVoyageTemplate[]) {
    this.railVoyageTemplates = railVoyageTemplates;
    this.determineNewTemplateNumber();
  }

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    sendQuery('com.portbase.hinterland.api.rail.query.GetRailVoyageTemplates', <GetRailVoyageTemplates>{}, {caching: false})
      .subscribe((railVoyageTemplates: RailVoyageTemplate[]) => {
        this.railVoyageTemplates = railVoyageTemplates;
        this.determineNewTemplateNumber();
      });
  }

  determineNewTemplateNumber = () => {
    this.newTemplateNumber = this.railVoyageTemplates.length > 0 ? 1 + this.railVoyageTemplates.reduce((highest, template) =>
      Math.max(this.getTemplateNumber(template.templateId), highest), 0) : 0;
  }

  cast = (item: RailVoyageTemplate): RailVoyageTemplate => item;

  search = term => {
    return of(this.railVoyageTemplates.filter(railVoyageTemplate => JSON.stringify(railVoyageTemplate).toLowerCase().indexOf(term.toLowerCase()) > -1));
  };

  getTemplateNumber = (templateId: string): number => {
    return templateId && templateId.indexOf("_") ? parseInt(templateId.split("_")[1]) : 0;
  }

  newEntry = () => {
    if (this.searchResult) {
      this.template = cloneObject(this.searchResult);
    } else {
      this.template = <RailVoyageTemplate>{
        templateId: AppContext.userProfile.organisationShortName + "_" + this.newTemplateNumber,
        declarant: AppContext.userProfile.organisation,
        cargoDeclarants: [AppContext.userProfile.organisation],
        modality: "rail",
        visits: [],
        voyageData: {firstDepartureDay: null, train: {}, modality: "rail"}
      }
      this.newTemplateNumber++;
    }
    this.searchResult = undefined;
  };

  cancel = () => {
    this.clear();
  };

  saveRailVoyageTemplate = () => {
    this.template.declarant = this.template.declarant || AppContext.userProfile.organisation;
    if (checkValidity(this.element)) {
      this.cleanUpEmptyCargoDeclarants();

      sendCommand('com.portbase.hinterland.api.rail.command.SaveRailVoyageTemplate', <SaveRailVoyageTemplate>{railVoyageTemplate: this.template}, () => {
        AppContext.registerSuccess('Rail rotation template was saved successfully.');
        this.railVoyageTemplates = this.railVoyageTemplates.filter(f => f.templateId != this.template.templateId);
        this.railVoyageTemplates.unshift(this.template);
        this.clear();
      });
    }
  };

  private cleanUpEmptyCargoDeclarants() {
    if (this.template.cargoDeclarants) {
      this.template.cargoDeclarants = this.template.cargoDeclarants.filter(c => !!c.shortName);
    }
  }

  deleteRailVoyageTemplate = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.rail.command.DeleteRailVoyageTemplate', {'templateId': this.template.templateId}, () => {
        AppContext.registerSuccess('Rail rotation template was deleted successfully.');
        this.railVoyageTemplates = this.railVoyageTemplates.filter(f => f.templateId != this.template.templateId);
        this.clear();
      });
    }
  };

  private clear = () => {
    this.showEntry = false;
    this.template = undefined;
    this.searchResult = undefined;
  };

  onEntrySelected(entry: RailVoyageTemplate) {
    this.searchResult = entry;
    this.template = entry;
  }

  declarantFormatter = (organisation: DeclarantOrganisation) => organisation.fullName;

  delayedShowEntry() {
    setTimeout(() => {
      this.showEntry = true
    }, 100);
  }

  allItemsSelected = (): boolean => {
    for (const railVoyageTemplate of this.railVoyageTemplates) {
      if (!this.isSelected(railVoyageTemplate)) {
        return false;
      }
    }

    return true;
  }

  selectAll = () => {
    for (const railVoyageTemplate of this.railVoyageTemplates) {
      if (!this.isSelected(railVoyageTemplate)) {
        this.selectItem(railVoyageTemplate);
      }
    }
  }

  deselectAll = () => {
    for (const railVoyageTemplate of this.railVoyageTemplates) {
      if (this.isSelected(railVoyageTemplate)) {
        this.deselectItem(railVoyageTemplate);
      }
    }
  }

  isSelected = (railVoyageTemplate: RailVoyageTemplate) => {
    return this.selectedTemplates.indexOf(railVoyageTemplate.templateId) !== -1;
  }

  selectItem = (railVoyageTemplate: RailVoyageTemplate) => {
    this.selectedTemplates.push(railVoyageTemplate.templateId);
  };

  deselectItem = (railVoyageTemplate: RailVoyageTemplate) => {
    removeItem(this.selectedTemplates, railVoyageTemplate.templateId);
  };

  generateVoyages() {
    sendCommand('com.portbase.hinterland.api.rail.command.GenerateRailVoyages',
      {
        'railVoyageTemplateIds': this.selectedTemplates,
        'dateRange': this.dateRange,
      }, () => {
        AppContext.registerSuccess(this.amountOfVoyagesToBeCreated + ' rail voyages were generated successfully.');
      }, () => {
        AppContext.registerError('Failed to generate the rail voyages. Please contact support.');
      });
  }

  calculateAmountOfVoyages(): number {
    if (!this.dateRange) {
      return null
    }
    let result = {'MONDAY': 0, 'TUESDAY': 0, 'WEDNESDAY': 0, 'THURSDAY': 0, 'FRIDAY': 0, 'SATURDAY': 0, 'SUNDAY': 0};
    let current = moment(this.dateRange.start, "YYYY-MM-DD");
    let end = moment(this.dateRange.end, "YYYY-MM-DD");
    do {
      result[HinterlandUtils.weekdays[(current.weekday() + 6) % 7]]++
    } while (!(current.add(1, "days").isAfter(end)));
    this.amountOfVoyagesToBeCreated = this.selectedTemplates.map(templateId => result[this.railVoyageTemplates.find(t => t.templateId == templateId).voyageData.firstDepartureDay]).reduce((a, b) => a + b, 0);
  }

  onDateSelection = (dateRange: DateRange): void => {
    this.dateRange = dateRange;
    this.calculateAmountOfVoyages();
  }
}
