import {
  BinnenhavengeldApplicationConfig,
  BinnenhavengeldSignalling,
  BinnenhavengeldSubscription,
  BinnenhavengeldUser,
  BinnenhavengeldUserProfile
} from '@portbase/hinterland-service-typescriptmodels';
import {BinnenhavengeldShip} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import moment from 'moment';

export class BinnenhavengeldMock {
  static applicationConfig: BinnenhavengeldApplicationConfig = {subscriptionCutoffDate: "2023-01-02"}
}

const adminProfile = <BinnenhavengeldUserProfile>{
  userId: "admin",
  organisationId: "admin",
  userRoles: ['BinnenhavengeldAdmin'],
  roles: ['BinnenhavengeldAdmin'],
  email: "bhg-admin@portbase.com",
  acceptedUserAgreements: [String(moment().year())],
  organisation: {
    organisationId: 'admin',
    roles: [],
    managedSkippers: []
  }
}

const agentProfile = <BinnenhavengeldUserProfile>{
  userId: "agent",
  organisationId: "agent",
  userRoles: [],
  roles: ['BinnenhavengeldAgent'],
  email: "bhg-agent@portbase.com",
  acceptedUserAgreements: [String(moment().year())],
  organisation: {
    organisationId: 'agent',
    organisationName: 'Agent',
    street: 'agent street',
    streetNumber: '1',
    city: 'agent city',
    country: 'Nederland',
    postalCode: '3011WS',
    roles: ['BinnenhavengeldAgent'],
    managedSkippers: [
      {
        organisationId: 'skipper1',
        organisationName: 'Skipper1',
        street: 'skipper street',
        streetNumber: '1',
        city: 'skipper city',
        country: 'Nederland',
        postalCode: '3011WX',
      },
      {
        organisationId: 'skipper2',
        organisationName: 'Skipper2',
        street: 'skipper street',
        streetNumber: '2',
        city: 'skipper city',
        country: 'Nederland',
        postalCode: '3011WY',
      }
    ]
  }
}

const skipperProfile = <BinnenhavengeldUserProfile>{
  userId: "skipper",
  organisationId: "skipper",
  userRoles: [],
  roles: ['BinnenhavengeldSkipper'],
  email: "bhg-skipper@portbase.com",
  acceptedUserAgreements: [String(moment().year())],
  organisation: {
    organisationId: 'skipper',
    organisationName: 'Skipper',
    street: 'skipper street',
    streetNumber: '10',
    city: 'skipper city',
    country: 'Nederland',
    postalCode: '3011AA',
    roles: ['BinnenhavengeldSkipper'],
    bankAccountNumber: "ABNA3278932",
    managedSkippers: []
  }
}

const skipperProfile2 = <BinnenhavengeldUserProfile>{
  userId: "skipper2",
  organisationId: "skipper2",
  userRoles: [],
  roles: ['BinnenhavengeldSkipper'],
  email: "bhg-skipper2@portbase.com",
  acceptedUserAgreements: ['2022'],
  organisation: {
    organisationId: 'skipper2',
    organisationName: 'Skipper 2',
    street: 'skipper street',
    streetNumber: '10',
    city: 'skipper city',
    country: 'Nederland',
    postalCode: '3011AA',
    roles: ['BinnenhavengeldSkipper'],
    bankAccountNumber: "ABNA3278932",
    managedSkippers: []
  }
}

const superUserProfile = <BinnenhavengeldUserProfile>{
  userId: "super",
  organisationId: "super",
  userRoles: ['BinnenhavengeldSuperUser'],
  roles: ['BinnenhavengeldSuperUser'],
  email: "bhg-super@portbase.com",
  acceptedUserAgreements: [String(moment().year())],
  organisation: {
    organisationId: 'hbr',
    roles: [],
    managedSkippers: []
  }
}

const signallerProfile = <BinnenhavengeldUserProfile>{
  userId: "signaller",
  organisationId: "signaller",
  userRoles: [],
  roles: ['BinnenhavengeldSignaller'],
  email: "bhg-signaller@portbase.com",
  acceptedUserAgreements: [String(moment().year())],
  organisation: {
    organisationId: 'signaller',
    organisationName: 'Signaller',
    street: 'signaller street',
    streetNumber: '10',
    city: 'signaller city',
    country: 'Nederland',
    postalCode: '3011AA',
    roles: ['BinnenhavengeldSignaller'],
    bankAccountNumber: "ABNA3278933",
    managedSkippers: []
  }
}

export const mockBinnenhavengeldProfiles = [adminProfile, superUserProfile, skipperProfile, agentProfile, skipperProfile2, signallerProfile];

let currentBinnenhavengeldUser : BinnenhavengeldUserProfile
  = JSON.parse(localStorage.getItem("currentBinnenhavengeldUser")) || adminProfile;

export function setCurrentBinnenhavengeldUser(profile: BinnenhavengeldUserProfile)  {
  localStorage.setItem('currentBinnenhavengeldUser', JSON.stringify(profile));
  window.location.href = "/binnenhavengeld";
}

export function getCurrentBinnenhavengeldUser() : BinnenhavengeldUserProfile {
  return currentBinnenhavengeldUser;
}

export const bhgSubscriptionsMock = [
  <BinnenhavengeldSubscription>{
    "subscriptionId" : "1",
    "declarationId" : "0",
    "declarant": {
      "organisationId": "361778",
      "organisationName": "Scheepvaartbedrijf Dari B.V.",
      "street": "Postbus",
      "streetNumber": "8048",
      "postalCode": "3301 CA",
      "city": "DORDRECHT",
      "country": "Nederland",
      "bankAccountNumber": "NL54ABNA12345",
      "roles": ["BinnenhavengeldAgent"]
    },
    "skipper": {
      "organisationId": "353526",
      "organisationName": "Eurobulk Transport Maatschappij BV",
      "street": "Amstelwijckweg",
      "streetNumber": "15",
      "postalCode": "3316 BB",
      "city": "DORDRECHT",
      "country": "Nederland",
      "roles": ["BinnenhavengeldSkipper"]
    },
    "ship" : {
      "eniNumber": "04502760",
      "name": "BAVARIA 83",
      "type": "freight",
      "category": "C.1",
      "tonnage": 2792,
      "surface": 1198,
      "length": 108.98,
      "width": 11.00,
      "draught": 3.16
    },
    "type": "week",
    "paymentMethod": "manually",
    "dateRange" : {
      "start" : "2022-12-10",
      "end" : "2022-12-17"
    }
  },
  <BinnenhavengeldSubscription>{
    "subscriptionId" : "2",
    "declarationId" : "0",
    "declarant": {
      "organisationId": "361778",
      "organisationName": "Scheepvaartbedrijf Dari B.V.",
      "street": "Postbus",
      "streetNumber": "8048",
      "postalCode": "3301 CA",
      "city": "DORDRECHT",
      "country": "Nederland",
      "bankAccountNumber": "NL54ABNA12345",
      "roles": ["BinnenhavengeldAgent"]
    },
    "skipper": {
      "organisationId": "353526",
      "organisationName": "Eurobulk Transport Maatschappij BV",
      "street": "Amstelwijckweg",
      "streetNumber": "15",
      "postalCode": "3316 BB",
      "city": "DORDRECHT",
      "country": "Nederland",
      "roles": ["BinnenhavengeldSkipper"]
    },
    "ship" : {
      "eniNumber": "06002496",
      "name": "LACO 11",
      "type": "freight",
      "category": "C.5",
      "tonnage": 2363,
      "surface": 871,
      "length": 76.46,
      "width": 11.40,
      "draught": 3.50
    },
    "type": "fortnight",
    "paymentMethod": "manually",
    "dateRange" : {
      "start" : "2022-12-10",
      "end" : "2022-12-24"
    },
    "legacy": true
  }
]

export const bhgSignallingsMock = [
  <BinnenhavengeldSignalling>{
    "ship" : {
      "eniNumber" : "04502760",
      "name" : "BAVARIA 83",
      "type" : "freight",
      "category" : "C.1",
      "tonnage" : 2792,
      "surface" : 1198,
      "length" : 108.98,
      "width" : 11.00,
      "draught" : 3.16,
      "eniNumberAlias" : "4502760"
    },
    "timestamp" : "2022-12-10T01:00:00",
    "location" : {
      "code" : "5",
      "name" : "2e Merwedehaven Dordrecht"
    },
    "signaller" : {
      "organisationId" : "354266",
      "organisationName" : "RPA02",
      "street" : null,
      "streetNumber" : null,
      "streetNumberSuffix" : null,
      "postalCode" : null,
      "city" : null,
      "country" : null,
      "bankAccountNumber" : null,
      "roles" : [ "BinnenhavengeldSignaller" ],
      "managedSkippers" : [ ]
    },
    "signallingId" : "0"
  },
  <BinnenhavengeldSignalling>{
    "ship" : {
      "eniNumber" : "04502761",
      "name" : "BAV 84",
      "type" : "freight",
      "category" : "C.1",
      "tonnage" : 2792,
      "surface" : 1198,
      "length" : 108.98,
      "width" : 11.00,
      "draught" : 3.16,
      "eniNumberAlias" : "4502760"
    },
    "timestamp" : "2022-12-10T04:00:00",
    "location" : {
      "code" : "51",
      "name" : "Maashavenkade"
    },
    "signaller" : {
      "organisationId" : "354266",
      "organisationName" : "RPA02",
      "street" : null,
      "streetNumber" : null,
      "streetNumberSuffix" : null,
      "postalCode" : null,
      "city" : null,
      "country" : null,
      "bankAccountNumber" : null,
      "roles" : [ "BinnenhavengeldSignaller" ],
      "managedSkippers" : [ ]
    },
    "signallingId" : "1",
    "legacy": true
  }
]

export const bhgShipsMock = [
  <BinnenhavengeldShip>{
    "name": "HERKULES II",
    "eniNumber": "01820014",
    "type": "passengerOrTug",
    "category": "C.1",
    "tonnage": 0,
    "surface": 519,
    "length": 39.95,
    "width": 13,
    "draught": 2
  },
  <BinnenhavengeldShip>{
    "name": "ALF",
    "eniNumber": "01820015",
    "type": "other",
    "category": "C.2",
    "tonnage": 100,
    "surface": 819,
    "length": 59.95,
    "width": 15,
    "draught": 3
  }
]

export const bhgUsersMock = [
  <BinnenhavengeldUser>{
    userId: "skipper@portbase.com",
    userRoles: [],
    email: "skipper@portbase.com",
    organisationId: "skipper"
  },
  <BinnenhavengeldUser>{
    userId: "agent@portbase.com",
    userRoles: [],
    email: "agent@portbase.com",
    organisationId: "agent"
  },
  <BinnenhavengeldUser>{
    userId: "admin",
    userRoles: ['BinnenhavengeldAdmin'],
    email: "admin@portbase.com",
    organisationId: "admin"
  },
  <BinnenhavengeldUser>{
    userId: "signaller@portbase.com",
    userRoles: [],
    email: "signaller@portbase.com",
    organisationId: "signaller"
  },
  <BinnenhavengeldUser>{
    userId: "super@portbase.com",
    userRoles: ['BinnenhavengeldSuperUser'],
    email: "super@portbase.com",
    organisationId: "super"
  },
]
