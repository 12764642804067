import {Component, EventEmitter, Output} from '@angular/core';
import {AppContext} from '../app-context';
import {environment} from '../../environments/environment';
import {HinterlandUtils} from '../hinterland/hinterland-utils';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {

  s000Url = 'https://' + AppContext.environment + '.portbase.com';
  appContext = AppContext;
  utils = HinterlandUtils;
  @Output() roleChange = new EventEmitter<any>();

  logOut = () => {
    if (environment.production || environment.docker) {
      window.location.href = '/api/logout';
    }
  }
}
