<div class="p-3 border border-top-0 rounded-bottom mb-3" *ngIf="movement.item as item">
  <div class="row">
    <div class="col-md">
      <app-form-group label="Number">
        <input [ngModel]="item.containerNumber" class="form-control" required>
      </app-form-group>
      <app-form-group label="Type">
        <app-select [(ngModel)]="item.containerType" [formatter]="utils.enumFormatter"
                    [options]="utils.containerTypeOptions"></app-select>
      </app-form-group>
      <app-form-group label="Size/type">
        <app-search [(ngModel)]="item.sizeType"
                    [inputFormatter]="utils.sizeTypeFormatter" [searchFunction]="utils.findSizeTypes"
                    dataKey="name" placeholder="Size or type, e.g. 22G1" required>
        </app-search>
      </app-form-group>
      <app-form-group label="Container operator">
        <app-search [(ngModel)]="movement.containerOperator"
                    [inputFormatter]="utils.containerOperatorFormatter"
                    [searchFunction]="utils.noOpSearchFunction" dataKey="name" required></app-search>
      </app-form-group>
      <app-form-group label="Booking reference">
        <input [(ngModel)]="movement.pickupReference" class="form-control">
      </app-form-group>
      <app-form-group label="Empty">
        <app-yes-no [(ngModel)]="item.empty"></app-yes-no>
      </app-form-group>
      <app-form-group label="Tare weight">
        <div class="input-group">
          <input [(ngModel)]="item.tareWeight" appDecimalNumber [maxDecimals]="3" class="form-control">
          <span class="input-group-text">kg</span>
        </div>
      </app-form-group>
      <app-form-group label="Cargo weight">
        <div class="input-group">
          <input [(ngModel)]="shipment.cargoWeight" appDecimalNumber [maxDecimals]="3" class="form-control">
          <span class="input-group-text">kg</span>
        </div>
      </app-form-group>
    </div>

    <div class="col-md">
      <app-form-group *ngIf="!!movement.discharge">
        <label>Discharge time</label>
        <app-date-time [(ngModel)]="movement.discharge"></app-date-time>
      </app-form-group>
      <app-form-group label="Shipper seal">
        <input [(ngModel)]="movement.shipperSealNumber" class="form-control">
      </app-form-group>
      <app-form-group label="Carrier seal">
        <input [(ngModel)]="movement.carrierSealNumber" class="form-control">
      </app-form-group>
      <app-form-group label="Customs seal">
        <input [(ngModel)]="movement.customsSealNumber" class="form-control">
      </app-form-group>
      <app-form-group label="Temperature setting">
        <div class="input-group">
          <input [(ngModel)]="item.temperature"
                 appDecimalNumber class="form-control" maxlength="100">
          <span class="input-group-text">&deg;C</span>
        </div>
      </app-form-group>
      <app-form-group label="Overlength">
        <div class="input-group">
          <input [(ngModel)]="item.overlength" appDecimalNumber [maxDecimals]="3" class="form-control">
          <span class="input-group-text">cm</span>
        </div>
      </app-form-group>
      <app-form-group label="Overheight">
        <div class="input-group">
          <input [(ngModel)]="item.overheight" appDecimalNumber [maxDecimals]="3" class="form-control">
          <span class="input-group-text">cm</span>
        </div>
      </app-form-group>
      <app-form-group label="Overwidth">
        <div class="input-group">
          <input [(ngModel)]="item.overwidth" appDecimalNumber [maxDecimals]="3" class="form-control">
          <span class="input-group-text">cm</span>
        </div>
      </app-form-group>
      <app-form-group label="Oversize remarks">
        <input [(ngModel)]="item.oversizeRemarks" class="form-control">
      </app-form-group>
    </div>
  </div>
</div>
