import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AcknowledgeTerminalUpdate, AdditionalCapacityOverride,
  BargeVisit,
  BargeVisitData,
  CancelVisit,
  CloseVisit,
  DeclareVisit,
  EstimatedHandlings,
  EstimatedSizes,
  FixedWindow, GetFixedWindowsForDeclarant,
  MoveVisit,
  SaveVisit,
  SendLoadDischargeList,
  Terminal,
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {HinterlandUtils} from '../../hinterland-utils';
import {checkValidity, clearValidation, sendCommand, sendQuery} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {combineLatest, Observable, of} from 'rxjs';
import {CommandGateway} from '../../../common/command-gateway';
import {
  AdditionalCapacity,
  BargeVoyageData,
  DateTimeRange, FindVoyagesForMovingVisit,
  Voyage
} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-edit-barge-visit-operator',
  templateUrl: './edit-barge-visit-operator.component.html',
  styleUrls: ['./edit-barge-visit-operator.component.css']
})
export class EditBargeVisitOperatorComponent implements OnChanges, OnInit {

  utils = HinterlandUtils;
  context = AppContext;

  @Input() visit: BargeVisit;
  @Input() voyageId: string;
  @Input() voyageCancelled: boolean;
  @Input() voyageData: BargeVoyageData;
  @Input() voyageDeclarantShortname: string;
  @Input() integralPlanningRequested: boolean;
  @Input() shareWithNextlogicRequested: boolean;
  @Input() nextlogicEnabled: boolean;

  @Output() etaUpdatedEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() deletedEventEmitter: EventEmitter<string> = new EventEmitter<string>();

  moveToVoyage: Voyage = undefined;
  fixedWindows: FixedWindow[] = [];
  terminals: Terminal[] = [];

  isDisabled: boolean;
  isMoveVisitAllowed: boolean;
  isMovingVisit = false;
  isNew: boolean = false;

  additionalTotalCapacity: Number;
  additionalTotalLength: Number;
  showAdditionalCapacityOverride: boolean;
  additionalCapacityOverride: AdditionalCapacityOverride = <AdditionalCapacityOverride>{length: null};

  constructor(private element: ElementRef, private commandGateway: CommandGateway) {
  }

  ngOnInit(): void {
    this.etaUpdatedEventEmitter.subscribe(next => this.updateFixedWindowsForSelectedTerminal());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.voyageData.additionalCapacity) {
      this.voyageData.additionalCapacity = <AdditionalCapacity>{teuCapacity: null, length: null};
    }

    if (!!this.visit.requestedVisitData
      && !!this.visit.requestedVisitData.additionalCapacityOverride
      && !!this.visit.requestedVisitData.additionalCapacityOverride.length) {
      this.showAdditionalCapacityOverride = true;
      this.additionalCapacityOverride = this.visit.requestedVisitData.additionalCapacityOverride;
    }

    if (changes.visit) {
      this.isNew = !this.visit.visitId;
      this.isDisabled = this.visit.arrived;

      if (!!this.visit.requestedVisitData) {
        // Automatically changed by MCA when terminal accepts non-nxl-participating visit
        this.visit.requestedVisitData.appointment = this.visit.visitData.appointment;
      } else if (this.visit.requestedVisitData == null && !!this.visit.visitData) {
        this.visit.requestedVisitData = this.visit.visitData;
      } else {
        this.visit.requestedVisitData = <BargeVisitData>{
          estimatedHandlings: <EstimatedHandlings>{}
        };
      }

      this.isMoveVisitAllowed = !!this.visit.visitId &&
        !!this.visit.visitDeclaration &&
        !!this.visit.terminal &&
        !this.visit['slaveToLinkedVisit'] &&
        (this.visit.visitDeclaration.acknowledgedByTerminal ||
          ["RWG", "ECTDELTA", "EUROMAX"].indexOf(this.visit.terminal.shortName) === -1);
    }

    if (changes.visit || changes.integralPlanningRequested) {
      if (this.integralPlanningRequested && !this.visit.requestedVisitData.estimatedHandlings.estimatedSizes) {
        this.visit.requestedVisitData.estimatedHandlings.estimatedSizes = <EstimatedSizes>{
          discharge20ftAndUnder: null,
          discharge20To30ft: null,
          discharge30To40ft: null,
          dischargeOver40ft: null,
          dischargeTeuLost: null,
          loading20ftAndUnder: null,
          loading20To30ft: null,
          loading30To40ft: null,
          loadingOver40ft: null,
          loadingTeuLost: null
        }
      }
      this.updateLoadingSizes();
      this.updateDischargeSizes();
    }

    if (changes.visit || changes.voyageDeclarantShortname) {
      this.updateFixedWindowsForSelectedTerminal();
    }

    if (changes.visit || changes.shareWithNextlogicRequested || changes.nextlogicEnabled) {
      if (this.visit.visitId) {
        HinterlandUtils.getTerminals(this.visit.modality, this.shareWithNextlogicRequested || this.nextlogicEnabled, true, false)
          .subscribe(terminals => this.terminals = terminals);
      } else {
        HinterlandUtils.getTerminals(this.visit.modality, this.shareWithNextlogicRequested || this.nextlogicEnabled, true, true)
          .subscribe(terminals => this.terminals = terminals);
      }
    }
  }

  saveVisit = () => {
    clearValidation(this.element);

    this.getVisitId().subscribe(visitId => {
      sendCommand('com.portbase.hinterland.api.common.command.SaveVisit', <SaveVisit>{
        voyageId: this.voyageId,
        visitData: this.visit.requestedVisitData,
        visitId: visitId,
        terminal: this.visit.terminal
      }, () => {
        this.deletedEventEmitter.emit(visitId);
        AppContext.registerSuccess('The visit was saved successfully');
      });
    });
  };

  declareVisit = () => {
    this.updateAdditionalCapacity();

    if (checkValidity(this.element)) {
      this.getVisitId().subscribe(visitId => {
        sendCommand('com.portbase.hinterland.api.common.command.DeclareVisit', <DeclareVisit>{
          voyageId: this.voyageId,
          visitData: this.visit.requestedVisitData,
          visitId: visitId,
          terminal: this.visit.terminal
        }, () => {
          this.deletedEventEmitter.emit(visitId);
          AppContext.registerSuccess('The visit was declared successfully');
        });
      });
    }
  };

  sendLoadDischargeList = () => {
    sendCommand('com.portbase.hinterland.api.common.command.SendLoadDischargeList', <SendLoadDischargeList>{
      voyageId: this.voyageId,
      visitId: this.visit.visitId
    }, () => {
      AppContext.registerSuccess('The load-discharge list for this visit sent');
    });
  };

  finalizeLoadDischargeList = () => {
    sendCommand('com.portbase.hinterland.api.common.command.CloseVisit', <CloseVisit>{
      voyageId: this.voyageId,
      visitId: this.visit.visitId
    }, () => {
      AppContext.registerSuccess('The load-discharge list for this visit is closed again');
    });
  };

  cancelVisit = () => {
    if (this.isNew) {
      //TODO Uniquely identify new visit... now all new visits will be removed.
      this.deletedEventEmitter.emit(this.visit.visitId);
    } else {
      sendCommand('com.portbase.hinterland.api.common.command.CancelVisit',
        <CancelVisit>{
          voyageId: this.voyageId,
          visitId: this.visit.visitId
        }, () => {
          if (this.integralPlanningRequested) {
            AppContext.registerSuccess('The visit was cancelled successfully and restrictions were updated accordingly');
          } else {
            AppContext.registerSuccess('The visit was cancelled successfully');
          }
        });
    }
  };

  acknowledgeUpdate = () => {
    sendCommand('com.portbase.hinterland.api.common.command.AcknowledgeTerminalUpdate',
      <AcknowledgeTerminalUpdate>{
        voyageId: this.voyageId,
        visitId: this.visit.visitId
      }, () => {
        AppContext.registerSuccess('The update to the visit has been acknowledged successfully');
      });
  };

  moveVisit = () => {
    const commandResults: Observable<any>[] = [];
    if (this.moveToVoyage) {
      commandResults.push(this.commandGateway.send("com.portbase.hinterland.api.common.command.MoveVisit",
        <MoveVisit>{
          voyageId: this.voyageId,
          visitId: this.visit.visitId,
          nextVoyageId: this.moveToVoyage.voyageId
        }));
      if (commandResults.length > 0) {
        AppContext.waitForProcess(combineLatest(commandResults)).subscribe(() => {
            if (this.integralPlanningRequested) {
              AppContext.registerSuccess('The visit was moved successfully and restrictions were updated accordingly');
            } else {
              AppContext.registerSuccess('The visit was moved successfully');
            }
            this.moveToVoyage = null;
            this.isMovingVisit = false;
          },
          error => AppContext.registerError(error));
      } else {
        this.isMovingVisit = false;
      }
    }
  };

  getVisitId = (): Observable<string> => this.visit.visitId ? of(this.visit.visitId) : HinterlandUtils.getNextVisitId();

  updateFixedWindowsForSelectedTerminal = () => {
    if (!!this.visit.terminal) {
      let periodOfValidity: DateTimeRange = null;
      if (this.visit.requestedVisitData?.eta && this.visit.requestedVisitData?.etd) {
        periodOfValidity = {
          start: this.visit.requestedVisitData.eta,
          end: this.visit.requestedVisitData.etd
        }
      }
      sendQuery("com.portbase.hinterland.api.refdata.query.GetFixedWindowsForDeclarant", <GetFixedWindowsForDeclarant>{
        terminal: {
          shortName: this.visit.terminal.shortName
        },
        declarantShortName: this.voyageDeclarantShortname,
        periodOfValidity: periodOfValidity
      }, {caching: false}).subscribe(result => {
        this.fixedWindows = result;
        if (!result.includes(null)) this.fixedWindows.unshift(null);
        if (!!this.visit?.requestedVisitData?.fixedWindow &&
          this.visit?.requestedVisitData?.fixedWindow.terminal?.shortName !== this.visit?.terminal?.shortName) {
          this.visit.requestedVisitData.fixedWindow = null;
        }
      });
    }
  }

  updateLoadingSizes = () => {
    if (this.integralPlanningRequested && !!this.visit.requestedVisitData.estimatedHandlings && !!this.visit.requestedVisitData.estimatedHandlings.estimatedSizes) {
      this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.loading20ftAndUnder = (this.visit.requestedVisitData.estimatedHandlings.loadingTotal || 0)
        - (this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.loading20To30ft || 0)
        - (this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.loading30To40ft || 0)
        - (this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.loadingOver40ft || 0)
    }
  };

  updateDischargeSizes = () => {
    if (this.integralPlanningRequested && !!this.visit.requestedVisitData.estimatedHandlings && !!this.visit.requestedVisitData.estimatedHandlings.estimatedSizes) {
      this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.discharge20ftAndUnder = (this.visit.requestedVisitData.estimatedHandlings.dischargeTotal || 0)
        - (this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.discharge20To30ft || 0)
        - (this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.discharge30To40ft || 0)
        - (this.visit.requestedVisitData.estimatedHandlings.estimatedSizes.dischargeOver40ft || 0)
    }
  };

  updateDischargeAmountOfBundles = () => {
    if (this.visit.requestedVisitData.estimatedHandlings.dischargeStacked === 0) {
      this.visit.requestedVisitData.estimatedHandlings.dischargeBundles = 0;
    }
  }

  updateLoadingAmountOfBundles = () => {
    if (this.visit.requestedVisitData.estimatedHandlings.loadingStacked === 0) {
      this.visit.requestedVisitData.estimatedHandlings.loadingBundles = 0;
    }
  }

  updateAdditionalCapacity = () => {
    this.visit.requestedVisitData.additionalCapacityOverride = this.additionalCapacityOverride;
    this.additionalTotalCapacity = Number(this.voyageData.barge.teuCapacity || 0) + Number(this.voyageData.additionalCapacity.teuCapacity || 0) - Number(this.voyageData.reservedTeu || 0);
    this.additionalTotalLength = Number(this.voyageData.barge.length || 0) + Number(this.additionalCapacityOverride.length || 0);
  }

  onSelect = () => {
    if (this.showAdditionalCapacityOverride) {
      if (this.visit.requestedVisitData.additionalCapacityOverride == null || this.visit.requestedVisitData.additionalCapacityOverride.length == null) {
        this.additionalCapacityOverride = <AdditionalCapacityOverride>{length: this.voyageData.additionalCapacity.length};
      } else {
        this.additionalCapacityOverride = this.visit.requestedVisitData.additionalCapacityOverride;
      }
    } else {
      this.additionalCapacityOverride = <AdditionalCapacityOverride>{length: null};
    }
  }

  findVoyagesForMovingVisit = (term: string) =>
    sendQuery("com.portbase.hinterland.api.common.query.FindVoyagesForMovingVisit", <FindVoyagesForMovingVisit>{
      term: term, declarantShortName: this.voyageDeclarantShortname
    }, {caching: false})
}
