import {Component, ElementRef, OnInit} from '@angular/core';
import {checkValidity, sendQuery} from '../../common/utils';
import {SignInBinnenhavengeldUser} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from '../../app-context';
import {BhgSupportComponent} from '../bhg-support/bhg-support.component';
import {EditModalService} from "../../components/modals/edit-modal/edit-modal.service";
import _moment from 'moment-timezone';

@Component({
  selector: 'app-bhg-login',
  templateUrl: './bhg-login.component.html',
  styleUrls: ['./bhg-login.component.scss']
})
export class BhgLoginComponent implements OnInit {
  email: string;
  password: string;

  errors: String[] = [];

  constructor(
    private element: ElementRef,
    private readonly editModalService: EditModalService,
  ) {
  }

  ngOnInit() {
    this.errors = [];
  }

  maintenance() {
    return _moment.utc().isAfter(_moment.utc("2024-08-29 09:00", "YYYY-MM-DD HH:mm"))
        && _moment.utc().isBefore(_moment.utc("2024-09-02 07:00", "YYYY-MM-DD HH:mm"));
  }

  getSupport() {
    this.editModalService.openModal(BhgSupportComponent);
  }

  signIn = () => {
    if (checkValidity(this.element, false)) {
      this.errors = [];
      sendQuery('com.portbase.hinterland.api.binnenhavengeld.SignInBinnenhavengeldUser',
        <SignInBinnenhavengeldUser>{userId: this.email, password: this.password},
        {showSpinner: true, caching: false})
        .subscribe(profile => {
          AppContext.binnenhavengeldUserProfile = profile;
          AppContext.navigateToBinnenhavengeldHomepage(true);
        }, (error) => {
          this.errors.push(error?.error?.error);
        });
    }
  };
}
