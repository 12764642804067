import {Component, Input, OnInit} from '@angular/core';
import {
  HandlingData,
  Notification,
  NotificationLevel,
  RoadVisit
} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import moment from "moment/moment";
import {DeepSea, DetachedHandling, RoadHandling, RoadVoyage} from "@portbase/hinterland-service-typescriptmodels";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {EventHandler, EventType} from 'src/app/common/event-gateway';
import {HinterlandUtils} from "../../../../hinterland/hinterland-utils";
import {
  ViewHandlingOffCanvasComponent
} from "../../offcanvas-panel/view-handling-details-offcanvas/app-view-handling-offcanvas";
import {ActionMode, HandlingPanelDisplayMode, VoyageVisitAndHandling} from "../../../../hinterland/types";
import {AppContext} from "../../../../app-context";
import {EditModalService} from '../../../../components/modals/edit-modal/edit-modal.service';
import {ConfirmationModalService} from "../../../../components/modals/confirmation-modal/confirmation-modal.service";
import {
  VisitCancelledWarningOnMoveComponent
} from "../../dialog/visit-cancelled-warning-on-move-dialog/visit-cancelled-warning-on-move.component";

@Component({
  selector: 'app-handling-panel',
  templateUrl: './app-handling-panel.html',
  styleUrls: ['./app-handling-panel.scss']
})
export class HandlingPanelComponent extends HinterlandBaseComponent implements OnInit {

  utils = HinterlandUtils;
  appContext = AppContext;

  @Input() voyage: RoadVoyage;
  @Input() visit: RoadVisit;
  @Input() handling: RoadHandling | DetachedHandling;
  @Input() handlingData: HandlingData;
  @Input() displayMode: HandlingPanelDisplayMode;
  @Input() actionMode: ActionMode;

  isPickup: boolean;
  isDetached: boolean;

  deepSea: DeepSea;
  cargoOpeningTime: string;
  cargoClosingTime: string;

  applicableNotifications: Notification[] = [];
  highLevelApplicableNotifications: Notification[] = [];
  mediumLevelApplicableNotifications: Notification[] = [];
  lowLevelApplicableNotifications: Notification[] = [];

  showVisitWillBeCancelledWarning: boolean;
  visitWillBeCancelledWarningConfirmed: boolean;

  secureChainHandling: boolean;
  commercialReleaseAvailable: boolean;

  constructor(
    private readonly editModalService: EditModalService,
    private readonly confirmationModalService: ConfirmationModalService
  ) {
    super();
  }

  ngOnInit() {
    this.isDetached = !this.voyage;
    this.isPickup = this.handlingData.type === 'loading';

    if (this.handling.handlingStatus?.notifications) {
      this.applicableNotifications = this.handling.handlingStatus?.notifications.filter(a => (this.appContext.isAdmin() || this.appContext.isHinterlandTerminalAndDeclarant()) ? true :
        this.appContext.isHinterlandDeclarant() ? a.recipients.includes("INLAND_TRANSPORT_OPERATOR") : this.appContext.isHinterlandTerminal() ? a.recipients.includes("TERMINAL") : false);

      this.lowLevelApplicableNotifications = this.getNotificationsForLevel('LOW');
      this.mediumLevelApplicableNotifications = this.getNotificationsForLevel('MEDIUM');
      this.highLevelApplicableNotifications = this.getNotificationsForLevel('HIGH');
    }

    if (this.handling.deepSea) {
      this.deepSea = this.handling.deepSea;
    }

    if (this.deepSea?.cargoOpeningTime || this.deepSea?.cargoClosingTime) {
      this.cargoOpeningTime = this.deepSea.cargoOpeningTime;
      this.cargoClosingTime = this.deepSea.cargoClosingTime;
    } else if (this.handling.handlingStatus?.window) {
      this.cargoOpeningTime = this.handling.handlingStatus?.window?.start;
      this.cargoClosingTime = this.handling.handlingStatus?.window?.end;
    }

    this.showVisitWillBeCancelledWarning = !this.isDetached && this.visit?.handlings.length === 1 && !this.visitWillBeCancelledWarningConfirmed;

    this.secureChainHandling = !!this.handling.shipmentId;
    this.commercialReleaseAvailable = this.handling.bookingNumberSource == "COMMERCIAL_RELEASE";
  }

  onSelectionChanged() {
    this.handling['selected'] = !this.handling['selected'];

    if (this.handling['selected'] === true) {
      this.eventGateway.publish(EventType.HandlingSelected, <VoyageVisitAndHandling>{
        'voyage': this.voyage,
        'visit': this.visit,
        'handling': this.handling
      });
    } else {
      this.eventGateway.publish(EventType.HandlingDeselected, <VoyageVisitAndHandling>{
        'voyage': this.voyage,
        'visit': this.visit,
        'handling': this.handling
      });
    }
  }

  // noinspection JSUnusedGlobalSymbols -> EventHandler
  onEnterActionMode: EventHandler<EventType.EnterActionMode> = (data) => {
    if (!!data.visit) {
      this.handling['visitIdToAttach'] = data['visitId'];
    }
  }

  // noinspection JSUnusedGlobalSymbols -> EventHandler
  onExitActionMode: EventHandler<EventType.ExitActionMode> = () => {
    this.displayMode = undefined;
    this.handling['visitIdToAttach'] = undefined;
  }

  showModelAndAddHandlingToThisVisit() {
    if (this.showVisitWillBeCancelledWarning) {
      this.confirmationModalService.openModalWithCallback((move) => {
        if (move) {
          this.showVisitWillBeCancelledWarning = false;
          this.eventGateway.publish(EventType.AddHandlingToVisit, this.handling);
        }
      }, VisitCancelledWarningOnMoveComponent, {});
    } else {
      this.eventGateway.publish(EventType.AddHandlingToVisit, this.handling);
    }
  }

  addHandlingToThisVisit() {
    if (this.showVisitWillBeCancelledWarning && !this.visitWillBeCancelledWarningConfirmed) {
      return;
    }

    this.eventGateway.publish(EventType.AddHandlingToVisit, this.handling);
  }

  formatDateTime(dateTime: string) {
    const m = moment(dateTime);
    return m.format(`${m.year() === moment().year() ? 'DD MMM' : 'DD MMM [’]YY'}, HH:mm`);
  }

  showHandlingDetails() {
    this.editModalService.openModal(ViewHandlingOffCanvasComponent, <VoyageVisitAndHandling>{
      'voyage': this.voyage,
      'visit': this.visit,
      'handling': this.handling
    });
  }

  getNotificationClass() {
    if (this.highLevelApplicableNotifications.length > 0) {
      return "notification-danger";
    } else if (this.mediumLevelApplicableNotifications.length > 0) {
      return "notification-warning";
    } else {
      return "notification-info";
    }
  }

  getNotificationsForLevel(level: NotificationLevel): Notification[] {
    return this.applicableNotifications.filter(a => a.level == level || (level == "LOW" && !a.level));
  }

  isReadOnly() {
    if (this.appContext.isAdmin()) return false;
    if (!!this.voyage) {
      return this.voyage?.declarant?.shortName !== this.appContext.userProfile.organisationShortName;
    } else {
      return this.handling['declarant'].shortName !== this.appContext.userProfile.organisationShortName;
    }
  }

  hasRemainOnTruckContainers() {
    return !this.isDetached
      && this.voyage?.remainOnTruckContainers
      && this.visit?.terminal?.remainOnTruckAllowed
      && this.voyage?.remainOnTruckContainers[this.visit.visitId]?.length > 0;
  }

}
