<app-tooltip>
  <div class="tooltipContent">
    <p class="mb-0">Sea Vessel</p>
    <p class="text-start mb-0">Name:&nbsp;{{ shipName }}</p>
    <p class="text-start mb-0" *ngIf="imoCode">Imo code: {{ imoCode }}</p>
    <p class="text-start mb-0" *ngIf="eta">ETA: {{ eta }}</p>
    <p class="text-start mb-0" *ngIf="etd">ETD: {{ etd }}</p>

    <ng-container *ngIf="!!window">
      @if (handling?.handlingData.type === 'discharge') {
        <p class="text-start mb-0">Cargo opening/closing:
          <app-formatted-date-range [start]="window.start"
                                    [end]="window.end"
                                    [customClass]="'text-white'"
                                    [showClock]="false">
          </app-formatted-date-range>
        </p>
      } @else {
        @if (window.start === window.end) {
          <p class="text-start mb-0">Ultimate release date:
            <app-formatted-date-range [start]="window.start"
                                      [end]="window.end"
                                      [showClock]="false"
                                      [customClass]="'text-white'"
                                      [startOnly]="true"></app-formatted-date-range>
          </p>
        } @else {
          <p class="text-start mb-0">Commercial window:</p>
          <p class="text-start">
            <app-formatted-date-range [start]="window.start"
                                      [end]="window.end"
                                      [customClass]="'text-white'"
                                      [showClock]="false"></app-formatted-date-range>
          </p>
        }
      }
    </ng-container>

    <p class="text-start mb-0" *ngIf="source">Source: {{ source }}</p>
    <p class="text-start mb-0" *ngIf="lastUpdated">Last updated: {{ lastUpdated }}</p>
  </div>
  <span>{{ shipName ? shipName : handling?.deepSea ? "Sea Vessel" : null }}</span>
</app-tooltip>
