<div [ngClass]="{'cancelled' : voyage.cancelled}" class="voyage-summary border border-light">
  <ng-container *ngIf=isModalityBarge>
    <div class="header row narrow-gutters align-items-center mx-0 py-2">
      <div class="col-auto" style="line-height: 3rem">
        <h4 *ngIf="!voyage.integralPlanningRequested" class="fa fa-ship m-0 ps-1"></h4>
        <h4 *ngIf="voyage.integralPlanningRequested" class="integral-planning-icon fa-stack pt-0 ps-1 ms-1">
          <i class="fa fa-fw fa-ship fa-stack-2x"></i>
          <i class="fas fa-ellipsis-v fa-stack-1x fa-inverse ms-n3 mt-n1"></i>
          <i class="fas fa-minus fa-stack-1x fa-inverse fa-rotate-90 fa-sm top ms-n3 mt-n2"></i>
          <i class="fas fa-minus fa-stack-1x fa-inverse fa-rotate-90 fa-sm bottom ms-n3"></i>
        </h4>
      </div>

      <div class="col">
        <span *ngIf="voyage.acknowledgedByOperator === false" class="fa fa-circle me-2 text-info"></span>
        <ng-container>
          <h5 class="d-none d-sm-inline m-0">{{voyage.voyageData?.barge?.name | titlecase}}</h5>
          <span class="d-sm-none m-0">{{voyage.voyageData?.barge?.name | titlecase}}</span>
        </ng-container>
        <notifications
          [alerts]="voyage.integralPlanningStatus?.alerts"
          [handlingSummary]="voyage.handlingSummary"
          [visitSummary]="voyage.visitSummary"
          [notificationContext]="summaryContext"
          compactView="true">
        </notifications>
      </div>

      <div class="col" *ngIf="voyage['integralPlanningStatus'] && !voyage['integralPlanningStatus'].handled && voyage['integralPlanningStatus'].pta; else manuallyPlanned">
        <div>
          <span class="fa fa-stack fa-1x fa-ellipsis-v mt-n1 me-2" style="font-size:1em;width:1em" title="Date and time of arrival and departure as planned by integral planning"></span>
          <app-formatted-date-range [start]="voyage['integralPlanningStatus'].pta" [end]="voyage['integralPlanningStatus'].ptd"></app-formatted-date-range>
        </div>
        <div>
          <span *ngIf="isModalityBarge" class="fa fa-1x fa-ship modality me-2 extra-date-time" style="font-size:1em;width:1em" title="Date and time of arrival and departure as requested by barge operator"></span>
          <span *ngIf="isModalityRail" class="fa-stack fa-1x mt-n1 me-2 extra-date-time" style="font-size:1em;width:1em" title="Date and time of arrival and departure as requested by rail operator"><i class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white" style="font-size:0.8em">O</strong></span>
          <span *ngIf="isModalityRoad" class="fa fa-1x fa-truck modality me-2 extra-date-time" style="font-size:1em;width:1em" title="Date and time of arrival and departure as requested by road operator"></span>
          <app-formatted-date-range [muted]="true" [start]="voyage.voyageData.eta" [end]="voyage.voyageData.etd"></app-formatted-date-range>
        </div>
      </div>

      <ng-template #manuallyPlanned>
        <div class="col">
          <span *ngIf="isModalityBarge" class="fa fa-1x fa-ship modality me-2 text-secondary" style="font-size:1em;width:1em" title="Date and time of arrival and departure as requested by barge operator"></span>
          <span *ngIf="isModalityRail" class="fa-stack fa-1x mt-n1 me-2 text-secondary" style="font-size:1em;width:1em" title="Date and time of arrival and departure as requested by rail operator"><i class="fas fa-square fa-stack-1x"></i><strong class="fa-stack-1x text-white" style="font-size:0.8em">O</strong></span>
          <span *ngIf="isModalityRoad" class="fa fa-1x fa-truck modality me-2 text-secondary" style="font-size:1em;width:1em" title="Date and time of arrival and departure as requested by road operator"></span>
          <app-formatted-date-range [start]="voyage.voyageData.eta" [end]="voyage.voyageData.etd"></app-formatted-date-range>
        </div>
      </ng-template>

      <div class="col d-none d-lg-block">
        <app-handling-total [handlingStats]="voyage.handlingSummary" [inOperation]="voyage.voyageStatus==='IN_OPERATION' || voyage.voyageStatus==='COMPLETED'"></app-handling-total>
      </div>

      <div class="col d-none d-lg-block">
        <div (click)="appContext.togglePersistentFilter('Discharge');$event.stopPropagation();">
          <span *ngIf="voyage.voyageData?.inHouseDischargeNumber || appContext.isFiltering('Discharge')" [ngClass]="appContext.isFiltering('Discharge')?'text-warning':'text-dark'" class="me-2 badge rounded-pill bg-light" style="width: 5rem">Discharge</span>&nbsp;
          <span *ngIf="voyage.voyageData?.inHouseDischargeNumber">{{voyage.voyageData?.inHouseDischargeNumber}}</span>
        </div>
        <div (click)="appContext.togglePersistentFilter('Loading');$event.stopPropagation();">
          <span *ngIf="voyage.voyageData?.inHouseLoadingNumber || appContext.isFiltering('Loading')" [ngClass]="appContext.isFiltering('Loading')?'text-warning':'text-dark'" class="me-2 badge rounded-pill bg-light" style="width: 5rem">Loading</span>&nbsp;
          <span *ngIf="voyage.voyageData?.inHouseLoadingNumber">{{voyage.voyageData?.inHouseLoadingNumber}}</span>
        </div>
      </div>

      <div class="col-auto d-none d-sm-block" style="min-width: 2.5rem;">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isModalityRoad">
    <div class="header row narrow-gutters align-items-center mx-0 py-2">
      <div class="col-auto" style="line-height: 3rem">
        <h4 class="fa fa-fw fa-truck m-0 ps-1"></h4>
      </div>
      <div class="col">
        <ng-container>
          <h5 class="d-none d-sm-inline m-0">{{voyage.declarant.fullName | titlecase}}</h5>
          <span class="d-sm-none m-0">{{voyage.voyageData?.truckLicenseId | titlecase}}</span>
        </ng-container>
        <notifications
          [alerts]="voyage.integralPlanningStatus?.alerts"
          [handlingSummary]="voyage.handlingSummary"
          [visitSummary]="voyage.visitSummary"
          compactView="true">
        </notifications>
      </div>
      <div class="col d-none d-lg-block">
        <app-handling-total [handlingStats]="voyage.handlingSummary" [inOperation]="voyage.voyageStatus==='IN_OPERATION' || voyage.voyageStatus==='COMPLETED'"></app-handling-total>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isModalityRail">
    <div class="header row narrow-gutters align-items-center mx-0 py-2">
      <div class="col-auto" style="line-height: 3rem">
        <h4 class="fa fa-fw fa-train m-0 ps-1"></h4>
      </div>

      <div class="col">
        <span *ngIf="voyage.acknowledgedByOperator === false" class="fa fa-circle me-2 text-info"></span>
        <ng-container>
          <h5 class="d-none d-sm-inline m-0">{{voyage.voyageData.train.trainNumber}}</h5>
          <span class="d-sm-none m-0">{{voyage.voyageData.train.trainNumber}}</span>
        </ng-container>
        <ng-container *ngIf="voyage.voyageData.shuttle">
          <h5 class="d-none d-sm-inline m-0"> - {{voyage.voyageData.shuttle}}</h5>
          <span class="d-sm-none m-0"> - {{voyage.voyageData.shuttle}}</span>
        </ng-container>
      </div>

      <div class="col">
        <app-formatted-date-range *ngIf="!hasRailTemplate" [showTime]="false" [startOnly]="isModalityRail" [start]="voyage.voyageData.firstDepartureDate"></app-formatted-date-range>
        <span *ngIf="hasRailTemplate">{{voyage.voyageData['firstDepartureDay'] | titlecase}}</span>
      </div>

      <div class="col d-none d-lg-block">
        <app-handling-total *ngIf="!hasRailTemplate" [handlingStats]="voyage.handlingSummary" [inOperation]="voyage.voyageStatus==='IN_OPERATION' || voyage.voyageStatus==='COMPLETED'"></app-handling-total>
        <span *ngIf="hasRailTemplate">Rotation template <i>{{voyage['templateId']}}</i></span>
      </div>

      <div class="col d-none d-lg-block">
        <div *ngIf="!hasRailTemplate" (click)="appContext.togglePersistentFilter('Discharge');$event.stopPropagation();">
          <span *ngIf="voyage.voyageData.dischargeVoyageNumber || appContext.isFiltering('Discharge')" [ngClass]="appContext.isFiltering('Discharge')?'text-warning':'text-dark'" class="me-2 badge rounded-pill bg-light" style="width: 5rem">Discharge</span>&nbsp;
          <span>{{voyage.voyageData.dischargeVoyageNumber}}</span>
        </div>
        <div *ngIf="!hasRailTemplate" (click)="appContext.togglePersistentFilter('Loading');$event.stopPropagation();">
          <span *ngIf="voyage.voyageData.loadingVoyageNumber || appContext.isFiltering('Loading')" [ngClass]="appContext.isFiltering('Loading')?'text-warning':'text-dark'" class="me-2 badge rounded-pill bg-light" style="width: 5rem">Loading</span>&nbsp;
          <span>{{voyage.voyageData.loadingVoyageNumber}}</span>
        </div>
      </div>

      <div class="col-auto d-none d-sm-block" style="min-width: 2.5rem;">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>
</div>
