<div [ngClass]="{'disabled' : isDisabled}" class="fieldset">
  <div class="row">
    <div class="col-md-6">
      <app-form-group>
        <label>Terminal</label>
        <app-select (ngModelChange)="getAvailableTimetableSlots()" [(ngModel)]="visit.terminal"
                    [disabled]="!!visit.visitDeclaration" [options]="terminals" dataKey="shortName"
                    required>
        </app-select>
      </app-form-group>
      <app-form-group *ngIf="visit.visitId" label="Call ID">
        <input [(ngModel)]="visit.visitId" class="form-control" disabled>
      </app-form-group>

      <app-form-group label="Timetable slot">
        <input [(ngModel)]="timetableEntry" class="form-control" disabled>
      </app-form-group>
    </div>

    <div class="col-md-6">
      <app-form-group *ngIf="!hasTemplate" label="ETA">
        <app-date-time (ngModelChange)="timeUpdatedEventEmitter.emit()" [(ngModel)]="visit.visitData.eta"
                       required></app-date-time>
      </app-form-group>
      <app-form-group *ngIf="!hasTemplate" label="ETD">
        <app-date-time (ngModelChange)="timeUpdatedEventEmitter.emit()" [(ngModel)]="visit.visitData.etd"
                       required></app-date-time>
      </app-form-group>
      <app-form-group *ngIf="!hasTemplate" label="Cutoff time">
        <app-date-time (ngModelChange)="timeUpdatedEventEmitter.emit()" [(ngModel)]="visit.plannedVisitData.cutoffTime"></app-date-time>
      </app-form-group>
      <app-form-group *ngIf="hasTemplate" class="day-and-time-range" label="ETA time of week">
        <app-select [(ngModel)]="getVisitDataTemplate().etaTimeOfWeek.day" [formatter]="toTitleCase"
                    [options]="weekDays()" [required]="!getVisitDataTemplate().timetableEntry">
        </app-select>
        <app-date-time [(ngModel)]="getVisitDataTemplate().etaTimeOfWeek.time" [required]="!getVisitDataTemplate().timetableEntry"
                       timeOnly="true"></app-date-time>
      </app-form-group>
      <app-form-group *ngIf="hasTemplate" class="day-and-time-range" label="ETD time of week">
        <app-select [(ngModel)]="getVisitDataTemplate().etdTimeOfWeek.day" [formatter]="toTitleCase"
                    [options]="weekDays()" [required]="!getVisitDataTemplate().timetableEntry">
        </app-select>
        <app-date-time [(ngModel)]="getVisitDataTemplate().etdTimeOfWeek.time" [required]="!getVisitDataTemplate().timetableEntry"
                       timeOnly="true"></app-date-time>
      </app-form-group>
      <ng-container *ngIf="terminalView">
        <app-form-group>
          <label>Terminal remarks</label>
          <textarea (ngModelChange)="onChangeTerminalRemarks($event)"
                    [ngModel]="visit.plannedVisitData?.terminalRemarks" class="form-control"
                    placeholder="Type any remarks for the operator here"
                    style="min-height: 6rem"></textarea>
        </app-form-group>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="visit.visitData.estimatedHandlings">
    <div class="row">
      <div class="col-md-6 declaration-block">
        <h2>Discharge</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
        </div>

        <div class="row narrow-gutters py-2">
          <div class="col">
            <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeTotal" [maxDecimals]="0" appDecimalNumber
                   class="form-control">
          </div>
          <div class="col">
            <input [(ngModel)]="visit.visitData.estimatedHandlings.dischargeEmpty" [maxDecimals]="0" appDecimalNumber
                   class="form-control">
          </div>
        </div>
      </div>

      <div class="col-md-6 declaration-block">
        <h2>Loading</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
        </div>

        <div class="row narrow-gutters py-2">
          <div class="col">
            <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingTotal" [maxDecimals]="0" appDecimalNumber
                   class="form-control">
          </div>
          <div class="col">
            <input [(ngModel)]="visit.visitData.estimatedHandlings.loadingEmpty" [maxDecimals]="0" appDecimalNumber
                   class="form-control">
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="utils.isVoyageDeclarant(voyage) && !hasTemplate && !terminalView">
    <div class="row narrow-gutters mt-3">
      <ng-container *ngIf="!isDisabled;">
        <div class="col-md-auto mb-3 mb-md-0 me-auto">
          <button *ngIf="!!visit.visitId; else cancelUnsavedVisit"
                  [attr.data-bs-target]="'#cancelVisitModal-' + visit.visitId" class="btn btn-danger btn-block"
                  data-bs-toggle="modal" style="min-width: 10rem" tabindex="-1" type="button">Cancel visit
          </button>
          <ng-template #cancelUnsavedVisit>
            <button (click)="cancelVisit()"
                    class="btn btn-secondary btn-block" style="min-width: 10rem;" tabindex="-1" type="button">Cancel
              visit
            </button>
          </ng-template>
        </div>
        <div class="col-md-auto mb-md-0 ms-auto"></div>
        <div *ngIf="visit.acknowledgedByOperator === false" class="col-md-auto mb-3 mb-md-0 enabled">
          <button (click)="acknowledgeUpdate()" class="btn btn-outline-info btn-block" style="min-width: 10rem;" tabindex="-1"
                  type="button"><span>Acknowledge update</span></button>
        </div>
        <div class="col-md-auto ms-0">
          <button (click)="declareVisit()" class="btn btn-info btn-block" style="min-width: 10rem;" tabindex="-1"
                  type="button"><span>Save & send visit</span></button>
        </div>
      </ng-container>
    </div>
  </ng-container>


  <div *ngIf="visit.visitId && !isDisabled" [id]="'cancelVisitModal-' + visit.visitId" class="modal fade" role="dialog"
       tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure that you want to cancel this visit?</h5>
        </div>
        <div class="modal-footer">
          <div class="container-fluid px-0">
            <div class="row g-0 justify-content-between">
              <div class="col-auto">
                <button class="btn btn-secondary" data-bs-dismiss="modal" style="width: 8rem" type="button">No</button>
              </div>
              <div class="col-auto">
                <button (click)="cancelVisit()" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem"
                        type="button">Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
