<div class="row">
  <div class="col-md-6">
    <app-form-group label="Name">
      <input [(ngModel)]="good.name" class="form-control" placeholder="Enter goods description" required>
    </app-form-group>
    <app-form-group label="Gross weight">
      <div class="input-group">
        <input [(ngModel)]="good.grossWeight" [maxDecimals]="3" appDecimalNumber class="form-control" required>
        <span class="input-group-text">kg</span>
      </div>
    </app-form-group>
    <app-form-group label="Packaging">
      <app-search (ngModelChange)="onOuterPackageChange()" [(ngModel)]="good.packageType"
                  [inputFormatter]="refData.packageTypeFormatter" [searchFunction]="refData.findSizeTypes"
                  dataKey="code" placeholder="– Select packaging –" required></app-search>
    </app-form-group>
    <app-form-group label="Packages">
      <div class="input-group">
        <input [(ngModel)]="good.numberOfPackages" [disabled]="good.packageType?.bulk" [maxDecimals]="0" [min]="1"
               appDecimalNumber class="form-control" required>
        <span class="input-group-text">#</span>
      </div>
    </app-form-group>
    <app-form-group label="Commodity code">
      <app-search [(ngModel)]="good.classification" [inputFormatter]="refData.goodsClassificationFormatter"
                  [searchFunction]="refData.findSizeTypes"
                  dataKey="code" placeholder="Select a classification"></app-search>
    </app-form-group>
    <app-form-group label="Temperature range">
      <div class="input-group">
        <input [(ngModel)]="good.minimumTemperature" appDecimalNumber class="form-control" maxlength="100"
               placeholder="Minimum">
        <input [(ngModel)]="good.maximumTemperature" appDecimalNumber class="form-control" maxlength="100"
               placeholder="Maximum">
        <span class="input-group-text">&deg;C</span>
      </div>
    </app-form-group>
    <app-form-group label="Remarks"><input [(ngModel)]="good.remarks" class="form-control"></app-form-group>
  </div>

  <div class="col-md">
    <app-form-group label="UN code"><input [(ngModel)]="good.unCode" class="form-control"></app-form-group>
    <app-form-group label="Hazard class"><input [(ngModel)]="good.hazardClass" class="form-control">
    </app-form-group>
    <app-form-group label="Danger packinggroup">
      <app-select [(ngModel)]="good.packingGroup" [formatter]="refData.packingGroupFormatter"
                  [options]="refData.packingGroups"></app-select>
    </app-form-group>
    <app-form-group label="Segregation group"><input [(ngModel)]="good.segregationGroup" class="form-control">
    </app-form-group>
    <app-form-group label="Ems fire code"><input [(ngModel)]="good.emsFireCode" class="form-control">
    </app-form-group>
    <app-form-group label="Ems spillage code"><input [(ngModel)]="good.emsSpillageCode" class="form-control">
    </app-form-group>
    <app-form-group label="Subsidiary risk"><input [(ngModel)]="good.subsidiaryRisk" class="form-control">
    </app-form-group>
  </div>


  <!--  <div class="row">-->
  <!--    <div class="col-md">-->
  <!--      <app-form-group label="Danger information">-->
  <!--        <app-search [(ngModel)]="good.dangerInformation" dataKey="name"-->
  <!--                    [inputFormatter]="refData.dangerInformationFormatter"-->
  <!--                    [searchFunction]="refData.findDangerInformation"-->
  <!--                    [resultFormatter]="refData.dangerInformationFormatter"-->
  <!--                    placeholder="Find by name or un code"></app-search>-->
  <!--      </app-form-group>-->
  <!--    </div>-->

  <!--    <div class="col-md">-->
  <!--    </div>-->
</div>
