import {Pipe, PipeTransform} from '@angular/core';
import {Terminal} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Pipe({
  name: 'asTerminals',
  pure: true
})
export class CastTerminalsPipe implements PipeTransform {
  transform(value: any, args?: any): Terminal[] {
    return value;
  }
}
