import {Component, OnDestroy} from '@angular/core';
import {sendQuery} from '../common/utils';
import {AppContext} from '../app-context';
import {RequestGateway} from '../common/request-gateway';
import {Router} from '@angular/router';
import {scheduleTask} from '../common/scheduler';
import moment from 'moment';

@Component({
  selector: 'app-binnenhavengeld',
  templateUrl: './bhg-signed-in.component.html',
  styleUrls: ['./bhg.scss' , './bhg-signed-in.component.scss']
})
export class BhgSignedInComponent implements OnDestroy {
  context = AppContext;

  constructor(private router: Router) {
    document.title = 'Inland port dues';
    RequestGateway.baseUrl = "/api/proxy/binnenhavengeld";
    this.getUserProfileIfNeeded();
  }

  private getUserProfileIfNeeded = () => {
    if (AppContext.binnenhavengeldUserProfile) {
      AppContext.navigateToBinnenhavengeldHomepage();
      return;
    }
    sendQuery('com.portbase.hinterland.api.binnenhavengeld.GetMyBinnenhavengeldUserProfile', {},
      {showSpinner: true, caching: false, hideError: true})
      .subscribe(userProfile => {
        AppContext.binnenhavengeldUserProfile = userProfile;
        //send users to login page 6 hours after logging in (as session expires after 6 hours)
        scheduleTask(() => window.location.href = '/binnenhavengeld/login', moment.duration(6, 'hours'));
        AppContext.navigateToBinnenhavengeldHomepage();
      }, error => {
        if (error.status === 401) {
          this.router.navigateByUrl("/binnenhavengeld/login");
        } else {
          setTimeout(() => this.getUserProfileIfNeeded(), 5_000);
        }
      });
  };

  ngOnDestroy(): void {
    document.title = 'Hinterland Notification';
    RequestGateway.baseUrl = "/api";
  }

}
