import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HinterlandOrganisation, UpdateCargoDeclarants} from '@portbase/hinterland-service-typescriptmodels';
import {HinterlandUtils} from '../../hinterland-utils';
import {checkValidity, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-edit-cargo-declarants',
  templateUrl: './edit-cargo-declarants.component.html',
  styleUrls: ['./edit-cargo-declarants.component.css']
})
export class EditCargoDeclarantsComponent implements OnChanges {

  context = AppContext;
  utils = HinterlandUtils;

  @Input() voyageId: string;
  @Input() declarant: HinterlandOrganisation;
  @Input() cargoDeclarants: HinterlandOrganisation[];

  constructor(private element: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cargoDeclarants) {
      //CargoDeclarant gets added by clicking add AND after saving by received JSON patch.
      //Remove the duplicate when patch is received.
      this.cargoDeclarants = this.cargoDeclarants.filter((value, index, array) =>
        array.findIndex(t => (JSON.stringify(t) === JSON.stringify(value))) === index);
    }
  }

  addCargoDeclarant = () => {
    this.cargoDeclarants.push(<HinterlandOrganisation>{});
  }

  updateCargoDeclarants = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.common.command.UpdateCargoDeclarants', <UpdateCargoDeclarants>{
        voyageId: this.voyageId,
        cargoDeclarants: this.cargoDeclarants,
      }, () => {
        AppContext.registerSuccess('Cargo declarants have been updated successfully');
      });
    }
  };

  trackByIndex = (index: number, obj: any): any => index;

  isVoyageDeclarant = (cargoDeclarantShortName: string) => this.declarant.shortName === cargoDeclarantShortName;

}
