import {Component, EventEmitter, Input, OnChanges, OnInit, OnDestroy, Output, SimpleChanges} from "@angular/core";
import {
  BargeVisit,
  HinterlandOrganisation,
  RailVisit,
  RailVoyage, RoadVisit,
  Terminal,
  Visit,
  VisitDeclaration,
  VisitStatusType,
  Voyage,
  VoyageData,
  VoyageStatus
} from "@portbase/hinterland-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {HinterlandUtils} from "../../hinterland-utils";
import {TrainCompositionUpload} from "../../rail/train-composition/train-composition.upload";
import {RailExcelUtils} from "../../rail/rail-excel.utils";
import {RailVisitStatus} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import moment from "moment";

@Component({
  selector: 'app-visit-details',
  templateUrl: './visit-details.component.html',
  styleUrls: ['./visit-details.component.scss']
})
export class VisitDetailsComponent implements OnChanges, OnInit, OnDestroy {

  appContext = AppContext;

  @Input() voyageId: string;
  @Input() visit: BargeVisit | RailVisit | RoadVisit;

  //Component properties
  @Input() voyageCancelled: boolean;
  @Input() voyageData: VoyageData;
  @Input() visitTerminal: Terminal;
  @Input() visitStatus: VisitStatusType;
  @Input() visitDeclarationStatus: any
  @Input() visitDeclaration: VisitDeclaration
  @Input() visitDeclarations: VisitDeclaration[]

  //Component and chained properties
  @Input() openVisitId: string;
  @Input() openHandlingId: string;
  @Input() hasRailTemplate: boolean;

  //Chained properties --> Try to make obsolete
  @Input() voyage: Voyage;
  @Input() visits: Visit[];
  @Input() integralPlanningRequested: boolean;
  @Input() shareWithNextlogicRequested: boolean;
  @Input() nextlogicEnabled: boolean;
  @Input() voyageDeclarantShortname: string;
  @Input() voyageDeclarant: HinterlandOrganisation;
  @Input() voyageCargoDeclarants: HinterlandOrganisation[];
  @Input() voyageStatus: VoyageStatus;
  @Input() originalVoyageId: string;
  @Input() declareCargoAllowed: boolean;

  @Output() visitEtaUpdatedEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() visitDeletedEventEmitter: EventEmitter<any> = new EventEmitter();

  status: string;
  showOnLoad: boolean;
  activeTab: string;
  terminalDisplayName: string;

  isModalityBarge: boolean;
  isModalityRail: boolean;
  isModalityRoad: boolean;

  trainCompositionUploadEnabled: boolean;
  expiryChecker: number;

  ngOnInit(): void {
    this.isModalityBarge = this.voyage.modality === 'barge';
    this.isModalityRail = this.voyage.modality === 'rail';
    this.isModalityRoad = this.voyage.modality === 'road';

    this.activeTab = !!this.openHandlingId || (this.visit.status && this.isModalityRail) ? 'handlingsTab' : 'visitTab'
    this.terminalDisplayName = HinterlandUtils.getTerminalDisplayName(this.visit.terminal, this.visit.modality);

    this.setTrainCompositionUploadEnabled();
    if (this.isModalityRail) {
      this.expiryChecker = setInterval(() => {
        this.setTrainCompositionUploadEnabled();
      }, 10_000);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visitTerminal || changes.visitStatus || changes.visitDeclaration || changes.visitDeclarationStatus || changes.isModalityBarge) {
      if (this.visitTerminal && this.visitTerminal.nonPcs) {
        this.status = null;
      } else if (this.visitStatus === 'COMPLETED' || this.visitStatus === 'IN_OPERATION') {
        this.status = this.visitStatus;
      } else if (this.visitDeclarationStatus === 'ACCEPTED' || this.visitDeclarationStatus === 'REJECTED') {
        this.status = this.visitDeclarationStatus;
      } else if (this.visitDeclarationStatus === 'DECLARED') {
        if (!!this.visitDeclaration && this.visitDeclaration.acknowledgedByTerminal) {
          this.status = 'PROCESSED';
        } else {
          this.status = 'DECLARED';
        }
      } else {
        this.status = this.visit.status;
      }
    }

    if (changes.visit || changes.openVisitId) {
      this.showOnLoad = !this.visit.visitId || this.visit.visitId == this.openVisitId;
    }

    this.terminalDisplayName = HinterlandUtils.getTerminalDisplayName(this.visit.terminal, this.visit.modality);
  }

  ngOnDestroy() {
    clearInterval(this.expiryChecker);
  }

  removeVisitTemplate = (visitTemplate) => {
    this.visits.splice(this.visits.indexOf(visitTemplate), 1);
  }

  uploadTrainComposition(excelFile: File) {
    new TrainCompositionUpload().upload(excelFile, {voyageId: this.voyageId, visitId: this.visit.visitId});
  }

  downloadLoadDischargePlan() {
    RailExcelUtils.downloadTrainComposition(<RailVoyage>this.voyage, <RailVisit>this.visit);
  }

  downloadTrainCompositionAfterOperations() {
    RailExcelUtils.downloadTrainCompositionAfterOperations(<RailVoyage> this.voyage, <RailVisit>this.visit);
  }

  setTrainCompositionUploadEnabled(): void {
    if (this.isModalityRail) {
      const visitStatus: RailVisitStatus = <RailVisitStatus>(this.visit.plannedVisitData);
      if (visitStatus && visitStatus.cutoffTime) {
        const now = new Date();
        const cutoffTime = new Date((visitStatus).cutoffTime);
        this.trainCompositionUploadEnabled = now < cutoffTime;
      } else {
        this.trainCompositionUploadEnabled = true;
      }
    } else {
      this.trainCompositionUploadEnabled = false;
    }
  }

  uploadButtonTitle(): string {
    const visitStatus: RailVisitStatus = <RailVisitStatus>(this.visit.plannedVisitData);
    if (visitStatus && visitStatus.cutoffTime) {
      const cutoffTime = new Date(visitStatus.cutoffTime);
      return 'Upload train composition possible until ' + moment(cutoffTime).format('DD MMMM, HH:mm')
    }
    return 'Upload train composition'
  }

}
