import {Component, Input} from '@angular/core';
import {PlannedCargo, Slot, TrainComposition} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-train-composition',
  templateUrl: './train-composition.component.html',
  styleUrls: ['./train-composition.component.scss']
})
export class TrainCompositionComponent {

  @Input() trainComposition: TrainComposition;

  getEquipmentNumber(slotNumber: number, slots: Slot[], type: Direction) {
    let cargo: PlannedCargo = this.getCargo(slotNumber, slots, type);

    if (!cargo) {
      return undefined;
    }

    return cargo.cargoData.equipmentNumber ? cargo.cargoData.equipmentNumber : '-';
  }

  getType(slotNumber, slots: Slot[], type: Direction) {
    let cargo: PlannedCargo = this.getCargo(slotNumber, slots, type);

    if (!cargo) {
      return "hidden";
    }

    switch (cargo.type) {
      case 'discharge':
        return "fa-arrow-up";
      case 'loading':
        return "fa-arrow-down";
      case 'remain':
        return "fa-equals";
      case 'shift':
        return "fa-arrow-up-arrow-down";
      default:
        return "hidden";
    }
  }

  getCargo(slotNumber: number, slots: Slot[], type: Direction): PlannedCargo {
    let slot: Slot = slots.find(s => s.number == slotNumber);

    if (!slot || (type == 'incoming' && !slot.incomingCargo) || (type == 'outgoing' && !slot.outgoingCargo)) {
      return undefined;
    }

    return type == 'incoming' ? slot.incomingCargo : slot.outgoingCargo;
  }

}

export type Direction = 'incoming' | 'outgoing';
