<app-tooltip class="formGroupLabel" *ngIf="!!handlingStats" [disabled]="handlingStats.handlingsInError===0 && !timeslot">
  <div class="tooltipContent text-start">
    <ng-container *ngIf="!this.timeslot">
      <div class="row align-items-center" *ngFor="let h of handlingStats.errorHandlings">
        <div class="col">
          <span *ngIf="h.handlingData.type === 'loading'" class="fa fa-arrow-down me-2"></span>
          <span *ngIf="h.handlingData.type === 'discharge'" class="fa fa-arrow-up me-2"></span>
          <ng-container *ngIf="!!h.handlingData.equipmentNumber">{{h.handlingData.equipmentNumber}}</ng-container>
          <ng-container *ngIf="!h.handlingData.equipmentNumber">Empty</ng-container>
        </div>
        <div class="col" *ngIf="h.declarationStatus==='REJECTED'">
            <span class="position-absolute">
            <span class="fa fa-stack-1x fa-circle text-white"></span>
            <span class="fa fa-stack-1x fa-times-circle text-danger"></span>
            </span> <span class="ms-4"> Rejected</span>
        </div>
        <div class="col" *ngIf="h.declarationStatus!=='REJECTED'">
          <span *ngFor="let status of h['acceptStatuses'];let last=last;" class="fa"
                [ngClass]="[status['icon'], status['class'], last?'':'me-3']"></span>
        </div>
      </div>
      <div class="mt-1" style="margin-left: 20px"
           *ngIf="handlingStats.handlingsInError>5">{{handlingStats.handlingsInError - 5}} more
      </div>
    </ng-container>
   <ng-container *ngIf="!!this.timeslot">
    <div class="row">
      <div class="col-5">
        &nbsp;
      </div>
      <div class="col">
        D
      </div>
      <div class="col">
        L
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        Slot:
      </div>
      <div class="col">
        {{timeslot.totalDischarge}}
      </div>
      <div class="col">
        {{timeslot.totalLoading}}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        Estimated:
      </div>
      <div class="col">
        {{handlingStats.dischargeEstimated}}
      </div>
      <div class="col">
        {{handlingStats.loadingEstimated}}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        Preannounced:
      </div>
      <div class="col">
        {{handlingStats.dischargePrenotification}}
      </div>
      <div class="col">
        {{handlingStats.loadingPrenotification}}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        Handled:
      </div>
      <div class="col">
        {{handlingStats.dischargeHandled}}
      </div>
      <div class="col">
        {{handlingStats.loadingHandled}}
      </div>
    </div>
   </ng-container>
  </div>
  <div style="display: flex; align-items: center;">
    <span class="fa fa-arrow-up"></span>&nbsp;
    <ng-container *ngIf="!inOperation && !showSlotTotals">
      {{handlingStats.dischargePrenotification}}
      <ng-container *ngIf="handlingStats.dischargeEstimated">/{{handlingStats.dischargeEstimated}}</ng-container>
    </ng-container>
    <span style="display: inline-block; text-align: right" *ngIf="!inOperation && showSlotTotals">
      <div *ngIf="timeslot.totalDischarge != handlingStats.dischargeEstimated" class="text-secondary">{{timeslot.totalDischarge}}</div>
      <div>{{handlingStats.dischargeEstimated}}</div>
    </span>
    <ng-container *ngIf="inOperation">
      {{handlingStats.dischargeHandled}}/{{handlingStats.dischargePrenotification}}
    </ng-container>
    <span class="ms-1 fa fa-exclamation-circle text-warning" *ngIf="showDischargeWarning"></span>
    <span class="ms-2 fa fa-arrow-down"></span>&nbsp;
    <ng-container *ngIf="!inOperation && !showSlotTotals">
      {{handlingStats.loadingPrenotification}}
      <ng-container *ngIf="handlingStats.loadingEstimated">/{{handlingStats.loadingEstimated}}</ng-container>
    </ng-container>
    <span style="display: inline-block; text-align: right" *ngIf="!inOperation && showSlotTotals">
      <div *ngIf="timeslot.totalLoading != handlingStats.loadingEstimated" class="text-secondary">{{timeslot.totalLoading}}</div>
      <div>{{handlingStats.loadingEstimated}}</div>
    </span>
    <ng-container *ngIf="inOperation">
      {{handlingStats.loadingHandled}}/{{handlingStats.loadingPrenotification}}
    </ng-container>
    <span class="ms-1 fa fa-exclamation-circle text-warning" *ngIf="showLoadingWarning"></span>
    <span class="ms-1 fa fa-check-circle text-success" *ngIf="handlingStats.accepted && !inOperation"></span>
    <span class="text-danger" *ngIf="!(terminalView && visit.modality == 'rail') && handlingStats.handlingsInError > 0">
      <span class="ms-1 fa fa-exclamation-triangle"></span> {{handlingStats.handlingsInError}}</span>
  </div>
</app-tooltip>
<app-tooltip *ngIf="!!visit && visit['loadDischargeListEnabled']">
  <span class="fa fa-stack">
    <i class="fas fa-stack-1x fa-th"
       [ngClass]="pastLoadDischargeListClosing && !visit.minimumCallSizeReached?'text-danger':'text-dark'"></i>
    <i *ngIf="visit.loadDischargeListStatus == 'reopened'"
       class="fas fa-stack-1x fa-lock-open cornered-tr text-secondary"></i>
    <i *ngIf="visit.loadDischargeListStatus == 'closed'" class="fas fa-stack-1x fa-lock cornered-tr text-secondary"></i>
    <i *ngIf="visit.loadDischargeListStatus == 'sent'"
       class="fa fa-stack-1x fa-angle-double-right cornered-tr text-success"></i>
  </span>
  <div class="tooltipContent">
    <div class="text-start row">
      <b class="col" style="border-bottom: 2px solid #ffffff">Load-Discharge list</b>
    </div>
    <div class="text-start row justify-content-between">
      <span class="col-8 col-md-6" style="color:#C0C0C0;">Status: </span>
      <span class="col-4 col-md-6">{{visit.loadDischargeListStatus}}</span>
      <span class="col-8 col-md-6" *ngIf="!visit.loadDischargeListClosed && visit.loadDischargeListClosing"
            style="color:#C0C0C0;">Closing time: </span>
      <span class="col-4 col-md-6"
            *ngIf="!visit.loadDischargeListClosed && visit.loadDischargeListClosing">{{visit.loadDischargeListClosing | timestamp}}</span>
      <span class="col-8 col-md-6" *ngIf="visit.loadDischargeListClosed && visit.loadDischargeListStatus != 'reopened'"
            style="color:#C0C0C0;">Closed time: </span>
      <span class="col-4 col-md-6"
            *ngIf="visit.loadDischargeListClosed && visit.loadDischargeListStatus != 'reopened'">{{visit.loadDischargeListClosed | timestamp}}</span>
      <span class="col-8 col-md-6" *ngIf="visit.minimumCallSize" style="color:#C0C0C0;">Minimum&nbsp;callsize: </span>
      <span class="col-4 col-md-6" *ngIf="visit.minimumCallSize">{{visit.minimumCallSize}}&nbsp;<span
        class="badge rounded-pill bg-success" *ngIf="visit.minimumCallSize && visit.minimumCallSizeReached"><span
        class="fa fa-check"></span></span></span>
      <span class="col-10 col-md-6" *ngIf="handlingStats.handlingCount > 0"
            style="color:#C0C0C0;">Containers&nbsp;OK: </span>
      <span class="col-2 col-md-6" *ngIf="handlingStats.handlingCount > 0 && visit.minimumCallSizeReached"><b
        class="badge rounded-pill bg-success">{{handlingStats.handlingsAllOK}}</b></span>
      <span class="col-2 col-md-6" *ngIf="handlingStats.handlingCount > 0 && !visit.minimumCallSizeReached"><b
        class="badge rounded-pill bg-danger">{{handlingStats.handlingsAllOK}}</b></span>
    </div>
  </div>
</app-tooltip>
<div *ngIf="!handlingStats && !template">no handling data loaded</div>
