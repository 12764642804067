import {Component, Input} from '@angular/core';
import {AppContext} from "../../../app-context";
import {sendQuery} from "../../../common/utils";
import {GetFriendlyVisitId} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Component({
  selector: 'app-visit-admin',
  templateUrl: './visit-admin.component.html',
  styleUrls: ['./visit-admin.component.scss']
})
export class VisitAdminComponent {

  appContext = AppContext;

  @Input() voyageId: string;
  @Input() originalVoyageId: string;
  @Input() visitId: string;
  @Input() visitTerminalShortname: string;
  @Input() isModalityBarge: boolean;

  openOutgoingIftsaiMessages() {
    const payload = <GetFriendlyVisitId>{
      voyageId: this.voyageId,
      visitId: this.visitId
    };

    if (this.isModalityBarge && 'RWG' !== this.visitTerminalShortname && this.originalVoyageId) {
      payload.voyageId = this.originalVoyageId;
    }

    sendQuery('com.portbase.common.api.admin.GetFriendlyVisitId', payload, {caching: false})
      .subscribe(id => AppContext.redirectToMessageManagement(id));
  }

}
