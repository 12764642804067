import {Component, Input} from '@angular/core';
import {CargoMovement, Shipment, Stop} from '@portbase/hinterland-service-typescriptmodels';
import {cloneObject, lodash} from "../../../common/utils";
import {ComparatorChain} from '../../../common/comparator-chain';
import {AppContext} from "../../../app-context";
import {Router} from "@angular/router";

@Component({
  selector: 'app-cargo-shipment',
  templateUrl: './cargo-shipment.component.html',
  styleUrls: ['./cargo-shipment.component.scss']
})
export class CargoShipmentComponent {
  private static movementComparator = new ComparatorChain('start').compare;

  cargoShipment: Shipment;
  movements: CargoMovement[] = [];

  constructor(private router: Router) {
  }

  @Input('cargoShipment') set setConsignment(consignment: Shipment) {
    this.cargoShipment = consignment;
    this.movements = cloneObject(consignment.movements);
    this.movements.forEach(m => {
      const lastArrivalIndex: number = lodash.findLastIndex(m.itinerary, s => !!s.ata || !!s.atd);
      if (lastArrivalIndex >= 0) {
        for (let i = 0; i <= lastArrivalIndex; i++) {
          (<UiStop>m.itinerary[i]).arrived = true;
        }
      }
    });

    const hinterlandMovements = this.movements.slice(1);
    hinterlandMovements.sort(CargoShipmentComponent.movementComparator);
    this.movements.splice(1, hinterlandMovements.length, ...hinterlandMovements);
  }

  getRotationLink(movement: CargoMovement) {
    if (!AppContext.isAdmin() && movement.operator?.portbaseId !== AppContext.userProfile.organisationShortName) {
      return null;
    }

    if (!movement.vessel || lodash.includes(['road', 'sea'], movement.vessel.modality) ||
      !movement.portbaseVoyageId || movement.itinerary.length === 0) {
      return null;
    }

    const stop = this.cargoShipment.data.type === 'IMPORT' ? lodash.head(movement.itinerary) : lodash.last(movement.itinerary)
    const handlingId = this.cargoShipment.data.type === 'IMPORT' ? movement.portbaseLoadingId : movement.portbaseDischargeId;

    if(!stop.portbaseId || !handlingId) {
      return null
    }

    return `/rotation/${movement.portbaseVoyageId}?visitId=${stop.portbaseId}&handlingId=${handlingId}`;
  }

  navigateToRotation(movement: CargoMovement) {
    this.router.navigateByUrl(this.getRotationLink(movement))
  }
}

export interface UiStop extends Stop {
  arrived: boolean;
}
