import {Component} from '@angular/core';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {HandlingModel, HinterlandUtils, voyageComparator, VoyageUnion} from '../hinterland-utils';
import {
  DateTimeRange,
  DetachedHandling,
  FindDetachedHandlings,
  FindVoyages,
  GetDetachedHandlings,
  GetVoyages,
  Handling,
  HandlingData,
  Voyage
} from '@portbase/hinterland-service-typescriptmodels';
import {combineLatest, Observable} from 'rxjs';
import {AppContext} from '../../app-context';
import {filterByTerm, lodash, removeItem, replaceItem, sendQuery, uuid} from '../../common/utils';
import {map, tap} from 'rxjs/operators';
import moment from "moment";
import {HandlingsUpload} from "../../common/upload/handlings.upload";
import {BargeHandlingsUpload} from "../barge/barge-handlings.upload";
import {RailHandlingsUpload} from "../rail/rail-handlings.upload";

@Component({
  selector: 'app-handling-overview',
  templateUrl: './handling-overview.component.html',
  styleUrls: ['./handling-overview.component.css']
})
export class HandlingOverviewComponent extends AbstractOverviewComponent<VoyageOrHandling> {
  detached: HandlingModel[] = [];
  expected: VoyageUnion[] = [];
  inOperation: VoyageUnion[] = [];
  completed: VoyageUnion[] = [];
  appContext = AppContext;
  pagingSupported: boolean = AppContext.isAdmin() || AppContext.isHinterlandTerminal();

  /*
    Loading
   */

  ngOnInit() {
    super.ngOnInit();
    this.appContext.persistedFilterValuesChanged.subscribe(() => {
      this.renderFilteredItems()
    });
  }

  searchOrFilter = (): (VoyageOrHandling[] | Observable<VoyageOrHandling[]>) => {
    if (this.pagingSupported) {
      return this.reload();
    } else {
      return this.getFilteredItems();
    }
  };

  public filterItems() {
    let filteredItems = this.items;
    if (AppContext.isFiltering("Rail")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'rail');
    } else if (AppContext.isFiltering("Barge")) {
      filteredItems = filteredItems.filter(item => !item["modality"] || item["modality"] == 'barge');
    }
    if (this.filterTerm) {
      this.generateSearchableItems();
      filteredItems = filteredItems.filter(filterByTerm(this.filterTerm, this.excludedFilterFields, this.searchableItems));
    }
    if (filteredItems.length < 10) this.loadAndRenderNextPage();
    return filteredItems;
  }

  getFilteredItems = (): VoyageOrHandling[] => {
    const filteredItems:any = [];

    const voyageOrHandlings:VoyageOrHandling[] = this.filterItems();
    for (const voyageOrHandling of voyageOrHandlings) {
      let filtered = false;
      if (voyageOrHandling.voyageStatus) {
        filtered = true;
        this.filterAndSetVoyageHandlings(voyageOrHandling);
      } else if (this.isHandlingFiltered(<HandlingModel> voyageOrHandling, AppContext.persistedFilterValues)) {
        filtered = true;
      }
      if (filtered) {
        filteredItems.push(voyageOrHandling);
      }
    }

    return filteredItems;
  }

  filterAndSetVoyageHandlings(voyage:any) {
    if (voyage['allHandlings']) {
      const filteredHandlings = [];
      for (const handling of voyage['allHandlings']) {
        if (this.isHandlingFiltered(handling, AppContext.persistedFilterValues)) {
          filteredHandlings.push(handling);
        }
      }
      voyage['handlings'] = filteredHandlings;
    } else {
      voyage['handlings'] = undefined;
    }
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<VoyageOrHandling[]> {
    let voyages: Observable<VoyageOrHandling[]>, detached: Observable<VoyageOrHandling[]>;
    {
      let type = AppContext.isHinterlandTerminal() && !AppContext.isAdmin() ? 'com.portbase.hinterland.api.common.query.GetVoyagesForTerminal' : 'com.portbase.hinterland.api.common.query.GetVoyages';
      const payload = <GetVoyages>{dateTimeRange: dateTimeRange, excludeVisits: true};
      if (this.pagingSupported) {
        payload.from = this.from;
        payload.maxHits = this.maxItems;
        if (this.filterTerm) {
          (<FindVoyages>payload).term = this.filterTerm;
          type = AppContext.isHinterlandTerminal() && !AppContext.isAdmin() ? 'com.portbase.hinterland.api.common.query.FindVoyagesForTerminal' : 'com.portbase.hinterland.api.common.query.FindVoyages';
        }
      }
      voyages = sendQuery(type, payload, {caching: false, showSpinner: !this.unsubscribeHandle})
        .pipe(map(results => results.filter(voyage => AppContext.shouldProcessBargeOrRailVoyage(voyage)).sort(voyageComparator)));
    }

    {
      const modality = [];
      if (AppContext.isHinterlandTerminal() || AppContext.isBargeDeclarant()) {
        modality.push('barge');
      }
      if (AppContext.isRailDeclarant()) {
        modality.push('rail');
      }
      const payload = <GetDetachedHandlings>{dateTimeRange: dateTimeRange, excludeEmptyEta: this.excludeEmptyEta, modality: <any> modality};
      let type = AppContext.isHinterlandTerminal() && !AppContext.isAdmin() ? 'com.portbase.hinterland.api.common.query.GetDetachedHandlingsForTerminal' : 'com.portbase.hinterland.api.common.query.GetDetachedHandlings';
      if (this.pagingSupported) {
        payload.from = this.from;
        payload.maxHits = this.maxItems;
        if (this.filterTerm) {
          (<FindDetachedHandlings>payload).term = this.filterTerm;
          type = AppContext.isHinterlandTerminal() && !AppContext.isAdmin() ? 'com.portbase.hinterland.api.common.query.FindDetachedHandlingsForTerminal' : 'com.portbase.hinterland.api.common.query.FindDetachedHandlings';
        }
      }
      detached = sendQuery(type, payload, {caching: false, showSpinner: !this.unsubscribeHandle})
        .pipe(tap((handlings: HandlingModel[]) => handlings.forEach(addStatus)));
    }
    return combineLatest([voyages, detached])
      .pipe(map(([v, d]) => v.concat(d)));
  }


  fetchHandlings = (voyage: VoyageUnion) => {
    sendQuery(AppContext.isHinterlandTerminal() && !AppContext.isAdmin() ?"com.portbase.hinterland.api.common.query.GetVoyageForTerminal" :  "com.portbase.hinterland.api.common.query.GetVoyage", {voyageId: voyage.voyageId},
      {caching: false, showSpinner: true})
      .subscribe((voyage: Voyage) => {
        const matchingVoyage:VoyageOrHandling = this.items.find(v => v.voyageId === voyage.voyageId);
        if (matchingVoyage) {
          const handlingModels = [];
          voyage.visits.filter(filterByTerm(this.filterTerm)).forEach(visit => {
            const worstVisitHandlingStatus = HinterlandUtils.getWorstVisitHandlingStatus(visit);
            visit.handlings.forEach(h => {
              h.voyageId = voyage.voyageId;
              h.voyageStatus = voyage.voyageStatus;
              h.modality = voyage.modality;
              h.declarant = voyage.declarant;
              h.cargoDeclarants = voyage.cargoDeclarants;
              h.visitId = visit.visitId;
              h.visitStatus = visit.plannedVisitData;
              h.terminal = visit.terminal;
              h.eta = visit.visitData.eta;
              h.etd = visit.visitData['etd'];
              h.visitData = visit.visitData;
              h.visitArrived = visit.arrived;
              h.cutOffInMinutes = HinterlandUtils.findTerminalSettings(h).cargoCutOffInMinutes;
              h.visitLoadDischargeListStatus = visit['loadDischargeListStatus'];
              h.visitWaitForCloseVisitEnabled = visit['waitForCloseVisitEnabled'];
              h.worstVisitHandlingStatus = worstVisitHandlingStatus;
              h.status = HinterlandUtils.getHandlingStatus(h);
              handlingModels.push(h);
            });
          });
          matchingVoyage['allHandlings'] = handlingModels;
          this.filterAndSetVoyageHandlings(matchingVoyage);
        }
      });
  };

  get excludeEmptyEta() {
    return "true" === localStorage.getItem("excludeEmptyEta");
  }

  set excludeEmptyEta(value: boolean) {
    localStorage.setItem("excludeEmptyEta", value.toString());
    this.loadAndRender(true);
  }

  /*
    Rendering
   */

  doRender = (items: VoyageOrHandling[]) => {
    const detached = [], expected = [], inOperation = [], completed = [];
    items.forEach(v => {
      if (!v.voyageStatus) {
        detached.push(v);
      } else if (this.countHandling([<Voyage>v]) > 0) {
        switch (v.voyageStatus) {
          case 'IN_OPERATION':
            inOperation.push(v);
            break;
          case 'COMPLETED':
          case 'CANCELLED':
            completed.push(v);
            break;
          default:
            expected.push(v);
            break;
        }
      }
    });
    this.detached = detached;
    this.inOperation = inOperation;
    this.completed = completed;
    this.expected = expected;
  };

  countHandling = (voyages: Voyage[]): number => lodash.sumBy(voyages, v => {
    return HinterlandUtils.getHandlingCount(v);
  });

  trackByVoyageId = (index: number, voyage: Voyage) => voyage.voyageId;

  /*
    Adding and removing
   */

  addHandling = () => {
    const newHandling: HandlingModel = <HandlingModel>{
      new: true, //used to differentiate new from existing handlings
      handlingId: uuid(),
      modality: "barge",
      handlingData: <HandlingData>{
        dangerousGoods: [],
        shippersOwned: false
      },
      terminal: {terminalSettings: []},
      preNotification: true,
      handlingDeclarations: [],
      declarant: AppContext.userProfile.organisation
    };
    addStatus(newHandling);
    this.items.splice(0, 0, newHandling);
    this.detached.splice(0, 0, newHandling);
  };

  deleteHandling = (handling: HandlingModel) => {
    removeItem(this.items, handling);
    removeItem(this.detached, handling);
  };

  uploadDetachedHandlings(excelFile: File, modality: string) {
    const handlingsUpload:HandlingsUpload = modality === 'barge' ? new BargeHandlingsUpload() : new RailHandlingsUpload();
    handlingsUpload.uploadDetached(excelFile, null);
  }

  /*
    Websocket updates
   */

  onUpdateVoyage = (voyage: VoyageUnion) => {
    if (!voyage) {
      return;
    }
    if (AppContext.shouldProcessBargeOrRailVoyage(voyage) && HinterlandUtils.hasEtaInRange(voyage, this.dateRange.start, this.dateRange.end)) {
      if (AppContext.isHinterlandTerminalAndDeclarant()) {
        voyage = HinterlandUtils.removeWhatDeclarantShouldNotSee(voyage, AppContext.userProfile.organisationShortName);
      }
      if (!!voyage) {
        const previousVoyage = this.items.find(v => v.voyageId === voyage.voyageId);
        replaceItem(this.items, previousVoyage, <any>voyage);
        this.items.sort(voyageComparator);
        this.renderFilteredItems();
      }
    }
  };

  onUpdateDetachedHandling = (handling:DetachedHandling) => {
    if ((handling.modality === 'rail' || handling.modality === 'barge')
      && ((!handling.eta && !this.excludeEmptyEta) || (!!handling.eta && moment(handling.eta).isBetween(this.dateRange.start, this.dateRange.end)))) {
      const previousHandling = this.items.find(h => h['handlingId'] === handling.handlingId);

      if(handling.attached) {
        removeItem(this.detached, previousHandling);
        removeItem(this.items, previousHandling);
      } else {
        const newHandling = addStatus(handling);
        replaceItem(this.detached, previousHandling, newHandling);
        replaceItem(this.items, previousHandling, newHandling);
      }

      this.renderFilteredItems();
    }
  };
}

function addStatus(handling: Handling): HandlingModel {
  (<HandlingModel>handling).status = HinterlandUtils.getHandlingStatus(<any>handling);
  return <HandlingModel>handling;
}

export type VoyageOrHandling = HandlingModel | VoyageUnion;


