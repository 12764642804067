<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>Move handling</div>
  </div>
  <div aria-label="Close" class="icon-button position-top-right" data-bs-dismiss="modal" tabindex="0">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </div>
  <div class="modal-body">
    By moving this handling to another visit will cancel its existing visit with the terminal.
  </div>
  <div class="modal-footer">
    <button (click)="keep()" data-bs-dismiss="modal" class="btn btn-secondary" type="button">Cancel</button>
    <button (click)="move()" data-bs-dismiss="modal" class="btn btn-primary" type="button">Move handling</button>
  </div>
</div>
