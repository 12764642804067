import {Component, Input} from '@angular/core';
import {sendCommand} from "../../../../common/utils";
import {InformByMail} from "@portbase/hinterland-service-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";

@Component({
  selector: 'app-email-handling',
  templateUrl: './email-handling.component.html',
  styleUrls: ['./email-handling.component.scss']
})
export class EmailHandlingComponent {

  @Input() data: ClosableModal;

  emailAddress: string;

  emailHandling() {
    sendCommand('com.portbase.common.api.reporting.InformByMail', <InformByMail>{
        handlingIds: [this.data['handlingId']],
        url: window.location.href,
        to: this.emailAddress
      },
      () => {
        AppContext.publishSuccessMessage("The selected handlings were successfully emailed to '" + this.emailAddress + "'.");
      }, (error) => {
        let errorMessage = error?.error?.error;
        AppContext.publishErrorMessage(errorMessage ? errorMessage : "An error occurred sending the email to '" + this.emailAddress + "'.");
      }
    );
  }
}
