import {Component, Input} from "@angular/core";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {ActionMode} from "../../../../hinterland/types";

@Component({
  selector: 'app-remain-on-truck-panel',
  templateUrl: './app-remain-on-truck-panel.html',
  styleUrls: ['./app-remain-on-truck-panel.scss']
})
export class RemainOnTruckComponent extends HinterlandBaseComponent {
  @Input() actionMode: ActionMode;
  @Input() containerNumber: string;
}
