<div *ngIf="hasVisibleHandlings()" class="row narrow-gutters align-items-center bg-light py-2 mb-2">
  <div class="col-auto" style="min-width: 3rem;">
    <div class="custom-check custom-check-inline">
      <input (click)="allHandlingsSelected() ? deselectAll() : selectAll()"
             [disabled]="disabled()"
             [ngClass]="{'invisible' : selectableHandlings === 0}"
             [ngModel]="allHandlingsSelected()" class="custom-check-input" type="checkbox"></div>
  </div>
  <div class="col me-3">
    <div class="row align-items-center">
      <div (click)="sortBy = getSortDirection('visit'); setHandlings = handlings" *ngIf="displayVisitInfo"
           class="col-md-6 col-lg-4 d-md-block sort-by">
        Visit <span *ngIf="sortBy === 'visit'" class="fa fa-sort-down"></span><span *ngIf="sortBy === 'visitReversed'"
                                                                                    class="fa fa-sort-up"></span>
      </div>
      <div (click)="sortBy = getSortDirection('handling'); setHandlings = handlings"
           [ngClass]="{'col-md-5': displayVisitInfo, 'col-lg-9': !displayVisitInfo}"
           class="col-11 sort-by">
        Handling <span *ngIf="sortBy === 'handling'" class="fa fa-sort-down"></span><span
        *ngIf="sortBy === 'handlingReversed'" class="fa fa-sort-up"></span>
      </div>
      <div (click)="sortBy = getSortDirection('status'); setHandlings = handlings" class="col-lg-3 d-lg-block sort-by">
        Status <span *ngIf="sortAcceptStatusesBy">( <span class="fa {{sortAcceptStatusesBy.icon}} text-danger"
                                                          style="font-size:67%"></span> ) </span>
        <span *ngIf="sortBy === 'status'" class="fa fa-sort-down"></span>
        <span *ngIf="sortBy === 'statusReversed'" class="fa fa-sort-up"></span>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="pagination">
  <app-pagination #paginationComponent [items]="handlings">
    <ng-container [ngTemplateOutletContext]="{items: paginationComponent.getItemsOnPage()}"
                  [ngTemplateOutlet]="handlingsview">
    </ng-container>
  </app-pagination>
</ng-container>
<ng-container *ngIf="!pagination">
  <ng-container [ngTemplateOutletContext]="{items: handlings}" [ngTemplateOutlet]="handlingsview">
  </ng-container>
</ng-container>

<ng-template #handlingsview let-items="items">
  <ng-container *ngFor="let handling of items; trackBy: utils.trackByHandlingId; let index = index">
    <div *ngIf="visible(handling)" style="display:flex">
      <div style="flex-grow:1">
        <div *ngIf="index > 0 && handling.voyageId !== items[index - 1].voyageId" class="py-1"></div>
        <app-collapse #handlingCollapse [collapse]="collapse"
                      [ngClass]="{'enabled': utils.isCargoDeclarant(handling)}"
                      [showOnLoad]="handling.new || handling.handlingId === selectedHandlingId">
          <div class="row narrow-gutters align-items-center">
            <div class="col-auto" style="min-width: 3rem">
              <div [ngClass]="{'invisible' : !selectable(handling)}" class="custom-check custom-check-inline">
                <input (ngModelChange)="$event ? selectItem(handling) : deselectItem(handling)"
                       [ngModel]="selectedHandlings.indexOf(handling.handlingId) >= 0"
                       class="custom-check-input" type="checkbox"></div>
            </div>
            <div class="col me-3">
              <div [ngClass]="{'cancelled' : handling.cancelled, 'border-dashed' : !handling.preNotification}"
                   class="collapsible road-handling row border py-1">
                <ng-container *ngIf="displayVisitInfo || !handling.new; else newentry">
                  <div *ngIf="displayVisitInfo" class="col-sm-6 col-md-6 col-lg-4 d-md-block">
                    <app-visit-status *ngIf="showVisitStatus(handling, index, items[index -1])"
                                      [allHandlingsInVisitCancelled]="allHandlingsInVisitCancelled(handling.visitId)"
                                      [handling]="handling">
                    </app-visit-status>
                  </div>
                  <div [ngClass]="{'col-md-5': displayVisitInfo, 'col-lg-9': !displayVisitInfo}" class="col-11">
                    <app-handling-summary [displayTerminalAndEta]="displayTerminalAndEta"
                                          [handling]="handling"></app-handling-summary>
                  </div>
                  <div class="col-lg-3 col-1 px-2 px-md-3 align-self-center">
                    <app-handling-status (selectedAcceptStatusType)="toggleAcceptStatusSorting($event)"
                                         [keepTooltipOpen]="handlingCollapse.showing"
                                         [setHandling]="handling"
                                         [status]="utils.getHandlingStatus(handling)"
                                         [terminal]="handling.terminal">
                    </app-handling-status>
                    <div class="float-end d-none d-lg-block align-self-center">
                      <h4 class="my-0 fa fa-chevron-right text-secondary"></h4>
                    </div>
                  </div>
                </ng-container>
                <ng-template #newentry>
                  <div class="col-12" style="line-height: 3.25rem">
                    New handling
                    <div class="float-end d-none d-lg-block">
                      <h4 class="my-0 fa fa-chevron-right text-secondary"></h4>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <ng-template #collapse>
            <div class="collapse">
              <div class="row my-3">
                <div class="col-auto" style="min-width: 3rem"></div>
                <div class="col">
                  <app-edit-handling (deleteHandling)="deleteHandling.emit(handling)"
                                     [copyHandling]="copyHandling.observers && copyHandling.observers.length > 0 ? copyHandling : null"
                                     [readonly]="readonly"
                                     [setHandling]="handling"></app-edit-handling>
                  <app-handling-admin [handling]="handling"></app-handling-admin>
                </div>
              </div>
            </div>
          </ng-template>
        </app-collapse>
      </div>
      <div *ngIf="this.displayVisitInfo && handling.voyageId" class="col-1 text-center to-voyage">
        <h4 (click)="editVoyage(handling)"
            *ngIf="index === 0 || handling.visitId !== handlings[index - 1].visitId"
            class="m-0 fa fa-external-link-alt text-secondary" title="To Rotation"></h4>
      </div>
    </div>
  </ng-container>
</ng-template>


<ng-container *ngIf="hideAccepted && completedCount > 1">
  <div class="row narrow-gutters align-items-center pt-3 always-enabled">
    <div class="col-auto" style="min-width: 3rem"></div>
    <div *ngIf="!viewCompleted" class="col">
      Hiding {{completedCount}} {{visitArrived ? 'completed' : 'accepted'}} handlings
      <button (click)="viewCompleted = true; updateSelectable()" class="btn btn-outline-info btn-sm ms-2" type="button">
        Show
      </button>
    </div>
    <div *ngIf="viewCompleted" class="col">
      Showing {{completedCount}} {{visitArrived ? 'completed' : 'accepted'}} handlings
      <button (click)="viewCompleted = false; updateSelectable()" class="btn btn-outline-info btn-sm ms-2"
              type="button">Hide
      </button>
    </div>
  </div>
</ng-container>

<!--attach / detach-->
<ng-container *ngIf="selectedHandlings.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center bg-white py-3 border-top">
    <div *ngIf="selectedHandlings.length === 1" class="col-auto mx-1">
      <button (click)="copy();" class="btn btn-secondary" tabindex="-1" type="button">Copy container</button>
    </div>
    <div class="col-auto mx-1">
      <button (click)="cancelling = true" class="btn btn-secondary" tabindex="-1"
              type="button">{{selectedHandlings.length > 1 ? 'Cancel ' + selectedHandlings.length + ' containers' : "Cancel container"}}</button>
    </div>
    <div *ngIf="onlyDetachableHandlingsSelected()" class="col-auto mx-1">
      <button (click)="detaching = true" class="btn btn-secondary" tabindex="-1"
              type="button">{{selectedHandlings.length > 1 ? 'Detach ' + selectedHandlings.length + ' containers' : "Detach container"}}</button>
    </div>
    <div class="col-auto mx-1">
      <button (click)="attaching = true" class="btn btn-info" tabindex="-1"
              type="button">{{selectedHandlings.length > 1 ? 'Attach ' + selectedHandlings.length + ' containers' : "Attach container"}}</button>
    </div>
    <div *ngIf="onlyPrenotifiable()" class="col-auto mx-1">
      <button (click)="prenotifySelected()" class="btn btn-info" tabindex="-1"
              type="button">{{selectedHandlings.length > 1 ? 'Prenotify ' + selectedHandlings.length + ' containers' : "Prenotify container"}}</button>
    </div>
    <div class="col-auto mx-1">
      <button [attr.data-bs-target]="'#emailHandlingsModal-' + uuid" class="btn btn-info" data-bs-toggle="modal" tabindex="-1"
              type="button">
        Email {{selectedHandlings.length > 1 ? selectedHandlings.length + " containers" : "container"}}</button>
    </div>
    <div *ngIf="appContext.isAdmin()" class="col-auto mx-1">
      <button class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
              style="min-width: 10rem;" type="button">Admin actions
        <span class="caret"></span></button>
      <ul class="dropdown-menu dropdown-menu-start">
        <li (click)="adminAction = 'resendWaiting'" class="dropdown-item">{{"Resend pending messages"}}</li>
        <li (click)="adminAction = 'resend'" class="dropdown-item">{{"Resend handling declaration"}}</li>
        <li (click)="adminAction = 'reject'"
            class="dropdown-item">{{selectedHandlings.length > 1 ? 'Reject ' + selectedHandlings.length + ' containers' : "Reject container"}}</li>
        <li (click)="adminAction = 'accept'"
            class="dropdown-item">{{selectedHandlings.length > 1 ? 'Accept ' + selectedHandlings.length + ' containers' : "Accept container"}}</li>
        <li (click)="adminAction = 'acceptCancel'"
            class="dropdown-item">{{selectedHandlings.length > 1 ? 'Accept ' + selectedHandlings.length + ' cancellations' : "Accept cancellation"}}</li>
      </ul>
    </div>
  </div>
  <ng-container *ngIf="detaching">
    <div class="fixed-bottom bg-white py-3 border-top">
      <div class="container-fluid">
        <div class="row narrow-gutters justify-content-center align-items-center">
          <div class="col-auto">
            <div>Detach container<span *ngIf="selectedHandlings.length > 1">s</span>. Are you sure?</div>
            <div class="small">Container<span *ngIf="selectedHandlings.length > 1">s</span> will be detached.</div>
          </div>
        </div>
        <div class="row narrow-gutters justify-content-center align-items-center mt-2">
          <div class="col-auto">
            <button (click)="detaching = false" class="btn btn-secondary mx-2" style="min-width: 8rem" tabindex="-1"
                    type="button">Cancel
            </button>
            <button (click)="moveSelected()" class="btn btn-info mx-2" style="min-width: 8rem" tabindex="-1"
                    type="button">Detach
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="attaching">
    <div class="fixed-bottom bg-white py-3 border-top">
      <div class="container-fluid">
        <div class="row narrow-gutters justify-content-center align-items-center">
          <div class="col-auto d-none d-sm-block">
            <div>Attach container<span *ngIf="selectedHandlings.length > 1">s</span>. Are you sure?</div>
            <div class="small" style="max-width: 24rem">The container<span *ngIf="selectedHandlings.length > 1">s</span>
              will be switched over to the new visit. Status info will be requested from the terminal directly.
            </div>
          </div>
          <div class="col-auto" style="min-width: 19rem">
            <div class="col-auto" style="min-width: 19rem">
              <app-search [(ngModel)]="visitForAttaching"
                          [autoSelectOnSingleResult]="true"
                          [dataKey]="'visitId'"
                          [modality]="getFirstSelectedHandling().modality"
                          [placeholder]="utils.findVisitPlaceholder(getFirstSelectedHandling().modality)"
                          [placement]="'top-left'"
                          [resultFormatter]="utils.visitResultFormatter"
                          [searchFunction]="utils.findVisits">
              </app-search>
            </div>
          </div>
        </div>

        <div class="row narrow-gutters justify-content-center align-items-center mt-2">
          <div class="col-auto">
            <button (click)="attaching = false; visitForAttaching = undefined" class="btn btn-secondary mx-2"
                    style="min-width: 8rem" tabindex="-1" type="button">Cancel
            </button>
            <button (click)="moveSelected()" [disabled]="!visitForAttaching" class="btn btn-info mx-2"
                    style="min-width: 8rem"
                    tabindex="-1" type="button">Attach
            </button>
            <button (click)="moveSelected(true)" [disabled]="!visitForAttaching" class="btn btn-info mx-2"
                    style="min-width: 8rem"
                    tabindex="-1" type="button">Attach and pre-notify
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="cancelling">
    <div class="fixed-bottom bg-white py-3 border-top">
      <div class="container-fluid">
        <div class="row narrow-gutters justify-content-center align-items-center">
          <div class="col-auto">
            <div>Cancel container<span *ngIf="selectedHandlings.length > 1">s</span>. Are you sure?</div>
          </div>
        </div>
        <div class="row narrow-gutters justify-content-center align-items-center mt-2">
          <div class="col-auto">
            <button (click)="cancelling = false" class="btn btn-secondary mx-2" style="min-width: 8rem" tabindex="-1"
                    type="button">Cancel
            </button>
            <button (click)="cancelSelected()" class="btn btn-info mx-2" style="min-width: 8rem" tabindex="-1"
                    type="button">Cancel container
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!!adminAction">
    <div class="fixed-bottom bg-white py-3 border-top">
      <div class="container-fluid">
        <div class="row narrow-gutters justify-content-center align-items-center">
          <div class="col-auto">
            <div>{{adminAction | titlecase}} container<span *ngIf="selectedHandlings.length > 1">s</span>. Are you sure?
            </div>
          </div>
        </div>
        <div class="row narrow-gutters justify-content-center align-items-center mt-2">
          <div class="col-auto">
            <button (click)="adminAction = null" class="btn btn-secondary mx-2" style="min-width: 8rem" tabindex="-1"
                    type="button">Cancel
            </button>
            <button (click)="resendWaitingSelected()" *ngIf="adminAction ==='resendWaiting'"
                    class="btn btn-info mx-2" style="min-width: 8rem" tabindex="-1" type="button">Resend waiting
            </button>
            <button (click)="resendSelected()" *ngIf="adminAction ==='resend'"
                    class="btn btn-info mx-2" style="min-width: 8rem" tabindex="-1" type="button">Resend (force)
            </button>
            <button (click)="rejectSelected()" *ngIf="adminAction ==='reject'"
                    class="btn btn-info mx-2" style="min-width: 8rem" tabindex="-1" type="button">Reject
            </button>
            <button (click)="acceptSelected()" *ngIf="adminAction ==='accept'"
                    class="btn btn-info mx-2" style="min-width: 8rem" tabindex="-1" type="button">Accept (all statuses
              ok)
            </button>
            <button (click)="acceptCancelSelected()" *ngIf="adminAction ==='acceptCancel'"
                    class="btn btn-info mx-2" style="min-width: 8rem" tabindex="-1" type="button">Accept cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<div class="modal fade" id="emailHandlingsModal-{{uuid}}" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width:400px">
      <div class="modal-header">
        <h5 class="modal-title">Enter email address:</h5>
      </div>
      <div class="modal-body" style="width:auto;">
        <div class="p-2">
          <input (keydown.enter)="closeModal();sendHandlingsByMail();" [(ngModel)]="sendHandlingsEmailAddress"
                 class="form-control" maxlength="64" type="text">
        </div>
        <div class="float-end p-2">
          <button (click)="sendHandlingsByMail()" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem"
                  type="button">Send email
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

