<div class="declaration-block">
  <h2>Arrivals</h2>
</div>

<div class="row narrow-gutters my-2">
  <div class="col-md-4 mb-1 mb-md-0" style="min-width: 19rem">
    <app-date-range (ngModelChange)="onDateSelection($event)" [ngModel]="dateRange"></app-date-range>
  </div>
  <div class="col-md-8 col-lg mb-1 mb-md-0">
    <app-filter (resultsFound)="renderFilteredItems()" [(ngModel)]="filterTerm" [searchFunction]="searchOrFilter"
                placeholder="Filter by consignment nr, container nr, etc..."></app-filter>
  </div>
  <div class="col-md-3 col-lg-1 mb-1 mb-md-0">
    <select [(ngModel)]="modalityFilter" (ngModelChange)="this.renderFilteredItems()" class="clickable font-awesome rounded form-select">
      <option value="all" class="font-awesome">&#xf0d1;&#xf21a;&#xf238;</option>
      <option value="road" class="font-awesome">&#xf0d1;</option>
      <option value="barge" class="font-awesome">&#xf21a;</option>
      <option value="rail" class="font-awesome">&#xf238;</option>
    </select>
  </div>
  <div class="col-md-9 col-lg-3 mb-1 mb-md-0">
    <app-select [(ngModel)]="terminalFilter"
                (ngModelChange)="this.renderFilteredItems()"
                [dataKey]="modalityFilter === 'rail' ? 'shortName' : 'quayName'"
                placeholder="Filter by terminal"
                [optionsProvider]="utils.getTerminals(modalityFilterOrRoad(), true, true, true)"
                [refreshWatch]="modalityFilter"
                optionLabel="displayName"
                optionSelectable="shortName" required>
    </app-select>
  </div>
</div>

<div *ngIf="!!items; else noArrivals">
  <div *ngIf="items.length > 0" class="mt-4 accordion" id="arrivalAccordion" data-userflow="shipment-accordion">
    <app-pagination #page [maxPerPage]="20" [items]="items">
      <ng-container *ngIf="page.getItemsOnPage() as pageItems">
        <div *ngFor="let arrival of asArrivals(pageItems); let index = index, trackBy: trackByArrivalId">
          <app-fancy-collapse [collapse]="details" data-userflow="shipment-collapse">
            <div class="p-2">
              <div class="row align-items-center">
                <div *ngIf="$any(arrival.movement.vessel) as vessel" class="col-auto">
                  <app-vessel [vessel]="vessel"></app-vessel>
                </div>
                <div *ngIf="arrival.movement.transportReference as value" class="col-auto small">
                  <span class="fa fa-dharmachakra"></span><span class="ms-2">{{value}}</span>
                </div>
                <div *ngIf="arrival.movement.operator as value" class="col-auto small">
                  <span class="fa fa-fw fa-users"></span><span class="ms-2">{{value.name}}</span>
                </div>
                <div *ngIf="getArrivalTime(arrival) as value" class="col-auto small">
                  <app-date-time [ngModel]="value" [readonly]="true"></app-date-time>
                </div>
                <div class="col-auto d-none d-lg-block ms-auto">
                  <app-cargo-summary [cargoShipments]="arrival.cargoShipments"></app-cargo-summary>
                </div>
              </div>
            </div>

            <ng-template #details>
              <div class="collapse" data-bs-parent="#arrivalAccordion">
                <div class="my-2 ms-3">
                  <app-shipments [items]="arrival.cargoShipments"></app-shipments>
                </div>
              </div>
            </ng-template>
          </app-fancy-collapse>

        </div>

      </ng-container>
    </app-pagination>
  </div>
</div>
<ng-template #noArrivals>
  <div class="text-center text-muted" style="padding: 20px 0">
    Shipments have not been reported yet.
  </div>
</ng-template>
