import {Component, Input} from '@angular/core';
import {AppContext} from '../../../../app-context';

@Component({
  selector: 'app-bhg-company-details',
  templateUrl: './bhg-company-details.component.html',
  styleUrls: ['./bhg-company-details.component.css']
})
export class BhgCompanyDetailsComponent {

  @Input() companyDetails: any;
  @Input() externalCompany: boolean;

  newAccount = !AppContext.binnenhavengeldUserProfile;

}
