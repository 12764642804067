import {Cell, WorkBookTemplate} from "./excel.utils";
import {sendQuery} from "../utils";
import {map} from "rxjs/operators";
import {GetLocation, GetShippingCompany, GetSizeType} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {ContainerNumberValidator} from "../container-number-validator";
import moment from 'moment';

export interface UploadData {
}

export abstract class Upload {

  static dateTimeFormat: string = "DD-MM-YYYY HH:mm";

  abstract getVersion(): string;

  abstract getRowCount(): number;

  abstract getTemplate(forDetached: boolean): WorkBookTemplate

  abstract upload(excelFile: File, data: UploadData)

  shippingCompanyMapper(scacCode: string, cell: Cell) {
    if (!scacCode) {
      return null;
    }
    return sendQuery('com.portbase.hinterland.api.common.query.GetShippingCompany', <GetShippingCompany>{scacCode: scacCode})
      .pipe(map(shippingCompany => {
        if (!shippingCompany) {
          throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown Shipping Company: ${scacCode}`
        }
        return shippingCompany;
      }));
  }

  sizeTypeMapper(code: string, cell: Cell) {
    if (!code) {
      return null;
    }

    return sendQuery('com.portbase.hinterland.api.common.query.GetSizeType', <GetSizeType>{code: code})
      .pipe(map(sizeType => {
        if (!sizeType) {
          throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown size type: ${code}`
        }
        return sizeType;
      }));
  }

  locationMapper(locationUnCode: string, cell: Cell) {
    if (!locationUnCode) {
      return null;
    }

    return sendQuery('com.portbase.hinterland.api.refdata.query.GetLocation', <GetLocation>{unCode: locationUnCode})
      .pipe(map(location => {
        if (!location) {
          throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown location unCode: ${locationUnCode}`
        }
        return location;
      }));
  }

  terminalByBicsOrSmdgMapper(code: string, cell: Cell) {
    if (!code) {
      return null;
    }

    return sendQuery('com.portbase.hinterland.api.refdata.query.GetTerminalByCode', {code: code})
      .pipe(map(terminal => {
        if (!terminal) {
          throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown terminal: ${code}`
        }
        return terminal;
      }));
  }

  shippersOwnedMapper(equipmentNumber: string): boolean {
    return equipmentNumber != null && !ContainerNumberValidator.compliesToIso6346(equipmentNumber);
  }

  fullEmptyMapper(value: string, cell: Cell): boolean {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      switch (value.toLowerCase()) {
        case 'f':
        case 'full':
          return true;
        case 'e':
        case 'empty':
          return false;
      }
    }

    throw `Cell ${cell.cell} in sheet "${cell.sheetName}" contains an unknown Full/Empty value: ${value}`
  }

  emptyStringToNullMapper(value: string): string {
    if (!value) {
      return null;
    }

    return value;
  }

  dateTimeToIsoStringMapper(dateTime: string): string {
    if (!dateTime) {
      return null;
    }

    return moment(dateTime, Upload.dateTimeFormat, true).toISOString();
  }

  toUpperCaseMapper(value: string): string {
    if (!value) {
      return null;
    }

    if (typeof value !== 'string') {
      return value;
    }

    return value.toUpperCase();
  }

}
