import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HinterlandOrganisation} from '@portbase/hinterland-service-typescriptmodels';
import {HinterlandUtils} from '../../hinterland-utils';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-edit-rail-voyage-template-cargo-declarants',
  templateUrl: 'edit-rail-voyage-template-cargo-declarants.component.html',
  styleUrls: ['edit-rail-voyage-template-cargo-declarants.component.scss']
})
export class EditRailVoyageTemplateCargoDeclarantsComponent implements OnInit {

  context = AppContext;
  utils = HinterlandUtils;

  @Input() declarant: HinterlandOrganisation;
  @Input() cargoDeclarants: HinterlandOrganisation[];
  @Output() cargoDeclarantsChange = new EventEmitter<HinterlandOrganisation[]>();

  ngOnInit() {
    if (!this.cargoDeclarants || this.cargoDeclarants.length == 0) {
      this.cargoDeclarants = [this.declarant];
      this.cargoDeclarantsChanged();
    }
  }

  cargoDeclarantsChanged() {
    this.cargoDeclarantsChange.emit(this.cargoDeclarants);
  }

  addCargoDeclarant = () => {
    this.cargoDeclarants.push(<HinterlandOrganisation>{});
  }

  trackByIndex = (index: number, obj: any): any => index;

  isTemplateDeclarant = (cargoDeclarantShortName: string) => this.declarant.shortName === cargoDeclarantShortName;

}
