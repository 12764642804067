<div class="bg-portbase-lighter-grey" style="min-height: 100vh">
  <div class="w-100 h-100 d-flex justify-content-center align-content-around">
    <div style="width: 600px">
      <div class="row mt-4 mb-3">
        <div class="col-auto ps-4">
          <img src="assets/img/port-of-rotterdam-logo.svg" style="height: 35px" alt="Port of Rotterdam logo" class="notranslate">
        </div>
        <div class="col-auto ms-auto pe-4">
          <img src="assets/img/portbase-logo.svg" style="height: 30px" alt="Port of Rotterdam logo" class="notranslate">
        </div>
      </div>

      <div class="card">
        <div class="card-body px-4">
          <div class="row">
            <div class="col-12 my-3">
              <span style="font-size: 1.25rem; line-height: 1.5rem; font-weight: 500">Accept terms and conditions</span>
            </div>

            <app-error [errors]="errors"></app-error>

            <div class="col-12 my-3">
              To continue, please read and accept the latest <a target='_blank' href='https://www.portofrotterdam.com/en/port-dues-tariffs'>Terms and Conditions</a> for Inland Port Dues.
            </div>

            <div class="col-12 my-3">
              <div class="form-group">
                <div class="custom-check">
                  <input [ngModel]="false" required class="custom-check-input" type="checkbox" id="confirmCheck" style="margin-top: 0.1rem">
                  <label class="form-check-label" for="confirmCheck">
                    I have read and agree to the terms and conditions
                  </label>
                </div>
              </div>
            </div>

            <div class="col-12 mt-2">
              <div class="row">
                <div class="ms-auto col-auto">
                  <button type="button" class="btn btn-secondary" (click)="location.back()">Cancel</button>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-primary" (click)="accept()">Continue</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>


  </div>
</div>
