import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {BargeVisit, BargeVoyageRestriction, UpdateRestrictions} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {DragulaService} from "ng2-dragula";
import {HinterlandUtils} from '../../hinterland-utils';
import {checkValidity, removeItem, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {Visit} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-edit-barge-restrictions',
  templateUrl: './edit-barge-restrictions.component.html',
  styleUrls: ['./edit-barge-restrictions.component.css']
})
export class EditBargeRestrictionsComponent implements OnInit, AfterViewInit {
  utils = HinterlandUtils;

  @Input() voyageId: string
  @Input() visits: Visit[]
  @Input() restrictions: BargeVoyageRestriction[];

  constructor(private element: ElementRef, private dragulaService: DragulaService) {
  }

  ngOnInit(): void {
    if (!this.restrictions || this.restrictions.length === 0) {
      this.restrictions = <BargeVoyageRestriction[]>[<BargeVoyageRestriction>{
        visitIds: [[], []],
        transportingReefers: false
      }]
    }
  }

  ngAfterViewInit() {
    this.dragulaService.find('restrictions').options.accepts = (el, target, source, sibling) => {
      return target.id !== 'visits';
    };
    this.dragulaService.find('restrictions').options.copy = (el, source) => {
      return source.id === 'visits';
    };
    this.dragulaService.find('restrictions').options.copyItem = (visit: BargeVisit) => {
      return visit.visitId;
    };
    this.dragulaService.dropModel('restrictions').subscribe(value => {
      removeItem(value.targetModel, value.item);
      value.targetModel.push(value.item);
      value.targetModel.sort((a, b) => a < b ? -1 : 1);
    });
    this.dragulaService.find('restrictions').options.removeOnSpill = true;
  }

  updateRestrictions = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.barge.command.UpdateRestrictions', <UpdateRestrictions>{
        voyageId: this.voyageId,
        restrictions: this.restrictions,
      }, () => {
        AppContext.registerSuccess('Restrictions have been updated successfully');
      });
    }
  };

  getTerminalQuayNameForVisitId = (visitId: string): string =>  {
    let visit = this.visits.find(visit => visit.visitId === visitId);
    return !!visit ? visit.terminal.quayName : "";
  }

  removeRestriction(index) {
    removeItem(this.restrictions, this.restrictions[index]);
  }
}
