import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-visit-cancelled-warning-on-move',
  templateUrl: './visit-cancelled-warning-on-move.component.html',
  styleUrl: './visit-cancelled-warning-on-move.component.css',
  standalone: true
})
export class VisitCancelledWarningOnMoveComponent {

  @Input() data;

  callback: (result: boolean) => any;

  keep() {
    this.callback(false);
  }

  move() {
    this.callback(true);
  }


}
