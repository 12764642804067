<div class="row">
  <div class="col-auto fw-medium" style="width: 60%">Organisation name</div>
  <div class="col-auto fw-medium" style="width: 40%">Debitor number</div>
  <div class="col-auto notranslate" style="width: 60%">{{organisation.organisationName}}</div>
  <div class="col-auto notranslate" style="width: 40%">{{organisation.organisationId}}</div>
</div>

<div class="row mt-2">
  <div class="col-auto fw-medium" style="width: 60%">Address</div>
  <div class="col-auto fw-medium" style="width: 40%" *ngIf="organisation.organisationId === context.binnenhavengeldUserProfile?.organisation?.organisationId">IBAN</div>
  <div class="col-auto notranslate overflow-hidden" style="width: 60%">{{organisation.street | titlecase}} {{organisation.streetNumber}} {{organisation.streetNumberSuffix}} {{organisation.city | titlecase}}, {{organisation.country | titlecase}}</div>
  <div class="col-auto notranslate" style="width: 40%" *ngIf="organisation.organisationId === context.binnenhavengeldUserProfile?.organisation?.organisationId">{{organisation.bankAccountNumber || '–'}}</div>
</div>

