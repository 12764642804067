import {Component, Input, OnInit} from "@angular/core";
import {AppContext} from "../../app-context";
import {GetOrganisationPreferences, OrganisationPreferences} from "@portbase/hinterland-service-typescriptmodels";
import {sendQuery} from "../../common/utils";
import {HinterlandUtils, Organisation} from "../../hinterland/hinterland-utils";
import {mergeMap} from "rxjs/operators";

@Component({
  selector: 'organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent implements OnInit {
  appContext = AppContext;
  organisationPreferences: OrganisationPreferences;
  utils = HinterlandUtils;

  @Input() entry: Organisation;
  @Input() currentShortName: string;

  ngOnInit() {
    if (this.currentShortName) {
      sendQuery("com.portbase.common.api.authentication.query.GetOrganisationPreferences", <GetOrganisationPreferences>{organisation: this.currentShortName})
        .subscribe(result => {
          this.organisationPreferences = result;
        });
    }
  }

  search = () => {
    const organisation$ = sendQuery('com.portbase.hinterland.api.common.query.GetOrganisationByShortName', {shortName: this.currentShortName}, {caching: false})
      .pipe(mergeMap(organisation => this.entry = organisation));
    const preferences$ = sendQuery("com.portbase.common.api.authentication.query.GetOrganisationPreferences", <GetOrganisationPreferences>{organisation: this.entry.shortName});
    organisation$.pipe(
      mergeMap(() => {
        console.log("First result, now second query");
        return preferences$;
      })).subscribe(preference => console.log("Preference: " + JSON.stringify(preference)));
  }

  isHinterlandDeclarant(org: Organisation): boolean {
    return org.roles.filter((role) => role.indexOf("HinterlandCargoDeclarant") > -1).length > 0;
  }

  isHinterlandTerminal(org: Organisation): boolean {
    return org.roles.filter((role) => role.indexOf("HinterlandTerminal") > -1).length > 0;
  }

  searchResultFormatter = (result: Organisation) => {
    return result.organisationData.fullName + ' ( ' + result.shortName + ')';
  };
}
