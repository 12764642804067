import {Component, ElementRef, OnInit} from '@angular/core';
import {Bollard, Coordinates, FindBollards, GetTerminal, Terminal} from "@portbase/hinterland-service-typescriptmodels";
import {checkValidity, cloneObject, sendCommand, sendQuery, toTitleCase, uuid} from "../../common/utils";
import {AppContext} from "../../app-context";
import {HinterlandUtils} from "../../hinterland/hinterland-utils";

@Component({
  selector: 'app-bollard',
  templateUrl: './bollard.component.html',
  styleUrls: ['./bollard.component.css']
})
export class BollardComponent implements OnInit {
  bollards : Bollard[];
  entry : Bollard;
  searchResult : Bollard;
  appContext = AppContext;
  terminal: Terminal;
  terminals: Terminal[];
  selectedQuay: Terminal;

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    if (this.isTerminal()) {
      sendQuery('com.portbase.hinterland.api.common.query.GetTerminal',
        <GetTerminal>{shortName: this.appContext.userProfile.organisationShortName}, {caching: false})
        .subscribe((terminal: Terminal) => this.terminal = terminal)
    }
    HinterlandUtils.getTerminals('barge', true, true, false).subscribe(terminals => this.terminals = terminals);
    sendQuery('com.portbase.hinterland.api.refdata.query.FindBollards', <FindBollards>{untyped: true}, {caching: false})
      .subscribe((bollards: Bollard[]) => this.bollards = bollards);
  }

  cancel = () => {
    this.clear();
  };

  addEntry = () => {
    this.addTerminalData();
    if (checkValidity(this.element) && this.validateId()) {
      sendCommand('com.portbase.hinterland.api.refdata.command.AddBollard', {entity : this.entry}, () => {
        AppContext.registerSuccess('Bollard was added successfully');
        this.bollards.push(this.entry);
        this.clear();
      });
    }
  };

  updateEntry = () => {
    this.addTerminalData();
    if (checkValidity(this.element) && this.validateId()) {
      sendCommand('com.portbase.hinterland.api.refdata.command.UpdateBollard', {entity : this.entry}, () => {
        AppContext.registerSuccess('Bollard was updated successfully');
        this.clear();
      });
    }
  };

  deleteEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.DeleteBollard', {entity : this.entry}, () => {
        AppContext.registerSuccess('Bollard was deleted successfully');
        this.bollards = this.bollards.filter(f => f['id'] != this.entry.id);
        this.clear();
      });
    }
  };

  private clear = () => {
    this.entry = undefined;
    this.searchResult = undefined;
  };

  newEntry = () => {
    if (this.searchResult) {
      this.entry = cloneObject(this.searchResult);
      this.entry.id = uuid();
    } else {
      this.entry = <Bollard>{};
      this.entry.coordinates = <Coordinates>{};
    }
    this.entry.id = uuid();
    this.searchResult = undefined;
    if(this.isTerminal()) {
      this.selectedQuay = this.terminal;
    } else {
      this.selectedQuay = undefined;
    }
  };

  search = term => {
      return sendQuery('com.portbase.hinterland.api.refdata.query.FindBollards',
        {term: term, untyped: true}, {caching: false});
  };

  searchResultFormatter = (result: Bollard) => {
    return '' + result.number + ' ( ' + this.quayName(result.bicsCode) + ')';
  }

  mooringSides() {
    return ['starboard', 'port'];
  }

  quays() : Terminal[] {
    if (this.appContext.isAdmin()) {
      return this.terminals;
    } else {
      return this.terminals.filter(o => o.shortName == this.appContext.userProfile.organisationShortName);
    }
  }

  quayName(bicsCode: string): String {
    let terminal: Terminal = this.terminals.filter(t => t.bicsCode == bicsCode)[0];
    if(terminal) {
      return terminal.quayName;
    } else {
      return 'Unknown terminal ' + bicsCode;
    }
  }

  quay(bicsCode: string) : Terminal {
    return this.terminals.filter(t => t.bicsCode == bicsCode)[0];
  }

  toTitleCase = (value) => toTitleCase(value);

  isTerminal() {
    return !this.appContext.isAdmin() && this.appContext.isHinterlandTerminal();
  }

  onEntrySelected(entry : Bollard) {
    this.searchResult = entry;
    this.onEntryChange();
  }

  onEntryChange() {
    this.entry = this.searchResult;
    if (this.entry != undefined && this.entry.bicsCode != undefined) {
      this.selectedQuay = this.quay(this.entry.bicsCode);
    }
  }

  addTerminalData() {
    if (this.selectedQuay != undefined) {
      this.entry.bicsCode = this.selectedQuay.bicsCode;
    } else if (this.isTerminal()) {
      this.entry.bicsCode = this.terminal.bicsCode;
    }
  }

  validateId () : boolean {
    if(this.bollards != undefined &&
      this.bollards.filter(bollard =>
        bollard.id != this.entry.id
        && bollard.bicsCode == this.entry.bicsCode
        && bollard.number == this.entry.number).length > 0) {
      AppContext.registerError('There is already a bollard with number ' + this.entry.number
        + ' for quay ' + this.quay(this.entry.bicsCode).quayName + '.');
      return false;
    }
    return true;
  }

  cast = (item: Bollard): Bollard => item;

}
