<div class="dropdown">
  <button class="p-0 bg-transparent border-0" app-icon-button type="button" data-bs-toggle="dropdown">
    <img [src]="'assets/img/' + language + '.svg'" alt="Select language" width="30" class="notranslate">
  </button>
  <div class="dropdown-menu dropdown-menu-end">
    <button class="dropdown-item" (click)="changeLanguage('nl')">Dutch</button>
    <button class="dropdown-item" (click)="changeLanguage('en')">English</button>
    <button class="dropdown-item" (click)="changeLanguage('de')">German</button>
    <button class="dropdown-item" (click)="changeLanguage('fr')">French</button>
  </div>
</div>
