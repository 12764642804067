import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-remove-remain-on-truck',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgForOf,
    FormsModule
  ],
  templateUrl: 'remove-remain-on-truck-containers.component.html',
  styleUrl: 'remove-remain-on-truck-containers.component.scss'
})
export class RemoveRemainOnTruckContainersComponent {

  @Input() data;

  callback: (result: boolean) => any;

  remove() {
    this.callback(this.data?.remainOnTruckContainers);
  }

  toggleForRemoval(container) {
    container['forRemoval'] = !container['forRemoval'];
  }

}
