{
  "timestamp": {
    "en": "Timestamp",
    "nl": "Tijdstip",
    "de": "Uhrzeit",
    "fr": "Horodatage"
  },
  "location": {
    "en": "Location",
    "nl": "Locatie",
    "de": "Ort",
    "fr": "Lieu"
  },
  "ship": {
    "en": "Ship",
    "nl": "Schip",
    "de": "Schiff",
    "fr": "Bateau"
  },
  "signaller": {
    "en": "Signaller",
    "nl": "Signaleerder",
    "de": "Erfasser",
    "fr": "Rapporteur"
  },
  "details": {
    "en": "Details",
    "nl": "Details",
    "de": "Details",
    "fr": "Détails"
  },
  "year": {
    "en": "Year",
    "nl": "Jaar",
    "de": "Jahr",
    "fr": "Année"
  },
  "quarter": {
    "en": "Quarter",
    "nl": "Kwartaal",
    "de": "Quartal",
    "fr": "Trimestre"
  },
  "month": {
    "en": "Month",
    "nl": "Maand",
    "de": "Monat",
    "fr": "Mois"
  },
  "fortnight": {
    "en": "14 days",
    "nl": "14 dagen",
    "de": "14 Tage",
    "fr": "14 jours"
  },
  "week": {
    "en": "7 days",
    "nl": "7 dagen",
    "de": "7 Tage",
    "fr": "7 jours"
  },
  "services": {
    "en": "Services",
    "nl": "Diensten",
    "de": "Dienste",
    "fr": "Services"
  },
  "subscription": {
    "en": "Subscription",
    "nl": "Abonnement",
    "de": "Abonnement",
    "fr": "Abonnement"
  },
  "subscriptions": {
    "en": "Subscriptions",
    "nl": "Abonnementen",
    "de": "Abonnements",
    "fr": "Abonnements"
  },
  "signallings": {
    "en": "Signallings",
    "nl": "Signaleringen",
    "de": "Schiffsmeldungen",
    "fr": "Signalements"
  },
  "inland port dues": {
    "en": "Inland port dues",
    "nl": "Binnenhavengeld",
    "de": "Binnenhafengebühren",
    "fr": "Redevances portuaires intérieures"
  },
  "users": {
    "en": "Users",
    "nl": "Gebruikers",
    "de": "Benutzer",
    "fr": "Utilisateurs"
  },
  "add user": {
    "en": "Add user",
    "nl": "Voeg gebruiker toe",
    "de": "Benutzer hinzufügen",
    "fr": "Ajouter l'utilisateur"
  },
  "download users": {
    "en": "Download users",
    "nl": "Download gebruikers",
    "de": "Benutzer herunterladen",
    "fr": "Télécharger les utilisateurs"
  },
  "loading users...": {
    "en": "Loading users...",
    "nl": "Gebruikers laden ...",
    "de": "Benutzer laden ...",
    "fr": "Chargement des utilisateurs ..."
  },
  "news and notifications": {
    "en": "News and notifications",
    "nl": "Nieuws en meldingen",
    "de": "Nachrichten",
    "fr": "Nouvelles et notifications"
  },
  "support": {
    "en": "Support",
    "nl": "Ondersteuning",
    "de": "Unterstützung",
    "fr": "Support"
  },
  "select language": {
    "en": "Select language",
    "nl": "Selecteer taal",
    "de": "Sprache auswählen",
    "fr": "Choisir la langue"
  },
  "user actions": {
    "en": "User actions",
    "nl": "Gebruikersacties",
    "de": "Benutzeraktionen",
    "fr": "Actions des utilisateurs"
  },
  "filter by email or organisation": {
    "en": "Filter by email or organisation",
    "nl": "Filter op email of organisatie",
    "de": "Filter per E-Mail oder Organisation",
    "fr": "Filtre par e-mail ou organisation"
  },
  "manage account": {
    "en": "Manage account",
    "nl": "Beheer account",
    "de": "Accountdaten ändern",
    "fr": "Ajuster les données"
  },
  "sign out": {
    "en": "Sign out",
    "nl": "Log uit",
    "de": "Ausloggen",
    "fr": "Déconnexion"
  },
  "dutch": {
    "en": "Dutch",
    "nl": "Nederlands",
    "de": "Niederländisch",
    "fr": "Néerlandais"
  },
  "english": {
    "en": "English",
    "nl": "Engels",
    "de": "Englisch",
    "fr": "Anglais"
  },
  "german": {
    "en": "German",
    "nl": "Duits",
    "de": "Deutsch",
    "fr": "Allemand"
  },
  "french": {
    "en": "French",
    "nl": "Frans",
    "de": "Französisch",
    "fr": "Français"
  },
  "menu": {
    "en": "Menu",
    "nl": "Menu",
    "de": "Menü",
    "fr": "Menu"
  },
  "add signalling": {
    "en": "Add signalling",
    "nl": "Signalering toevoegen",
    "de": "Schiffsmeldung hinzufügen",
    "fr": "Ajouter la signalisation"
  },
  "loading signallings...": {
    "en": "Loading signallings...",
    "nl": "Signaleringen laden...",
    "de": "Laden von Schiffsmeldungen ...",
    "fr": "Chargement de signalisation ..."
  },
  "filter by ship name, eni-number, etc...": {
    "en": "Filter by ship name, eni-number, etc...",
    "nl": "Filter op scheepsnaam of ENI",
    "de": "Filtern Sie nach Schiffsnamen oder ENI",
    "fr": "Filtrer par nom de bateau ou ENI, etc."
  },
  "skipper": {
    "en": "Skipper",
    "nl": "Schipper",
    "de": "Kapitän",
    "fr": "Skipper"
  },
  "type": {
    "en": "Type",
    "nl": "Type",
    "de": "Art",
    "fr": "Type"
  },
  "start": {
    "en": "Start",
    "nl": "Begin",
    "de": "Start",
    "fr": "Début"
  },
  "manually": {
    "en": "Manually",
    "nl": "Handmatig",
    "de": "Manuell",
    "fr": "Manuellement"
  },
  "payment": {
    "en": "Payment",
    "nl": "Betaling",
    "de": "Zahlung",
    "fr": "Paiement"
  },
  "subscription details": {
    "en": "Subscription details",
    "nl": "Abonnementsdetails",
    "de": "Abonnementdetails",
    "fr": "Détails de l'abonnement"
  },
  "select skipper": {
    "en": "Select skipper",
    "nl": "Selecteer schipper",
    "de": "Wählen Sie Kapitän",
    "fr": "Sélectionner le skipper"
  },
  "please enter a skipper": {
    "en": "Please enter a skipper",
    "nl": "Voer een schipper in",
    "de": "Bitte geben Sie einen Kapitän ein",
    "fr": "Veuillez entrer un skipper"
  },
  "please enter a ship": {
    "en": "Please enter a ship",
    "nl": "Voer een schip in",
    "de": "Bitte geben Sie ein Schiff ein",
    "fr": "Veuillez entrer un navire"
  },
  "please enter a type": {
    "en": "Please enter a type",
    "nl": "Voer een type in",
    "de": "Bitte geben Sie einen Typ ein",
    "fr": "Veuillez saisir un type"
  },
  "please enter a year": {
    "en": "Please enter a year",
    "nl": "Voer een jaar in",
    "de": "Bitte geben Sie ein Jahr ein",
    "fr": "Veuillez entrer un an"
  },
  "add subscription": {
    "en": "Add subscription",
    "nl": "Abonnement toevoegen",
    "de": "Abonnement hinzufügen",
    "fr": "Ajouter un abonnement"
  },
  "selected subscriptions": {
    "en": "Selected subscriptions",
    "nl": "Geselecteerde abonnementen",
    "de": "Ausgewählte Abonnements",
    "fr": "Abonnements sélectionnés"
  },
  "payment method": {
    "en": "Payment method",
    "nl": "Betalingsmiddel",
    "de": "Zahlungsmethode",
    "fr": "Mode de paiement"
  },
  "select payment method": {
    "en": "Select payment method",
    "nl": "Selecteer betaalmethode",
    "de": "Wählen Sie die Zahlungsmethode",
    "fr": "Sélectionnez le mode de paiement"
  },
  "please enter a payment method": {
    "en": "Please enter a payment method",
    "nl": "Voer een betaalmethode in",
    "de": "Bitte geben Sie eine Zahlungsmethode ein",
    "fr": "Veuillez saisir un mode de paiement"
  },
  "send": {
    "en": "Send",
    "nl": "Versturen",
    "de": "Senden",
    "fr": "Envoyer"
  },
  "debitor number": {
    "en": "Debitor number",
    "nl": "Debiteurnummer",
    "de": "Belastungsnummer",
    "fr": "Numéro de débit"
  },
  "ship name or eni": {
    "en": "Ship name or ENI",
    "nl": "Scheepsnaam of ENI",
    "de": "Schiffsname oder Eni",
    "fr": "Nom du navire ou ENI"
  },
  "organisation name": {
    "en": "Organisation name",
    "nl": "Organisatienaam",
    "de": "Organisationsname",
    "fr": "Nom de l'organisme"
  },
  "address": {
    "en": "Address",
    "nl": "Adres",
    "de": "Die Anschrift",
    "fr": "Adresse"
  },
  "change selection": {
    "en": "Change selection",
    "nl": "Wijziging selectie",
    "de": "Auswahl ändern",
    "fr": "Sélection du changement"
  },
  "direct debit": {
    "en": "Direct debit",
    "nl": "Automatische incasso",
    "de": "Lastschrifteinzugsverfahren",
    "fr": "Prélèvement automatique"
  },
  "debit in installments": {
    "en": "Debit in installments",
    "nl": "Automatische incasso in 6 termijnen",
    "de": "Lastschrifteinzugsverfahren in 6 Raten",
    "fr": "Prélèvement automatique avec 6 échéances"
  },
  "end": {
    "en": "End",
    "nl": "Einde",
    "de": "Ende",
    "fr": "Finir"
  },
  "remove subscription": {
    "en": "Remove subscription",
    "nl": "Verwijder abonnement",
    "de": "Abonnement entfernen",
    "fr": "Supprimer l'abonnement"
  },
  "select location": {
    "en": "Select location",
    "nl": "Selecteer een locatie",
    "de": "Ort auswählen",
    "fr": "Sélectionnez l'emplacement"
  },
  "loading subscriptions...": {
    "en": "Loading subscriptions...",
    "nl": "Abonnementen laden ...",
    "de": "Abonnements laden ...",
    "fr": "Chargement des abonnements ..."
  },
  "organisation": {
    "en": "Organisation",
    "nl": "Organisatie",
    "de": "Organisation",
    "fr": "Organisme"
  },
  "user details": {
    "en": "User details",
    "nl": "Gebruikersdetails",
    "de": "Nutzerdetails",
    "fr": "Détails de l'utilisateur"
  },
  "email": {
    "en": "Email",
    "nl": "Email",
    "de": "Email",
    "fr": "Email"
  },
  "please enter an email": {
    "en": "Please enter an email",
    "nl": "Voer een email in",
    "de": "Bitte geben Sie eine Email ein",
    "fr": "Veuillez saisir un email"
  },
  "login name": {
    "en": "Login name",
    "nl": "Inlognaam",
    "de": "Benutzername",
    "fr": "Identifiant"
  },
  "please enter a login name": {
    "en": "Please enter a login name",
    "nl": "Voer een inlognaam in",
    "de": "Bitte geben Sie einen Benutzername ein",
    "fr": "Veuillez saisir un identifiant"
  },
  "please enter an organisation": {
    "en": "Please enter an organisation",
    "nl": "Voer een organisatie in",
    "de": "Bitte geben Sie eine Organisation ein",
    "fr": "Veuillez entrer une organisation"
  },
  "hbr": {
    "en": "HBR",
    "nl": "HBR",
    "de": "HBR",
    "fr": "HBR"
  },
  "yes": {
    "en": "Yes",
    "nl": "Ja",
    "de": "Ja",
    "fr": "Oui"
  },
  "no": {
    "en": "No",
    "nl": "Nee",
    "de": "Nein",
    "fr": "Non"
  },
  "please enter a hbr": {
    "en": "Please select whether the user is an HBR user",
    "nl": "Kies of de gebruiker een HBR medewerker is",
    "de": "Bitte geben Sie einen HBR ein",
    "fr": "Veuillez entrer un HBR"
  },
  "admin": {
    "en": "Admin",
    "nl": "Admin",
    "de": "Administrator",
    "fr": "Administrer"
  },
  "please enter an admin": {
    "en": "Please select whether the user is an admin",
    "nl": "Kies of de gebruiker admin is",
    "de": "Bitte geben Sie einen Administrator ein",
    "fr": "Veuillez entrer un administrateur"
  },
  "language": {
    "en": "Language",
    "nl": "Taal",
    "de": "Sprache",
    "fr": "Langue"
  },
  "(optional)": {
    "en": "(Optional)",
    "nl": "(Optioneel)",
    "de": "(Optional)",
    "fr": "(Facultatif)"
  },
  "please enter a language": {
    "en": "Please enter a language",
    "nl": "Voer een taal in",
    "de": "Bitte geben Sie eine Sprache ein",
    "fr": "Veuillez entrer une langue"
  },
  "enter the email address of the user": {
    "en": "Enter the email address of the user",
    "nl": "Voer het emailadres van de gebruiker in",
    "de": "Geben Sie die Email-Adresse des Benutzers ein",
    "fr": "Entrez l'adresse email de l'utilisateur"
  },
  "enter a login name (defaults to email)": {
    "en": "Enter a login name (defaults to email)",
    "nl": "Voer een inlognaam in (doorgaans gelijk aan email)",
    "de": "Geben Sie einen Benutzername ein",
    "fr": "Entrez un nom de connexion"
  },
  "enter sap organisation id": {
    "en": "Enter SAP organisation id",
    "nl": "Voer SAP organisatie id in",
    "de": "Geben Sie die SAP organisations ID ein",
    "fr": "Entrez l'ID de l'organisation SAP"
  },
  "signalling details": {
    "en": "Signalling details",
    "nl": "Signaleringsdetails",
    "de": "Details der Schiffsmeldung",
    "fr": "Détails de signalisation"
  },
  "select signaller": {
    "en": "Select signaller",
    "nl": "Selecteer signaleerder",
    "de": "Wählen Sie Erfasser",
    "fr": "Sélectionnez rapporteur"
  },
  "date and time": {
    "en": "Date and time",
    "nl": "Datum en tijd",
    "de": "Datum und Uhrzeit",
    "fr": "Date et l'heure"
  },
  "add": {
    "en": "Add",
    "nl": "Toevoegen",
    "de": "Hinzufügen",
    "fr": "Ajouter"
  },
  "add and close": {
    "en": "Add and close",
    "nl": "Toevoegen en sluiten",
    "de": "Hinzufügen und schließen",
    "fr": "Ajouter et fermer"
  },
  "please enter a signaller": {
    "en": "Please enter a signaller",
    "nl": "Voer een signaleerder in",
    "de": "Bitte geben Sie einen Erfasser aus",
    "fr": "Veuillez entrer un rapporteur"
  },
  "please enter a date and time": {
    "en": "Please enter a date and time",
    "nl": "Voer een datum en tijd in",
    "de": "Bitte geben Sie ein Datum und Uhrzeit ein",
    "fr": "Veuillez saisir une date et une heure"
  },
  "report issue": {
    "en": "Report issue",
    "nl": "Meld probleem",
    "de": "Melde Probleme",
    "fr": "Signaler une question"
  },
  "issue details": {
    "en": "Issue details",
    "nl": "Gegevens",
    "de": "Details ausgeben",
    "fr": "Détails de la publication"
  },
  "answers to frequently asked questions can be found on our": {
    "en": "Answers to frequently asked questions can be found on our",
    "nl": "Antwoorden op veelgestelde vragen zijn te vinden op onze",
    "de": "Antworten auf häufig gestellte Fragen finden Sie auf unserer",
    "fr": "Des réponses aux questions fréquemment posées peuvent être trouvées sur notre"
  },
  "support page": {
    "en": "support page",
    "nl": "ondersteuningspagina",
    "de": "Support-Seite",
    "fr": "page d'assistance"
  },
  "question": {
    "en": "Question",
    "nl": "Vraag",
    "de": "Frage",
    "fr": "Question"
  },
  "enter your email": {
    "en": "Enter your email",
    "nl": "Voer uw email in",
    "de": "Geben sie ihre Email Adresse ein",
    "fr": "Entrer votre email"
  },
  "describe your question in as much detail as possible": {
    "en": "Describe your question in as much detail as possible",
    "nl": "Beschrijf uw vraag zo gedetailleerd mogelijk",
    "de": "Beschreiben Sie Ihre Frage so detailliert wie möglich",
    "fr": "Décrivez votre question avec le plus de détails possible"
  },
  "please enter an issue": {
    "en": "Please enter an issue",
    "nl": "Voer een probleem in",
    "de": "Bitte geben Sie ein Problem ein",
    "fr": "Veuillez saisir un problème"
  },
  "name": {
    "en": "Name",
    "nl": "Naam",
    "de": "Name",
    "fr": "Nom"
  },
  "eni-number": {
    "en": "ENI-number",
    "nl": "ENI-nummer",
    "de": "ENI-number",
    "fr": "ENI-nombre"
  },
  "category": {
    "en": "Category",
    "nl": "Categorie",
    "de": "Kategorie",
    "fr": "Catégorie"
  },
  "passenger or tug": {
    "en": "Passenger or tug",
    "nl": "Passagier of sleepboot",
    "de": "Passagier oder Schlepper",
    "fr": "Passager ou remorqueur"
  },
  "tonnage": {
    "en": "Tonnage",
    "nl": "Tonnage",
    "de": "Tonnage",
    "fr": "Tonnage"
  },
  "surface": {
    "en": "Surface",
    "nl": "Oppervlakte",
    "de": "Auftauchen",
    "fr": "Surface"
  },
  "length": {
    "en": "Length",
    "nl": "Lengte",
    "de": "Länge",
    "fr": "Longueur"
  },
  "width": {
    "en": "Width",
    "nl": "Breedte",
    "de": "Breite",
    "fr": "Largeur"
  },
  "draught": {
    "en": "Draught",
    "nl": "Diepgang",
    "de": "Tiefe",
    "fr": "Profondeur"
  },
  "no matching signallings found.": {
    "en": "No matching signallings found.",
    "nl": "Geen bijpassende signaleringen gevonden.",
    "de": "Keine passenden Schiffsmeldungen gefunden.",
    "fr": "Aucune signalisation correspondante trouvée."
  },
  "no matching subscriptions found.": {
    "en": "No matching subscriptions found.",
    "nl": "Geen bijpassende abonnementen gevonden.",
    "de": "Keine passenden Abonnements gefunden.",
    "fr": "Aucun abonnement correspondant trouvé."
  },
  "no matching users found.": {
    "en": "No matching users found.",
    "nl": "Geen bijpassende gebruikers gevonden.",
    "de": "Keine passenden Benutzer gefunden.",
    "fr": "Aucun utilisateur correspondant trouvé."
  },
  "you only need to complete changed fields here. you can also request to declare for other skippers. if you have permission to declare for other skippers and no longer require this, you should contact the port of rotterdam authority by telephone or e-mail.": {
    "en": "You only need to complete changed fields here. You can also request to declare for other skippers. If you have permission to declare for other skippers and no longer require this, you should contact the Port of Rotterdam Authority by telephone or e-mail.",
    "nl": "U hoeft hier alleen gewijzigde velden in te vullen. U kunt ook aangifte doen voor andere schippers. Heeft u toestemming om te melden namens andere schippers en heeft u deze niet meer nodig, neem dan telefonisch of per email contact op met Havenbedrijf Rotterdam.",
    "de": "Hier müssen Sie nur geänderte Felder ausfüllen. Sie können auch die Anmeldung für andere Skipper beantragen. Wenn Sie eine Anmeldeerlaubnis für andere Skipper haben und diese nicht mehr benötigen, sollten Sie sich telefonisch oder per E-Mail an den Hafenbetrieb Rotterdam wenden.",
    "fr": "Vous n'avez qu'à remplir les champs modifiés ici. Vous pouvez également demander à déclarer pour d'autres skippers. Si vous avez l'autorisation de déclarer pour d'autres skippers et que vous n'en avez plus besoin, vous devez contacter l'Autorité du port de Rotterdam par téléphone ou par e-mail."
  },
  "reason for update": {
    "en": "Reason for update",
    "nl": "Reden voor update",
    "de": "Grund für das Update",
    "fr": "Raison de la mise à jour"
  },
  "reason": {
    "en": "Reason",
    "nl": "Reden",
    "de": "Grund",
    "fr": "Raison"
  },
  "company details": {
    "en": "Company details",
    "nl": "Bedrijfsgegevens",
    "de": "Firmendetails",
    "fr": "Détails de l'entreprise"
  },
  "street": {
    "en": "Street",
    "nl": "Straat",
    "de": "Straße",
    "fr": "Rue"
  },
  "number": {
    "en": "Number",
    "nl": "Nummer",
    "de": "Anzahl",
    "fr": "Nombre"
  },
  "suffix": {
    "en": "Suffix",
    "nl": "Achtervoegsel",
    "de": "Suffix",
    "fr": "Suffixe"
  },
  "postal code": {
    "en": "Postal code",
    "nl": "Postcode",
    "de": "Postleitzahl",
    "fr": "Code postal"
  },
  "city": {
    "en": "City",
    "nl": "Stad",
    "de": "Stadt",
    "fr": "Ville"
  },
  "country": {
    "en": "Country",
    "nl": "Land",
    "de": "Land",
    "fr": "Pays"
  },
  "iban": {
    "en": "IBAN",
    "nl": "IBAN",
    "de": "IBAN",
    "fr": "IBAN"
  },
  "bic": {
    "en": "BIC",
    "nl": "BIC",
    "de": "BIC",
    "fr": "BIC"
  },
  "chamber of commerce number": {
    "en": "Chamber of commerce number",
    "nl": "Kamer van koophandelnummer",
    "de": "Handelskammer",
    "fr": "Numéro de chambre de commerce"
  },
  "vat number": {
    "en": "VAT number",
    "nl": "BTW-nummer",
    "de": "Umsatzsteuer-Identifikationsnummer",
    "fr": "numéro de TVA"
  },
  "contact details": {
    "en": "Contact details",
    "nl": "Contact details",
    "de": "Kontaktdetails",
    "fr": "Détails du contact"
  },
  "landline phone number": {
    "en": "Landline phone number",
    "nl": "Vaste telefoonnummer",
    "de": "Festnetznummer",
    "fr": "Numéro de téléphone fixe"
  },
  "mobile phone number": {
    "en": "Mobile phone number",
    "nl": "Mobiele telefoonnummer",
    "de": "Handynummer",
    "fr": "numéro de téléphone portable"
  },
  "email for login": {
    "en": "Email for login",
    "nl": "Email voor inloggen",
    "de": "Email für Login",
    "fr": "Email pour la connexion"
  },
  "email for invoice": {
    "en": "Email for invoice",
    "nl": "Email voor factuur",
    "de": "Email für Rechnung",
    "fr": "Email pour facture"
  },
  "language for messaging": {
    "en": "Language for messaging",
    "nl": "Taal voor berichten",
    "de": "Sprache für Nachrichten",
    "fr": "Langue pour la messagerie"
  },
  "declaration for other parties": {
    "en": "Declaration for other parties",
    "nl": "Melden namens andere schippers",
    "de": "Erklärung für andere Parteien",
    "fr": "Déclaration pour les autres parties"
  },
  "declaration for skipper": {
    "en": "Declaration for skipper",
    "nl": "Melding namens schipper",
    "de": "Erklärung für Kapitän",
    "fr": "Déclaration de skipper"
  },
  "add skipper": {
    "en": "Add skipper",
    "nl": "Schipper toevoegen",
    "de": "Kapitän hinzufügen",
    "fr": "Ajouter le skipper"
  },
  "cancel": {
    "en": "Cancel",
    "nl": "Annuleren",
    "de": "Absagen",
    "fr": "Annuler"
  },
  "update": {
    "en": "Update",
    "nl": "Bijwerken",
    "de": "Aktualisieren",
    "fr": "Mettre à jour"
  },
  "please enter a reason": {
    "en": "Please enter a reason",
    "nl": "Voer een reden in",
    "de": "Bitte geben Sie einen Grund ein",
    "fr": "Veuillez saisir une raison"
  },
  "please enter a name": {
    "en": "Please enter a name",
    "nl": "Voer een naam in",
    "de": "Bitte geben Sie einen Namen ein",
    "fr": "Veuillez saisir un nom"
  },
  "please enter a street": {
    "en": "Please enter a street",
    "nl": "Ga een straat in",
    "de": "Bitte betreten Sie eine Straße",
    "fr": "Veuillez entrer dans une rue"
  },
  "please enter a number": {
    "en": "Please enter a number",
    "nl": "Voer alstublieft een nummer in",
    "de": "Bitte gebe eine Nummer ein",
    "fr": "Veuillez saisir un numéro"
  },
  "please enter a suffix": {
    "en": "Please enter a suffix",
    "nl": "Voer een achtervoegsel in",
    "de": "Bitte geben Sie ein Suffix ein",
    "fr": "Veuillez saisir un suffixe"
  },
  "please enter a postal code": {
    "en": "Please enter a postal code",
    "nl": "Voer een postcode in",
    "de": "Bitte geben Sie eine Postleitzahl an",
    "fr": "Veuillez saisir un code postal"
  },
  "please enter a city": {
    "en": "Please enter a city",
    "nl": "Ga een stad in",
    "de": "Bitte betreten Sie eine Stadt",
    "fr": "Veuillez entrer dans une ville"
  },
  "please enter a country": {
    "en": "Please enter a country",
    "nl": "Voer een land in",
    "de": "Bitte betreten Sie ein Land",
    "fr": "Veuillez entrer dans un pays"
  },
  "please enter an iban": {
    "en": "Please enter an iban",
    "nl": "Voer een IBAN in",
    "de": "Bitte geben Sie einen IBAN ein",
    "fr": "Veuillez entrer un IBAN"
  },
  "please enter a bic": {
    "en": "Please enter a bic",
    "nl": "Voer een BIC in",
    "de": "Bitte geben Sie einen BIC ein",
    "fr": "Veuillez entrer un BIC"
  },
  "please enter a chamber of commerce number": {
    "en": "Please enter a chamber of commerce number",
    "nl": "Voer een Kamer van Koophandel in",
    "de": "Bitte geben Sie eine Handelskammer ein",
    "fr": "Veuillez saisir un numéro de chambre de commerce"
  },
  "please enter a vat number": {
    "en": "Please enter a vat number",
    "nl": "Voer een btw -nummer in",
    "de": "Bitte geben Sie eine Mehrwertsteuernummer ein",
    "fr": "Veuillez saisir un numéro de TVA"
  },
  "please enter a landline phone number": {
    "en": "Please enter a landline phone number",
    "nl": "Voer een vaste telefoonnummer in",
    "de": "Bitte geben Sie eine Festnetznummer ein",
    "fr": "Veuillez saisir un numéro de téléphone fixe"
  },
  "please enter a mobile phone number": {
    "en": "Please enter a mobile phone number",
    "nl": "Voer een mobiel telefoonnummer in",
    "de": "Bitte geben Sie eine Handynummer ein",
    "fr": "Veuillez saisir un numéro de téléphone mobile"
  },
  "please enter an email for login": {
    "en": "Please enter an email for login",
    "nl": "Voer een email in voor inloggen",
    "de": "Bitte geben Sie eine Email für die Login ein",
    "fr": "Veuillez saisir un e-mail pour la connexion"
  },
  "please enter an email for invoice": {
    "en": "Please enter an email for invoice",
    "nl": "Voer een email in voor factuur",
    "de": "Bitte geben Sie eine Email für Rechnung ein",
    "fr": "Veuillez saisir un e-mail pour la facture"
  },
  "please enter a language for messaging": {
    "en": "Please enter a language for messaging",
    "nl": "Voer een taal in voor berichten",
    "de": "Bitte geben Sie eine Sprache für Nachrichten ein",
    "fr": "Veuillez saisir une langue pour la messagerie"
  },
  "please enter a declaration for skipper": {
    "en": "Please enter a declaration for skipper",
    "nl": "Voer een verklaring voor schipper in",
    "de": "Bitte geben Sie eine Erklärung für Kapitän ein",
    "fr": "Veuillez saisir une déclaration pour Skipper"
  },
  "request new account": {
    "en": "Request new account",
    "nl": "Vraag een nieuw account aan",
    "de": "Neues Konto anfordern",
    "fr": "Demander un nouveau compte"
  },
  "request an account to log in with. we will contact you as soon as your account has been created.": {
    "en": "Request an account to log in with. We will contact you as soon as your account has been created.",
    "nl": "Vraag een account aan om in te loggen met. We nemen contact met u op zodra uw account is aangemaakt.",
    "de": "Fordern Sie ein Konto an, um sich anzumelden. Wir werden Sie kontaktieren, sobald Ihr Konto erstellt wurde.",
    "fr": "Demandez un compte pour vous connecter. Nous vous contacterons dès que votre compte aura été créé."
  },
  "repeat email for login": {
    "en": "Repeat email for login",
    "nl": "Herhaal email voor inloggen",
    "de": "Wiederholen Sie die Email für die Login",
    "fr": "Répétez l'e-mail pour la connexion"
  },
  "request account": {
    "en": "Request account",
    "nl": "Account aanvragen",
    "de": "Konto anfordern",
    "fr": "Demander un compte"
  },
  "port of rotterdam logo": {
    "en": "Port of Rotterdam logo",
    "nl": "Poort van Rotterdam -logo",
    "de": "Port des Rotterdam -Logos",
    "fr": "Port de Rotterdam Logo"
  },
  "value differs from email for login": {
    "en": "Value differs from email for login",
    "nl": "Waarde verschilt van email voor inloggen",
    "de": "Wert unterscheidet sich von der Email für die Anmeldung",
    "fr": "La valeur diffère des e-mails pour la connexion"
  },
  "remove": {
    "en": "Remove",
    "nl": "Verwijderen",
    "de": "Entfernen",
    "fr": "Éliminer"
  },
  "inland port dues login": {
    "en": "Inland Port Dues login",
    "nl": "Binnenhavengeld login",
    "de": "Login im Binnenhafengebühren",
    "fr": "Connexion des cotisations portuaires intérieures"
  },
  "email address": {
    "en": "Email address",
    "nl": "E-mailadres",
    "de": "E-Mail-Addresse",
    "fr": "Adresse e-mail"
  },
  "password": {
    "en": "Password",
    "nl": "Wachtwoord",
    "de": "Passwort",
    "fr": "Mot de passe"
  },
  "sign in": {
    "en": "Sign in",
    "nl": "Log in",
    "de": "Einloggen",
    "fr": "S'identifier"
  },
  "forgot password": {
    "en": "Forgot password",
    "nl": "Wachtwoord vergeten",
    "de": "Passwort vergessen",
    "fr": "Mot de passe oublié"
  },
  "reset password": {
    "en": "Reset password",
    "nl": "Reset wachtwoord",
    "de": "Passwort zurücksetzen",
    "fr": "Réinitialiser le mot de passe"
  },
  "invalid username and/or password": {
    "en": "Invalid username and/or password",
    "nl": "Ongeldige inlognaam en/of wachtwoord",
    "de": "Ungültiger Anmeldename und/oder Passwort",
    "fr": "Nom d'utilisateur et/ou mot de passe invalide"
  },
  "inland port dues registration": {
    "en": "Inland port dues registration",
    "nl": "Binnenhavengeld registratie",
    "de": "Anmeldung zur Binnenhafengebühren",
    "fr": "Enregistrement ports de la zone portuaire intérieure"
  },
  "your password has expired. please reset it" : {
    "en": "Your password has expired. Please reset it",
    "nl": "Uw wachtwoord is verlopen. Stel deze opnieuw in",
    "de": "Ihr Passwort ist abgelaufen. Bitte setzen Sie es zurück",
    "fr": "Votre mot de passe a expiré. Veuillez le réinitialiser"
  },
  "get support": {
    "en": "Get support",
    "nl": "Krijg ondersteuning",
    "de": "Hole dir Unterstützung",
    "fr": "Obtenir de l'aide"
  },
  "enter your email-address": {
    "en": "Enter your email address",
    "nl": "Voer uw e-mailadres in",
    "de": "Geben Sie Ihre E-Mail-Beratung ein",
    "fr": "Entrez votre adresse e-mail"
  },
  "enter your password": {
    "en": "Enter your password",
    "nl": "Voer uw wachtwoord in",
    "de": "Geben Sie Ihr Passwort ein",
    "fr": "Tapez votre mot de passe"
  },
  "please enter an email address": {
    "en": "Please enter an email address",
    "nl": "Voer een e-mailadres in alstublieft",
    "de": "Bitte geben Sie eine E-mail-Adresse ein",
    "fr": "s'il vous plait entrez une adresse mail"
  },
  "please enter a password": {
    "en": "Please enter a password",
    "nl": "voer een wachtwoord in alstublieft",
    "de": "Bitte Passwort eingeben",
    "fr": "Veuillez entrer un mot de passe"
  },
  "enter your email address, and we will send you a link to get back into your account.": {
    "en": "Enter your email address, and we will send you a link to get back into your account.",
    "nl": "Voer uw emailadres in en we sturen u een link waarmee u uw wachtwoord kunt wijzigen.",
    "de": "Geben Sie Ihre Email-Adresse ein und wir senden Ihnen einen Link, um wieder in Ihr Konto zu kommen.",
    "fr": "Entrez votre adresse e-mail, et nous vous enverrons un lien pour revenir dans votre compte."
  },
  "send login link": {
    "en": "Send login link",
    "nl": "Stuur login link",
    "de": "Anmeldelink senden",
    "fr": "Envoyer un lien de connexion"
  },
  "enter email address": {
    "en": "Enter email address",
    "nl": "Voer e-mailadres in",
    "de": "Geben Sie E-Mail-Beraten ein",
    "fr": "Entrez l'adresse e-mail"
  },
  "edit user": {
    "en": "Edit user",
    "nl": "Bewerk gebruiker",
    "de": "Benutzer bearbeiten",
    "fr": "Modifier l'utilisateur"
  },
  "delete user": {
    "en": "Delete user",
    "nl": "Verwijder gebruiker",
    "de": "Benutzer löschen",
    "fr": "Supprimer l'utilisateur"
  },
  "no.": {
    "en": "No.",
    "nl": "No.",
    "de": "Nr.",
    "fr": "No."
  },
  "n/a": {
    "en": "n/a",
    "nl": "n/a",
    "de": "nicht zutreffend",
    "fr": "n/a"
  },
  "type of vessel": {
    "en": "Type of Vessel",
    "nl": "Type vaartuig",
    "de": "Art des Schiffes",
    "fr": "Type de navire"
  },
  "raise/discount": {
    "en": "Raise/Discount",
    "nl": "Verhoging/korting",
    "de": "Erhöhung/Rabatt",
    "fr": "Augmentation / remise"
  },
  "vessels with propulsion engines that do not meet the ccr2* emission standard.": {
    "en": "Vessels with propulsion engines that do not meet the CCR2* emission standard.",
    "nl": "Schepen met voortstuwingsmotoren die niet voldoen aan de CCR2* -emissienorm.",
    "de": "Gefäße mit Antriebsmotoren, die den CCR2* -Missionsstandard nicht erfüllen.",
    "fr": "Des navires avec des moteurs de propulsion qui ne répondent pas à la norme d'émission CCR2 *."
  },
  "vessels with propulsion engines that do meet the ccr2* emission standard.": {
    "en": "Vessels with propulsion engines that do meet the CCR2* emission standard.",
    "nl": "Schepen met voortstuwingsmotoren die voldoen aan de CCR2* -emissienorm.",
    "de": "Gefäße mit Antriebsmotoren, die den CCR2* -Missionsstandard erfüllen.",
    "fr": "Les navires avec des moteurs de propulsion qui répondent à la norme d'émission CCR2 *."
  },
  "vessels with a green award certificate that at least meet the ccr2* emission standard and have a score lower than 400 points for the propulsion engines.": {
    "en": "Vessels with a Green Award certificate that at least meet the CCR2* emission standard and have a score lower than 400 points for the propulsion engines.",
    "nl": "Schepen met een Green Award -certificaat dat op zijn minst voldoet aan de CCR2* -emissienorm en een score lager hebben dan 400 punten voor de aandrijfmotoren.",
    "de": "Schiffe mit einem Green Award -Zertifikat, das zumindest den CCR2* -Missionsstandard entspricht und eine Punktzahl von weniger als 400 Punkten für die Antriebsmotoren hat.",
    "fr": "Les navires avec un certificat de récompense verte qui répondent au moins à la norme d'émission CCR2 * et ont un score inférieur à 400 points pour les moteurs de propulsion."
  },
  "vessels with propulsion engines that are at least 60% cleaner than the ccr2* emission 4 standard and/or vessels with a green award certificate issued later than 17 june 2014 with a score of 400 points or more for the propulsion engines.": {
    "en": "Vessels with propulsion engines that are at least 60% cleaner than the CCR2* emission 4 standard and/or Vessels with a Green Award certificate issued later than 17 June 2014 with a score of 400 points or more for the propulsion engines.",
    "nl": "Schepen met voortstuwingsmotoren die ten minste 60% schoner zijn dan de CCR2* Emission 4 Standard en/of Vessels met een Green Award -certificaat dat later dan 17 juni 2014 is uitgegeven met een score van 400 punten of meer voor de voortstuwingsmotoren.",
    "de": "Schiffe mit Antriebsmotoren, die mindestens 60% sauberer sind als der CCR2* Emission 4 Standard und/oder Schiffe mit einem Green Award -Zertifikat, das später als 17. Juni 2014 mit einer Punktzahl von 400 Punkten oder mehr für die Antriebsmotoren ausgestellt wurde.",
    "fr": "Des navires avec des moteurs de propulsion qui sont au moins 60% plus propres que la norme CCR2 * Emission 4 et / ou navires avec un certificat de récompense vert délivré plus tard que le 17 juin 2014 avec un score de 400 points ou plus pour les moteurs de propulsion."
  },
  "vessels without propulsion engines / barges.": {
    "en": "Vessels without propulsion engines / Barges.",
    "nl": "Schepen zonder voortstuwingsmotoren / binnenvaartschepen.",
    "de": "Schiffe ohne Antriebsmotoren / Lastkähne.",
    "fr": "Navires sans moteurs / barges de propulsion."
  },
  "vessels with propulsion engines with a green award platinum label.": {
    "en": "Vessels with propulsion engines with a Green Award Platinum Label.",
    "nl": "Schepen met voortstuwingsmotoren met een Green Award Platinum -label.",
    "de": "Schiffe mit Antriebsmotoren mit einem Green Award Platinum -Etikett.",
    "fr": "Navires avec des moteurs de propulsion avec un label Platinum Green Award."
  },
  "more information on the environmental category is available from": {
    "en": "More information on the Environmental Category is available from ",
    "nl": "Meer informatie over de milieucategorie is beschikbaar op ",
    "de": "Weitere Informationen zur Umweltkategorie finden Sie unter ",
    "fr": "Plus d'informations sur la catégorie environnementale sont disponibles à partir de"
  },
  "* of pm and nox": {
    "en": "* of PM and NOX",
    "nl": "* van PM en Nox",
    "de": "* von PM und NOX",
    "fr": "* de PM et Nox"
  },
  "filter": {
    "en": "Filter",
    "nl": "Filter",
    "de": "Filter",
    "fr": "Filtre"
  },
  "download": {
    "en": "Download",
    "nl": "Download",
    "de": "Herunterladen",
    "fr": "Télécharger"
  },
  "start date": {
    "en": "Start date",
    "nl": "Startdatum",
    "de": "Anfangsdatum",
    "fr": "Date de début"
  },
  "please review the fields with errors.": {
    "en": "Please review the fields with errors.",
    "nl": "Controleer de velden met fouten.",
    "de": "Bitte überprüfen Sie die Felder mit Fehlern.",
    "fr": "Veuillez vérifier les champs contenant des erreurs."
  },
  "close": {
    "en": "Close",
    "nl": "Sluiten",
    "de": "Schließen",
    "fr": "Fermer"
  },
  "start date is more than 35 days in the past": {
    "en": "Start date is more than 35 days in the past",
    "nl": "De startdatum ligt meer dan 35 dagen in het verleden",
    "de": "Anfangsdatum liegt mehr als 35 Tage in der Vergangenheit",
    "fr": "La date de début est plus de 35 jours dans le passé"
  },
  "no subscriptions found.": {
    "en": "No subscriptions found.",
    "nl": "Geen abonnementen gevonden.",
    "de": "Keine Abonnements gefunden.",
    "fr": "Aucun abonnement trouvé."
  },
  "no signallings found.": {
    "en": "No signallings found.",
    "nl": "Geen signaleringen gevonden.",
    "de": "Keine Schiffsmeldungen gefunden.",
    "fr": "Aucun signalement trouvé."
  },
  "no users found.": {
    "en": "No users found.",
    "nl": "Geen gebruikers gevonden.",
    "de": "Keine Benutzer gefunden.",
    "fr": "Aucun utilisateur trouvé."
  },
  "add a subscription": {
    "en": "Add a subscription",
    "nl": "Voeg een abonnement toe",
    "de": "Fügen Sie ein Abonnement hinzu",
    "fr": "Ajouter un abonnement"
  },
  "add a signalling": {
    "en": "Add a signalling",
    "nl": "Voeg een signalering toe",
    "de": "Fügen Sie ein Schiffsmeldung hinzu",
    "fr": "Ajouter un signalement"
  },
  "add a user": {
    "en": "Add a user",
    "nl": "Voeg een gebruiker toe",
    "de": "Fügen Sie einen Benutzer hinzu",
    "fr": "Ajouter un utilisateur"
  },
  "found overlapping subscription": {
    "en": "Found overlapping subscription",
    "nl": "Overlappend abonnement gevonden",
    "de": "Überschneidendes Abonnement gefunden",
    "fr": "Abonnement chevauchant trouvé"
  },
  "found overlapping subscriptions": {
    "en": "Found overlapping subscriptions",
    "nl": "Overlappende abonnementen gevonden",
    "de": "Sich überschneidende Abonnements gefunden",
    "fr": "Des abonnements qui se chevauchent ont été trouvés"
  },
  "range:": {
    "en": "Range:",
    "nl": "Periode:",
    "de": "Zeitraum:",
    "fr": "Période:"
  },
  "update user": {
    "en": "Update user",
    "nl": "Gebruiker bijwerken",
    "de": "Benutzer aktualisieren",
    "fr": "Modifier l'utilisateur"
  },
  "if your question is not listed in the 'frequently asked questions', please contact the harbour dues department using the form below. please describe your question in as much detail as possible.": {
    "en": "If your question is not listed in the 'frequently asked questions', please contact the Harbour Dues department using the form below. Please describe your question in as much detail as possible.",
    "nl": "Staat uw vraag niet bij de 'veelgestelde vragen', neem dan contact op met de afdeling Havengelden door middel van onderstaand formulier. Beschrijf uw vraag zo gedetailleerd mogelijk.",
    "de": "Wenn Ihre Frage nicht in den „häufig gestellten Fragen“ aufgeführt ist, wenden Sie sich bitte über das unten stehende Formular an die Abteilung Hafengebühren. Beschreiben Sie Ihre Frage so detailliert wie möglich.",
    "fr": "Si votre question ne figure pas dans la « foire aux questions », veuillez contacter le service des redevances portuaires en utilisant le formulaire ci-dessous. Décrivez votre question avec le plus de détails possible."
  },
  "the harbour dues department will contact you as soon as possible.": {
    "en": "The Harbour Dues department will contact you as soon as possible.",
    "nl": "De afdeling Havengelden neemt zo snel mogelijk contact met u op.",
    "de": "Die Hafengebührenabteilung wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.",
    "fr": "Le service des redevances portuaires vous contactera dans les plus brefs délais."
  },
  "contact form": {
    "en": "Contact form",
    "nl": "Contact opnemen",
    "de": "Kontakt Formular",
    "fr": "Formulaire de contact"
  },
  "ask your question": {
    "en": "Ask your question",
    "nl": "Stel uw vraag",
    "de": "Stell Ihre Frage",
    "fr": "Pose ta question"
  },
  "that debitor number does not belong to a signaller": {
    "en": "That debitor number does not belong to a signaller",
    "nl": "Dat debiteurnummer is niet van een signaleerder",
    "de": "Diese Debitorennummer gehört keinem Erfasser",
    "fr": "Ce numéro de débiteur n'appartient pas à un rapporteur"
  },
  "that debitor number does not belong to a skipper": {
    "en": "That debitor number does not belong to a skipper",
    "nl": "Dat debiteurnummer is niet van een schipper",
    "de": "Diese Debitorennummer gehört keinem Kapitän",
    "fr": "Ce numéro de débiteur n'appartient pas à un skipper"
  },
  "your question has been sent": {
    "en": "Your question has been sent",
    "nl": "Uw vraag is verzonden",
    "de": "Ihre Frage wurde gesendet",
    "fr": "Votre question a été envoyée"
  },
  "select a skipper": {
    "en": "Select a skipper",
    "nl": "Selecteer een schipper",
    "de": "Wählen Sie einen Skipper aus",
    "fr": "Sélectionnez un capitaine"
  },
  "subscription type": {
    "en": "Subscription type",
    "nl": "Abonnementstype",
    "de": "Abonnementtyp",
    "fr": "Type d'abonnement"
  },
  "other vessels": {
    "en": "Other vessels",
    "nl": "Overige vaartuigen",
    "de": "Andere Schiffe",
    "fr": "Autres navires"
  },
  "please select a new password. passwords should have at least 8 characters, including at least one digit, special character, capital, and lowercase letter.": {
    "en": "Please select a new password. Passwords should have at least 8 characters, including at least one digit, special character, capital, and lowercase letter.",
    "nl": "Selecteer een nieuw wachtwoord. Wachtwoorden moeten minimaal 8 tekens bevatten, waaronder minimaal één cijfer, een speciaal teken, een hoofdletter en een kleine letter.",
    "de": "Bitte wählen Sie ein neues Passwort. Passwörter sollten mindestens 8 Zeichen lang sein, darunter mindestens eine Ziffer, Sonderzeichen, Groß- und Kleinbuchstaben.",
    "fr": "Veuillez sélectionner un nouveau mot de passe. Les mots de passe doivent comporter au moins 8 caractères, dont au moins un chiffre, un caractère spécial, une majuscule et une minuscule."
  },
  "set password": {
    "en": "Set password",
    "nl": "Wachtwoord instellen",
    "de": "Passwort festlegen",
    "fr": "Définir le mot de passe"
  },
  "enter your new password": {
    "en": "Enter your new password",
    "nl": "Typ uw nieuwe wachtwoord",
    "de": "Gib dein neues Passwort ein",
    "fr": "Entrez votre nouveau mot de passe"
  },
  "new password": {
    "en": "New password",
    "nl": "Nieuw wachtwoord",
    "de": "Neues Passwort",
    "fr": "Nouveau mot de passe"
  },
  "accept terms and conditions": {
    "en": "Accept terms and conditions",
    "nl": "Accepteer de algemene voorwaarden",
    "de": "Geschäftsbedingungen akzeptieren",
    "fr": "Accepter les termes et conditions"
  },
  "continue": {
    "en": "Continue",
    "nl": "Ga door",
    "de": "Weiter",
    "fr": "Continuer"
  },
  "to continue, please read and accept the latest": {
    "en": "To continue, please read and accept the latest",
    "nl": "Om door te gaan, lees en accepteer de meest recente",
    "de": "Um fortzufahren, lesen und akzeptieren Sie bitte die neuesten",
    "fr": "Pour continuer, veuillez lire et accepter les dernières"
  },
  "terms and conditions": {
    "en": "Terms and Conditions",
    "nl": "Algemene Voorwaarden",
    "de": "Allgemeinen Geschäftsbedingungen",
    "fr": "Conditions Générales"
  },
  "for inland port dues.": {
    "en": "for Inland Port Dues.",
    "nl": "voor binnenhavengeld.",
    "de": "für Binnenhafengebühren.",
    "fr": "pour les droits de port intérieur."
  },
  "are you sure you want to delete this user:": {
    "en": "Are you sure you want to delete this user:",
    "nl": "Weet u zeker dat u deze gebruiker wilt verwijderen:",
    "de": "Möchten Sie diesen Benutzer wirklich löschen:",
    "fr": "Voulez-vous vraiment supprimer cet utilisateur:"
  },
  "? this cannot be undone.": {
    "en": "? This cannot be undone.",
    "nl": "? Dit kan niet ongedaan gemaakt worden.",
    "de": "? Das kann nicht rückgängig gemacht werden.",
    "fr": "? Ça ne peut pas être annulé."
  },
  "username": {
    "en": "Username",
    "nl": "Gebruikersnaam",
    "de": "Benutzername",
    "fr": "Nom d'utilisateur"
  },
  "hbr number": {
    "en": "HBR number",
    "nl": "HBR-nummer",
    "de": "HBR-Nummer",
    "fr": "Numéro HBR"
  },
  "i have read and agree to the terms and conditions": {
    "en": "I have read and agree to the terms and conditions",
    "nl": "Ik heb de voorwaarden gelezen en ga hiermee akkoord",
    "de": "Ich habe die AGB gelesen und stimme ihnen zu",
    "fr": "J'ai lu et j'accepte les termes et conditions"
  },
  "please enter a subscription type": {
    "en": "Please enter a subscription type",
    "nl": "Vul een abonnementstype in",
    "de": "Bitte geben Sie einen Abonnementtyp ein",
    "fr": "Veuillez entrer un type d'abonnement"
  },
  "please enter a start date": {
    "en": "Please enter a start date",
    "nl": "Vul een startdatum in",
    "de": "Bitte geben Sie ein Startdatum ein",
    "fr": "Veuillez entrer une date de début"
  },
  "unsaved changes": {
    "en": "Unsaved changes",
    "nl": "Niet opgeslagen wijzigingen",
    "de": "Nicht gespeicherte Änderungen",
    "fr": "Modifications non enregistrées"
  },
  "if you close the editing screen, you will lose the changes you have made.": {
    "en": "If you close the editing screen, you will lose the changes you have made.",
    "nl": "Als u het bewerkingsscherm sluit, gaan de aangebrachte wijzigingen verloren.",
    "de": "Wenn Sie den Bearbeitungsbildschirm schließen, gehen die vorgenommenen Änderungen verloren.",
    "fr": "Si vous fermez l'écran d'édition, vous perdrez les modifications que vous avez apportées."
  },
  "continue editing": {
    "en": "Continue editing",
    "nl": "Doorgaan met bewerken",
    "de": "Weiter Bearbeiten",
    "fr": "Continuer la modification"
  },
  "close without saving": {
    "en": "Close without saving",
    "nl": "Sluit zonder op te slaan",
    "de": "Schließen ohne speichern",
    "fr": "Fermer sans enregistrer"
  },
  "configure application": {
    "en": "Configure application",
    "nl": "Applicatie configureren",
    "de": "Anwendung konfigurieren",
    "fr": "Configurer l'application"
  },
  "configuration": {
    "en": "Configuration",
    "nl": "Configuratie",
    "de": "Konfiguration",
    "fr": "Configuration"
  },
  "minimum subscription start date": {
    "en": "Minimum subscription start date",
    "nl": "Minimale startdatum abonnement",
    "de": "Mindestbeginn des Abonnements",
    "fr": "Date minimale de début d'abonnement"
  },
  "if left blank, skippers may add subscriptions up to 35 days before the system date.": {
    "en": "If left blank, skippers may add subscriptions up to 35 days before the system date.",
    "nl": "Als dit leeg wordt gelaten, mogen schippers tot 35 dagen voor de systeemdatum abonnementen toevoegen.",
    "de": "Wenn das Feld leer gelassen wird, können Skipper Abonnements bis zu 35 Tage vor dem Systemdatum hinzufügen.",
    "fr": "Si laissé vide, les skippers peuvent ajouter des abonnements jusqu'à 35 jours avant la date système."
  },
  "save configuration": {
    "en": "Save configuration",
    "nl": "Configuratie opslaan",
    "de": "Konfiguration speichern",
    "fr": "Fermer sans enregistrer"
  },
  "minimum start date is": {
    "en": "Minimum start date is ",
    "nl": "Minimale startdatum is ",
    "de": "Mindeststartdatum ist ",
    "fr": "La date de début minimale est "
  },
  "number of selected subscriptions:": {
    "en": "Number of selected subscriptions:",
    "nl": "Aantal geselecteerde abonnementen:",
    "de": "Anzahl ausgewählter Abonnements:",
    "fr": "Nombre d'abonnements sélectionnés:"
  },
  "new subscriptions were added successfully": {
    "en": "New subscriptions were added successfully",
    "nl": "Nieuwe abonnementen zijn succesvol toegevoegd",
    "de": "Neue Abonnements wurden erfolgreich hinzugefügt",
    "fr": "Les nouveaux abonnements ont été ajoutés avec succès"
  },
  "sanction text": {
    "en": "I hereby declare that I am familiar with the international sanctions and that I have hereby seen the explanatory notes to the sanctions regulations.",
    "nl": "Hierbij verklaar ik dat ik bekend ben met de internationale sancties en dat ik hierbij de toelichting op de sanctieregelingen heb ingezien.",
    "de": "Ich erkläre hiermit, dass ich mit den internationalen Sanktionen vertraut bin und dass ich die Erläuterungen zu den Sanktionsbestimmungen zur Kenntnis genommen habe.",
    "fr": "Je déclare par la présente que je connais les sanctions internationales et que j'ai pris connaissance des notes explicatives du règlement relatif aux sanctions."
  },
  "sanctions regulations statements": {
    "en": "Sanctions regulations statements",
    "nl": "Toelichting op sanctieregelingen",
    "de": "Erklärungen zu Sanktionsregelungen",
    "fr": "Sanctions règlements déclarations"
  },
  "sanction line-1": {
    "en": "I hereby declare that I am aware of international sanctions, and based on appropriate inquiries, I have no reason to believe that (1) I, or the vessel for which this notification is being made, or its agent, owners, the carrier, or the current and intended owners of any goods being transported by this vessel, are specifically targeted by any EU sanctions (such as for example an asset freeze or other restrictive measures), and (2) that any of the aforementioned entities or natural persons are owned, held or controlled by persons or entities specifically targeted by EU sanctions, or acting on behalf or at the direction thereof.",
    "nl": "Hierbij verklaar ik dat ik bekend ben met de internationale sancties, en dat ik, mede op basis van passende maatregelen, geen reden heb om aan te nemen dat (1) ik, of het vaartuig waarvoor deze kennisgeving wordt gedaan, de agent, de eigenaren, de vervoerder of de huidige en beoogde eigenaren van de goederen die door dit vaartuig worden vervoerd, specifiek worden getroffen door EU-sancties (zoals bijvoorbeeld een bevriezing van tegoeden of andere beperkende maatregelen), en (2) dat de bovengenoemde entiteiten of natuurlijke personen eigendom is van, in het bezit is van of gecontroleerd wordt door personen of entiteiten die specifiek worden getroffen door EU-sancties, of namens of op aanwijzing daarvan handelen.",
    "de": "Ich erkläre hiermit, dass mir die internationalen Sanktionen bekannt sind und ich aufgrund entsprechender Nachforschungen keinen Grund zu der Annahme habe, dass (1) ich oder das Schiff, für das diese Meldung gemacht wird, oder sein Agent, seine Eigner, der Frachtführer oder die derzeitigen und beabsichtigten Eigner von Waren, die von diesem Schiff transportiert werden, von EU Sanktionen (wie z.B. dem Einfrieren von Vermögenswerten oder anderen restriktiven Massnahmen) betroffen sind, und (2) dass eine der oben genannten Einrichtungen oder natürlichen Personen im Eigentum, Besitz oder unter der Kontrolle von Personen oder Einrichtungen steht, die von EU-Sanktionen betroffen sind, oder in deren Namen oder auf deren Anweisung handelt.",
    "fr": "Je déclare par la présente que j'ai connaissance des sanctions internationales et que, sur la base d'enquêtes appropriées, je n'ai aucune raison de croire que (1) moi-même, ou le navire pour lequel cette notification est faite, ou son agent, ses propriétaires, le transporteur, ou les propriétaires actuels et prévus de toute marchandise transportée par ce navire, sont spécifiquement visés par des sanctions de l'UE (telles que, par exemple, un gel des avoirs ou d'autres mesures restrictives), et (2) que l'une quelconque des entités ou personnes physiques susmentionnées est détenue, possédée ou contrôlée par des personnes ou des entités spécifiquement visées par des sanctions de l'UE, ou agissant en leur nom ou sur leurs instructions."
  },
  "sanction line-2": {
    "en": "I hereby declare that I am aware of international sanctions, and based on appropriate inquiries, I have no reason to believe that the vessel is not transporting or being used for the transport of any goods that are subject to any import and/or export ban under any EU sanctions.",
    "nl": "Ik verklaar hierbij dat ik bekend ben met de internationale sancties, en dat ik, mede op basis van passende maatregelen, geen reden heb om aan te nemen dat het vaartuig goederen vervoert of wordt gebruikt voor het vervoer van goederen waarvoor krachtens EU-sancties een invoer- en/of uitvoerverbod geldt.",
    "de": "Ich erkläre hiermit, dass mir die internationalen Sanktionen bekannt sind und ich auf der Grundlage entsprechender Nachforschungen keinen Grund zu der Annahme habe, dass das Schiff keine Waren transportiert oder für den Transport von Waren verwendet wird, die einem Einfuhr- und/oder Ausfuhrverbot im Rahmen von EU-Sanktionen unterliegen.",
    "fr": "Je déclare par la présente avoir connaissance des sanctions internationales et, sur la base d'enquêtes appropriées, n'avoir aucune raison de croire que le navire ne transporte pas ou n'est pas utilisé pour le transport de biens faisant l'objet d'une interdiction d'importation et/ou d'exportation en vertu de sanctions de l'UE."
  },
  "maintenance message": {
    "en": "Dear user, the portal is closed from August 29th at 11:00 AM to September 2nd at 09:00 AM due to a system update.",
    "nl": "Geachte gebruiker, het portaal is gesloten van 29 augustus 11:00u tot 2 september 09:00u wegens een systeem update.",
    "de": "Sehr geehrter Benutzer, das Portal ist vom 29. August 11:00 Uhr bis zum 2. September 09:00 Uhr wegen eines Systemupdates geschlossen.",
    "fr": "Cher utilisateur, le portail est fermé du 29 août à 11h00 au 2 septembre à 09h00 en raison d'une nmise à jour du système."
  },
  "system update": {
    "en": "System update",
    "nl": "Systeem update",
    "de": "Systemupdates",
    "fr": "Mise à jour du système"
  }
}
