import {Component, OnInit} from '@angular/core';
import {GetCurrentUserPreferences} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from "../app-context";
import {
  AddHandlingOffCanvasComponent
} from './view-components/offcanvas-panel/add-handling-offcanvas/app-add-handling-offcanvas';
import {EditModalService} from '../components/modals/edit-modal/edit-modal.service';
import {QueryGateway} from '../common/query-gateway';
import userflow from "userflow.js";

@Component({
  selector: 'app-new-mca',
  templateUrl: './new-mca.component.html',
  styleUrls: ['./new-mca.component.scss']
})
export class NewMcaComponent implements OnInit {

  appContext = AppContext;

  constructor(
    private readonly queryGateway: QueryGateway,
    private readonly editModalService: EditModalService,
  ) {
  }

  ngOnInit() {
    this.queryGateway.send("com.portbase.common.api.authentication.query.GetCurrentUserPreferences", <GetCurrentUserPreferences>{})
      .subscribe(userPreferences => {
        AppContext.cachedUserPrefs = userPreferences;

        if (AppContext.useUserflowFeatures()
          && !!userPreferences
          && userPreferences.shipmentNominationEmail == undefined
          && userPreferences.shipmentNominationRevokedEmail == undefined) {
          userflow.start('1088033d-b9cd-44fd-a174-1bb27a953044', {once: true});
        }
      });
  }

  createHandling() {
    this.editModalService.openModal(AddHandlingOffCanvasComponent);
  }
}
