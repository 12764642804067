import {Component, Input} from '@angular/core';
import {BinnenhavengeldSignalling} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-bhg-signalling',
  templateUrl: './bhg-signalling.component.html',
  styleUrls: ['./bhg-signalling.component.css']
})
export class BhgSignallingComponent {
  superOrAdmin = AppContext.isBinnenhavengeldSuperUserOrAdmin();

  @Input() signalling: BinnenhavengeldSignalling;

}
