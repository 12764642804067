import {Component, Input, OnInit} from '@angular/core';
import {IntegralPlanningVisitStatus, Terminal, IntegralPlanningVoyageStatus} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Component({
  selector: 'integral-planning-status',
  templateUrl: './integral-planning-status.component.html',
  styleUrls: ['./integral-planning-status.component.scss']
})
export class IntegralPlanningStatusComponent implements OnInit {

  @Input() terminal: Terminal;
  @Input() terminalView: boolean;
  @Input() integralPlanningVisitStatus: IntegralPlanningVisitStatus;
  @Input() integralPlanningVoyageStatus: IntegralPlanningVoyageStatus;

  visitView = false;

  ngOnInit(): void {
    if (!!this.integralPlanningVisitStatus && !(this.terminalView && !!this.terminal && !this.terminal.nextlogicParticipant)) {
      if (this.integralPlanningVisitStatus.pta != null || this.integralPlanningVisitStatus.fromBollard != null
        || this.integralPlanningVisitStatus.toBollard != null || this.integralPlanningVisitStatus.lastUpdate != null) {
        this.visitView = true;
      }
    }
  }

}
