<div [ngClass]="{'attach-handling': this.displayMode === 'attachHandling'}" class="visit-panel">
  <div [class]="(actionMode) ? actionMode === 'addHandlingToVisitMode'
                             ? 'grid-add-handling-to-visit-mode' : 'grid-attach-to-visit-mode' : 'panel-grid'"
       class="visit-panel-grid g-0">

    <div *ngIf="!actionMode">&nbsp;</div>
    <div *ngIf="actionMode" class="show-sm-screen">&nbsp;</div>

    <div class="hide-md-screen">
      <div class="panel-icon">
        <span class="fa-light fa-truck-container fa-fw"></span>
      </div>
    </div>

    <div class="truncate">
      <div *ngIf="!isChartered() else chartered">
        <div class="text-truncate">
          {{ utils.getTerminalDisplayName(visit.terminal, 'road') }}
        </div>
        <div class="sub-text">Terminal</div>
      </div>
      <ng-template #chartered>
        <div [ngbTooltip]="charterTooltip" container="body" placement="bottom">
          <div>
            {{ utils.getTerminalDisplayName(visit.terminal, 'road') }} <span *ngIf="voyage?.voyageData?.charter"
                                                                             class="charter">(fwd)</span>
          </div>
          <div class="sub-text">Terminal</div>
        </div>
      </ng-template>
    </div>

    <!-- TAR-->
    <div class="hide-md-screen">
      <div class="truncate">
        <span *ngIf="hasTar else noTar" class="select-all text-truncate">
          {{ visit.plannedVisitData.tar }}
        </span>
        <ng-template #noTar>
          <span>-</span>
        </ng-template>
      </div>
      <div class="sub-text">
        TAR
      </div>
    </div>

    <!-- Timeslot / ETA -->
    <div class="hide-md-screen">
      <span *ngIf="hasTimeslot else noTimeSlot">
        <app-formatted-date-range [end]="visit.plannedVisitData?.etd" [showClock]="false" class="select-all"
                                  [start]="eta"></app-formatted-date-range>
      </span>
      <ng-template #noTimeSlot>
        <span>
           <app-date-time [ngModel]="eta" [readonly]="true" [showClock]="false" class="select-all"></app-date-time>
        </span>
      </ng-template>
      <div>
        <span class="sub-text">
          {{ hasTimeslot ? 'Timeslot' : 'ETA' }}
        </span>
      </div>
    </div>

    <div *ngIf="actionMode && actionMode == 'addHandlingToVisitMode'" class="show-sm-screen">&nbsp;</div>
    <div class="show-sm-screen">
      <div class="timeslot select-all">
        <div class="">{{ formatSlotTime(eta, visit.plannedVisitData?.etd) }}</div>
        <div class="sub-text">{{ formatSlotDate(eta) }}</div>
      </div>
    </div>

    <ng-template #showDeclarant>
      <div *ngIf="appContext.isHinterlandTerminal() else emptyDiv" class="hide-lg-screen">
        <div>
          <span>
            {{ voyage.declarant.fullName }}
          </span>
        </div>
        <div class="sub-text">
          Declarant
        </div>
      </div>
    </ng-template>

    <div *ngIf="actionMode === 'addHandlingToVisitMode'" class="show-md-screen">&nbsp;</div>

    <div class="hide-md-screen" *ngIf="displayMode === 'attachHandling' else showDeclarant">
      <div class="d-flex g-0 align-items-center">
        <div [ngbTooltip]="sizeTypeTooltip" container="body" class="col-auto" placement="bottom">
          <div class="panel-icon">
            <div class="d-flex align-items-top h-100 me-1">
              <div
                [class]="'fal fa-fw fa-lg pb-container-' + (firstHandling?.handlingData?.type === 'loading' ? 'pickup': 'dropoff')"></div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="select-all">{{
              (firstHandling?.handlingData?.equipmentNumber) ? firstHandling?.handlingData?.equipmentNumber
                : firstHandling?.handlingStatus?.equipmentNumber ? firstHandling.handlingStatus?.equipmentNumber : '-'
            }}
          </div>
          <div class="sub-text">
            <span *ngIf="firstHandling?.handlingData?.type === 'loading'">Pick-up</span>
            <span *ngIf="firstHandling?.handlingData?.type !== 'loading'">Drop-off</span>
          </div>
        </div>
        <ng-template #sizeTypeTooltip>
          <div class="tooltip-card">
            <div class="tooltip-card-title">Size type</div>
            <div
              class="tooltip-card-body">{{ firstHandling?.handlingData?.sizeType?.code }} {{ firstHandling?.handlingData?.full ? '(full)' : '(empty)' }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div *ngIf="actionMode === 'addHandlingToVisitMode'" class="hide-lg-screen">&nbsp;</div>

    <div *ngIf="actionMode != 'addHandlingToVisitMode'" class="d-flex justify-content-end hide-md-screen">
      <span *ngIf="this.applicableNotifications.length > 0" [ngClass]="getNotificationClass()"
            [ngbTooltip]="notificationsTooltip" class="notification" container="body" placement="bottom-right">
              {{ applicableNotifications?.length }}
      </span>
    </div>

    <div *ngIf="displayMode === 'standard' && !actionMode" class="actions">
      <button [disabled]="isReadOnly()" aria-expanded="false" class="btn btn-tertiary" data-bs-toggle="dropdown"
              type="button">
        <span class="fal fa-ellipsis-vertical fa-fw show-sm-screen"></span>
        <span class="hide-md-screen">Actions</span>
      </button>
      <div class="dropdown-menu dropdown-menu-end">
        <div (click)="openUpdateVisitCanvas()" *ngIf="updateVisitAllowed()" class="dropdown-item">Update visit</div>
        <div (click)="startAddingHandlingsToVisit()" *ngIf="updateVisitAllowed()" class="dropdown-item">
          Add handling to this visit
        </div>
        <div *ngIf="visit.terminal.remainOnTruckAllowed" class="dropdown-item" (click)="addRemainOnTruckModal()">
          Add 'Remain on Truck' container
        </div>
        <div *ngIf="visit.terminal.remainOnTruckAllowed && hasOneRemainOnTruckContainer()" class="dropdown-item"
             (click)="removeRemainOnTruckContainerModal()">
          Remove 'Remain on Truck' container
        </div>
        <div *ngIf="visit.terminal.remainOnTruckAllowed && hasMultipleRemainOnTruckContainers()"
             class="dropdown-item" (click)="removeRemainOnTruckContainersModal()">
          Remove 'Remain on Truck' container(s)
        </div>
        <div (click)="copyVisitAsText()" class="dropdown-item">Copy visit as text</div>
      </div>
    </div>

    <div *ngIf="actionMode === 'attachToVisitMode'" class="d-flex justify-content-end">
      <button (click)="addToThisVisit()" [disabled]="visitContainsHandlingAlready()" class="btn btn-secondary"
              type="button">
        <i class="fal fa-arrow-down-right fa-lg button-icon"></i><span
        class="ps-2 hide-md-screen">Attach to this visit</span>
      </button>
    </div>
  </div>

  <div *ngIf="visit?.handlings && displayMode === 'standard'" class="handlings">
    <ng-container *ngFor="let handling of visit.handlings; let index = index">
      <app-handling-panel
        [actionMode]="actionMode"
        [handlingData]="handling.handlingData"
        [handling]="handling"
        [visit]="visit"
        [voyage]="voyage">
      </app-handling-panel>
    </ng-container>
  </div>

  <div *ngIf="this.voyage?.remainOnTruckContainers && this.visit.terminal.remainOnTruckAllowed">
    <ng-container *ngFor="let containerNumber of this.voyage.remainOnTruckContainers[this.visit.visitId]">
      <app-remain-on-truck-panel
        [actionMode]="actionMode"
        [containerNumber]="containerNumber">
      </app-remain-on-truck-panel>
    </ng-container>
  </div>
</div>

<ng-template #notificationsTooltip>
  <div class="tooltip-card">
    <ng-container *ngIf="highLevelApplicableNotifications.length > 0">
      <div class="tooltip-card-title">High priority</div>
      <div class="tooltip-card-body">
        <span *ngFor="let notification of highLevelApplicableNotifications">
          [{{ notification.code }}] {{ notification.description }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="mediumLevelApplicableNotifications.length > 0">
      <div class="tooltip-card-title">Medium priority</div>
      <div class="tooltip-card-body">
        <span *ngFor="let notification of mediumLevelApplicableNotifications">
          [{{ notification.code }}] {{ notification.description }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="lowLevelApplicableNotifications.length > 0">
      <div class="tooltip-card-title">Low priority</div>
      <div class="tooltip-card-body">
        <span *ngFor="let notification of lowLevelApplicableNotifications">
          [{{ notification.code }}] {{ notification.description }}
        </span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #charterTooltip>
  <div class="tooltip-card charter-tooltip">
    <div class="tooltip-card-title">Charter</div>
    <div class="tooltip-card-body">Your organisation is carrying out this visit for: {{ voyage?.declarant?.fullName }}
      ({{ voyage?.declarant?.ean }})
    </div>
  </div>
</ng-template>

<ng-template #emptyDiv>
  <div class="hide-lg-screen">&nbsp;</div>
</ng-template>
