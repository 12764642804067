import {Component, ViewEncapsulation} from '@angular/core';
import {AppContext} from '../app-context';
import {sendCommand} from '../common/utils';
import {ReportIssue} from '@portbase/hinterland-service-typescriptmodels';
import {VoyageContext} from '../hinterland/common/voyage-context';
import {HinterlandUtils} from "../hinterland/hinterland-utils";

@Component({
  selector: 'app-feedback',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportIssueComponent {
  email = AppContext.userProfile.emailAddress;
  modality = this.getModalityOptions()[0];
  feedback;

  reset() {
    this.email = AppContext.userProfile.emailAddress;
    this.feedback = "";
  }

  isValid(): boolean {
    return this.email && this.feedback;
  }

  sendFeedback() {
    HinterlandUtils.makeScreenshot("#screen").then((screenshot) => {
      sendCommand('com.portbase.common.api.reporting.ReportIssue', {
        '@class': 'io.fluxcapacitor.javaclient.common.Message',
        payload: <ReportIssue>{
          'voyageId': VoyageContext.voyage ? VoyageContext.voyage.voyageId : null,
          'modality': this.modality,
          'email': this.email,
          'contact': AppContext.userProfile.contact,
          'issue': this.feedback,
          'url': window.location.href
        },
        metadata: {
          'upload': screenshot
        }
      }, () => {
        AppContext.registerSuccess("Your feedback was sent successfully");
      })
    });
  }

  getSupportUrl() {
    return this.modality === 'rail' ? 'https://support.portbase.com/services/melding-container-achterland-rail/'
      : 'https://support.portbase.com/services/melding-container-achterland-barge/';
  }

  getModalityOptions() {
    let options = [];
    if(AppContext.isRoadDeclarant()) options.push('road');
    if(AppContext.isRailDeclarant()) options.push('rail');
    if(AppContext.isBargeDeclarant()) options.push('barge');
    if(options.length == 0) options.push('other');
    return options.length == 1 ? options : [null].concat(options);
  }

  isLocatedInRightHalfOfWindow(el:Element) : boolean {
    return el.getBoundingClientRect().left > (window.innerWidth / 2)
  }
}
