import {Component, Input} from '@angular/core';
import {Barge} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-edit-barge',
  templateUrl: './edit-barge.component.html',
  styleUrls: ['./edit-barge.component.css']
})
export class EditBargeComponent {

  @Input() barge : Barge;

}
