import {Component, OnDestroy} from '@angular/core';
import {AppContext} from '../../../app-context';
import {
  DateTimeRange,
  FindShipments,
  Modality,
  Shipment,
  Terminal
} from "@portbase/hinterland-service-typescriptmodels";
import {AbstractOverviewComponent} from "../../common/abstract-overview.component";
import {Observable} from "rxjs";
import {replaceItem, sendQuery} from "../../../common/utils";
import {HinterlandUtils} from "../../hinterland-utils";

@Component({
  selector: 'app-shipment-overview',
  templateUrl: './shipment-overview.component.html',
  styleUrls: ['./shipment-overview.component.scss']
})
export class ShipmentOverviewComponent extends AbstractOverviewComponent<Shipment> implements OnDestroy {

  pagingSupported: boolean = AppContext.isAdmin();
  utils = HinterlandUtils;
  modalityFilter: Modality | "all" = "all";
  terminalFilter: Terminal;
  shipments: Shipment[];
  actionInProgress = false;

  get selectedItems(): Shipment[] {
    return this.items.filter(s => !!s['selected'] && !s['hidden']);
  }

  toggleSelectAll() {
    const visibleItems = this.items.filter(s => !s['hidden']);
    if (this.selectedItems.length === visibleItems.length) {
      visibleItems.forEach(c => c['selected'] = false);
    } else {
      visibleItems.forEach(c => c['selected'] = true);
    }
  }

  updateActionState(actionInProgress: boolean) {
    this.actionInProgress = actionInProgress;
  }

  searchOrFilter = (): (Shipment[] | Observable<Shipment[]>) => {
    return this.reload();
  };

  doLoad(dateTimeRange: DateTimeRange): Observable<(Shipment)[]> {
    return sendQuery('com.portbase.hinterland.api.shipment.query.FindShipments',
      <FindShipments>{dateTimeRange: dateTimeRange, term: this.filterTerm},
      {caching: false, showSpinner: !this.unsubscribeHandle});
  }

  doRender = (shipments: Shipment[]) => {
    this.shipments = shipments;
  }

  'onUpdateShipment' = (update) => {
    const shipment = <Shipment>update.value;
    if(this.filterTerm && this.excludedByFilterTerm(shipment)){
      return;
    }
    if (update.newAggregate) {
      this.items.splice(0, 0, shipment);
    } else {
      const old = this.items.find(v => v.id === update.id);
      if (old) {
        replaceItem(this.items, old, shipment);
      }
    }
    this.renderFilteredItems();
  };

  excludedByFilterTerm = (shipment : Shipment) => {
    const filterTerms = this.filterTerm.split(" ") || [];
    let jsonValues = '';
    JSON.stringify(shipment, (key, value) => {
      if (typeof value !== 'object') {
        jsonValues += `${value} `;
      }
      return value;
    });
    jsonValues = jsonValues.toLowerCase();
    return filterTerms.some(term => jsonValues.indexOf(term.toLowerCase()) === -1);
  }

  public filterItems() {
    let filteredItems = this.items;
    if (this.modalityFilter != "all") {
      filteredItems = filteredItems.filter(shipment => this.determineModality(shipment) === this.modalityFilter);
    }
    if(!!this.terminalFilter){
      filteredItems = filteredItems.filter(shipment => shipment.data.deepSeaTerminal?.shortName === this.terminalFilter.shortName);
    }
    return filteredItems;
  }

  determineModality = (shipment: Shipment) : Modality => shipment.movements[1]?.vessel?.modality || shipment.data.inlandModality;

  modalityFilterOrRoad = () => this.modalityFilter == "all" ?  "road" : this.modalityFilter;

  #defaultFilterLength = "100";
  #regexp = /^\s*([A-Z]{4}[0-9]{7}(\s*;\s*))+([A-Z]{4}[0-9]{7})?\s*$/
  maxFilterLength = this.#defaultFilterLength;
  checkForSeparator(value: string) {
    this.maxFilterLength = this.#regexp.test(value) ? "" : this.#defaultFilterLength;
  }
}

