import {Component, Input, OnInit} from '@angular/core';
import {AppContext} from '../../app-context';
import {Router} from '@angular/router';
import {InjectorProvider} from '../injector-provider';
import {CookieService} from 'ngx-cookie';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {

  @Input() language;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.setLanguage(this.language || AppContext.getPreferredLanguage());
  }

  changeLanguage(language: string) {
    this.setLanguage(language);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    AppContext.navigateToUrl("/binnenhavengeld");
  }

  setLanguage(language: string) {
    this.language = language;
    localStorage.setItem('selected-language', language);
    const selectField : HTMLSelectElement = document.querySelector("#google_translate_element select");
    for (let i = 0; i < selectField?.children.length; i++) {
      const option = <HTMLOptionElement> selectField.children[i];
      if (option.value === language) {
        selectField.selectedIndex = i;
        selectField.dispatchEvent(new Event('change'));
        //for some reason switching from english to another language requires a 2nd change event
        selectField.dispatchEvent(new Event('change'));
        break;
      }
    }
    //for some reason Google sets 2 cookies: one for top domain and one for current domain...
    setTimeout(() => InjectorProvider.injector.get(CookieService)
      .remove('googtrans', {domain: '.portbase.com'}), 500)

    //update beamer
    window['Beamer']?.update({"language" : language});
  }

}
