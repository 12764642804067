import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../component/value-accessor.component';
import {uuid} from '../utils';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => YesNoComponent), multi: true}
  ],

})
export class YesNoComponent extends AbstractValueAccessorComponent<Boolean> implements OnInit {

  selectedValue : Boolean;
  @Input() inline: boolean = true;
  @Input() disabled: boolean;
  @Input() formatter = value => value ? "Yes" : "No";
  @Input() required;
  @Input() id = uuid();

  get value(): Boolean {
    return this.selectedValue;
  }

  writeValue(value: Boolean): void {
    this.selectedValue = value === undefined ? null : value;
  }

  ngOnInit(): void {
    if (this.required === "") {
      this.required = true;
    }
  }

}
