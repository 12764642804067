import {Component, ElementRef, OnInit} from '@angular/core';
import {checkValidity, sendCommand} from '../../../common/utils';
import {AcceptBinnenhavengeldUserAgreement} from '@portbase/hinterland-service-typescriptmodels';
import moment from 'moment';
import {AppContext} from '../../../app-context';
import {Location} from '@angular/common';

@Component({
  selector: 'app-bhg-accept-terms',
  templateUrl: './bhg-accept-terms.component.html',
  styleUrls: ['./bhg-accept-terms.component.css']
})
export class BhgAcceptTermsComponent implements OnInit {

  errors: string[];

  constructor(public location: Location, private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.errors = [];
  }

  accept() {
    if (checkValidity(this.elementRef)) {
      AppContext.clearAlerts();
      this.errors = [];
      const agreementId = String(moment().year());
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.AcceptBinnenhavengeldUserAgreement',
        <AcceptBinnenhavengeldUserAgreement>{agreementId: agreementId},
        () => {
          AppContext.binnenhavengeldUserProfile?.acceptedUserAgreements?.push(agreementId);
          AppContext.navigateToUrl("/binnenhavengeld");
        },
        (error) => {
          this.errors.push(error?.error?.error);
        });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  }
}
