<div class="mt-3" *ngIf="this.organisationPreferences
&& (appContext.environment !== 'pcs' || appContext.isOrganisationAdmin())
&& (appContext.userProfile.organisationModalities.includes('barge') ||
appContext.userProfile.organisationModalities.includes('rail') || appContext.isTractionSupplier())">
  <div class="declaration-block">
    <h2>Organisation preferences</h2>
  </div>
  <div class="row mt-1">
    <div class="col-md-12">
      <app-form-group>
        <label>
          <app-info [wide]="true">Activate data sharing with Nextlogic
            <div class="tooltipContent">
              Only activate this if Nextlogic has registered your organisation on their platform.
            </div>
          </app-info>
        </label>
        <app-yes-no [(ngModel)]="this.organisationPreferences.shareWithNextlogicEnabled"
                    (ngModelChange)="$event === false ? this.organisationPreferences.integralPlanningEnabled = false : null"></app-yes-no>
      </app-form-group>
      <app-form-group
        *ngIf="this.organisationPreferences.shareWithNextlogicEnabled && appContext.environment !== 'pcs'">
        <label>Activate integral planning with Nextlogic</label>
        <app-yes-no [(ngModel)]="this.organisationPreferences.integralPlanningEnabled"></app-yes-no>
      </app-form-group>
    </div>
  </div>
  <button class="btn btn-info mt-2" style="width: 100px;" (click)="updateOrganisationPreferences()">Save</button>
</div>

<div class="mt-3" *ngIf="this.userPreferences">
  <div class="declaration-block">
    <h2>User notification management</h2>
  </div>
  <div class="row mt-1">
    <div class="col-md-12">
      <div *ngIf="appContext.isHinterlandDeclarant()">
        <h6 class="pt-3 pb-2">Handlings</h6>
        <div>
        </div>
        <app-form-group>
          <label for="handling-preference-accept">Pre-notification is accepted</label>
          <input id="handling-preference-accept" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.containerStatusAcceptEmail">
        </app-form-group>
        <app-form-group>
          <label for="handling-preference-no-accept">Pre-notification is not accepted</label>
          <input id="handling-preference-no-accept" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.containerStatusNotAcceptEmail">
        </app-form-group>
        <app-form-group>
          <label for="handling-preference-reject">Pre-notification is rejected</label>
          <input id="handling-preference-reject" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.containerStatusRejectEmail">
        </app-form-group>
        <app-form-group label="Transport order is received"
                        tooltip="You will receive an email when a forwarder authorises you for a shipment and includes transport order details.">
          <input class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.transportOrderReceivedEmail">
        </app-form-group>

        <app-form-group label="Authorised for shipment in the secure chain"
                        tooltip="You will receive an email when a forwarder authorises you in the secure chain for a shipment and gives the permission to prenotify the container at the terminal">
          <input class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.shipmentNominationEmail">
        </app-form-group>

        <app-form-group label="Un-authorised for shipment in the secure chain"
                        tooltip="You will receive an email when a forwarder un-authorises you in the secure chain for a shipment and therefore you do not have the permission to prenotify the container at the terminal">
          <input class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.shipmentNominationRevokedEmail">
        </app-form-group>
      </div>

      <div *ngIf="appContext.isHinterlandDeclarant() || appContext.isTractionSupplier()">
        <div>
          <h6 class="pt-3 pb-2">Visits</h6>
        </div>
        <app-form-group>
          <label for="visit-preference-accept">Visit is accepted</label>
          <input id="visit-preference-accept" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.visitAcceptedEmail">
        </app-form-group>
        <app-form-group>
          <label for="visit-preference-acknowledge">Update of visit is acknowledged</label>
          <input id="visit-preference-acknowledge" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.visitUpdateAcknowledgedEmail">
        </app-form-group>
        <app-form-group>
          <label for="visit-preference-reject">Visit is rejected</label>
          <input id="visit-preference-reject" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.visitRejectedEmail">
        </app-form-group>
        <app-form-group>
          <label for="visit-preference-cancel">Visit is cancelled</label>
          <input id="visit-preference-cancel" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.visitCancelledEmail">
        </app-form-group>
      </div>

      <ng-container *ngIf="appContext.isHinterlandTerminal()">
        <div>
          <h6 class="pt-3 pb-2">Visit preferences for Terminal</h6>
        </div>
        <app-form-group>
          <label for="terminal-visit-preference-cancel">Visit is cancelled</label>
          <input id="terminal-visit-preference-cancel" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.visitCancelledEmail">
        </app-form-group>
        <app-form-group>
          <label for="terminal-visit-preference-update">Rail visit updates</label>
          <input id="terminal-visit-preference-update" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.railVisitStatusEmail">
        </app-form-group>
        <app-form-group>
          <label for="terminal-visit-preference-planning">Train composition updates</label>
          <input id="terminal-visit-preference-planning" class="form-control" placeholder="Email"
                 style="max-width: 30rem" [(ngModel)]="this.userPreferences.railPlanningNotificationEmail">
        </app-form-group>
        <app-form-group>
          <label>Receive train composition updates as rail consist xml</label>
          <app-yes-no [(ngModel)]="this.userPreferences.sendRailConsist" id="sendRailConsist"></app-yes-no>
        </app-form-group>
      </ng-container>

      <div *ngIf="appContext.isBargeDeclarant()">
        <h6 class="pt-3 pb-2">Integral Planning</h6>
        <app-form-group>
          <label for="ip-high-prio-notification">High priority notification</label>
          <input id="ip-high-prio-notification" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.integralPlanningHighPriorityAlertsEmail">
        </app-form-group>
        <app-form-group>
          <label for="ip-medium-prio-notification">Medium priority notification</label>
          <input id="ip-medium-prio-notification" class="form-control" placeholder="Email" style="max-width: 30rem"
                 [(ngModel)]="this.userPreferences.integralPlanningMediumPriorityAlertsEmail">
        </app-form-group>
      </div>
    </div>
    <button class="btn btn-info mt-3" style="width: 100px;" (click)="updateUserPreferences()">Save</button>
  </div>
</div>
