import {Observable} from "rxjs";
import {VoyageUnion} from "../hinterland/hinterland-utils";
import {
  EmhMessageLogItem,
  GetHandlingLog,
  GetHistoricalVoyage,
  GetMessageLogForHandling,
  GetVoyage,
  GetVoyageLog,
  Handling,
  LogItem,
  RoadVisit,
  VoyageData
} from "@portbase/hinterland-service-typescriptmodels";
import {sendCommand, sendQuery} from "./utils";
import {UpdateVoyage} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {AppContext} from "../app-context";

export function getVoyage(voyageId: string): Observable<VoyageUnion> {
  const payload: GetVoyage = <GetVoyage>{
    voyageId: voyageId
  };
  return sendQuery("com.portbase.hinterland.api.common.query.GetVoyage", payload, {caching: false, showSpinner: true});
}

export function getHistoricalVoyage(voyageId: string, eventId: string): Observable<VoyageUnion> {
  const payload: GetHistoricalVoyage = <GetHistoricalVoyage>{
    voyageId: voyageId,
    eventId: eventId
  };
  return sendQuery("com.portbase.hinterland.api.common.query.GetHistoricalVoyage", payload, {
    caching: false,
    showSpinner: true
  });
}

export function getVoyageLog(voyageId: string): Observable<LogItem[]> {
  const payload: GetVoyageLog = <GetVoyageLog>{
    voyageId: voyageId
  };
  return sendQuery("com.portbase.hinterland.api.common.query.GetVoyageLog", payload, {
    caching: false,
    showSpinner: false
  });
}

export function getHandlingLog(handlingId: string): Observable<LogItem[]> {
  const payload: GetHandlingLog = <GetHandlingLog>{
    handlingId: handlingId
  };
  return sendQuery("com.portbase.hinterland.api.common.query.GetHandlingLog", payload, {
    caching: false,
    showSpinner: false
  });
}

export function getHandlingMessageLog(handling: Handling, visit: RoadVisit): Observable<EmhMessageLogItem[]> {
  const payload: GetMessageLogForHandling = <GetMessageLogForHandling>{
    handlingId: handling.handlingId,
    visitId: visit?.terminal?.tarRequired && !!visit?.plannedVisitData?.tar ? visit?.plannedVisitData?.tar : visit?.visitId,
    containerNumber: handling.handlingData?.equipmentNumber
  }
  return sendQuery("com.portbase.hinterland.api.common.query.GetMessageLogForHandling", payload, {
    caching: false,
    showSpinner: false
  });
}

export function updateVoyage(voyageId: string, voyageData: VoyageData) {
  const payload: UpdateVoyage = <UpdateVoyage>{
    voyageId: voyageId,
    voyageData: voyageData
  };

  return sendCommand("com.portbase.hinterland.api.common.command.UpdateVoyage", payload, () => {
    AppContext.registerSuccess('The voyage was updated successfully');
  });
}
