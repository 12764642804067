import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BinnenhavengeldSubscription,
  NewBinnenhavengeldSubscription
} from '@portbase/hinterland-service-typescriptmodels';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-bhg-new-subscription',
  templateUrl: './bhg-new-subscription.component.html',
  styleUrls: ['./bhg-new-subscription.component.css']
})
export class BhgNewSubscriptionComponent implements OnInit {
  @Input() skipperId?: string;
  @Input() subscription: NewBinnenhavengeldSubscription;
  @Input() overlappingSubscriptionsQuery: () => Observable<BinnenhavengeldSubscription[]>;
  @Output() removeSubscription = new EventEmitter<NewBinnenhavengeldSubscription>();

  overlappingSubscriptions : BinnenhavengeldSubscription[] = [];

  ngOnInit(): void {
    this.overlappingSubscriptionsQuery().subscribe((result: BinnenhavengeldSubscription[]) => {
      this.overlappingSubscriptions = result;
    });
  }
}
