import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InfoComponent {
  @Input() wide: boolean;
  @Input() placement? : PlacementArray;

  @Output() shown = new EventEmitter<{}>();
  @Output() hidden = new EventEmitter<{}>();

  @ViewChild('tooltip', { static: true }) tooltip: NgbTooltip;

  isOpen() {
    return this.tooltip.isOpen();
  }

}
