<div class="fieldset" [ngClass]="{'disabled': isDisabled}">
  <div class="row">
    <div class="col-md-6">
      <app-form-group>
        <label>Terminal</label>
        <app-select [disabled]="!!visit.visitDeclaration"
                    [(ngModel)]="visit.terminal"
                    [options]="terminals" required dataKey="shortName"
                    (ngModelChange)="onTerminalChanged()">
        </app-select>
      </app-form-group>

      <app-form-group *ngIf="visit.visitId" label="Call ID">
        <input class="form-control" [(ngModel)]="visit.visitId" disabled>
      </app-form-group>

      <app-form-group *ngIf="timetableEntries?.length > 0 || visit.requestedVisitData?.timetableEntry">
        <label for="timetableEntry">Timetable slot</label>
        <app-select id="timetableEntry"
                    [(ngModel)]="visit.requestedVisitData.timetableEntry"
                    [options]="timetableEntries"
                    [nullOption]="false"
                    dataKey="code"
                    [formatter]="formatTimetableEntry"
                    (ngModelChange)="onTimetableEntryChanged()">
        </app-select>
      </app-form-group>
    </div>

    <div class="col-md-6">
      <app-form-group *ngIf="!hasTemplate" label="ETA">
        <app-compare [value]="visit.requestedVisitData?.eta | timestamp"
                     [otherValue]="visit.plannedVisitData?.eta | timestamp"
                     [mode]="'TERMINAL'">
          <app-date-time [(ngModel)]="visit.requestedVisitData.eta" required
                         (ngModelChange)="etaUpdatedEventEmitter.emit()">
          </app-date-time>
        </app-compare>
      </app-form-group>

      <app-form-group *ngIf="!hasTemplate" label="ETD">
        <app-compare [value]="visit.requestedVisitData?.etd | timestamp"
                     [otherValue]="visit.plannedVisitData?.etd | timestamp"
                     [mode]="'TERMINAL'">
          <app-date-time [(ngModel)]="visit.requestedVisitData.etd" required
                         (ngModelChange)="etaUpdatedEventEmitter.emit()"></app-date-time>
        </app-compare>
      </app-form-group>

      <app-form-group *ngIf="hasTemplate" class="day-and-time-range" label="ETA time of week">
        <app-select [(ngModel)]="getVisitDataTemplate().etaTimeOfWeek.day"
                    [options]="weekDays(!!getVisitDataTemplate().timetableEntry)"
                    [nullOption]="!getVisitDataTemplate().timetableEntry"
                    [formatter]="toTitleCase"
                    [required]="!getVisitDataTemplate().timetableEntry">
        </app-select>
        <app-date-time [(ngModel)]="getVisitDataTemplate().etaTimeOfWeek.time"
                       timeOnly="true"
                       [required]="!getVisitDataTemplate().timetableEntry">
        </app-date-time>
      </app-form-group>

      <app-form-group *ngIf="hasTemplate" class="day-and-time-range" label="ETD time of week">
        <app-select [(ngModel)]="getVisitDataTemplate().etdTimeOfWeek.day"
                    [options]="weekDays(!!getVisitDataTemplate().timetableEntry)"
                    [nullOption]="!getVisitDataTemplate().timetableEntry"
                    [formatter]="toTitleCase"
                    [required]="!getVisitDataTemplate().timetableEntry">
        </app-select>
        <app-date-time [(ngModel)]="getVisitDataTemplate().etdTimeOfWeek.time"
                       timeOnly="true"
                       [required]="!getVisitDataTemplate().timetableEntry">
        </app-date-time>
      </app-form-group>

      <app-form-group>
        <label>Operator remarks</label>
        <textarea class="remarks form-control"
                  [(ngModel)]="visit.requestedVisitData.operatorRemarks"
                  placeholder="Type any remarks for the terminal here">
        </textarea>
      </app-form-group>
    </div>
  </div>

  <ng-container *ngIf="visit.requestedVisitData.estimatedHandlings">
    <div class="row">
      <div class="col-md-6 declaration-block" >
        <h2>Discharge</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
        </div>

        <div class="row narrow-gutters py-2">
          <div class="col">
            <!-- railestimatedhandlings nog niet beschikbaar in plannedVisitData-->
            <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeTotal"
                         [otherValue]="visit.visitData?.estimatedHandlings?.dischargeTotal"
                         [mode]="'TERMINAL'">
              <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeTotal" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
            </app-compare>
          </div>
          <!-- railestimatedhandlings nog niet beschikbaar in plannedVisitData-->
          <div class="col">
            <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.dischargeEmpty"
                         [otherValue]=" visit.visitData?.estimatedHandlings?.dischargeEmpty"
                         [mode]="'TERMINAL'">
              <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.dischargeEmpty" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
            </app-compare>
          </div>
        </div>
      </div>

      <div class="col-md-6 declaration-block">
        <h2>Loading</h2>

        <div class="row narrow-gutters bg-light py-2">
          <div class="col"><span class="ps-2">Total</span></div>
          <div class="col"># Empty</div>
        </div>

        <div class="row narrow-gutters py-2">
          <div class="col">
            <!-- railestimatedhandlings nog niet beschikbaar in plannedVisitData-->
            <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingTotal"
                         [otherValue]="visit.visitData?.estimatedHandlings?.loadingTotal"
                         [mode]="'TERMINAL'">
              <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingTotal" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
            </app-compare>
          </div>
          <div class="col">
            <!-- railestimatedhandlings nog niet beschikbaar in plannedVisitData-->
            <app-compare [value]="visit.requestedVisitData?.estimatedHandlings?.loadingEmpty"
                         [otherValue]="visit.visitData?.estimatedHandlings?.loadingEmpty"
                         [mode]="'TERMINAL'">
              <input [(ngModel)]="visit.requestedVisitData.estimatedHandlings.loadingEmpty" appDecimalNumber [maxDecimals]="0" min="0" class="form-control">
            </app-compare>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="utils.isVoyageDeclarantByShortName(voyageDeclarantShortname) && !hasTemplate">
    <div class="row narrow-gutters mt-3">
      <ng-container *ngIf="!visit.arrived; else visitArrived">
        <div class="col-md-auto mb-3 mb-md-0">
          <button *ngIf="!!visit.visitId; else cancelUnsavedVisit"
                  data-bs-toggle="modal" [attr.data-bs-target]="'#cancelVisitModal-' + visit.visitId"
                  type="button" class="btn btn-danger btn-block" style="min-width: 10rem" tabindex="-1">
            Cancel visit
          </button>
          <ng-template #cancelUnsavedVisit>
            <button (click)="cancelVisit()" type="button" class="btn btn-secondary btn-block" tabindex="-1" style="min-width: 10rem;">
              Cancel visit
            </button>
          </ng-template>
        </div>
        <div class="col-md-auto mb-md-0 ms-auto"></div>
        <div *ngIf="!visit.visitDeclaration" class="col-md-auto mb-3 mb-md-0">
          <button (click)="saveVisit()" type="button" class="btn btn-info btn-block" tabindex="-1"  style="min-width: 10rem;">
            <span>Save visit</span>
          </button>
        </div>
        <div *ngIf="visit.acknowledgedByOperator === false" class="col-md-auto mb-3 mb-md-0 enabled">
          <button (click)="acknowledgeUpdate()" type="button" class="btn btn-outline-info btn-block" tabindex="-1" style="min-width: 10rem;">
            <span>Acknowledge update</span>
          </button>
        </div>
        <div class="col-md-auto ms-0">
          <button (click)="declareVisit()" type="button" class="btn btn-info btn-block" tabindex="-1" style="min-width: 10rem;">
            <span>Save & send visit</span>
          </button>
        </div>
      </ng-container>

      <ng-template #visitArrived>
        <div *ngIf="visit.acknowledgedByOperator === false" class="ms-auto col-md-auto mb-3 mb-md-0 enabled">
          <button (click)="acknowledgeUpdate()" type="button" class="btn btn-outline-info btn-block" tabindex="-1" style="min-width: 10rem;">
            <span>Acknowledge update</span>
          </button>
        </div>
      </ng-template>
    </div>
  </ng-container>


  <div *ngIf="visit.visitId && !visit.arrived"
       [id]="'cancelVisitModal-' + visit.visitId" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure that you want to cancel this visit?</h5>
        </div>
        <div class="modal-body">
          <p>Handlings attached to this visit need to be moved, detached or cancelled before the visit can be cancelled.</p>
        </div>
        <div class="modal-footer">
          <div class="container-fluid px-0">
            <div class="row g-0 justify-content-between">
              <div class="col-auto">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="width: 8rem">No</button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-info" data-bs-dismiss="modal" style="min-width: 8rem"
                        (click)="cancelVisit()">Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
