import {Component, Input, OnInit} from '@angular/core';
import {VoyageUnion} from '../../hinterland-utils';
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-voyage-summary',
  templateUrl: './voyage-summary.component.html',
  styleUrls: ['./voyage-summary.component.scss']
})
export class VoyageSummaryComponent implements OnInit {

  @Input() voyage: VoyageUnion;
  @Input() hasRailTemplate = false;
  @Input() summaryContext: SummaryContext;

  appContext = AppContext;

  isModalityRoad: boolean;
  isModalityBarge: boolean;
  isModalityRail: boolean;

  ngOnInit(): void {
    this.isModalityRoad = this.voyage.modality === 'road';
    this.isModalityBarge = this.voyage.modality === 'barge';
    this.isModalityRail = this.voyage.modality === 'rail';
  }

}

export type SummaryContext = "overview" | "detail"
