<div class="alert alert-{{type}} alert-dismissible fade show m-0" role="alert">
  <div class="row align-items-center">
    <div class="col-auto">
      <h2 class="fa-light fa-circle-exclamation m-0"></h2>
    </div>
    <div class="col">
      <div class="fw-medium"><ng-content select=".alert-heading"></ng-content></div>
      <ng-content></ng-content>
    </div>
  </div>
  <button *ngIf="dismissible" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" tabindex="-1"></button>
</div>
