import {Component, Input} from '@angular/core';
import {HandlingModel, HinterlandUtils} from '../../../hinterland-utils';
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-visit-status',
  templateUrl: './visit-status.component.html',
  styleUrls: ['./visit-status.component.css']
})
export class VisitStatusComponent {

  @Input() handling: HandlingModel;
  @Input() allHandlingsInVisitCancelled: boolean;

  appContext = AppContext;
  utils = HinterlandUtils;

  getCargoDeclarant() {
    return this.handling.cargoDeclarants?.find(c => c.shortName === this.handling.cargoDeclarantShortName)?.fullName;
  }

}
