import {Component, Input, OnInit} from '@angular/core';
import {Handling, LogItem} from '@portbase/hinterland-service-typescriptmodels';

import {ComparatorChain} from "../../../common/comparator-chain";
import {sendQuery} from "../../../common/utils";

@Component({
  selector: 'app-history-component',
  templateUrl: './history-overview.component.html',
  styleUrls: ['./history-overview.component.css']
})
export class HistoryOverviewComponent implements OnInit {

  logComparator = new ComparatorChain("timestamp").compare;
  reverseLogComparator = new ComparatorChain("-timestamp").compare;

  @Input() handling: Handling;
  @Input() visitId: string;
  @Input() voyageId: string;

  sortDirection: SortType = "ascending";
  items: LogItem[];

  ngOnInit(): void {
    if (this.voyageId != null) {
      this.getFilteredVoyageLog();
    } else if (this.handling != null) {
      this.getHandlingLog();
    } else if (this.visitId != null) {
      this.getVisitLog();
    }
  }

  toggleSort() {
    this.sortDirection = this.sortDirection == "ascending" ? "descending" : "ascending";
    this.items.sort(this.getComparator());
  }

  getHandlingLog(): void {
    sendQuery("com.portbase.hinterland.api.common.query.GetHandlingLog", {handlingId: this.handling.handlingId},
      {showSpinner: true, caching: false})
      .subscribe((items: LogItem[]) => {
        this.items = items.sort(this.getComparator());
      });
  }

  getVisitLog(): void {
    sendQuery("com.portbase.hinterland.api.common.query.GetVisitLog", {visitId: this.visitId},
      {showSpinner: true, caching: false})
      .subscribe((items: LogItem[]) => {
        this.items = items.filter(item => !item.handlingId).sort(this.getComparator());
      });
  }

  getFilteredVoyageLog(): void {
    sendQuery("com.portbase.hinterland.api.common.query.GetVoyageLog", {voyageId: this.voyageId},
      {showSpinner: true, caching: false})
      .subscribe((items: LogItem[]) => {
        //toon items die geen visitId of handlingId hebben of matchen op visitId of handlingId.
        this.items = items.filter(item => (!item.visitId || item.visitId == this.visitId) &&
          (!this.handling || !item.handlingId || item.handlingId == this.handling.handlingId))
          .sort(this.getComparator());
      });
  }

  getComparator() {
    return this.sortDirection == 'ascending' ? this.logComparator : this.reverseLogComparator;
  }

  getMode(): string {
    if (this.handling != null) {
      return "HANDLING";
    }
    if (this.visitId != null) {
      return "VISIT"
    }
    return "VOYAGE";
  }

  cast = (item: LogItem): LogItem => item;

  organisationName(user: string) {
    let start = user.indexOf('@', 0);
    return user.substring(Math.max(0, start + 1));
  }

}

export type SortType = 'descending' | 'ascending';
