import {Component, ElementRef, Input} from '@angular/core';
import {checkValidity} from '../../common/utils';
import {AppContext} from '../../app-context';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-send-email-token-component',
  templateUrl: './send-email-token.component.html',
  styleUrls: ['./send-email-token.component.css']
})
export class SendEmailTokenComponent {

  user: string;
  organisation: string;

  emailSent: boolean;

  constructor(private element: ElementRef, private http: HttpClient) {
  }

  @Input() set visible(visible : boolean) {
    if (visible) {
      AppContext.clearAlerts();
      $('#sendEmailTokenModal').modal({backdrop: 'static', keyboard: false});
    }
  }

  sendLoginToken = () => {
    if (checkValidity(this.element)) {
      AppContext.clearAlerts();
      return AppContext.waitForProcess(this.http.post<any>('/api/emailToken', {
        user : this.user,
        organisation : this.organisation
      })).subscribe(() => {
          this.emailSent = true
          AppContext.registerSuccess('The email was sent successfully')
        },
          error => AppContext.registerError(error));
    }
  };
}
