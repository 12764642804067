<div *ngIf="appContext.isAdmin() || appContext.isOrganisationAdmin()" class="mt-3">
  <div class="declaration-block">
    <h2>Integral planning preferences {{shortName}}</h2>
  </div>
  <div class="row mt-1">
    <div class="col-12">
      <span *ngIf="!!organisationPreferences else loading">
        <app-form-group>
            <label>
              <app-info [wide]="true">Activate or de-activate data sharing
                <div class="tooltipContent">
                  Changing this setting only affects new rotations!
                </div>
              </app-info>
            </label>
            <app-yes-no (click)="updateShareWithNextlogicPreference()" [ngModel]="this.organisationPreferences.shareWithNextlogicEnabled"
                        inline="true"></app-yes-no>
        </app-form-group>
      </span>
      <ng-template #loading>
        <span>Loading organisation preferences</span>
      </ng-template>
    </div>
    <div class="col-12">
      <app-form-group>
        <label>
          <app-info [wide]="true">Activate or de-activate Integral Planning
            <div class="tooltipContent">
              Changing this setting only affects new rotations! Only enable this setting when this organisation is also registered at the NXL platform!
            </div>
          </app-info>
        </label>
        <app-yes-no (click)="updateIntegralPlanningPreference()" [disabled]="!this.organisationPreferences.shareWithNextlogicEnabled"
                    [ngModel]="this.organisationPreferences.integralPlanningEnabled" inline="true"></app-yes-no>

      </app-form-group>
    </div>
  </div>
</div>
