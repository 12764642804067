<div *ngIf="appContext.isAdmin()" class="row always-enabled pb-3">
  <div class="col-md-12">
    <div class="row g-0 justify-content-end">
      <div class="col-md-auto">
      </div>
      <div class="dropdown col-md-auto mt-3 mt-md-0">
        <button class="btn btn-secondary dropdown-toggle" style="min-width: 10rem;"
                type="button" data-bs-toggle="dropdown">Messages
          <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li (click)="openOutgoingIftsaiMessages()" class="dropdown-item">IFTSAI out -> APERAK in</li>
        </ul>
      </div>
    </div>
  </div>
</div>
