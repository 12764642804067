import {Alert} from "./status-alert/alert";

/**
 * Validator for the container equipment number according to ISO 6346
 */

export const regexAlert = <Alert>{content: "Container number does not match pattern of four letters and seven digits", type: 'warning'};
export const digitAlert = <Alert>{content: "Container number contains an incorrect check digit", type: 'warning'};

export class ContainerNumberValidator {
  private static regex: RegExp = new RegExp(/^[A-Z]{4}[0-9]{7}/g);

  static compliesToIso6346(equipmentNumber:string) {
    return this.checkCorrectRegex(equipmentNumber) && this.checkDigitCorrect(equipmentNumber);
  }

  static checkCorrectRegex(equipmentNumber: string) {
    return equipmentNumber.match(this.regex)
  }

  static checkDigitCorrect(equipmentNumber: string) {
    const alphabetIndex: { [key: string]: number } = {
      A: 10, B: 12, C: 13, D: 14, E: 15, F: 16, G: 17, H: 18, I: 19, J: 20, K: 21, L: 23, M: 24,
      N: 25, O: 26, P: 27, Q: 28, R: 29, S: 30, T: 31, U: 32, V: 34, W: 35, X: 36, Y: 37, Z: 38
    }

    const factor = 11;
    const base = 2;
    const checkDigitPos = 10;

    // Step 1 - Convert all elements to numbers
    const elements = Array.from(equipmentNumber);

    // Step 2 - Convert letters to numbers
    // Step 3.i - Sum all values
    let sum = 0;
    for (let i = 0; i < checkDigitPos; i++) {
      let value = elements[i];
      let number = alphabetIndex[value];
      if (number != null) {
        sum = sum + (number * Math.pow(base, i));
      } else {
        sum = sum + (Number(value) * Math.pow(base, i));
      }
    }

    // Step 3.ii - Divide by 11 and take
    // Step 3.iii - Round the result down
    let division = Math.floor(sum / factor);

    // Step 3.iv - Multiply by 11
    let absoluteSum = division * factor;

    // Step 3.v - Calculate difference between sum and absolute sum
    let checkDigit = Math.abs(sum - absoluteSum);

    if (checkDigit == 10) {
      checkDigit = 0;
    }

    // Check digit
    return checkDigit == Number(elements[10]);
  }

}
