import {Component} from '@angular/core';
import {ShipmentExcelUtils} from './shipment-excel.utils';

@Component({
  selector: 'app-cargo-shipments',
  templateUrl: './cargo-shipments.component.html',
  styleUrls: ['./cargo-shipments.component.css']
})
export class CargoShipmentsComponent {
  excel = ShipmentExcelUtils;
}
