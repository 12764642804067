<div class="row">
    <div class="col-md">
      <app-form-group label="Invoice reference">
        <input [ngModel]="order.invoiceReference" class="form-control">
      </app-form-group>
      <app-form-group label="Debtor">
        <input [ngModel]="order.debtorName" class="form-control">
      </app-form-group>
      <app-form-group label="Consignee">
        <input [ngModel]="order.consigneeName" class="form-control">
      </app-form-group>
      <app-form-group label="Release expiration">
        <app-date-time [ngModel]="order.releaseExpiration"></app-date-time>
      </app-form-group>
      <app-form-group label="Demurrage">
        <app-days [ngModel]="order.demurrage"></app-days>
      </app-form-group>
      <app-form-group label="Detention">
        <app-days [ngModel]="order.detention"></app-days>
      </app-form-group>
      <app-form-group label="Return terminal">
        <input [ngModel]="order.returnTerminal" class="form-control">
      </app-form-group>
      <app-form-group label="Return reference">
        <input [ngModel]="order.returnReference" class="form-control">
      </app-form-group>
    </div>

    <div class="col-md">
      <app-form-group label="Destination target date">
        <app-date-time [ngModel]="order.destinationTargetDate" [dateOnly]="true"></app-date-time>
      </app-form-group>
      <app-form-group label="Destination reference">
        <input [ngModel]="order.destinationReference" class="form-control">
      </app-form-group>
      <app-form-group label="Destination instructions">
        <textarea [ngModel]="order.destinationInstructions" class="form-control"></textarea>
      </app-form-group>
      <app-form-group label="Customs category">
        <input [ngModel]="order.customsCategory" class="form-control">
      </app-form-group>
      <app-form-group label="Customs document number">
        <input [ngModel]="order.documentNumber" class="form-control">
      </app-form-group>
      <app-form-group label="Document expiration">
        <app-date-time [ngModel]="order.documentExpiration" [dateOnly]="true"></app-date-time>
      </app-form-group>
    </div>
  </div>
