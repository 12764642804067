import { Component, ElementRef, OnInit, Type } from '@angular/core';
import {
  DeepSeaReference,
  DetachedHandling,
  Handling,
  RoadVisit,
  RoadVoyage,
  Terminal,
  TerminalSettings
} from "@portbase/hinterland-service-typescriptmodels";
import { HinterlandUtils, TerminalModel } from "../../../../hinterland/hinterland-utils";
import { HinterlandBaseComponent } from "../../../../common/hinterland-base-component";
import { checkValidity, cloneObject, scrollToTopInPanel } from "../../../../common/utils";
import { VoyageVisitAndHandling } from "../../../../hinterland/types";
import { ViewHandlingOffCanvasComponent } from "../view-handling-details-offcanvas/app-view-handling-offcanvas";
import { ContainerNumberValidator } from "../../../../common/container-number-validator";
import { SwitchEditingDialogComponent } from "../../../../components/dialogs/switch-editing-dialog/switch-editing-dialog.component";
import { AppContext } from "../../../../app-context";
import { AppData } from "../../../app-data";
import { EditModalService } from '../../../../components/modals/edit-modal/edit-modal.service';
import { ConfirmationModalService } from '../../../../components/modals/confirmation-modal/confirmation-modal.service';
import {
  CloseEditingDialogComponent
} from '../../../../components/dialogs/close-editing-dialog/close-editing-dialog.component';
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";
import {InjectorProvider} from "../../../../common/injector-provider";

@Component({
  selector: 'app-edit-handling-offcanvas',
  templateUrl: './app-edit-handling-offcanvas.html',
  styleUrls: ['./app-edit-handling-offcanvas.scss']
})
export class EditHandlingOffCanvasComponent extends HinterlandBaseComponent implements OnInit, ClosableModal {

  isModalClosable: boolean = true;
  data: VoyageVisitAndHandling;
  duplicate:VoyageVisitAndHandling;

  voyage: RoadVoyage;
  visit: RoadVisit;
  handling: Handling;
  isDetached: boolean;

  terminal: Terminal;
  terminalSettings: TerminalSettings;
  terminals: TerminalModel[];

  invalidEquipmentNumberLength: boolean = false;
  invalidEquipmentNumberCheckDigit: boolean = false;
  validationErrors: boolean = false;

  hasReefer = false;
  hasOutOfGauge = false;

  utils = HinterlandUtils;
  appContext = AppContext;
  appData: AppData;

  errors: String[] = [];

  constructor(
      private element: ElementRef,
      private readonly editModalService: EditModalService,
      private readonly confirmationModalService: ConfirmationModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.appData = InjectorProvider.injector.get(AppData);
    this.errors = [];
    this.utils.getTerminals('road', true, true, true).subscribe(terminals => this.terminals = terminals);

    this.voyage = cloneObject(this.data?.voyage);
    this.visit = cloneObject(this.data?.visit);
    this.handling = cloneObject(this.data?.handling);

    this.isDetached = !this.voyage;

    this.hasReefer = !!this.handling.handlingData.reefer;
    if (this.isDetached) {
      this.terminal = (<DetachedHandling>this.handling).terminal;
    } else {
      this.terminal = this.visit.terminal;
    }

    if (this.data.handling.handlingData.deepSea) {
      this.handling.handlingData.deepSea = this.data.handling.handlingData.deepSea;
    } else if (this.data.handling.deepSea) {
      this.handling.handlingData.deepSea = <DeepSeaReference> {
        imoCode: this.data.handling.deepSea.imoCode,
        shipName: this.data.handling.deepSea.shipName,
        crnNumber: this.data.handling.deepSea.crnNumber
      };
    }

    this.setTerminalSettings();

    this.isModalClosable = true;
  }

  onContainerNumberPaste(event: ClipboardEvent) {
    const pastedContainerNumber = event.clipboardData.getData('text');
    this.handling.handlingData.equipmentNumber = pastedContainerNumber.replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
    event.preventDefault();
  }

  equipmentNumberRequired() {
    return this.handling.handlingData.full === true
      || (this.handling.handlingData.type === 'discharge' && !this.utils.isLossenOpRichtlijnAllowed(this.terminal, 'road'));
  }

  validateEquipmentNumber() {
    this.invalidEquipmentNumberLength = !!this.handling.handlingData.equipmentNumber && !this.handling.handlingData.shippersOwned
      && !ContainerNumberValidator.checkCorrectRegex(this.handling.handlingData.equipmentNumber);
    this.invalidEquipmentNumberCheckDigit = !!this.handling.handlingData.equipmentNumber && !this.handling.handlingData.shippersOwned
      && !ContainerNumberValidator.checkDigitCorrect(this.handling.handlingData.equipmentNumber);

    this.setModalClosable(false);
  }

  onTerminalChanged() {
    this.setTerminalSettings();
    this.checkForDuplicateHandling();
  }

  onEquipmentNumberChanged() {
    this.checkForDuplicateHandling();
  }

  onHandlingTypeChanged() {
    this.setTerminalSettings();
    this.checkForDuplicateHandling();
  }

  checkForDuplicateHandling() {
    for (const roadItem of this.appData.roadItems) {
      if (roadItem.isVoyage) {
        let voyage:RoadVoyage = roadItem.getVoyage();
        const duplicate:VoyageVisitAndHandling = this.checkForDuplicateVoyageHandling(voyage);
        if (duplicate) {
          this.duplicate = duplicate;
          return;
        }
      } else {
        let detachedHandling:DetachedHandling = roadItem.getDetachedHandling();
        const duplicate:VoyageVisitAndHandling = this.checkForDuplicateDetachedHandling(detachedHandling);
        if (duplicate) {
          this.duplicate = duplicate;
          return;
        }
      }
    }

    this.duplicate = undefined;
  }

  checkForDuplicateVoyageHandling(voyage:RoadVoyage):VoyageVisitAndHandling {
    if (voyage.declarant.shortName !== AppContext.userProfile.organisationShortName) {
      return undefined;
    }

    for (const visit of voyage.visits) {
      if (visit.terminal.bicsCode !== this.terminal.bicsCode) {
        continue;
      }

      for (const handling of visit.handlings) {
        if (handling.handlingId === this.handling.handlingId) {
          continue;
        }

        if (handling.completed === false
            && handling.handlingData.equipmentNumber
            && handling.handlingData.equipmentNumber === this.handling.handlingData.equipmentNumber
            && handling.handlingData.type === this.handling.handlingData.type) {
          return <VoyageVisitAndHandling> {
            voyage: voyage,
            visit: visit,
            handling: handling
          };
        }
      }
    }

    return undefined;
  }

  checkForDuplicateDetachedHandling(detachedHandling:DetachedHandling):VoyageVisitAndHandling {
    if (detachedHandling.handlingId === this.handling.handlingId
        || detachedHandling.declarant.shortName !== AppContext.userProfile.organisationShortName) {
      return undefined;
    }

    if (detachedHandling.terminal.bicsCode === this.terminal.bicsCode
        && detachedHandling.handlingData.equipmentNumber
        && detachedHandling.handlingData.equipmentNumber === this.handling.handlingData.equipmentNumber
        && detachedHandling.handlingData.type === this.handling.handlingData.type) {
      return <VoyageVisitAndHandling> {
        voyage: undefined,
        visit: undefined,
        handling: detachedHandling
      };
    }

    return undefined;
  }

  openDuplicateHandling() {
    this.editModalService.openModal(EditHandlingOffCanvasComponent, this.duplicate);
  }

  setTerminalSettings() {
    this.terminalSettings = this.terminal.terminalSettings.find(setting =>
        setting.modality === "road"
        && setting.full === this.handling.handlingData.full
        && setting.loading === (this.handling.handlingData.type === 'loading'))
      || <TerminalSettings>{};

    if (this.terminalSettings.sealNumberAllowed !== true && this.handling.handlingData.sealNumber) {
      this.handling.handlingData.sealNumber = null;
    }
    if (this.terminalSettings.outOfGaugeAllowed !== true) {
      this.handling.handlingData.outOfGauge = null;
    }
  }

  hasReeferChanged() {
    this.handling.handlingData.reefer = this.hasReefer ? {temperatureFixed: true} : undefined;
  }

  hasOutOfGaugeChanged() {
    this.handling.handlingData.outOfGauge = this.hasOutOfGauge ? {} : undefined;
  }

  temperatureFixedChanged() {
    if (this.handling.handlingData.reefer) {
      if (this.handling.handlingData.reefer.temperatureFixed) {
        this.handling.handlingData.reefer.minimumTemperature = undefined;
        this.handling.handlingData.reefer.maximumTemperature = undefined;
      } else {
        this.handling.handlingData.reefer.temperature = undefined;
      }
    }
  }

  updatePreNotification() {
    this.setModalClosable(true);

    this.validationErrors = false;
    if (this.invalidEquipmentNumberLength || this.invalidEquipmentNumberCheckDigit) {
      this.validationErrors = true;
      return;
    }

    if (checkValidity(this.element)) {
      this.queryAndCommandGateway.declareHandling(this.handling, true, this.voyage.voyageId, this.visit.visitId,
        () => {
          AppContext.publishSuccessMessage("The handling was updated successfully.");
          this.closeModal();
        },
        (error) => {
          this.errors.push(error?.error?.error);
          scrollToTopInPanel();
        }
      );
    } else {
      this.validationErrors = true;
      scrollToTopInPanel();
    }
  }

  updateStatusRequest() {
    this.setModalClosable(true);

    this.validationErrors = false;
    if (this.invalidEquipmentNumberLength || this.invalidEquipmentNumberCheckDigit) {
      this.validationErrors = true;
      return;
    }

    if (checkValidity(this.element)) {
      this.queryAndCommandGateway.declareDetachedHandling(this.handling, this.terminal, this.handling['eta'],
        () => {
          AppContext.publishSuccessMessage("The handling was updated successfully.");
          this.closeModal();
        },
        (error) => {
          this.errors.push(error?.error?.error);
          scrollToTopInPanel();
        });
    } else {
      this.validationErrors = true;
      scrollToTopInPanel();
    }
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

  switchModal() {
    this.isModalClosable = true;
    this.openViewHandlingPanel();
  }

  getCloseDialogComponentType(): Type<any> {
    return CloseEditingDialogComponent;
  }

  openViewHandlingPanel() {
    if (this.isModalClosable) {
      this.editModalService.openModal(ViewHandlingOffCanvasComponent, <VoyageVisitAndHandling>{
        'voyage': this.data?.voyage,
        'visit': this.data?.visit,
        'handling': this.data?.handling
      });
    } else {
      this.confirmationModalService.openModal(SwitchEditingDialogComponent, this);
    }
  }

}
