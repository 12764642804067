import {Pipe, PipeTransform} from '@angular/core';
import {Terminal} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {TerminalSettings} from "@portbase/hinterland-service-typescriptmodels";

@Pipe({
  name: 'asTerminalSettings',
  pure: true
})
export class CastTerminalSettingsPipe implements PipeTransform {
  transform(value: any, args?: any): TerminalSettings[] {
    return value;
  }
}
