import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {AppContext} from "../../../app-context";
import {GetOrganisationPreferences, OrganisationPreferences, UpdateOrganisationPreferences} from "@portbase/hinterland-service-typescriptmodels";
import {sendCommand, sendQuery} from "../../../common/utils";
import {HinterlandUtils} from "../../../hinterland/hinterland-utils";
import {take} from "rxjs/operators";

@Component({
  selector: 'organisation-preferences',
  templateUrl: './organisation-preferences.component.html',
  styleUrls: ['./organisation-preferences.component.css']
})
export class OrganisationPreferencesComponent implements OnInit, OnChanges {
  appContext = AppContext;
  organisationPreferences: OrganisationPreferences;
  utils = HinterlandUtils;

  @Input() shortName: string;
  fullName: string;

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init() {
    if (!this.shortName) {
      this.shortName = this.appContext.userProfile.organisationShortName;
    }

    this.fullName = this.appContext.userProfile.organisation.fullName;

    this.organisationPreferences = null;
    sendQuery("com.portbase.common.api.authentication.query.GetOrganisationPreferences", <GetOrganisationPreferences>{organisation: this.shortName})
      .pipe(take(1)).subscribe(result => {
      this.organisationPreferences = result;
    });
  }

  updateOrganisationPreferences() {
    sendCommand('com.portbase.common.api.authentication.command.UpdateOrganisationPreferences', <UpdateOrganisationPreferences>{
      organisationShortName: this.shortName,
      preferences: this.organisationPreferences
    }, () => AppContext.registerSuccess('Preferences for ' + this.fullName + ' updated successfully'));
  }

  updateIntegralPlanningPreference() {
    this.organisationPreferences.integralPlanningEnabled = !this.organisationPreferences.integralPlanningEnabled;
    this.updateOrganisationPreferences();
  }

  updateShareWithNextlogicPreference() {
    this.organisationPreferences.shareWithNextlogicEnabled = !this.organisationPreferences.shareWithNextlogicEnabled;
    if (!this.organisationPreferences.shareWithNextlogicEnabled) {
      this.organisationPreferences.integralPlanningEnabled = false; //IMPORTANT! als BO geen data wil delen, dan ook geen integrale planning!
    }
    this.updateOrganisationPreferences();
  }
}
