import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  @ViewChild('form', {static: true}) form: ElementRef;
  @Output() submit = new EventEmitter<SubmitEvent>();

  checkValidity() : boolean {
    return this.form.nativeElement.checkValidity();
  }
}
