<div class="bg-white border rounded m-3 p-3">
  <div class="row narrow-gutters align-items-center">
    <div class="col-auto">
      <h4 class="m-0 pe-3"><i class="fal fa-clock" [ngClass]="{'text-secondary' : signalling['legacy'] && superOrAdmin}"></i></h4>
    </div>
    <div class="col text-truncate">
      <div class="notranslate">
        {{signalling?.ship?.name}}
      </div>
      <div class="sub-text">
        Ship
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <div class="notranslate">
        {{signalling?.ship?.eniNumber}}
      </div>
      <div class="sub-text notranslate">
        ENI
      </div>
    </div>
    <div class="col d-none d-lg-block" *ngIf="superOrAdmin">
      <div class="notranslate">
        {{signalling?.signaller?.organisationId}}
      </div>
      <div class="sub-text">
        Signaller
      </div>
    </div>
    <div class="col">
      <div class="notranslate">
        {{signalling?.timestamp | timestamp:'DD-MM-YYYY HH:mm'}}
      </div>
      <div class="sub-text">
        Timestamp
      </div>
    </div>
    <div class="col d-none d-lg-block">
      <div class="notranslate">
        {{signalling?.location?.name | titlecase}}
      </div>
      <div class="sub-text">
        Location
      </div>
    </div>
  </div>
</div>
