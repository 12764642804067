import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {sendCommand, sendQuery} from '../common/utils';
import moment from 'moment';
import {AppContext} from '../app-context';
import {
  Application,
  DeclarationType,
  GetApplication,
  PendingDeclaration,
  TerminalTrackerStats
} from "@portbase/hinterland-service-typescriptmodels";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-message-overview',
  templateUrl: './message-overview.component.html',
  styleUrls: ['./message-overview.component.scss']
})
export class MessageOverviewComponent implements AfterViewInit, OnInit, OnDestroy {
  declarationTypes: DeclarationType[] = <any>["All types", "COPINO", "IFTSAI"];

  terminals: string [] = <any>["All terminals", "APMII", "APMRTM", "ECTDELTA", "EUROMAX", "RWG", "RST", "INTERFORESTRTM", "BROEKMANDISTRI"];

  filter: MessageFilter = {
    declarationType: <any>"All types",
    terminal: <any>"All terminals",
    minimumAge: 1,
    minimumAgeUnit: 'hours'
  };

  pendingDeclarations: PendingDeclaration[] = [];
  trackerStats: TerminalTrackerStats[] = [];

  appContext = AppContext;
  application: Application;

  applicationSubscription: Subscription;

  ngOnInit() {
    this.applicationSubscription = sendQuery("com.portbase.common.api.application.query.GetApplication", <GetApplication>{})
      .subscribe(result => {
        this.application = result;
      });
  }

  ngAfterViewInit(): void {
    this.loadPendingDeclarations();
    this.loadTrackerStats();
  }

  trackByHandlingId(index: number, declaration: PendingDeclaration) {
    return declaration.handlingId;
  }

  private loadTrackerStats() {
    sendQuery("com.portbase.common.api.admin.GetTerminalTrackerStats", {}, {caching: false, showSpinner: false})
      .subscribe((result: TerminalTrackerStats[]) => this.trackerStats = result);
  }

  private loadPendingDeclarations() {
    sendQuery("com.portbase.common.api.admin.GetPendingDeclarations", {}, {caching: false, showSpinner: true})
      .subscribe((result: PendingDeclaration[]) => this.pendingDeclarations = result);
  }

  getTerminalQueueSize(terminal: string) {
    let threshold = moment().subtract(5, "minutes");
    if (this.appContext.roadMessages) {
      return this.pendingDeclarations.filter(entry => entry.terminal == terminal && entry.modality == 'road' && entry.type === 'COPINO' && moment(entry.timestamp).isBefore(threshold)).length;
    }
    return this.pendingDeclarations.filter(entry => entry.terminal == terminal && entry.modality != 'road' && entry.type === 'COPINO' && moment(entry.timestamp).isBefore(threshold)).length;
  }

  getMessage = (index) => {
    return sendQuery('com.portbase.common.api.admin.GetMessagesFromIndex',
      {
        messageType: "COMMAND",
        minIndex: index,
        maxSize: 1
      }).subscribe(result => {
      return result[0]["metadata"]["$traceId"]
    });
  };

  resubmit = () => {
    sendCommand('com.portbase.common.api.admin.ResubmitDeclarations',
      {
        commandIndexes: this.getPendingDeclarations().filter(e => e['selected']).map(e => e.messageIndex)
      },
      () => {
        this.loadPendingDeclarations();
        AppContext.registerSuccess("Declarations were resubmitted successfully")
      })
  };

  remove = () => {
    sendCommand('com.portbase.common.api.admin.RemoveDeclarations',
      {
        declarations: this.getPendingDeclarations().filter(e => e['selected'])
      },
      () => {
        setTimeout(() => {
          this.loadPendingDeclarations();
          AppContext.registerSuccess("Declarations were removed successfully")
          //query is steeds net te vroeg, en dit is toch maar een admin scherm
        }, 1000);
      })
  };

  selectAll = () => {
    this.pendingDeclarations.forEach(e => e['selected'] = true);
  };

  deselectAll = () => {
    this.pendingDeclarations.forEach(e => e['selected'] = false);
  };

  getPendingDeclarations = (): PendingDeclaration[] => {
    let threshold = moment().subtract(this.filter.minimumAge, this.filter.minimumAgeUnit);
    return this.pendingDeclarations.filter(m => {
      if (this.appContext.roadMessages && m.modality !== "road") {
        return false;
      } else {
        if (!this.appContext.roadMessages && m.modality === "road") {
          return false;
        }
      }
      if ((this.filter.declarationType != <any>'All types' && m.type !== this.filter.declarationType)
        || (this.filter.terminal != <any>'All terminals' && m.terminal !== this.filter.terminal)) {
        return false;
      }
      return moment(m.timestamp).isBefore(threshold);
    });
  };

  allDeclarationsChecked = () => this.pendingDeclarations.every(e => e['selected']);

  noEntriesChecked = () => !this.pendingDeclarations.some(e => e['selected']);

  getLink = (entry: PendingDeclaration) => {
    if (!!entry.voyageId) {
      if (!entry.handlingId) {
        return '/rotation/' + entry.voyageId + '?visitId=' + entry.visitId;
      } else {
        return '/rotation/' + entry.voyageId + '?visitId=' + entry.visitId + '&handlingId=' + entry.handlingId;
      }
    }
    return '/handlings?filterTerm=' + entry.handlingId;
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
  }

  formatDate(lastUpdate: string) {
    return moment(lastUpdate).fromNow(false);
  }

}

interface MessageFilter {
  declarationType: DeclarationType;
  terminal: string;
  minimumAge: number;
  minimumAgeUnit: 'minutes' | 'hours' | 'days';
}
