import {Component, Input, OnInit} from '@angular/core';
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {
  Handling,
  HandlingData,
  HandlingDeclaration,
  HandlingResult,
  HandlingStatusLogItem,
  LogItem,
  RoadVisit,
  RoadVoyage,
  VisitPlanningLogItem
} from "@portbase/hinterland-service-typescriptmodels";
import {getHandlingLog, getVoyageLog} from "../../../../common/query-utils";
import moment from "moment/moment";
import {AppContext} from "../../../../app-context";
import {EventHandler, EventType} from "../../../../common/event-gateway";
import _ from 'lodash';
import {Visit} from "@portbase/hinterland-service-typescriptmodels/hinterland";

@Component({
  selector: 'app-view-handling-history-offcanvas',
  templateUrl: './app-view-handling-history-offcanvas.html',
  styleUrls: ['./app-view-handling-history-offcanvas.scss']
})
export class ViewHandlingHistoryOffCanvasComponent extends HinterlandBaseComponent implements OnInit {

  @Input() voyage: RoadVoyage;
  @Input() visit: RoadVisit;
  @Input() handling: Handling;

  historyItems: HistoryItem[] = [];

  ngOnInit(): void {
    this.fetchLogItems();
  }

  onVoyageUpdated: EventHandler<EventType.VoyageUpdated> = (voyageId: string) => {
    if (this.voyage && this.voyage.voyageId === voyageId) {
      this.fetchLogItemsDebounced();
    }
  }

  onVisitUpdated: EventHandler<EventType.VisitUpdated> = (visitId: string) => {
    if (this.visit && this.visit.visitId === visitId) {
      this.fetchLogItemsDebounced();
    }
  }

  onHandlingUpdated: EventHandler<EventType.HandlingUpdated> = (handlingId: string) => {
    if (this.handling.handlingId === handlingId) {
      this.fetchLogItemsDebounced();
    }
  }

  fetchLogItemsDebounced: Function = _.debounce(() => {
    this.fetchLogItems();
  }, 500);

  fetchLogItems(): void {
    this.historyItems = [];
    if (this.voyage) {
      getVoyageLog(this.voyage.voyageId).subscribe((logItems: LogItem[]) => {
        logItems = logItems
          .filter(logItem => (logItem.visitId === undefined || this.visit === undefined || logItem.visitId === this.visit.visitId))
          .filter(logItem => (logItem.handlingId === undefined || this.handling === undefined || logItem.handlingId === this.handling.handlingId));
        this.setLogItems(logItems);
      });
    } else if (this.handling) {
      getHandlingLog(this.handling.handlingId).subscribe((logItems: LogItem[]) => {
        this.setLogItems(logItems);
      });
    }
  }

  setLogItems(logItems: LogItem[]) {
    logItems.sort(function (logItemA, logItemB) { //Chronologically ascending
      const timestampA: moment.Moment = moment(logItemA.timestamp);
      const timestampB: moment.Moment = moment(logItemB.timestamp);
      if (timestampA.isSame(timestampB)) {
        return 0;
      } else if (timestampA.isBefore(timestampB)) {
        return -1;
      } else {
        return 1;
      }
    });

    this.initializeHistoryItems(logItems);
  }

  initializeHistoryItems(logItems: LogItem[]) {
    let previousVisitId = '';
    let previousTar = '';
    let previousEta = '';
    for (const logItem of logItems) {
      const timestamp: moment.Moment = moment(logItem.timestamp);
      const formattedDate = timestamp.format('ddd DD-MM-YY');
      const formattedTime = timestamp.format('HH:mm');
      let formattedEta = undefined;
      let user = logItem.user;
      let visitId = undefined;
      let tar = undefined;
      let queued = false;
      let handlingForStatusComponent = undefined;
      let visitForStatusComponent = undefined;

      if (!AppContext.isAdmin()) {
        const userSplitted = user.split('@');
        const organisation = userSplitted[userSplitted.length - 1];
        if (organisation !== AppContext.userProfile.organisationShortName) {
          user = organisation;
        }
      }

      if (logItem.visitPlanningLogItem) {
        visitForStatusComponent = logItem.visitPlanningLogItem;
        if (logItem.visitId && logItem.visitId !== previousVisitId) {
          visitId = logItem.visitId;
          previousVisitId = logItem.visitId;
        }
        if (logItem.visitPlanningLogItem.tar && logItem.visitPlanningLogItem.tar !== previousTar) {
          tar = logItem.visitPlanningLogItem.tar;
          previousTar = logItem.visitPlanningLogItem.tar;
        }
        if (logItem.visitPlanningLogItem.eta && logItem.visitPlanningLogItem.eta !== previousEta) {
          const eta: moment.Moment = moment(logItem.visitPlanningLogItem.eta);
          formattedEta = eta.format('DD-MM-YYYY HH:mm');
          previousEta = logItem.visitPlanningLogItem.eta;
        }
      }

      let href;
      if (logItem.handlingStatusLogItem) {
        queued = logItem.handlingStatusLogItem.messagesInWaitingRoom;
        handlingForStatusComponent = this.createDummyHandlingForHandlingStatus(logItem.handlingStatusLogItem, logItem.handlingData, logItem.visitPlanningLogItem);
        href = !!logItem.voyageId ? `/rotation/${logItem.voyageId}?visitId=${logItem.visitId}&handlingId=${logItem.handlingId}&eventId=${logItem.eventId}` : undefined
      } else if (logItem.visitPlanningLogItem) {
        handlingForStatusComponent = this.createDummyHandlingForVisitStatus(logItem.visitPlanningLogItem, logItem.handlingData);
        href = !!logItem.voyageId ? `/rotation/${logItem.voyageId}?visitId=${logItem.visitId}&eventId=${logItem.eventId}` : undefined
      } else {
        href = !!logItem.voyageId ? `/rotation/${this.voyage.voyageId}?eventId=${logItem.eventId}` : undefined;
      }

      this.historyItems.push(<HistoryItem>{
        eventId: logItem.eventId,
        visitId: visitId,
        tar: tar,
        formattedDate: formattedDate,
        formattedTime: formattedTime,
        formattedEta: formattedEta,
        action: this.stripAction(logItem.action),
        user: user,
        queued: queued,
        handlingForStatusComponent: handlingForStatusComponent,
        visitForStatusComponent: visitForStatusComponent,
        href: href,
        timestamp: timestamp
      });
    }

    this.historyItems.sort(function (historyItemA, historyItemB) { //Chronologically descending
      if (historyItemA.timestamp.isSame(historyItemB.timestamp) && !!historyItemA.href && !!historyItemB.href) {
        return historyItemB.href.length - historyItemA.href.length; //Sometimes CreateVoyage and DeclareVisit have identical timestamps --> This is a trick to make sure the sorting is 'right'
      } else if (historyItemA.timestamp.isBefore(historyItemB.timestamp)) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  stripAction(action: string) {
    const bracketOpenIndex = action.indexOf('(');
    if (bracketOpenIndex === -1) {
      return action;
    }

    return action.substring(0, bracketOpenIndex - 1);
  }

  createDummyHandlingForHandlingStatus(handlingStatusLogItem: HandlingStatusLogItem, handlingData: HandlingData, visitPlanningLogItem: VisitPlanningLogItem): Handling {
    let handlingDeclaration: HandlingDeclaration = handlingStatusLogItem.handlingDeclaration;
    if (!handlingDeclaration) {
      handlingDeclaration = {
        status: handlingStatusLogItem.declarationStatus,
        acceptStatuses: [],
        rejectReasons: [],
        allStatussesOK: true,
        preNotification: true
      }
    }
    handlingDeclaration.timestamp = moment().toISOString()

    const handling: Handling = <Handling>{
      handlingData: handlingData,
      declarationStatus: handlingStatusLogItem.declarationStatus,
      handlingDeclaration: handlingDeclaration,
      cancelled: handlingStatusLogItem.cancelled,
      rejected: handlingStatusLogItem.rejected
    };

    if (visitPlanningLogItem && visitPlanningLogItem.statusType === "COMPLETED") {
      handling['handlingResult'] = <HandlingResult>{
        modality: "road"
      };
    } else {
      handling['handlingResult'] = undefined;
    }

    return handling;
  }

  createDummyHandlingForVisitStatus(visitPlanningLogItem: VisitPlanningLogItem, handlingData: HandlingData): Handling {
    if (!handlingData) {
      handlingData = <HandlingData>{
        modality: "road",
        type: "loading"
      }
    }

    return <Handling>{
      handlingId: undefined,
      handlingData: handlingData,
      declarationStatus: visitPlanningLogItem.visitDeclarationStatus,
      handlingDeclaration: <HandlingDeclaration>{
        status: visitPlanningLogItem.visitDeclarationStatus,
        acceptStatuses: [],
        rejectReasons: [],
        allStatussesOK: true,
        preNotification: true,
        timestamp: moment().toISOString() //Trick to circumvent 'stuck' status in handling-status-panel
      },
      cancelled: visitPlanningLogItem.cancelled
    }
  }

  copyToClipboard(id: string) {
    navigator.clipboard.writeText(id);
  }
}

interface HistoryItem {
  formattedDate: string;
  formattedTime: string;
  action: string;
  visitId: string
  tar: string,
  user: string;
  queued: boolean;
  formattedEta: string;
  handlingForStatusComponent: Handling;
  visitForStatusComponent: Visit;
  href: string
  timestamp: moment.Moment;
}
