[
  {
    "code": "1",
    "name": "1e Eemhaven"
  },
  {
    "code": "3",
    "name": "1e Petroleumhaven"
  },
  {
    "code": "5",
    "name": "2e Merwedehaven Dordrecht"
  },
  {
    "code": "6",
    "name": "2e Petroleumhaven"
  },
  {
    "code": "7",
    "name": "3e Merwedehaven Dordrecht"
  },
  {
    "code": "8",
    "name": "3e Petroleumhaven"
  },
  {
    "code": "9",
    "name": "4e Petroleumhaven"
  },
  {
    "code": "10",
    "name": "5e Petroleumhaven"
  },
  {
    "code": "11",
    "name": "6e Petroleumhaven"
  },
  {
    "code": "12",
    "name": "7e Petroleumhaven"
  },
  {
    "code": "13",
    "name": "8e Petroleumhaven"
  },
  {
    "code": "16",
    "name": "Alblashaven"
  },
  {
    "code": "17",
    "name": "Amazonehaven"
  },
  {
    "code": "19",
    "name": "Barendrechtse Haventje"
  },
  {
    "code": "21",
    "name": "Beerkanaal"
  },
  {
    "code": "24",
    "name": "Beneluxhaven"
  },
  {
    "code": "9926",
    "name": "Binnen Kalkhaven Dordrecht"
  },
  {
    "code": "30",
    "name": "Bomhaven Dordrecht"
  },
  {
    "code": "9902",
    "name": "Boompjeskade"
  },
  {
    "code": "32",
    "name": "Botlek"
  },
  {
    "code": "33",
    "name": "Brittanniehaven"
  },
  {
    "code": "9927",
    "name": "Buiten Kalkhaven Dordrecht"
  },
  {
    "code": "9928",
    "name": "Buiten Walevest Dordrecht"
  },
  {
    "code": "35",
    "name": "Buitenhaven Vlaardingen"
  },
  {
    "code": "38",
    "name": "Calandkanaal"
  },
  {
    "code": "39",
    "name": "Chemiehaven"
  },
  {
    "code": "40",
    "name": "Coolhaven"
  },
  {
    "code": "49",
    "name": "Dintelhaven"
  },
  {
    "code": "50",
    "name": "Donauhaven"
  },
  {
    "code": "9933",
    "name": "Dordrecht"
  },
  {
    "code": "54",
    "name": "Eemhaven"
  },
  {
    "code": "55",
    "name": "Elbehaven"
  },
  {
    "code": "57",
    "name": "Europahaven"
  },
  {
    "code": "9903",
    "name": "Europoort"
  },
  {
    "code": "9937",
    "name": "Euryzakade Zwijndrecht"
  },
  {
    "code": "61",
    "name": "Geulhaven"
  },
  {
    "code": "64",
    "name": "Hartelhaven"
  },
  {
    "code": "65",
    "name": "Hartelkanaal"
  },
  {
    "code": "9929",
    "name": "Het Groothoofd Dordrecht"
  },
  {
    "code": "66",
    "name": "Heysehaven"
  },
  {
    "code": "70",
    "name": "Hudsonhaven"
  },
  {
    "code": "71",
    "name": "IJselhaven"
  },
  {
    "code": "74",
    "name": "Julianahaven Dordrecht"
  },
  {
    "code": "76",
    "name": "Keilehaven"
  },
  {
    "code": "192",
    "name": "Kolenhaven Dordrecht"
  },
  {
    "code": "79",
    "name": "Koningin Wilhelminahaven Vlaardingen"
  },
  {
    "code": "81",
    "name": "Kooihaven Papendrecht"
  },
  {
    "code": "82",
    "name": "Kortenoordsehaven"
  },
  {
    "code": "84",
    "name": "Krabbegeul Dordrecht"
  },
  {
    "code": "215",
    "name": "Kuipershaven Dordrecht"
  },
  {
    "code": "86",
    "name": "Lekhaven"
  },
  {
    "code": "87",
    "name": "Leuvehaven Dordrecht"
  },
  {
    "code": "91",
    "name": "Londenhaven"
  },
  {
    "code": "9906",
    "name": "Maasboulevard"
  },
  {
    "code": "9938",
    "name": "Maasboulevard Zwijndrecht"
  },
  {
    "code": "93",
    "name": "Maashaven"
  },
  {
    "code": "9907",
    "name": "Maaskade"
  },
  {
    "code": "95",
    "name": "Madroelhaven"
  },
  {
    "code": "96",
    "name": "Mallegat Dordrecht"
  },
  {
    "code": "97",
    "name": "Merwehaven"
  },
  {
    "code": "9931",
    "name": "Merwekade Dordrecht"
  },
  {
    "code": "101",
    "name": "Mississippihaven"
  },
  {
    "code": "9935",
    "name": "Molenpolder Papendrecht"
  },
  {
    "code": "103",
    "name": "Neckarhaven"
  },
  {
    "code": "9909",
    "name": "Nesserdijk"
  },
  {
    "code": "109",
    "name": "Nieuwe Maas"
  },
  {
    "code": "207",
    "name": "Nijlhaven"
  },
  {
    "code": "125",
    "name": "Oude Maas"
  },
  {
    "code": "9934",
    "name": "Papendrecht"
  },
  {
    "code": "127",
    "name": "Parkhaven"
  },
  {
    "code": "9912",
    "name": "Pernis"
  },
  {
    "code": "130",
    "name": "Pistoolhaven"
  },
  {
    "code": "132",
    "name": "Prins Johan Frisohaven"
  },
  {
    "code": "133",
    "name": "Prins Willem-Alexanderhaven"
  },
  {
    "code": "134",
    "name": "Prinses Alexiahaven"
  },
  {
    "code": "135",
    "name": "Prinses Amaliahaven"
  },
  {
    "code": "136",
    "name": "Prinses Arianehaven"
  },
  {
    "code": "137",
    "name": "Prinses Beatrixhaven"
  },
  {
    "code": "138",
    "name": "Prinses Margriethaven"
  },
  {
    "code": "140",
    "name": "Riedijkshaven Dordrecht"
  },
  {
    "code": "9914",
    "name": "Rotterdam"
  },
  {
    "code": "231",
    "name": "Rozenburgse sluis"
  },
  {
    "code": "9916",
    "name": "Schaardijk"
  },
  {
    "code": "151",
    "name": "Scheurhaven"
  },
  {
    "code": "9917",
    "name": "Schiedam"
  },
  {
    "code": "9918",
    "name": "Schiedam Nieuwe Haven"
  },
  {
    "code": "156",
    "name": "Seinehaven"
  },
  {
    "code": "157",
    "name": "Sint-Janshaven"
  },
  {
    "code": "159",
    "name": "Sint-Laurenshaven"
  },
  {
    "code": "160",
    "name": "Sleepboothaven"
  },
  {
    "code": "167",
    "name": "Tennesseehaven"
  },
  {
    "code": "168",
    "name": "Torontohaven"
  },
  {
    "code": "9919",
    "name": "Vlaardingen"
  },
  {
    "code": "172",
    "name": "Voorhaven Schiedam"
  },
  {
    "code": "9920",
    "name": "Vopak Vlaardingen"
  },
  {
    "code": "174",
    "name": "Vulcaanhaven"
  },
  {
    "code": "233",
    "name": "Waalhaven"
  },
  {
    "code": "177",
    "name": "Wantij Dordrecht"
  },
  {
    "code": "179",
    "name": "Welplaathaven"
  },
  {
    "code": "180",
    "name": "Werkhaven"
  },
  {
    "code": "181",
    "name": "Wezerhaven"
  },
  {
    "code": "185",
    "name": "Wilhelminahaven Dordrecht"
  },
  {
    "code": "186",
    "name": "Wilhelminahaven Schiedam"
  },
  {
    "code": "9923",
    "name": "Wilhelminakade"
  },
  {
    "code": "9924",
    "name": "Willemskade"
  },
  {
    "code": "188",
    "name": "Wolwevershaven Dordrecht"
  },
  {
    "code": "9901",
    "name": "Yangtzehaven"
  },
  {
    "code": "189",
    "name": "Yangtzekanaal"
  },
  {
    "code": "9940",
    "name": "Yukonhaven"
  },
  {
    "code": "9936",
    "name": "Zwijndrecht"
  }
]