import {Component, OnInit} from '@angular/core';
import {AppContext} from "../app-context";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  appContext = AppContext;
  kibanaUrl: String;

  ngOnInit(): void {
    this.kibanaUrl = "https://kibana.hcn." + this.appContext.environment + ".portbase.com/_dashboards/app/data-explorer/discover#"
  }

}
