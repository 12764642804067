<div class="bg-portbase-lighter-grey" style="min-height: 100vh">
  <div class="w-100 h-100 d-flex justify-content-center align-content-around">
    <div style="width: 500px; max-width: 90vw">
      <div class="row mt-4 mb-3">
        <div class="col-auto ps-4">
          <img src="assets/img/port-of-rotterdam-logo.svg" style="height: 35px" alt="Port of Rotterdam logo" class="notranslate">
        </div>
        <div class="col-auto ms-auto pe-4">
          <img src="assets/img/portbase-logo.svg" style="height: 30px" alt="Port of Rotterdam logo" class="notranslate">
        </div>
      </div>

      <app-form (submit)="sendLoginLink()">
        <div class="card">
          <div class="card-body px-4">
            <div class="row">
              <app-error [errors]="errors"></app-error>
              <div class="col-12 my-3">
                <span style="font-size: 1.25rem; line-height: 1.5rem; font-weight: 500">Reset password</span>
              </div>

              <div class="col-12">
                Enter your email address, and we will send you a link to get back into your account.
              </div>

              <div class="col-12">
                <hr class="my-3 mx-n4">
              </div>

              <div class="col-12">
                <app-form-field label="Email address">
                  <input [(ngModel)]="command.userId" placeholder="Enter email address" required
                         class="form-control">
                </app-form-field>
              </div>

            </div>
          </div>

          <div class="card-footer bg-white py-4">
            <div class="row narrow-gutters justify-content-between">
              <div class="col-auto">
                <button type="button" class="btn btn-secondary" (click)="location.back()">
                  Cancel
                </button>
              </div>

              <div class="col-auto">
                <button type="submit" class="btn btn-primary">
                  Send login link
                </button>
              </div>
            </div>
          </div>

        </div>
      </app-form>
    </div>
  </div>
</div>
