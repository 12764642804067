<div class="mx-auto mb-5 mt-4" style="max-width: 1150px" *ngIf="appContext.isAdmin()">
  <div class="card">
    <div class="card-header bg-secondary text-light">
      <span class="fas fa-sliders-h px-4"></span>Application
    </div>
    <div class="card-body">
      <p class="card-text">Generic settings for the whole applicaton (both UI and backend API!)</p>
      <app-tooltip placement="bottom bottom-right" backgroundcolor="#f5c542" [wide]="true" [keepOpen]="false"><div class="tooltipContent">Generic application settings, like enabling/disabling integral planning.</div><a class="btn btn-outline-secondary btn-rounded" href="/admin/application-settings">Application settings</a></app-tooltip>
    </div>
  </div>
</div>
