import {Component, Input} from '@angular/core';
import {BinnenhavengeldShip, BinnenhavengeldShipType} from '@portbase/hinterland-service-typescriptmodels/hinterland';

@Component({
  selector: 'app-bhg-ship',
  templateUrl: './bhg-ship.component.html',
  styleUrls: ['./bhg-ship.component.scss']
})
export class BhgShipComponent {
  @Input() ship: BinnenhavengeldShip;

  formatShipType(type: BinnenhavengeldShipType) : string {
    if (!type) {
      return "";
    }
    switch (type) {
      case 'freight': return "Freight";
      case 'passengerOrTug': return "Passenger or tug";
      case 'other': return "Other vessels"
    }
  }
}
