import {Component} from '@angular/core';

@Component({
  selector: 'app-refdata-export',
  templateUrl: './refdata-export.component.html',
  styleUrls: ['./refdata-export.component.css']
})
export class RefdataExportComponent {

  type: string;

}
