import {Component, Input} from '@angular/core';
import {AppContext} from '../../../app-context';
import {BinnenhavengeldAccountDetails} from "@portbase/hinterland-service-typescriptmodels";

@Component({
  selector: 'app-bhg-account-details',
  templateUrl: './bhg-account-details.component.html',
  styleUrls: ['./bhg-account-details.component.css']
})
export class BhgAccountDetailsComponent {
  @Input() accountDetails : BinnenhavengeldAccountDetails;
  newAccount = !AppContext.binnenhavengeldUserProfile;
}
