import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {AppContext} from "../../app-context";

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompareComponent implements OnInit, OnChanges {

  appContext = AppContext;
  different: boolean;

  @Input() disabled = false;
  @Input() value;
  @Input() otherValue;
  @Input() mode: CompareComponentMode;
  @Input() formatter = inputValue => (inputValue == null || inputValue === '') ? 'none' : inputValue;
  @Input() label: String = "Previous";

  ngOnInit(): void {
    this.different = this.isDifferent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.different = this.isDifferent();
  }

  tooltipContent = () => {
    const label = this.mode === 'TERMINAL' ? 'Terminal' : this.mode === 'INTEGRAL_PLANNING' ? 'Nextlogic' : this.label;
    return label + ": " + this.formatter(this.otherValue)
  }

  isDifferent = () => {
    return ((this.mode === 'PREVIOUS') && !this.disabled && this.value !== this.otherValue)
      || (((this.mode === 'TERMINAL' || this.mode === 'INTEGRAL_PLANNING')
        && !this.disabled && this.otherValue != null
        && !(this.value == null && this.otherValue == 0)
        && this.value !== this.otherValue));
  }
}

export type CompareComponentMode = 'PREVIOUS' | 'TERMINAL' | 'INTEGRAL_PLANNING';
