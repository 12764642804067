import {Pipe, PipeTransform, Injectable} from '@angular/core';
import {LogItem} from "@portbase/hinterland-service-typescriptmodels";

@Pipe({
  name: "historyFilter",
  pure: false
})

@Injectable()
export class HistoryFilter implements PipeTransform {
  transform(list: any, showHandlings: any, visitId: any, handlingFilterTerm: any): any {
    let tempList: LogItem[] = [];

    if (showHandlings) {
      //show handlings
      if (!visitId && (!handlingFilterTerm || 3 >= handlingFilterTerm.length)) {
        return list;
      }
      //filter visit or equipment
      for (let i = 0; i < list.length; i++) {
        let handlingFilter = list[i].handlingId;
        if (handlingFilterTerm) {
          handlingFilter = list[i].handlingId && list[i].handlingData && ((list[i].handlingData.equipmentNumber && list[i].handlingData.equipmentNumber.startsWith(handlingFilterTerm)) || (list[i].handlingData.bookingNumber && list[i].handlingData.bookingNumber.startsWith(handlingFilterTerm)));
        }

        if (handlingFilter && (!visitId || visitId && list[i].visitId === visitId)) {
          tempList.push(list[i])
        } else {
          if (!list[i].handlingId && (!list[i].visitId || !visitId || (visitId && list[i].visitId === visitId))) {
            tempList.push(list[i]);
          }
        }
      }

    } else {
      //hide handlings
      for (let i = 0; i < list.length; i++) {
        if (!list[i].handlingId) {
          tempList.push(list[i]);
        }
      }
      let tempList2: LogItem[] = [];
      if (visitId) {
        for (let i = 0; i < tempList.length; i++) {
          if (tempList[i].visitId === visitId || !tempList[i].visitId) {
            tempList2.push(tempList[i]);
          }
        }
        return tempList2;
      } else {
        return tempList;
      }
    }
    return tempList;
  }
}
