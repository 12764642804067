<div class="dropdown always-enabled">
  <button class="btn" [ngClass]="hasSelection() ? 'btn-info' : 'btn-outline-info'"
          type="button" id="toggleFilterDropdownButton" data-bs-toggle="dropdown" data-bs-reference="parent" aria-haspopup="true" aria-expanded="false">
    <span class="fa fa-filter m-0"></span>
  </button>

  <div class="dropdown-menu py-1" aria-labelledby="toggleFilterDropdownButton" (click)="$event.stopPropagation()">
    <div class="container">
      <div *ngFor="let option of options" class="row flex-nowrap">
        <div class="col px-1 icon" (click)="toggle(option)">
          <span *ngIf="option.icon" class="icon-inner fa {{option.icon}}"></span>
        </div>
        <div class="col px-2 label unselectable" (click)="toggle(option)">{{option.value}}</div>
        <div class="col px-1 btn-group toggle-buttons">
          <button type="button" class="btn p-0 toggle-button" (click)="toggleOn(option)"
                  [ngClass]="isOn(option) ? 'btn-info' : 'btn-outline-info'">
            <span class="fa fa-plus"></span>
          </button>
          <button type="button" class="btn p-0 toggle-button" (click)="toggleOff(option)"
                  [ngClass]="isOff(option) ? 'btn-info' : 'btn-outline-info'">
            <span class="fa fa-minus"></span>
          </button>
        </div>
      </div>

      <div class="row flex-nowrap">
        <div *ngIf="appContext.isAdmin()" class="col px-1 icon"><span class="icon-inner fa fa-info text-warning"></span></div>
        <app-tooltip *ngIf="appContext.isAdmin()" wide="false">
          <div class="col px-2 label text-warning">Missing Handlings</div>
          <div class="tooltipContent">
            <div class="text-warning"><b>Not all handlings may be shown for admins</b></div>
            <div>The severity depends on the amount of handlings loaded.</div>
            <div>A smaller date range will reduce this issue.</div>
            <div>This issue will be resolved soon.</div>
          </div>
        </app-tooltip>
        <button *ngIf="hasSelection()" class="p-0 me-1 my-1 ms-auto btn btn-secondary border-0 bg-transparent text-secondary clear-button" (click)="resetAll()">
          <span><span class="fa fa-ban"></span> Clear All</span>
        </button>
      </div>
    </div>
  </div>
</div>
