<div class="text-portbase-dark-blue">

  <ng-container *ngFor="let movement of movements; let firstMovement = first">
    <app-stop *ngIf="movement.itinerary.length > 0 && movement.itinerary[0] as s" [stop]="s" type="first"></app-stop>

    <div class="movement-row row align-items-center">
      <div class="col-auto itinerary">&nbsp;</div>
      <div class="col movement">
        <app-fancy-collapse [collapse]="movementDetails">
          <div class="p-2">
            <div class="row align-items-center">
              <div *ngIf="$any(movement.vessel || {modality : cargoShipment.data.inlandModality}) as vessel" class="col-auto">
                <app-vessel [vessel]="vessel"></app-vessel>
              </div>
              <div *ngIf="movement.transportReference || movement.portbaseVoyageId" class="col-auto small">
                <div *ngIf="movement.transportReference">
                  <span class="fa fa-fw fa-dharmachakra"></span><span class="ms-2">{{movement.transportReference}}</span>
                </div>
                <div *ngIf="movement.portbaseVoyageId">
                  <span class="fa fa-fw fa-route"></span><span class="ms-2">{{movement.vessel?.modality === 'sea' ? movement.portbaseVoyageId : (movement.itinerary.length > 0 && movement.itinerary[0].portbaseId || movement.portbaseVoyageId)}}</span>
                </div>
              </div>
              <div *ngIf="movement.operator as value" class="col-auto small">
                <span class="fa fa-users"></span><span class="ms-2">{{value.name}}</span>
              </div>
              <div *ngIf="movement.releaseExpiration" class="col-auto small" title="Release expiration date">
                <span class="fa-regular fa-calendar-circle-exclamation"></span>
                <span class="ms-2">
                  <app-date-time customDateClass="text-portbase-dark-blue" [ngModel]="movement.releaseExpiration" readonly="true" [showClock]="false"></app-date-time>
                </span>
              </div>
              <div class="col-auto ms-auto">
                <app-cargo-summary [movement]="movement"></app-cargo-summary>
              </div>
            </div>
          </div>

          <ng-template #movementDetails>
            <div class="collapse">
              <div class="my-2">
                <app-movement [movement]="movement" [shipment]="cargoShipment"></app-movement>
              </div>

              <div *ngIf="movement.itinerary.length > 2" class="mb-3">
                <ng-container *ngFor="let stop of movement.itinerary; let first = first; let last = last;">
                  <div *ngIf="!first && !last" class="position-relative" style="left: -6.1em">
                    <app-stop [stop]="stop" type="inner"></app-stop>
                  </div>
                </ng-container>
              </div>

            </div>
          </ng-template>

        </app-fancy-collapse>
      </div>
      <div class="col-1 text-center to-voyage" *ngIf="getRotationLink(movement) as link">
        <h4 title="To Rotation" class="m-0"><a [href]="link" target="_blank"
                                               class="fa fa-external-link-alt text-secondary"></a></h4>
      </div>
    </div>

    <app-stop *ngIf="movement.itinerary.length === 1 && firstMovement && movement.itinerary[0] as s" [stop]="s" type="last"></app-stop>
    <app-stop *ngIf="movement.itinerary.length > 1" [stop]="movement.itinerary[movement.itinerary.length - 1]" type="last"></app-stop>
  </ng-container>

</div>
