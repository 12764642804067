import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  IntegralPlanningVisitStatus,
  Modality,
  RailVisitData,
  VisitData,
  VisitStatus
} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {Terminal} from "@portbase/hinterland-service-typescriptmodels";
import moment from "moment";
import {HinterlandUtils} from "../../../hinterland/hinterland-utils";

@Component({
  selector: 'app-visit-date-range',
  templateUrl: './visit-date-range.component.html',
  styleUrls: ['./visit-date-range.component.css']
})
export class VisitDateRangeComponent implements OnInit, OnChanges {

  @Input() integralPlanningRequested: boolean;
  @Input() visitData: VisitData;
  @Input() integralPlanningStatus: IntegralPlanningVisitStatus;
  @Input() plannedVisitData: VisitStatus;
  @Input() requestedVisitData: VisitData;
  @Input() terminal: Terminal;
  @Input() modality: Modality;
  @Input() status;
  @Input() firstDepartureDate;

  integralPlanningMuted: boolean;
  terminalPlanningMuted: boolean;
  requestedPlanningMuted: boolean;
  terminalPlanningHidden: boolean;
  requestedPlanningHidden: boolean;
  terminalEta;
  terminalEtd;
  timeslot;
  showDeviatingTimeSlotWarning: boolean;

  isModalityRoad: boolean;
  isModalityBarge: boolean;
  isModalityRail: boolean;

  ngOnInit(): void {
    this.isModalityRoad = this.modality === 'road';
    this.isModalityBarge = this.modality === 'barge';
    this.isModalityRail = this.modality === 'rail';
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.visitData || changes.integralPlanningStatus || changes.plannedVisitData || changes.requestedVisitData || changes.status) {
      this.init();
    }
  }

  private init() {
    this.timeslot = this.isModalityRail && (this.visitData as RailVisitData).timetableEntry;
    this.terminalEta = this.plannedVisitData && !this.plannedVisitData['unsaved'] ? this.plannedVisitData['eta'] : this.timeslot ? HinterlandUtils.nextMoment(this.firstDepartureDate, this.timeslot.window.start) : undefined;
    this.terminalEtd = this.plannedVisitData && !this.plannedVisitData['unsaved'] ? this.plannedVisitData['etd'] : this.timeslot ? HinterlandUtils.nextMoment(this.firstDepartureDate, this.timeslot.window.end) : undefined;
    this.terminalPlanningHidden = (!this.plannedVisitData || !this.plannedVisitData.eta || this.plannedVisitData['unsaved']) && !this.timeslot;
    this.requestedPlanningHidden = this.isModalityRail && this.terminalPlanningMatchesRequestedVisitData();
    this.integralPlanningMuted = !this.integralPlanningMatchesVisitData();
    this.terminalPlanningMuted = !this.terminalPlanningMatchesVisitData() && !this.requestedPlanningHidden;
    this.requestedPlanningMuted = !this.requestedPlanningMatchesVisitData();
    this.showDeviatingTimeSlotWarning = this.timeslot && !this.requestedPlanningHidden && this.status == 'DECLARED';
  }

  integralPlanningMatchesVisitData() {
    let sameEta = !!this.integralPlanningStatus && this.momentOf(this.visitData['eta']) === this.momentOf(this.integralPlanningStatus['pta']);
    let sameEtd = !!this.integralPlanningStatus && !!this.visitData['etd'] ? this.momentOf(this.visitData['etd']) === this.momentOf(this.integralPlanningStatus['ptd']) : true;
    return sameEta && sameEtd;
  }

  terminalPlanningMatchesVisitData() {
    let sameEta = !!this.plannedVisitData && this.momentOf(this.visitData['eta']) === this.momentOf(this.terminalEta);
    let sameEtd = !!this.plannedVisitData && this.visitData['etd'] && this.momentOf(this.visitData['etd']) === this.momentOf(this.terminalEtd);
    return (sameEta && sameEtd) || this.status === 'ACCEPTED';
  }

  terminalPlanningMatchesRequestedVisitData() {
    let sameEta = !!this.requestedVisitData && this.momentOf(this.requestedVisitData['eta']) === this.momentOf(this.terminalEta);
    let sameEtd = !!this.requestedVisitData && this.requestedVisitData['etd'] && this.momentOf(this.requestedVisitData['etd']) === this.momentOf(this.terminalEtd);
    return sameEta && sameEtd;
  }

  requestedPlanningMatchesVisitData() {
    if (!this.requestedVisitData) return false;
    let sameEta = this.momentOf(this.visitData['eta']) === this.momentOf(this.requestedVisitData['eta']);
    let sameEtd = !!this.visitData['etd'] ? this.momentOf(this.visitData['etd']) === this.momentOf(this.requestedVisitData['etd']) : true;
    let declared = this.status === 'DECLARED' && !this.integralPlanningStatus;
    return (sameEta && sameEtd) || declared;
  }

  momentOf(dateTime: string) {
    return moment(dateTime).format("YYYY-MM-DDTHH:mm");
  }

}
