<div *ngIf="!hidden" class="overview-navbar d-flex align-items-center border-bottom">
  <div class="custom-check-inline h-100 me-0 pe-2 select-all-aligned hide-md-screen">
    <div class="form-check">
      <input *ngIf="!actionMode" type="checkbox" class="form-check-input" id="select-all-handlings-checkbox"
             [indeterminate]="this.selectAllValue === 'some'"
             [checked]="this.selectAllValue === 'all'" (click)="toggleSelectAllHandlings()">
    </div>
  </div>

  <app-overview-filter [(ngModel)]="filterTerm"
                   [searchFunction]="onFilterTermChanged"
                   (resultsFound)="{}"
                   placeholder="Search"
                   class="input-group-left"></app-overview-filter>

  <app-new-select [(ngModel)]="selectedHandlingType" nullLabel="Pick up/Drop-off" [options]="['loading', 'discharge']"
                  [formatter]="handlingTypeFormatter" (ngModelChange)="onSelectedHandlingTypeChanged($event)"
                  width="md" customSelectClass="input-group-middle" class="hide-lg-screen"></app-new-select>

  <app-new-select [(ngModel)]="selectedTerminal" nullLabel="All terminals" [options]="terminals" [dataKey]="'bicsCode'"
                  [formatter]="utils.terminalFormatter" (ngModelChange)="onSelectedTerminalChanged($event)" optionSelectable="shortName"
                  width="xl" customSelectClass="input-group-right" class="hide-md-screen"></app-new-select>

  <div class="flex-fill"></div>

  <app-new-select *ngIf="showSort" [options]="sortOptions" [selectedFormatter]="sortTypeFormatter" [formatter]="sortTypeFormatter"
                  [(ngModel)]="selectedSort" (ngModelChange)="onSelectedSortTypeChanged($event)"
                  styling="tertiary" customSelectClass="display-right" class="ms-1 hide-lg-screen"></app-new-select>
  <div *ngIf="!showSort" class="default-sort-type hide-lg-screen">Timeslot (descending)</div>

  <div class="pagination-text hide-md-screen">{{this.getPageText()}}</div>
  <ng-container *ngIf="previousPageDisabled(); else previousEnabled">
    <div class="icon-button hide-md-screen" disabled>
      <i class="fal fa-chevron-left"></i>
    </div>
  </ng-container>
  <ng-template #previousEnabled>
    <div class="icon-button hide-md-screen" [attr.tabindex]="0" (click)="previousPage()">
      <i class="fal fa-chevron-left"></i>
    </div>
  </ng-template>
  <ng-container *ngIf="nextPageDisabled(); else nextEnabled">
    <div class="icon-button hide-md-screen" disabled>
      <i class="fal fa-chevron-right"></i>
    </div>
  </ng-container>
  <ng-template #nextEnabled>
    <div class="icon-button hide-md-screen" [attr.tabindex]="0" (click)="nextPage()">
      <i class="fal fa-chevron-right"></i>
    </div>
  </ng-template>

  <div class="show-sm-screen pe-2">
    <button *ngIf="(!actionMode || actionMode == 'other') && appContext.isHinterlandDeclarant()" (click)="createHandling()" class="btn btn-primary" type="button">
      <i class="button-icon fa-light fa-plus"></i>
    </button>

    <button *ngIf="actionMode == 'addHandlingToVisitMode' && appContext.isHinterlandDeclarant()" (click)="createHandlingForExistingVisit()" class="btn btn-secondary" type="button">
      <i class="button-icon fa-light fa-plus"></i>
    </button>

    <button *ngIf="actionMode == 'attachToVisitMode' && !this.actionObject?.handling?.preNotification && appContext.isHinterlandDeclarant()"
            (click)="createVisitForExistingHandling()" class="btn btn-secondary" type="button">
      <i class="button-icon fa-light fa-plus"></i>
    </button>
  </div>

</div>
