<app-pagination #page [items]="items" [maxPerPage]="20">

  <ng-container *ngIf="page.getItemsOnPage() as pageItems">
    <div *ngFor="let shipment of asShipments(pageItems); let index = index; trackBy: trackByShipmentId">

      <!-- render cargoDirector -->
      <ng-container *ngIf="shipment.data.cargoDirector as forwarder">
        <ng-container *ngIf="index === 0 || forwarder.shortName !== pageItems[index - 1].data.cargoDirector?.shortName">
          <div [ngClass]="{ 'mt-3' : index > 0 }" class="row g-0 align-items-center"
               title="Authorised by cargo director: {{forwarder.fullName | titlecase}}">
            <div class="col-auto me-2 invisible">
              <div class="custom-check custom-check-inline m-0">
                <input class="custom-check-input m-0" type="checkbox"></div>
            </div>
            <div class="col py-2 ps-2 text-portbase-dark-blue bg-portbase-very-light-aqua">
              <span class="fa fa-fw fa-users"></span>
              <span class="ms-1 me-2 small fa fa-forward"></span>
              <span class="text-dark">{{ forwarder.fullName | titlecase }}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>


      <div class="row g-0">
        <div class="col-auto me-2">
          <div class="custom-check custom-check-inline m-0 always-enabled"
               style="height: 2.5rem">
            <input [(ngModel)]="shipment['selected']" [disabled]="!!chosenAction" class="always-enabled custom-check-input m-0"
                   data-userflow="secure-chain-checkbox" type="checkbox"></div>
        </div>
        <div class="col">
          <app-fancy-collapse [collapse]="c" [deleteHandle]=null
                              [deleteModal]="cancelAllowed(shipment) ? ('#cancelShipment-' + shipment.id) : null">
            <div class="p-2">
              <div class="row align-items-center">
                <div *ngIf="shipment.data.equipmentNumber as equipmentNr" class="col-auto">
                  <span class="d-inline-block align-top text-truncate" style="width: 12rem; max-width: 100%"><span
                    class="fa fa-cube me-2"></span>{{ equipmentNr }}</span>
                </div>
                <div class="col-auto d-none d-lg-block">
                              <span class="d-inline-block align-top text-truncate"
                                    style="width: 12rem; max-width: 100%"><span class="fa fa-file-invoice me-2"></span>
                                {{ shipment.data.consignmentNumbers.join(', ') }}</span>
                </div>

                <div *ngIf="appContext.isAdmin() && shipment.data.inlandOperator?.fullName as inlandOperatorName"
                     class="col-auto d-none d-md-block"
                     title="Inland operator">
                              <span class="d-inline-block align-top text-truncate"
                                    style="width: 20rem; max-width: 100%">
                                <app-vessel
                                  *ngIf="shipment.movements[1].vessel || {'modality' : shipment.data.inlandModality} as vessel"
                                  [hideName]="true" [vessel]="vessel" class="me-2"></app-vessel>
                                {{ inlandOperatorName }}</span>
                </div>

                <div class="col d-none d-md-block text-end" style="min-width: 12rem">
                  <app-cargo-summary [cargoShipment]="shipment"></app-cargo-summary>
                </div>
              </div>
            </div>

            <ng-template #c>
              <div class="collapse">
                <div class="py-2 ms-2">
                  <div
                    *ngIf="shipment.commercialRelease?.releaseData?.releaseToParty?.name || shipment.releaseToParty?.fullName as rtp"
                    class="text-end position-relative" style="top: 35px; right: 10px">
                    <span title="Release-to party on commercial release"><i
                      class="fa fa-user-lock"></i> {{ rtp | titlecase }}</span>
                  </div>
                  <ng-container *ngIf="appContext.isAdmin; else notAdmin">
                    <ul #nav="ngbNav" class="nav-tabs" ngbNav>
                      <li ngbNavItem="1">
                        <a ngbNavLink>Details</a>
                        <ng-template ngbNavContent>
                          <div class="p-3">
                            <app-cargo-shipment [cargoShipment]="shipment"></app-cargo-shipment>
                          </div>
                        </ng-template>
                      </li>

                      <li ngbNavItem="2">
                        <a ngbNavLink>History</a>
                        <ng-template ngbNavContent>
                          <div class="p-3">
                            <app-shipment-history [shipmentId]="shipment.id"></app-shipment-history>
                          </div>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                  </ng-container>
                  <ng-template #notAdmin>
                    <app-cargo-shipment [cargoShipment]="shipment"></app-cargo-shipment>
                  </ng-template>
                </div>
              </div>
            </ng-template>
          </app-fancy-collapse>

          <div [id]="'cancelShipment-' + shipment.id" class="modal fade" role="dialog" tabindex="-1">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Cancel shipment</h5>
                  <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body">
                  <p>Are you sure you want to cancel this shipment?</p>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
                  <button (click)="cancelShipment(shipment)" class="btn btn-primary" data-bs-dismiss="modal"
                          type="button">Cancel shipment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-pagination>

<div *ngIf="items.length === 0" class="text-center text-muted" style="padding: 20px 0">
  Shipments have not been reported yet.
</div>

<!-- Every bulk action on selected items-->

<ng-container *ngIf="selectedItems.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center bg-white py-3 border-top">
    <div *ngIf="appContext.isAdmin() && getOriginalDownload() as downloadLink" class="col-auto mx-1">
      <a class="btn btn-outline-secondary" download="shipments-original.xls" href="{{downloadLink}}"
         style="min-width: 10rem" tabindex="-1" type="button">
        Download original
      </a>
    </div>
    <div *ngIf="cancelSelectedAllowed()" class="col-auto mx-1">
      <app-confirmation-toggle>
        <button class="btn btn-outline-danger" style="min-width: 10rem" type="button">Cancel shipment
        </button>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Cancel shipment</h5>
            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to cancel the selected {{ selectedItems.length === 1 ? 'shipment' : 'shipments' }}
              ?</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
            <button (click)="cancelSelected()" class="btn btn-primary" data-bs-dismiss="modal" type="button">Cancel
              shipment
            </button>
          </div>
        </div>
      </app-confirmation-toggle>
    </div>
    <div class="col-auto mx-1">
      <button (click)="excel.downloadShipments(selectedItems)" class="btn btn-outline-info" style="min-width: 10rem"
              type="button">Download
      </button>
    </div>
    <ng-container
      *ngIf="appContext.isHinterlandDeclarant() && isSameInlandOperator() && hasAttachableMovementsOfSameModality() as modality">
      <div class="col-auto mx-1">
        <button (click)="chosenAction = 'attaching'" class="btn btn-outline-info" style="min-width: 10rem"
                type="button">Attach to {{ modality }} visit
        </button>
      </div>
      <div *ngIf="modality === 'road'" class="col-auto mx-1">
        <button (click)="chosenAction = 'upgrading'" class="btn btn-outline-info" style="min-width: 10rem"
                type="button">Upgrade to pre-notification
        </button>
      </div>
    </ng-container>
    <div *ngIf="forwardingAllowed()" class="col-auto mx-1">
      <button [autoClose]="'outside'" [ngbPopover]="findInlandOperator"
              class="btn btn-outline-info dropdown-toggle" data-bs-toggle="dropdown" data-userflow="shipment-forward"
              popoverClass="find-authorisation-popover" style="min-width: 10rem" type="button">Forward
      </button>
      <ng-template #findInlandOperator>
        <app-form-group label="Organisation">
          <app-search (ngModelChange)="$event ? forwardSelected($event) : null"
                      [inputFormatter]="utils.hinterlandOrganisationFormatter" [ngModel]="null"
                      [searchFunction]="utils.findInlandOperators"
                      placeholder="Find organisation"
                      placement="top">
          </app-search>
        </app-form-group>
      </ng-template>
    </div>
    <div *ngIf="cancelForwardingAllowed()" class="col-auto mx-1">
      <app-confirmation-toggle>
        <button class="btn btn-outline-danger" style="min-width: 10rem" type="button">Cancel forwarding
        </button>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Cancel forwarding</h5>
            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to cancel the forwarding of the selected
              {{ selectedItems.length === 1 ? 'shipment' : 'shipments' }}?</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
            <button (click)="cancelForwarding()" class="btn btn-primary" data-bs-dismiss="modal" type="button">Cancel
              forwarding
            </button>
          </div>
        </div>
      </app-confirmation-toggle>
    </div>
  </div>
  <ng-container *ngIf="chosenAction === 'attaching'">
    <div class="fixed-bottom bg-white py-3 border-top">
      <div *ngIf="getSelectedModality() as modality" class="container-fluid">
        <div class="row narrow-gutters justify-content-center align-items-center">
          <div class="col-auto d-none d-sm-block">
            <div>Attach container<span *ngIf="selectedItems.length > 1">s</span> to a {{ modality }} visit. Are you
              sure?
            </div>
            <div class="small" style="max-width: 24rem">The container<span *ngIf="selectedItems.length > 1">s</span>
              will be added to the new visit. Status info will be requested from the terminal directly.
            </div>
          </div>
          <div class="col-auto" style="min-width: 19rem">
            <app-search [(ngModel)]="chosenValue['visitForAttaching']"
                        [autoSelectOnSingleResult]="true"
                        [dataKey]="['tar', 'visitId']"
                        [minCharacters]="3"
                        [modality]="modality"
                        [placeholder]="utils.findVisitPlaceholder(modality)"
                        [placement]="'top-left'"
                        [resultFormatter]="visitResultFormatter"
                        [searchFunction]="findVisits">
            </app-search>
          </div>
        </div>

        <div class="row narrow-gutters justify-content-center align-items-center mt-2">
          <div class="col-auto">
            <button (click)="chosenAction = null" class="btn btn-secondary mx-2" style="min-width: 8rem" tabindex="-1"
                    type="button">Cancel
            </button>
            <button (click)="attachSelected()" *ngIf="modality!=='road'" [disabled]="!chosenValue['visitForAttaching']"
                    class="btn btn-info mx-2"
                    style="min-width: 8rem" tabindex="-1" type="button">Attach
            </button>
            <button (click)="attachSelected(true)" [disabled]="!chosenValue['visitForAttaching']"
                    class="btn btn-info mx-2"
                    style="min-width: 8rem" tabindex="-1" type="button">Attach and pre-notify
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="chosenAction === 'upgrading'">
    <div class="fixed-bottom bg-white py-3 border-top">
      <div class="container-fluid">
        <div class="row narrow-gutters justify-content-center align-items-center">
          <div class="col-auto d-none d-sm-block">
            <div>Upgrade container<span *ngIf="selectedItems.length > 1">s</span> to separate road pre-notifications.
              Are you sure?
            </div>
            <div class="small" style="max-width: 24rem">The container<span *ngIf="selectedItems.length > 1">s</span>
              will be added to separate visits with given license plate. Status info and if possible TARs will be
              requested from the terminal directly.
            </div>
          </div>
          <div class="col-auto">
            <input [(ngModel)]="chosenValue['truckLicenseId']" class="form-control" maxlength="15"
                   oninput="this.value = this.value.toUpperCase()" placeholder="Truck license plate" type="text"/>
          </div>
          <div class="col-auto">
            <button (click)="chosenAction = null" class="btn btn-secondary mx-2" style="min-width: 8rem" tabindex="-1"
                    type="button">Cancel
            </button>
            <button (click)="upgradeSelected()" class="btn btn-info mx-2"
                    style="min-width: 8rem" tabindex="-1" type="button">Upgrade to pre-notifications
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
