import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HandlingType} from "@portbase/hinterland-service-typescriptmodels";
import {Terminal} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import {Observable} from "rxjs";
import {HinterlandUtils} from "../../../../hinterland/hinterland-utils";
import {EventHandler, EventType} from "../../../../common/event-gateway";
import {HinterlandBaseComponent} from "../../../../common/hinterland-base-component";
import {ActionMode, SelectAllValue, SortType, VoyageVisitAndHandling} from "../../../../hinterland/types";
import {AppContext} from "../../../../app-context";
import {AddHandlingOffCanvasComponent} from "../../offcanvas-panel/add-handling-offcanvas/app-add-handling-offcanvas";
import {AddVisitOffCanvasComponent} from "../../offcanvas-panel/add-visit-offcanvas/app-add-visit-offcanvas";
import {EditModalService} from '../../../../components/modals/edit-modal/edit-modal.service';

@Component({
  selector: 'app-road-navbar-top',
  templateUrl: './road-navbar-top.component.html',
  styleUrls: ['./road-navbar-top.component.scss']
})
export class RoadNavbarTopComponent extends HinterlandBaseComponent implements OnInit {

  appContext = AppContext;
  utils = HinterlandUtils;

  @Input() onFilterTermChanged: (arg: string) => Observable<any[]> | any[];
  @Input() onSelectedHandlingTypeChanged: (arg: HandlingType) => Observable<any[]> | any[];
  @Input() onSelectedTerminalChanged: (arg: Terminal) => Observable<any[]> | any[];
  @Input() onSelectedSortTypeChanged: (arg: SortType) => Observable<any[]> | any[];

  @Input() maxHits: number;
  @Input() page: number;
  @Input() pageSize: number;
  @Input() totalCount: number;

  @Input() filterTerm: string = '';
  @Input() selectedTerminal: Terminal = undefined;
  @Input() selectedHandlingType: string = undefined;
  @Input() selectedSort: SortType = 'timeslot';
  @Input() showSort: boolean;

  @Output() changePage = new EventEmitter<number>();

  actionMode: ActionMode;
  actionObject: VoyageVisitAndHandling;

  hidden: boolean = false;
  selectAllValue: SelectAllValue = 'none';

  sortOptions: SortType[] = ['timeslot', 'updated', 'status'];
  terminals: Terminal[] = [];

  constructor(
    private readonly editModalService: EditModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    HinterlandUtils.getTerminals('road', true, true, true).subscribe(terminals => this.terminals = terminals);

    const storedSortType: SortType = AppContext.getSortType();
    this.selectedSort = !!storedSortType ? storedSortType : 'timeslot';
  }

  toggleSelectAllHandlings() {
    if (this.selectAllValue === 'all') {
      this.eventGateway.publish(EventType.DeselectAllHandlings);
    } else {
      this.eventGateway.publish(EventType.SelectAllHandlings);
    }
  }

  getPageText() {
    if (this.totalCount === 0) {
      return '0 of 0';
    } else {
      const firstItemIndex = this.page * this.pageSize;
      let lastItemIndex = firstItemIndex + this.pageSize;
      if (lastItemIndex > this.totalCount) {
        lastItemIndex = this.totalCount;
      }

      const moreResults = this.totalCount === this.maxHits ? '+' : '';
      return `${firstItemIndex + 1} - ${lastItemIndex} of ${this.totalCount}${moreResults}`;
    }
  }

  nextPage() {
    this.changePage.emit(this.page + 1);
  }

  previousPage() {
    this.changePage.emit(this.page - 1);
  }

  previousPageDisabled() {
    return this.page === 0;
  }

  nextPageDisabled() {
    return (this.page + 1) * this.pageSize >= this.totalCount;
  }

  onHandlingSelected: EventHandler<EventType.HandlingSelected> = () => {
    this.hidden = true;
  }

  onSelectAllHandlingsChanged: EventHandler<EventType.SelectAllHandlingsChanged> = (value: SelectAllValue) => {
    this.selectAllValue = value;

    if (value === "none") {
      this.hidden = false;
    }
  }

  onEnterActionMode: EventHandler<EventType.EnterActionMode> = (data) => {
    this.actionMode = !!data.visit ? 'addHandlingToVisitMode' : (!!data.handling || !!data.handlings ? 'attachToVisitMode' : 'other');
    this.actionObject = data;
  }

  onExitActionMode: EventHandler<EventType.ExitActionMode> = (data) => {
    if (!!data) {
      this.actionMode = null;
      this.actionObject = null;
    }
    if (this.hidden) {
      this.hidden = false;
    }
  }

  handlingTypeFormatter = (handlingType) => {
    switch (handlingType) {
      default:
        return "";
      case "loading":
        return "Pick-up";
      case "discharge":
        return "Drop-off";
    }
  }

  sortTypeFormatter = (sortType: SortType) => {
    switch (sortType) {
      default:
        return "Timeslot (ascending)";
      case 'timeslot':
        return "Timeslot (ascending)";
      case 'updated':
        return "Recently updated (descending)";
      case 'status':
        return "Status";
    }
  }

  createHandling() {
    this.editModalService.openModal(AddHandlingOffCanvasComponent);
  }

  createHandlingForExistingVisit() {
    this.editModalService.openModal(AddHandlingOffCanvasComponent, {
      addMode: 'addToVisit',
      voyage: this.actionObject?.voyage,
      visit: this.actionObject?.visit
    });

    this.eventGateway.publish(EventType.ExitActionMode, this.actionObject);
    this.eventGateway.publish(EventType.EnterEditMode);
  }

  createVisitForExistingHandling() {
    this.editModalService.openModal(AddVisitOffCanvasComponent, this.actionObject);

    this.eventGateway.publish(EventType.ExitActionMode, this.actionObject);
    this.eventGateway.publish(EventType.EnterEditMode);
  }

}
