import {Component, ElementRef, OnInit} from '@angular/core';
import {UpdateBinnenhavengeldAccount} from '@portbase/hinterland-service-typescriptmodels';
import {Location} from '@angular/common';
import {checkValidity, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-bhg-update-account',
  templateUrl: './bhg-update-account.component.html',
  styleUrls: ['./bhg-update-account.component.css']
})
export class BhgUpdateAccountComponent implements OnInit {

  command: UpdateBinnenhavengeldAccount = {
    organisationId: AppContext.binnenhavengeldUserProfile?.organisationId,
    accountDetails: {
      companyDetails: {},
      otherParties: []
    }
  };

  errors: string[];

  constructor(public location: Location, private element: ElementRef) {
  }

  ngOnInit() {
    this.errors = [];
  }

  updateAccount = () => {
    if (checkValidity(this.element)) {
      AppContext.clearAlerts();
      this.errors = [];
      sendCommand('com.portbase.hinterland.api.binnenhavengeld.UpdateBinnenhavengeldAccount', this.command,
        () => {
          AppContext.publishSuccessMessage("Your request has been submitted to the Port of Rotterdam for review");
          AppContext.navigateToBinnenhavengeldHomepage(true);
        },
        (error) => {
          this.errors.push(error?.error?.error);
        });
    } else {
      AppContext.publishValidationErrorMessage();
    }
  };

}
