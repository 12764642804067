<ng-container *ngIf="config">
  <div class="offcanvas-panel">
    <div class="modal-header">
      <div class="d-flex header-container">
        <div class="d-flex centered clickable pe-3"><i data-bs-dismiss="modal"
                                                       class="overlay-icon fa-regular fa-xmark"></i></div>
        <div class="d-flex centered">Configure application</div>
        <div class="d-flex flex-fill"></div>
      </div>
    </div>

    <div class="modal-body">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Configuration</h5>

          <app-error [errors]="errors"></app-error>

          <div class="row">
            <div class="col-12">
              <app-form-field label="Minimum subscription start date"
                              tooltip="If left blank, skippers may add subscriptions up to 35 days before the system date."
                              tooltipPlacement="bottom">
                <app-date-time [(ngModel)]="config.subscriptionCutoffDate" [dateOnly]="true"></app-date-time>
              </app-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="footer-container">
        <div class="footer-container-content">
          <div class="button-bar">
            <button class="btn btn-primary" type="button" (click)="update()">Save configuration</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
