import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BinnenhavengeldUser} from '@portbase/hinterland-service-typescriptmodels';

@Component({
  selector: 'app-bhg-user',
  templateUrl: './bhg-user.component.html',
  styleUrls: ['../../bhg.scss', './bhg-user.component.scss']
})
export class BhgUserComponent {
  @Input() user: BinnenhavengeldUser;
  @Output() editUser = new EventEmitter<BinnenhavengeldUser>();
}
