<div class="offcanvas-panel">
  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i data-bs-dismiss="modal"
                                                     class="overlay-icon fa-regular fa-xmark"></i></div>
      <div class="d-flex centered">{{ data.userId ? 'Edit user' : 'Add user' }}</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">User details</h5>

        <app-error [errors]="errors"></app-error>
        <div class="row">
          <div class="col-12">
            <app-form-field label="Email">
              <input autocomplete="do-not-autofill" [(ngModel)]="data.email"
                     (change)="!updateMode && !data.userId ? data.userId = data.email : null"
                     required placeholder="Enter the email address of the user" class="form-control">
            </app-form-field>
            <app-form-field label="Login name">
              <input autocomplete="do-not-autofill" required [(ngModel)]="data.userId" [readonly]="updateMode"
                     tabindex="-1"
                     placeholder="Enter a login name (defaults to email)" class="form-control">
            </app-form-field>
            <app-form-field label="Organisation">
              <input autocomplete="do-not-autofill" [(ngModel)]="data.organisationId"
                     placeholder="Enter SAP organisation id" required class="form-control">
            </app-form-field>
            <app-form-field label="HBR">
              <app-yes-no [ngModel]="data.userRoles.includes('BinnenhavengeldSuperUser')" required
                          (ngModelChange)="$event ? addRole('BinnenhavengeldSuperUser') : removeRole('BinnenhavengeldSuperUser')"></app-yes-no>
            </app-form-field>
            <app-form-field label="Admin">
              <app-yes-no [ngModel]="data.userRoles.includes('BinnenhavengeldAdmin')" required
                          (ngModelChange)="$event ? addRole('BinnenhavengeldAdmin') : removeRole('BinnenhavengeldAdmin')"></app-yes-no>
            </app-form-field>
            <app-form-field label="Language" *ngIf="!updateMode">
              <app-new-select [(ngModel)]="data.preferredLanguage"
                              [options]="['Dutch', 'English', 'German', 'French']"></app-new-select>
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="footer-container-content">
        <div class="button-bar">
          @if (updateMode) {
            <button class="btn btn-secondary" type="button" (click)="confirmDelete()">
              <i class="button-icon fa-light fa-trash-alt pe-2"></i>
              Delete user
            </button>
            <button class="btn btn-primary" type="button" (click)="update()">Update user</button>
          } @else {
            <button class="btn btn-primary" type="button" (click)="add()">Add user</button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
