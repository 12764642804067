<div class="row bg-portbase-dark-blue z-layer-3">
  <nav class="page-section col navbar navbar-dark">
    <a [attr.href]="s000Url" class="navbar-brand" tabindex="-1">
      <img alt="Portbase Portal" class="d-inline-block align-top" height="30" src="assets/logo-portbase.svg">
      <span class="d-none d-sm-inline ms-3 ps-3 border-start">Hinterland Notification</span>
      <span *ngIf="appContext.roadMessages" class="fa fa-truck ms-1 ps-1" title="Road"></span>
    </a>
    <div class="row g-0 align-items-center">
      <div class="col-auto me-sm-3">
      </div>

      <div class="col d-none d-sm-block">
        <app-user-menu></app-user-menu>
      </div>

      <!-- mobile navbar buttons -->
      <div class="col-auto d-sm-none">
        <!-- feedback component -->
        <app-feedback></app-feedback>

        <!-- menu -->
        <div class="btn-group">
          <button class="btn btn-outline-light rounded" data-bs-toggle="dropdown" type="button">
            <span class="fa fa-bars"></span>
          </button>

          <div class="dropdown-menu dropdown-menu-end mobile-menu z-top">
            <a
              *ngIf="appContext.isBargeDeclarant() || appContext.isRailDeclarant() || appContext.isTractionSupplier() || appContext.isHinterlandTerminal()"
              [routerLink]="['/']"
              class="nav-item nav-link text-dark">{{appContext.isHinterlandTerminal() ? 'Visits' : 'Rotations'}}</a>

            <a
              *ngIf="appContext.isBargeDeclarant() || appContext.isRailDeclarant() || this.appContext.userProfile.organisationShortName === 'ECTDELTA'; else roadHandlings"
              [routerLink]="['/handlings']"
              class="nav-item nav-link text-dark">Handlings</a>

            <ng-template #roadHandlings>
              <a *ngIf="appContext.isRoadDeclarant()" [routerLink]="['/road']"
                 class="nav-item nav-link text-dark">Handlings</a>
            </ng-template>

            <a *ngIf="appContext.isHinterlandTerminal() && appContext.isHinterlandDeclarant()"
               [routerLink]="['/rotations']"
               class="nav-item nav-link text-dark">Rotations</a>

            <ng-container>
              <a [routerLink]="['/shipments']" class="nav-item nav-link text-dark">Shipments</a>
            </ng-container>

            <ng-container
              *ngIf="appContext.isHinterlandTerminal()">
              <a class="nav-item nav-link text-dark" href="/road">Road</a>
            </ng-container>

            <ng-container *ngIf="appContext.isAdmin()">
              <a [routerLink]="['/message-overview']" class="nav-item nav-link text-dark">Pending</a>
              <a [routerLink]="['/refdata']" class="nav-item nav-link text-dark">Refdata</a></ng-container>

            <div class="nav-item nav-link text-dark ms-auto has-news beamer-button" data-bs-toggle="tab" role="tab"
                 tabindex="-1">News
            </div>
            <a [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/application')}"
               [routerLink]="['/preferences/application']"
               class="nav-item nav-link text-dark">Application Preferences</a>
            <a *ngIf="appContext.isHinterlandTerminal()"
               [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/bollard')}"
               [routerLink]="['/preferences/bollard']"
               class="nav-item nav-link text-dark">Bollards</a>
            <a *ngIf="appContext.isHinterlandTerminal()"
               [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/fixedwindow')}"
               [routerLink]="['/preferences/fixedwindow']"
               class="nav-item nav-link text-dark">Barge Fixed Windows</a>
            <a *ngIf="appContext.isHinterlandTerminal()"
               [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/timetable')}"
               [routerLink]="['/preferences/timetable']"
               class="nav-item nav-link text-dark">Rail Timetable</a>
            <a *ngIf="appContext.isRailDeclarant()"
               [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/railvoyagetemplate')}"
               [routerLink]="['/preferences/railvoyagetemplate']"
               class="nav-item nav-link text-dark">Rail rotation
              templates</a>
            <a
              class="nav-item nav-link text-dark"
              href="https://support.portbase.com/services/melding-container-achterland-barge/"
              target="_blank">Tutorial + FAQ</a>
            <a class="nav-item nav-link text-dark" data-bs-target="#logOutModal" data-bs-toggle="modal" href="#"
               tabindex="-1">Log out</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>

<!-- sub nav bar -->
<div class="row bg-portbase-aqua d-none d-sm-block z-layer-2">
  <nav class="page-section col hinterland-nav">
    <ul [activeId]="appContext.getUrlPath()" class="nav-tabs navbar-dark border-bottom-0" ngbNav>

      <ng-container
        *ngIf="appContext.isBargeDeclarant() || appContext.isRailDeclarant() || appContext.isTractionSupplier() || appContext.isHinterlandTerminal()">
        <li ngbNavItem="/">
          <a [routerLink]="['/']" class="text-dark" ngbNavLink
             tabindex="-1">{{appContext.isHinterlandTerminal() ? 'Visits' : 'Rotations'}}</a>
        </li>
      </ng-container>

      <ng-container
        *ngIf="appContext.isBargeDeclarant() || appContext.isRailDeclarant() || this.appContext.userProfile.organisationShortName === 'ECTDELTA'; else roadHandlingsContainer">
        <li ngbNavItem="/handlings">
          <a [routerLink]="['/handlings']"
             class="nav-item nav-link text-dark"
             data-bs-toggle="tab" ngbNavLink role="tab" tabindex="-1">Handlings</a>
        </li>
      </ng-container>

      <ng-template #roadHandlingsContainer>
        <ng-container *ngIf="appContext.isRoadDeclarant()">
          <li ngbNavItem="/road">
            <a [routerLink]="['/road']"
               class="nav-item nav-link text-dark"
               data-bs-toggle="tab" ngbNavLink role="tab" tabindex="-1">Handlings</a>
          </li>
        </ng-container>
      </ng-template>


      <ng-container
        *ngIf="appContext.isHinterlandTerminal() && (appContext.isBargeDeclarant() || appContext.isRailDeclarant())">
        <li ngbNavItem="/rotations">
          <a [routerLink]="['/rotations']"
             class="nav-item nav-link text-dark"
             data-bs-toggle="tab" ngbNavLink role="tab" tabindex="-1">Rotations</a>
        </li>
      </ng-container>

      <ng-container>
        <li ngbNavItem="/shipments">
          <a [routerLink]="['/shipments']" class="text-dark" ngbNavLink tabindex="-1">Shipments</a>
        </li>
      </ng-container>

      <ng-container
        *ngIf="appContext.isHinterlandTerminal()">
        <li ngbNavItem="/road">
          <a class="nav-link text-dark" href="/road" tabindex="-1">Road</a>
        </li>
      </ng-container>

      <ng-container *ngIf="appContext.isAdmin()">
        <li ngbNavItem="/message-overview">
          <a [routerLink]="['/message-overview']" class="text-dark" ngbNavLink
             tabindex="-1">Pending</a>
        </li>
        <li ngbNavItem="/refdata">
          <a [routerLink]="['/refdata']" class="text-dark" ngbNavLink tabindex="-1">Refdata</a>
        </li>
      </ng-container>

      <li *ngIf="appContext.isPortAlertBetaUser()"
          ngbNavItem="/port-alert">
        <a [class.active]="appContext.getUrlPath().includes('port-alert')" class="text-dark" ngbNavLink
           routerLink="/port-alert"
           tabindex="-1">Port Alert</a>
      </li>

      <div class="nav-item nav-link text-dark ms-auto has-news beamer-button" data-bs-toggle="tab" role="tab"
           tabindex="-1">News
      </div>

      <a [ngClass]="{'active': appContext.getUrlPath().startsWith('/preferences')}" aria-expanded="false"
         aria-haspopup="true" class="nav-item nav-link text-dark" data-bs-toggle="dropdown" id="preferencesDropdown"
         role="tab"
         style="cursor: pointer" tabindex="-1">Configuration</a>

      <div aria-labelledby="preferencesDropdown" class="dropdown-menu">
        <a [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/application')}"
           [routerLink]="['/preferences/application']"
           class="dropdown-item">Application Preferences</a>
        <a *ngIf="appContext.isHinterlandTerminal()"
           [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/bollard')}"
           [routerLink]="['/preferences/bollard']"
           class="dropdown-item">Bollards</a>
        <a *ngIf="appContext.isHinterlandTerminal()"
           [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/fixedwindow')}"
           [routerLink]="['/preferences/fixedwindow']"
           class="dropdown-item">Barge Fixed Windows</a>
        <a *ngIf="appContext.isHinterlandTerminal()"
           [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/timetable')}"
           [routerLink]="['/preferences/timetable']"
           class="dropdown-item">Rail Timetable</a>
        <a *ngIf="appContext.isRailDeclarant()"
           [ngClass]="{'active': appContext.getUrlPath() == ('/preferences/railvoyagetemplate')}"
           [routerLink]="['/preferences/railvoyagetemplate']"
           class="dropdown-item">Rail rotation
          templates</a>
      </div>

      <a
        class="nav-item nav-link text-dark"
        href="https://support.portbase.com/services/melding-container-achterland-barge/"
        tabindex="-1" target="_blank">Tutorial + FAQ</a>

      <app-feedback></app-feedback>
    </ul>
  </nav>
</div>

<div class="modal fade" id="logOutModal" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Log out</h5>
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to log out?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
        <button (click)="logOut()" class="btn btn-primary" data-bs-dismiss="modal" type="button">Log out</button>
      </div>
    </div>
  </div>
</div>

