<div class="form-group row">
  <div class="col-xl-4 col-form-label" [ngClass]="{'col-xl-12': labelAbove}">
    <ng-container *ngIf="tooltip; else labelText">
      <app-info [wide]="true">
        <span class="me-1">
          <ng-container *ngTemplateOutlet="labelText"></ng-container>
        </span>
        <div class="tooltipContent">{{tooltip}}</div></app-info></ng-container>
  </div>
  <div class="col-xl form-content">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #labelText>
  <ng-container *ngIf="label; else fromTemplate"><label>{{label}}</label></ng-container>
</ng-template>

<ng-template #fromTemplate>
  <ng-content select="label"></ng-content>
  <ng-content select=".formGroupLabel"></ng-content>
</ng-template>
