import {Component} from '@angular/core';
import {AbstractOverviewComponent} from '../../hinterland/common/abstract-overview.component';
import {
  BinnenhavengeldUser,
  DateTimeRange,
  FindBinnenhavengeldUsers
} from '@portbase/hinterland-service-typescriptmodels';
import {Observable} from 'rxjs';
import {removeIf, replaceItem, sendQuery} from '../../common/utils';
import {BhgEditUserComponent} from './bhg-edit-user/bhg-edit-user.component';
import {exportDataAsExcel} from '../../common/upload/excel.utils';
import {EditModalService} from "../../components/modals/edit-modal/edit-modal.service";

@Component({
  selector: 'app-bhg-users',
  templateUrl: './bhg-users.component.html',
  styleUrls: ['../bhg.scss', './bhg-users.component.css']
})
export class BhgUsersComponent extends AbstractOverviewComponent<BinnenhavengeldUser> {

  constructor(
    private readonly editModalService: EditModalService,
  ) {
    super();
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<BinnenhavengeldUser[]> {
    return sendQuery('com.portbase.hinterland.api.binnenhavengeld.FindBinnenhavengeldUsers',
      <FindBinnenhavengeldUsers>{term: this.filterTerm}, {caching: false});
  }

  doRender(entries: BinnenhavengeldUser[]) {
  }

  searchOrFilter = (): (BinnenhavengeldUser[] | Observable<BinnenhavengeldUser[]>) => {
    return this.reload();
  };

  editUser(user: BinnenhavengeldUser) {
    this.editModalService.openModal(BhgEditUserComponent, user);

  }

  'onBinnenhavengeldUserAdded' = (item: BinnenhavengeldUser) => {
    this.items.splice(0, 0, item);
  }

  'onBinnenhavengeldUserUpdated' = (item: BinnenhavengeldUser) => {
    replaceItem(this.items, this.items.find(i => i.userId === item.userId), item);
  }

  'onBinnenhavengeldUserDeleted' = (item: BinnenhavengeldUser) => {
    removeIf(this.items, i => i.userId === item.userId);
  }

  addUser() {
    this.editModalService.openModal(BhgEditUserComponent);
  }

  downloadUsers() {
    const data = [["Businesspartner Nummer", "Mailadres", "Admin", "Super user"]];

    sendQuery('com.portbase.hinterland.api.binnenhavengeld.GetAllBinnenhavengeldUsers', {}, {caching: false, showSpinner: true}).subscribe((users: BinnenhavengeldUser[]) => {
      users.forEach(u => {
        data.push([
          u.organisationId,
          u.email,
          String(u.userRoles?.includes('BinnenhavengeldAdmin')),
          String(u.userRoles?.includes('BinnenhavengeldSuperUser'))
        ]);
      });
      exportDataAsExcel(data,'binnenhavengeld-users.xlsx');
    });
  }
}
