<ng-template #t let-date let-focused="focused">
      <span class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
            (click)="onDateSelection(date, $event)">
        {{ date.day }}
      </span>
</ng-template>

<div class="fieldset position-relative" [ngClass]="{'disabled' :disabled}">
  <!--disgusting trick to make Angular Bootstrap component work the way we want.
      This input is invisible and is only used to instruct the date picker and position the range picker-->
  <input ngbDatepicker #d="ngbDatepicker" (closed)="onDatepickerClose()" [displayMonths]="numberOfMonths" [dayTemplate]="t"
         autoClose="outside" placement="bottom-left" class="form-control"
         style="visibility: hidden; position: absolute">
  <div class="form-row datetime">
    <div class="col input-group">
      <input [(ngModel)]="formatted" [id]="id ? id + '-input' : ''"
             [placeholder]="'mmm dd, yyyy' + separator + 'mmm dd, yyyy'"
             class="form-control" (blur)="onInputBlur($event.target.value)">
      <span class="input-group-text fal fa-calendar-alt"
              (click)="picker = d; d.toggle(); d.navigateTo(start);" type="button" tabindex="-1"></span>
    </div>
  </div>
</div>
