import {Component} from '@angular/core';
import {UserProfile} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from '../../app-context';
import {sendCommand} from '../../common/utils';
import {Router} from '@angular/router';
import {mockProfiles} from '../../../../utils/jwt-generator/mocks/user-profiles.mock';
import {HinterlandUtils} from "../../hinterland/hinterland-utils";
import {BetaUserService} from 'src/app/port-alert/services/beta-user.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent {

  appContext = AppContext;
  util = HinterlandUtils;

  mockProfiles = mockProfiles;

  constructor(private router: Router, private betaUserService: BetaUserService) {
  }

  onUserChanged = async (userProfile: UserProfile) => {
    if (!!userProfile) {
      sendCommand("updateUserProfile", userProfile, () => {
        if (environment?.docker || environment?.production) {
          this.router.navigate(['/']);
        }
      });
    }

    this.appContext.portAlertBetaUser = await this.betaUserService.isPortAlertBetaUser().toPromise();
  };

  apiDocUrl = (): string => {
    return 'https://hcn.' + (AppContext.environment || 'pcs') + '.portbase.com/api-documentation/index.html';
  };

  openTransactions() {
    if (!!AppContext.environment) {
      window.location.href = 'https://hcn.' + AppContext.environment + '.portbase.com/transactions';
    } else {
      window.location.href = 'http://localhost:4200/transactions';
    }
  }

  openSupport() {
    if (!!AppContext.environment && AppContext.isAdmin()) {
      window.location.href = 'https://hcn.' + AppContext.environment + '.portbase.com/support';
    } else if (!!AppContext.environment) {
      window.location.href = 'https://hcn.' + AppContext.environment + '.portbase.com';
    } else {
      window.location.href = 'http://localhost:4200/support';
    }
  };

}
