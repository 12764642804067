<span *ngIf="movements() as m" class="mx-1"><app-container-status [movements]="m"></app-container-status></span>

<span *ngIf="orderAvailable()" class="mx-2 fa fa-euro-sign" title="Transport order available"></span>

<ng-container *ngIf="containerEmpty()">
  <span class="mx-2">
    <span class="badge bg-secondary align-text-bottom" title="Empty container">Empty</span>
  </span>
</ng-container>

<span *ngIf="dangerousCargo()" class="fa fa-burn mx-2 text-danger" title="Cargo contains dangerous goods"></span>

<span *ngIf="reefers()" class="fa fa-thermometer-half mx-2 text-danger" title="Cargo is temperature controlled"></span>

<span *ngIf="shipmentCount() as count" class="mx-2" title="{{count}} shipment{{count > 1 ? 's' : ''}}"><span class="fa fa-file-invoice me-1"></span>{{count}}</span>

<span *ngIf="packageCount() as count" class="mx-2" title="{{count}} package{{count > 1 ? 's' : ''}}"><span class="fa fa-box-open me-1"></span>{{count}}</span>

<span *ngIf="containerCount() as count" class="mx-2" title="{{count}} container{{count > 1 ? 's' : ''}}"><span class="fa fa-cube me-1"></span><span class="text-start d-inline-block" style="min-width: 1.5rem">{{count}}</span></span>
