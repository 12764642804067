import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
  DayAndTimeRange,
  DayOfWeek,
  DeclarantOrganisation,
  FindDeclarantOrganisations,
  FixedWindow,
  GetDeclarantOrganisationsByShortName,
  GetFixedWindowsForTerminal,
  TerminalOrganisation
} from '@portbase/hinterland-service-typescriptmodels';
import {AppContext} from "../../app-context";
import {checkValidity, cloneObject, lodash, sendCommand, sendQuery, toTitleCase, uuid} from "../../common/utils";
import {of} from "rxjs";
import {ComparatorChain} from "../../common/comparator-chain";
import moment, {now} from "moment";
import {HinterlandUtils, TerminalModel} from "../../hinterland/hinterland-utils";
import {map} from "rxjs/operators";
import {DateRange} from '@portbase/hinterland-service-typescriptmodels/hinterland';

@Component({
  selector: 'app-fixed-window',
  templateUrl: './fixed-window.component.html',
  styleUrls: ['./fixed-window.component.css']
})
export class FixedWindowComponent implements OnInit {

  private static dayOrder: DayOfWeek[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
  private static dayOrderReversed: DayOfWeek[] = ["SUNDAY", "SATURDAY", "FRIDAY", "THURSDAY", "WEDNESDAY", "TUESDAY", "MONDAY"];
  private static startDayComparator = (f1: FixedWindowModel, f2: FixedWindowModel) => FixedWindowComponent.dayOrderReversed.indexOf(f2.window.start.day) - FixedWindowComponent.dayOrderReversed.indexOf(f1.window.start.day);
  private static endDayComparator = (f1: FixedWindowModel, f2: FixedWindowModel) => FixedWindowComponent.dayOrderReversed.indexOf(f2.window.start.day) - FixedWindowComponent.dayOrderReversed.indexOf(f1.window.start.day);
  private static startDayComparatorReversed = (f1: FixedWindowModel, f2: FixedWindowModel) => FixedWindowComponent.dayOrder.indexOf(f1.window.start.day) - FixedWindowComponent.dayOrder.indexOf(f2.window.start.day);
  private static endDayComparatorReversed = (f1: FixedWindowModel, f2: FixedWindowModel) => FixedWindowComponent.dayOrder.indexOf(f1.window.start.day) - FixedWindowComponent.dayOrder.indexOf(f2.window.start.day);

  appContext = AppContext;
  entry: FixedWindow;
  searchResult: FixedWindow;
  fixedWindows: FixedWindowModel[];

  @Input() sortBy: 'code' | 'codeReversed' | 'terminal' | 'terminalReversed' = 'terminal';

  @Input() set setFixedWindows(fixedWindows: FixedWindowModel[]) {
    this.sortFixedWindows(fixedWindows);
  }

  comparators = {
    'code': ["code", "periodOfValidity.start"],
    'codeReversed': ["-code", "-periodOfValidity.start"],
    'terminal': ["terminal.shortName", "periodOfValidity.start"],
    'terminalReversed': ["-terminal.shortName", "-periodOfValidity.start"],
    'declarant': ["declarant.fullName"],
    'declarantReversed': ["-declarant.fullName"],
    'handling': ["handlings"],
    'handlingReversed': ["-handlings"],
    'periodOfValidity': ["periodOfValidity.start", "periodOfValidity.end", FixedWindowComponent.startDayComparator, "window.start.time", FixedWindowComponent.endDayComparator, "window.end.time"],
    'periodOfValidityReversed': ["-periodOfValidity.start", "-periodOfValidity.end", FixedWindowComponent.startDayComparatorReversed, "-window.start.time", FixedWindowComponent.endDayComparatorReversed, "-window.end.time"],
    'window': [FixedWindowComponent.startDayComparator, "window.start.time", FixedWindowComponent.endDayComparator, "window.end.time"],
    'windowReversed': [FixedWindowComponent.startDayComparatorReversed, "-window.start.time", FixedWindowComponent.endDayComparatorReversed, "-window.end.time"]
  };

  sortDirection = {
    'code': 'codeReversed',
    'terminal': 'terminalReversed',
    'declarant': 'declarantReversed',
    'handling': 'handlingReversed',
    'periodOfValidity': 'periodOfValidityReversed',
    'window': 'windowReversed'
  }

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    sendQuery('com.portbase.hinterland.api.refdata.query.GetFixedWindowsForTerminal', <GetFixedWindowsForTerminal>{untyped: true}, {caching: false})
      .subscribe((fixedWindows: FixedWindow[]) => {
        this.sortFixedWindows(fixedWindows);
        this.lookupDeclarantOrganisations(fixedWindows);
      });
  }

  private lookupDeclarantOrganisations(fixedWindows: FixedWindow[]) {
    sendQuery('com.portbase.hinterland.api.common.query.GetDeclarantOrganisationsByShortName', <GetDeclarantOrganisationsByShortName>{shortNames: fixedWindows.map(f => f.declarant.shortName)}, {caching: false}) //
      .subscribe((declarants: DeclarantOrganisation[]) => //
        this.fixedWindows = fixedWindows.map(f => new FixedWindowModel(f, declarants.find(d => d.shortName == f.declarant.shortName))));
  }

  private getComparator() {
    let comparatorChain = new ComparatorChain();
    this.comparators[this.sortBy].forEach(p => comparatorChain.addProperty(p));
    return comparatorChain.compare;
  }

  sortFixedWindows(fixedWindows: FixedWindowModel[]) {
    (this.fixedWindows = fixedWindows).sort(this.getComparator());
  }

  getSortDirection = (nextSort: string) => {
    return this.sortBy === nextSort ? this.sortDirection[nextSort] : nextSort;
  }

  searchResultFormatter = (result: any) => {
    const typedResult = result as FixedWindowModel;
    return typedResult.code + " - "
      + (AppContext.isAdmin() ? typedResult.terminal.shortName + ', ' : '')
      + typedResult.declarant.fullName
      + ' (' + toTitleCase(typedResult.window.start.day)
      + ' ' + typedResult.window.start.time + ' - '
      + (typedResult.window.end.day != typedResult.window.start.day ? toTitleCase(typedResult.window.end.day) + ' ' : '')
      + typedResult.window.end.time + ")";
  };

  cast = (item: FixedWindowModel): FixedWindowModel => item;

  search = term => {
    return of(this.fixedWindows.filter(fixedWindow => JSON.stringify(fixedWindow).toLowerCase().indexOf(term.toLowerCase()) > -1));
  };

  newEntry = () => {
    if (this.searchResult) {
      this.entry = cloneObject(this.searchResult);
      this.entry.id = uuid();
    } else {
      const date = moment(now()).format('YYYY-MM-DD');
      this.entry = <FixedWindow>{window: {start: {}, end: {}}, periodOfValidity: {start: date, end: date}};
    }

    if (!this.appContext.isAdmin() && this.entry.terminal == null) {
      this.findTerminals(this.appContext.userProfile.organisationShortName)
        .subscribe((terminals: TerminalOrganisation[]) =>
          this.entry.terminal = terminals.filter(terminal => terminal.shortName == this.appContext.userProfile.organisationShortName).pop());
    }

    this.entry.id = uuid();
    this.searchResult = undefined;
  };

  onEntryChange() {
    this.entry = this.searchResult;
  }

  cancel = () => {
    this.clear();
  };

  addEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.AddFixedWindow', {entity: this.fixEmptyDisplayNameOverrides(this.entry)}, () => {
        AppContext.registerSuccess('Fixed window was added successfully');
        this.fixedWindows.push(this.entry);
        this.clear();
      });
    }
  };

  updateEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.UpdateFixedWindow', {entity: this.fixEmptyDisplayNameOverrides(this.entry)}, () => {
        AppContext.registerSuccess('Fixed window was updated successfully');
        this.clear();
      });
    }
  };

  deleteEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.hinterland.api.refdata.command.DeleteFixedWindow', {entity: this.fixEmptyDisplayNameOverrides(this.entry)}, () => {
        AppContext.registerSuccess('Fixed window was deleted successfully');
        this.fixedWindows = this.fixedWindows.filter(f => f['id'] != this.entry.id);
        this.clear();
      });
    }
  };

  fixEmptyDisplayNameOverrides = (fixedWindow: FixedWindow) => {
    let displayNameOverrides = fixedWindow.terminal.displayNameOverrides
    if(!!displayNameOverrides && Object.keys(displayNameOverrides).toString() == "@class") {
      delete fixedWindow.terminal.displayNameOverrides["@class"];
    }
    return fixedWindow;
  }

  private clear = () => {
    this.entry = undefined;
    this.searchResult = undefined;
  };

  onEntrySelected(entry: FixedWindow) {
    this.searchResult = entry;
    this.entry = entry;
  }

  findDeclarants = term => sendQuery("com.portbase.hinterland.api.common.query.FindDeclarantOrganisations", <FindDeclarantOrganisations>{term: term, modality: 'barge'});
  findTerminals = term => sendQuery("com.portbase.hinterland.api.common.query.GetTerminals", {})
    .pipe(map((r: TerminalModel[]) => r
      .filter(t =>
        !!t.shortName
        && t.shortName.toUpperCase().includes(term.toUpperCase())
        && t.allowedModalities.includes("barge"))))
    .pipe(map((r: TerminalModel[]) => lodash.uniqBy(r, 'shortName')));
  declarantFormatter = (organisation: DeclarantOrganisation) => organisation.fullName;

  // Do not change to terminal quay name. The fixed window is based on terminal organisation and barge operator
  terminalFormatter = (terminal: TerminalOrganisation) => terminal.shortName;

  weekDays = () => HinterlandUtils.weekdays;
  toTitleCase = (value) => toTitleCase(value);
}

export class FixedWindowModel {
  constructor(fixedWindow: FixedWindow, organisation: DeclarantOrganisation) {
    this['id'] = fixedWindow['id'];
    this.code = fixedWindow.code;
    this.handlings = Number(fixedWindow.handlings);
    this.minimumCallSize = fixedWindow.minimumCallSize;
    this.periodOfValidity = fixedWindow.periodOfValidity;
    this.window = FixedWindowModel.formatHHMM(fixedWindow.window);
    this.terminal = {
      bicsCode: fixedWindow.terminal.bicsCode,
      displayNameOverrides: fixedWindow.terminal.displayNameOverrides,
      ean: fixedWindow.terminal.ean,
      location: fixedWindow.terminal.location,
      quayName: fixedWindow.terminal.quayName,
      shortName: fixedWindow.terminal.shortName,
      smdgCode: fixedWindow.terminal.smdgCode,
    };
    this.declarant = organisation;
  }

  private static formatHHMM(window: DayAndTimeRange): DayAndTimeRange {
    return <DayAndTimeRange>{
      start: {
        time: window.start.time.substring(0, window.start.time.indexOf(":") + 3),
        day: window.start.day
      },
      end: {
        time: window.end.time.substring(0, window.end.time.indexOf(":") + 3),
        day: window.end.day
      }
    }
  }

  code?: string;
  handlings?: number;
  minimumCallSize?: number;
  periodOfValidity?: DateRange;
  terminal?: TerminalOrganisation;
  window?: DayAndTimeRange;
  declarant?: DeclarantOrganisation;

}
