import {Component, Input} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-remove-remain-on-truck',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: 'remove-remain-on-truck-container.component.html',
  styleUrl: 'remove-remain-on-truck-container.component.css'
})
export class RemoveRemainOnTruckContainerComponent {

  @Input() data;

  callback: (result: boolean) => any;

  remove() {
    this.callback(true);
  }

}
