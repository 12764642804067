<div>
  <ng-container *ngFor="let restriction of restrictions; let idRestriction = index;">
    <div class="card card-body my-2">
      <div class="card-title row">
        <h5 class="col">{{restriction.transportingReefers ? 'Reefer restriction' : 'Restriction'}}</h5>
        <span (click)="removeRestriction(idRestriction)" class="fa fa-times text-secondary me-2 clickable remove-restriction"></span>
      </div>

      <div class="row align-items-center g-0 mx-0 restriction">
        <ng-container *ngFor="let group of restriction.visitIds; let idGroup = index;">
          <div class="col-sm-3 my-2 me-2 bg-light fa-border">

            <div [(dragulaModel)]="restrictions[idRestriction].visitIds[idGroup]" class="restrictions py-2 ms-2"
                 dragula="restrictions" id="restrictions">
              <div *ngFor="let visitId of group;"
                   class="row g-0">
                <div class="col col-3">
                  <span>
                    <span class="me-2 badge rounded-pill bg-light text-dark" style="width: 5rem">{{visitId}}</span>
                  </span>
                </div>
                <div class="col col-9">
                  <span>{{getTerminalQuayNameForVisitId(visitId)}}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="restriction.visitIds.length-1 !== idGroup" class="col-auto pe-2">
            <span class="fa fa-arrow-right"></span>
          </div>
        </ng-container>
        <div class="col-auto">
              <span (click)="restriction.visitIds.pop()" class="fa fa-times text-secondary pe-4 clickable"></span>
        </div>
        <div class="col-auto my-2">
          <button (click)="restriction.visitIds.push([])" class="btn btn-info" style="min-width: 10rem" type="button">
            <span class="fa fa-plus-circle pe-2"></span> Add group
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="row narrow-gutters my-3">
  <div class="col-md-auto mb-3 mb-md-0">
    <button (click)="restrictions.push({visitIds: [[],[]], transportingReefers:false})"
            class="btn btn-info btn-block me-2"
            style="min-width: 10rem" type="button">
      <span class="fa fa-plus-circle pe-2"></span> Add restriction
    </button>
  </div>
  <div class="col-md-auto me-auto mb-3 mb-md-0">
    <button (click)="restrictions.push({visitIds: [[],[]], transportingReefers:true})" class="btn btn-info btn-block"
            style="min-width: 10rem" type="button">
      <span class="fa fa-plus-circle pe-2"></span> Add reefer restriction
    </button>
  </div>
  <div class="col-md-auto">
    <button (click)="updateRestrictions()" class="btn btn-info btn-block" style="min-width: 10rem;" tabindex="-1"
            type="button"><span>Save restrictions</span></button>
  </div>
</div>
