<div class="row g-0 align-items-center">
  <div class="col">
    <div class="row g-0 align-items-center">
      <div class="col">
        <div>
          <div class="row g-0 justify-content-between">
            <div
              [ngClass]="{'strikethrough' : handling.cancelled, 'col-lg-4': numberOfColumns()>2, 'invisible' : !updatedEquipmentNumber()}"
              class="text-capitalize col-6">
              {{ updatedEquipmentNumber() || 'UNKNOWN' }}
              <span *ngIf="!!this.handling.shipmentId && this.handling.bookingNumberSource === 'COMMERCIAL_RELEASE'"
                    class="fas fa-shield-check info-icon"
                    title="Secure chain handling released"></span>
              <span *ngIf="!!this.handling.shipmentId && this.handling.bookingNumberSource != 'COMMERCIAL_RELEASE'"
                    class="fal fa-shield info-icon"
                    title="Secure chain handling"></span>
            </div>
            <app-deep-sea-overview
              *ngIf="!!handling.deepSea && !(handling?.handlingStatus?.window && handling.handlingType === 'discharge'); else commercial_window"
              [handling]="handling"
              [ngClass]="{'col-sm-4 col-8': numberOfColumns()==3, 'col-2': numberOfColumns()>3}"
              class="text-secondary small d-none d-lg-block">
            </app-deep-sea-overview>
            <ng-template #commercial_window>
              <div *ngIf="!!handling.handlingStatus?.window"
                   [ngClass]="{'col-sm-4 col-8': numberOfColumns()==3, 'col-2': numberOfColumns()>3}"
                   class="text-secondary small d-none d-lg-block">
                @if (handling.handlingData.type === 'loading') {
                  @if (handling.handlingStatus?.window?.start === handling.handlingStatus?.window?.end) {
                    Ultimate release date
                  } @else {
                    Commercial window
                  }
                } @else {
                  Cargo opening/closing
                }
              </div>
            </ng-template>
            <div *ngIf="displayTerminalAndEta"
                 [ngClass]="{'col-sm-4 col-8': numberOfColumns()==3, 'col-sm-2 col-6': numberOfColumns()>3}"
                 class="text-secondary d-none d-lg-block">
              <span
                class="px-3 badge rounded-pill bg-light text-dark">{{ utils.getSlicedTerminalDisplayName(handling.terminal, handling.modality) }}</span>
            </div>
            <div *ngIf="handling.handlingData.type==='discharge' || utils.isCargoDeclarant(handling)"
                 [ngClass]="{'col-lg-4': numberOfColumns()>2}" class="text-end col-6 d-none d-sm-block">
              {{ handling.handlingData.bookingNumber }}
            </div>
          </div>
          <div class="row g-0 justify-content-between small">
            <div [ngClass]="{'col-lg-4': numberOfColumns()>2}" class="text-secondary col-6">
              <span [title]="handling.handlingId">{{ handling.handlingData.full ? 'Full' : 'Empty' }}</span>
              <notifications [forHandling]="handling"
                             [notificationContext]="'detail'" compactView="true"></notifications>
            </div>
            <div *ngIf="!!handling.handlingStatus?.window || !!handling.deepSea"
                 [ngClass]="{'col-sm-4 col-8': numberOfColumns()==3, 'col-2': numberOfColumns()>3}"
                 class="text-secondary small d-none d-lg-block">
              <ng-container
                *ngIf="!handling.deepSea || (handling?.handlingStatus?.window && handling.handlingType === 'discharge')">
                @if (handling?.handlingStatus?.window?.start === handling?.handlingStatus?.window?.end) {
                  <span class="text-nowrap">{{ windowStartDate() }}, {{ windowStartTime() }}</span>
                } @else {
                  <span class="text-nowrap">{{ windowStartDate() }}, {{ windowStartTime() }}</span> -
                  <span class="text-nowrap">{{ windowEndDate() }}, {{ windowEndTime() }}</span>
                }
              </ng-container>
            </div>
            <div *ngIf="displayTerminalAndEta"
                 [ngClass]="{'col-sm-4 col-8': numberOfColumns()==3, 'col-sm-2 col-6': numberOfColumns()>3}"
                 class="text-secondary small d-none d-lg-block">
              <app-date-time [ngModel]="handling.eta" [readonly]="true"></app-date-time>
            </div>
            <div [ngClass]="{'col-lg-4': numberOfColumns()>2}" class="text-end col-6">
              {{ handling.handlingData.sizeType?.code || "" }}
            </div>
          </div>
          <div *ngIf="!handling.visitId" class="row g-0 justify-content-between small">
            <div [ngClass]="{'col-sm-4 col-8': numberOfColumns()==3, 'col-2': numberOfColumns()>3}"
                 class="text-secondary d-none d-lg-block">
              <span *ngIf="(this.appContext.isHinterlandTerminal() || this.appContext.isAdmin())"
                    class="px-3 badge rounded-pill bg-light text-dark"
                    title="Declarant">{{ handling?.declarant?.fullName }}</span>
            </div>
            <div *ngIf="displayTerminalAndEta"
                 [ngClass]="{'col-sm-4 col-8': numberOfColumns()==3, 'col-sm-2 col-6': numberOfColumns()>3}"
                 class="text-secondary d-none d-lg-block">
            </div>
            <div [ngClass]="{'col-lg-4': numberOfColumns()>2}" class="text-end col-6"></div>
          </div>
        </div>
      </div>
      <div class="col-auto align-items-center">
        <h5 class="my-0 ms-2">
          <span *ngIf="handling.handlingData.type === 'loading'" class="fa fa-arrow-down" title="Loading"></span>
          <span *ngIf="handling.handlingData.type === 'discharge'" class="fa fa-arrow-up" title="Discharge"></span>
        </h5>
        <div *ngIf="handling.transportOrder" title="Transport order available">
          <h6 class="my-0 ms-2">
            <span class="fa fa-euro-sign text-portbase-orange"></span>
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
