import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appValidator]',
  standalone: true
})
export class ValidatorDirective implements AfterViewInit {

  @Input() appValidator: any;

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.onchange = () => {
      this.checkValidity();
    };
    this.checkValidity();
  }

  private checkValidity = () => {
    setTimeout(() => this.appValidator['ngInvalid'] = !!this.elementRef.nativeElement.querySelector('.ng-invalid'), 0);
  }
}
