<div *ngIf="showDateRangeWarning" class="alert alert-warning mb-2">Choosing a wide date range can cause longer load
  times.
</div>
<div class="row narrow-gutters mb-3">
  <div class="col-md-4 mb-2 mb-md-0" style="min-width: 19rem">
    <app-date-range (ngModelChange)="onDateSelection($event)" [ngModel]="dateRange"></app-date-range>
  </div>
  <div class="col">
    <app-filter (resultsFound)="renderFilteredItems()" [(ngModel)]="filterTerm" [searchFunction]="searchOrFilter"
                placeholder="Filter by call ID, barge name, etc..."></app-filter>
  </div>
  <div class="col-auto">
    <app-modality-toggle
      [visibleModalities]="this.appContext.isFiltering('Barge') ? 'Barge' : this.appContext.isFiltering('Rail') ? 'Rail' : 'all'"></app-modality-toggle>
  </div>
  <div *ngIf="appContext.isAdmin()" class="col-2">
    <app-select (ngModelChange)="onTerminalChanged($event)"
                [(ngModel)]="viewAsTerminal"
                [dataKey]="'quayName'"
                [nullOption]="true"
                [optionsProvider]="utils.getAllTerminalOrganisations(false, false)"
                optionLabel="displayName"
                optionSelectable="shortName" required>
    </app-select>
  </div>
</div>

<app-scroller (endReached)="loadAndRenderNextPage()" *ngIf="!!overview">
  <ul #nav="ngbNav" class="nav-tabs" ngbNav>
    <li ngbNavItem="1">
      <a ngbNavLink>Active</a>
      <ng-template ngbNavContent>
        <div class="my-4">
          <ng-container *ngFor="let category of categories; let index = index">
            <ng-container *ngIf="isRequest(category)">
              <app-collapse [animateOnLoad]="false" [collapse]="collapse" [showOnLoad]="true">
                <div class="collapsible mb-2 py-2 px-3 border border-secondary">
                  <div class="row">
                    <div class="col-auto">
                      <h4 class="m-0 fa fa-chevron-right text-dark"></h4>
                    </div>
                    <div class="col fw-bold text-dark">{{category | titlecase}}
                      ({{pagingSupported && !endReached ? '> ' : ''}}{{overview[category].length}})
                    </div>
                  </div>
                </div>

                <ng-template #collapse>
                  <div class="collapse">
                    <ng-container *ngIf="showHeader(overview[category])"
                                  [ngTemplateOutletContext]="{ category : category }"
                                  [ngTemplateOutlet]="visitEntryHeading"></ng-container>
                    <ng-container *ngFor="let model of overview[category]; let index = index"
                                  [ngTemplateOutletContext]="{ model : model }" [ngTemplateOutlet]="visitEntry">
                    </ng-container>
                    <ng-container *ngIf="getSelectedViews(category) as selected"
                                  [ngTemplateOutletContext]="{ category: category, selected : selected }"
                                  [ngTemplateOutlet]="visitActions">
                    </ng-container>
                  </div>
                </ng-template>
              </app-collapse>
            </ng-container>
          </ng-container>
        </div>

        <div class="mt-5 declaration-block">
          <h2>Accepted</h2>
          <ng-container *ngFor="let category of categories; let index = index">
            <ng-container *ngIf="isActive(category) && !isRequest(category)">
              <app-collapse [animateOnLoad]="false" [collapse]="collapse" [showOnLoad]="false">
                <div class="collapsible mb-2 py-2 px-3 border border-secondary">
                  <div class="row">
                    <div class="col-auto">
                      <h4 class="m-0 fa fa-chevron-right text-dark"></h4>
                    </div>
                    <div class="col fw-bold text-dark">{{category | titlecase}}
                      ({{pagingSupported && !endReached ? '> ' : ''}}{{overview[category].length}})
                    </div>
                  </div>
                </div>

                <ng-template #collapse>
                  <div class="collapse">
                    <ng-container *ngIf="showHeader(overview[category])"
                                  [ngTemplateOutletContext]="{ category : category }"
                                  [ngTemplateOutlet]="visitEntryHeading"></ng-container>
                    <ng-container *ngFor="let model of overview[category]; let index = index"
                                  [ngTemplateOutletContext]="{ model : model }" [ngTemplateOutlet]="visitEntry">
                    </ng-container>
                    <ng-container *ngIf="getSelectedViews(category) as selected"
                                  [ngTemplateOutletContext]="{ category: category, selected : selected }"
                                  [ngTemplateOutlet]="visitActions">
                    </ng-container>
                  </div>
                </ng-template>
              </app-collapse>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="2">
      <a ngbNavLink>Processed</a>
      <ng-template ngbNavContent>
        <div class="my-4">
          <ng-container *ngFor="let category of categories; let index = index">
            <ng-container *ngIf="!isActive(category)">
              <app-collapse [animateOnLoad]="false" [collapse]="collapse" [showOnLoad]="true">
                <div class="collapsible mb-2 py-2 px-3 border border-secondary">
                  <div class="row">
                    <div class="col-auto">
                      <h4 class="m-0 fa fa-chevron-right text-dark"></h4>
                    </div>
                    <div class="col fw-bold text-dark">{{category | titlecase}}
                      ({{pagingSupported && !endReached ? '> ' : ''}}{{overview[category].length}})
                    </div>
                  </div>
                </div>
                <ng-template #collapse>
                  <div class="collapse">
                    <ng-container *ngIf="showHeader(overview[category])"
                                  [ngTemplateOutletContext]="{ category : category }"
                                  [ngTemplateOutlet]="visitEntryHeading"></ng-container>
                    <ng-container *ngFor="let model of overview[category]; let index = index"
                                  [ngTemplateOutletContext]="{ model : model }" [ngTemplateOutlet]="visitEntry">
                    </ng-container>
                    <ng-container *ngIf="getSelectedViews(category) as selected"
                                  [ngTemplateOutletContext]="{ category: category, selected : selected }"
                                  [ngTemplateOutlet]="visitActions">
                    </ng-container>
                  </div>
                </ng-template>
              </app-collapse>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</app-scroller>

<ng-template #visitActions let-category='category' let-selected='selected'>
  <div *ngIf="selected.length > 0 || isBulkAcceptable(category)" class="row my-3">
    <div *ngIf="selected.length === 0 && isBulkAcceptable(category)" class="col-sm-auto my-1">
      <button (click)="downloadAndAcceptIntegrallyPlanned(category)" class="btn btn-outline-info">Download and accept
        integrally planned
        <span class="ms-2 fa fa-file-download"></span></button>
    </div>
    <div *ngIf="selected.length > 0" class="col-sm-auto my-1">
      <button (click)="downloadAndPlan(selected)" *ngIf="category === 'requested'" class="btn btn-outline-info"
              type="button">Download and plan selected <span class="ms-2 fa fa-file-download"></span></button>

      <button (click)="download(selected)" *ngIf="category !== 'requested'" class="btn btn-outline-info"
              type="button">Download selected <span class="ms-2 fa fa-file-download"></span></button>
    </div>
    <div *ngIf="appContext.isAdmin() && selected.length > 0" class="col-auto ms-auto my-1">
      <button class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
              style="min-width: 10rem;" type="button">Admin actions
        <span class="caret"></span></button>
      <ul class="dropdown-menu dropdown-menu-start">
        <li (click)="rejectVisits(selected)" class="dropdown-item">Reject visits</li>
        <li (click)="acceptVisits(selected)" class="dropdown-item">Accept visits</li>
        <li (click)="closeLoadDischargeLists(selected)" class="dropdown-item">Close load-discharge list</li>
        <li (click)="reopenLoadDischargeLists(selected)" class="dropdown-item">Reopen load-discharge list</li>
      </ul>
    </div>
    <div *ngIf="appContext.isHinterlandTerminal() && selected.length > 0 && onlyRailVisits(selected)"
         [ngClass]="appContext.isAdmin()?'':'ms-auto'"
         class="col-auto my-1">
      <button (click)="acceptVisits(selected)" class="btn btn-info btn-block"
              type="button">Accept selected rail visits
      </button>
    </div>
  </div>
</ng-template>

<ng-template #visitEntryHeading let-category='category'>
  <div class="px-2">
    <div class="row g-0 bg-light mb-2">
      <div class="col-auto ms-4 mt-2">
        <input (click)="allSelected(category) ? deselectAll(category) : selectAll(category)"
               [ngModel]="allSelected(category)"
               class="custom-check-input" id="selectAll" title="Select all" type="checkbox">
      </div>
      <div class="col">
        <div class="ms-0 border border-light p-2" style="border-width: 0 2px !important;">
          <div class="row text-secondary">
            <div class="col-auto" style="width:3rem">

            </div>
            <div class="col-8 col-md-9 col-lg-11">
              <div class="row">
                <div class="col">
                  Means of transport
                </div>
                <div class="col d-none d-lg-block">
                  Declarant
                </div>
                <div
                  (click)="sort(category, actualComparators[category] == visitIdComparator ? visitIdComparatorReversed : visitIdComparator)"
                  class="col d-none d-md-block"
                  style="cursor: pointer">
                  Call ID
                  <span
                    [ngClass]="{'fa-sort': actualComparators[category] != visitIdComparator && actualComparators[category] != visitIdComparatorReversed, 'fa-sort-down': actualComparators[category] == visitIdComparator, 'fa-sort-up': actualComparators[category] == visitIdComparatorReversed}"
                    class="fa"></span>
                </div>
                <div class="col d-none d-md-block" style="min-width: 10%;">
                  Location
                </div>
                <div class="col-3 d-none d-xl-block" style="max-width:23%;">
                  Handlings
                </div>
                <div
                  (click)="sort(category, actualComparators[category] == visitEtaComparator ? visitEtaComparatorReversed : visitEtaComparator)"
                  class="col-3 d-none d-xl-block"
                  style="cursor: pointer">
                  Eta-Etd
                  <span
                    [ngClass]="{'fa-sort': actualComparators[category] != visitEtaComparator && actualComparators[category] != visitEtaComparatorReversed, 'fa-sort-down': actualComparators[category] == visitEtaComparator, 'fa-sort-up': actualComparators[category] == visitEtaComparatorReversed}"
                    class="fa"></span>
                </div>
              </div>
            </div>
            <div class="col-auto ms-auto p-0" style="width:10px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #visitEntry let-m='model'>
  <div *ngIf="asVisitView(m) as model" class="mb-3 px-2">
    <app-collapse (show)="loadVoyageWithHandlings(model.voyage)" [collapse]="collapse">
      <div class="row g-0">
        <div class="col-auto mt-3 ms-4">
          <input [(ngModel)]="model.selected" class="custom-check-input" type="checkbox">
        </div>
        <div class="col">
          <div class="collapsible ms-0 border border-light p-2" style="border-width: 2px !important;">
            <div class="row">
              <div class="col-auto mt-1" style="width:3rem">
                <h5 *ngIf="model.voyage.modality == 'rail'" class="fa fa-train m-0 ps-1"></h5>
                <h5 *ngIf="model.voyage.modality == 'barge' && !model.voyage['integralPlanningRequested']"
                    class="fa fa-ship m-0 ps-1"></h5>
                <h5 *ngIf="model.voyage.modality == 'barge' && model.voyage['integralPlanningRequested']"
                    class="integral-planning-icon fa-stack pt-0 ps-1 ms-1">
                  <i class="fa fa-ship fa-stack-2x"></i>
                  <i class="fas fa-ellipsis-v fa-stack-1x fa-inverse ms-n3 mt-n1"></i>
                  <i class="fas fa-minus fa-stack-1x fa-inverse fa-rotate-90 fa-sm top ms-n3 mt-n2"></i>
                  <i class="fas fa-minus fa-stack-1x fa-inverse fa-rotate-90 fa-sm bottom ms-n3"></i>
                </h5>

              </div>
              <div class="col-8 col-md-9 col-lg-11">
                <div class="row">
                  <!-- Means of transport -->
                  <div class="col">
                    <span *ngIf="model.status === 'to be planned'
                     && !model.visit.visitDeclaration.acknowledgedByTerminal"
                          class="fa fa-circle me-2 text-info"></span>
                    <span *ngIf="model.voyage.modality == 'barge'">
                        {{model.voyage.voyageData['barge']['name'] | titlecase}}
                    </span>
                    <div *ngIf="model.voyage.modality == 'rail'" style="display: inline-block">
                        <span
                          *ngIf="!!model.voyage.voyageData['dischargeVoyageNumber'] && !!model.voyage.voyageData['loadingVoyageNumber']">
                          {{model.voyage.voyageData['dischargeVoyageNumber']}}
                          <br> {{model.voyage.voyageData['loadingVoyageNumber']}}
                        </span>
                      <span
                        *ngIf="!model.voyage.voyageData['dischargeVoyageNumber'] || !model.voyage.voyageData['loadingVoyageNumber']">
                           {{model.voyage.voyageData['dischargeVoyageNumber'] ? model.voyage.voyageData['dischargeVoyageNumber'] : model.voyage.voyageData['loadingVoyageNumber']}}
                        </span>
                    </div>
                    <span
                      *ngIf="model.voyage.modality == 'rail' && !model.visit.visitData.timetableEntry && model.visit.usingVisitDeclarations"
                      class="fa-solid fa-calendar-circle-plus pt-1 ps-3 text-warning"
                      style="vertical-align: top;" title="Rail visit without a timetable slot"></span>
                    <notifications [forVisit]="model.visit"
                                   [notificationContext]="'overview'"
                                   compactView="true">
                    </notifications>
                  </div>
                  <!-- Declarant -->
                  <div class="col d-none d-lg-block">
                    <span class="fa fa-users"></span> {{model.voyage.declarant.fullName}}
                  </div>
                  <!-- CallId -->
                  <div class="col d-none d-md-block">
                    {{model.visit.visitId}}
                  </div>
                  <!-- Location -->
                  <div class="col d-none d-md-block" style="min-width:10%;">
                    <span
                      *ngIf="!model.visit?.requestedTerminal || model.visit.terminal.quayName===model.visit?.requestedTerminal?.quayName">{{model.visit.terminal.quayName}}</span>
                    <app-tooltip
                      *ngIf="model.visit?.requestedTerminal && model.visit.terminal.quayName!==model.visit?.requestedTerminal.quayName">
                      <div class="tooltipContent text-start">Quay different than requested!</div>
                      <b>{{model.visit.terminal.quayName}}</b>&nbsp;<span class="fa fa-info-circle"
                                                                          style="color:#004462">&nbsp;</span>
                    </app-tooltip>
                  </div>
                  <!-- Handlings -->
                  <div class="col-3 d-none d-xl-block" style="max-width:23%">
                    <app-handling-total
                      [handlingStats]="model.visit.handlingSummary"
                      [inOperation]="model.visit.arrived"
                      [terminalView]="true"
                      [visit]="model.visit">
                    </app-handling-total>
                  </div>
                  <!-- Eta-Etd -->
                  <div class="col-3 d-none d-xl-block">
                    <app-visit-date-range
                      [firstDepartureDate]="model.voyage.voyageData['firstDepartureDate']"
                      [integralPlanningRequested]="model.voyage['integralPlanningRequested']"
                      [integralPlanningStatus]="model.visit['integralPlanningStatus']"
                      [modality]="model.visit.modality"
                      [plannedVisitData]="model.visit?.plannedVisitData"
                      [requestedVisitData]="model.visit?.requestedVisitData"
                      [status]="model.visit.visitDeclarationStatus"
                      [terminal]="model.visit.terminal"
                      [visitData]="model.visit.visitData">
                    </app-visit-date-range>
                  </div>
                </div>

              </div>
              <div class="col-auto ms-auto p-0" style="width:32px">
                <h4 class="m-0 fa fa-chevron-right text-secondary"></h4>
              </div>
            </div>

          </div>
        </div>
      </div>

      <ng-template #collapse>
        <div class="collapse">
          <div class="pb-4 pt-2">
            <div class="row g-0 me-0">
              <div class="col">
                <ul #nav="ngbNav" class="nav-tabs" ngbNav>
                  <li ngbNavItem="1">
                    <a ngbNavLink>Visit details</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 pt-4 border border-top-0 rounded-bottom">
                        <notifications [forVisit]="model.visit"
                                       [notificationContext]="'overview'"
                                       topMargin="0">
                        </notifications>
                        <integral-planning-status
                          [integralPlanningVisitStatus]="model.visit['integralPlanningStatus']"
                          [terminalView]="true"
                          [terminal]="model.visit.terminal">
                        </integral-planning-status>
                        <app-visit-admin
                          [isModalityBarge]="model.visit.modality === 'barge'"
                          [originalVoyageId]="model.voyage['originalVoyageId']"
                          [visitId]="model.visit.visitId"
                          [visitTerminalShortname]="model.visit.terminal?.shortName"
                          [voyageId]="model.voyage.voyageId">
                        </app-visit-admin>
                        <div
                          *ngIf="model.status === 'to be planned' || model.status === 'updated' || model.status === 'requested'"
                          class="pb-3">
                          <div class="row">
                            <div class="col-sm-auto me-auto mb-2 mb-sm-0">
                              <button (click)="rejectVisit(model)"
                                      class="btn btn-secondary btn-block" style="min-width: 10rem;" tabindex="-1"
                                      type="button">Reject visit <span class="fa fa-chevron-right"></span>
                              </button>
                            </div>
                            <div
                              *ngIf="model.status === 'to be planned' && !model.visit.visitDeclaration.acknowledgedByTerminal"
                              class="col-sm-auto mb-2 mb-sm-0">
                              <button (click)="acknowledgeVisitUpdate(model)" class="btn btn-outline-info btn-block"
                                      style="min-width: 10rem;" tabindex="-1"
                                      type="button">Acknowledge update
                              </button>
                            </div>
                            <div class="col-sm-auto mb-2 mb-sm-0">
                              <button (click)="sendRemarks(model)" class="btn btn-info btn-block"
                                      style="min-width: 10rem;"
                                      tabindex="-1"
                                      type="button">Send remarks
                              </button>
                            </div>
                            <div
                              *ngIf="model.status === 'updated' && model.visit.loadDischargeListStatus === 'closing' || model.visit.loadDischargeListStatus === 'closed'"
                              class="col-sm-auto mb-2 mb-sm-0">
                              <button (click)="reopenVisit(model)" class="btn btn-info btn-block"
                                      style="min-width: 10rem;"
                                      tabindex="-1"
                                      type="button">Reopen load-discharge list
                              </button>
                            </div>
                            <div class="col-sm-auto">
                              <button (click)="acceptVisit(model)" class="btn btn-info btn-block"
                                      style="min-width: 10rem;"
                                      tabindex="-1"
                                      type="button">Accept visit
                              </button>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="model.status === 'cancellation requested'" class="pb-3">
                          <div class="row justify-content-between">
                            <div class="col-auto"></div>
                            <div class="col-auto">
                              <button (click)="acceptCancellation(model)" class="btn btn-info" style="min-width: 10rem;"
                                      tabindex="-1"
                                      type="button">Accept cancellation
                              </button>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="model.status === 'planned'" class="pb-3">
                          <div class="row">
                            <div class="col-sm-auto me-auto mb-2 mb-sm-0">
                              <button (click)="rejectVisit(model)"
                                      class="btn btn-secondary btn-block" style="min-width: 10rem;" tabindex="-1"
                                      type="button">Reject visit <span class="fa fa-chevron-right"></span>
                              </button>
                            </div>
                            <div
                              *ngIf="model.visit.loadDischargeListStatus === 'closing' || model.visit.loadDischargeListStatus === 'closed'"
                              class="col-sm-auto mb-2 mb-sm-0">
                              <button (click)="reopenVisit(model)" class="btn btn-info btn-block"
                                      style="min-width: 10rem;"
                                      tabindex="-1"
                                      type="button">Reopen load-discharge list
                              </button>
                            </div>
                            <div class="col-sm-auto">
                              <button (click)="acceptVisit(model, 'adjust visit')" class="btn btn-info btn-block"
                                      style="min-width: 10rem;"
                                      tabindex="-1"
                                      type="button">Adjust visit
                              </button>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="model.status === 'in operation' || (model.status === 'completed' && !model.visit.endOfOperations)" class="pb-3">
                          <div class="row justify-content-between">
                            <div class="col-auto"></div>
                            <div class="col-auto">
                              <button (click)="completeVisit(model)"
                                      class="btn btn-info" style="min-width: 10rem;" tabindex="-1"
                                      type="button">Complete visit <span class="fa fa-chevron-right"></span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="model.visit.requestedVisitData?.operatorRemarks as remarks"
                             [ngClass]="model.status === 'to be planned' && model.visit.acknowledgedRequestedVisitData?.operatorRemarks !==  model.visit.requestedVisitData?.operatorRemarks ? 'alert-warning' : 'alert-info'"
                             class="alert alert-dismissible fade show"
                             role="alert">
                          {{model.voyage.declarant.fullName}}: {{remarks}}
                          <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
                        </div>
                        <app-edit-rail-visit *ngIf="model.voyage.modality === 'rail'"
                                             [terminalView]="true"
                                             [visit]="model.visit"
                                             [voyage]="model.voyage">
                        </app-edit-rail-visit>
                        <app-edit-barge-visit-terminal *ngIf="model.voyage.modality === 'barge'"
                                                       [current]="model.visit.visitData"
                                                       [previousRequested]="model.status === 'updated' || model.visit.visitDeclarationStatus === 'DECLARED' ? model.visit.acknowledgedRequestedVisitData : null"
                                                       [previous]="model.status === 'requested' ? model.visit.requestedVisitData
                          : model.status === 'to be planned' ? model.visit.acknowledgedRequestedVisitData
                          : model.status === 'updated' || model.visit.visitDeclarationStatus === 'DECLARED' ? model.visit.plannedVisitData : null"
                                                       [visit]="model.visit"
                                                       [voyage]="model.voyage">
                        </app-edit-barge-visit-terminal>
                      </div>
                    </ng-template>
                  </li>
                  <li ngbNavItem="2">
                    <a ngbNavLink>Handlings</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <app-visit-handlings *ngIf="model.visit.handlings"
                                             [readonly]="true" [setVisit]="model.visit"
                                             [voyage]="model.voyage"></app-visit-handlings>
                      </div>
                    </ng-template>
                  </li>
                  <li *ngIf="model.visit.modality == 'rail'"
                      id="trainCompositionTab"
                      ngbNavItem="3">
                    <a ngbNavLink>Train composition</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <div class="row">
                          <div class="col-12 mb-2 text-end">
                            <button (click)="downloadTrainComposition(model.voyage, model.visit)"
                                    [disabled]="!model.visit['trainComposition']" class="btn btn-info mx-1"
                                    type="button">
                              <span>Download train composition</span>
                            </button>
                          </div>
                          <div *ngIf="!model.visit['trainComposition']" class="col-md-12">
                            No train composition available yet
                          </div>
                          <div class="col-md-12">
                            <app-train-composition *ngIf="model.visit['trainComposition']"
                                                   [trainComposition]="model.visit['trainComposition']"></app-train-composition>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li *ngIf="!appContext.isPhone()" ngbNavItem="4">
                    <a ngbNavLink>History</a>
                    <ng-template ngbNavContent>
                      <div class="p-3 border border-top-0 rounded-bottom">
                        <div class="row">
                          <div class="declaration-block col-md-12">
                            <h2>Visit history</h2>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <app-history-component [visitId]="model.visit.visitId"></app-history-component>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-collapse>

  </div>
</ng-template>

<div class="modal fade" id="rejectVisitModal" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="m-0">Reject this visit?</h5>
        <h5 class="m-0 text-end"><span class="ms-4 badge rounded-pill bg-secondary">Call ID</span>&nbsp;
          <span class="ms-2">{{visitForReject?.visit.visitId}}</span></h5>
      </div>
      <div class="modal-body">
        <textarea #rejectReason class="form-control" placeholder="Type your reason here"></textarea>
      </div>
      <div class="modal-footer">
        <div class="container-fluid px-0">
          <div class="row g-0 justify-content-between">
            <div class="col-auto">
              <button class="btn btn-secondary" data-bs-dismiss="modal" style="min-width: 8rem" type="button">Cancel
              </button>
            </div>
            <div class="col-auto">
              <button (click)="doRejectVisit(rejectReason.value)" class="btn btn-info" data-bs-dismiss="modal"
                      style="min-width: 8rem"
                      type="button">Reject visit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="completeVisitModal" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="m-0">Complete Visit</h5>
        <h5 class="m-0 text-end"><span class="ms-4 badge rounded-pill bg-secondary">Call ID</span>&nbsp;
          <span class="ms-2">{{visitForComplete?.visit.visitId}}</span></h5>
      </div>
      <div class="modal-body">
        <div class="container px-0">
          <div class="row">
            <div class="col-3">
              <label [ngClass]="{'required' : true}">End of Operations</label>
            </div>
            <div class="col-9">
              <app-date-time #endOfOperationsComponent [(ngModel)]="initialEndOfOperationsDate" [required]="true"
                             [timeEmptyOnInit]="true"></app-date-time>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="container px-0">
          <div class="row g-0 justify-content-between">
            <div class="col-auto">
              <button class="btn btn-secondary" data-bs-dismiss="modal" style="min-width: 8rem" type="button">Cancel
              </button>
            </div>
            <div class="col-auto">
              <button (click)="doCompleteVisit(endOfOperationsComponent.value)" class="btn btn-info"
                      style="min-width: 8rem"
                      type="button">Complete Visit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
