<div
  [ngClass]="{'has-remain-on-truck' : hasRemainOnTruckContainers(), 'attach-handling': this.displayMode === 'attachToVisitMode'}"
  class="handling-panel">
  <ng-container *ngIf="!actionMode else attachMode">
    <div class="handling-panel-row handling-panel-grid panel-grid g-0">
      <!-- Selection checkbox & loading/discharge Icon -->
      <div *ngIf="!isReadOnly() else emptyDiv" class="form-check hide-md-screen">
        <input (ngModelChange)="onSelectionChanged()" [id]="handling.handlingId+'-checkbox'"
               [ngModel]="handling['selected']" class="form-check-input" type="checkbox">
        <label [for]="handling.handlingId+'-checkbox'" class="form-check-label"></label>
      </div>
      <ng-template #emptyDiv>
        <div class="hide-md-screen">&nbsp;</div>
      </ng-template>

      <div [ngbTooltip]="sizeTypeTooltip" container="body" class="size-type" placement="bottom">
        <div class="panel-icon">
          <span [class]="'fa-fw fa-lg pb-container-' + (isPickup? 'pickup': 'dropoff')"></span>
        </div>
      </div>

      <!-- Equipment & handling-type -->
      <div class="truncate">
        <div class="text-truncate"><span
          class="select-all">{{
            (handling.handlingData.equipmentNumber) ? handling.handlingData.equipmentNumber
              : handling.handlingStatus?.equipmentNumber ? handling.handlingStatus?.equipmentNumber : '-'
          }}</span>
          <span *ngIf="!handling.handlingData?.equipmentNumber && handling.handlingStatus?.equipmentNumber"
                [ngbTooltip]="equipmentNumberTooltip" container="body" placement="bottom" class="info-icon">
            <i class="fa-light fa-circle-info"></i>
          </span>
          <span *ngIf="secureChainHandling" [ngbTooltip]="secureChainTooltip"
                [ngClass]="commercialReleaseAvailable ? 'fas fa-shield-check' : 'fal fa-shield'"
                class="info-icon secure-chain-shield"
                placement="bottom"
                data-userflow="secure-chain-shield"
          ></span>
        </div>
        <div class="sub-text">
          <span *ngIf="isPickup">Pick-up</span>
          <span *ngIf="!isPickup">Drop-off</span>
        </div>
      </div>

      <!-- BookingNumber (attached) / Terminal (detached) -->
      <div *ngIf="!isDetached" class="truncate hide-md-screen">
        <div class="select-all text-truncate">{{ handling.handlingData.bookingNumber }}</div>
        <div class="sub-text text-truncate">Acceptance ref.</div>
      </div>

      <div *ngIf="isDetached" class="hide-md-screen">
        <div>{{ utils.getTerminalDisplayName(handling['terminal'], 'road') }}</div>
        <div class="sub-text">Terminal</div>
      </div>

      <!-- Deep sea information -->
      <ng-container *ngIf="!!deepSea?.shipName && !(!isPickup && handling?.handlingStatus?.window) else window">
        <div class="hide-lg-screen">
          <div [ngbTooltip]="(deepSea) ? deepSeaTooltip : ''" class="deep-sea" container="body" placement="bottom">
            <div *ngIf="deepSea?.shipName; else dashTemplate">{{ deepSea.shipName }}</div>
            <div class="sub-text">Deep sea vessel</div>
          </div>
        </div>
      </ng-container>

      <ng-template #window>
        <ng-container *ngIf="(!!cargoOpeningTime || !!cargoClosingTime) else emptyDeepSea">
          <div [ngbTooltip]="(deepSea) ? deepSeaTooltip : ''" container="body" class="hide-lg-screen deep-sea"
               placement="bottom">
            <div>
              <app-formatted-date-range [end]="cargoClosingTime" [showClock]="false"
                                        [start]="cargoOpeningTime" [startOnly]="cargoOpeningTime == cargoClosingTime && isPickup">
              </app-formatted-date-range>
            </div>
            <div class="sub-text">{{ isPickup ?
              cargoOpeningTime === cargoClosingTime ? "Ultimate release date" : "Commercial window"
              : "Cargo opening/closing" }}</div>
          </div>
        </ng-container>

        <ng-template #emptyDeepSea>
          <ng-container>
            <div class="hide-lg-screen">
              <div>-</div>
              <div class="sub-text">Deep sea vessel</div>
            </div>
          </ng-container>
        </ng-template>
      </ng-template>

      <!-- Handling Status -->
      <div>
        <app-handling-status-panel [handling]="handling" [visit]="visit"
                                   class="hide-md-screen"></app-handling-status-panel>
        <div (click)="showHandlingDetails()">
          <app-handling-status-panel [disableTooltip]="true" [displayMode]="'summary'"
                                     [handling]="handling" [visit]="visit"
                                     class="show-sm-screen"></app-handling-status-panel>
        </div>
      </div>

      <div class="hide-md-screen">
        <div *ngIf="this.applicableNotifications.length > 0" [ngbTooltip]="notificationsTooltip" container="body"
             placement="left">
              <span [ngClass]="getNotificationClass()" class="notification">
                {{ applicableNotifications?.length }}
              </span>
        </div>
      </div>

      <div class="details">
        <button (click)="showHandlingDetails()" class="btn btn-tertiary details-button">
          <span class="hide-md-screen">Details</span><i class="button-icon fa-light fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #attachMode>
  <div [class]="actionMode === 'attachToVisitMode' ? 'grid-attach-to-visit-mode' : 'grid-add-handling-to-visit-mode'"
       class="handling-panel-grid g-0">

    <div [ngbTooltip]="sizeTypeTooltip" container="body" class="size-type" placement="bottom">
      <div class="panel-icon">
        <span [class]="'fa-fw fa-lg pb-container-' + (isPickup? 'pickup' : 'dropoff')"></span>
      </div>
    </div>

    <div class="truncate">
      <div class="select-all text-truncate">{{
          (handling.handlingData.equipmentNumber) ? handling.handlingData.equipmentNumber
            : handling.handlingStatus?.equipmentNumber ? handling.handlingStatus?.equipmentNumber : '-'
        }}
        <span *ngIf="!handling.handlingData.equipmentNumber && handling.handlingStatus?.equipmentNumber"
              [ngbTooltip]="equipmentNumberTooltip" placement="bottom" container="body" class="info-icon">
          <i class="fa-light fa-circle-info"></i>
        </span>
        <span *ngIf="secureChainHandling" [ngbTooltip]="secureChainTooltip" class="fal fa-shield info-icon"
              placement="bottom"></span>
      </div>
      <div class="sub-text">
        <span *ngIf="isPickup">Pick-up</span>
        <span *ngIf="!isPickup">Drop-off</span>
      </div>
    </div>

    <div class="hide-md-screen">
      <div>{{ handling.handlingData.sizeType?.code }} {{ handling.handlingData.full ? '(full)' : '(empty)' }}</div>
      <div class="sub-text">Size type</div>
    </div>

    <div class="hide-lg-screen">
      <div><span
        *ngIf="handling.handlingStatus?.blockGroup; else dashTemplate">{{ handling.handlingStatus.blockGroup }}</span>
      </div>
      <div class="sub-text">Block group</div>
    </div>

    <div class="hide-md-screen">
      <ng-container
        *ngIf="!!deepSea && (!!deepSea.cargoOpeningTime || !!deepSea.cargoClosingTime) else commercial_window">
        <div>
          <app-formatted-date-range [end]="deepSea?.cargoClosingTime"
                                    [showClock]="false" [start]="deepSea?.cargoOpeningTime">
          </app-formatted-date-range>
        </div>
        <div class="sub-text">{{ isPickup ? "Commercial window" : "Cargo opening/closing" }}</div>
      </ng-container>
      <ng-template #commercial_window>
        <div *ngIf="!!handling.handlingStatus?.window else dashTemplate">
          <app-formatted-date-range [end]="handling.handlingStatus?.window?.end"
                                    [showClock]="false" [start]="handling.handlingStatus?.window?.start">
          </app-formatted-date-range>
        </div>
        <div class="sub-text">{{ isPickup ? "Commercial window" : "Cargo opening/closing" }}</div>
      </ng-template>
    </div>

    <div *ngIf="actionMode === 'attachToVisitMode'">&nbsp;</div>

    <div>
      <app-handling-status-panel [displayMode]="'summary'" [handling]="handling"
                                 [visit]="visit"></app-handling-status-panel>
    </div>

    <div *ngIf="actionMode === 'addHandlingToVisitMode'" class="text-end move-handling">
      <button (click)="showModelAndAddHandlingToThisVisit()" [disabled]="handling['visitIdToAttach'] === visit?.visitId"
              class="btn btn-secondary" data-bs-toggle="modal" type="button">
        <i class="fal fa-arrow-up-right fa-lg button-icon pe-2"></i>Move<span class="hide-md-screen"> handling</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #equipmentNumberTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-title">Reported by terminal</div>
  </div>
</ng-template>

<ng-template #sizeTypeTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-title">Size type</div>
    <div class="tooltip-card-body text-nowrap">
      {{ handling.handlingData.sizeType?.code }} {{ handling.handlingData.full ? '(full)' : '(empty)' }}
    </div>
  </div>
</ng-template>

<ng-template #secureChainTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body text-nowrap">
      @if (commercialReleaseAvailable) {
        Secure chain handling released
      } @else {
        Secure chain handling
      }
    </div>
  </div>
</ng-template>

<ng-template #deepSeaTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-title">Vessel</div>
    <div class="tooltip-card-body">
      <span *ngIf="deepSea?.shipName; else dashTemplate">{{ deepSea.shipName }}</span>
    </div>
  </div>
  <div class="tooltip-card">
    <div class="tooltip-card-title">ETA</div>
    <div class="tooltip-card-body">
      <span *ngIf="deepSea?.eta; else dashTemplate">{{ formatDateTime(deepSea.eta) }}</span>
    </div>
  </div>
  <div class="tooltip-card">
    <div class="tooltip-card-title">ETD</div>
    <div class="tooltip-card-body">
      <span *ngIf="deepSea?.etd; else dashTemplate">{{ formatDateTime(deepSea.etd) }}</span>
    </div>
  </div>
  <div *ngIf="isPickup" class="tooltip-card">
    <div class="tooltip-card-title">Expected Discharge Time</div>
    <div class="tooltip-card-body">
        <span
          *ngIf="deepSea?.expectedDischargeTime; else dashTemplate">{{ formatDateTime(deepSea.expectedDischargeTime) }}</span>
    </div>
  </div>
  <div *ngIf="deepSea?.source" class="tooltip-card">
    <div class="tooltip-card-title">Source</div>
    <div class="tooltip-card-body">
      <span>{{ deepSea.source }}</span>
    </div>
  </div>
  <div *ngIf="deepSea?.lastUpdated" class="tooltip-card">
    <div class="tooltip-card-title">Last updated</div>
    <div class="tooltip-card-body">
      <span>{{ formatDateTime(deepSea.lastUpdated) }}</span>
    </div>
  </div>

  <div *ngIf="!isPickup" class="tooltip-card">
    <div class="tooltip-card-title">Cargo Opening</div>
    <div class="tooltip-card-body">
      <span *ngIf="deepSea?.cargoOpeningTime; else dashTemplate">{{ formatDateTime(deepSea.cargoOpeningTime) }}</span>
    </div>
  </div>

  <div *ngIf="!isPickup" class="tooltip-card">
    <div class="tooltip-card-title">Cargo Closing</div>
    <div class="tooltip-card-body">
      <span *ngIf="deepSea?.cargoClosingTime; else dashTemplate">{{ formatDateTime(deepSea.cargoClosingTime) }}</span>
    </div>
  </div>

  <div *ngIf="isPickup && !!deepSea?.cargoOpeningTime && deepSea?.cargoOpeningTime === deepSea?.cargoClosingTime" class="tooltip-card">
    <div class="tooltip-card-title">Ultimate release date</div>
    <div class="tooltip-card-body">
      <span *ngIf="deepSea?.cargoOpeningTime; else dashTemplate">{{ formatDateTime(deepSea.cargoOpeningTime) }}</span>
    </div>
  </div>
</ng-template>

<ng-template #notificationsTooltip>
  <div class="tooltip-card">
    <div *ngIf="highLevelApplicableNotifications.length > 0" class="tooltip-card-title">High priority</div>
    <div class="tooltip-card-body mb-2">
      <span *ngFor="let notification of highLevelApplicableNotifications">
        [{{ notification.code }}] {{ notification.description }}
        </span>
    </div>

    <div *ngIf="mediumLevelApplicableNotifications.length > 0" class="tooltip-card-title">Medium priority</div>
    <div class="tooltip-card-body mb-2">
      <span *ngFor="let notification of mediumLevelApplicableNotifications">
        [{{ notification.code }}] {{ notification.description }}
        </span>
    </div>

    <div *ngIf="lowLevelApplicableNotifications.length > 0" class="tooltip-card-title">Low priority</div>
    <div class="tooltip-card-body mb-2">
      <span *ngFor="let notification of lowLevelApplicableNotifications">
        [{{ notification.code }}] {{ notification.description }}
        </span>
    </div>
  </div>
</ng-template>

<ng-template #dashTemplate>-</ng-template>
