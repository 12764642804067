<div class="fieldset">
  <div class="row">
    <div class="col-md-6">
      <app-form-group>
        <label>Terminal</label>
        <input [(ngModel)]="terminal" class="form-control" disabled>
      </app-form-group>

      <app-form-group *ngIf="visit.visitId">
        <label>Call ID</label>
        <input [(ngModel)]="visit.visitId" class="form-control" disabled>
      </app-form-group>

      <app-form-group *ngIf="visit.plannedVisitData?.tar">
        <label>TAR</label>
        <input [(ngModel)]="visit.plannedVisitData.tar" class="form-control" disabled>
      </app-form-group>
    </div>
  </div>
</div>
